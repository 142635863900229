import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import Api from "../utils/api";
import { IGroupStatisticItem } from "models/dto/ZoomiLxp/Models/GroupModels/IGroupStatisticItem";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IGroupModel } from "models/dto/ZoomiLxp/Models/GroupModels/IGroupModel";

export class GroupsApi {
	static getGroupsStatistic(data: IQueryParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IGroupStatisticItem[]>>("/group/groupsStatistic", data);
	}

	static getGroup(id: number) {
		return Api.get<IGroupModel>(`/group/${id}`);
	}

	static deleteGroup(id: number) {
		return Api.delete<object>(`/group/${id}`);
	}

	static deleteGroupFromCourse(groupId: number, courseId: number) {
		return Api.delete<object>(`/group/fromCourse/${groupId}/${courseId}`);
	}

	static deleteGroupFromThread(groupId: number, threadId: number) {
		return Api.delete<object>(`/group/fromThread/${groupId}/${threadId}`);
	}

	static deleteGroupFromUser(groupId: number, userId: number) {
		return Api.delete<object>(`/group/fromUser/${groupId}/${userId}`);
	}
}
