import React, { ReactElement, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";
import Icon from "../icon/icon";
import { observer } from "mobx-react";

export interface ButtonProps {
	label: string;
	hoverState?: { label?: string; icon?: ReactElement };
	testId?: string;
	onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	className?: string;
	disabled?: boolean;
	icon?: ReactElement | null;
	iconPosition?: "left" | "right";
	type?: "primary" | "secondary" | "outlined" | "text" | "link" | "info";
	form?: "default" | "rounded";
	size?: "default" | "common" | "small";
	labelColor?:
		| "primary"
		| "secondary"
		| "primaryText"
		| "secondaryText"
		| "textInput"
		| "tileText"
		| "outlinePrimary"
		| "outlineSecondary"
		| "textLayoutHeader";
	enableHover?: boolean;
	title?: string;
}

const Button = (props: ButtonProps) => {
	const {
		disabled = false,
		type = "primary",
		form = "rounded",
		size = "default",
		enableHover = true,
		labelColor = "primaryText",
		title,
	} = props;
	const [label, setLabel] = useState(props.label);
	const [icon, setIcon] = useState(props.icon);
	useEffect(() => setLabel(props.label), [props.label]);
	useEffect(() => setIcon(props.icon), [props.icon]);

	return (
		<div
			data-testid={props.testId}
			className={classnames(
				styles.button,
				styles[`button__form_${form}`],
				styles[`button__size_${size}`],
				{
					[`${styles.button_disabled}`]: disabled,
					[`${styles.hoverable}`]: enableHover,
				},
				props.className,
				styles[`button__type_${type}`]
			)}
			onClick={(event) => {
				if (disabled) {
					event.preventDefault();
					event.stopPropagation();
					return;
				}
				return props.onClick(event);
			}}
			onMouseEnter={() => {
				if (!props.hoverState) {
					return;
				}
				props.hoverState.label && setLabel(props.hoverState.label);
				props.hoverState.icon && setIcon(props.hoverState.icon);
			}}
			onMouseLeave={() => {
				if (!props.hoverState) {
					return;
				}
				props.hoverState.label && setLabel(props.label);
				props.hoverState.icon && setIcon(props.icon);
			}}
			title={title}
		>
			{icon && (props.iconPosition === "left" || !props.iconPosition) && (
				<Icon className={styles.button_icon} icon={icon} />
			)}
			<span
				className={classnames(styles.button__label, {
					[styles.label_text_input]: labelColor === "textInput",
					[styles.label_text_tile]: labelColor === "tileText",
					[styles.label_primary]: labelColor === "primary",
					[styles.label_secondary]: labelColor === "secondary",
					[styles.label_text_primary]: labelColor === "primaryText",
					[styles.label_text_secondary]: labelColor === "secondaryText",
					[styles.label_text_outline_primary]: labelColor === "outlinePrimary",
					[styles.label_text_outline_secondary]: labelColor === "outlineSecondary",
					[styles.label_text_layout_header]: labelColor === "textLayoutHeader",
				})}
			>
				{label}
			</span>
			{icon && props.iconPosition === "right" && (
				<Icon
					icon={icon}
					className={classnames(styles.button_icon, styles.button_icon__right, {
						[styles.button_icon__small]: size === "small",
						[styles.button_icon__primary]: labelColor === "primaryText",
					})}
				/>
			)}
		</div>
	);
};

export default observer(Button);
