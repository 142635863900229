import styles from "./styles.module.scss";
import { CmsTileProps } from "components/partial/cms/cms-table/cms-table";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import { ICertificatePreviewModel } from "models/dto/ZoomiLxp/Models/Certificates/ICertificatePreviewModel";
import { CertificateDownloadCell, CourseVersionLink } from "./data";

const MyCertificatesTile = (props: CmsTileProps<ICertificatePreviewModel>) => {
	const { item } = props;
	const { certId, certIssueDate, courseTitle, courseVersionDate, courseVersionId, courseId } = item;

	return (
		<div className={styles.cms_tile_certificate}>
			<div className={styles.cms_tile_certificate__course_title_container}>
				<span>Course: {courseTitle}</span>
				<CertificateDownloadCell certId={certId} />
			</div>

			<div className={styles.cms_tile_certificate__course_title}>
				Version ID: <CourseVersionLink courseId={courseId} versionId={courseVersionId} />
			</div>
			<div>
				Version date:{" "}
				{!isNil(courseVersionDate) ? (
					DateTime.fromISO(courseVersionDate).setLocale("en").toLocaleString(DateTime.DATE_MED)
				) : (
					<>-</>
				)}
			</div>
			<div>
				Certificate ID: <span className={styles.cms_tile_certificate__cert_id_row}>{certId}</span>
			</div>
			<div>
				Issue date:{" "}
				{!isNil(certIssueDate) ? (
					DateTime.fromISO(certIssueDate).setLocale("en").toLocaleString(DateTime.DATE_MED)
				) : (
					<>-</>
				)}
			</div>
		</div>
	);
};

export default MyCertificatesTile;
