import styles from "./styles.module.scss";
import { Column } from "react-table";
import isEmpty from "lodash/isEmpty";
import { IB2CPurchaseModel } from "models/dto/ZoomiLxp/Models/Billing/IB2CPurchaseModel";
import { ReactComponent as PlayIcon } from "assets/icons/ic_player_play.svg";
import { isNil } from "lodash";
import { DateTime } from "luxon";
import classNames from "classnames";
import { NavLink, generatePath } from "react-router-dom";
import { Routes } from "routes";
import Tooltip from "components/base/tooltip/tooltip";
import * as qs from "querystringify";
import { TransitionSources } from "constants/constants";

const generateCoursePath = (courseId: number): string => {
	let path = generatePath(Routes.CoursePage, { id: courseId });
	path += qs.stringify(
		{
			ref: TransitionSources.Purchases,
			refCourseId: undefined,
			refCourseItemId: undefined,
		},
		true
	);
	return path;
};

export const columns: Column<IB2CPurchaseModel>[] = [
	{
		Header: "Purchases",
		columns: [
			{
				Header: "",
				accessor: "courseId",
				Cell(cell) {
					return (
						<Tooltip
							label={"Course is inactive."}
							className={styles.profile_purchases__tooltip}
							isActive={!cell.row.original.courseIsActive}
						>
							<NavLink
								onClick={(event) => !cell.row.original.courseIsActive && event.preventDefault()}
								to={generateCoursePath(cell.row.original.courseId)}
								target="_blank"
							>
								<PlayIcon
									className={classNames(styles.profile_purchases__play_icon, {
										[styles.icon_disabled]: !cell.row.original.courseIsActive,
									})}
								/>
							</NavLink>
						</Tooltip>
					);
				},
			},
			{
				Header: "Course",
				accessor: "courseTitle",
				Cell({ cell: { value } }) {
					return !isEmpty(value) ? <>{value}</> : <></>;
				},
			},
			{
				Header: "Date",
				accessor: "date",
				Cell({ cell: { value } }) {
					return !isNil(value) ? DateTime.fromISO(value).setLocale("en").toLocaleString(DateTime.DATE_MED) : <>-</>;
				},
			},
			{
				Header: "Payed",
				accessor: "cost",
				Cell({ cell: { value } }) {
					return `$ ${value}`;
				},
			},
			{
				Header: "Status",
				accessor: "status",
				Cell({ cell: { value } }) {
					return (
						<span
							className={classNames(styles.profile_purchases__status, {
								[styles.profile_purchases__status_success]: value === "succeeded",
								[styles.profile_purchases__status_error]: value === "failed",
							})}
						>
							{value}
						</span>
					);
				},
			},
		],
	},
];
