import { AnalyticsApi } from "api/controllers/AnalyticsApi";
import { IMotifsTableRowData } from "components/partial/myDesk/my-analytics/components/motifs-statistics/data";
import { makeAutoObservable, runInAction } from "mobx";
import { ICourseMotifStatisticsParams } from "models/dto/ZoomiLxp/Models/Analytics/ICourseMotifStatisticsParams";
import { IEngagementRequestModel } from "models/dto/ZoomiLxp/Models/Analytics/IEngagementRequestModel";
import { IInteractionsInfluencerResponseModel } from "models/dto/ZoomiLxp/Models/Analytics/IInteractionsInfluencerResponseModel";
import { ILearningPathItem } from "models/dto/ZoomiLxp/Models/Analytics/ILearningPathItem";
import { IMotifMeterItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifMeterItem";
import { IMotifSessionItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifSessionItem";
import { IMotifStatisticsItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifStatisticsItem";
import { IMotifStatisticsParams } from "models/dto/ZoomiLxp/Models/Analytics/IMotifStatisticsParams";
import { IMotifsMeterRequestModel } from "models/dto/ZoomiLxp/Models/Analytics/IMotifsMeterRequestModel";
import { ITransitionSourceModel } from "models/dto/ZoomiLxp/Models/Analytics/ITransitionSourceModel";
import { IZ1RequestModel } from "models/dto/ZoomiLxp/Models/Analytics/IZ1RequestModel";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { ISubjectModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ISubjectModel";
import { TimeFilterTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/TimeFilterTypes";

export interface IMotifCourseSessionsItems {
	courseId: number;
	sessions: IMotifSessionItem[];
}
export class AnalyticsStore {
	private _isLoading: boolean = false;
	private _isSessionsLoading: boolean = false;
	commentsCount: number = 0;
	likesCount: number = 0;
	podcastsCount: number = 0;
	textsCount: number = 0;
	videosCount: number = 0;
	z1Score: number = 0;
	engagementScore: number = 0;
	analyticsSubjects: ISubjectModel[] = [];
	motifsMeterItems: IMotifMeterItem[] = [];
	motifsSessionsItems: IMotifCourseSessionsItems[] = [];
	tableData: IMotifsTableRowData[] = [];
	allMyMotifsCount: number = 0;
	allOthersMotifsCount: number = 0;
	watchingTime: number = 0;
	listenedTime: number = 0;
	readTime: number = 0;
	scormTime: number = 0;
	interactions: IInteractionsInfluencerResponseModel[] = [];
	myLearningPathItems: ILearningPathItem[] = [];
	motifsMeterInsight: { title: string; text: string } | null = null;
	private _transitionSources: ITransitionSourceModel[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	async getAllAnalytics(userId?: number) {
		this.isLoading = true;
		try {
			Promise.allSettled([
				this.getCommonAnalytics(userId),
				this.getTotalTimings(userId),
				this.getZ1Score(undefined, userId),
				this.getEngagementScore(undefined, userId),
				this.getInteractions(userId),
				this.getAnalyticsSubjects(),
				this.getMotifsMeterData(undefined, userId),
			]);
		} finally {
			this.isLoading = false;
		}
	}

	async getCommonAnalytics(userId?: number) {
		this.isLoading = true;
		try {
			const commentsCountResponse = userId
				? await AnalyticsApi.getUserCommentsCount(userId)
				: await AnalyticsApi.getCommentsCount();
			const likesCountResponse = userId
				? await AnalyticsApi.getUserLikesCount(userId)
				: await AnalyticsApi.getLikesCount();
			const podcastsCountResponse = userId
				? await AnalyticsApi.getUserPodcastsCount(userId)
				: await AnalyticsApi.getPodcastsCount();
			const textsCountResponse = userId
				? await AnalyticsApi.getUserTextsCount(userId)
				: await AnalyticsApi.getTextsCount();
			const videosCountResponse = userId
				? await AnalyticsApi.getUserVideosCount(userId)
				: await AnalyticsApi.getVideosCount();
			runInAction(() => {
				this.commentsCount = commentsCountResponse.data.data.count;
				this.likesCount = likesCountResponse.data.data.count;
				this.podcastsCount = podcastsCountResponse.data.data.count;
				this.textsCount = textsCountResponse.data.data.count;
				this.videosCount = videosCountResponse.data.data.count;
			});
		} finally {
			this.isLoading = false;
		}
	}

	async getTotalTimings(userId?: number) {
		this.isLoading = true;
		try {
			const watchingTimeResponse = userId
				? await AnalyticsApi.getUserTotalWatchedTime(userId)
				: await AnalyticsApi.getTotalWatchedTime();
			const listenedTimeResponse = userId
				? await AnalyticsApi.getUserTotalListenedTime(userId)
				: await AnalyticsApi.getTotalListenedTime();
			const scormTimeResponse = userId
				? await AnalyticsApi.getUserTotalScormTime(userId)
				: await AnalyticsApi.getTotalScormTime();
			const readTimeResponse = userId
				? await AnalyticsApi.getUserTotalReadedTime(userId)
				: await AnalyticsApi.getTotalReadedTime();

			runInAction(() => {
				this.watchingTime = watchingTimeResponse.data.data.count;
				this.listenedTime = listenedTimeResponse.data.data.count;
				this.scormTime = scormTimeResponse.data.data.count;
				this.readTime = readTimeResponse.data.data.count;
			});
		} finally {
			this.isLoading = false;
		}
	}

	async getZ1Score(data?: IZ1RequestModel, userId?: number) {
		this.isLoading = true;
		const initialRequest: IZ1RequestModel = {
			timeFilterType: TimeFilterTypes.OverAll,
		};
		const response = userId
			? await AnalyticsApi.getUserZ1Score(userId, data ?? initialRequest)
			: await AnalyticsApi.getZ1Score(data ?? initialRequest);
		try {
			runInAction(() => (this.z1Score = response.data.data.percentZ1Rating));
			return response.data.data.percentZ1Rating;
		} finally {
			this.isLoading = false;
		}
	}

	async getAnalyticsSubjects() {
		this.isLoading = true;
		const response = await AnalyticsApi.getAnalyticsSubjects();

		try {
			runInAction(() => (this.analyticsSubjects = response.data.data.subjects));
			return response.data.data.subjects;
		} finally {
			this.isLoading = false;
		}
	}

	async getMotifsMeterData(data?: IMotifsMeterRequestModel, userId?: number) {
		this.isLoading = true;
		const initialRequest: IMotifsMeterRequestModel = {
			timeFilterType: TimeFilterTypes.OverAll,
		};
		const response = userId
			? await AnalyticsApi.getUserMotifsMeterData(userId, data ?? initialRequest)
			: await AnalyticsApi.getMotifsMeterData(data ?? initialRequest);
		try {
			runInAction(() => {
				this.motifsMeterItems = response.data.data.motifMeterItems;
				this.allMyMotifsCount = response.data.data.allMyMotifsCount;
				this.allOthersMotifsCount = response.data.data.allOthersMotifsCount;
				this.motifsMeterInsight = { text: response.data.data.hint, title: response.data.data.hintTitle };
			});
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getMotifsStatistics(data: IMotifStatisticsParams, userId?: number) {
		this.isSessionsLoading = true;

		try {
			const response = userId
				? await AnalyticsApi.getUserMotifsStatisticsData(userId, data)
				: await AnalyticsApi.getMotifsStatisticsData(data);
			if (!this.analyticsSubjects.length) {
				await this.getAnalyticsSubjects();
			}

			if (!this.motifsMeterItems.length) {
				await this.getMotifsMeterData(undefined, userId);
			}

			const motifsStatistics = await this.statisticsMapper(response.data.data.records, userId);

			const result: IGetRecordsResponse<IMotifsTableRowData> = {
				records: motifsStatistics,
				totalFilteredRecords: response.data.data.totalFilteredRecords,
			};

			runInAction(() => {
				this.tableData = motifsStatistics;
			});
			return result;
		} finally {
			this.isSessionsLoading = false;
		}
	}

	private async statisticsMapper(data: IMotifStatisticsItem[], userId?: number) {
		const mappedStatistics = data.map(async (statsItem: IMotifStatisticsItem) => {
			const sessions = await this.getCourseSessions({ courseId: statsItem.courseId, take: 15 }, userId);
			const tableRowData: IMotifsTableRowData = {
				...statsItem,
				sessions,
			};
			return tableRowData;
		});

		return await Promise.all(mappedStatistics);
	}

	async getCourseSessions(data: ICourseMotifStatisticsParams, userId?: number) {
		const response = userId
			? await AnalyticsApi.getUserCourseMotifsData(userId, data)
			: await AnalyticsApi.getCourseMotifsData(data);
		return response.data.data.records;
	}

	async getMyLearningPathData(data?: IPagingParams, userId?: number) {
		this.isLoading = true;
		const initialParams: IPagingParams = {
			take: 40,
		};

		const learningPathItemsResponse = userId
			? await AnalyticsApi.getUserLearningPathData(data ?? initialParams, userId)
			: await AnalyticsApi.getMyLearningPathData(data ?? initialParams);
		const transitionSources = await AnalyticsApi.getTransitionSources();
		try {
			runInAction(() => {
				this.myLearningPathItems = learningPathItemsResponse.data.data.records;
				this.transitionSources = transitionSources.data.data;
			});
			return learningPathItemsResponse.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getEngagementScore(data?: IEngagementRequestModel, userId?: number) {
		this.isLoading = true;
		const initialRequest: IEngagementRequestModel = {
			timeFilterType: TimeFilterTypes.OverAll,
		};
		const response = userId
			? await AnalyticsApi.getUserEngagementScore(userId, data ?? initialRequest)
			: await AnalyticsApi.getEngagementScore(data ?? initialRequest);
		try {
			runInAction(() => (this.engagementScore = response.data.data.percent));
			return response.data.data.percent;
		} finally {
			this.isLoading = false;
		}
	}

	async getInteractions(userId?: number) {
		this.isLoading = true;
		const response = userId ? await AnalyticsApi.getUserInteractions(userId) : await AnalyticsApi.getInteractions();
		try {
			runInAction(() => (this.interactions = response.data.data));
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get isLoading() {
		return this._isLoading;
	}

	set isSessionsLoading(isLoading: boolean) {
		this._isSessionsLoading = isLoading;
	}

	get isSessionsLoading() {
		return this._isSessionsLoading;
	}

	set transitionSources(sources: ITransitionSourceModel[]) {
		this._transitionSources = sources;
	}

	get transitionSources() {
		return this._transitionSources;
	}

	clearStore() {
		runInAction(() => {
			this.commentsCount = 0;
			this.likesCount = 0;
			this.podcastsCount = 0;
			this.textsCount = 0;
			this.videosCount = 0;
			this.z1Score = 0;
			this.engagementScore = 0;
			this.analyticsSubjects = [];
			this.motifsMeterItems = [];
			this.motifsSessionsItems = [];
			this.tableData = [];
			this.allMyMotifsCount = 0;
			this.allOthersMotifsCount = 0;
			this.watchingTime = 0;
			this.listenedTime = 0;
			this.readTime = 0;
			this.scormTime = 0;
			this.interactions = [];
		});
	}
}
