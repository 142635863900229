import Api, { ApiBase } from "api/utils/api";
import { AxiosResponse } from "axios";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import { ICreateAssessmentModel } from "models/dto/ZoomiLxp/Models/Assessments/ICreateAssessmentModel";
import { IRequestModel } from "models/dto/ZoomiLxp/Models/Common/IRequestModel";
import { IAssessmentModel } from "models/dto/ZoomiLxp/Models/Assessments/IAssessmentModel";
import { ICreateAssessmentAttemptModel } from "models/dto/ZoomiLxp/Models/Assessments/ICreateAssessmentAttemptModel";
import { ICreateAssessmentAttemptResponse } from "models/dto/ZoomiLxp/Models/Assessments/ICreateAssessmentAttemptResponse";
import { IUpdateAssessmentModel } from "models/dto/ZoomiLxp/Models/Assessments/IUpdateAssessmentModel";
import { INextQuestionModel } from "models/dto/ZoomiLxp/Models/Assessments/INextQuestionModel";
import { ISaveAnswerRequest } from "../../models/dto/ZoomiLxp/Models/Assessments/ISaveAnswerRequest";
import { IFinishAssessmentAttemptRequest } from "models/dto/ZoomiLxp/Models/Assessments/IFinishAssessmentAttemptRequest";
import { IFinishAssessmentAttemptResponse } from "models/dto/ZoomiLxp/Models/Assessments/IFinishAssessmentAttemptResponse";
import { IAssessmentResultModel } from "models/dto/ZoomiLxp/Models/Assessments/IAssessmentResultModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { IImportAssessmentResultsModel } from "models/dto/ZoomiLxp/Models/Assessments/IImportAssessmentResultsModel";
import { IGetLastAssessmentAttemptRequest } from "models/dto/ZoomiLxp/Models/Assessments/IGetLastAssessmentAttemptRequest";
import { IGetLastAssessmentAttemptResponse } from "models/dto/ZoomiLxp/Models/Assessments/IGetLastAssessmentAttemptResponse";
import { ISaveAnswerResponse } from "models/dto/ZoomiLxp/Models/Assessments/ISaveAnswerResponse";

export class AssessmentsApi {
	static createQuiz(data: ICreateAssessmentModel) {
		return ApiBase.post<IRequestModel<ICreateAssessmentModel>, AxiosResponse<IResponseModel<IAssessmentModel>>>(
			"/Assessments/Assessment",
			{
				params: data,
			} as IRequestModel<ICreateAssessmentModel>
		);
	}

	static createAttempt(assessmentId: number) {
		return ApiBase.post<
			IRequestModel<ICreateAssessmentAttemptModel>,
			AxiosResponse<IResponseModel<ICreateAssessmentAttemptResponse>>
		>("/Assessments/attempt", {
			params: { assessmentId } as ICreateAssessmentAttemptModel,
		} as IRequestModel<ICreateAssessmentAttemptModel>);
	}

	static getAssessment(AssessmentId: number) {
		return ApiBase.get<IResponseModel<IAssessmentModel>>(`/Assessments/Assessment/${AssessmentId}`);
	}

	static getCoursesWithQuizResults(data: IPagingParams) {
		return Api.post<IGetRecordsResponse<IPreviewCourseModel>, IPagingParams>(
			"/Assessments/CoursesWithAssessments/",
			data
		);
	}

	static getUserCoursesWithQuizResults(data: IPagingParams, userId: number) {
		return Api.post<IGetRecordsResponse<IPreviewCourseModel>, IPagingParams>(
			`/Assessments/CoursesWithAssessments/${userId}`,
			data
		);
	}

	static getQuizResults(courseId: number, data: IPagingParams) {
		return Api.post<IGetRecordsResponse<IAssessmentResultModel>, IPagingParams>(
			`/Assessments/AssessmentResults/${courseId}`,
			data
		);
	}

	static getUserQuizResults(userId: number, courseId: number, data: IPagingParams) {
		return Api.post<IGetRecordsResponse<IAssessmentResultModel>, IPagingParams>(
			`/Assessments/UserAssessmentResults/${courseId}/${userId}`,
			data
		);
	}

	static exportQuiz(quizId: number) {
		return Api.post(
			`/Assessments/export/${quizId}`,
			{},
			{
				responseType: "blob",
			}
		);
	}

	static importQuiz(fileId: number) {
		return Api.post<IImportAssessmentResultsModel>(`/Assessments/import/${fileId}`);
	}

	static changeQuiz(assessmentId: number, data: IUpdateAssessmentModel) {
		return ApiBase.put<IRequestModel<IUpdateAssessmentModel>, AxiosResponse<IResponseModel<IAssessmentModel>>>(
			`/Assessments/Assessment/${assessmentId}`,
			{
				params: data,
			} as IRequestModel<IUpdateAssessmentModel>
		);
	}

	static nextQuestion(attemptId: number) {
		const query = `/Assessments/nextquestion/${attemptId}`;
		return ApiBase.get<IResponseModel<INextQuestionModel>>(query);
	}

	static saveAnswer(data: ISaveAnswerRequest) {
		return ApiBase.post<IRequestModel<ISaveAnswerRequest>, AxiosResponse<IResponseModel<ISaveAnswerResponse>>>(
			"/Assessments/SaveAnswer",
			{
				params: data,
			} as IRequestModel<ISaveAnswerRequest>
		);
	}

	static finishAttempt(attemptId: number) {
		return ApiBase.post<
			IRequestModel<IFinishAssessmentAttemptRequest>,
			AxiosResponse<IResponseModel<IFinishAssessmentAttemptResponse>>
		>("/Assessments/FinishAttempt", {
			params: { attemptId } as IFinishAssessmentAttemptRequest,
		} as IRequestModel<IFinishAssessmentAttemptRequest>);
	}

	static getLastAttempt(data: IGetLastAssessmentAttemptRequest) {
		return Api.post<IGetLastAssessmentAttemptResponse>("/Assessments/GetLastAttempt", data);
	}
}
