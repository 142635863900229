import { observer } from "mobx-react";
import PrivateLayout from "layouts/private/private";
import styles from "./styles.module.scss";
import { ReactComponent as SuccessIcon } from "assets/icons/ic_check.svg";
import { generatePath, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useEffect } from "react";
import { useAppStore } from "store";
import { Routes } from "routes";
import Banner from "components/base/banner/banner";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";

export const PaymentSuccessPage = observer(function PaymentSuccessPage() {
	const match = useRouteMatch<{ sessionId: string }>();
	const location = useLocation();
	const paymentSessionId = match.params.sessionId ?? location.search.split("?SessionId=")[1];

	const { billingStore, settingsStore } = useAppStore();
	const history = useHistory();

	useEffect(() => {
		if (paymentSessionId && paymentSessionId.length) {
			billingStore
				.getCourseIdByPaymentSession(paymentSessionId)
				.then((response) => history.push(generatePath(Routes.MyDeskLesson, { courseId: response })));
		}
	}, [paymentSessionId, billingStore, history]);

	return (
		<PrivateLayout>
			<div className={styles.payment_result_page}>
				<Banner
					bannerContentType={settingsStore.publicSettings.Images?.[SiteSettingsKeys.HomeBannerContentType]}
					bannerSrc={settingsStore.publicSettings.Images?.[SiteSettingsKeys.HomeBanner]}
				/>

				<div className={styles.payment_result_page__content}>
					<SuccessIcon className={styles.payment_result_page__image} />
					<span className={styles.payment_result_page__text}>Payment was successful</span>
				</div>
			</div>
		</PrivateLayout>
	);
});
