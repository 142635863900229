import { useEffect } from "react";
import RecommendationsNotice from "./components/recommendations-notice/recommendations-notice";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import RecommendationsList from "./components/recommendations-list/recommendations-list";
interface RecommendationsProps {
	className?: string;
}

const Recommendations = ({ className }: RecommendationsProps) => {
	const { recommendationsStore, contentStore, toasterStore } = useAppStore();

	useEffect(() => {
		if (contentStore.sessionId) {
			recommendationsStore
				.getRecommendations(contentStore.sessionId)
				.catch((err) => toasterStore.showErrorMessage(err));
		}
	}, [contentStore.sessionId, toasterStore, recommendationsStore]);

	useEffect(() => {
		return () => {
			recommendationsStore.clear();
		};
	}, [recommendationsStore]);

	return (
		<>
			<RecommendationsNotice className={className} />
			<RecommendationsList className={className} />
		</>
	);
};

export default observer(Recommendations);
