import { memo } from "react";
import styles from "./styles.module.scss";
import Checkbox from "components/base/checkbox/checkbox";
import classNames from "classnames";

function CheckBoxInfo() {
	return (
		<div className={styles.popup__checkbox}>
			<p className={styles.popup__checkbox_info}>
				<Checkbox className={classNames(styles.course_card_assignments__checkbox)} theme="assignments" />{" "}
				<span className={styles.popup__checkbox_text}>Not assigned</span>
			</p>
			<p className={styles.popup__checkbox_info}>
				<Checkbox
					className={classNames(
						styles.course_card_assignments__checkbox,
						styles.course_card_assignments__checkbox_requested
					)}
					theme="assignments"
				/>
				<span className={styles.popup__checkbox_text}>Asked to assign</span>
			</p>
			<p className={styles.popup__checkbox_info}>
				<Checkbox
					value={true}
					className={classNames(
						styles.course_card_assignments__checkbox,
						styles.course_card_assignments__checkbox_requested
					)}
					theme="assignments"
				/>
				<span className={styles.popup__checkbox_text}>Assigned</span>
			</p>
			<p className={styles.popup__checkbox_info}>
				<Checkbox className={classNames(styles.course_card_assignments__checkbox)} value={true} theme="locked" />
				<span className={styles.popup__checkbox_text}>Access available</span>
			</p>
		</div>
	);
}

export default memo(CheckBoxInfo);
