import { useEffect, useState } from "react";
import { NavLink, generatePath, useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import FilterMenu from "components/partial/filters/filter-menu/filter-menu";
import SelectFilter from "components/partial/filters/select-filter/select-filter";
import DateFilter from "components/partial/filters/date-filter/date-filter";
import StatusFilter from "components/partial/filters/status-filter/status-filter";
import SearchFilter from "components/partial/search-filter/search-filter";
import { ReactComponent as IconAdd } from "assets/icons/ic_plus.svg";
import { useAppStore } from "store";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { IOptionsItem, getAuthorsOptions, getSubjectOptions } from "helpers/select.helper";
import { Routes } from "routes";
import BulkOperationsMenu from "components/partial/bulk-operations-menu/bulk-operations-menu";
import { SearchStateConstants } from "constants/constants";
import { useSearchStore } from "hooks/useSearchStore";

interface IThreadsFiltersOptions {
	subjects?: IOptionsItem[];
	authors?: IOptionsItem[];
}

export function CmsThreadsFilter() {
	const history = useHistory();
	const { peersStore, bulkOperationsStore } = useAppStore();
	const [filtersOptions, setFilterOptions] = useState<IThreadsFiltersOptions>({} as IThreadsFiltersOptions);

	useEffect(() => {
		peersStore.getSubjects().then((subjects) => {
			const subjectsOptions = getSubjectOptions(subjects);
			setFilterOptions((prevState) => ({ ...prevState, subjects: subjectsOptions }));
		});
		peersStore.getAuthors().then((authors) => {
			const authorsOptions = getAuthorsOptions(authors);
			setFilterOptions((prevState) => ({ ...prevState, authors: authorsOptions }));
		});
	}, [peersStore]);
	const initParams: IQueryParams = bulkOperationsStore.getThreadInitParams();

	const { setParams } = useSearchStore(initParams, SearchStateConstants.threads);

	return (
		<>
			<div className={styles.cms_threads__header}>
				<div className={styles.cms_threads__title}>Threads</div>
				<BulkOperationsMenu menuType="threads" className={styles.cms_threads__bulk} />
				<div className={styles.cms_threads__filters_group}>
					<FilterMenu params={peersStore.threadsSearchParams} setParams={setParams} initParams={initParams}>
						<SelectFilter
							params={peersStore.threadsSearchParams}
							setParams={setParams}
							label="Author"
							options={filtersOptions.authors ?? []}
							propertyName="AccountCreatedId"
						/>
						<SelectFilter
							params={peersStore.threadsSearchParams}
							setParams={setParams}
							label="Subject"
							options={filtersOptions.subjects ?? []}
							propertyName="SubjectId"
						/>
						<DateFilter
							className={styles.cms_threads__date_filter}
							params={peersStore.threadsSearchParams}
							setParams={setParams}
							propertyName="UpdatedUtc"
						/>
						<StatusFilter params={peersStore.threadsSearchParams} setParams={setParams} />
					</FilterMenu>
					<SearchFilter
						params={peersStore.threadsSearchParams}
						setParams={setParams}
						classNameInput={styles.cms_threads__search}
					/>
				</div>
				<NavLink className={styles.btn_add} to={Routes.CmsAddThread}>
					Add thread
				</NavLink>
				<button
					className={styles.cms_threads__btn_add_mobile}
					onClick={() => history.push(generatePath(Routes.CmsAddThread))}
				>
					<IconAdd className={styles.cms_threads__icon} />
				</button>
			</div>
		</>
	);
}
