import { ICourseItemNavigation } from "models/dto/ZoomiLxp/Models/Courses/ICourseItemNavigation";
import { DELAY_TIME } from "constants/constants";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";

export interface IPlayerPropsBase {
	className?: string;
	onEventHandle: (event: ISaveEventCourseItemHistoryModel) => Promise<void>;
}

export const scrollByPixels = (boxRef: React.RefObject<HTMLDivElement>, lastWatchedCourse: ICourseItemNavigation) => {
	if (lastWatchedCourse.posY || lastWatchedCourse.posX) {
		setTimeout(() => {
			boxRef?.current?.scrollTo(lastWatchedCourse.posX ?? 0, lastWatchedCourse.posY ?? 0);
		}, DELAY_TIME);
	}
};
