//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ScheduleNotificationTypes {
	Once = 0,
	Weekly = 1,
	Monthly = 2,
	Yearly = 3
}
