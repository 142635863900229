import { useAppStore } from "store";
import { BulkOperationType } from "models/dto/ZoomiLxp/Utilities/Enumerations/BulkOperationType";
import { Routes } from "routes";
import { matchPath } from "react-router-dom";
import { PermissionConstantsCreateOrUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsCreateOrUpdate";
import { PermissionConstantsUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsUpdate";
import { useMemo } from "react";

export const useReindexArgument = (pathname: string) => {
	const { usersStore } = useAppStore();
	const isCoursesAllowed = usersStore.checkOneOfUserPermissions([
		PermissionConstantsCreateOrUpdate.CoursesWithAnyCreator,
	]);
	const isThreadsAllowed = usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.AllThreads]);
	const isUsersAllowed = usersStore.checkOneOfUserPermissions([PermissionConstantsCreateOrUpdate.AllAccounts]);
	const isCompaniesAllowed = usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.AnyCompanies]);

	const argument: BulkOperationType = useMemo(() => {
		if (!!matchPath(pathname, Routes.CmsCourses) && isCoursesAllowed) return BulkOperationType.ReindexCourses;
		if (!!matchPath(pathname, Routes.CmsThreads) && isThreadsAllowed) return BulkOperationType.ReindexThreads;
		if (!!matchPath(pathname, Routes.CmsCompanies) && isCompaniesAllowed) return BulkOperationType.ReindexCompanies;
		if (!!matchPath(pathname, Routes.CmsUsers) && isUsersAllowed) return BulkOperationType.ReindexAccounts;
		return BulkOperationType.ReindexCourses;
	}, [isCoursesAllowed, isThreadsAllowed, isCompaniesAllowed, isUsersAllowed, pathname]);
	return { argument };
};
