//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum UploadFileTypes {
	UserThumbnail = 0,
	CompanyThumbnail = 1,
	Content = 2,
	ThreadThumbnail = 3,
	ThreadPicture = 4,
	PostPicture = 5,
	CourseThumbnail = 6,
	ChapterThumbnail = 7,
	Logo = 8,
	Favicon = 9,
	HomeBanner = 10,
	PeersBanner = 11,
	InfluencersBanner = 12,
	QuizImport = 13,
	UsersImport = 14,
	QuizThumbnail = 15,
	Wiki = 16,
	Certificates = 17
}
