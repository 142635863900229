import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { Page } from "react-pdf/dist/esm/entry.webpack";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import { useAppStore } from "store";
import { observer } from "mobx-react";

interface PagePdfProps {
	pageNumber: number;
	setPageVisibility: (pageNumber: number, isIntersecting: boolean) => void;
	boxRef?: React.RefObject<HTMLDivElement>;
	className?: string;
}

const PagePdf = ({ pageNumber, setPageVisibility, boxRef, className }: PagePdfProps) => {
	const { playerStore } = useAppStore();
	const [page, setPage] = useState<React.LegacyRef<HTMLCanvasElement> | undefined>(undefined);

	const observerConfig = {
		root: boxRef?.current ?? null,
		threshold: 0.3,
	} as IntersectionObserverInit;

	const onIntersectionChange = useCallback(
		([entry]) => {
			setPageVisibility(pageNumber, entry.isIntersecting);
		},
		[pageNumber, setPageVisibility]
	);

	useIntersectionObserver(page, observerConfig, onIntersectionChange);
	useEffect(() => {
		playerStore.zoomMultiplier = 1;
		// eslint-disable-next-line
	}, []);

	return (
		<Page
			canvasRef={setPage as React.LegacyRef<HTMLCanvasElement>}
			pageNumber={pageNumber}
			className={classNames(styles.page_pdf, className)}
			renderTextLayer={false}
			onLoadError={(e) => console.error("PDFLoadingError:", e)}
			scale={playerStore.zoomMultiplier + 0.3}
		/>
	);
};

export default observer(PagePdf);
