import { ICategorySubjectItem, getCategoriesWithSubjects } from "helpers/select.helper";
import { ISubjectModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ISubjectModel";
import { useMemo } from "react";
import { useAppStore } from "store";

export const useCategorySubjects = () => {
	const { taxonomyStore } = useAppStore();

	const courseCategorySubjects = useMemo<ICategorySubjectItem[]>(
		() => getCategoriesWithSubjects(taxonomyStore.taxonomyData.categories),
		[taxonomyStore.taxonomyData.categories]
	);

	const getCategorySubjectColor = (subject: ISubjectModel) => {
		const categorySubject = courseCategorySubjects.find((item: ICategorySubjectItem) => item.value === subject.id);
		return { color: categorySubject?.categoryColor ?? "", name: categorySubject?.label ?? "" };
	};

	const getMappedSubjects = (subject: ISubjectModel, values: ISubjectModel[]) => {
		const mappedArray = values?.map((subj: ISubjectModel) => {
			if (subj.id !== subject.id) {
				subj.isMain = false;
			}
			return subj;
		});
		return mappedArray;
	};

	return { courseCategorySubjects, getCategorySubjectColor, getMappedSubjects };
};
