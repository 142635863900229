import toast from "react-hot-toast";
import { makeAutoObservable } from "mobx";
import { isApiError, isBadRequest } from "api/utils/api";
import { IBadRequestModel } from "models/dto/ZoomiLxp/Models/Common/IBadRequestModel";
import { IBadRequestValidationFailedModel } from "models/dto/ZoomiLxp/Models/Common/IBadRequestValidationFailedModel";


export class ToasterStore {

	constructor() {
		makeAutoObservable(this);
	}

	private isApiBadRequestModel = (obj: any): obj is IBadRequestValidationFailedModel => {
		return "errorMessage" in obj && "title" in obj && "errorMessages" in obj;
	};

	private isApiValidationFailedModel = (obj: any): obj is IBadRequestValidationFailedModel => {
		return obj && this.isApiBadRequestModel(obj) && "validationFailures" in obj;
	};
	//Move error logic back to error helper
	showErrorMessage = (err: unknown, options = {}) => {
		let errorMessage: string | null | undefined = null;
		if (typeof err === "string") {
			errorMessage = err;
		} else if (isApiError<IBadRequestModel | IBadRequestValidationFailedModel>(err)) {
			const data = err.response?.data.data;
			const status = err.response?.status;

			if (status === 401) {
				//According to Business rules we do not need to show to user any messages when session/token expired.
				return;
			}
			if (this.isApiValidationFailedModel(data)) {
				errorMessage = data?.validationFailures?.map((e) => e.errorMessage)?.join(", ");
			}
			if (!errorMessage) {
				if (isBadRequest(err)) {
					errorMessage = err.response?.data.data?.errorMessage;
				} else {
					errorMessage = data?.errorMessages?.join(", ");
				}
			}
			if (!errorMessage) {
				errorMessage = err.message;
			}
		} else if(err instanceof TypeError) {
			return;
		}

		const notHandledCorrectly: boolean = !errorMessage;
		if (!errorMessage) {
			errorMessage = String(err);
		}

		toast.error(errorMessage, options);
		if (notHandledCorrectly) {
			throw err;
		}
	};

	showSuccessMessage = (message: string, options = {}) => {
		toast.success(message, options);
	}

}
