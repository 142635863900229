import { ReactComponent as InfoIcon } from "assets/icons/ic_info.svg";
import { ReactComponent as SpinnerIcon } from "assets/icons/ic_spinner.svg";
import { ReactComponent as Head } from "assets/images/brain.svg";
import { ReactComponent as LightningSquare } from "assets/icons/ic_lightning_square.svg";
import { ReactComponent as Sleep } from "assets/icons/ic_sleep.svg";

import classNames from "classnames";
import { useAppStore } from "store";
import Tooltip from "components/base/tooltip/tooltip";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { engagementPercentageCircleStyles, percentageCircleStyles } from "../data";
import styles from "../styles.module.scss";

interface IHeadChartProps {
	title: string;
	value: number;
	type: "z1" | "engagement";
	tooltipText?: string;
}

const HeadChart = ({ title, value, tooltipText, type }: IHeadChartProps) => {
	const { analyticsStore } = useAppStore();

	return (
		<div className={classNames(styles.tile, styles.analytics_page__head_box)}>
			<div className={styles.analytics_page__chart_top_box}>
				{title}
				{analyticsStore.isLoading ? (
					<div className={styles.analytics_page__spinner_box}>
						<SpinnerIcon className={styles.analytics_page__loading_spinner} />
					</div>
				) : (
					<Tooltip label={tooltipText} className={styles.analytics_page__info_tooltip}>
						<InfoIcon className={styles.analytics_page__info_icon} />
					</Tooltip>
				)}
			</div>
			<div className={styles.analytics_page__percentage}>
				{value}
				<span className={styles.analytics_page__percentage_sign}>%</span>
			</div>
			<div className={styles.analytics_page__percentage_box}>
				<CircularProgressbarWithChildren
					value={value}
					background
					backgroundPadding={1}
					styles={buildStyles(type === "z1" ? percentageCircleStyles : engagementPercentageCircleStyles)}
					className={styles.percentage_circle}
					circleRatio={0.75}
					strokeWidth={6}
				>
					{type === "z1" ? (
						<LightningSquare className={styles.analytics_page__percentage_icon} />
					) : (
						<Sleep className={styles.analytics_page__percentage_icon} />
					)}
					<Head className={styles.analytics_page__percentage_head} />
				</CircularProgressbarWithChildren>
			</div>
		</div>
	);
};
export default HeadChart;
