import Api, { ApiBase } from "api/utils/api";
import { ICompanyModel } from "models/dto/ZoomiLxp/Models/CompanyModels/ICompanyModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { AxiosResponse } from "axios";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";

export class CompaniesApi {
	static getAllCompanies(data: IQueryParams) {
		return ApiBase.post<IQueryParams, AxiosResponse<IResponseModel<IGetRecordsResponse<ICompanyModel>>>>(
			"/company/GetAll",
			data
		);
	}

	static getCompanyById(companyId: number) {
		return Api.get<ICompanyModel>(`/company/getCompanyById/${companyId}`);
	}

	static addNewCompany(data: ICompanyModel) {
		return Api.post<ICompanyModel>("/company/addCompany", data);
	}

	static updateCompany(data: ICompanyModel) {
		return Api.post("/company/updateCompany", data);
	}
}
