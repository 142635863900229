import React from "react";
import styles from "./styles.module.scss";
import { CmsTileProps } from "components/partial/cms/cms-table/cms-table";
import isEmpty from "lodash/isEmpty";
import noImage from "assets/images/no-image.png";
import classNames from "classnames";
import { DateTime } from "luxon";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";

const cx = classNames.bind(styles);

const CmsTileThread: React.FunctionComponent<CmsTileProps<IThreadModel>> = (props: CmsTileProps<IThreadModel>) => {
	const { item: thread, onClick } = props;
	const { thumbnail, isActive, title, created } = thread;

	return (
		<div className={styles.cms_tile_thread} onClick={() => onClick && onClick(thread)}>
			<div className={styles.cms_tile_thread__cell_picture}>
				<img
					className={styles.cms_tile_thread__cell_image}
					src={isEmpty(thumbnail?.url) ? noImage : thumbnail?.url}
					alt={title}
				/>
			</div>
			<div className={styles.cms_tile_thread__wrap}>
				<div className={styles.cms_tile_thread__cell_title}>{title}</div>
				<div className={styles.cms_tile_thread__inner}>
					<div
						className={cx(styles.cms_tile_thread__status, {
							[styles.cms_tile_thread__status_success]: isActive,
						})}
					>
						{isActive ? "Active" : "InActive"}
					</div>
					<div className={styles.cms_tile_thread__date}>
						{!isEmpty(created) && DateTime.fromISO(created).setLocale("en").toLocaleString(DateTime.DATE_MED)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CmsTileThread;
