//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum EmailSourceMessage {
	AssignToMeMessage = 0,
	BecomeCompanyLearnerMessage = 1,
	BecomeCompanyManagerMessage = 2,
	BecomeFreeLearnerMessage = 3,
	BecomeInfluencerMessage = 4,
	BecomeMasterAdminMessage = 5,
	ConfirmationCodeEmailMessage = 6,
	CourseAssignedMessage = 7,
	InvitationToSwitchCompany = 8,
	InvitationToCompany = 9,
	ResetPasswordEmailMessage = 10,
	SomeoneReactedOnYourComment = 11,
	SomeoneFollowedToYou = 12,
	CourseLiked = 13,
	SomethingRated = 14,
	NewMessageReceived = 15,
	SendMessageImmediately = 16,
	ScheduleSendMessage = 17,
	BecomeTenantAdminMessage = 18,
	ThreadLiked = 19,
	CommentLiked = 20,
	AccountLiked = 21,
	GroupsAssigned = 22
}
