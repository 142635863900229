import styles from "./styles.module.scss";
import classNames from "classnames";
import { FieldArray, FieldArrayRenderProps, Form, Formik } from "formik";
import RecipientsSelector from "components/partial/notifications/recipients-selector/recipients-selector";
import { useAppStore } from "store";
import { useEffect, useMemo } from "react";
import { IOptionsItem } from "helpers/select.helper";
import { getChangedGroup } from "helpers/groups.helper";
import { GroupAssignmentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/GroupAssignmentTypes";
import { useGroupsAssignmentsList } from "hooks/useGroups";
import GroupsList from "components/partial/groups-list/groups-list";
import { BulkOperationType } from "models/dto/ZoomiLxp/Utilities/Enumerations/BulkOperationType";

interface IBulkAssignmentFormProps {
	className?: string;
}

const BulkAssignmentForm = ({ className }: IBulkAssignmentFormProps) => {
	const { bulkOperationsStore, usersStore, groupStore } = useAppStore();
	const { handleGroupAssignment, checkIsGroupAlreadyInBulkList, removeGroupFromBulkList } = useGroupsAssignmentsList();
	const isBulkAssign = bulkOperationsStore.bulkOperationType === BulkOperationType.BulkGroupAssignment;

	useEffect(() => {
		usersStore.getPermissionsGroups();
		return () => {
			if (bulkOperationsStore.isUsersBulkGroup) {
				groupStore.clearGroupsAssignPopupData();
			}
		};
	}, [usersStore, groupStore, bulkOperationsStore.isUsersBulkGroup]);

	const placeholder = useMemo(() => {
		let text = "Tap here to start searching";
		if (bulkOperationsStore.isCoursesOrThreadsBulkGroup) {
			text = "Groups (typesearch and text)";
			return text;
		}
		if (bulkOperationsStore.isUsersBulkGroup) {
			text = "Enter group name";
		}
		return text;
	}, [bulkOperationsStore.isCoursesOrThreadsBulkGroup, bulkOperationsStore.isUsersBulkGroup]);

	const onUserGroupChangeHandler = (item: IOptionsItem, isRemoved?: boolean) => {
		const changedGroup = getChangedGroup(
			item,
			isBulkAssign ? GroupAssignmentTypes.Assign : GroupAssignmentTypes.Unassign
		);

		const alreadyInList = checkIsGroupAlreadyInBulkList(changedGroup, bulkOperationsStore.bulkOperationType);

		if (alreadyInList && !isRemoved) {
			return;
		}

		if (isRemoved) {
			removeGroupFromBulkList(changedGroup, bulkOperationsStore.bulkOperationType);
			return;
		}

		handleGroupAssignment(changedGroup, bulkOperationsStore.bulkOperationType);
	};

	return (
		<Formik initialValues={{ recipients: [] }} onSubmit={() => {}} validateOnChange={false} enableReinitialize={true}>
			{() => (
				<Form>
					<div className={classNames(styles.bulk_assignment_popup__recipients, className)}>
						<FieldArray
							name="recipients"
							component={(arrayProps) =>
								RecipientsSelector({
									...(arrayProps as FieldArrayRenderProps),
									isBulkOperation: true,
									placeholder,
									isInternalEmailsOnly: true,
									hideOpenIndicator: true,
									onChangeHandler: bulkOperationsStore.isUsersBulkGroup ? onUserGroupChangeHandler : null,
									onRemoveItemHandler: (item: IOptionsItem) =>
										bulkOperationsStore.isUsersBulkGroup ? onUserGroupChangeHandler(item, true) : null,
								})
							}
						/>
					</div>
					{bulkOperationsStore.isUsersBulkGroup && (
						<div className={styles.assign_groups_popup__content_container}>
							<GroupsList
								groups={isBulkAssign ? groupStore.addedGroupsAssignmentData : groupStore.removedGroupsAssignmentData}
								title={isBulkAssign ? "Assign courses from next groups:" : "Unassign courses from next groups:"}
							/>
						</div>
					)}
				</Form>
			)}
		</Formik>
	);
};

export default BulkAssignmentForm;
