import { useCallback } from "react";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { useAppStore } from "store";

export const useQuiz = () => {
	const { quizStore, contentStore, toasterStore } = useAppStore();

	const startQuiz = useCallback(
		(assessmentId: number, isTryAgain: boolean = false) => {
			quizStore.isLoading = true;
			if (contentStore?.sessionId) {
				if (isTryAgain) {
					quizStore.clearStore(isTryAgain);
					return quizStore.createAttempt(assessmentId).finally(() => (quizStore.isLoading = false));
				}
				return quizStore
					.getLastAttempt(assessmentId)
					.then(() => {
						const data: ISaveEventCourseItemHistoryModel = {
							sessionId: contentStore?.sessionId ?? 0,
							eventType: EventTypes.Start,
							payload: "",
						};
						return Promise.all([quizStore.getQuiz(assessmentId), contentStore.sendEventCourseItemHistory(data)]);
					})
					.catch((err) => toasterStore.showErrorMessage(err))
					.finally(() => {
						quizStore.isLoading = false;
					});
			}
		},
		// eslint-disable-next-line
		[toasterStore, contentStore, contentStore.sessionId, quizStore]
	);

	return { startQuiz };
};
