//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FileTypes {
	PDF = 1,
	Thumbnail = 2,
	Medium = 3,
	SCORM = 4,
	SpreadSheet = 5,
	Original = 6
}
