import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import { SortDirection } from "models/dto/ZoomiLxp/Utilities/Enumerations/SortDirection";

export const getInitParams = (pageSize: number = 100): IQueryParams => {
	return {
		queryString: "",
		take: pageSize,
		skip: 0,
		filterCriteria: [],
		sortCriteria: [],
	};
};

export const fistLastNameSort = [
	{
		order: 1,
		propertyName: "firstName",
		direction: SortDirection.Ascending,
	},
	{
		order: 2,
		propertyName: "lastName",
		direction: SortDirection.Ascending,
	},
];

export const getSearchInitParams = (pageSize?: number): IQueryParams => {
	return {
		...getInitParams(pageSize),
		filterCriteria: [
			{
				argument: true,
				function: 2,
				propertyNames: ["IsActive"],
			},
		],
	};
};

export class ThreadFilter {
	static tagId = (tagId?: number) => ({
		propertyNames: ["Tags.Id"],
		function: FilterFunction.In,
		argument: tagId,
	});
	static courseItemId = {
		propertyNames: ["CourseItemId"],
		function: FilterFunction.IsNull,
	};
	static accountCreatedId = (currentUserId: number) => ({
		propertyNames: ["AccountCreatedId"],
		function: FilterFunction.Equal,
		argument: currentUserId,
	});
	static creatorId = (currentUserId: number) => ({
		propertyNames: ["CreatorId"],
		function: FilterFunction.Equal,
		argument: currentUserId,
	});
}

export class CourseFilter {
	static tagId = (tagId?: number) => ({
		propertyNames: ["Tags.Id"],
		function: FilterFunction.In,
		argument: tagId,
	});
	static creatorId = (currentUserId: number) => ({
		propertyNames: ["CreatorId"],
		function: FilterFunction.Equal,
		argument: currentUserId,
	});
}

export class UserFilter {
	static groupsId = (groupId: number) => ({
		propertyNames: ["Groups.Id"],
		function: FilterFunction.In,
		argument: groupId,
	});
}
