import { useRef } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { ReactComponent as OpenSubmenuButton } from "assets/icons/ic_open_submenu.svg";
import { ReactComponent as CloseSubmenuButton } from "assets/icons/ic_close_submenu.svg";
import { ReactComponent as HistoryIcon } from "assets/icons/ic_history_button.svg";
import { ReactComponent as AnalyticsIcon } from "assets/icons/ic_analytics_button.svg";
import { ReactComponent as GoalsIcon } from "assets/icons/ic_goal_button.svg";
import { ReactComponent as QuizResultsIcon } from "assets/icons/ic_type_quiz.svg";
import { ReactComponent as NotesIcon } from "assets/icons/ic_sticky_note.svg";
import { NavLink } from "react-router-dom";
import { useDetectOutsideClick } from "hooks/useDetectOutsideClick";
import { Routes } from "routes";
import { useAppStore } from "store";

const CircleSubmenu = () => {
	const { playerStore } = useAppStore();
	const submenuRef = useRef<HTMLDivElement>(null);
	const { isActive, setIsActive } = useDetectOutsideClick(submenuRef, false);
	const openCloseSubmenu = () => setIsActive(!isActive);

	return (
		<>
			<div
				className={classNames(styles.submenu, styles.submenu__icon, styles.submenu__cursor_on, {
					[styles.submenu__empty]: isActive,
					[styles.submenu__blur]: playerStore.params?.playing,
				})}
				onClick={openCloseSubmenu}
			>
				<OpenSubmenuButton />
			</div>
			<div ref={submenuRef} className={classNames(styles.submenu__open, { [styles.submenu__empty]: !isActive })}>
				<div className={styles.submenu__wrap}>
					<div
						className={classNames(styles.submenu__icon, styles.submenu__cursor_on, styles.submenu__icon_close)}
						onClick={openCloseSubmenu}
					>
						<CloseSubmenuButton />
					</div>

					<div className={styles.submenu__circle_box}>
						<NavLink className={styles.submenu__icon} to={Routes.MyGoals}>
							<GoalsIcon />
						</NavLink>
						<NavLink className={styles.submenu__icon} to={Routes.MyAnalytics}>
							<AnalyticsIcon />
						</NavLink>
						<NavLink className={styles.submenu__icon} to={Routes.MyHistory}>
							<HistoryIcon />
						</NavLink>
						<NavLink className={classNames(styles.submenu__icon, styles.submenu__quiz_icon)} to={Routes.MyQuizResults}>
							<QuizResultsIcon />
						</NavLink>
						<NavLink className={classNames(styles.submenu__icon, styles.submenu__notes_icon)} to={Routes.MyNotes}>
							<NotesIcon />
						</NavLink>
					</div>
				</div>
			</div>
		</>
	);
};

export default CircleSubmenu;
