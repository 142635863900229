import styles from "./styles.module.scss";
import { ReactComponent as BookmarkIcon } from "assets/icons/ic_bookmark.svg";
import React, { MouseEventHandler } from "react";
import classNames from "classnames";

interface ButtonBookmarkProps {
	className?: string;
	isSecondaryType: boolean;
	bookmarked: boolean;
	isBookmarkable: boolean;
	onClick?: MouseEventHandler<SVGSVGElement>;
}

const cx = classNames.bind(styles);
const ButtonBookmark = (props: ButtonBookmarkProps) => {
	const { className, isSecondaryType, bookmarked, isBookmarkable, onClick } = props;
	return (<BookmarkIcon
		className={cx(className, styles.button_bookmark__bookmark_icon, {
			[styles.button_bookmark__bookmark_icon__bookmarkable]: isBookmarkable,
			[styles.button_bookmark__bookmark_icon__bookmarked]: bookmarked,
			[styles.button_bookmark__bookmark_icon__secondary]: isSecondaryType,
			[styles.button_bookmark__bookmark_icon__secondary__bookmarked]: isSecondaryType && bookmarked,
		})}
		onClick={isBookmarkable ? onClick : () => {}}
	/>);
};

export default ButtonBookmark;
