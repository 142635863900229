import Button from "components/base/button/button";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import { DELAY_TIME_LONGER } from "constants/constants";

interface ISearchClearButtonProps {
	setIsClear: (isClear: boolean) => void;
}

const SearchClearButton = (props: ISearchClearButtonProps) => {
	const { setIsClear } = props;
	const { searchStore } = useAppStore();
	const isClearFunction = () => {
		searchStore.clearStoreAll();
		setIsClear(true);
		setTimeout(() => setIsClear(false), DELAY_TIME_LONGER);
	};
	return (
		<Button label="Clear" type="secondary" size="common" onClick={isClearFunction} labelColor="textLayoutHeader" />
	);
};
export default observer(SearchClearButton);
