import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useAppStore } from "store";
import { getAreaOfExpertises, getJobTitles, IOptionsItem, searchStyleSheet } from "helpers/select.helper";
import MultiSelectFilter from "components/partial/filters/select-filter/multi-select-filter";
import { getSearchInitParams } from "helpers/filter.helper";
import SearchClearButton from "./filter-clear-button";
import classNames from "classnames/bind";
import { observer } from "mobx-react";
import isEqual from "lodash/isEqual";
import { useAdvSearch } from "hooks/useAdvSearch";
import isEmpty from "lodash/isEmpty";
import { IDefaultFilter } from "./data";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";

interface IAdvSearchFilterProps extends IDefaultFilter {}

interface IAdvFilterOptions {
	jobTitles?: IOptionsItem[] | { value: string; label: string }[];
	areaOfExpetises?: IOptionsItem[];
}

const cx = classNames.bind(styles);

const AdvSearchInfluencerFilters: FC<IAdvSearchFilterProps> = (props) => {
	const { className } = props;
	const [filtersOptions, setFilterOptions] = useState<IAdvFilterOptions>({} as IAdvFilterOptions);
	const [isClear, setIsClear] = useState(false);
	const { searchStore, toasterStore } = useAppStore();
	const hasFilters = !isEqual(getSearchInitParams(), searchStore.params) || searchStore.influencersResult.length;

	useEffect(() => {
		Promise.allSettled([searchStore.getAreaOfExpertises(), searchStore.getJobTitles()]).then((response) => {
			setFilterOptions((prevState) => ({
				...prevState,
				areaOfExpetises: response[0].status === "fulfilled" ? getAreaOfExpertises(response[0].value) : [],
				jobTitles: response[1].status === "fulfilled" ? getJobTitles(response[1].value) : [],
			}));

			const errors = response
				.filter((result) => result.status === "rejected")
				.map((result) => ("reason" in result ? result.reason : null));
			if (!isEmpty(errors)) toasterStore.showErrorMessage(errors);
		});
	}, [searchStore, toasterStore]);

	const { setParams } = useAdvSearch();

	return (
		<div className={cx(styles.adv_search_filter__row, className)}>
			<MultiSelectFilter
				containerClassName={styles.adv_search_filter__select_container}
				customStyles={searchStyleSheet}
				className={styles.adv_search_filter__col}
				params={searchStore.params}
				setParams={setParams}
				label="Job title"
				options={filtersOptions.jobTitles ?? []}
				propertyName="JobTitle"
				filterFunction={FilterFunction.Equal}
				isClear={isClear}
			/>

			<MultiSelectFilter
				containerClassName={styles.adv_search_filter__select_container}
				customStyles={searchStyleSheet}
				className={styles.adv_search_filter__col}
				params={searchStore.params}
				setParams={setParams}
				label="Area of expertise"
				options={filtersOptions.areaOfExpetises ?? []}
				propertyName="Expertises.Id"
				isClear={isClear}
			/>

			{hasFilters && <SearchClearButton setIsClear={setIsClear} />}
		</div>
	);
};

export default observer(AdvSearchInfluencerFilters);
