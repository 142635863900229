import classNames from "classnames";
import styles from "./styles.module.scss";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import { NavLink, generatePath } from "react-router-dom";
import { Routes } from "routes";
import { TilePlacement } from "./peer-tile";

type PropsType = {
	threadInfo: IThreadModel;
	tilePlacement: string;
	isLight: boolean;
};

export const PeerTileInfo = ({ threadInfo, tilePlacement, isLight }: PropsType) => {
	if (tilePlacement === TilePlacement.horizontal) {
		return (
			<>
				<div
					className={classNames(
						styles.peer_tile__info_container,
						styles[`peer_tile__info_container__${tilePlacement}`]
					)}
				>
					<div
						className={classNames(
							styles.peer_tile__name_container,
							styles[`peer_tile__name_container__${tilePlacement}`]
						)}
					>
						<NavLink
							className={classNames(
								styles.peer_tile__name,
								{
									[styles.peer_tile__name__dark]: isLight,
								},
								styles[`peer_tile__name__${tilePlacement}`]
							)}
							to={generatePath(Routes.PeersThread, { id: threadInfo.id })}
						>
							{threadInfo.title}
						</NavLink>
					</div>
				</div>
				<div
					className={classNames(
						styles.peer_tile__desc_container,
						styles[`peer_tile__desc_container__${tilePlacement}`]
					)}
				>
					<div
						className={classNames(
							styles.peer_tile__desc,
							{
								[styles.peer_tile__desc__dark]: isLight,
							},
							styles[`peer_tile__desc__${tilePlacement}`]
						)}
					>
						{threadInfo.description}
					</div>
				</div>
			</>
		);
	}
	return (
		<div
			className={classNames(styles.peer_tile__info_container, styles[`peer_tile__info_container__${tilePlacement}`])}
		>
			<div
				className={classNames(styles.peer_tile__name_container, styles[`peer_tile__name_container__${tilePlacement}`])}
			>
				<NavLink
					className={classNames(
						styles.peer_tile__name,
						{
							[styles.peer_tile__name__dark]: isLight,
						},
						styles[`peer_tile__name__${tilePlacement}`]
					)}
					to={generatePath(Routes.PeersThread, { id: threadInfo.id })}
				>
					{threadInfo.title}
				</NavLink>
			</div>
			<div
				className={classNames(styles.peer_tile__desc_container, styles[`peer_tile__desc_container__${tilePlacement}`])}
			>
				<div
					className={classNames(
						styles.peer_tile__desc,
						{
							[styles.peer_tile__desc__dark]: isLight,
						},
						styles[`peer_tile__desc__${tilePlacement}`]
					)}
				>
					{threadInfo.description}
				</div>
			</div>
		</div>
	);
};
