import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { IContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentModel";
import classNames from "classnames";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import { ControlParams } from "../player-control/player-control";
import { useAppStore } from "store";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import PagePdf from "./components/page-pdf";
import reduce from "lodash/reduce";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import isEqual from "lodash/isEqual";
import range from "lodash/range";
import findIndex from "lodash/findIndex";
import { useScroll } from "hooks/useScroll";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { ISegmentPositions } from "../player-layout/player-layout";
import { IPlayerPropsBase, scrollByPixels } from "../data/data";

interface IPlayerPdfProps extends IPlayerPropsBase {
	content: IContentModel;
	params: ControlParams;
	setParams: Dispatch<SetStateAction<ControlParams>>;
	children?: ReactNode;
	segment?: ISegmentPositions;
}

export interface dataInfo {
	accountId: number;
	contentId: number;
	courseId: number;
	segmentId: number | null;
	sessionId: number;
}

const PlayerPdf = ({ content, className, params, setParams, segment, children, onEventHandle }: IPlayerPdfProps) => {
	const { currentProgress, boxRef, loading } = params;

	const { contentStore, coursesStore, playerStore, toasterStore } = useAppStore();
	const [pagesActive, setPagesActive] = useState<number[]>([]);
	const [listPages, setListPages] = useState<number[]>([]);
	const [total, setTotal] = useState(0);
	const docRef = useRef<HTMLDivElement>(null);
	const calledOnceComplete = useRef(false);
	const calledOnce = useRef(false);
	const visiblePages = useRef({});

	const sendScrollEvent = useCallback(
		(posY: number) => {
			const data: ISaveEventCourseItemHistoryModel = {
				sessionId: contentStore?.sessionId ?? 0,
				eventType: EventTypes.Scroll,
				payload: JSON.stringify({ posY }),
			};
			return onEventHandle(data);
		},
		[contentStore, onEventHandle]
	);

	useScroll(docRef, sendScrollEvent);

	const onDocumentLoadSuccess = async ({ numPages }: { numPages: number }) => {
		const sendStart = () => {
			const data: ISaveEventCourseItemHistoryModel = {
				sessionId: contentStore?.sessionId ?? 0,
				eventType: EventTypes.Start,
				payload: "",
			};
			contentStore.sendEventCourseItemHistory(data);
		};

		setTotal(numPages);
		setListPages(segment ? range(segment.startPosition, segment.endPosition + 1) : range(1, numPages + 1));
		setParams((prevState: ControlParams) => ({
			...prevState,
			totalProgress: segment ? segment.endPosition - segment.startPosition + 1 : numPages,
		}));
		setParams((prevState: ControlParams) => ({ ...prevState, loading: false }));
		setParams((prevState: ControlParams) => ({ ...prevState, isReady: true }));

		if (contentStore.sessionId) {
			sendStart();
		}

		if (params.currentProgress !== 1) {
			setParams((prevState: ControlParams) => ({ ...prevState, currentProgress: 1 }));
		}
		scrollByPixels(docRef, coursesStore.lastWatchedCourseItem);
	};

	const handleError = useCallback(
		() => toasterStore.showErrorMessage(`Load error ${content.fileContent.url}`),
		[toasterStore, content.fileContent.url]
	);

	const setPageVisibility = useCallback(
		(pageNumber, isIntersecting) => {
			visiblePages.current = { ...visiblePages.current, [pageNumber]: isIntersecting };
			if (!isEmpty(visiblePages.current)) {
				const pages: number[] = reduce(
					visiblePages.current,
					(result: number[], value: boolean, key) => {
						if (value) result.push(Number(key));
						return result;
					},
					[]
				);

				if (!isEmpty(pages) && !isEqual(pages, pagesActive) && !calledOnce.current) {
					calledOnce.current = true;
					setPagesActive(pages);
				}
			}
		},
		[pagesActive]
	);

	useEffect(() => {
		if (!isEmpty(pagesActive)) {
			const firstPage = head(pagesActive);
			let currentPage = firstPage;
			if (currentPage) {
				if (segment) {
					const idx = findIndex(listPages, (value) => Number(value) === firstPage);
					if (~idx) currentPage = idx + 1;
				}
				if (currentProgress !== currentPage) {
					setParams((prevState: ControlParams) => ({ ...prevState, currentProgress: Number(currentPage) }));
				}
			}
		}
	}, [currentProgress, listPages, pagesActive, segment, setParams]);

	useEffect(() => {
		calledOnce.current = false;
		if (!isEmpty(pagesActive) && contentStore.sessionId) {
			const data: ISaveEventCourseItemHistoryModel = {
				sessionId: contentStore?.sessionId ?? 0,
				eventType: EventTypes.Scroll,
				payload: JSON.stringify({
					pagesActive,
					totalPages: total,
				}),
			};
			onEventHandle(data);
		}
	}, [contentStore, pagesActive, total, onEventHandle]);

	useEffect(() => {
		if (!isEmpty(pagesActive)) {
			if (head(pagesActive) === total && !calledOnceComplete.current && contentStore.sessionId) {
				const data: ISaveEventCourseItemHistoryModel = {
					sessionId: contentStore?.sessionId ?? 0,
					eventType: EventTypes.Complete,
					payload: "",
				};
				onEventHandle(data)
					.then(() => {
						calledOnceComplete.current = true;
						if (coursesStore.activeChaptersItems?.null) {
							coursesStore.activeChaptersItems.null.forEach((el) => {
								if (el.courseItemId === coursesStore.curActiveContent.courseItemId) {
									el.completed = true;
								}
							});
						}
					})
					.catch((err) => console.log(err));
			}
		}
	}, [
		contentStore,
		pagesActive,
		total,
		coursesStore.activeChaptersItems,
		coursesStore.curActiveContent.courseItemId,
		onEventHandle,
	]);

	useEffect(() => {
		playerStore.documentRef = docRef;
	}, [docRef, playerStore]);

	return (
		<div className={classNames(styles.player_pdf, className)} ref={boxRef}>
			<Document
				file={content.fileContent.url}
				onLoadSuccess={onDocumentLoadSuccess}
				className={classNames(styles.player_pdf__document, { [styles.player_pdf__hide]: loading })}
				onLoadError={handleError}
				inputRef={docRef}
			>
				{Array.from(listPages, (el) => (
					<PagePdf key={`page_${el}`} pageNumber={el} setPageVisibility={setPageVisibility} boxRef={boxRef} />
				))}
			</Document>
			{children}
		</div>
	);
};

export default PlayerPdf;
