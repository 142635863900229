import { isBadRequest } from "api/utils/api";
import { ExternalIdentityProviderTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ExternalIdentityProviderTypes";
import { useAppStore } from "store";
import Keycloak from "keycloak-js";
import { useCallback, useMemo } from "react";
import { useSocialSignIn } from "./useSocialSignIn";
import { Routes } from "routes";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { generatePath, useHistory, useLocation } from "react-router-dom";

export function useKeycloakProvider() {
	const { settingsStore, accountStore, toasterStore } = useAppStore();
	const { externalSignIn } = useSocialSignIn();
	const history = useHistory();
	const location = useLocation();
	const redirectUri = window.location.origin + Routes.DigitalUniversity;
	const DUclientId = settingsStore.publicSettings?.Auth[SiteSettingsKeys.DigitalUniverseClientId];
	const DUUlr = settingsStore.publicSettings?.Auth[SiteSettingsKeys.DigitalUniverseBaseUrl];
	const referrer = document.referrer;

	const keycloakAuthClient = useMemo(() => {
		return new Keycloak({
			realm: "digital-university",
			//TODO: replace this url with another one if needed before deploy to prod
			url: DUUlr,
			clientId: String(DUclientId),
		});
	}, [DUclientId, DUUlr]);

	const initiKeycloak = useCallback(async () => {
		if(referrer && referrer.includes("digitalu") && DUclientId && keycloakAuthClient) {
			if(accountStore.isAuthenticated) {
				accountStore.signout();
			}
			keycloakAuthClient.init(
				{
					onLoad: "check-sso",
					pkceMethod: "S256",
					checkLoginIframe: false,
					redirectUri: redirectUri,
				}
			)
			.catch((err) => {
				if (isBadRequest(err)) {
					console.error("Social login error:", err);
				} else {
					throw err;
				}
			});
		}
	// eslint-disable-next-line
	}, [referrer, settingsStore, DUclientId, keycloakAuthClient]);

	const initiKeycloakWithoutReferrer = useCallback(async () => {
		if(DUclientId && keycloakAuthClient) {
			keycloakAuthClient.init(
				{
					onLoad: "login-required",
					pkceMethod: "S256",
					checkLoginIframe: false,
					redirectUri: redirectUri,
				}
			)
			.catch((err) => {
				if (isBadRequest(err)) {
					console.error("Social login error:", err);
				} else {
					throw err;
				}
			});
		}
	// eslint-disable-next-line
	}, [settingsStore, DUclientId, keycloakAuthClient]);

	const checkReferrer = useCallback(() => {
		if(referrer && referrer.includes("digitalu") && location.pathname !== Routes.DigitalUniversity && !sessionStorage.getItem("redirectPath")) {
			if(accountStore.isAuthenticated) {
				accountStore.signout();
			}
			sessionStorage.setItem("redirectPath", location.pathname);
		}
	}, [referrer, location, accountStore]);

	const handleKeycloakToken = useCallback(async () => {
		if(DUclientId && keycloakAuthClient) {
			keycloakAuthClient.init({
				onLoad: "login-required",
				pkceMethod: "S256",
				checkLoginIframe: false,
			}).then((auth) => {
				if(auth) {
					if(keycloakAuthClient.token) {
						externalSignIn({ externalProvider: ExternalIdentityProviderTypes.DigitalUniverse, token: keycloakAuthClient.token, redirectUri: sessionStorage.getItem("redirectPath") || "" });
					} else {
						toasterStore.showErrorMessage("Login error");
						history.push(generatePath(Routes.Home));
					}
				}
			}).catch((err) => {
				if (isBadRequest(err)) {
					console.error("Social login error:", err);
				} else {
					throw err;
				}
			});
		}
	// eslint-disable-next-line
	}, [keycloakAuthClient, DUclientId]);

	return { keycloakAuthClient, checkReferrer, initiKeycloak, initiKeycloakWithoutReferrer, handleKeycloakToken };
}
