import { useAppStore } from "store";
import { SearchType } from "pages/private/adv-search-page/data";

export const useGetData = (searchType: SearchType | undefined) => {
	const { searchStore } = useAppStore();

	const SearchTypeToGetDataDict = {
		[SearchType.CoursesSearch]: () => searchStore.searchCourses(),
		[SearchType.InfluensersSearch]: () => searchStore.searchInfluencers(),
		[SearchType.ThreadsSearch]: () => searchStore.searchThreads(),
	};

	const getData = SearchTypeToGetDataDict[searchType ?? 0];
	const fetchMoreData = () => searchStore.fetchMoreData(getData);

	return { getData, fetchMoreData };
};
