import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { useEffect, useState } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { Routes } from "routes";
import { useAppStore } from "store";
import cloneDeep from "lodash/cloneDeep";
import { SearchStateConstants } from "constants/constants";

export const useSearchStore = (initParams: IQueryParams, searchStateKey: SearchStateConstants) => {
	const { searchStore } = useAppStore();
	const history = useHistory();
	const [params, setParams] = useState<IQueryParams>(JSON.parse(sessionStorage.getItem(searchStateKey)!) ?? initParams);

	useEffect(() => {
		searchStore.params = params;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	const anotherSearchKeys = Object.keys(sessionStorage).filter(
		(stateKey) => stateKey.includes("SearchState") && stateKey !== searchStateKey
	);

	useEffect(() => {
		let isNextRouteAllowed: boolean;
		const unlisten = history.listen((location) => {
			isNextRouteAllowed =
				location.pathname.includes(Routes.Cms) &&
				!matchPath(location.pathname, [
					Routes.CmsTaxonomy,
					Routes.CmsSales,
					Routes.CmsB2BSales,
					Routes.CmsB2BPurchases,
					Routes.CmsB2BPurchases,
				]);
		});
		const params = cloneDeep(searchStore.params);
		params.skip = 0;
		sessionStorage.setItem(searchStateKey, JSON.stringify(params));
		return () => {
			if (anotherSearchKeys.length && isNextRouteAllowed) {
				anotherSearchKeys.forEach((stateKey: string) => sessionStorage.removeItem(stateKey));
			} else if (!isNextRouteAllowed) {
				anotherSearchKeys.forEach((stateKey: string) => sessionStorage.removeItem(stateKey));
				sessionStorage.removeItem(searchStateKey);
			}
			unlisten();
		};
	}, [anotherSearchKeys, history, searchStateKey, searchStore.params]);

	return { params, setParams };
};
