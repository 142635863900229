import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { StylesConfig } from "react-select";
import { KeyboardEventHandler, MutableRefObject } from "react";
import { IOptionsItem } from "helpers/select.helper";

export const defaultDropdownStyles: StylesConfig = {
	control: (styles: any, { isDisabled }: any) => ({
		...styles,
		width: "100%",
		background: isDisabled
			? "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), var(--input-background)"
			: "var(--input-background)",
		borderRadius: "8px",
		border: "none",
		cursor: "pointer",
		borderColor: "none",
		boxShadow: "none",
	}),
	placeholder: (styles: any) => ({
		...styles,
		color: "var(--text-input-primary)",
		paddingLeft: "8px",
		fontSize: "16px",
		focus: "transform: translate3d(16px, 30%, 0) scale(0.8)",
		opacity: 0.6,
	}),
	indicatorSeparator: (styles: any) => ({
		...styles,
		display: "none",
	}),
	indicatorsContainer: (styles: any) => ({
		...styles,
		cursor: "pointer",
		svg: {
			color: "var(--text-input-primary)",
		},
	}),
	loadingIndicator: (styles: any) => ({
		...styles,
		fontSize: "8px",
	}),
	option: (styles: any) => ({
		...styles,
		cursor: "pointer",
		paddingLeft: "16px",
		fontSize: "16px",
		padding: "8px 16px",
		color: "var(--text-input-primary)",
		width: "100%",
		overflowWrap: "break-word",
		backgroundColor: "var(--input-background)",
		borderRadius: "10px",
		position: "relative",
		opacity: 0.7,
		":hover": {
			...styles[":hover"],
			opacity: 1,
		},
	}),
	menu: (styles: any) => ({
		...styles,
		borderRadius: "10px",
		border: "none",
		boxShadow: "none",
		top: "100%",
		backgroundColor: "var(--input-background)",
		position: "absolute",
		width: "100%",
		zIndex: "10",
		boxSizing: "border-box",

		"@media screen and (max-width: 767px)": {
			zIndex: "1001",
		},
	}),
	singleValue: (styles: any) => ({
		...styles,
		color: "var(--text-input-primary)",
		paddingLeft: "8px",
		paddingTop: "8px",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		fontSize: "16px",
	}),
	menuList: (styles: any) => ({
		...styles,
		paddingTop: 0,
		paddingBottom: 0,
		border: "1px solid var(--text-input-primary)",
		borderRadius: "10px",
	}),
	input: (styles: any) => ({
		...styles,
		color: "var(--text-input-primary)",
		gridTemplateColumns: "0 auto",
	}),
	multiValue: (styles: any) => ({
		...styles,
		display: "flex",
		justifyContent: "space-between",
		color: "var(--text-input-primary)",
		fontWeight: 500,
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		fontSize: "16px",
		border: "1px solid var(--text-input-primary)",
		borderRadius: "5px",
		backgroundColor: "var(--input-background)",
	}),
	multiValueLabel: (styles: any) => ({
		...styles,
		color: "var(--text-input-primary)",
	}),
	valueContainer: (styles: any) => ({
		...styles,
		flexWrap: "wrap",
	}),
	multiValueRemove: (styles: any) => ({
		...styles,
		height: "22px",
		":hover": {
			...styles[":hover"],
			backgroundColor: "var(--text-input-primary)",
			color: "var(--input-background)",
		},
	}),
};

export interface ParamsObject {
	params: IQueryParams;
	setParams: (value: ((prevState: IQueryParams) => IQueryParams) | IQueryParams) => void;
	propertyName: string;
	isLoading?: boolean;
}

export interface DropdownListProps {
	options: IOptionsItem[];
	placeholder: string;
	className?: string;
	errorText?: string;
	customStyles?: StylesConfig;
	onChange?: (option: IOptionsItem | null) => void;
	onLoadAction?: () => void;
	defaultValue?: IOptionsItem;
	value?: IOptionsItem | IOptionsItem[] | null;
	querySearchParams?: ParamsObject;
	isMultiSelected?: boolean;
	isSearchable?: boolean;
	isDisabled?: boolean;
	isError?: boolean;
	isLoading?: boolean;
	isClearable?: boolean;
	isCompact?: boolean;
	openOnClick?: boolean;
	onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
	selectInputRef?: MutableRefObject<any>;
}
