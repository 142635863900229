import { EmailSourceMessage } from "models/dto/ZoomiLxp/Utilities/Enumerations/EmailSourceMessage";
import { DaysOfWeek } from "models/dto/ZoomiLxp/Utilities/Enumerations/DaysOfWeek";
import { ScheduleNotificationTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ScheduleNotificationTypes";

export enum TypeTabs {
	NewNotification = 97,
	GeneralTab = 98,
	NoData = 99,
}

export type TypeNotification = EmailSourceMessage | TypeTabs;

export interface INotificationsItem {
	id: number;
	name: string;
	isActive: boolean;
	type: TypeNotification;
	isRequired?: boolean;
	description?: string;
}

export const generalItem: INotificationsItem = {
	id: 0,
	name: "General",
	isActive: true,
	type: TypeTabs.GeneralTab,
	isRequired: true,
};

export const newNotificationItem: INotificationsItem = {
	id: 0,
	name: "New notification",
	isActive: true,
	type: TypeTabs.NewNotification,
	isRequired: true,
};

export interface IScheduleSettingsModel {
	isSchedule: boolean;
	scheduleNotificationType: ScheduleNotificationTypes | null;
	daysOfWeek: DaysOfWeek[] | null;
	days: number[] | null;
	dayOfDate: number | null;
	monthOfDate: number | null;
	yearOfDate: number | null;
	hours: number | null;
	minutes: number | null;
}
