import React from "react";

interface IconHeartProps {
	fill?: string;
	stroke?: string;
}
const IconHeart = (props: IconHeartProps) => {
	const { fill = "none", stroke = "white" } = props;

	return (
		<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19.3449 3.10498L19.3451 3.10521C19.7911 3.55096 20.1449 4.08021 20.3862 4.66271C20.6276 5.24523 20.7518 5.86958 20.7518 6.50012C20.7518 7.13065 20.6276 7.75501 20.3862 8.33752C20.1449 8.92003 19.7911 9.44928 19.3451 9.89503L19.345 9.89514L18.285 10.9551L11 18.2402L3.71497 10.9551L2.65497 9.89514C1.75455 8.99472 1.24871 7.7735 1.24871 6.50012C1.24871 5.22674 1.75455 4.00551 2.65497 3.10509C3.55539 2.20467 4.77662 1.69883 6.05 1.69883C7.32338 1.69883 8.5446 2.20467 9.44502 3.10509L10.505 4.16509L11 4.66007L11.495 4.16509L12.555 3.10509L12.5551 3.10497C13.0008 2.65902 13.5301 2.30525 14.1126 2.06388C14.6951 1.82252 15.3195 1.69829 15.95 1.69829C16.5805 1.69829 17.2049 1.82252 17.7874 2.06388C18.3699 2.30525 18.8992 2.65902 19.3449 3.10498Z"
				strokeWidth="1.4"
				fill={fill}
				stroke={stroke}
			/>
		</svg>
	);
};

export default IconHeart;
