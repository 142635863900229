import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { IUpdateProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IUpdateProfileDataModel";
import { cloneDeep } from "lodash";
import { ICreateProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/ICreateProfileDataModel";
import { AccountRoles } from "models/dto/ZoomiLxp/Utilities/Enumerations/AccountRoles";

const getCompanyId = (data: IProfileDataModel): number | undefined => {
	const roleId = data?.roles?.[0]?.id;
	if (roleId) {
		return roleId === AccountRoles.CompanyLearner || roleId === AccountRoles.CompanyManager
			? data.company?.id ?? undefined
			: undefined;
	}
	return undefined;
};

export const converterToUpdateProfile = (
	data: IProfileDataModel,
	isChangeCompanyImmediately: boolean
): IUpdateProfileDataModel => ({
	accountId: data.id,
	isActive: data.isActive,
	firstName: data.firstName,
	lastName: data.lastName,
	displayName: data.displayName,
	jobTitle: data.jobTitle,
	companyId: getCompanyId(data),
	country: data.country,
	city: data.city,
	userPicture: data.userPicture?.id ? cloneDeep(data.userPicture) : undefined,
	description: data.description,
	groupIds: cloneDeep(data.groupIds),
	roleId: extractRole(data) ?? 0,
	expertises: cloneDeep(data.expertises),
	isChangeCompanyImmediately: isChangeCompanyImmediately,
	mfaEnabled: data.mfaEnabled,
});

export const converterToCreateProfile = (data: IProfileDataModel): ICreateProfileDataModel => ({
	firstName: data.firstName,
	lastName: data.lastName,
	displayName: data.displayName,
	email: data.email,
	jobTitle: data.jobTitle,
	companyId: getCompanyId(data),
	country: data.country,
	city: data.city,
	userPicture: data.userPicture?.id ? cloneDeep(data.userPicture) : undefined,
	roleId: extractRole(data),
	groupIds: cloneDeep(data.groupIds),
	mfaEnabled: data.mfaEnabled,
});

export const extractRole = (data: IProfileDataModel): number | undefined => {
	const result = data?.roles?.[0]?.id;
	return result ? result : undefined;
};
