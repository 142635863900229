import React from "react";
import styles from "./styles.module.scss";
import { CmsTileProps } from "components/partial/cms/cms-table/cms-table";
import { IAssessmentResultModel } from "models/dto/ZoomiLxp/Models/Assessments/IAssessmentResultModel";
import { ExpandCell } from "components/partial/cms/cms-table/expand-cell";
import { IAnswerModel } from "models/dto/ZoomiLxp/Models/Assessments/IAnswerModel";
import { isAnswerCanBeCorrect } from "../data";
import classNames from "classnames";
import { QuestionTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/QuestionTypes";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";

const QuizResultsTableTile = ({ item }: CmsTileProps<IAssessmentResultModel>) => {
	const { name, passDate, learnerPercent, answers } = item;
	return (
		<div className={styles.quizResult_table_tile}>
			<div className={styles.quiz_info}>
				<div className={styles.quiz_tile}>
					<>
						{name}
						<div className={styles.updated_date}>
							{!isEmpty(passDate) ? (
								DateTime.fromISO(passDate).setLocale("en").toLocaleString(DateTime.DATE_MED)
							) : (
								<></>
							)}
						</div>
					</>
					{learnerPercent}%
				</div>
			</div>
			{answers.map((answer: IAnswerModel) => {
				const isCanBeRight = isAnswerCanBeCorrect(answer.question.questionType);
				return (
					<div className={styles.question_cell} key={answer.question.id}>
						<ExpandCell className={styles.question_text} expandLength={100} text={answer.question.name} />
						<div
							key={`${answer.question.id}-${answer.isRightAnswer?.toString()}`}
							className={classNames(styles.question_result__status, {
								[styles.question_result__status_success]: isCanBeRight && answer.isRightAnswer,
								[styles.question_result__status_error]: isCanBeRight && !answer.isRightAnswer,
							})}
						>
							{isCanBeRight ? (answer.isRightAnswer ? "Pass" : "Failed") : QuestionTypes[answer.question.questionType]}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default QuizResultsTableTile;
