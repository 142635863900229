import styles from "./styles.module.scss";
import { useAppStore } from "store";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import CourseTile from "components/partial/course-tile/course-tile";
import Carousel from "components/partial/carousel/carousel";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { useMemo } from "react";
import { TransitionSources } from "constants/constants";

interface Props {
	title?: string;
	otherUserId?: number;
}

const GoalsCarousels = ({ title = "", otherUserId }: Props) => {
	const { goalsStore, coursesStore, usersStore } = useAppStore();

	const userId = useMemo(
		() => otherUserId || usersStore.currentUserInfo?.id,
		[otherUserId, usersStore.currentUserInfo?.id]
	);

	return (
		<LoadingIndicator loading={goalsStore.isLoading} spinnerPosition={"center"}>
			<div className={styles.goals_carousels}>
				{!isEmpty(coursesStore.currentAssignedCourses.records) && (
					<section className={styles.goals_carousels__section}>
						<h2 className={styles.goals_carousels__title}>Assigned to you by manager</h2>
						<div className={styles.goals_carousels__tiles_section}>
							<Carousel
								items={coursesStore.currentAssignedCourses.records.map((course: IPreviewCourseModel) => (
									<CourseTile
										courseInfo={course}
										className={styles.goals_carousels__gap_tile}
										disableRating={true}
										key={`${course.id}`}
										courseTileKey={TransitionSources.Goals}
									/>
								))}
								hasMore={
									coursesStore.currentAssignedCourses.totalFilteredRecords >
									coursesStore.currentAssignedCourses.records.length
								}
								getMoreData={() => {
									if (userId) {
										return coursesStore.getMoreAssignedCourses(
											userId,
											coursesStore.currentAssignedCourses.records.length
										);
									}
								}}
								className={styles.goals_carousels__carousel}
								classNameSlider={styles.goals_carousels__gap_tile}
							/>
						</div>
					</section>
				)}
				{!isEmpty(goalsStore.coursesForJobTitle) && (
					<section className={styles.goals_carousels__section}>
						<h2 className={styles.goals_carousels__title}>
							{title ? `Required skills for: ${title}` : "Required skills"}
						</h2>
						<div className={styles.goals_carousels__tiles_section}>
							<Carousel
								items={goalsStore.coursesForJobTitle.records.map((course: IPreviewCourseModel) => (
									<CourseTile
										courseInfo={course}
										className={styles.goals_carousels__gap_tile}
										disableRating={true}
										key={`${course.id}`}
										courseTileKey={TransitionSources.Goals}
									/>
								))}
								hasMore={
									goalsStore.coursesForJobTitle.totalFilteredRecords > goalsStore.coursesForJobTitle.records.length
								}
								getMoreData={() => {
									if (userId) {
										return goalsStore.getMoreCoursesForJobTitle(userId, goalsStore.coursesForJobTitle.records.length);
									}
								}}
								className={styles.goals_carousels__carousel}
								classNameSlider={styles.goals_carousels__gap_tile}
							/>
						</div>
					</section>
				)}
				{!isEmpty(goalsStore.courses) &&
					goalsStore.courses.map((course) => {
						if (!isEmpty(course.courses)) {
							return (
								<section className={styles.goals_carousels__section} key={`${course.id}_${course.name}`}>
									<h2 className={styles.goals_carousels__title}>{course.name}</h2>
									<div className={styles.goals_carousels__tiles_section}>
										<Carousel
											items={course.courses.map((item: IPreviewCourseModel) => (
												<CourseTile
													courseInfo={item}
													className={styles.goals_carousels__gap_tile}
													disableRating={true}
													key={item.id}
													courseTileKey={TransitionSources.Goals}
												/>
											))}
											hasMore={course.totalFilteredRecords > course.courses.length}
											getMoreData={() => goalsStore.getMoreCourses(course.id, course.courses.length)}
											className={styles.goals_carousels__carousel}
											classNameSlider={styles.goals_carousels__gap_tile}
											key={course.id}
										/>
									</div>
								</section>
							);
						}
						return null;
					})}
			</div>
		</LoadingIndicator>
	);
};

export default observer(GoalsCarousels);
