import { observer } from "mobx-react";
import React from "react";
import { useKeycloakProvider } from "hooks/useKeycloakProvider";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import PublicLayout from "layouts/public/public";

const DigitalUniversityRedirectPage: React.FC = observer(() => {
  const { handleKeycloakToken } = useKeycloakProvider();
  handleKeycloakToken();

	return (
		<PublicLayout>
      		<LoadingIndicator loading={true} />
		</PublicLayout>
	);
});
export default DigitalUniversityRedirectPage;
