import React from "react";
import styles from "./styles.module.scss";
import { getAvatarTitle } from "helpers/string.helper";
import Avatar from "components/base/avatar/avatar";
import classNames from "classnames";
import { CmsTileProps } from "components/partial/cms/cms-table/cms-table";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { isNil } from "lodash";

const cx = classNames.bind(styles);

const CmsTileUser: React.FunctionComponent<CmsTileProps<IProfileDataModel>> = (
	props: CmsTileProps<IProfileDataModel>
) => {
	const { item: user, onClick } = props;
	const { firstName, lastName, userPicture, email, isActive, company } = user;

	return (
		<div className={styles.cms_tile_user} onClick={() => onClick && onClick(user)}>
			<div className={styles.cms_tile_user__avatar}>
				<Avatar title={getAvatarTitle(firstName, lastName)} image={userPicture?.url} size="compact" />
			</div>
			<div className={styles.cms_tile_user__middle_part}>
				<span className={styles.cms_tile_user__name}>
					{firstName} {lastName}
				</span>
				<span className={styles.cms_tile_user__email}>{email}</span>
				<span className={cx(styles.cms_tile_user__status, { [styles.cms_tile_user__status_success]: isActive })}>
					{isActive ? "Active" : "InActive"}
				</span>
			</div>
			<div className={styles.cms_tile_user__company}>{!isNil(company) ? company.name : "-"}</div>
		</div>
	);
};

export default CmsTileUser;
