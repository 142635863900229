import { IWidgetModel } from "models/dto/ZoomiLxp/Models/Reports/IWidgetModel";
import ReactGridLayout, { Layout } from "react-grid-layout";
import isEmpty from "lodash/isEmpty";
import { MIN_WIDGET_HEIGHT } from "constants/widget";
import { ISaveWidgetModel } from "models/dto/ZoomiLxp/Models/Reports/ISaveWidgetModel";
import merge from "lodash/merge";
import keyBy from "lodash/keyBy";
import values from "lodash/values";

export interface IWidgetMeta {
	id: string;
	name: string;
	url: string;
	collapsed: boolean;
	collapsedHeight: number;
	static: boolean;
}

export const convertWidgetModelToLayout = (data: IWidgetModel[]): Layout[] => {
	if (isEmpty(data)) return [];
	return data.map((widget) => ({
		i: String(widget.id),
		x: widget.posX,
		y: widget.posY,
		h: widget.collapsed ? MIN_WIDGET_HEIGHT : widget.height,
		w: widget.width,
		minH: widget.collapsed ? 0 : 1,
		minW: 1,
		static: true,
	}));
};

export const convertWidgetModelToWidgetMeta = (data: IWidgetModel[]): IWidgetMeta[] => {
	if (isEmpty(data)) return [];
	return data.map((widget) => {
		const { id, name, url, collapsed } = widget;
		return {
			id: String(id),
			name,
			url,
			collapsed,
			collapsedHeight: widget.height,
			static: true,
		};
	});
};

export interface ICoord {
	x: number;
	y: number;
}
export const newCoord = (widgets: ReactGridLayout.Layout[]): ICoord => {
	const MAX_WIDTH = 3;
	const MAX_COLS = 12;
	let coord: ICoord = { x: 0, y: 0 };

	if (!isEmpty(widgets)) {
		let maxWidget = widgets[0];
		if (widgets.length > 1) {
			for (let i = 1; i < widgets.length; i++) {
				if (widgets[i].y > maxWidget.y) {
					maxWidget = widgets[i];
				} else if (widgets[i].y === maxWidget.y) {
					if (widgets[i].x > maxWidget.x) maxWidget = widgets[i];
				}
			}
		}
		if (maxWidget.x + maxWidget.w + MAX_WIDTH <= MAX_COLS) {
			coord = { x: maxWidget.x + maxWidget.w, y: maxWidget.y };
		} else {
			coord = { x: 0, y: maxWidget.y + maxWidget.h };
		}
	}
	return coord;
};

export const convertWidgetsToSaveWidgetModel = (view: Layout[], meta: IWidgetMeta[]): ISaveWidgetModel[] => {
	if (isEmpty(view) || isEmpty(meta)) return [];
	const mergedArrays = values(merge(keyBy(view, "i"), keyBy(meta, "id")));
	return mergedArrays.map(
		(item) =>
			({
				id: item.id.includes("widget_") ? null : Number(item.id),
				name: item.name,
				url: item.url,
				width: item.w,
				height: item.collapsed ? item.collapsedHeight : item.h,
				posX: item.x,
				posY: item.y,
				collapsed: item.collapsed,
			} as ISaveWidgetModel)
	);
};
