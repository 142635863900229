import { cmsMenuItems } from "components/partial/submenu/items";
import { useAppStore } from "../store";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";
import { Routes } from "routes";
import { PermissionConstantsUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsUpdate";

export const useSubMenuItems = () => {
	const { usersStore } = useAppStore();
	let allowedRoutes: string[] = [];

	if (
		usersStore.checkOneOfUserPermissions([
			PermissionConstantsRead.OwnCoursesForTechActions,
			PermissionConstantsRead.AllCoursesForTechActions,
		])
	) {
		allowedRoutes = allowedRoutes.concat([Routes.CmsCourses]);
	}

	if (usersStore.checkOneOfUserPermissions([PermissionConstantsRead.ThreadsFromCms])) {
		allowedRoutes = allowedRoutes.concat([Routes.CmsThreads]);
	}

	if (usersStore.checkOneOfUserPermissions([PermissionConstantsRead.Company, PermissionConstantsRead.AnyCompanies])) {
		allowedRoutes = allowedRoutes.concat([Routes.CmsCompanies]);
	}

	if (
		usersStore.checkOneOfUserPermissions([
			PermissionConstantsRead.AllUsersProfileInfo,
			PermissionConstantsRead.CompanyUsersProfileInfo,
		])
	) {
		allowedRoutes = allowedRoutes.concat([Routes.CmsUsers]);
	}

	if (usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.Taxonomy])) {
		allowedRoutes = allowedRoutes.concat([Routes.CmsTaxonomy]);
	}

	if (usersStore.checkOneOfUserPermissions([PermissionConstantsRead.Purchases])) {
		allowedRoutes = allowedRoutes.concat([Routes.CmsB2BSales]);
	}

	if (usersStore.checkOneOfUserPermissions([PermissionConstantsRead.Purchases])) {
		allowedRoutes = allowedRoutes.concat([Routes.CmsB2BPurchases]);
	}

	if (usersStore.checkOneOfUserPermissions([PermissionConstantsRead.PrivateSettings])) {
		allowedRoutes = allowedRoutes.concat([Routes.CmsSettings]);
	}

	if (usersStore.checkOneOfUserPermissions([PermissionConstantsRead.GroupCmsManagement])) {
		allowedRoutes = allowedRoutes.concat([Routes.CmsGroups]);
	}

	if (usersStore.checkOneOfUserPermissions([PermissionConstantsRead.SystemEmailSettings])) {
		allowedRoutes = allowedRoutes.concat([Routes.CmsNotificationsSystem]);
	}

	if (usersStore.checkOneOfUserPermissions([PermissionConstantsRead.OwnLogHistoryActivities])) {
		allowedRoutes = allowedRoutes.concat([Routes.CmsLogHistory]);
	}

	const allowedMenuItems = cmsMenuItems.filter((menuItem) => allowedRoutes.includes(menuItem.route));

	return { allowedMenuItems };
};
