import classNames from "classnames";
import styles from "./styles.module.scss";
import { ReactComponent as RemoveIcon } from "assets/icons/ic_close.svg";

interface IChipProps {
	label: string;
	onChipClick?: () => void;
	showDeleteIcon?: boolean;
	onIconClick?: () => void;
	isMain?: boolean;
	inlineChipStyle?: React.CSSProperties;
	iconColor?: string;
}

const Chip = ({
	label,
	onChipClick,
	onIconClick,
	isMain,
	showDeleteIcon = true,
	inlineChipStyle,
	iconColor,
}: IChipProps) => {
	return (
		<div className={classNames(styles.chip, { [styles.chip__main]: isMain })} style={inlineChipStyle}>
			<span
				className={classNames(styles.chip__label, { [styles.chip__label__hoverable]: !!onChipClick })}
				onClick={onChipClick}
			>
				{label}
			</span>
			{showDeleteIcon && (
				<RemoveIcon className={classNames(styles.chip_icon)} onClick={onIconClick} color={iconColor} />
			)}
		</div>
	);
};

export default Chip;
