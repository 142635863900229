import React, { useEffect } from "react";
import HeaderDashboard from "components/partial/dashboard/header-dashboard/header-dashboard";
import BodyDashboard from "components/partial/dashboard/body-dashboard/body-dashboard";
import { useAppStore } from "store";

const ReportDashboard = () => {
	const { reportsStore, toasterStore } = useAppStore();

	useEffect(() => {
		reportsStore.getWidgets().catch((err) => toasterStore.showErrorMessage(err));

		return () => reportsStore.clearWidgets();
	}, [toasterStore, reportsStore]);

	return (
		<>
			<HeaderDashboard />
			<BodyDashboard />
		</>
	);
};

export default ReportDashboard;
