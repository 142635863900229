import { makeAutoObservable, runInAction } from "mobx";
import { ReportsApi } from "api/controllers/ReportsApi";
import { downloadFile } from "helpers/file-download.helper";
import { IWidgetModel } from "models/dto/ZoomiLxp/Models/Reports/IWidgetModel";
import { ISaveWidgetModel } from "models/dto/ZoomiLxp/Models/Reports/ISaveWidgetModel";
import { ISaveWidgetsRequestModel } from "models/dto/ZoomiLxp/Models/Reports/ISaveWidgetsRequestModel";
import ReactGridLayout, { Layout, Layouts } from "react-grid-layout";
import {
	convertWidgetModelToLayout,
	convertWidgetModelToWidgetMeta,
	convertWidgetsToSaveWidgetModel,
	ICoord,
	IWidgetMeta,
	newCoord,
} from "helpers/reports.helper";
import findIndex from "lodash/findIndex";
import update from "immutability-helper";
import uniqueId from "lodash/uniqueId";
import isEmpty from "lodash/isEmpty";
import { MIN_WIDGET_HEIGHT } from "constants/widget";
import cloneDeep from "lodash/cloneDeep";
import { Emitter } from "mitt";
import { AlertEventTypes } from "components/base/alert/alert";

export interface IReportModel {
	reportName: string;
	onExport: () => void;
}

export class ReportsStore {
	private _isLoading: boolean = false;
	private _reports: IReportModel[] = [];
	private _widgets: IWidgetModel[] = [];
	private _viewWidgets: Layouts | undefined;
	private _metaWidgets: IWidgetMeta[] = [];
	private _alertEventBus: Emitter<AlertEventTypes> | undefined;
	private _isShowButton: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	async exportCourseProgressReport() {
		this.isLoading = true;
		try {
			await ReportsApi.exportCourseProgressReport().then((response) => {
				downloadFile(response);
			});
		} finally {
			this.isLoading = false;
		}
	}

	async exportZ2ByCourseReport() {
		this.isLoading = true;
		try {
			await ReportsApi.exportZ2ByCourseReport().then((response) => {
				downloadFile(response);
			});
		} finally {
			this.isLoading = false;
		}
	}

	async exportQuizResultsReport() {
		this.isLoading = true;
		try {
			await ReportsApi.exportQuizResultsReport().then((response) => {
				downloadFile(response);
			});
		} finally {
			this.isLoading = false;
		}
	}

	async exportAssignedCoursesActivityReport() {
		this.isLoading = true;
		try {
			await ReportsApi.exportAssignedCoursesActivityReport().then((response) => {
				downloadFile(response);
			});
		} finally {
			this.isLoading = false;
		}
	}

	async exportNotAssignedCoursesActivityReport() {
		this.isLoading = true;
		try {
			await ReportsApi.exportNotAssignedCoursesActivityReport().then((response) => {
				downloadFile(response);
			});
		} finally {
			this.isLoading = false;
		}
	}

	async exportEngagementByCourseReport() {
		this.isLoading = true;
		try {
			await ReportsApi.exportEngagementByCourseReport().then((response) => {
				downloadFile(response);
			});
		} finally {
			this.isLoading = false;
		}
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get isLoading() {
		return this._isLoading;
	}

	get allReports(): IReportModel[] {
		return this._reports;
	}

	get widgets(): IWidgetModel[] {
		return this._widgets;
	}

	set widgets(value: IWidgetModel[]) {
		this._widgets = value;
	}

	async getWidgets() {
		runInAction(() => {
			this._isLoading = true;
		});
		try {
			const response = await ReportsApi.getWidgets();
			this.saveRecords(response.data.data.records);
			return response.data.data;
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	private saveRecords(records: IWidgetModel[]) {
		runInAction(() => {
			this._widgets = records;
			this._viewWidgets = { lg: convertWidgetModelToLayout(records) };
			this._metaWidgets = convertWidgetModelToWidgetMeta(records);
		});
	}

	async saveWidgets() {
		runInAction(() => {
			this._isLoading = true;
		});
		try {
			const data: ISaveWidgetModel[] = convertWidgetsToSaveWidgetModel(
				this._viewWidgets?.["lg"] ?? [],
				this._metaWidgets
			);
			const info: ISaveWidgetsRequestModel = { widgets: data };
			const response = await ReportsApi.saveWidgets(info);
			this.saveRecords(response.data.data.records);
			return response.data.data;
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	get viewWidgets(): ReactGridLayout.Layouts | undefined {
		return this._viewWidgets;
	}

	set viewWidgets(value: ReactGridLayout.Layouts | undefined) {
		this._viewWidgets = value;
	}

	get metaWidgets(): IWidgetMeta[] {
		return this._metaWidgets;
	}

	set metaWidgets(value: IWidgetMeta[]) {
		this._metaWidgets = value;
	}

	clearWidgets() {
		runInAction(() => {
			this._widgets = [];
			this._viewWidgets = undefined;
			this._metaWidgets = [];
			this._isShowButton = false;
		});
	}
	saveMetaInformation(values: IWidgetMeta) {
		const index = findIndex(this._metaWidgets, { id: values.id });
		if (~index) {
			runInAction(() => {
				this._metaWidgets = update(this._metaWidgets, { [index]: { $set: values } });
			});
		}
	}

	addWidget() {
		const widgets = this._viewWidgets?.["lg"] ?? [];
		const coord: ICoord = newCoord(widgets);
		const id = uniqueId("widget_");
		runInAction(() => {
			this._viewWidgets = update(this._viewWidgets, {
				lg: { $push: [{ i: id, x: coord.x, y: coord.y, w: 3, h: 2 } as Layout] },
			});
			this._metaWidgets = update(this._metaWidgets, {
				$push: [{ id, name: "", url: "", collapsed: false } as IWidgetMeta],
			});
		});
	}

	deleteWidget(id: string) {
		const { indexView, indexMeta } = this.findIndexes(id);

		if (~indexView) {
			runInAction(() => {
				this._viewWidgets = update(this._viewWidgets, { lg: { $splice: [[indexView, 1]] } });
			});
		}
		if (~indexMeta) {
			runInAction(() => {
				this._metaWidgets = update(this._metaWidgets, { $splice: [[indexMeta, 1]] });
			});
		}
	}

	collapseWidget(id: string, isCollapse: boolean) {
		const { indexView, indexMeta } = this.findIndexes(id);

		if (~indexMeta) {
			runInAction(() => {
				this._metaWidgets = update(this._metaWidgets, {
					[indexMeta]: { collapsed: { $set: isCollapse } },
				});
			});
		}

		if (~indexView) {
			runInAction(() => {
				if (isCollapse) {
					this._metaWidgets[indexMeta].collapsedHeight = this._viewWidgets?.lg[indexView].h ?? 0;
				}
				this._viewWidgets = update(this._viewWidgets, {
					lg: {
						[indexView]: {
							h: { $set: isCollapse ? MIN_WIDGET_HEIGHT : this._metaWidgets[indexMeta].collapsedHeight },
							minH: { $set: isCollapse ? 0 : 1 },
							static: { $set: isCollapse },
						},
					},
				});
			});
		}
	}

	findIndexes(id: string) {
		let indexView = -1;
		const widgets = this._viewWidgets?.["lg"] ?? [];
		if (!isEmpty(widgets)) indexView = findIndex(widgets, { i: id });

		const indexMeta = findIndex(this._metaWidgets, { id });

		return { indexView, indexMeta };
	}

	staticWidgets(isStatic: boolean) {
		let metaWidgets = cloneDeep(this._metaWidgets);
		if (!isEmpty(metaWidgets)) {
			metaWidgets = metaWidgets.map((widget) => ({ ...widget, static: isStatic }));
			runInAction(() => {
				this._metaWidgets = update(this._metaWidgets, { $set: metaWidgets });
			});
		}

		let widgets = cloneDeep(this._viewWidgets?.["lg"] ?? []);
		if (!isEmpty(widgets)) {
			widgets = widgets.map((widget, index) => ({
				...widget,
				static: metaWidgets[index].collapsed ? true : isStatic,
			}));
			runInAction(() => {
				this._viewWidgets = update(this._viewWidgets, { lg: { $set: widgets } });
			});
		}
	}

	cancelChanges() {
		runInAction(() => {
			this._viewWidgets = { lg: convertWidgetModelToLayout(this._widgets) };
			this._metaWidgets = convertWidgetModelToWidgetMeta(this._widgets);
		});
	}

	get alertEventBus(): Emitter<AlertEventTypes> | undefined {
		return this._alertEventBus;
	}

	set alertEventBus(value: Emitter<AlertEventTypes> | undefined) {
		this._alertEventBus = value;
	}

	checkErrors() {
		let flag = false;
		this._metaWidgets.forEach((widget) => {
			if (isEmpty(widget.name) || isEmpty(widget.url)) flag = true;
		});
		if (flag) throw new Error("Fill in the required widget fields!");
	}

	get isShowButton(): boolean {
		return this._isShowButton;
	}

	set isShowButton(value: boolean) {
		this._isShowButton = value;
	}
}
