import { AxiosResponse } from "axios";
import { mimeTypes } from "constants/constants";
import { saveAs } from "file-saver";
import isEmpty from "lodash/isEmpty";

export const downloadFile = (response: AxiosResponse<any>) => {
	const isFileSaverSupported = !!new Blob();

	let filename: string = Object(response.headers["content-disposition"])
		.split(";")
		.find((n: string) => n.includes("filename="))
		.replace("filename=", "")
		.trim();

	if (!isEmpty(filename) && filename.endsWith('"')) {
		filename = filename.replace(/"/g, "");
	}

	if (isFileSaverSupported) {
		saveAs(response.data, filename);
	} else {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", filename);
		document.body.appendChild(link);
		link.click();
		link.remove();
	}
};

export const getPdfUrl = (pdfFileData: ArrayBuffer) => {
	const bytes = new Uint8Array(pdfFileData);
	const blob = new Blob([bytes], { type: mimeTypes.pdf });
	const fileUrl = URL.createObjectURL(blob);
	return fileUrl;
};

export const getBase64DataString = (data: string) => {
	return Buffer.from(data, "binary").toString("base64");
};
