import styles from "./styles.module.scss";
import { ReactComponent as CloseIcon } from "assets/icons/ic_close.svg";
import React, { MouseEventHandler } from "react";
import classNames from "classnames";

interface ButtonCloseProps {
	className?: string;
	onClick?: MouseEventHandler<SVGSVGElement>;
}

const cx = classNames.bind(styles);
const ButtonClose = (props: ButtonCloseProps) => {
	const { className, onClick } = props;
	return (<CloseIcon
		className={cx(className, styles.button_close__close_icon)}
		onClick={onClick}
	/>);
};

export default ButtonClose;
