import { makeAutoObservable, runInAction } from "mobx";
import { ICourseModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseModel";
import { CoursesApi } from "api/controllers/CoursesApi";
import { ICreateCourseModel } from "models/dto/ZoomiLxp/Models/Courses/ICreateCourseModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { ISaveEventCourseHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseHistoryRequestModel";
import { ICourseItemModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseItemModel";
import { EventApi } from "api/controllers/EventApi";
import { ICreateContentRefModel } from "models/dto/ZoomiLxp/Models/ContentModels/ICreateContentRefModel";
import { IUpdateContentRefModel } from "models/dto/ZoomiLxp/Models/ContentModels/IUpdateContentRefModel";
import { AppStore } from "store";
import { ICourseAssignmentModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseAssignmentModel";
import { IAssignCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IAssignCourseModel";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { ICreateChapterModel } from "models/dto/ZoomiLxp/Models/Courses/ICreateChapterModel";
import { IUpdateChapterModel } from "models/dto/ZoomiLxp/Models/Courses/IUpdateChapterModel";
import { IChapterRef } from "models/dto/ZoomiLxp/Models/Courses/IChapterRef";
import { isBadRequest, requestErrorHandler } from "api/utils/api";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { SequenceDirection } from "components/partial/cms/cms-content-table/data";
import { ICreateThreadRefModel } from "models/dto/ZoomiLxp/Models/Peers/ICreateThreadRefModel";
import { IUpdateThreadRefModel } from "models/dto/ZoomiLxp/Models/Peers/IUpdateThreadRefModel";
import { chain, Dictionary, every, filter, find, findIndex, groupBy, isEmpty, isNil, orderBy, sortBy, uniqBy } from "lodash";
import { IFullUpdateCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IFullUpdateCourseModel";
import { emptyFile } from "constants/constants";
import { initialCarouselPagingParams } from "helpers/params.helper";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { CarouselDataType } from "components/partial/carousels-array/carousel-data-provider";
import { UsersApi } from "api/controllers/UsersApi";
import { defaultCarouselRecordsParams, getParams, getUpdatedParams } from "helpers/carousel.helper";
import { ICourseForbiddenResultModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseForbiddenResultModel";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import { AxiosResponse } from "axios";
import { IUpdateContentSegmentRefModel } from "models/dto/ZoomiLxp/Models/ContentModels/IUpdateContentSegmentRefModel";
import { showErrorMessage } from "helpers/error.handling.helper";
import { IPreviewCourseVersionModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseVersionModel";
import { IBaseModel } from "models/dto/ZoomiLxp/Models/Common/IBaseModel";
import { ICourseItemNavigation } from "models/dto/ZoomiLxp/Models/Courses/ICourseItemNavigation";
import { initialAssignments, initialLastWatchedItem, takeActive } from "./courses-store-helper";
import { getInitParams } from "helpers/filter.helper";
import { ContentUpdateType } from "pages/private/cms-content-page/content-profile/content-profile.helper";
import { ISegmentModel } from "models/dto/ZoomiLxp/Models/ContentModels/ISegmentModel";
import { getBase64DataString, getPdfUrl } from "helpers/file-download.helper";
import { IActivityInfo } from "models/dto/ZoomiLxp/Models/LMS/IActivityInfo";
import { IChapterItemModel } from "pages/private/myDesk/components/myDesk-chapters-section";

export class CoursesStore {
	private rootStore;
	private _isLoading: boolean = false;
	speciallyForYou: IPreviewCourseModel[] = [];
	mostWatched: IPreviewCourseModel[] = [];
	mostLiked: IPreviewCourseModel[] = [];
	mostRated: IPreviewCourseModel[] = [];
	playlist: IPreviewCourseModel[] = [];
	trendingCourses: IPreviewCourseModel[] = [];
	relatedCourses: IPreviewCourseModel[] = [];
	findedCourses: IPreviewCourseModel[] = [];
	courseAssignments: ICourseAssignmentModel[] = [];
	currentAssignedCourses: IGetRecordsResponse<IPreviewCourseModel> = initialAssignments;
	lastWatchedCourseItem: ICourseItemNavigation = initialLastWatchedItem;
	purchasedCourses: IPreviewCourseModel[] = [];
	meetGoals: IPreviewCourseModel[] = [];
	public availableSegmentsParams: Array<IBaseModel> = [];
	private _isActiveCourse: boolean = false;
	private _isPaidCourse: boolean = false;
	private _isAssignedCourse: boolean = false;
	private _isActiveContentCourse: boolean = false;
	private _activeCourseItems: ICourseItemModel[] = [];
	private _courseItems: ICourseItemModel[] = [];
	private _archivedItems: ICourseItemModel[] = [];
	private _curPositionContent: number = 0;
	private _prevPositionContent: number = 0;
	private _isCourseEnded: boolean = false;
	private _currentCourse: ICourseModel = {} as ICourseModel;
	private _currentCourseVersions: IPreviewCourseVersionModel[] = [];
	private _nullChapterPosition: number = 0;
	private _isPartCourse: boolean = false;
	private _coursesSearchParams: IQueryParams = getInitParams();
	private _isErrorCourse: boolean = false;
	private _errorText: string = "";
	private _activeChapterItems: Dictionary<ICourseItemModel[]> | null = null;
	private _allChapterItems: Dictionary<ICourseItemModel[]> | null = null;
	private _activeChaptersGroup: IChapterRef[] | null = null;
	private _allChaptersGroup: IChapterRef[] = [];
	private _loadingGroup: {
		[key: string]: boolean;
	} = {
		mostLikedCourses: false,
		mostWatchedCourses: false,
		speciallyForYou: false,
		bookmarkedCourses: false,
		topRatedCourses: false,
		trendingCourses: false,
		purchasedCurses: false,
		coursesForJobTitle: false,
		meetGoals: false,
		similarCourses: false,
	};

	private _coursesCarouselsPagingParams: {
		[key: string]: IGetRecordsResponse<CarouselDataType>;
	} = {
		assignedCourses: defaultCarouselRecordsParams,
		mostLikedCourses: defaultCarouselRecordsParams,
		mostWatchedCourses: defaultCarouselRecordsParams,
		speciallyForYou: defaultCarouselRecordsParams,
		bookmarkedCourses: defaultCarouselRecordsParams,
		topRatedCourses: defaultCarouselRecordsParams,
		trendingCourses: defaultCarouselRecordsParams,
		purchasedCourses: defaultCarouselRecordsParams,
		coursesForJobTitle: defaultCarouselRecordsParams,
		meetGoals: defaultCarouselRecordsParams,
		similarCourses: defaultCarouselRecordsParams,
	};

	constructor(rootStore: AppStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);
	}

	async addNewCourse(data: ICreateCourseModel) {
		const correctedData: ICreateCourseModel = {
			...data,
			price: data.price ?? 0,
			accountCreatedId: this.rootStore.usersStore.currentUserInfo!.id,
		};
		this.isLoading = true;
		try {
			const response = await CoursesApi.addNewCourse(correctedData);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async createChapter(data: ICreateChapterModel) {
		this.isLoading = true;
		try {
			const response = await CoursesApi.createChapter(data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async updateChapter(data: IUpdateChapterModel, chapterId: number) {
		this.isLoading = true;
		try {
			const response = await CoursesApi.updateChapter(data, chapterId);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getChapterInfo(chapterId: number) {
		this.isLoading = true;
		try {
			const response = await CoursesApi.getChapterById(chapterId);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async deleteChapter(chapterId: number, getCourseAfterDeleting?: boolean) {
		this.isLoading = true;
		try {
			await CoursesApi.deleteChapter(chapterId);
			if (getCourseAfterDeleting) {
				await this.getCourseById(this.currentCourse.id);
			}
		} finally {
			this.isLoading = false;
		}
	}

	async deleteChapterFromGrid(chapterId: number) {
		const filteredChapters = this.allChaptersGroup.filter((chapter) => chapter.id !== chapterId);
		const nullChapterItemPositions = this._courseItems
			.filter((item) => !item.chapter && !item.isArchive)
			.map((item) => item.position);
		let maxPosition = nullChapterItemPositions.length ? Math.max(...nullChapterItemPositions) : 0;

		this._courseItems.forEach((item) => {
			if (item.chapter?.id === chapterId) {
				item.chapter = undefined;
				item.position = maxPosition + 1;
				maxPosition++;
			}
		});
		this._courseItems = this.getSortedCourseItem(this._courseItems);
		this.allChaptersGroup = filteredChapters;
	}

	async addContentRef(courseId: number, data: ContentUpdateType) {
		const segmentIds: number[] = data.segments.map((segment: ISegmentModel) => segment.id);
		this.isLoading = true;
		const refData: ICreateContentRefModel = {
			contentId: data.id,
			courseId: courseId,
			chapterId: data.chapterId !== 0 ? data.chapterId : undefined,
			isActive: data?.isActive ?? false,
			segmentIds,
		};
		try {
			const response = await CoursesApi.addContentRef(refData);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async updateContentRef(courseItemId: number, data: ContentUpdateType) {
		this.isLoading = true;
		const refData: IUpdateContentRefModel = {
			chapterId: data.chapterId !== 0 ? data.chapterId : undefined,
			isActive: data?.isActive ?? false,
		};
		try {
			const response = await CoursesApi.updateContentRef(courseItemId, refData);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async updateContentSegmentRef(courseItemId: number, data: ContentUpdateType) {
		this.isLoading = true;
		const segmentId: number = data.segments.map((segment: ISegmentModel) => segment.id)[0];
		const segment = this.rootStore.contentStore.segments.filter((value) => value.id === segmentId)[0];
		const segmentRef: IUpdateContentSegmentRefModel = {
			chapterId: data.chapterId !== 0 ? data.chapterId : undefined,
			isActive: data?.isActive ?? false,
			displayName: segment?.name ?? "",
		};
		try {
			const response = await CoursesApi.updateContentSegmentRef(courseItemId, segmentRef);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getCreators() {
		const response = await CoursesApi.getCreators();
		return response.data.data.authors;
	}

	async getSources() {
		const response = await CoursesApi.getSources();
		return response.data.data.sources;
	}

	async getCategories() {
		const response = await CoursesApi.getCategories();
		return response.data.data;
	}

	async getSubjects() {
		const response = await CoursesApi.getSubjects();
		return response.data.data;
	}

	async getAllCoursesByQuery(query?: IQueryParams) {
		this.isLoading = true;
		try {
			const response = await CoursesApi.searchCourses(query ?? { ...getInitParams(), take: 15 });
			runInAction(() => {
				this.findedCourses = response.data.data.records;
			});
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getSegmentsReTypes() {
		const response = await CoursesApi.getSegmentsReTypes();
		runInAction(() => {
			this.availableSegmentsParams = response?.data?.data;
		});
	}

	async setCourseAssignments(courseId: number, query?: IQueryParams) {
		const response = await CoursesApi.getCourseAssignments(courseId, query ?? getInitParams());
		runInAction(() => {
			this.courseAssignments = response.data.data.records;
		});
	}

	async getIsCourseRequested(courseId: number) {
		const response = await CoursesApi.getIsCourseRequested(courseId);
		return response.data.data;
	}

	async getCourseAssignmentsData(courseId: number, query: IQueryParams) {
		this.isLoading = true;
		try {
			const response = await CoursesApi.getCourseAssignments(courseId, query);
			return response.data.data;
		} catch (err) {
			showErrorMessage(this.rootStore.commonStore.alertEventBus, err);
		} finally {
			this.isLoading = false;
		}
	}

	async getAssignedCourses(userId: number, data?: IPagingParams) {
		this.isLoading = true;
		try {
			const assignedCourses = await CoursesApi.getAssignedCourses(userId, data ?? initialCarouselPagingParams);
			const newParams = getParams(assignedCourses.data.data);
			const oldParams = this._coursesCarouselsPagingParams.mostLikedCourses;
			runInAction(() => {
				this.currentAssignedCourses = {
					records: assignedCourses.data.data.records,
					totalFilteredRecords: assignedCourses.data.data.totalFilteredRecords,
				};
				this._coursesCarouselsPagingParams = {
					...this._coursesCarouselsPagingParams,
					assignedCourses: getUpdatedParams(newParams, oldParams),
				};
			});
			return assignedCourses.data.data.records;
		} finally {
			this.isLoading = false;
		}
	}

	async getMoreAssignedCourses(userId: number, skip: number) {
		this.isLoading = true;
		try {
			const assignedCourses = await CoursesApi.getAssignedCourses(userId, { ...initialCarouselPagingParams, skip });
			runInAction(() => {
				this.currentAssignedCourses = {
					records: [...assignedCourses.data.data.records, ...assignedCourses.data.data.records],
					totalFilteredRecords: assignedCourses.data.data.totalFilteredRecords,
				};
			});
		} finally {
			this.isLoading = false;
		}
	}

	async assignCourse(data: IAssignCourseModel) {
		this.isLoading = true;
		try {
			const response = await CoursesApi.assignCourse(data);
			runInAction(() => {
				this.courseAssignments = this.courseAssignments.map((assignment: ICourseAssignmentModel) => {
					if (assignment.accountId === response.data.data.accountId) {
						assignment = response.data.data;
					}
					return assignment;
				});
			});
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async requestAssignCourse(data: IAssignCourseModel) {
		this.isLoading = true;
		try {
			const response = await CoursesApi.requestAssignCourse(data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getAllCoursesByGroups(userId: number) {
		const response = await Promise.allSettled([
			this.getAssignedCourses(userId, initialCarouselPagingParams),
			this.getMostLikedCourses(),
			this.getMostWatchedCourses(),
			this.getRecommendedCourses(),
			this.getBookmarkedCourses(),
			this.getTopRatedCourses(),
			this.getTrendingCourses(),
			this.getPurchasedCurses(),
			this.getCoursesForJobTitle(),
			this.meetYourGoals(),
		]);
		const errors = response
			.filter((result) => result.status === "rejected")
			.map((result) => ("reason" in result ? result.reason : null));
		if (!isEmpty(errors)) requestErrorHandler(errors);
	}

	async getMyDeskCourses(userId: number, courseId: number) {
		const response = await Promise.allSettled([
			this.getAssignedCourses(userId, initialCarouselPagingParams),
			this.getMostWatchedCourses(),
			this.getSimilarCourses(courseId, initialCarouselPagingParams),
			this.meetYourGoals(),
		]);
		const errors = response
			.filter((result) => result.status === "rejected")
			.map((result) => ("reason" in result ? result.reason : null));
		if (!isEmpty(errors)) requestErrorHandler(errors);
	}

	async addThreadRef(data: ICreateThreadRefModel) {
		const response = await CoursesApi.addThreadRef(data);
		return response.data.data;
	}

	async updateThreadRef(courseItemId: number, data: IUpdateThreadRefModel) {
		const response = await CoursesApi.updateThreadRef(courseItemId, data);
		return response.data.data;
	}

	async getCoursesForJobTitle(data?: IPagingParams) {
		this._loadingGroup = { ...this.loadingGroup, coursesForJobTitle: true };
		try {
			if (this.rootStore.usersStore.currentUserInfo) {
				const coursesForJobTitle = await UsersApi.getCoursesForJobTitle(
					this.rootStore.usersStore.currentUserInfo.id,
					data ?? initialCarouselPagingParams
				);
				const newParams = getParams(coursesForJobTitle.data.data);
				const oldParams = this._coursesCarouselsPagingParams.coursesForJobTitle;
				runInAction(() => {
					this.rootStore.usersStore.coursesForJobTitle = coursesForJobTitle.data.data.records;
					this._coursesCarouselsPagingParams = {
						...this._coursesCarouselsPagingParams,
						coursesForJobTitle: getUpdatedParams(newParams, oldParams),
					};
				});
				return coursesForJobTitle.data.data;
			} else {
				return this.rootStore.usersStore.coursesForJobTitle;
			}
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this.loadingGroup, coursesForJobTitle: false };
			});
		}
	}

	async getMostLikedCourses(data?: IPagingParams) {
		runInAction(() => {
			this._loadingGroup = { ...this.loadingGroup, mostLikedCourses: true };
		});
		try {
			const mostLikedCourses = await CoursesApi.getMostLikedCourses(data ?? initialCarouselPagingParams);
			const newParams = getParams(mostLikedCourses.data.data);
			const oldParams = this._coursesCarouselsPagingParams.mostLikedCourses;
			runInAction(() => {
				this.mostLiked = mostLikedCourses.data.data.records;
				this._coursesCarouselsPagingParams = {
					...this._coursesCarouselsPagingParams,
					mostLikedCourses: getUpdatedParams(newParams, oldParams),
				};
			});
			return mostLikedCourses.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this.loadingGroup, mostLikedCourses: false };
			});
		}
	}

	async getMostWatchedCourses(data?: IPagingParams) {
		runInAction(() => {
			this._loadingGroup = { ...this.loadingGroup, mostWatchedCourses: true };
		});
		try {
			const mostWatchedCourses = await CoursesApi.getMostWatchedCourses(data ?? initialCarouselPagingParams);
			const newParams = getParams(mostWatchedCourses.data.data);
			const oldParams = this._coursesCarouselsPagingParams.mostWatchedCourses;
			runInAction(() => {
				this.mostWatched = mostWatchedCourses.data.data.records;
				this._coursesCarouselsPagingParams = {
					...this._coursesCarouselsPagingParams,
					mostWatchedCourses: getUpdatedParams(newParams, oldParams),
				};
			});
			return mostWatchedCourses.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this.loadingGroup, mostWatchedCourses: false };
			});
		}
	}

	async getRecommendedCourses(data?: IPagingParams) {
		runInAction(() => {
			this._loadingGroup = { ...this.loadingGroup, speciallyForYou: true };
		});
		try {
			const recommendedCourses = await CoursesApi.getRecommendedCourses(data ?? initialCarouselPagingParams);
			const newParams = getParams(recommendedCourses.data.data);
			const oldParams = this._coursesCarouselsPagingParams.recommendedCourses;
			runInAction(() => {
				this.speciallyForYou = recommendedCourses.data.data.records;
				this._coursesCarouselsPagingParams = {
					...this._coursesCarouselsPagingParams,
					recommendedCourses: getUpdatedParams(newParams, oldParams),
				};
			});
			return recommendedCourses.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this.loadingGroup, speciallyForYou: false };
			});
		}
	}

	async getBookmarkedCourses(data?: IPagingParams, keepStateAfterEvent?: boolean) {
		runInAction(() => {
			this._loadingGroup = { ...this.loadingGroup, bookmarkedCourses: true };
		});
		try {
			const bookmarkedCourses = await CoursesApi.getBookmarkedCourses(data ?? initialCarouselPagingParams);
			const newParams = getParams(bookmarkedCourses.data.data);
			const oldParams = this._coursesCarouselsPagingParams.bookmarkedCourses;
			runInAction(() => {
				this.playlist = bookmarkedCourses.data.data.records;
				this._coursesCarouselsPagingParams = {
					...this._coursesCarouselsPagingParams,
					bookmarkedCourses: keepStateAfterEvent ? newParams : getUpdatedParams(newParams, oldParams),
				};
			});
			return bookmarkedCourses.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this.loadingGroup, bookmarkedCourses: false };
			});
		}
	}

	async getTrendingCourses(data?: IPagingParams) {
		runInAction(() => {
			this._loadingGroup = { ...this.loadingGroup, trendingCourses: true };
		});
		try {
			const trendingCourses = await CoursesApi.getTrendingCourses(data ?? initialCarouselPagingParams);
			const newParams = getParams(trendingCourses.data.data);
			const oldParams = this._coursesCarouselsPagingParams.trendingCourses;
			runInAction(() => {
				this.trendingCourses = trendingCourses.data.data.records;
				this._coursesCarouselsPagingParams = {
					...this._coursesCarouselsPagingParams,
					trendingCourses: getUpdatedParams(newParams, oldParams),
				};
			});
			return trendingCourses.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this.loadingGroup, trendingCourses: false };
			});
		}
	}

	async getTopRatedCourses(data?: IPagingParams) {
		runInAction(() => {
			this._loadingGroup = { ...this.loadingGroup, topRatedCourses: true };
		});
		try {
			const topRatedCourses = await CoursesApi.getTopRatedCourses(data ?? initialCarouselPagingParams);
			const newParams = getParams(topRatedCourses.data.data);
			const oldParams = this._coursesCarouselsPagingParams.topRatedCourses;
			runInAction(() => {
				this.mostRated = topRatedCourses.data.data.records;
				this._coursesCarouselsPagingParams = {
					...this._coursesCarouselsPagingParams,
					topRatedCourses: getUpdatedParams(newParams, oldParams),
				};
			});
			return topRatedCourses.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this.loadingGroup, topRatedCourses: false };
			});
		}
	}

	async getPurchasedCurses(data?: IPagingParams) {
		runInAction(() => {
			this._loadingGroup = { ...this.loadingGroup, purchasedCurses: true };
		});
		try {
			const purchasedCourses = await CoursesApi.getPurchasedCurses(data ?? initialCarouselPagingParams);
			const newParams = getParams(purchasedCourses.data.data);
			const oldParams = this._coursesCarouselsPagingParams.purchasedCourses;
			runInAction(() => {
				this.purchasedCourses = purchasedCourses.data.data.records;
				this._coursesCarouselsPagingParams = {
					...this._coursesCarouselsPagingParams,
					purchasedCourses: getUpdatedParams(newParams, oldParams),
				};
			});
			return purchasedCourses.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this.loadingGroup, purchasedCurses: false };
			});
		}
	}

	async updateActiveCourseItemToCompleted() {
		if (!this._activeCourseItems[this._curPositionContent].completed) {
			this._activeCourseItems[this._curPositionContent].completed = true;
		} 
	}

	private async getCourse(
		courseExtractor: () => Promise<AxiosResponse<IResponseModel<ICourseModel>, any>>,
		courseItemId?: number,
		showLoading: boolean = true
	): Promise<ICourseModel> {
		if (showLoading) this.isLoading = true;
		try {
			runInAction(() => {
				this._isErrorCourse = false;
				this._errorText = "";
			});

			const response = await courseExtractor();
			const course = response.data.data;
			this.initCourse(course, courseItemId);
			return course;
		} catch (e) {
			if (isBadRequest(e)) {
				const data = e.response?.data?.data;
				if (data && "courseModel" in data) {
					const text = (data as ICourseForbiddenResultModel)?.errorMessage ?? "Something went wrong";
					runInAction(() => {
						this._isErrorCourse = true;
						this._errorText = text;
					});
					//@ts-ignore
					const course = data.courseModel as ICourseModel;
					this.initCourse(course, courseItemId);
					return course;
				} else if (e?.response?.status === 400) {
					runInAction(() => {
						this._isErrorCourse = true;
						this._errorText = data?.errorMessage ?? "Something went wrong";
					});
				}
			}
			throw e;
		} finally {
			if (showLoading) this.isLoading = false;
		}
	}

	async getCourseById(courseId: number, courseItemId?: number, showLoading: boolean = true): Promise<ICourseModel> {
		return await this.getCourse(
			async () => {
				const response = await CoursesApi.getCourseById(courseId);

				if (!response.data.data) return Promise.reject(Error(`Course ${courseId} not found!`));
				return response;
			},
			courseItemId,
			showLoading
		);
	}

	async getCourseVersionsList(courseId: number) {
		this.isLoading = true;
		try {
			const response = await CoursesApi.getCourseVersions(courseId);

			runInAction(() => {
				this._currentCourseVersions = response.data.data;
			});
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getCourseVersion(versionId: number) {
		const response = await CoursesApi.getCourseVersionDetails(versionId);
		const courseData: ICourseModel = response.data.data;
		this.initCourse(courseData, undefined, true);
		runInAction(() => {
			this._courseItems = courseData.courseItems;
			this.archivedItems = courseData.courseItems.filter((item) => item.isArchive);
		});
		return courseData;
	}

	async getLastWatchedCourse() {
		return await this.getCourse(async () => {
			const response = await CoursesApi.getLastWatchedCourse();
			return response;
		}, undefined);
	}

	private getSortedCourseItem(courseItems: ICourseItemModel[]): ICourseItemModel[] {
		const getItemChapterSequence = (item: ICourseItemModel): number => {
			return item.chapter ? item.chapter.sequence : this._nullChapterPosition;
		};
		return courseItems.sort((currentItem, nextItem) => {
			const currentItemChapterPos = getItemChapterSequence(currentItem);
			const nextItemChapterPos = getItemChapterSequence(nextItem);
			return currentItemChapterPos === nextItemChapterPos
				? currentItem.position - nextItem.position
				: currentItemChapterPos - nextItemChapterPos;
		});
	}

	private initCourse(course: ICourseModel, courseItemId?: number, keepStoredCourse?: boolean) {
		this.getCourseVersionsList(course.id);

		runInAction(() => {
			this._isActiveCourse = course.isActive;
			this._isAssignedCourse = course.isAssigned;
			this._isPaidCourse = course.isPaid;
			if (course.lastWatchedCourseItem) this.lastWatchedCourseItem = course.lastWatchedCourseItem;
		});
		const targetCourseItemId: number | undefined = courseItemId || course.lastWatchedCourseItem?.id;

		runInAction(() => {
			this._isActiveContentCourse =
				!isEmpty(course.courseItems) && !!course.courseItems.find((item: ICourseItemModel) => item.isActive);
			const chaptersPositions = course.chapters.map((chapter) => chapter.sequence);
			this._nullChapterPosition = Math.max(...chaptersPositions) + 1;
			const sortedItems = this.getSortedCourseItem(course.courseItems as ICourseItemModel[]);
			this._courseItems = sortedItems;
			this.refreshNullChaptersItemsPosition();
			this._activeCourseItems = takeActive(sortedItems);
			this.archivedItems = this.courseItems.filter((item) => item.isArchive);
			this._curPositionContent = targetCourseItemId
				? findIndex(this._activeCourseItems, { courseItemId: targetCourseItemId })
				: 0;
			if (!keepStoredCourse) {
				this._currentCourse = course;
			}
		});

		runInAction(() => {
			const activeItems = takeActive(course.courseItems);
			this._activeChapterItems = groupBy<ICourseItemModel>(activeItems, (item: ICourseItemModel) =>
				isNil(item.chapter) ? null : item.chapter.id
			);
		});

		const chapters: IChapterItemModel[] = course.chapters.map((item) => { return { ...item, completed: every(this._activeChapterItems?.[`${item?.id}`], { "completed": true })};});
		const sortedChapters: IChapterItemModel[] = orderBy(chapters, (e) => e.sequence);
		const nullChapter: IChapterItemModel = {
			id: 0,
			name: "Out of Chapter",
			thumbnail: emptyFile(),
			sequence: chapters.length ? sortedChapters[sortedChapters.length - 1].sequence + 1 : 0,
		};

		chapters.push({ ...nullChapter, completed: every(this._activeChapterItems?.null, { "completed": true })});
		sortedChapters.push(nullChapter);
		const idChaptersWithActiveItems = chain(this._activeChapterItems)
			.keys()
			.map((key) => {
				if (key === "null") return 0;
				return Number(key);
			})
			.value();

		runInAction(() => {
			this._activeChaptersGroup = filter(chapters, (chapter) => idChaptersWithActiveItems.includes(chapter.id));
			this._allChaptersGroup = sortedChapters;
		});

		runInAction(() => {
			this._isActiveContentCourse =
				!isEmpty(course.courseItems) && !!course.courseItems.find((item: ICourseItemModel) => item.isActive);
		});
	}

	private refreshNullChaptersItemsPosition = () => {
		let outOfChapterItemPositionCounter = 0;
		let archiveItemPositionCounter = 0;
		this._courseItems.forEach((item: ICourseItemModel, index) => {
			const isNullChapter = !item.chapter || !item.chapter?.id;
			if (isNullChapter && !item.isArchive) {
				this._courseItems[index].position = outOfChapterItemPositionCounter;
				outOfChapterItemPositionCounter++;
			}
			if (isNullChapter && item.isArchive) {
				this._courseItems[index].position = archiveItemPositionCounter;
				archiveItemPositionCounter++;
			}
		});
	};

	async toggleItemActive(isActive: boolean, itemId: number) {
		this._courseItems.forEach((item) => {
			if (item.courseItemId === itemId) {
				item.isActive = isActive;
			}
		});
	}

	async updateCourse(courseId: number, data: ICourseModel) {
		this.isLoading = true;
		const actualCourseChaptersId = data.chapters.map((chapter: IChapterRef) => chapter.id);
		const isChapterAvailable = (itemChapter: IChapterRef) => actualCourseChaptersId.includes(itemChapter.id);

		try {
			const updateCourseModel: IFullUpdateCourseModel = {
				...data,
				courseItems: this._courseItems.map((item) => ({
					...item,
					sequence: item.position,
					chapter: item.chapter && isChapterAvailable(item.chapter) ? item.chapter : undefined,
					chapterId: !item.isArchive && item.chapter && isChapterAvailable(item.chapter) ? item.chapter.id : undefined,
				})),
				chapters: data.chapters.map((chapter) => ({ ...chapter, chapterId: chapter.id })),
			};

			updateCourseModel.additionalFields.creatorId = data.additionalFields.creator?.id;
			if (data.chapters && data.chapters.length) {
				updateCourseModel.chapters = data.chapters
					.filter((chapter) => chapter.id !== 0)
					.map((chapter) => ({ ...chapter, chapterId: chapter.id }));
			}

			const response = await CoursesApi.updateCourse(courseId, updateCourseModel);
			this.initCourse(response.data.data);
			runInAction(() => {
				this._isActiveCourse = response.data.data.isActive;
				this._isAssignedCourse = response.data.data.isAssigned;
				this._isPaidCourse = response.data.data.isPaid;
				this._isActiveContentCourse =
					!isEmpty(response.data.data.courseItems) &&
					!!response.data.data.courseItems.find((item: ICourseItemModel) => item.isActive);
			});
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async changePositionInCourse(direction: SequenceDirection, courseItemId?: number, chapterId?: number) {
		const newItemsArr: ICourseItemModel[] = this._courseItems.filter((item) => !item.isArchive).map((item) => item);

		const archivedItems: ICourseItemModel[] = this._archivedItems;
		const uniqueChapters = uniqBy(
			this._allChaptersGroup.map((item: IChapterRef) => item),
			"id"
		);
		const newChaptersArr: IChapterRef[] = sortBy(uniqueChapters, "sequence");
		const getItemChapterId = (courseItem: ICourseItemModel): number => {
			if (courseItem && courseItem.chapter && courseItem.chapter.id) {
				return courseItem.chapter.id;
			}
			return 0;
		};

		const updateItemPosition = (chapterId: number) => {
			let positionCounter = 0;
			newItemsArr.forEach((item, index) => {
				const itemChapterId = getItemChapterId(item);
				if (chapterId === itemChapterId) {
					newItemsArr[index].position = positionCounter;
					positionCounter++;
				}
			});
		};

		const updateChaptersSequence = () => {
			let positionCounter = 0;
			newChaptersArr.forEach((_, index) => {
				newChaptersArr[index].sequence = positionCounter;
				positionCounter++;
			});
			this._allChaptersGroup = newChaptersArr;
		};

		const updateChaptersInItems = () => {
			newItemsArr.forEach((item) => {
				if (item.chapter) {
					const itemChapterId = getItemChapterId(item);
					const chapterFromChaptersArr = newChaptersArr.find((chapter: IChapterRef) => chapter.id === itemChapterId)!;
					item.chapter.sequence = chapterFromChaptersArr.sequence;
				}
			});
		};

		if (courseItemId) {
			const currentItem: ICourseItemModel = newItemsArr.find((item) => item.courseItemId === courseItemId)!;
			const itemIndex = newItemsArr.findIndex((item) => item.courseItemId === courseItemId);
			const currentItemPosition = currentItem.position;
			const currentItemChapterId = currentItem.chapter ? currentItem.chapter.id : 0;
			const currentItemChapterIndex = newChaptersArr.findIndex((chapter) => chapter.id === currentItemChapterId);

			if (direction === SequenceDirection.Up) {
				const prevItem = newItemsArr[itemIndex - 1];
				if (prevItem && getItemChapterId(newItemsArr[itemIndex]) === getItemChapterId(prevItem)) {
					currentItem.position = prevItem.position;
					prevItem.position = currentItemPosition;

					newItemsArr[itemIndex] = prevItem;
					newItemsArr[itemIndex - 1] = currentItem;
				} else {
					const targetChapter = newChaptersArr[currentItemChapterIndex ? currentItemChapterIndex - 1 : 0];
					newItemsArr[itemIndex].chapter = newChaptersArr[currentItemChapterIndex - 1];
					updateItemPosition(currentItemChapterId);
					updateItemPosition(targetChapter.id);
				}
			} else {
				const nextItem = newItemsArr[itemIndex + 1];

				if (getItemChapterId(newItemsArr[itemIndex]) === getItemChapterId(nextItem)) {
					currentItem.position = nextItem.position;
					nextItem.position = currentItemPosition;

					newItemsArr[itemIndex] = nextItem;
					newItemsArr[itemIndex + 1] = currentItem;
				} else {
					const targetChapterId = newChaptersArr[currentItemChapterIndex + 1].id;
					if (targetChapterId) {
						newItemsArr[itemIndex].chapter = newChaptersArr[currentItemChapterIndex + 1];
					} else {
						newItemsArr[itemIndex].chapter = undefined;
					}
					updateItemPosition(targetChapterId);
				}
			}

			this._courseItems = [...archivedItems, ...newItemsArr];
		}
		if (chapterId) {
			const chapterIndex = newChaptersArr.findIndex((chapter) =>
				chapter ? chapter.id === chapterId : chapterId === 0
			);
			const currentChapter: IChapterRef = newChaptersArr.find((chapter) => chapter.id === chapterId)!;
			const currentChapterSequence = currentChapter.sequence;

			if (direction === SequenceDirection.Up) {
				const prevChapter = newChaptersArr[chapterIndex - 1];
				currentChapter.sequence = prevChapter.sequence;
				prevChapter.sequence = currentChapterSequence;

				newChaptersArr[chapterIndex] = prevChapter;
				newChaptersArr[chapterIndex - 1] = currentChapter;
			} else {
				const nextChapter = newChaptersArr[chapterIndex + 1];
				currentChapter.sequence = nextChapter.sequence;
				nextChapter.sequence = currentChapter.sequence;

				newChaptersArr[chapterIndex] = nextChapter;
				newChaptersArr[chapterIndex + 1] = currentChapter;
			}

			updateChaptersSequence();
			updateChaptersInItems();
			this._courseItems = this.getSortedCourseItem([...archivedItems, ...newItemsArr]);
		}

		return courseItemId
			? ([...archivedItems, ...newItemsArr] as ICourseItemModel[])
			: (newChaptersArr as IChapterRef[]);
	}

	private getItemIndex = (currentItem: ICourseItemModel) =>
		this._courseItems.findIndex((item) => item.courseItemId === currentItem.courseItemId);

	addInArchive = (currentItem: ICourseItemModel) => {
		const itemIndex = this.getItemIndex(currentItem);
		const newArr = this._courseItems.map((item) => item);
		newArr[itemIndex].isArchive = true;
		newArr[itemIndex].isActive = false;
		newArr[itemIndex].position = this._archivedItems.length;
		runInAction(() => {
			this._archivedItems = [...this._archivedItems, newArr[itemIndex]];
			this._courseItems = this.getSortedCourseItem(newArr as ICourseItemModel[]);
		});
		this.refreshNullChaptersItemsPosition();
	};

	removeFromArchive = (currentItem: ICourseItemModel) => {
		const itemIndex = this.getItemIndex(currentItem);
		const newArr = this._courseItems.map((item) => item);
		newArr[itemIndex].isArchive = false;
		newArr[itemIndex].isActive = true;
		const nullChapterItems = newArr.filter((item) => (!item.chapter || !item.chapter.id) && !item.isArchive);
		newArr[itemIndex].position = nullChapterItems.length;
		runInAction(() => {
			this._archivedItems = this._archivedItems.filter((item) => item.courseItemId !== newArr[itemIndex].courseItemId);
			this._courseItems = this.getSortedCourseItem(newArr as ICourseItemModel[]);
		});
		this.refreshNullChaptersItemsPosition();
	};

	sendCourseEvent = async (data: ISaveEventCourseHistoryRequestModel) => {
		await EventApi.sendCourseEvent(data);
	};

	async getSimilarCourses(courseId: number, data?: IPagingParams) {
		runInAction(() => {
			this._loadingGroup = { ...this.loadingGroup, similarCourses: true };
		});
		try {
			const similarCourses = await CoursesApi.getSimilarCourses(courseId, data ?? initialCarouselPagingParams);
			const newParams = getParams(similarCourses.data.data);
			const oldParams = this._coursesCarouselsPagingParams.similarCourses;
			runInAction(() => {
				this.relatedCourses = similarCourses.data.data.records;
				this._coursesCarouselsPagingParams = {
					...this._coursesCarouselsPagingParams,
					similarCourses: getUpdatedParams(newParams, oldParams),
				};
			});
			return similarCourses.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this.loadingGroup, similarCourses: false };
			});
		}
	}

	async meetYourGoals(data?: IPagingParams) {
		runInAction(() => {
			this._loadingGroup = { ...this.loadingGroup, meetGoals: true };
		});
		try {
			const goalsCourses = await CoursesApi.meetYourGoals(data ?? initialCarouselPagingParams);
			const newParams = getParams(goalsCourses.data.data);
			const oldParams = this._coursesCarouselsPagingParams.meetGoals;
			runInAction(() => {
				this.meetGoals = goalsCourses.data.data.records;
				this._coursesCarouselsPagingParams = {
					...this._coursesCarouselsPagingParams,
					meetGoals: getUpdatedParams(newParams, oldParams),
				};
			});
			return goalsCourses.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this.loadingGroup, meetGoals: false };
			});
		}
	}

	curContentItem = (courseItemId: number): ICourseItemModel => {
		if (!isEmpty(this._courseItems) && courseItemId) {
			const item = find(this._courseItems, { courseItemId });
			return item ? item : ({} as ICourseItemModel);
		}
		return {} as ICourseItemModel;
	};

	async getCMSCourses() {
		this.isLoading = true;
		try {
			this.coursesSearchParams = this.rootStore.searchStore.params;
			const response = await CoursesApi.getAllCourses(this.coursesSearchParams);
			return response.data.data;
		} catch (err) {
			requestErrorHandler(err);
		} finally {
			this.isLoading = false;
		}
	}

	private async certificateExtractor<T>(isPdf?: boolean) {
		const response = await CoursesApi.getCourseCertificate(this.currentCourse.id);
		const certificateId = response.data.data.certId;
		const certificateData = await this.rootStore.certificatesStore.getCertificateDataById<T>(certificateId, isPdf);

		return certificateData;
	}

	async getCourseCertificate(): Promise<string | undefined> {
		this.isLoading = true;
		try {
			const dataString = await this.certificateExtractor<string>();
			const base64String = getBase64DataString(dataString);
			return base64String;
		} catch (err) {
			requestErrorHandler(err);
		} finally {
			this.isLoading = false;
		}
	}

	isCourseCompleted(): boolean {
		let completeCounter = 0;
		this._activeCourseItems.forEach(item => {
			item.completed && completeCounter ++;
		});
		if(completeCounter === this._activeCourseItems.length) {
			return true;
		} else {
			return false;
		}
	}

	async getCourseCertificatePdfUrl(): Promise<string | undefined> {
		this.isLoading = true;
		try {
			const certificateData = await this.certificateExtractor<ArrayBuffer>(true);
			const fileUrl = getPdfUrl(certificateData);
			return fileUrl;
		} catch (err) {
			requestErrorHandler(err);
		} finally {
			this.isLoading = false;
		}
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get isLoading() {
		return this._isLoading;
	}

	get archivedItems() {
		return this._archivedItems;
	}
	set archivedItems(items: ICourseItemModel[]) {
		this._archivedItems = items;
	}

	set isCurrentCourseActive(isActive: boolean) {
		this._isActiveCourse = isActive;
	}

	get isCurrentCourseActive() {
		return this._isActiveCourse;
	}

	get activeCourseItems(): ICourseItemModel[] {
		return this._activeCourseItems;
	}

	get courseItems(): ICourseItemModel[] {
		return this._courseItems;
	}

	get hasNextItemInSameChapter(): boolean {
		if (this.activeChaptersGroup) {
			const currentChapterId = this.curActiveContent.chapter?.id || 0;
			const currentChapterItems = this._activeChapterItems?.[currentChapterId.toString()];
			if (currentChapterItems) {
				const curItemIndex = currentChapterItems.findIndex(
					(e) => e.courseItemId === this.curActiveContent.courseItemId
				);
				return curItemIndex < currentChapterItems.length - 1;
			}
		}
		return true;
	}

	get currentChapterUnlocked(): boolean {
		if (this._currentCourse.lockNextChapterPercent && this.activeChaptersGroup) {
			const lockPercent = this._currentCourse.lockNextChapterPercent;
			for (let i = 0; i < this.activeChaptersGroup.length; i++) {
				if (this.activeChaptersGroup[i].id === (this.curActiveContent.chapter?.id ?? 0)) return true;
				const chapterCompletionPercent = this.calcChapterCompletionPercent(this.activeChaptersGroup[i].id);
				if (lockPercent >= chapterCompletionPercent) return false;
			}
		}
		return true;
	}

	get nextChapterUnlocked(): boolean {
		if (this._currentCourse.lockNextChapterPercent) {
			const lockPercent = this._currentCourse.lockNextChapterPercent;
			return lockPercent < this.currentChapterCompletionPercent;
		}
		return true;
	}

	get currentChapterCompletionPercent(): number {
		if (this.activeChaptersGroup) {
			const curChapterId = this.curActiveContent.chapter?.id || 0;
			return this.calcChapterCompletionPercent(curChapterId);
		}
		return 100;
	}

	calcChapterCompletionPercent(chapterId: number): number {
		if (this.activeChaptersGroup) {
			const chapterItems = this._activeChapterItems?.[chapterId.toString()];
			const completedCount = chapterItems?.filter((e) => e.completed).length || 0;
			const totalCount = chapterItems?.length || 0;
			return totalCount > 0 ? (completedCount * 100.0) / totalCount : 0;
		}
		return 100;
	}

	get curPositionContent(): number {
		return this._curPositionContent;
	}

	set curPositionContent(value: number) {
		let position = value;

		if (value < 0) {
			position = 0;
		} else if (value + 1 > this._activeCourseItems.length) {
			position = this._activeCourseItems.length - 1;
		}
		this._curPositionContent = position;
	}

	get curActiveContent(): ICourseItemModel {
		return !isEmpty(this._activeCourseItems) && this._curPositionContent >= 0
			? this._activeCourseItems[this._curPositionContent]
			: ({} as ICourseItemModel);
	}

	get countContents(): number {
		return this._activeCourseItems.length ?? 0;
	}

	get prevPositionContent(): number {
		return this._prevPositionContent;
	}

	set prevPositionContent(value: number) {
		if (value < 0) {
			this._prevPositionContent = 0;
		} else {
			this._prevPositionContent = value;
		}
	}

	get coursesSearchParams(): IQueryParams {
		return this._coursesSearchParams;
	}

	set coursesSearchParams(params: IQueryParams) {
		this._coursesSearchParams = params;
	}

	get isActiveContentCourse(): boolean {
		return this._isActiveContentCourse;
	}

	set isActiveContentCourse(value: boolean) {
		this._isActiveContentCourse = value;
	}

	get currentCourse(): ICourseModel {
		return this._currentCourse;
	}

	get currentCourseVersions(): IPreviewCourseVersionModel[] {
		return this._currentCourseVersions;
	}

	get isPartCourse(): boolean {
		return this._isPartCourse;
	}

	set isPartCourse(value: boolean) {
		this._isPartCourse = value;
	}

	get isPaidCourse(): boolean {
		return this._isPaidCourse;
	}

	get isAssignedCourse(): boolean {
		return this._isAssignedCourse;
	}

	get isErrorCourse(): boolean {
		return this._isErrorCourse;
	}

	get errorText(): string {
		return this._errorText;
	}

	get isActiveCourse(): boolean {
		return this._isActiveCourse;
	}

	get activeChaptersItems(): Dictionary<ICourseItemModel[]> | null {
		return this._activeChapterItems;
	}

	get allChaptersItems(): Dictionary<ICourseItemModel[]> | null {
		return this._allChapterItems;
	}

	get activeChaptersGroup(): IChapterRef[] | null {
		return this._activeChaptersGroup;
	}

	get allChaptersGroup(): IChapterRef[] {
		return this._allChaptersGroup;
	}

	set allChaptersGroup(chapters: IChapterRef[]) {
		this._allChaptersGroup = chapters;
	}

	get isCourseEnded(): boolean {
		return this._isCourseEnded;
	}

	set isCourseEnded(isEnded: boolean) {
		this._isCourseEnded = isEnded;
	}

	get loadingGroup(): {
		[key: string]: boolean;
	} {
		return this._loadingGroup;
	}

	get coursesCarouselsPagingParams() {
		return this._coursesCarouselsPagingParams;
	}

	clearStore() {
		runInAction(() => {
			this.rootStore.lmsStore.activityInfo = {} as IActivityInfo;
			this.isLoading = false;
			this.speciallyForYou = [];
			this.mostWatched = [];
			this.mostLiked = [];
			this.mostRated = [];
			this.playlist = [];
			this.trendingCourses = [];
			this.relatedCourses = [];
			this.findedCourses = [];
			this.courseAssignments = [];
			this.currentAssignedCourses = initialAssignments;
			this.lastWatchedCourseItem = initialLastWatchedItem;
			this.purchasedCourses = [];
			this.meetGoals = [];
			this._coursesSearchParams = getInitParams();
			this._isActiveCourse = false;
			this._isPaidCourse = false;
			this._isAssignedCourse = false;
			this._isActiveContentCourse = false;
			this._activeCourseItems = [];
			this._curPositionContent = 0;
			this._prevPositionContent = 0;
			this._currentCourse = {} as ICourseModel;
			this._currentCourseVersions = [];
			this._isPartCourse = false;
			this._isErrorCourse = false;
			this._errorText = "";
			this._isCourseEnded = false;
			this._activeChapterItems = null;
			this._activeChaptersGroup = null;
			this._loadingGroup = {
				mostLikedCourses: false,
				mostWatchedCourses: false,
				speciallyForYou: false,
				bookmarkedCourses: false,
				topRatedCourses: false,
				trendingCourses: false,
				purchasedCurses: false,
				coursesForJobTitle: false,
				meetGoals: false,
				similarCourses: false,
			};
			this._coursesCarouselsPagingParams = {
				assignedCourses: defaultCarouselRecordsParams,
				mostLikedCourses: defaultCarouselRecordsParams,
				mostWatchedCourses: defaultCarouselRecordsParams,
				speciallyForYou: defaultCarouselRecordsParams,
				bookmarkedCourses: defaultCarouselRecordsParams,
				topRatedCourses: defaultCarouselRecordsParams,
				trendingCourses: defaultCarouselRecordsParams,
				purchasedCourses: defaultCarouselRecordsParams,
				coursesForJobTitle: defaultCarouselRecordsParams,
				meetGoals: defaultCarouselRecordsParams,
				similarCourses: defaultCarouselRecordsParams,
			};
		});
	}
}
