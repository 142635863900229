import { makeAutoObservable, runInAction } from "mobx";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { getInitParams } from "helpers/filter.helper";
import { ICertificatePreviewModel } from "models/dto/ZoomiLxp/Models/Certificates/ICertificatePreviewModel";
import { CertificatesApi } from "api/controllers/CertificatesApi";
import { getPdfUrl } from "helpers/file-download.helper";

export class CertificatesStore {
	private _isLoading: boolean = false;
	private _certificates: ICertificatePreviewModel[] = [];
	private _certificatesQueryParams: IQueryParams = getInitParams();

	constructor() {
		makeAutoObservable(this);
	}

	async getCertificateDataById<T>(certId: string, isPdf?: boolean) {
		this.isLoading = true;
		try {
			const response = await CertificatesApi.getCertificateData<T>(certId, isPdf);
			return response.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getCertificatePdfUrl(certId: string) {
		this.isLoading = true;
		try {
			const certData = await this.getCertificateDataById<ArrayBuffer>(certId, true);
			const fileUrl = getPdfUrl(certData);
			return fileUrl;
		} finally {
			this.isLoading = false;
		}
	}

	async getSertificates(data?: IQueryParams, userId?: number) {
		this.isLoading = true;
		try {
			const response = userId
				? await CertificatesApi.getCertificatesByUserId(userId, data ?? this._certificatesQueryParams)
				: await CertificatesApi.getCertificates(data ?? this._certificatesQueryParams);
			runInAction(() => {
				this.certificates = response.data.data.records;
			});
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	get isLoading() {
		return this._isLoading;
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get certificates() {
		return this._certificates;
	}

	set certificates(certificates: ICertificatePreviewModel[]) {
		this._certificates = certificates;
	}
}
