import { MAX_EMAIL_LENGTH } from "./constants";

export type ErrorMessage = { response?: { data?: { data?: { errorMessage?: string } } } };

export const errorMessages = {
	unsupportedFileType: (actualType: string, expectedType: string) =>
		`Unsupported file type: ${actualType}. Please choose ${expectedType} format file.`,
	exceedingAllowedFileSize: (sizeMB: number | string, maxSizeMB: number) =>
		`Exceeding the allowed file size. Current file size: ${sizeMB}mb, allowed size: ${maxSizeMB}mb. Please check if you are loading file less then ${maxSizeMB}mb.`,
	somethingWentWrong: "Something went wrong",
};

export const getBulkOperationsMessages = (title?: string) => ({
	oneCourse:
		"The Course cloning procedure has been initiated. Status information is available on the Log History page.",
	oneUser: "The User cloning procedure has been initiated. Status information is available on the Log History page.",
	commonBulkOperations: `The ${
		title ?? ""
	} Bulk procedure has been initiated. Status information is available on the Log History page.`,
});

export const picturesHintMessages = {
	avatar: "JPG, PNG 10 Mb max. Recommended minimal resolution 400x400",
	thumbnail: "JPG, PNG 10 Mb max. Recommended minimal resolution 400x225",
	banner: "JPG,PNG,MP4,MOV 30 Mb max. Recommended resolution 1600х400",
};

export const passwordRequirements = "Minimum 8 symbols, at least one capital letter, one number and one special symbol";

export const infoMessages = {
	unsavedChanges: "Unsaved changes",
	leaveWithoutSaving: "Are you sure want to leave without saving changes?",
	settingsCarouselsOrder: "Please make sure that same position number is not assigned to more then one carousel.",
	linkCopiedToClipboard: "Link copied to clipboard",
	requiredField: "This field is required",
};

export const knowledgeOutdated = "Course was updated. Please watch new content to have relevant knowledge.";

export const motivesMeterInfo =
	"Sensory’s AI engine interprets user click stream data into meaningful sequences known as motifs. In the diagram below, you can view how often you triggered motifs of each type (the top bar) and how that compares to the site-wide average (the bottom bar). You can hover over the icons to the left of the bars to learn more about motif types and what they mean for your learning.";

export const engagementDescription =
	"Engagement measures how closely your activity meets expectations (including the amount of time spent) for a course or piece of content. An engagement score close to 0 indicates less activity than expected to understand the content, such as only watching 30 seconds of a 5 minute video or spending 1 minute reading a 20 page article. Engagement scores close to 100 indicate that your activity met or exceeded expectations.";

export const groupsTooltipText =
	"To add course/thread into a Group enter the Group tag using the format 'grp__<GroupName>'. To add course/thread into a License Group enter the License Group tag using the format 'licensegrp__<GroupName>'. To add course/thread into a Visibility Group enter the Visibility Group tag using the format 'visgrp__<GroupName>'.";

export const recipientsErrors = {
	emptyEmail: "The field cannot be empty",
	validateEmail: "The field contains an invalid email",
	duplicateEmails: "The field cannot contain duplicates",
	lengthEmail: `The email length exceeds the maximum ${MAX_EMAIL_LENGTH} characters.`,
	notActiveUser: "The user is not active",
};

export const loginSettingsError = {
	emptyDomain: "The e-mail domain cannot be empty",
	duplicateDomain: "The e-mail domain cannot contain duplicates",
	maxLengthDomain: (num: number) => `The e-mail domain length must be less than ${num} symbols.`,
	invalidDomain: "Entered e-mail domain is not allowed for registration",
	requiredFieldCompany: "Company is a required field",
	maxCountExcess: "Only 10 items can be added as domains",
};

export const changeB2bStatus = {
	title: "Are you shure that you want to change status?",
	text: "Please confirm changing status from 'Payed'!",
	confirmationButton: "Yes, I`m sure",
	cancelButton: "Cancel",
};

export const promptsDelete = {
	title: "Delete",
	text: "Are you sure you want to delete?",
	confirmationButton: "Yes, I`m sure",
	cancelButton: "No",
};

export const promptsCancel = {
	title: "Cancel",
	text: "Are you sure you want to undo the changes?",
	confirmationButton: "Yes, I`m sure",
	cancelButton: "No",
};

export const promptsChangeCompanyMethod = {
	popupText: `Invite allows User to decide, if he want’s to accept an invitation (e-mail request)<br><br>
			Adding User to company without an invitation may confuse him and is not recommended (e-mail notification)`,
	confirmationButtonLabel: "Invite",
	cancelButtonLabel: "Add",
	title: `Invite the user to the company or add him without an invitation`,
};

export const promptsResetPassword = {
	title: "Reset password",
	text: "The password change link will be sent to your e-mail.",
	resolveMessage: "Done. You will receive a message about changing your password to the specified email address",
};

export const categoryOperations = {
	created: "Category created",
	updated: "Category updated",
	deleted: "Category deleted",
};
