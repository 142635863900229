import isNil from "lodash/isNil";
import { ICourseItemModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseItemModel";
import { ICourseModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseModel";

import { useAppStore } from "store";

interface IOutdatedProps {
	course: ICourseModel;
	chapterId: number;
}

export const useCourseOutdated = (props: Partial<IOutdatedProps>) => {
	const { coursesStore } = useAppStore();
	const { course = coursesStore.currentCourse, chapterId = 0 } = props;
	const activeChapterItems = coursesStore.activeChaptersItems?.[chapterId === 0 ? "null" : chapterId];

	const allActiveItemsCompleted = (items: ICourseItemModel[]): boolean => {
		return course.onceCompleted && items.some((item: ICourseItemModel) => item.isActive && !item.completed);
	};

	const getIsItemsOutdated = (items?: ICourseItemModel[]): boolean => {
		if (!isNil(items) && items?.length) {
			return allActiveItemsCompleted(items);
		}
		return allActiveItemsCompleted(course.courseItems);
	};

	const isZ1Outdated: boolean = course ? course.knowledgeOutdated : coursesStore.currentCourse.knowledgeOutdated;
	const isCourseOutdated: boolean = getIsItemsOutdated();
	const isChapterOutdated: boolean = getIsItemsOutdated(activeChapterItems);

	return { isZ1Outdated, isCourseOutdated, isChapterOutdated };
};
