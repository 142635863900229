import { PeersApi } from "api/controllers/PeersApi";
import { makeAutoObservable, runInAction } from "mobx";
import { ICreateThreadModel } from "models/dto/ZoomiLxp/Models/Peers/ICreateThreadModel";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import { IUpdateThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IUpdateThreadModel";
import { ICreatePostModel } from "models/dto/ZoomiLxp/Models/Peers/ICreatePostModel";
import { IPostModel } from "models/dto/ZoomiLxp/Models/Peers/IPostModel";
import { AppStore } from "../index";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { ISaveEventThreadHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventThreadHistoryRequestModel";
import { EventApi } from "api/controllers/EventApi";
import { ISaveEventPostHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventPostHistoryRequestModel";
import { IReplyPostModel } from "models/dto/ZoomiLxp/Models/Peers/IReplyPostModel";
import { IUpdatePostModel } from "models/dto/ZoomiLxp/Models/Peers/IUpdatePostModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { IRenameDiscussionModel } from "models/dto/ZoomiLxp/Models/Peers/IRenameDiscussionModel";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { CarouselDataType } from "components/partial/carousels-array/carousel-data-provider";
import { defaultCarouselRecordsParams, getParams, getUpdatedParams } from "helpers/carousel.helper";
import { requestErrorHandler } from "api/utils/api";
import { getInitParams } from "helpers/filter.helper";
import { initialCarouselPagingParams } from "helpers/params.helper";

export class PeersStore {
	private rootStore;
	private _isLoading: boolean = false;
	private _isRepliesLoading: boolean = false;
	private _isPostLoading: boolean = false;
	trending: IThreadModel[] = [];
	createdByYou: IThreadModel[] = [];
	curatedForYou: IThreadModel[] = [];
	lastSaved: IThreadModel[] = [];
	otherPeers: IThreadModel[] = [];
	findedPeers: IThreadModel[] = [];
	private _currentThread: IThreadModel = {} as IThreadModel;
	private _posts: IPostModel[] = [];
	private _thread: IThreadModel = {} as IThreadModel;
	private _shareId: number | null = null;
	private _parentContainerHeight: number = 1000;
	private _chartContainerHeight: number = 90;
	private _threadsSearchParams: IQueryParams = getInitParams();
	private _loadingGroup: {
		[key: string]: boolean;
	} = {
		trendingThreads: false,
		createdByYouThreads: false,
		lastSavedThreads: false,
		recommendedThreads: false,
	};
	private _peersCarouselsPagingParams: {
		[key: string]: IGetRecordsResponse<CarouselDataType>;
	} = {
		trendingThreads: defaultCarouselRecordsParams,
		createdByYouThreads: defaultCarouselRecordsParams,
		lastSavedThreads: defaultCarouselRecordsParams,
		recommendedThreads: defaultCarouselRecordsParams,
	};

	constructor(rootStore: AppStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);
	}

	async getThreadsByGroups(count: number = 10) {
		runInAction(() => {
			this.isLoading = true;
		});
		const pagingParams = { take: count, skip: 0 } as IPagingParams;

		try {
			await Promise.allSettled([
				this.getTrendingThreads(pagingParams),
				this.getCreatedByYouThreads(pagingParams),
				this.getLastSavedThreads(pagingParams),
				this.getRecommendedThreads(pagingParams),
			]);
		} finally {
			runInAction(() => {
				this.isLoading = false;
			});
		}
	}

	async getTrendingThreads(params: IPagingParams) {
		runInAction(() => {
			this._loadingGroup = { ...this._loadingGroup, trendingThreads: true };
		});
		try {
			const trendingThreads = await PeersApi.trendingThreads(params);
			const newParams = getParams(trendingThreads.data.data);
			const oldParams = this._peersCarouselsPagingParams.trendingThreads;
			runInAction(() => {
				this.trending = trendingThreads.data.data.records;
				this._peersCarouselsPagingParams = {
					...this._peersCarouselsPagingParams,
					trendingThreads: getUpdatedParams(newParams, oldParams),
				};
			});

			return trendingThreads.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this._loadingGroup, trendingThreads: false };
			});
		}
	}

	async getCreatedByYouThreads(params: IPagingParams) {
		runInAction(() => {
			this._loadingGroup = { ...this._loadingGroup, createdByYouThreads: true };
		});
		try {
			const createdByYouThreads = await PeersApi.createdByYouThreads(params);
			const newParams = getParams(createdByYouThreads.data.data);
			const oldParams = this._peersCarouselsPagingParams.createdByYouThreads;
			runInAction(() => {
				this.createdByYou = createdByYouThreads.data.data.records;
				this._peersCarouselsPagingParams = {
					...this._peersCarouselsPagingParams,
					createdByYouThreads: getUpdatedParams(newParams, oldParams),
				};
			});
			return createdByYouThreads.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this._loadingGroup, createdByYouThreads: false };
			});
		}
	}

	async getLastSavedThreads(params?: IPagingParams, keepStateAfterEvent?: boolean) {
		runInAction(() => {
			this._loadingGroup = { ...this._loadingGroup, lastSavedThreads: true };
		});
		try {
			const lastSavedThreads = await PeersApi.lastSavedThreads(params ?? initialCarouselPagingParams);
			const newParams = getParams(lastSavedThreads.data.data);
			const oldParams = this._peersCarouselsPagingParams.lastSavedThreads;
			runInAction(() => {
				this.lastSaved = lastSavedThreads.data.data.records;
				this._peersCarouselsPagingParams = {
					...this._peersCarouselsPagingParams,
					lastSavedThreads: keepStateAfterEvent ? newParams : getUpdatedParams(newParams, oldParams),
				};
			});
			return lastSavedThreads.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this._loadingGroup, lastSavedThreads: false };
			});
		}
	}

	async getRecommendedThreads(params: IPagingParams) {
		runInAction(() => {
			this._loadingGroup = { ...this._loadingGroup, recommendedThreads: true };
		});
		try {
			const recommendedThreads = await PeersApi.recommendedThreads(params);
			const newParams = getParams(recommendedThreads.data.data);
			const oldParams = this._peersCarouselsPagingParams.recommendedThreads;
			runInAction(() => {
				this.curatedForYou = recommendedThreads.data.data.records;
				this._peersCarouselsPagingParams = {
					...this._peersCarouselsPagingParams,
					recommendedThreads: getUpdatedParams(newParams, oldParams),
				};
			});
			return recommendedThreads.data.data;
		} finally {
			runInAction(() => {
				this._loadingGroup = { ...this._loadingGroup, recommendedThreads: false };
			});
		}
	}

	async getAuthors() {
		try {
			const response = await PeersApi.getAuthors();
			return response.data.data?.authors;
		} catch (err) {
			requestErrorHandler(err);
		}
	}

	async getSubjects() {
		try {
			const response = await PeersApi.getSubjects();
			return response.data.data?.subjects;
		} catch (err) {
			requestErrorHandler(err);
		}
	}

	async addNewThread(data: ICreateThreadModel, courseItemId?: number) {
		this.isLoading = true;
		try {
			const response = await PeersApi.addNewThread(data);
			if (courseItemId) await this.getCurrentThread(courseItemId);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async updateThread(threadId: number, data: IUpdateThreadModel) {
		this.isLoading = true;
		try {
			const response = await PeersApi.updateThread(threadId, data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getThreadById(threadId: number) {
		this.isLoading = true;
		try {
			const response = await PeersApi.getThreadById(threadId);
			this._thread = response.data.data;
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async addThreadPost(data: ICreatePostModel, courseItemId: number) {
		this.isLoading = true;
		try {
			const response = await PeersApi.addPost(data);
			await this.getCurrentThread(courseItemId);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async deleteReply(replyId: number) {
		this.isRepliesLoading = true;
		try {
			const response = await PeersApi.deleteReply(replyId);
			return response.data.data;
		} finally {
			this.isRepliesLoading = false;
		}
	}

	async getCurrentThread(courseItemId: number) {
		this.isLoading = true;
		this.clearCurrentData();
		try {
			const response = await PeersApi.getCourseItemThreadDetails(courseItemId);
			const result = response.data.data;

			if (!result || result?.id === 0) {
				runInAction(() => {
					this._currentThread = {} as IThreadModel;
					this._posts = [];
				});
			} else {
				runInAction(() => {
					this._currentThread = response.data.data;
				});
			}
		} finally {
			this.isLoading = false;
		}
	}

	clearCurrentData() {
		this._currentThread = {} as IThreadModel;
		this._posts = [];
	}

	async getRunningThread() {
		const id = this.rootStore.coursesStore.curActiveContent.courseItemId;
		if (id) await this.getCurrentThread(id);
	}

	async addPost(data: ICreatePostModel) {
		this.isLoading = true;
		try {
			const response = await PeersApi.addPost(data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getPosts(threadId: number, queryParams: IQueryParams = getInitParams()) {
		this.isLoading = true;
		try {
			const responsePosts = await PeersApi.getPosts(threadId, queryParams);
			runInAction(() => {
				this._posts = responsePosts.data.data.records;
			});
		} finally {
			this.isLoading = false;
		}
	}

	async getThreadPosts(threadId: number, queryParams: IQueryParams) {
		this.isLoading = true;
		try {
			const responsePosts = await PeersApi.getPosts(threadId, queryParams);
			return responsePosts.data.data;
		} catch (err) {
			requestErrorHandler(err);
		} finally {
			this.isLoading = false;
		}
	}

	async saveEventThreadHistory(data: ISaveEventThreadHistoryRequestModel) {
		try {
			return await EventApi.saveEventThreadHistory(data);
		} catch (err) {
			requestErrorHandler(err);
		}
	}

	async saveEventPostHistory(data: ISaveEventPostHistoryRequestModel) {
		try {
			return await EventApi.saveEventPostHistory(data);
		} catch (err) {
			requestErrorHandler(err);
		}
	}

	async getReplies(postId: number, queryParams: IQueryParams = getInitParams()) {
		const responsePosts = await PeersApi.getReplies(postId, queryParams);
		return responsePosts.data.data.records;
	}

	async replyOnPost(data: IReplyPostModel) {
		this.isLoading = true;
		try {
			return await PeersApi.replyOnPost(data);
		} catch (err) {
			requestErrorHandler(err);
		} finally {
			this.isLoading = false;
		}
	}

	async updatePost(postId: number, data: IUpdatePostModel) {
		this.isPostLoading = true;
		try {
			const response = await PeersApi.updatePost(postId, data);
			return response.data.data;
		} catch (err) {
			requestErrorHandler(err);
		} finally {
			this.isPostLoading = false;
		}
	}

	async searchCMSThreads() {
		this.isLoading = true;
		try {
			this.threadsSearchParams = this.rootStore.searchStore.params;
			const response = await PeersApi.getAllThreadsByQuery(this.threadsSearchParams);
			return response.data.data;
		} catch (err) {
			requestErrorHandler(err);
		} finally {
			this.isLoading = false;
		}
	}

	async getAll(queryParams: IQueryParams) {
		const response = await PeersApi.searchThreadsByQuery(queryParams);
		return response.data.data;
	}

	async renameDiscussion(threadId: number, data: IRenameDiscussionModel) {
		const response = await PeersApi.renameDiscussion(threadId, data);
		return response.data.data;
	}

	get isLoading() {
		return this._isLoading;
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get threadsSearchParams() {
		return this._threadsSearchParams;
	}

	set threadsSearchParams(params: IQueryParams) {
		this._threadsSearchParams = params;
	}

	get peersCarouselsPagingParams() {
		return this._peersCarouselsPagingParams;
	}

	set isRepliesLoading(isRepliesLoading: boolean) {
		this._isRepliesLoading = isRepliesLoading;
	}

	get isRepliesLoading() {
		return this._isRepliesLoading;
	}

	set isPostLoading(isPostLoading: boolean) {
		this._isPostLoading = isPostLoading;
	}

	get isPostLoading() {
		return this._isPostLoading;
	}

	get currentThread(): IThreadModel {
		return this._currentThread;
	}

	get posts(): IPostModel[] {
		return this._posts;
	}

	get thread(): IThreadModel {
		return this._thread;
	}

	get shareId(): number | null {
		return this._shareId;
	}

	set shareId(value: number | null) {
		this._shareId = value;
	}

	get parentContainerHeight(): number {
		return this._parentContainerHeight;
	}

	set parentContainerHeight(value: number) {
		this._parentContainerHeight = value;
	}

	get chartContainerHeight(): number {
		return this._chartContainerHeight;
	}

	set chartContainerHeight(value: number) {
		this._chartContainerHeight = value;
	}

	get loadingGroup(): {
		[key: string]: boolean;
	} {
		return this._loadingGroup;
	}

	clearStore() {
		runInAction(() => {
			this.isLoading = false;
			this._isRepliesLoading = false;
			this.trending = [];
			this.createdByYou = [];
			this.curatedForYou = [];
			this.lastSaved = [];
			this.otherPeers = [];
			this.findedPeers = [];
			this._currentThread = {} as IThreadModel;
			this._posts = [];
			this._thread = {} as IThreadModel;
			this._shareId = null;
			this._loadingGroup = {
				trendingThreads: false,
				createdByYouThreads: false,
				lastSavedThreads: false,
				recommendedThreads: false,
			};
			this._peersCarouselsPagingParams = {
				trendingThreads: defaultCarouselRecordsParams,
				createdByYouThreads: defaultCarouselRecordsParams,
				lastSavedThreads: defaultCarouselRecordsParams,
				recommendedThreads: defaultCarouselRecordsParams,
			};
			this.threadsSearchParams = getInitParams();
		});
	}
}
