import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { IContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentModel";
import isEmpty from "lodash/isEmpty";
import PlayerHtml from "../player-html/player-html";
import { ContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentTypes";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import PlayerControlMini from "../player-control-mini/player-control-mini";
import { useScormDifficult } from "hooks/useScormDifficult";
import { IPlayerPropsBase } from "../data/data";

interface IPlayerHtmlBoxProps extends IPlayerPropsBase {
	content: IContentModel;
}

const cx = classNames.bind(styles);

const PlayerHtmlBox = (props: IPlayerHtmlBoxProps) => {
	const { content, className, onEventHandle } = props;

	const { contentStore } = useAppStore();

	let src = "";
	if (content?.id && content.contentType === ContentTypes.Zip) src = `/api/v1/LMS/PackageContent/${content.id}/`;

	const sendStart = () => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Start,
			payload: "",
		};
		Promise.allSettled([
			contentStore.sendEventCourseItemHistory(data),
			contentStore.sendEventCourseItemHistory({ ...data, eventType: EventTypes.Complete }),
		]);
	};

	const { params, onLoad } = useScormDifficult(sendStart);

	if (isEmpty(content.fileContent?.url)) return <div className={styles.player_html_box__nodata}>No data</div>;
	if (!src) {
		src = content.fileContent?.url;
	}

	return (
		<div className={cx(styles.player_html_box, className)}>
			<PlayerHtml src={src} params={params} onLoad={onLoad} onEventHandle={onEventHandle}>
				<PlayerControlMini params={params} />
			</PlayerHtml>
		</div>
	);
};

export default observer(PlayerHtmlBox);
