import { makeAutoObservable, runInAction } from "mobx";
import { IAssessmentModel } from "models/dto/ZoomiLxp/Models/Assessments/IAssessmentModel";
import { AssessmentsApi } from "api/controllers/AssesstmentsApi";
import { ICreateAssessmentModel } from "models/dto/ZoomiLxp/Models/Assessments/ICreateAssessmentModel";
import { IUpdateAssessmentModel } from "models/dto/ZoomiLxp/Models/Assessments/IUpdateAssessmentModel";
import { makePersistable } from "mobx-persist-store";
import { INextQuestionModel } from "models/dto/ZoomiLxp/Models/Assessments/INextQuestionModel";
import { ISaveAnswerRequest } from "models/dto/ZoomiLxp/Models/Assessments/ISaveAnswerRequest";
import isNull from "lodash/isNull";
import { Theme, initQuiz } from "./data";
import { IFinishAssessmentAttemptResponse } from "models/dto/ZoomiLxp/Models/Assessments/IFinishAssessmentAttemptResponse";
import { IAssessmentResultModel } from "models/dto/ZoomiLxp/Models/Assessments/IAssessmentResultModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { downloadFile } from "helpers/file-download.helper";
import { ICreateQuestionModel } from "models/dto/ZoomiLxp/Models/Assessments/ICreateQuestionModel";
import { ICreateAnswerModel } from "models/dto/ZoomiLxp/Models/Assessments/ICreateAnswerModel";
import { ICreateCompletionMessageModel } from "models/dto/ZoomiLxp/Models/Assessments/ICreateCompletionMessageModel";
import { ICreateAssessmentAttemptResponse } from "models/dto/ZoomiLxp/Models/Assessments/ICreateAssessmentAttemptResponse";
import { IGetLastAssessmentAttemptRequest } from "../../models/dto/ZoomiLxp/Models/Assessments/IGetLastAssessmentAttemptRequest";
import { ISaveAnswerResponse } from "models/dto/ZoomiLxp/Models/Assessments/ISaveAnswerResponse";

export class QuizStore {
	private _isLoading: boolean = false;
	private _quiz: IAssessmentModel = initQuiz;
	theme: Theme = "light";
	private _attemptId: number | null = null;
	private _curQuestion: INextQuestionModel = {} as INextQuestionModel;
	private _indexQuestion: number = 1;
	private _questionsCount: number = 0;
	private _finishAssessment: IFinishAssessmentAttemptResponse | undefined = undefined;
	private _quizResults: IAssessmentResultModel[] = [];
	private _coursesWithQuizResults: IPreviewCourseModel[] = [];
	private _answerCheckResult: ISaveAnswerResponse | undefined = undefined;

	constructor() {
		makeAutoObservable(this);

		makePersistable(this, {
			name: "quizStore",
			properties: ["theme"],
			storage: window.localStorage,
		});
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	set isLoading(value: boolean) {
		this._isLoading = value;
	}

	get quiz(): IAssessmentModel {
		return this._quiz;
	}

	set quiz(value: IAssessmentModel) {
		this._quiz = value;
	}

	async createQuiz(data: ICreateAssessmentModel) {
		this.isLoading = true;
		try {
			const response = await AssessmentsApi.createQuiz(data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	initQuiz() {
		this._quiz = initQuiz;
	}

	async getQuiz(id: number) {
		this.isLoading = true;
		try {
			const response = await AssessmentsApi.getAssessment(id);
			runInAction(() => {
				this._quiz = response.data.data;
			});
		} finally {
			this.isLoading = false;
		}
	}

	async importQuiz(fileId: number) {
		this.isLoading = true;
		try {
			const response = await AssessmentsApi.importQuiz(fileId);
			runInAction(() => {
				const quiz = response.data.data.assessment;
				this._quiz = {
					...quiz,
					questions: quiz.questions?.map((question: ICreateQuestionModel) => ({
						...question,
						id: 0,
						author: {
							id: 0,
							username: "",
							email: "",
							firstName: "",
							lastName: "",
							displayName: "",
							userPicture: { id: 0, url: "" },
						},
						created: Date.now(),
						answerOptions: question?.answers?.map((a: ICreateAnswerModel) => ({ ...a, id: 0 })),
					})),
					isActive: this._quiz.isActive,
					chapter: undefined,
					id: 0,
					created: Date.now(),
					author: { ...initQuiz.author },
					courseId: 0,
					completionMessages: quiz.completionMessages?.map((e: ICreateCompletionMessageModel) => ({
						id: 0,
						text: e.text,
						percent: e.percent,
					})),
					attempted: false,
				};
			});
		} finally {
			this.isLoading = false;
		}
	}

	async exportQuiz(quizId: number) {
		this.isLoading = true;
		try {
			await AssessmentsApi.exportQuiz(quizId).then((response) => {
				downloadFile(response);
			});
		} finally {
			this.isLoading = false;
		}
	}

	async getQuizResults(courseId: number, data: IPagingParams, userId?: number) {
		this.isLoading = true;
		try {
			const response = userId
				? await AssessmentsApi.getUserQuizResults(userId, courseId, data)
				: await AssessmentsApi.getQuizResults(courseId, data);
			runInAction(() => {
				this._quizResults = response.data.data.records;
			});
			return response;
		} finally {
			this.isLoading = false;
		}
	}

	set quizResults(data: IAssessmentResultModel[]) {
		this._quizResults = data;
	}

	get quizResults(): IAssessmentResultModel[] {
		return this._quizResults;
	}

	async getCoursesWithQuizResults(data: IPagingParams, userId?: number) {
		this.isLoading = true;
		try {
			const response = userId
				? await AssessmentsApi.getUserCoursesWithQuizResults(data, userId)
				: await AssessmentsApi.getCoursesWithQuizResults(data);
			runInAction(() => {
				this._coursesWithQuizResults = response.data.data.records;
			});
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	get coursesWithQuizResults(): IPreviewCourseModel[] {
		return this._coursesWithQuizResults;
	}

	async changeQuiz(assessmentId: number, data: IUpdateAssessmentModel) {
		this.isLoading = true;
		try {
			const response = await AssessmentsApi.changeQuiz(assessmentId, data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	setTheme(theme: Theme) {
		this.theme = theme;
	}

	get attemptId(): number | null {
		return this._attemptId;
	}

	get curQuestion(): INextQuestionModel {
		return this._curQuestion;
	}

	async createAttempt(assessmentId: number) {
		try {
			const response = await AssessmentsApi.createAttempt(assessmentId);
			runInAction(() => {
				const attemptResponse: ICreateAssessmentAttemptResponse = response.data.data;
				this._attemptId = attemptResponse.attemptId;
				this._questionsCount = attemptResponse.questionsCount + 1;
			});
			return response.data.data;
		} catch (err) {
			throw err;
		}
	}

	async nextQuestion() {
		this.isLoading = true;
		try {
			const response = await AssessmentsApi.nextQuestion(Number(this._attemptId));
			if (isNull(response.data.data)) {
				const result = await AssessmentsApi.finishAttempt(Number(this._attemptId));
				runInAction(() => {
					this._finishAssessment = result.data.data;
				});
			} else {
				runInAction(() => {
					this._curQuestion = response.data.data;
					this.indexQuestion = this.curQuestion.questionNumber;
				});
				return response.data.data;
			}
		} finally {
			this.isLoading = false;
		}
	}

	get indexQuestion(): number {
		return this._indexQuestion;
	}

	set indexQuestion(index: number) {
		this._indexQuestion = index;
	}

	get questionsCount(): number {
		return this._questionsCount;
	}

	async saveAnswer(data: ISaveAnswerRequest) {
		this.isLoading = true;
		try {
			const response = await AssessmentsApi.saveAnswer(data);
			runInAction(() => {
				this.answerCheckResult = response.data.data;
			});
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	clearStore(isTryAgain?: boolean) {
		if (!isTryAgain) {
			this._quiz = initQuiz;
		}
		this._attemptId = null;
		this._curQuestion = {} as INextQuestionModel;
		this._indexQuestion = 0;
		this._questionsCount = 0;
		this._finishAssessment = undefined;
		this._answerCheckResult = undefined;
	}

	get finishAssessment(): IFinishAssessmentAttemptResponse | undefined {
		return this._finishAssessment;
	}

	async getLastAttempt(assessmentId: number) {
		runInAction(() => {
			this.isLoading = true;
			this.clearStore();
		});
		try {
			const response = await AssessmentsApi.getLastAttempt({ assessmentId } as IGetLastAssessmentAttemptRequest);
			const responseLastAttempt = response.data.data;
			if (isNull(responseLastAttempt)) {
				await this.createAttempt(assessmentId);
			} else {
				runInAction(() => {
					this._attemptId = responseLastAttempt.attemptId;
					this._questionsCount = responseLastAttempt.questionsCount;
					this.indexQuestion = this.questionsCount;
					if (responseLastAttempt.isFinished) {
						this._finishAssessment = {
							assessmentId,
							attemptId: responseLastAttempt.attemptId,
							isPassed: responseLastAttempt.isPassed ?? false,
							learnerPercent: responseLastAttempt.learnerPercent ?? 0,
							learnerScore: responseLastAttempt.learnerScore ?? 0,
							percentToPass: responseLastAttempt.percentToPass ?? 0,
						};
					} else {
						this._finishAssessment = undefined;
					}
				});
			}
			return response.data.data;
		} finally {
			runInAction(() => {
				this.isLoading = false;
			});
		}
	}

	get answerCheckResult(): ISaveAnswerResponse | undefined {
		return this._answerCheckResult;
	}

	set answerCheckResult(results: ISaveAnswerResponse | undefined) {
		this._answerCheckResult = results;
	}
}
