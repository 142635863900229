import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { IContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentModel";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import PlayerVideo from "../player-video/player-video";
import PlayerControl, { ControlParams } from "../player-control/player-control";
import ReactPlayer from "react-player/lazy";
import { Duration } from "luxon";
import { useAppStore } from "store";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { usePlayerControl } from "hooks/usePlayerControl";
import { usePlayerNav } from "hooks/usePlayerNav";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { ISegmentPositions } from "../player-layout/player-layout";
import { ISetPositionPayload } from "models/dto/ZoomiLxp/Models/EventModels/Payloads/ISetPositionPayload";
import { IStartPayload } from "models/dto/ZoomiLxp/Models/EventModels/Payloads/IStartPayload";
import { IPlayerPropsBase } from "../data/data";

interface IPlayerVideoProps extends IPlayerPropsBase {
	content: IContentModel;
	segment?: ISegmentPositions;
	autoPlay?: boolean;
}

const PlayerVideoBox = ({ content, autoPlay, className, segment, onEventHandle }: IPlayerVideoProps) => {
	const { contentStore, coursesStore, playerStore, toasterStore } = useAppStore();
	const calledOnce = useRef(false);
	const calledOnceTwo = useRef(false);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const handlePlay = async () => {
		if (segment) {
			const currentProgress = playerStore?.params?.currentProgress ?? 0;
			if (
				currentProgress > 0 &&
				(currentProgress < Math.round(segment.startPosition) || currentProgress >= Math.round(segment.endPosition))
			)
				return;
		}

		setParams((prevState: ControlParams) => ({ ...prevState, playing: true }));

		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Play,
			payload: JSON.stringify({
				currentPosition: params.playerRef?.current?.getCurrentTime(),
				isPlaying: true,
				currentPlaybackRate: params.speed!,
			}),
		};
		await onEventHandle(data);
		await contentStore.startKeepAlive();
	};

	const handlePause = async () => {
		setParams((prevState: ControlParams) => ({ ...prevState, playing: false }));
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Pause,
			payload: JSON.stringify({
				currentPosition: params.playerRef?.current?.getCurrentTime(),
				isPlaying: false,
				currentPlaybackRate: params.speed!,
			}),
		};
		await onEventHandle(data);
	};

	const handleVolumeMute = () => {
		setParams((prevState: ControlParams) => ({ ...prevState, muted: true, volume: 0 }));
	};

	const handleVolumeUnmute = (volume?: number) => {
		setParams((prevState: ControlParams) => ({ ...prevState, muted: false, volume: volume ?? 0.5 }));
	};

	const handleStart = async () => {
		try {
			if (contentStore.sessionId) {
				const currentTime = params.playerRef?.current?.getCurrentTime();
				const startPayload: IStartPayload = {
					currentPosition: currentTime ? currentTime : 0,
					isPlaying: params.playing,
					currentPlaybackRate: params.speed ? params.speed : 1,
				};

				contentStore.sendEvent(EventTypes.Start, startPayload);
			}
		} catch (err) {
			toasterStore.showErrorMessage(err);
		}
	};

	const handleSeekChange = async (value: number) => {
		if (segment && (value < segment.startPosition || value > segment.endPosition)) return;
		try {
			const currentTime = params.playerRef?.current?.getCurrentTime();
			params.playerRef?.current?.seekTo(value, "seconds");

			const setPositionPayload: ISetPositionPayload = {
				currentPosition: currentTime ? currentTime : 0,
				isPlaying: params.playing,
				currentPlaybackRate: params.speed ? params.speed : 1,
				newPosition: value,
			};
			const data: ISaveEventCourseItemHistoryModel = {
				sessionId: contentStore?.sessionId ?? 0,
				eventType: EventTypes.SetPosition,
				payload: JSON.stringify(setPositionPayload),
			};

			await onEventHandle(data);
		} catch (err) {
			toasterStore.showErrorMessage(err);
		}
	};

	const handleVolumeLevel: React.FormEventHandler<HTMLInputElement> | undefined = (e) => {
		setParams((prevState: ControlParams) => ({
			...prevState,
			volume: Number((e.target as HTMLInputElement).value),
		}));
	};

	const getFormattedProgress = (seconds: number): string => {
		const duration = Duration.fromObject({ seconds });
		return duration.toFormat("h:mm:ss");
	};

	const handleSpeed = async (speed: number) => {
		setParams((prevState: ControlParams) => ({ ...prevState, speed }));

		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.SetPlaybackRate,
			payload: JSON.stringify({
				currentPosition: params.playerRef?.current?.getCurrentTime(),
				currentPlaybackRate: params.playerRef?.current?.props.playbackRate,
				newPlaybackRate: speed,
				isPlaying: params.playing,
			}),
		};
		await onEventHandle(data);
	};
	const { prev, next } = usePlayerNav();

	const handlePrev = async () => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.End,
			payload: JSON.stringify({
				currentPosition: params.playerRef?.current?.getCurrentTime(),
				isPlaying: params.playing,
			}),
		};
		contentStore.stopKeepAlive();
		await onEventHandle(data).then(() => prev());
	};

	const handleNext = async () => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.End,
			payload: JSON.stringify({
				currentPosition: params.playerRef?.current?.getCurrentTime(),
				isPlaying: params.playing,
			}),
		};
		contentStore.stopKeepAlive();
		await onEventHandle(data).then(() => {
			next();
		});
	};

	const handleComplete = async () => {
		setParams((prevState: ControlParams) => ({ ...prevState, playing: false }));
		contentStore.stopKeepAlive();

		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Complete,
			payload: JSON.stringify({
				currentPosition: params.playerRef?.current?.getCurrentTime(),
				isPlaying: params.playing,
				currentPlaybackRate: params.speed!,
			}),
		};
		try {
			await onEventHandle(data);
			coursesStore.curActiveContent.completed = true;
		} catch (err) {
			toasterStore.showErrorMessage(err);
		}

		if (!coursesStore.isPartCourse) {
			if (coursesStore.curPositionContent + 1 < coursesStore.countContents) coursesStore.curPositionContent++;
		}
	};

	const initParams: ControlParams = {
		currentProgress: 0,
		totalProgress: 0,
		boxRef: useRef<HTMLDivElement>(null),
		playing: false,
		volume: 0.8,
		muted: false,
		playerRef: useRef<ReactPlayer>(null),
		speed: 1,
		isReady: false,
		handleStart,
		handlePlay,
		handlePause,
		handleVolumeMute,
		handleVolumeUnmute,
		handleSeekChange,
		handleVolumeLevel,
		getFormattedProgress,
		handleSpeed,
		handleNext,
		handlePrev,
		handleComplete,
		isDownload: false,
		urlDownload: "",
	};

	const [params, setParams] = React.useState<ControlParams>(initParams);

	useEffect(() => {
		playerStore.params = params;
	}, [params, playerStore]);

	useEffect(() => {
		setParams((prevState: ControlParams) => ({ ...prevState, playing: autoPlay ? autoPlay : false }));
	}, [autoPlay]);

	usePlayerControl(setParams);

	useEffect(() => {
		if (params.isReady && !calledOnce.current) {
			if (segment) {
				playerStore.params?.playerRef?.current?.seekTo(segment.startPosition, "seconds");
			}
			if (coursesStore.lastWatchedCourseItem.position) {
				playerStore.params?.playerRef?.current?.seekTo(coursesStore.lastWatchedCourseItem.position, "seconds");
			}
			calledOnce.current = true;
		}
	}, [params.isReady, playerStore.params?.playerRef, segment, coursesStore.lastWatchedCourseItem.position]);

	useEffect(() => {
		if (segment && params.currentProgress >= Math.round(segment.endPosition) && !calledOnceTwo.current) {
			playerStore.params?.handleComplete?.();
			calledOnceTwo.current = true;
		}
	}, [params.currentProgress, playerStore.params, segment]);

	useEffect(() => {
		setParams((prevState: ControlParams) => ({
			...prevState,
			isDownload: content.downloadable,
			urlDownload: content.fileContent.url,
		}));
	}, [content.downloadable, content.fileContent.url]);

	if (isEmpty(content.fileContent?.url)) return <div className={styles.player_video_box__nodata}>No data</div>;

	return (
		<div className={classNames(styles.player_video_box, className)}>
			<PlayerVideo params={params} setParams={setParams} content={content}>
				{isFullscreen && (
					<PlayerControl
						params={params}
						segment={segment}
						zoomLimitMultiplier={2}
						onFullscreenChange={(value) => setIsFullscreen(value)}
					/>
				)}
			</PlayerVideo>
			{!isFullscreen && (
				<PlayerControl
					params={params}
					segment={segment}
					zoomLimitMultiplier={2}
					onFullscreenChange={(value) => setIsFullscreen(value)}
				/>
			)}
		</div>
	);
};

export default PlayerVideoBox;
