import classNames from "classnames";
import Button from "components/base/button/button";
import styles from "./styles.module.scss";

type BuyButtonDataType = {
	buyButtonData: {
		title: string;
		isPrimaryColor: boolean;
		showPrice: boolean;
	};
	handleBuy: () => void;
};

type AssignButtonDataType = {
	assignButtonData:
		| false
		| {
				title: string;
				isPrimaryColor: boolean;
		  };
	handleAssignRequest: () => void;
};

export const CourseBuyButton = ({ buyButtonData, handleBuy }: BuyButtonDataType) => {
	if (!buyButtonData?.title) return <></>;
	return (
		<Button
			label={buyButtonData.title}
			onClick={async (e) => {
				e.preventDefault();
				handleBuy();
			}}
			size="small"
			disabled={!buyButtonData.isPrimaryColor}
			className={classNames(styles.course_card__buy_button, {
				[styles.course_card__secondary]: !buyButtonData.isPrimaryColor,
			})}
		/>
	);
};

export const CourseAssignButton = ({ assignButtonData, handleAssignRequest }: AssignButtonDataType) => {
	if (!assignButtonData) return <></>;
	return (
		<Button
			label={assignButtonData.title}
			onClick={async (e) => {
				e.preventDefault();
				handleAssignRequest();
			}}
			size="small"
			disabled={!assignButtonData.isPrimaryColor}
			className={classNames(styles.course_card__buy_button, {
				[styles.course_card__secondary]: !assignButtonData.isPrimaryColor,
			})}
		/>
	);
};
