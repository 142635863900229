import Api, { abortController } from "api/utils/api";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { ICreateThreadModel } from "models/dto/ZoomiLxp/Models/Peers/ICreateThreadModel";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import { IUpdateThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IUpdateThreadModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { IPostModel } from "models/dto/ZoomiLxp/Models/Peers/IPostModel";
import { ICreatePostModel } from "models/dto/ZoomiLxp/Models/Peers/ICreatePostModel";
import { IReplyPostModel } from "models/dto/ZoomiLxp/Models/Peers/IReplyPostModel";
import { IUpdatePostModel } from "models/dto/ZoomiLxp/Models/Peers/IUpdatePostModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { IRenameDiscussionModel } from "models/dto/ZoomiLxp/Models/Peers/IRenameDiscussionModel";

export class PeersApi {
	static addNewThread(data: ICreateThreadModel) {
		return Api.post<IThreadModel, ICreateThreadModel>("/peers/AddThread", data);
	}

	static updateThread(threadId: number, data: IUpdateThreadModel) {
		return Api.put<IThreadModel>(`/peers/updateThread/${threadId}`, data);
	}

	static getAllThreadsByQuery(data: IQueryParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IThreadModel>, IQueryParams>("/peers/getAll", data, {
			signal: abortController.getSignalToken(),
		});
	}

	static searchThreadsByQuery(data: IQueryParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IThreadModel>, IQueryParams>("/peers/SearchThreads", data);
	}

	static getThreadById(threadId: number) {
		return Api.get<IThreadModel>(`/peers/GetThreadDetails/${threadId}`);
	}

	static getAuthors() {
		return Api.get("/peers/Authors");
	}

	static getSubjects() {
		return Api.get("/peers/Subjects");
	}

	static addPost(data: ICreatePostModel) {
		return Api.post<IPostModel, ICreatePostModel>("/peers/AddPost", data);
	}

	static getCourseThreadDetails(courseId: number) {
		return Api.get<IThreadModel>(`/peers/getCourseThreadDetails/${courseId}`);
	}

	static getThreadDetails(threadId: number) {
		return Api.get<IThreadModel>(`/peers/GetThreadDetails/${threadId}`);
	}

	static getPosts(threadId: number, queryParams: IQueryParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IPostModel>, IQueryParams>(`/peers/posts/${threadId}`, queryParams);
	}

	static getReplies(postId: number, queryParams: IQueryParams) {
		return Api.post<IGetRecordsResponse<IPostModel>, IQueryParams>(`/peers/replies/${postId}`, queryParams);
	}

	static replyOnPost(data: IReplyPostModel) {
		return Api.post<IPostModel, IReplyPostModel>("/peers/ReplyOnPost", data);
	}

	static updatePost(postId: number, data: IUpdatePostModel) {
		return Api.put<IPostModel, IUpdatePostModel>(`/peers/UpdatePost/${postId}`, data);
	}

	static deleteReply(postId: number) {
		return Api.delete<IPostModel>(`/Peers/DeletePost/${postId}`);
	}

	static recommendedThreads(pagingParams: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IThreadModel>, IPagingParams>(
			"/peers/RecommendedThreads",
			pagingParams
		);
	}

	static createdByYouThreads(pagingParams: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IThreadModel>, IPagingParams>(
			"/peers/CreatedByYouThreads",
			pagingParams
		);
	}

	static createdByUserThreads(userId: number, pagingParams: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IThreadModel>, IPagingParams>(
			`/peers/CreatedByUserThreads/${userId}`,
			pagingParams
		);
	}

	static lastSavedThreads(pagingParams: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IThreadModel>, IPagingParams>("/peers/LastSavedThreads", pagingParams);
	}

	static lastSavedThreadsByUserId(userId: number, pagingParams: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IThreadModel>, IPagingParams>(
			`/peers/UserLastSavedThreads/${userId}`,
			pagingParams
		);
	}

	static trendingThreads(pagingParams: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IThreadModel>, IPagingParams>("/peers/TrendingThreads", pagingParams);
	}

	static getCourseItemThreadDetails(courseItemId: number) {
		return Api.get<IThreadModel>(`/peers/GetCourseItemThreadDetails/${courseItemId}`);
	}

	static renameDiscussion(threadId: number, data: IRenameDiscussionModel) {
		return Api.put<IThreadModel>(`/peers/RenameDiscussion/${threadId}`, data);
	}
}
