import { CarouselDataType } from "components/partial/carousels-array/carousel-data-provider";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";

export const CAROUSEL_SLIDE_WIDTH = 400;
export const CAROUSEL_SLIDE_HEIGHT = 500;
export const CAROUSEL_SLIDE_HEIGHT_SMALL = 350;
export const SLIDE_THRESHOLD_VALUE = 1.25;

export const getIsEndOfList = (itemsCount: number, currentSlide: number) => {
	const visibleSlidesCount = Math.round(window.innerWidth / CAROUSEL_SLIDE_WIDTH - 1);
	const slideValue = (itemsCount - currentSlide) / visibleSlidesCount;
	const isEndOfList = slideValue < SLIDE_THRESHOLD_VALUE;

	return isEndOfList;
};

export const defaultCarouselRecordsParams = { totalFilteredRecords: 0, records: [] };

export const getUpdatedParams = (
	newParams: IGetRecordsResponse<CarouselDataType>,
	oldParams: IGetRecordsResponse<CarouselDataType>
): IGetRecordsResponse<CarouselDataType> => {
	return {
		records: oldParams && oldParams.records.length ? [...oldParams.records, ...newParams.records] : newParams.records,
		totalFilteredRecords: newParams.totalFilteredRecords,
	};
};

export const getParams = (response: IGetRecordsResponse<CarouselDataType>) => {
	return {
		records: response.records,
		totalFilteredRecords: response.totalFilteredRecords,
	};
};
