import { useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { IDateRange } from "models/dto/ZoomiLxp/Models/Query/IDateRange";
import { findIndex, isNil } from "lodash";
import { DateTime } from "luxon";
import Button from "components/base/button/button";
import { ReactComponent as Arrow } from "assets/icons/ic_arrow-left.svg";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { IFilterCriterion } from "models/dto/ZoomiLxp/Models/Query/IFilterCriterion";
import update from "immutability-helper";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

export interface Props {
	params: IQueryParams;
	setParams: (value: ((prevState: IQueryParams) => IQueryParams) | IQueryParams) => void;
	label?: string;
	className?: string;
	buttonClassName?: string;
	propertyName?: string;
	isClear?: boolean;
}

const DateFilter = (props: Props) => {
	const { label, className, buttonClassName, params, setParams, propertyName = "UpdatedUtc", isClear } = props;
	const defaultDate = useMemo(() => new Date(), []);
	const [startDate, setStartDate] = useState(() => defaultDate);
	const [endDate, setEndDate] = useState(() => defaultDate);
	const [isOpen, setIsOpen] = useState(false);
	const isAfter =
		DateTime.fromJSDate(endDate).isValid &&
		DateTime.fromJSDate(endDate).startOf("day") > DateTime.fromJSDate(startDate).startOf("day");

	useEffect(() => {
		if (isEmpty(params.filterCriteria)) {
			setStartDate(defaultDate);
			setEndDate(defaultDate);
		}
	}, [defaultDate, params]);

	const calledOnce = useRef(false);

	if (isClear && !calledOnce.current) {
		setTimeout(() => {
			setStartDate(defaultDate);
			isAfter && setEndDate(defaultDate);
			setIsOpen(false);
			calledOnce.current = true;
		}, 0);
	}

	if (!isClear) {
		calledOnce.current = false;
	}

	const changeDates = (dateRange: [Date, Date]) => {
		const [start, end] = dateRange;
		setStartDate(DateTime.fromJSDate(start).startOf("day").toJSDate());
		let newEndDate: Date;
		isNil(end)
			? (newEndDate = DateTime.fromJSDate(start).endOf("day").toJSDate())
			: (newEndDate = DateTime.fromJSDate(end).endOf("day").toJSDate());

		setEndDate(newEndDate);
		onSelect({
			start: DateTime.fromJSDate(start).startOf("day").toJSDate(),
			end: newEndDate,
		} as IDateRange);
	};

	const onSelect = (dateRange: IDateRange) => {
		const idx = findIndex(params.filterCriteria, { propertyNames: [propertyName] });
		const isDateSelectedBefore = !!params.filterCriteria?.find((fc) => fc.propertyNames.includes(propertyName));

		if (!isDateSelectedBefore) {
			const newParams = update<IQueryParams>(params, {
				filterCriteria: {
					$push: [
						{
							propertyNames: [propertyName],
							argument: dateRange.start,
							function: FilterFunction.GreaterThanOrEqual,
						} as IFilterCriterion,
						{
							propertyNames: [propertyName],
							argument: dateRange.end,
							function: FilterFunction.LessThanOrEqual,
						} as IFilterCriterion,
					],
				},
				skip: { $set: 0 },
			});

			setParams(newParams);
		} else {
			const filterItemStart = params.filterCriteria[idx];
			const filterItemEnd = params.filterCriteria[idx + 1];
			if (filterItemStart.argument !== dateRange.start && filterItemEnd !== dateRange.end) {
				const updatedStart = update(filterItemStart, { argument: { $set: dateRange.start } });
				const updatedEnd = update(filterItemEnd, { argument: { $set: dateRange.end } });
				const newParams = update(params, {
					filterCriteria: {
						$splice: [
							[idx, 1, updatedStart],
							[idx + 1, 1, updatedEnd],
						],
					},
					skip: { $set: 0 },
				});
				setParams(newParams);
			}
		}
	};

	return (
		<div className={className}>
			<DatePicker
				selected={startDate}
				onChange={changeDates}
				startDate={startDate}
				endDate={isAfter ? endDate : null}
				selectsRange
				shouldCloseOnSelect={!isNil(endDate) || DateTime.fromJSDate(endDate) !== DateTime.fromJSDate(startDate)}
				onCalendarOpen={() => setIsOpen(true)}
				onCalendarClose={() => setIsOpen(false)}
				open={isOpen}
				popperModifiers={[
					{
						name: "offset",
						options: {
							offset: [40, -5],
						},
					},
				]}
				customInput={
					<div>
						<Button
							type="outlined"
							labelColor="textInput"
							className={classNames(styles.filter_button, buttonClassName)}
							label={
								!!params.filterCriteria?.find((filter: IFilterCriterion) => filter.propertyNames.includes(propertyName))
									? `Start of ${DateTime.fromJSDate(startDate).toLocaleString()} - End of ${DateTime.fromJSDate(
											endDate
									  ).toLocaleString()}`
									: label || "Last updated"
							}
							onClick={() => setIsOpen(!isOpen)}
							icon={
								isOpen ? (
									<Arrow className={classnames(styles.arrow_up)} />
								) : (
									<Arrow className={classnames(styles.arrow_down)} />
								)
							}
							iconPosition="right"
						/>
					</div>
				}
			/>
		</div>
	);
};

export default DateFilter;
