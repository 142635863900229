import { ReactNode, useCallback } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ControlParams } from "../player-control/player-control";
import { useScroll } from "hooks/useScroll";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import { IPlayerPropsBase, scrollByPixels } from "../data/data";

interface PlayerHtmlProps extends IPlayerPropsBase {
	src: string;
	params: ControlParams;
	children?: ReactNode;
	onLoad?: () => void;
}

const PlayerHtml = ({ children, src, className, params, onLoad, onEventHandle }: PlayerHtmlProps) => {
	const { contentStore, coursesStore } = useAppStore();
	const { boxRef } = params;

	const sendScrollEvent = useCallback(
		(posY: number) => {
			const data: ISaveEventCourseItemHistoryModel = {
				sessionId: contentStore?.sessionId ?? 0,
				eventType: EventTypes.Scroll,
				payload: JSON.stringify({ posY }),
			};
			return onEventHandle(data);
		},
		[contentStore, onEventHandle]
	);

	useScroll(boxRef, sendScrollEvent);

	return (
		<div className={classNames(styles.player_html, className)} ref={boxRef}>
			{src !== "" && (
				<iframe
					src={src}
					title="This is a unique title"
					name="html-iframe"
					referrerPolicy="no-referrer"
					className={styles.player_html__document}
					loading="lazy"
					allowFullScreen
					onLoad={() => {
						onLoad?.();
						scrollByPixels(boxRef, coursesStore.lastWatchedCourseItem);
					}}
				/>
			)}
			{children}
		</div>
	);
};

export default observer(PlayerHtml);
