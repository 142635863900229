import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./styles.module.scss";
import classNames from "classnames";

import CmsCourses from "components/partial/cms/cms-courses/cms-courses";
import CmsThreads from "components/partial/cms/cms-threads/cms-threads";
import CmsUsers from "components/partial/cms/cms-users/cms-users";
import ProfileLayout from "layouts/private/profile/profile-layout";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";

import SubMenu from "components/partial/submenu/submenu";
import { getGroupsMenuItems, GroupTabs } from "components/partial/submenu/items";
import { Routes } from "routes";
import { PermissionConstantsCreateOrUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsCreateOrUpdate";
import { PermissionConstantsDelete } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsDelete";

const GroupProfilePage = () => {
	const { params } = useRouteMatch<{ id: string; tab: string }>();
	const history = useHistory();
	const { groupStore, usersStore } = useAppStore();
	const [isDataInitialised, setIsDataInitialised] = useState(false);
	const [groupName, setGroupName] = useState(groupStore.groupData.name);
	const isGroupUsersAllowed = usersStore.checkOneOfUserPermissions([
		PermissionConstantsDelete.GroupCmsManagement,
		PermissionConstantsCreateOrUpdate.AllAccounts,
	]);
	const groupFilter = params.tab ? params.tab : GroupTabs.Courses;

	useEffect(() => {
		(async () => {
			groupStore.isLoading = true;
			await groupStore.getGroupData(Number(params.id));
			if (groupFilter === GroupTabs.Users) {
				groupStore.updateUsersParams();
			} else if (groupFilter === GroupTabs.Threads) {
				groupStore.updateThreadsParams();
			} else {
				groupStore.updateCourseParams();
			}

			setGroupName(groupStore.groupData.name);
			setIsDataInitialised(true);
			groupStore.isLoading = false;
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ProfileLayout onBackButtonClick={() => history.push(Routes.CmsGroups)}>
			<div className={styles.group_profile}>
				<div className={styles.group_profile__tile_wrapper}>
					<div className={styles.group_profile__title}>{groupName}</div>
				</div>
				<div className={classNames(styles.group_profile__tile_wrapper, styles.group_profile__button_wrapper)}>
					<SubMenu
						className={styles.group_profile_layout__submenu}
						menuItems={getGroupsMenuItems(params.id, isGroupUsersAllowed)}
					/>
				</div>
				<LoadingIndicator loading={groupStore.isLoading} spinnerPosition="center" backgroundStyle={"white"}>
					{isDataInitialised && (
						<>
							{groupFilter === GroupTabs.Courses && <CmsCourses isHideFilter />}
							{groupFilter === GroupTabs.Threads && <CmsThreads isHideFilter />}
							{groupFilter === GroupTabs.Users && <CmsUsers isHideFilter />}
						</>
					)}
				</LoadingIndicator>
			</div>
		</ProfileLayout>
	);
};

export default observer(GroupProfilePage);
