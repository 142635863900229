import { useAppStore } from "store";

type Props = {
	items?: number;
};

export const TextInfoData = ({ items }: Props) => {
	const { quizStore } = useAppStore();
	if (!quizStore.isLoading && !quizStore.coursesWithQuizResults.length && !items) {
		return <p>You don't have any quiz results yet.</p>;
	}
	return <></>;
};
