export const railStyle = {
	backgroundColor: "#333333",
};

export const trackStyle = (color: string) => ({
	backgroundColor: color,
});

export const dotStyle = {
	backgroundColor: "#333333",
	border: "none",
};

export const activeDotStyle = (color: string) => ({
	backgroundColor: color,
});

export const handleStyle = (color: string) => ({
	backgroundColor: color,
	width: "32px",
	height: "32px",
	border: "solid 8px rgba(0, 0, 0, .5)",
	marginTop: "-13px",
});
