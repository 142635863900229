import styles from "./styles.module.scss";
import { ReactComponent as LikeIcon } from "assets/icons/ic_heart.svg";
import { ReactComponent as ClockIcon } from "assets/icons/ic_clock.svg";
import { ReactComponent as BookIcon } from "assets/icons/ic_book.svg";
import { ReactComponent as CourseIcon } from "assets/icons/ic_type_course.svg";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import ButtonBookmark from "components/partial/button-bookmark/button-bookmark";
import { mobileCheck } from "helpers/device.helper";
import { useAppStore } from "store";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { ICourseModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseModel";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import Button from "components/base/button/button";
import { ReactComponent as TimestampsIcon } from "assets/icons/ic_timestamps.svg";
import { ReactComponent as AssignmentsIcon } from "assets/icons/ic_assignments-arrow.svg";
import { ReactComponent as NotesIcon } from "assets/icons/ic_sticky_note.svg";
import CourseCardTimestamps from "./components/course-card-timestamps/course-card-timestamps";
import CourseCardBox from "./components/course-card-box/course-card-box";
import CourseCardAssignments from "./components/course-card-assignments/course-card-assignments";
import { getThumbnail } from "helpers/image.helper";
import { ReactComponent as ShareIcon } from "assets/icons/ic_share.svg";
import { generatePath, matchPath, useHistory, useLocation } from "react-router-dom";
import { Routes } from "routes";
import { copyTextToClipboard } from "helpers/clipboard.helper";
import CourseCardNotes from "./components/course-card-notes/course-card-notes";
import { ReactComponent as EditIcon } from "assets/icons/ic_edit.svg";
import { ReactComponent as ProgressIcon } from "assets/icons/ic_pulse.svg";
import { ContentItemTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentItemTypes";
import filter from "lodash/filter";
import size from "lodash/size";
import isNull from "lodash/isNull";
import ChaptersList from "../chapters/chapters-list/chapters-list";
import Description from "components/partial/description/description";
import { getCourseItemName } from "helpers/content.helper";
import { infoMessages } from "constants/messages";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";
import { PermissionConstantsUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsUpdate";
import { DEFAULT_COLOR } from "constants/colors";
import ZStatsBlock from "../z-stats-block/z-stats-block";
import { useCourseOutdated } from "hooks/useCourseOutdated";
import RatingBlock from "./components/course-card-rating-block/rating-block";
import ViewsCounter from "../views-counter/views-counter";
import AlertTooltip from "../icons/alert-tooltip";

interface Props {
	cardStyle?: "default" | "light";
	className?: string;
	disableRating?: boolean;
	type?: "landing";
}

const cx = classNames.bind(styles);
export const getIsFreeCourse = (courseInfo: ICourseModel): boolean => !courseInfo.price || courseInfo.price === 0;

const CourseCard = (props: Props) => {
	const { cardStyle = "default", className, disableRating = false, type } = props;
	const [courseInfo, setCourseInfo] = useState<ICourseModel>({} as ICourseModel);
	const history = useHistory();
	const location = useLocation();
	const { usersStore, coursesStore, toasterStore } = useAppStore();
	const [isLiked, setIsLiked] = useState(false);
	const isMobile: boolean = useMemo(() => mobileCheck() && window.innerWidth < 768, []);
	const descriptionMaxLength = isMobile ? 100 : 200;
	const [isBookmarked, setIsBookmarked] = useState(false);

	const [isShowBox, setShowBox] = useState(false);
	const [componentName, setComponentName] = useState("");

	const isNotesAllowed: boolean =
		coursesStore.curActiveContent.contentItemType === ContentItemTypes.Content ||
		coursesStore.curActiveContent.contentItemType === ContentItemTypes.ContentSegment;
	const isCourseAllowed = getIsFreeCourse(courseInfo) || coursesStore.currentCourse.isAssigned || courseInfo.isPaid;
	const courseItemName = getCourseItemName(coursesStore.curActiveContent);
	const isCourseAuthor = usersStore.currentUserInfo!.id! === courseInfo.additionalFields?.creator?.id;
	const isLanding = type === "landing";
	const isShowEditBtn =
		(usersStore.checkOneOfUserPermissions([PermissionConstantsRead.AllCourseWithAllGroups]) || isCourseAuthor) &&
		!isLanding;
	const [activeItems, setActiveItems] = useState(0);
	const [activeChapters, setActiveChapters] = useState(0);
	const hasAccessToAssignments = usersStore.checkOneOfUserPermissions([
		PermissionConstantsUpdate.ManageAssignCoursesOwnCompanyUsers,
		PermissionConstantsUpdate.ManageAssignCoursesForAllUsers,
	]);
	const incompletedItems: number = coursesStore.activeCourseItems.filter((courseItem) => !courseItem.completed).length;
	const incompletedPercentage: number = (incompletedItems / coursesStore.activeCourseItems.length) * 100;

	const { isCourseOutdated } = useCourseOutdated({ course: courseInfo });
	const isMyDeskRoute = !!matchPath(location.pathname, { path: Routes.MyDesk, exact: true });
	const isLight = cardStyle === "light";

	useEffect(() => {
		if (!isEmpty(coursesStore.currentCourse)) {
			setCourseInfo(coursesStore.currentCourse);
		}
		if (!isLanding && courseInfo.id && hasAccessToAssignments) {
			coursesStore.setCourseAssignments(courseInfo.id);
		}
	}, [coursesStore.currentCourse, coursesStore, usersStore, courseInfo.id, isLanding, hasAccessToAssignments]);

	const handleLike = async (isDisliked: boolean) => {
		!isDisliked ? courseInfo.likesCount++ : courseInfo.likesCount--;
		await coursesStore.sendCourseEvent({
			courseId: courseInfo.id,
			eventType: isDisliked ? EventTypes.CourseUnliked : EventTypes.CourseLiked,
			payload: "",
		});
	};

	const handleBookmark = async (isMarked: boolean) => {
		await coursesStore.sendCourseEvent({
			courseId: courseInfo.id,
			eventType: isMarked ? EventTypes.CourseBookmarked : EventTypes.CourseUnbookmarked,
			payload: "",
		});
	};

	useEffect(() => {
		setIsBookmarked(courseInfo.isBookmarked);
	}, [courseInfo.isBookmarked]);

	useEffect(() => {
		setIsLiked(courseInfo.isLiked);
	}, [courseInfo.isLiked]);

	const onShare = () => {
		const url = isMyDeskRoute
			? window.location.host +
			  generatePath(Routes.MyDeskContent, {
					courseId: courseInfo.id,
					courseItemId: coursesStore.curActiveContent.courseItemId,
			  })
			: window.location.href;

		copyTextToClipboard(url).then(() => {
			toasterStore.showSuccessMessage(infoMessages.linkCopiedToClipboard);
		});
	};

	useEffect(() => {
		if (!isEmpty(courseInfo)) {
			const activeItems = filter(courseInfo.courseItems, "isActive");
			setActiveItems(size(activeItems));
			const set = activeItems.reduce((result, value) => {
				if (!isNull(value.chapter)) result.add(value.chapter?.id);
				return result;
			}, new Set());
			setActiveChapters(set.size);
		}
	}, [courseInfo]);

	return (
		<div className={classNames(styles.course_card, { [styles.course_card__light]: isLight }, className)}>
			<div
				style={{ backgroundColor: courseInfo.category?.color ?? DEFAULT_COLOR }}
				className={classNames(styles.course_card__color_bar)}
			>
				{incompletedPercentage ? (
					<div
						style={{ width: `${incompletedPercentage}%` }}
						className={classNames(styles.course_card__color_bar__incompleted)}
					/>
				) : (
					<></>
				)}
			</div>
			<div className={styles.course_card__main_info_block}>
				{isLanding && (
					<>
						<div className={styles.course_card__inner_block}>
							<div className={styles.course_card__inner_group}>
								<ClockIcon className={cx(styles.course_card__inner_icon, styles.course_card__icon_video)} />
								<div className={styles.course_card__header_text}>{courseInfo?.durationMinutes ?? 0} min</div>
							</div>
							<div className={styles.course_card__inner_group}>
								<BookIcon className={cx(styles.course_card__inner_icon, styles.course_card__icon_book)} />
								<div className={styles.course_card__header_text}>
									{`${activeChapters} chapter${activeChapters === 1 ? "" : "s"}`}
								</div>
							</div>
							<div className={styles.course_card__inner_group}>
								<CourseIcon className={cx(styles.course_card__inner_icon, styles.course_card__icon_video)} />
								<div className={styles.course_card__header_text}>
									{`${activeItems} content${activeItems === 1 ? "" : "s"}`}
								</div>
							</div>
						</div>
					</>
				)}
				<div className={styles.course_card__wrap}>
					<div className={styles.course_card__left_section}>
						<div className={styles.course_card__description_container}>
							<div
								className={classNames(styles.course_card__description_picture, {
									[styles.course_card__description_picture__landing]: isLanding,
								})}
							>
								<img
									src={getThumbnail(courseInfo.thumbnail)}
									alt="thumbnail"
									className={styles.course_card__description_img}
								/>
							</div>
							<div className={styles.course_card__description_box}>
								{isLanding ? (
									<div className={styles.course_card__content_name}>{courseInfo.title}</div>
								) : (
									<>
										<div className={styles.course_card__course_name}>{courseInfo.title}</div>
										<div className={styles.course_card__content_name}>{courseItemName}</div>
									</>
								)}

								<Description
									description={courseInfo.description}
									maxLength={descriptionMaxLength}
									onClose={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
								/>
							</div>
						</div>
						<div className={styles.divider} />
						<div className={styles.course_card__stats_section}>
							<div className={cx(styles.course_card__inner_block, styles.course_card__inner_block_two)}>
								<div className={styles.course_card__inner_group}>
									<LikeIcon
										className={classNames(styles.course_card__like_icon, {
											[styles.course_card__like_icon__disliked]: !isLiked,
										})}
										onClick={async () => {
											setIsLiked(!isLiked);
											await handleLike(isLiked);
										}}
									/>
									<div className={styles.course_card__text_info}>{courseInfo.likesCount}</div>
								</div>
								<div className={styles.course_card__inner_group}>
									<ViewsCounter courseInfo={courseInfo} />
								</div>

								<div className={styles.course_card__inner_group}>
									<ButtonBookmark
										className={styles.course_card__bookmark}
										bookmarked={isBookmarked}
										isSecondaryType={isLight}
										isBookmarkable={true}
										onClick={async () => {
											setIsBookmarked(!isBookmarked);
											await handleBookmark(!isBookmarked);
										}}
									/>
									<div className={styles.course_card__text_info}>Save</div>
								</div>
								<div className={styles.course_card__inner_group}>
									<ShareIcon
										className={cx(styles.course_card__inner_icon, styles.course_card__icon_share)}
										onClick={() => onShare()}
									/>
									<div className={styles.course_card__text_info}>Share</div>
								</div>
								{isShowEditBtn && (
									<div className={styles.course_card__inner_group}>
										<EditIcon
											className={cx(styles.course_card__edit_icon)}
											onClick={() => history.push(generatePath(Routes.CmsCourseProfile, { id: courseInfo.id }))}
										/>
										<div className={styles.course_card__text_info}>Edit</div>
									</div>
								)}
							</div>
							<ZStatsBlock course={courseInfo} alertClassName={styles.course_card__z1_alert_tooltip} />
						</div>
						<div className={styles.divider} />
						<div className={styles.course_card__content_info_section}>
							<p className={styles.course_card__content_info}>Source · {courseInfo.source!}</p>
							<p className={styles.course_card__content_info}>Subject · {courseInfo!.subject?.name}</p>
							<p className={styles.course_card__content_info}>
								Course · {courseInfo.additionalFields?.courseTitle! ? courseInfo.additionalFields?.courseTitle : "none"}
							</p>
						</div>
						{isMobile && (
							<>
								<div className={styles.divider} />
								<RatingBlock
									courseInfo={courseInfo}
									disableRating={disableRating}
									isLanding={isLanding}
									isLight={isLight}
									activeItems={activeItems}
								/>
							</>
						)}
						{!isLanding && (
							<>
								<div className={styles.divider} />
								<div className={styles.course_card__btn_box}>
									<div className={styles.course_card__progress_box}>
										<Button
											label="Progress"
											onClick={() => {
												setShowBox(() => !(isShowBox && componentName === "progress"));
												setComponentName("progress");
											}}
											icon={<ProgressIcon className={styles.course_card__icon} />}
											className={styles.course_card__btn}
											type="text"
											labelColor="tileText"
										/>
										{isCourseOutdated && <AlertTooltip className={styles.course_card__outdated_progress_tooltip} />}
									</div>

									{isCourseAllowed &&
										coursesStore.curActiveContent.contentItemType !== ContentItemTypes.ContentSegment && (
											<Button
												label="Segments"
												onClick={() => {
													setShowBox(true);
													setComponentName("timestamps");
												}}
												icon={<TimestampsIcon className={styles.course_card__icon} />}
												className={cx(styles.course_card__btn, styles.course_card__btn_timestamps)}
												type="text"
												labelColor="tileText"
											/>
										)}
									{isCourseAllowed && isNotesAllowed && (
										<Button
											label="Notes"
											onClick={() => {
												setShowBox(true);
												setComponentName("notes");
											}}
											icon={<NotesIcon className={styles.course_card__icon} />}
											className={cx(styles.course_card__btn, styles.course_card__btn_assignements)}
											type="text"
											labelColor="tileText"
										/>
									)}
									{hasAccessToAssignments && (
										<Button
											label="Assignments"
											onClick={() => {
												setShowBox(true);
												setComponentName("assignements");
											}}
											icon={<AssignmentsIcon className={styles.course_card__icon} />}
											className={cx(styles.course_card__btn, styles.course_card__btn_assignements)}
											type="text"
											labelColor="tileText"
										/>
									)}
								</div>
							</>
						)}
					</div>
					{!isMobile && (
						<RatingBlock
							courseInfo={courseInfo}
							disableRating={disableRating}
							isLanding={isLanding}
							isLight={isLight}
							activeItems={activeItems}
						/>
					)}
				</div>

				<CourseCardBox className={!isShowBox ? styles.course_card__box_hidden : ""} isShow={isShowBox}>
					{componentName === "timestamps" && <CourseCardTimestamps onClose={() => setShowBox(false)} />}
					{componentName === "notes" && <CourseCardNotes onClose={() => setShowBox(false)} />}
					{componentName === "assignements" && hasAccessToAssignments && (
						<CourseCardAssignments
							courseId={courseInfo.id}
							assignments={coursesStore.courseAssignments}
							onClose={() => setShowBox(false)}
						/>
					)}
					{componentName === "progress" && <ChaptersList isCarouselList={false} isRosterInTile={true} />}
				</CourseCardBox>
			</div>
		</div>
	);
};

export default observer(CourseCard);
