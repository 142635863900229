import React, { Suspense } from "react";
import { observer } from "mobx-react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { HomePage } from "./home/home";
import { Routes } from "routes";

import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";
import { PermissionConstantsCreateOrUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsCreateOrUpdate";
import { PermissionConstantsExport } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsExport";
import { PermissionConstantsCreate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsCreate";
import { PermissionConstantsUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsUpdate";

import { InfluencersPage } from "./influencers-page/influencers";
import UserProfilePage from "./user-profile/user-profile";
import ModalDialog from "components/base/modal-dialog/modal-dialog";
import MyDeskHistoryPage from "./my-history-page/my-history-page";
import { MyDesk } from "./myDesk/myDesk";
import InfluencerProfile from "pages/private/influencer-profile/influencer-profile";
import PeersHomePage from "pages/private/peers/peers-home/peers-home";
import ThreadProfilePage from "pages/private/peers/thread-profile/thread-profile";
import { SearchResultPage } from "./search-result-page/search-result-page";
import NewPasswordPage from "pages/public/newpassword/newpassword";
import PeersTopic from "pages/private/peers/peers-topic/peers-topic";
import { PaymentSuccessPage } from "./payment-result-page/payment-success";
import { PaymentFailedPage } from "./payment-result-page/payment-failed";
import { isPublicRoute, isRouteExists } from "helpers/navigation.helper";
import InvitationPage from "pages/public/invitation/invitation";
import ProfilePurchasesPage from "./profile-purchases-page/profile-purchases-page";
import CoursePage from "./course-page/course-page";
import InfluencersAll from "./influencers-all/influencers-all";
import MyAnalyticsPage from "./my-analytics-page/my-analytics-page";
import MyNotesPage from "./my-notes-page/notes-page";
import MyGoalsPage from "./my-goals-page/my-goals-page";
import AdvSearchPage from "./adv-search-page/adv-search-page";
import MyQuizResultsPage from "./my-quiz-results-page/quiz-results-page";
import AllReportsPage from "./reports/all-reports-page/all-reports-page";
import ShortQuizResultsPage from "./short-quiz-results-page/short-quiz-results-page";
import { useEffect, useState } from "react";
import Wiki from "pages/public/wiki/wiki";
import RouteAccess from "components/partial/route-access/route-access";
import NoAccessPage from "pages/public/no-access/no-access-page";
import ReportBox from "./reports/report-box/report-box";
import { abortController } from "api/utils/api";
import GroupProfilePage from "./group-profile/group-profile";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import MotifsStatisticPage from "./motifs-statistic-page/motifs-statistic-page";
import MyCertificatesPage from "./my-certificates-page/my-certificates-page";
// import MotifDetailsPage from "./motifs-statistic-page/motifs-statistic-page";

const CmsCoursesPage = React.lazy(() => import("pages/private/cms-courses-page/cms-courses"));
const CmsUsersPage = React.lazy(() => import("pages/private/cms-users-page/cms-users"));
const CmsCompaniesPage = React.lazy(() => import("pages/private/cms-companies-page/cms-companies"));
const CompanyProfilePage = React.lazy(() => import("pages/private/cms-companies-page/company-profile/company-profile"));
const CmsThreadsPage = React.lazy(() => import("pages/private/cms-threads-page/cms-threads-page"));
const CmsTaxonomyPage = React.lazy(() => import("pages/private/cms-taxonomy-page/cms-taxonomy-page"));
const CmsB2BSalesPage = React.lazy(() => import("pages/private/cms-sales-page/cms-b2b-sales-page"));
const CmsB2CSalesPage = React.lazy(() => import("pages/private/cms-sales-page/cms-b2c-sales-page"));
const CmsB2BPurchasesPage = React.lazy(() => import("pages/private/cms-purchases-page/cms-b2b-purchases-page"));
const CmsB2CPurchasesPage = React.lazy(() => import("pages/private/cms-purchases-page/cms-b2c-purchases-page"));
const CmsSettingsPage = React.lazy(() => import("pages/private/cms-settings-page/cms-settings-page"));
const CmsContentThreadPage = React.lazy(() => import("pages/private/cms-content-thread-page/cms-content-thread-page"));
const CmsStreamPage = React.lazy(() => import("pages/private/cms-stream-page/cms-stream-page"));
const CmsExternalLinkPage = React.lazy(() => import("pages/private/cms-external-link-page/cms-external-link-page"));
const CmsGroupsPage = React.lazy(() => import("pages/private/cms-groups-page/cms-groups"));
const CmsLogHistoryPage = React.lazy(() => import("pages/private/cms-log-history-page/cms-log-history-page"));
const CourseProfilePage = React.lazy(() => import("pages/private/cms-courses-page/course-profile/course-profile"));
const QuizProfilePage = React.lazy(() => import("pages/private/cms-content-page/quiz-profile/quiz-profile"));
const ContentProfilePage = React.lazy(() => import("pages/private/cms-content-page/content-profile/content-profile"));
const RefContentProfilePage = React.lazy(
	() => import("pages/private/cms-content-page/content-profile/ref-content-profile")
);
const ChapterProfilePage = React.lazy(() => import("pages/private/cms-courses-page/chapter-profile/chapter-profile"));
const CmsNotificationsSystemPage = React.lazy(
	() => import("pages/private/cms-notifications-page/cms-notifications-system-page")
);
const CmsNotificationsCustomPage = React.lazy(
	() => import("pages/private/cms-notifications-page/cms-notifications-custom-page")
);

export const PrivateView = observer(function PrivateView() {
	const history = useHistory<{ redirectUrl: string }>();
	const redirectUrl = history.location.state?.redirectUrl;
	const isIncorrectRoute = !isRouteExists(history.location.pathname) || isPublicRoute(history.location.pathname);
	const currentLocation = useLocation();
	const [currentSearchLocation, setCurrentSearchLocation] = useState(currentLocation.pathname);

	//Listener for aborting pending requests when route changes.
	useEffect(() => {
		const abortListener = history.listen(() => {
			if (history.action !== "POP") {
				abortController.cancelPendingRequest();
			}
		});
		return () => {
			abortListener();
		};
	}, [history]);

	// Listener for adv-search routing.
	useEffect(() => {
		const unlisten = history.listen((location) => {
			setCurrentSearchLocation(currentLocation.pathname);
			if (location.pathname === Routes.InfluencersAll) {
				setCurrentSearchLocation(Routes.InfluencersAll);
			} else if (location.pathname === Routes.Peers) {
				setCurrentSearchLocation(Routes.Peers);
			}
		});

		return () => {
			unlisten();
		};
	}, [history, currentLocation]);

	return (
		<div data-testid="private-layout-container">
			<ModalDialog />
			<Switch>
				<Route exact path={Routes.Home} component={HomePage} />
				<Route exact path={Routes.SearchPage} component={SearchResultPage} />
				<Route exact path={Routes.MyDesk} component={MyDesk} />
				<Route exact strict path={Routes.MyDeskLesson} component={MyDesk} />
				<Route exact strict path={Routes.MyDeskContent} component={MyDesk} />
				<Route exact path={Routes.MyDeskContentTime} component={MyDesk} />
				<Route exact path={Routes.MyDeskShareLink} component={MyDesk} />
				<Route exact path={Routes.MyHistory} component={MyDeskHistoryPage} />
				<Route exact path={Routes.MyAnalytics} component={MyAnalyticsPage} />
				<Route exact path={Routes.MotifStatisticsPage} component={MotifsStatisticPage} />
				<Route exact path={Routes.MyNotes} component={MyNotesPage} />
				<Route exact path={Routes.MyGoals} component={MyGoalsPage} />
				<Route exact path={Routes.MyCertificates} component={MyCertificatesPage} />
				<Route exact path={Routes.Profile} component={UserProfilePage} />
				<Route exact path={Routes.ProfilePurchases} component={ProfilePurchasesPage} />
				<Route exact path={Routes.MyQuizResults} component={MyQuizResultsPage} />
				<Route exact path={Routes.InfluencersAll} component={InfluencersAll} />
				<Route exact path={Routes.InfluencersRecommendations} component={InfluencersPage} />
				<Route exact path={Routes.InfluencerPofile} component={InfluencerProfile} />
				<Route exact path={Routes.Peers} component={PeersHomePage} />
				<Route exact path={Routes.PeersThread} component={PeersTopic} />
				<Route exact path={Routes.PeersThreadShareLink} component={PeersTopic} />
				<Route exact path={Routes.CreateNewPassword} component={NewPasswordPage} />
				<Route exact path={Routes.Invitation} component={InvitationPage} />
				<Route path={Routes.PaymentSuccess} component={PaymentSuccessPage} />
				<Route path={Routes.PaymentError} component={PaymentFailedPage} />
				<Redirect exact from={Routes.Cms} to={Routes.CmsCourses} />
				<Route exact path={Routes.CoursePage} component={CoursePage} />
				<Route exact path={Routes.CoursePageChapter} component={CoursePage} />
				<Route exact path={Routes.WikiPage} component={Wiki} />
				<Redirect
					exact
					from={Routes.AdvSearchPage}
					to={{
						pathname:
							currentSearchLocation === Routes.InfluencersAll
								? Routes.AdvSearchInfluensersPage
								: currentSearchLocation === Routes.Peers
								? Routes.AdvSearchThreadsPage
								: Routes.AdvSearchCoursesPage,
					}}
				/>
				<Route exact path={Routes.AdvSearchCoursesPage} component={AdvSearchPage} />
				<Route exact path={Routes.AdvSearchInfluensersPage} component={AdvSearchPage} />
				<Route exact path={Routes.AdvSearchThreadsPage} component={AdvSearchPage} />
				<Route exact path={Routes.ShortQuizResults} component={ShortQuizResultsPage} />
				<RouteAccess
					exact
					path={[Routes.CmsQuiz, Routes.CmsAddQuiz]}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<QuizProfilePage />
						</Suspense>
					)}
					key={Routes.CmsQuiz}
					permissions={[PermissionConstantsUpdate.Courses]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsGroups}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsGroupsPage />
						</Suspense>
					)}
					key={Routes.CmsGroups}
					permissions={[PermissionConstantsUpdate.ManageGroups]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsGroupProfileTab}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<GroupProfilePage />
						</Suspense>
					)}
					key={Routes.CmsGroupProfileTab}
					permissions={[PermissionConstantsUpdate.ManageGroups]}
				/>
				<RouteAccess
					exact
					path={[Routes.CmsContentProfile, Routes.CmsAddContent]}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<ContentProfilePage />
						</Suspense>
					)}
					key={Routes.CmsContentProfile}
					permissions={[PermissionConstantsUpdate.Courses]}
				/>
				<RouteAccess
					exact
					path={[Routes.CmsRefContentProfile, Routes.CmsAddRefContent]}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<RefContentProfilePage />
						</Suspense>
					)}
					key={Routes.CmsRefContentProfile}
					permissions={[PermissionConstantsUpdate.Courses]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsAddChapter}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<ChapterProfilePage />
						</Suspense>
					)}
					key={Routes.CmsAddChapter}
					permissions={[PermissionConstantsUpdate.Courses]}
				/>
				<RouteAccess
					exact
					path={[Routes.CmsContentStreamAdd, Routes.CmsContentStreamProfile]}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsStreamPage />
						</Suspense>
					)}
					key={Routes.CmsContentStreamProfile}
					permissions={[PermissionConstantsUpdate.Courses]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsContentExternalLinkAdd}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsExternalLinkPage/>
						</Suspense>
					)}
					key={Routes.CmsContentExternalLinkAdd}
					permissions={[PermissionConstantsUpdate.Courses]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsContentExternalLinkProfile}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsExternalLinkPage/>
						</Suspense>
					)}
					key={Routes.CmsContentExternalLinkProfile}
					permissions={[PermissionConstantsUpdate.Courses]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsChapterProfile}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<ChapterProfilePage />
						</Suspense>
					)}
					key={Routes.CmsChapterProfile}
					permissions={[PermissionConstantsUpdate.Courses]}
				/>
				,
				<RouteAccess
					exact
					path={Routes.CmsLogHistory}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsLogHistoryPage />
						</Suspense>
					)}
					key={Routes.CmsLogHistory}
					permissions={[PermissionConstantsRead.OwnLogHistoryActivities]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsThreads}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsThreadsPage />
						</Suspense>
					)}
					key={Routes.CmsThreads}
					permissions={[PermissionConstantsRead.ThreadsFromCms]}
				/>
				<RouteAccess
					exact
					path={[Routes.CmsThreadProfile, Routes.PeersThreadEdit]}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<ThreadProfilePage />
						</Suspense>
					)}
					key={Routes.CmsThreadProfile}
					permissions={[PermissionConstantsUpdate.OwnThreads, PermissionConstantsUpdate.AllThreads]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsAddThread}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<ThreadProfilePage />
						</Suspense>
					)}
					key={Routes.CmsAddThread}
					permissions={[PermissionConstantsCreate.Threads]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsContentThreadAdd}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsContentThreadPage />
						</Suspense>
					)}
					key={Routes.CmsContentThreadAdd}
					permissions={[PermissionConstantsCreate.Courses]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsContentThreadProfile}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsContentThreadPage />
						</Suspense>
					)}
					key={Routes.CmsContentThreadProfile}
					permissions={[
						PermissionConstantsRead.AllCoursesForTechActions,
						PermissionConstantsRead.OwnCoursesForTechActions,
					]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsCourses}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsCoursesPage />
						</Suspense>
					)}
					key={Routes.CmsCourses}
					permissions={[
						PermissionConstantsRead.AllCoursesForTechActions,
						PermissionConstantsRead.OwnCoursesForTechActions,
					]}
				/>
				<RouteAccess
					exact
					path={[Routes.CmsCourseProfile, Routes.CmsCourseProfileVersion]}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CourseProfilePage />
						</Suspense>
					)}
					key={Routes.CmsCourseProfile}
					permissions={[
						PermissionConstantsRead.AllCoursesForTechActions,
						PermissionConstantsRead.OwnCoursesForTechActions,
					]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsUsers}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsUsersPage />
						</Suspense>
					)}
					key={Routes.CmsUsers}
					permissions={[PermissionConstantsRead.AllUsersProfileInfo, PermissionConstantsRead.CompanyUsersProfileInfo]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsUserProfile}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<UserProfilePage />
						</Suspense>
					)}
					key={Routes.CmsUserProfile}
					permissions={[PermissionConstantsRead.AllUsersProfileInfo, PermissionConstantsRead.CompanyUsersProfileInfo]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsUserHistory}
					component={MyDeskHistoryPage}
					key={Routes.CmsUserHistory}
					permissions={[PermissionConstantsRead.OtherUserHistory]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsUserAnalytics}
					component={MyAnalyticsPage}
					key={Routes.CmsUserAnalytics}
					permissions={[PermissionConstantsRead.OtherUserAnalytics]}
				/>
				<RouteAccess
					exact
					path={Routes.UserMotifStatisticsPage}
					component={MotifsStatisticPage}
					key={Routes.UserMotifStatisticsPage}
					permissions={[PermissionConstantsRead.OtherUserAnalytics]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsUserGoals}
					component={MyGoalsPage}
					key={Routes.CmsUserGoals}
					permissions={[PermissionConstantsRead.BasicOthersGoalsMyPerfectBrain]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsUserPurchases}
					component={ProfilePurchasesPage}
					key={Routes.CmsUserPurchases}
					permissions={[PermissionConstantsRead.OtherUsersBilling]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsUserCertificates}
					component={MyCertificatesPage}
					key={Routes.CmsUserCertificates}
					permissions={[PermissionConstantsRead.OtherAllUsersAdditionalInformation]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsUserQuizResults}
					component={MyQuizResultsPage}
					key={Routes.CmsUserQuizResults}
					permissions={[PermissionConstantsRead.OtherUserAssessmentResults]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsAddUser}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<UserProfilePage />
						</Suspense>
					)}
					key={Routes.CmsAddUser}
					permissions={[PermissionConstantsCreateOrUpdate.AllAccounts, PermissionConstantsCreateOrUpdate.Account]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsCompanies}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsCompaniesPage />
						</Suspense>
					)}
					key={Routes.CmsCompanies}
					permissions={[PermissionConstantsRead.AnyCompanies, PermissionConstantsRead.Company]}
				/>
				<RouteAccess
					exact
					path={[Routes.CmsCompanyProfile, Routes.CmsAddCompany]}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CompanyProfilePage />
						</Suspense>
					)}
					key={Routes.CmsCompanyProfile}
					permissions={[PermissionConstantsRead.Company, PermissionConstantsCreate.Company]}
				/>
				<Redirect exact from={Routes.Reports} to={Routes.ReportUser} key={Routes.Reports} />,
				<RouteAccess
					exact
					path={[Routes.ReportUser, Routes.ReportCourse, Routes.ReportDashboard]}
					component={ReportBox}
					key={Routes.ReportUser}
					permissions={[PermissionConstantsExport.Reports]}
				/>
				<RouteAccess
					exact
					path={Routes.AllReports}
					component={AllReportsPage}
					key={Routes.AllReports}
					permissions={[PermissionConstantsExport.Reports]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsB2BSales}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsB2BSalesPage />
						</Suspense>
					)}
					key={Routes.CmsB2BSales}
					permissions={[PermissionConstantsRead.Purchases]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsB2CSales}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsB2CSalesPage />
						</Suspense>
					)}
					key={Routes.CmsB2CSales}
					permissions={[PermissionConstantsRead.Purchases]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsB2BPurchases}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsB2BPurchasesPage />
						</Suspense>
					)}
					key={Routes.CmsB2BPurchases}
					permissions={[PermissionConstantsRead.Purchases]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsB2CPurchases}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsB2CPurchasesPage />
						</Suspense>
					)}
					key={Routes.CmsB2CPurchases}
					permissions={[PermissionConstantsRead.Purchases]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsTaxonomy}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsTaxonomyPage />
						</Suspense>
					)}
					key={Routes.CmsTaxonomy}
					permissions={[PermissionConstantsUpdate.Taxonomy]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsNotificationsSystem}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsNotificationsSystemPage />
						</Suspense>
					)}
					key={Routes.CmsNotificationsSystem}
					permissions={[PermissionConstantsRead.SystemEmailSettings]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsNotificationsCustom}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsNotificationsCustomPage />
						</Suspense>
					)}
					key={Routes.CmsNotificationsCustom}
					permissions={[PermissionConstantsRead.ScheduleEmailSettings]}
				/>
				<RouteAccess
					exact
					path={Routes.CmsSettings}
					component={() => (
						<Suspense fallback={<LoadingIndicator loading={true} />}>
							<CmsSettingsPage />
						</Suspense>
					)}
					key={Routes.CmsSettings}
					permissions={[PermissionConstantsRead.PrivateSettings]}
				/>
				<Route exact path={Routes.NoAccess} component={NoAccessPage} />
				<Redirect to={Routes.Home} />
			</Switch>
			{isIncorrectRoute && !redirectUrl && <Redirect to={Routes.Home} />}
			{redirectUrl && !redirectUrl.includes(Routes.CreateNewPassword) && <Redirect to={redirectUrl} />}
		</div>
	);
});
