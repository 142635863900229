import React, { useMemo } from "react";
import { mobileCheck, tabletCheck } from "helpers/device.helper";
import { SearchType } from "pages/private/adv-search-page/data";
import { SearchDataType } from "store/search/search-store";
import CourseTile from "components/partial/course-tile/course-tile";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import styles from "./styles.module.scss";
import InfluencerTile from "components/partial/influencer/influencer-tile/influencer-tile";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import PeerTile, { TilePlacement } from "components/partial/peers/peer-tile/peer-tile";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import classNames from "classnames";
import { useAppStore } from "store";
import { TransitionSources } from "constants/constants";

const cx = classNames.bind(styles);
export const useGetItems = (searchType: SearchType | undefined) => {
	const { searchStore } = useAppStore();

	const isMobile = useMemo(() => mobileCheck(), []);
	const isTablet = useMemo(() => tabletCheck, []);
	const isAdaptive = isMobile || isTablet;
	const [horisontal, vertical] = useMemo(
		() => [
			isAdaptive ? TilePlacement.default : TilePlacement.horizontal,
			isAdaptive ? TilePlacement.default : TilePlacement.vertical,
		],
		[isAdaptive]
	);

	const SearchTypeToTileHorizDict = {
		[SearchType.CoursesSearch]: (item: SearchDataType, index: number) => (
			<CourseTile
				courseInfo={item as IPreviewCourseModel}
				tilePlacement={horisontal}
				className={cx({ [styles.search_results__tile_horiz]: !isAdaptive })}
				disableRating={true}
				key={index}
				openInNewTab={searchStore.openResultsInNewTab}
				courseTileKey={TransitionSources.Search}
			/>
		),
		[SearchType.InfluensersSearch]: (item: SearchDataType, index: number) => (
			<InfluencerTile
				influencerInfo={item as IInfluencerDataModel}
				tilePlacement={horisontal}
				key={index}
				className={cx({ [styles.search_results__tile_horiz]: !isAdaptive })}
				openInNewTab={searchStore.openResultsInNewTab}
			/>
		),
		[SearchType.ThreadsSearch]: (item: SearchDataType, index: number) => (
			<PeerTile
				threadInfo={item as IThreadModel}
				key={index}
				tilePlacement={horisontal}
				className={cx({ [styles.search_results__tile_horiz]: !isAdaptive })}
				disableRating={true}
				openInNewTab={searchStore.openResultsInNewTab}
			/>
		),
	};

	const SearchTypeToTileVertDict = {
		[SearchType.CoursesSearch]: (item: SearchDataType, index: number) => (
			<CourseTile
				courseInfo={item as IPreviewCourseModel}
				tilePlacement={vertical}
				className={cx({ [styles.search_results__tile_vert]: !isAdaptive })}
				disableRating={true}
				key={index}
				openInNewTab={searchStore.openResultsInNewTab}
				courseTileKey={TransitionSources.Search}
			/>
		),
		[SearchType.InfluensersSearch]: (item: SearchDataType, index: number) => (
			<InfluencerTile
				influencerInfo={item as IInfluencerDataModel}
				tilePlacement={vertical}
				key={index}
				className={cx({ [styles.search_results__tile_vert]: !isAdaptive })}
				openInNewTab={searchStore.openResultsInNewTab}
			/>
		),
		[SearchType.ThreadsSearch]: (item: SearchDataType, index: number) => (
			<PeerTile
				threadInfo={item as IThreadModel}
				key={index}
				tilePlacement={vertical}
				className={cx({ [styles.search_results__tile_vert]: !isAdaptive })}
				disableRating={true}
				openInNewTab={searchStore.openResultsInNewTab}
			/>
		),
	};

	const SearchTypeToTileDict = {
		[SearchType.CoursesSearch]: (item: SearchDataType, index: number) => (
			<CourseTile
				courseInfo={item as IPreviewCourseModel}
				className={styles.search_results__lesson_tile}
				disableRating={true}
				key={index}
				openInNewTab={searchStore.openResultsInNewTab}
				courseTileKey={TransitionSources.Search}
			/>
		),
		[SearchType.InfluensersSearch]: (item: SearchDataType, index: number) => (
			<InfluencerTile
				influencerInfo={item as IInfluencerDataModel}
				className={styles.search_results__influencer_tile}
				key={index}
				openInNewTab={searchStore.openResultsInNewTab}
			/>
		),
		[SearchType.ThreadsSearch]: (item: SearchDataType, index: number) => (
			<PeerTile
				threadInfo={item as IThreadModel}
				key={index}
				className={styles.search_results__tile}
				disableRating={true}
				openInNewTab={searchStore.openResultsInNewTab}
			/>
		),
	};

	const getSearchResults = () => {
		switch (searchType) {
			case SearchType.CoursesSearch:
				return searchStore.coursesResult;
			case SearchType.InfluensersSearch:
				return searchStore.influencersResult;
			case SearchType.ThreadsSearch:
				return searchStore.threadsResult;
			default:
				return searchStore.items;
		}
	};

	const getResultTiles = () => {
		let counter: number;
		return getSearchResults().map((item, index: number) => {
			if (index === 0 || index % 10 === 0) counter = 0;
			counter++;
			if (counter === 1) {
				return SearchTypeToTileHorizDict[searchType ?? 0](item, index);
			} else if (counter === 2) {
				return SearchTypeToTileVertDict[searchType ?? 0](item, index);
			} else {
				return SearchTypeToTileDict[searchType ?? 0](item, index);
			}
		});
	};

	return { getResultTiles, getSearchResults };
};
