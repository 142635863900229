import styles from "./styles.module.scss";
import { ReactNode, useMemo } from "react";
import classNames from "classnames/bind";
import Button from "components/base/button/button";
import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { ConfirmationModal } from "../confirmation-popup/confirmation-popup";
import { useAppStore } from "store";
import { useHistory } from "react-router-dom";
import { ReactComponent as DartLeft } from "assets/icons/ic_dart-left.svg";
import { mobileCheck } from "helpers/device.helper";

interface HeaderProps {
	label: string;
	children?: ReactNode;
	className?: string;
	classNameContent?: string;
	classNameLabel?: string;
	showLogoutButton?: boolean;
	showBackButton?: boolean;
	onBackButtonClick?: () => void;
}

const cx = classNames.bind(styles);

const CommonHeader = (props: HeaderProps) => {
	const history = useHistory<{ redirectUrl: string }>();
	const {
		label,
		children,
		className,
		classNameContent,
		classNameLabel,
		showLogoutButton,
		showBackButton = false,
		onBackButtonClick = () => history.goBack(),
	} = props;
	const { accountStore } = useAppStore();
	const isMobile = useMemo(() => mobileCheck(), []);
	const openConfirmationPopup = async () => {
		await modalDialogService.show(ConfirmationModal, {
			popupText: "Are you sure you want to log out of Sensory?",
			confirmationAction: async () => {
				history.replace("", { redirectUrl: "" });
				await accountStore.signout();
			},
			confirmationButtonLabel: "Yes, I`m sure",
			cancelButtonLabel: "No, keep me logged in",
		});
	};

	return (
		<div className={cx(styles.common_header, className)}>
			<div className={cx(styles.common_header__content, classNameContent)}>
				{label && (
					<div className={cx(styles.common_header__label, classNameLabel)}>
						<div className={styles.common_header__back_btn_group}>
							{showBackButton && isMobile && (
								<Button
									label=""
									onClick={onBackButtonClick}
									icon={<DartLeft className={styles.common_header__icon} />}
									className={styles.common_header__btn_back_mobile}
									type="text"
								/>
							)}
							{label}{" "}
						</div>

						{showLogoutButton && (
							<Button
								label="Logout"
								onClick={() => openConfirmationPopup().catch((err) => console.log(err))}
								type="outlined"
								size="small"
								className={styles.logout_button}
							/>
						)}
					</div>
				)}
				<div className={styles.common_header__back_btn_group}>
					{showBackButton && !isMobile && (
						<DartLeft className={styles.common_header__icon} onClick={onBackButtonClick} />
					)}

					{children}
				</div>
			</div>
		</div>
	);
};

export default CommonHeader;
