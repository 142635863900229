import styles from "./styles.module.scss";
import { useAppStore } from "store";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import GoalsItem from "../goals-item/goals-item";

const GoalsList = () => {
	const { goalsStore } = useAppStore();

	return (
		<>
			{!isEmpty(goalsStore.goals) && (
				<div className={styles.goals_list}>
					{goalsStore.goals.map((goal) => (
						<GoalsItem key={goal.id} goal={goal} />
					))}
				</div>
			)}
		</>
	);
};

export default observer(GoalsList);
