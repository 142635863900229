import Api, { ApiBase } from "api/utils/api";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { AxiosResponse } from "axios";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";

export class InfluencersApi {
	static getInfluencerById(accountId: number) {
		return Api.get<IInfluencerDataModel>(`/influencer/GetInfluencerById/${accountId}`);
	}

	static getAllInfluencers(data: IQueryParams) {
		return ApiBase.post<IQueryParams, AxiosResponse<IResponseModel<IGetRecordsResponse<IInfluencerDataModel>>>>(
			"/influencer/GetAll",
			data
		);
	}

	static getMostFollowedInfluencers(data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IInfluencerDataModel>>("/influencer/GetMostFollowedInfluencers", data);
	}

	static getMostLikedInfluencers(data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IInfluencerDataModel>>("/influencer/GetMostLikedInfluencers", data);
	}

	static getMoverShakersOfLastWeek(data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IInfluencerDataModel>>("/influencer/GetMoverShakersOfLastWeek", data);
	}

	static getRecommendedInfluencers(data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IInfluencerDataModel>>("/influencer/GetRecommendedInfluencers", data);
	}

	static getCoursesByInfluencerId(accountId: number, pagingParams: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IPreviewCourseModel>, IPagingParams>(
			`/influencer/GetCoursesByInfluencerId/${accountId}`,
			pagingParams
		);
	}
}
