import React from "react";
import styles from "./styles.module.scss";
import { CmsTileProps } from "components/partial/cms/cms-table/cms-table";
import isEmpty from "lodash/isEmpty";
import noImage from "assets/images/no-image.png";
import classNames from "classnames";
import { DateTime } from "luxon";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { isNil } from "lodash";

const cx = classNames.bind(styles);

const CmsTileCourse: React.FunctionComponent<CmsTileProps<IPreviewCourseModel>> = (
	props: CmsTileProps<IPreviewCourseModel>
) => {
	const { item: course, onClick } = props;
	const { thumbnail, title, lastUpdated, isActive, price } = course;

	return (
		<div className={styles.cms_tile_course} onClick={() => onClick && onClick(course)}>
			<div className={styles.cms_tile_course__cell_picture}>
				<img
					className={styles.cms_tile_course__cell_image}
					src={isEmpty(thumbnail?.url) ? noImage : thumbnail?.url}
					alt={title}
				/>
			</div>
			<div className={styles.cms_tile_course__wrap}>
				<div className={styles.cms_tile_course__cell_title}>{title}</div>
				<div className={styles.cms_tile_course__inner}>
					<div className={cx(styles.cms_tile_course__status, { [styles.cms_tile_course__status_success]: isActive })}>
						{isActive ? "Active" : "InActive"}
					</div>
					<div className={styles.cms_tile_course__date}>
						{!isEmpty(lastUpdated) && DateTime.fromISO(lastUpdated).setLocale("en").toLocaleString(DateTime.DATE_MED)}
					</div>
					<div className={styles.cms_tile_course__price}>
						{!isNil(price) ? `$ ${Number(price / 100).toFixed(2)}` : <>-</>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CmsTileCourse;
