import styles from "./styles.module.scss";
import GoogleLogin from "react-google-login";
import Button from "components/base/button/button";
import { ReactComponent as GoogleLogo } from "assets/icons/ic_google-logo.svg";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { useAppStore } from "store";

interface IGoogleProps {
	onSuccess: (googleTokenId: string) => void;
}

const GoogleLoginButton = (props: IGoogleProps) => {
	const { settingsStore } = useAppStore();
	return (
		<GoogleLogin
			clientId={settingsStore.publicSettings.Auth?.[SiteSettingsKeys.GoogleAuthAppId]}
			responseType="token"
			onSuccess={(resp: any) => {
				if (!!resp.tokenId) {
					props.onSuccess(resp.tokenId);
				}
			}}
			onFailure={(resp) => console.log("Google Login Error:", resp)}
			render={(renderProps) => (
				<Button
					label="Sign in with Google"
					onClick={renderProps.onClick}
					icon={<GoogleLogo />}
					className={styles.google_login_button}
					form="default"
					labelColor="textLayoutHeader"
				/>
			)}
		/>
	);
};
export default GoogleLoginButton;
