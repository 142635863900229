import find from "lodash/find";
import { FileTypes } from "../models/dto/ZoomiLxp/Utilities/Enumerations/FileTypes";
import noImage from "../assets/images/no-image.png";
import pdfImage from "../assets/images/file_types/attachment_pdf.png";
import docImage from "../assets/images/file_types/attachment_docx.png";
import mp3Image from "../assets/images/file_types/attachment_mp3.png";
import pptxImage from "../assets/images/file_types/attachment_pptx.png";
import xlsxImage from "../assets/images/file_types/attachment_xlsx.png";
import mp4Image from "../assets/images/file_types/attachment_mp4.png";
import movImage from "../assets/images/file_types/attachment_mov.png";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import isEmpty from "lodash/isEmpty";
import { IThumbnailModel } from "models/dto/ZoomiLxp/Models/Abstract/IThumbnailModel";
import { IFileModel } from "models/dto/ZoomiLxp/Models/Common/IFileModel";
import isNil from "lodash/isNil";
import { ICourseAssignmentModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseAssignmentModel";
import { mimeTypes } from "constants/constants";

export const getImage = (item: IInfluencerDataModel | ICourseAssignmentModel, ignoreNoImage?: boolean) => {
	if (item.userPicture) {
		if (!isEmpty(item.userPicture.generatedFiles)) {
			const thumbnail = find(item.userPicture.generatedFiles, { fileType: FileTypes.Thumbnail });
			if (Boolean(thumbnail)) return thumbnail?.url ?? noImage;
		} else {
			return isNil(item.userPicture.url) || isEmpty(item.userPicture.url) ? noImage : item?.userPicture?.url;
		}
	}
	return ignoreNoImage ? undefined : noImage;
};

export const getPicture = (item: IThumbnailModel) => {
	if (item.thumbnail) {
		if (!isEmpty(item.thumbnail.generatedFiles)) {
			const thumbnail = find(item.thumbnail.generatedFiles, { fileType: FileTypes.Thumbnail });
			if (Boolean(thumbnail)) return thumbnail?.url ?? noImage;
		} else {
			return item.thumbnail?.url ?? noImage;
		}
	}
	return noImage;
};

export const getThumbnail = (item?: IFileModel, isAvatar?: boolean) => {
	if (item && !isEmpty(item)) {
		if (!isNil(item.generatedFiles) && !isEmpty(item.generatedFiles)) {
			const thumbnail = find(item.generatedFiles, { fileType: FileTypes.Thumbnail });
			if (thumbnail) {
				return thumbnail.url;
			} else return item.url ?? noImage;
		} else {
			return isNil(item.url) || isEmpty(item.url) ? noImage : item?.url;
		}
	}

	return isAvatar ? undefined : noImage;
};

export const getAttachmentPicture = (item: IFileModel, isThumbnail?: boolean) => {
	switch (item.contentType) {
		case mimeTypes.pdf:
			return pdfImage;
		case mimeTypes.msword:
		case mimeTypes.document:
			return docImage;
		case mimeTypes.mp3:
			return mp3Image;
		case mimeTypes.presentation:
		case mimeTypes.mspresentation:
			return pptxImage;
		case mimeTypes.msexcel:
		case mimeTypes.spreadsheet:
			return xlsxImage;
		case mimeTypes.mp4:
			return mp4Image;
		case mimeTypes.mov:
			return movImage;
		default:
			return isThumbnail ? getThumbnail(item) : getMediumImage(item);
	}
};

export const getMediumImage = (item: IFileModel) => {
	if (item) {
		if (!isEmpty(item.generatedFiles)) {
			const thumbnail = find(item.generatedFiles, { fileType: FileTypes.Medium });
			if (Boolean(thumbnail)) return thumbnail?.url ?? noImage;
		} else {
			return isNil(item.url) || isEmpty(item.url) ? noImage : item?.url;
		}
	}
	return noImage;
};

export const getIsAttachmentPicture = (item: IFileModel) => {
	return item.contentType && (item.contentType === mimeTypes.jpeg || item.contentType === mimeTypes.png);
};

export const getImageMeta = (url: string, callback: (img: HTMLImageElement) => void) => {
	const img = new Image();
	img.onload = () => callback(img);
	img.src = url;
	return img;
};

export const getFileFromCanvas = async (canvas: HTMLCanvasElement, contentType?: string): Promise<File | null> => {
	const context: CanvasRenderingContext2D | null = canvas.getContext("2d");
	const getFileName = (): string => {
		if (contentType) {
			const format = contentType.split("/")[1];
			return `croppedImage.${format}`;
		}

		return "croppedImage.jpg";
	};
	if (context?.canvas.width && context?.canvas.height) {
		const blob = await fetch(context?.canvas.toDataURL()).then((res) => res.blob());
		const file = new File([blob], getFileName(), { type: contentType ?? "image/jpeg" });
		return file;
	}
	return null;
};

export const getFileFromDataUrl = async (dataUrl: string, contentType?: string): Promise<File | null> => {
	const getFileName = (): string => {
		if (contentType) {
			const format = contentType.split("/")[1];
			return `croppedImage.${format}`;
		}

		return "croppedImage.jpg";
	};

	const blob = await fetch(dataUrl).then((res) => res.blob());
	const file = new File([blob], getFileName(), { type: contentType ?? "image/jpeg" });
	return file;
};
