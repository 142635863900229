import React, { useEffect } from "react";
import toast, { ToastBar, Toaster, useToasterStore } from "react-hot-toast";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as CloseIcon } from "assets/icons/ic_close.svg";

const cx = classNames.bind(styles);

const AlertToaster = () => {

	const { toasts } = useToasterStore();
	const lastAddedToast = toasts[0];

	useEffect(() => {
		if(toasts && lastAddedToast) {
			toasts.forEach((t) => {
				if(t.visible && t.id !== lastAddedToast.id) {
					if(lastAddedToast.message === t.message && lastAddedToast.type === t.type) {
						toast.dismiss(t.id);
					}
				}
			});
		}
	}, [lastAddedToast, toasts]);

	return (
		<Toaster 
			position="top-right" 
			reverseOrder={false}
			containerStyle={{top: 70}}
			toastOptions={{
				icon: "",
				duration: 10000,
				className: styles.alert__theme_red,
				success: {
					className: styles.alert__theme_green
				},
				error: {
					className: styles.alert__theme_red
				}
			}}
		>
			{(t) => (
					<ToastBar toast={t}>
						{({ message }) => (
							<>
								<div className={styles.alert__text}>
									{message}
								</div>
								<div className={styles.alert__icon_box} onClick={() => toast.dismiss(t.id)}>
									<CloseIcon className={cx(styles.alert__icon)} />
								</div>
							</>
						)}
					</ToastBar>
				)
			}
		</Toaster>
	);
};

export default AlertToaster;
