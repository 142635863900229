import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import PublicLayout from "layouts/public/public";
import React, { useEffect, useState } from "react";
import Logo from "components/base/logo/logo";
import InputField from "components/base/input-field/input-field";
import GoogleLoginButton from "components/partial/google-login-button/google-login-button";
import Checkbox from "components/base/checkbox/checkbox";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FacebookLoginButton from "components/partial/facebook-login-button/facebook-login-button";
import LinkedInLoginButton from "components/partial/linkedin-login-button/linkedin-login-button";
import isEmpty from "lodash/isEmpty";
import { generatePath, Link, useHistory, useRouteMatch } from "react-router-dom";
import { Routes } from "routes";
import InputPassword from "components/base/input-password/input-password";
import { emailValidatorEasy, passwordValidatorEasy } from "helpers/validator.helper";
import { ReactComponent as SpinnerIcon } from "assets/icons/ic_spinner.svg";
import classNames from "classnames/bind";
import Button from "components/base/button/button";
import { useSocialSignIn } from "hooks/useSocialSignIn";
import { ExternalIdentityProviderTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ExternalIdentityProviderTypes";
import { showLoginConfirmationModal } from "./login_popup";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { ISignInRequestModel } from "models/dto/ZoomiLxp/Models/Authentication/ISignInRequestModel";
import ExternalApiLoginButton from "components/partial/external-api-login-button/external-api-login-button";

interface FormValues {
	email: string;
	password: string;
}

const cx = classNames.bind(styles);

const LoginPage: React.FC = observer(() => {
	const { accountStore, settingsStore, toasterStore } = useAppStore();
	const [isRememberMe, setRememberMe] = useState(false);
	const match = useRouteMatch<{ guid: string }>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { externalSignIn } = useSocialSignIn();
	const history = useHistory();

	const getIsExternalLoginEnabled = (setting?: string | string[]): boolean => {
		if (setting && typeof setting === "string") {
			return setting.includes("true");
		}
		return !![
			settingsStore.publicSettings.Auth?.[SiteSettingsKeys.GoogleAuthEnabled],
			settingsStore.publicSettings.Auth?.[SiteSettingsKeys.FacebookAuthEnabled],
			settingsStore.publicSettings.Auth?.[SiteSettingsKeys.LinkedInAuthEnabled],
			settingsStore.publicSettings.Auth?.[SiteSettingsKeys.EnabledApiKeyAccess],
		].find((item) => item?.includes("true"));
	};

	useEffect(() => {
		const guid = match.params.guid;

		if (guid) {
			(async () => {
				try {
					await accountStore.confirmEmail({ code: guid });
					toasterStore.showSuccessMessage("E-mail is confirmed");
				} catch (err: unknown) {
					toasterStore.showErrorMessage(err);
				}
			})();
		}
	}, [match, accountStore, toasterStore]);

	const SignupSchema = Yup.object().shape({
		email: emailValidatorEasy,
		password: passwordValidatorEasy,
	});

	const initialValues: FormValues = { email: "", password: "" };

	const handleSubmit = async (values: FormValues) => {
		setIsLoading(true);
		const defaultSignInModel: ISignInRequestModel = {
			username: values.email,
			password: values.password,
			rememberMe: isRememberMe,
		};

		await accountStore
			.signIn(defaultSignInModel)
			.then((response) => {
				if (response && response.mfaEnabled) {
					history.push(Routes.Check2FA);
				}
				if (response && (!response.isTermsAccepted || !response.isPPAccepted || !response.token?.length)) {
					setIsLoading(true);
					showLoginConfirmationModal(() => {
						try {
							accountStore.signIn({
								...defaultSignInModel,
								isTermsAccepted: true,
								isPPAccepted: true,
							});
							accountStore.stop2FAPersist();
						} catch (err: unknown) {
							toasterStore.showErrorMessage(err);
						} finally {
							history.push(Routes.Check2FA);
						}
					});
					setIsLoading(false);
				}
			})
			.catch((err) => toasterStore.showErrorMessage(err))
			.finally(() => setIsLoading(false));
	};

	const socialSignIn = (externalType: ExternalIdentityProviderTypes, token: string) =>
		externalSignIn({
			externalProvider: externalType,
			token: token,
		});

	return (
		<PublicLayout>
			<section className={styles.login_page}>
				<div className={styles.login_page__logo_box}>
					<Logo />
				</div>
				<div className={styles.login_page__title}>Sign in to your account</div>
				<div className={styles.login_page__wrap}>
					<div className={`${styles.login_page__text} ${styles["login_page__text_inner"]}`}>Don't have an account?</div>
					<Link className={styles.login_page__link} to={generatePath(Routes.Register)}>
						Sign up
					</Link>
				</div>

				<Formik
					initialValues={initialValues}
					validationSchema={SignupSchema}
					onSubmit={handleSubmit}
					validateOnChange={false}
				>
					{({ values, validateForm, setFieldValue, errors, setErrors }) => (
						<Form
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									event.preventDefault();
									handleSubmit(values);
								}
							}}
						>
							<div className={styles.login_page__form} data-testid="login_page__fields_wrap">
								<div className={styles.login_page__fields_container}>
									<InputField
										inputType="email"
										label="Email"
										className={styles.login_page__email_field}
										name="email"
										value={values.email}
										onChange={(e) => setFieldValue("email", e.target.value)}
										isError={Boolean(errors["email"])}
										errorText={errors["email"]}
										autoFocus
									/>
									<InputPassword
										label="Password"
										className={styles.login_page__password_field}
										name="password"
										value={values.password}
										onChange={(e) => setFieldValue("password", e.target.value)}
										isError={Boolean(errors["password"])}
										errorText={errors["password"]}
									/>
								</div>
								<div className={styles.login_page__box}>
									<Checkbox value={isRememberMe} onChange={(value) => setRememberMe(value)}>
										<div className={styles.login_page__checkbox_inner}>Remember Me</div>
									</Checkbox>
									<Link className={styles.login_page__link} to={generatePath(Routes.ResetPassword)}>
										Forgot your password?
									</Link>
								</div>
							</div>
							<Button
								label={"Sign in"}
								onClick={async (e) => {
									e.preventDefault();
									const errors = await validateForm();
									isEmpty(errors) ? await handleSubmit(values) : setErrors(errors);
								}}
								size="common"
								className={cx(styles.login_page__btn_sign_in, { login_page__btn_disable: isLoading })}
								icon={isLoading ? <SpinnerIcon className={styles.login_page__icon} /> : <></>}
							/>
						</Form>
					)}
				</Formik>
				{getIsExternalLoginEnabled() && (
					<>
						<div className={styles.login_page__text_block}>
							<span className={styles.login_page__desc}>Or</span>
						</div>
						{/* TODO: Finish changing and calling the modal window. */}
						<div className={styles.login_page__socials_block}>
							{getIsExternalLoginEnabled(settingsStore.publicSettings.Auth?.[SiteSettingsKeys.GoogleAuthEnabled]) && (
								<GoogleLoginButton
									onSuccess={(tokenId) => socialSignIn(ExternalIdentityProviderTypes.Google, tokenId)}
								/>
							)}
							{getIsExternalLoginEnabled(settingsStore.publicSettings.Auth?.[SiteSettingsKeys.FacebookAuthEnabled]) && (
								<FacebookLoginButton />
							)}
							{getIsExternalLoginEnabled(settingsStore.publicSettings.Auth?.[SiteSettingsKeys.LinkedInAuthEnabled]) && (
								<LinkedInLoginButton />
							)}
							{getIsExternalLoginEnabled(settingsStore.publicSettings.Auth?.[SiteSettingsKeys.EnabledApiKeyAccess]) && (
								<ExternalApiLoginButton />
							)}
						</div>
					</>
				)}
			</section>
		</PublicLayout>
	);
});

export default LoginPage;
