//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum EventTypes {
	End = 1,
	Pause = 2,
	Play = 3,
	Start = 4,
	InfluencerFollowed = 10,
	InfluencerLiked = 11,
	InfluencerUnfollowed = 12,
	InfluencerUnliked = 13,
	SetPosition = 14,
	TabActive = 15,
	TabInactive = 16,
	ThreadLiked = 20,
	ThreadUnliked = 21,
	PostLiked = 22,
	PostUnliked = 23,
	ThreadBookmarked = 24,
	ThreadUnbookmarked = 25,
	ThreadRated = 26,
	CourseLiked = 27,
	CourseUnliked = 28,
	CourseBookmarked = 29,
	CourseUnbookmarked = 30,
	CourseRated = 31,
	SetPlaybackRate = 32,
	Complete = 33,
	ScormEvent = 34,
	Scroll = 35
}
