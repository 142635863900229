import styles from "./styles.module.scss";
import FacebookLogin, { ReactFacebookLoginInfo } from "react-facebook-login";
import { ReactComponent as FacebookLogo } from "assets/icons/ic_facebook-logo.svg";
import { useSocialSignIn } from "hooks/useSocialSignIn";
import { ExternalIdentityProviderTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ExternalIdentityProviderTypes";
import { useAppStore } from "store";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";

const FacebookLoginButton = () => {
	const { externalSignIn } = useSocialSignIn();
	const { settingsStore } = useAppStore();

	return (
		//Works only with HTTPS.
		<FacebookLogin
			appId={settingsStore.publicSettings.Auth?.[SiteSettingsKeys.FacebookAuthAppId]}
			autoLoad={false}
			fields="name,email,picture"
			callback={(response: ReactFacebookLoginInfo) => {
				if (!!response.accessToken) {
					externalSignIn({ externalProvider: ExternalIdentityProviderTypes.Facebook, token: response.accessToken });
				}
			}}
			onFailure={(resp) => console.log("Facebook login failed!", resp)}
			cssClass={styles.facebook_login_button}
			textButton="Sign in with Facebook"
			icon={<FacebookLogo className={styles.facebook_logo_icon} />}
			size="small"
		/>
	);
};
export default FacebookLoginButton;
