import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { IContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentModel";
import PlayerScormLite from "../player-scorm-lite/player-scorm-lite";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { useAppStore } from "store";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import PlayerControlMini from "../player-control-mini/player-control-mini";
import { useScormDifficult } from "hooks/useScormDifficult";
import { observer } from "mobx-react";

interface PlayerScormBoxProps {
	content: IContentModel;
	className?: string;
}

const cx = classNames.bind(styles);
const DEFAULT_URL = process.env.PUBLIC_URL + "/html/index.html";

const PlayerScormBox = (props: PlayerScormBoxProps) => {
	const { content, className } = props;
	const { contentStore, toasterStore } = useAppStore();

	const sendStart = () => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Start,
			payload: "",
		};
		contentStore.sendEventCourseItemHistory(data).catch((err) => toasterStore.showErrorMessage(err));
	};

	const { params, onLoad } = useScormDifficult(sendStart);

	return (
		<div className={cx(styles.player_scorm_box, className)}>
			<PlayerScormLite url={DEFAULT_URL} contentId={content.id} params={params} onLoad={onLoad}>
				<PlayerControlMini params={params} />
			</PlayerScormLite>
		</div>
	);
};

export default observer(PlayerScormBox);
