import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import { TableInstance } from "react-table";
import classNames from "classnames/bind";
import isEmpty from "lodash/isEmpty";
import { cmsItemClick } from "helpers/table.new-window-open";
import { useHistory } from "react-router";
import { mobileCheck } from "helpers/device.helper";

export interface CmsTileProps<D extends object> {
	item: D;
	onClick?: (item: D) => void;
}

interface CmsTableProps<D extends object> {
	table: TableInstance<D>;
	data: D[];
	getKey: (item: D) => any;
	tileComponent: React.FunctionComponent<CmsTileProps<D>>;
	route?: string;
	basePathOption?: object;
	className?: string;
	disableRowCursor?: boolean;
	disableRowHover?: boolean;
	ids?: number[];
}

const cx = classNames.bind(styles);

function CmsTable<D extends object>({
	table,
	data,
	getKey,
	tileComponent: TileComponent,
	route,
	basePathOption,
	className,
	disableRowCursor = false,
	disableRowHover = false,
	ids,
}: CmsTableProps<D>) {
	const { getTableProps, getTableBodyProps, headers, rows, prepareRow, visibleColumns } = table;
	const history = useHistory();
	const isMobile = useMemo(() => mobileCheck(), []);
	const tileClick = (item: D) => {
		// @ts-ignore
		const id = item.id;
		if (route && id) {
			cmsItemClick({ route, basePathOption, id, history });
		}
	};

	return !isMobile ? (
		<table {...getTableProps()} className={cx(styles.cms_table, className)}>
			<thead className={styles.cms_table__head}>
				<tr {...headers[0].getHeaderProps} className={styles.cms_table__head_col}>
					{visibleColumns.map((column) => (
						<th
							{...column.getHeaderProps()}
							className={styles.cms_table__head_column}
							data-column-id={column?.id ?? ""}
						>
							{column.render("Header")}
						</th>
					))}
				</tr>
			</thead>
			<tbody {...getTableBodyProps()} className={styles.cms_table__body}>
				{rows.map((row) => {
					// @ts-ignore
					const id = row.original.id;
					prepareRow(row);
					return (
						<tr
							{...row.getRowProps()}
							className={cx(styles.cms_table__row, {
								[styles.cms_table__disabled_cursor]: disableRowCursor,
								[styles.cms_table__row_hoverable]: !disableRowHover,
								[styles.cms_table__row_select]: ids?.includes(id),
							})}
							onClick={(event: React.MouseEvent<HTMLTableRowElement>) => {
								route && cmsItemClick({ route, basePathOption, id, history, event });
							}}
							onAuxClick={(event: React.MouseEvent<HTMLTableRowElement>) => {
								route && cmsItemClick({ route, basePathOption, id, history, event });
							}}
						>
							{row.cells.map((cell) => {
								return (
									<td
										{...cell.getCellProps()}
										className={styles.cms_table__cell}
										data-column-id={cell.column?.id ?? ""}
									>
										{cell.render("Cell")}
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	) : (
		<div className={styles.cms_table__mobile}>
			<div className={styles.cms_table__outline}>
				{!isEmpty(data) && data.map((item) => <TileComponent item={item} key={getKey(item)} onClick={tileClick} />)}
			</div>
		</div>
	);
}

export default CmsTable;
