import { getCarouselName, getCarouselsCount, getCarouselSequence, getImageFileUrl } from "helpers/settings.helper";
import { Dictionary, isNil } from "lodash";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { ILoginParameters } from "./settings-forms/login-settings/data";
import { AccountRoles } from "models/dto/ZoomiLxp/Utilities/Enumerations/AccountRoles";
import isEmpty from "lodash/isEmpty";
import { ITermsParameters } from "./settings-forms/terms-of-use-settings/data";
import {
	IBillingSettingsModelExtended,
	ICertificatesSettingsFormModel,
	ICommonPageSettings,
	IGeneralSettingsModel,
	IGeneralStringsSettingsModel,
	IMyDataPageSettings,
	IMyDeskPageSettings,
	IPlatformSettings,
	ITabPageSettings,
} from "./data/models";
import {
	HOME_CAROUSELS_KEYS,
	INFLUENCERS_CAROUSELS_KEYS,
	MY_DATA_CAROUSELS_KEYS,
	MYDESK_CAROUSELS_KEYS,
	PEERS_CAROUSELS_KEYS,
} from "./data/enums";

export const getHomePageSettings = (platformSettings: Dictionary<Dictionary<string>>): ICommonPageSettings => {
	return {
		tabName: platformSettings.Pages[SiteSettingsKeys.HomeTabName],
		tabColor: platformSettings.Colors[SiteSettingsKeys.ColorsNavbarHome],
		carouselsCount: getCarouselsCount(platformSettings, "Home.Carousels.Order"),
		banner: {
			url: platformSettings.Images[SiteSettingsKeys.HomeBanner],
		},
		carousels: [
			{
				name: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsNameCoursesCuratedForYou],
				sequence: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsOrderCoursesCuratedForYou],
				carouselKey: HOME_CAROUSELS_KEYS.curatedSpeciallyForYou,
				label: "'Curated Specially For You' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsNameHighestRated],
				sequence: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsOrderHighestRated],
				carouselKey: HOME_CAROUSELS_KEYS.mostRated,
				label: "'Most Rated' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsNameMostWatched],
				sequence: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsOrderMostWatched],
				carouselKey: HOME_CAROUSELS_KEYS.mostWatched,
				label: "'Most Watched' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsNamePlaylist],
				sequence: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsOrderPlaylist],
				carouselKey: HOME_CAROUSELS_KEYS.playlistCarousel,
				label: "'User`s playlist' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsNameRequiredSkillsFor],
				sequence: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsOrderRequiredSkillsFor],
				carouselKey: HOME_CAROUSELS_KEYS.requiredSkillsCarousel,
				label: "'Required Skills For' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsNameTrendingCourses],
				sequence: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsOrderTrendingCourses],
				carouselKey: HOME_CAROUSELS_KEYS.trendingCarousel,
				label: "'Trending last week' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsNamePurchasedCourses],
				sequence: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsOrderPurchasedCourses],
				carouselKey: HOME_CAROUSELS_KEYS.purchasedCarousel,
				label: "'Purchased courses' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsNameAssignedToYou],
				sequence: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsOrderAssignedToYou],
				carouselKey: HOME_CAROUSELS_KEYS.assignedCarousel,
				label: "'Assigned to you' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsNameMeetYourGoals],
				sequence: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsOrderMeetYourGoals],
				carouselKey: HOME_CAROUSELS_KEYS.goalsCarousel,
				label: "'Your goals' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsNameMostLiked],
				sequence: platformSettings.Pages[SiteSettingsKeys.HomeCarouselsOrderMostLiked],
				carouselKey: HOME_CAROUSELS_KEYS.mostLikedCarousel,
				label: "'Most liked' name",
			},
		],
	};
};

export const getInfluencersPageSettings = (platformSettings: Dictionary<Dictionary<string>>): ICommonPageSettings => {
	return {
		tabName: platformSettings.Pages[SiteSettingsKeys.InfluencerTabName],
		tabColor: platformSettings.Colors[SiteSettingsKeys.ColorsNavbarInfluencers],
		carouselsCount: getCarouselsCount(platformSettings, "InfluencersRecommendations.Carousels.Order"),
		banner: {
			url: platformSettings.Images[SiteSettingsKeys.InfluencersBanner],
		},
		carousels: [
			{
				name: platformSettings.Pages[
					SiteSettingsKeys.InfluencersRecommendationsCarouselsNamePeopleYouShouldBeFollowing
				],
				sequence:
					platformSettings.Pages[SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderPeopleYouShouldBeFollowing],
				carouselKey: INFLUENCERS_CAROUSELS_KEYS.shouldFollowCarousel,
				label: "'Should follow' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.InfluencersRecommendationsCarouselsNameMostLikedInfluencers],
				sequence: platformSettings.Pages[SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderMostLikedInfluencers],
				carouselKey: INFLUENCERS_CAROUSELS_KEYS.mostLikedCarousel,
				label: "'Most Liked' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.InfluencersRecommendationsCarouselsNameMostFollowedInfluencers],
				sequence:
					platformSettings.Pages[SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderMostFollowedInfluencers],
				carouselKey: INFLUENCERS_CAROUSELS_KEYS.mostFollowedCarousel,
				label: "'Most followed' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.InfluencersRecommendationsCarouselsNameLastFollowedInfluencers],
				sequence:
					platformSettings.Pages[SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderLastFollowedInfluencers],
				carouselKey: INFLUENCERS_CAROUSELS_KEYS.myInfluencersCarousel,
				label: "'Last followed' name",
			},
			{
				name: platformSettings.Pages[
					SiteSettingsKeys.InfluencersRecommendationsCarouselsNameMoversAndShakersOfLastWeek
				],
				sequence:
					platformSettings.Pages[SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderMoversAndShakersOfLastWeek],
				carouselKey: INFLUENCERS_CAROUSELS_KEYS.moversAndShakersCarousel,
				label: "'Movers and Shakers' name",
			},
		],
	};
};

export const getPeersPageSettings = (platformSettings: Dictionary<Dictionary<string>>): ICommonPageSettings => {
	return {
		tabName: platformSettings.Pages[SiteSettingsKeys.PeersTabName],
		tabColor: platformSettings.Colors[SiteSettingsKeys.ColorsNavbarPeers],
		carouselsCount: getCarouselsCount(platformSettings, "Peers.Carousels.Order"),
		banner: {
			url: platformSettings.Images[SiteSettingsKeys.PeersBanner],
		},
		carousels: [
			{
				name: platformSettings.Pages[SiteSettingsKeys.PeersCarouselsNameThreadsCreatedByYou],
				sequence: platformSettings.Pages[SiteSettingsKeys.PeersCarouselsOrderThreadsCreatedByYou],
				carouselKey: PEERS_CAROUSELS_KEYS.createdByYouCarousel,
				label: "'Created by you' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.PeersCarouselsNameThreadsCuratedForYou],
				sequence: platformSettings.Pages[SiteSettingsKeys.PeersCarouselsOrderThreadsCuratedForYou],
				carouselKey: PEERS_CAROUSELS_KEYS.curatedForYouCarousel,
				label: "'Curated for you' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.PeersCarouselsNameLastSavedThreads],
				sequence: platformSettings.Pages[SiteSettingsKeys.PeersCarouselsOrderLastSavedThreads],
				carouselKey: PEERS_CAROUSELS_KEYS.savedThreadsCarousel,
				label: "'Last saved threads' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.PeersCarouselsNameTrendingThreads],
				sequence: platformSettings.Pages[SiteSettingsKeys.PeersCarouselsOrderTrendingThreads],
				carouselKey: PEERS_CAROUSELS_KEYS.trendingCarousel,
				label: "'Trending' name",
			},
		],
	};
};

export const getMyDataSettings = (platformSettings: Dictionary<Dictionary<string>>): IMyDataPageSettings => {
	return {
		tabName: platformSettings.Pages[SiteSettingsKeys.MyDataTabName],
		tabColor: platformSettings.Colors[SiteSettingsKeys.ColorsNavbarMyData],
		showQuizResultsTab: toBoolean(platformSettings.Pages[SiteSettingsKeys.MyDataTabQuizResultsTabVisible]),
		carousels: [
			{
				name: platformSettings.Pages[SiteSettingsKeys.HistoryCarouselsNameFollowingInfluencers],
				sequence: platformSettings.Pages[SiteSettingsKeys.HistoryCarouselsOrderFollowingInfluencers],
				carouselKey: MY_DATA_CAROUSELS_KEYS.followingInfluencers,
				label: "'Following influencers' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HistoryCarouselsNameWatchingHistory],
				sequence: platformSettings.Pages[SiteSettingsKeys.HistoryCarouselsOrderWatchingHistory],
				carouselKey: MY_DATA_CAROUSELS_KEYS.watchingHistory,
				label: "'Watching history' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HistoryCarouselsNameContinueWatching],
				sequence: platformSettings.Pages[SiteSettingsKeys.HistoryCarouselsOrderContinueWatching],
				carouselKey: MY_DATA_CAROUSELS_KEYS.continueWatching,
				label: "'Continue watching' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HistoryCarouselsNameBookmarkedCourses],
				sequence: platformSettings.Pages[SiteSettingsKeys.HistoryCarouselsOrderBookmarkedCourses],
				carouselKey: MY_DATA_CAROUSELS_KEYS.bookmarkedCourses,
				label: "'Bookmarked courses' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HistoryCarouselsNameCoursesCreatedByYou],
				sequence: platformSettings.Pages[SiteSettingsKeys.HistoryCarouselsOrderCoursesCreatedByYou],
				carouselKey: MY_DATA_CAROUSELS_KEYS.coursesCreatedByYou,
				label: "'Courses created by you' name",
			},
			{
				name: platformSettings.Pages[SiteSettingsKeys.HistoryCarouselsNameThreadsCreatedByYou],
				sequence: platformSettings.Pages[SiteSettingsKeys.HistoryCarouselsOrderThreadsCreatedByYou],
				carouselKey: MY_DATA_CAROUSELS_KEYS.threadsCreatedByYou,
				label: "'Threads created by you' name",
			},
		],
	};
};

export const toBoolean = (value: string) => {
	return value ? value.toLowerCase() === "true" : false;
};

export const convertPlatformSettings = (platformSettings: Dictionary<Dictionary<string>>): IPlatformSettings => {
	return {
		general: {
			...convertFromGeneralSettingsResponse(platformSettings),
			playerBackgroundColor: platformSettings.Colors[SiteSettingsKeys.ColorsBackgroundMyDeskPlayer]
		},
		billing: convertFromBillingSettingsResponse(platformSettings),
		certificates: convertFromCertificatesSettingsResponse(platformSettings),
		home: getHomePageSettings(platformSettings),
		myDesk: {
			tabName: platformSettings.Pages[SiteSettingsKeys.MyDeskTabName],
			tabColor: platformSettings.Colors[SiteSettingsKeys.ColorsNavbarDesk],
			carouselsCount: getCarouselsCount(platformSettings, "MyDesk.Carousels.Order"),
			carousels: [
				{
					name: platformSettings.Pages[SiteSettingsKeys.MyDeskCarouselsNameMostWatched],
					sequence: platformSettings.Pages[SiteSettingsKeys.MyDeskCarouselsOrderMostWatched],
					carouselKey: MYDESK_CAROUSELS_KEYS.mostWatchedCarousel,
					label: "'Most Watched' name",
				},
				{
					name: platformSettings.Pages[SiteSettingsKeys.MyDeskCarouselsNameSimilarCourses],
					sequence: platformSettings.Pages[SiteSettingsKeys.MyDeskCarouselsOrderSimilarCourses],
					carouselKey: MYDESK_CAROUSELS_KEYS.similarCoursesCarousel,
					label: "'Related Courses' name",
				},
				{
					name: platformSettings.Pages[SiteSettingsKeys.MyDeskCarouselsNameAssignedToYou],
					sequence: platformSettings.Pages[SiteSettingsKeys.MyDeskCarouselsOrderAssignedToYou],
					carouselKey: MYDESK_CAROUSELS_KEYS.assignedToYouCarousel,
					label: "'Assigned to you' name",
				},
				{
					name: platformSettings.Pages[SiteSettingsKeys.MyDeskCarouselsNameMeetYourGoals],
					sequence: platformSettings.Pages[SiteSettingsKeys.MyDeskCarouselsOrderMeetYourGoals],
					carouselKey: MYDESK_CAROUSELS_KEYS.meetYourGoalsCarousel,
					label: "'Meet your goals' name",
				},
			],
			isCommentsOnTheRightSide: toBoolean(platformSettings.Pages[SiteSettingsKeys.MyDeskCommentsOnTheRightSide]),
			isRecommendationsBelowComments: toBoolean(
				platformSettings.Pages[SiteSettingsKeys.MyDeskRecommendationsBelowComments]
			),
			isRecommendSegmentsInsideOfCurrentCourse: toBoolean(
				platformSettings.Pages[SiteSettingsKeys.MyDeskRecommendSegmentsInsideOfCurrentCourse]
			),
			hideCreatorName: toBoolean(platformSettings.Pages[SiteSettingsKeys.MyDeskHideCreatorName]),
			navArrowsTooltip: platformSettings.Pages[SiteSettingsKeys.MyDeskCourseItemsNameTooltipForArrowButtons],
			alwaysShowNavArrows: toBoolean(platformSettings.Pages[SiteSettingsKeys.MyDeskShowAlwaysPrevNextButtons]),
			allowClickableLinks: toBoolean(platformSettings.Pages[SiteSettingsKeys.MyDeskAllowClickableLinks]),
		},
		peers: getPeersPageSettings(platformSettings),
		influencers: getInfluencersPageSettings(platformSettings),
		cms: {
			tabName: platformSettings.Pages[SiteSettingsKeys.CMSTabName],
			tabColor: platformSettings.Colors[SiteSettingsKeys.ColorsNavbarCms],
		},
		myData: getMyDataSettings(platformSettings),
		reports: {
			tabName: platformSettings.Pages[SiteSettingsKeys.ReportsTabName],
			tabColor: platformSettings.Colors[SiteSettingsKeys.ColorsNavbarReports],
		},
		terms: {
			termsOfUse: platformSettings.Wiki[SiteSettingsKeys.WikiTermsOfUse],
			termsOfUseEnabled: toBoolean(platformSettings.Wiki[SiteSettingsKeys.WikiTermsOfUseEnabled]),
		},
		auth: {
			apiKey: platformSettings.Auth[SiteSettingsKeys.ApiKey],
			enabledApiKeyAccess: toBoolean(platformSettings.Auth[SiteSettingsKeys.EnabledApiKeyAccess]),
			digitalUniverseClientId: platformSettings.Auth[SiteSettingsKeys.DigitalUniverseClientId],
			digitalUniverseUrl: platformSettings.Auth[SiteSettingsKeys.DigitalUniverseBaseUrl],
			allowedDomains: platformSettings.Auth[SiteSettingsKeys.AllowedDomains],
			defaultCompany: platformSettings.Auth[SiteSettingsKeys.DefaultCompany],
			defaultRole: platformSettings.Auth[SiteSettingsKeys.DefaultRole],
			facebookAuth: {
				appId: platformSettings.Auth[SiteSettingsKeys.FacebookAuthAppId],
				isActive: toBoolean(platformSettings.Auth[SiteSettingsKeys.FacebookAuthEnabled]),
			},
			googleAuth: {
				appId: platformSettings.Auth[SiteSettingsKeys.GoogleAuthAppId],
				isActive: toBoolean(platformSettings.Auth[SiteSettingsKeys.GoogleAuthEnabled]),
			},
			linkedInAuth: {
				appId: platformSettings.Auth[SiteSettingsKeys.LinkedInAuthAppId],
				isActive: toBoolean(platformSettings.Auth[SiteSettingsKeys.LinkedInAuthEnabled]),
			},
			internalAuthEnabled: toBoolean(platformSettings.Auth[SiteSettingsKeys.InternalAuthEnabled]),
			is2FAEnabled: toBoolean(platformSettings.Auth[SiteSettingsKeys.Enabled2FA]),
		},
		reporting: {
			reportByUser: platformSettings.Reports?.[SiteSettingsKeys.ReportByUser],
			reportByCourse: platformSettings.Reports?.[SiteSettingsKeys.ReportByCourse],
		},
	};
};

export const convertFromGeneralSettingsResponse = (
	generalData: Dictionary<Dictionary<string>>
): IGeneralSettingsModel => {
	if (!isNil(generalData)) {
		return {
			favicon: { url: generalData.Images[SiteSettingsKeys.Favicon] },
			logo: { url: generalData.Images[SiteSettingsKeys.Logo] },
			strings: convertFromStringsSettingsResponse(generalData.Strings),
			colorsHeaderBackground: generalData.Colors[SiteSettingsKeys.ColorsBackgroundNavbar],
			colorsPrimary: generalData.Colors[SiteSettingsKeys.ColorsPrimary],
			colorsPrimaryText: generalData.Colors[SiteSettingsKeys.ColorsTextPrimary],
			colorsSecondary: generalData.Colors[SiteSettingsKeys.ColorsSecondary],
			colorsSecondaryText: generalData.Colors[SiteSettingsKeys.ColorsTextSecondary],
			colorsGeneralBackground: generalData.Colors[SiteSettingsKeys.ColorsBackgroundLayout],
			colorsGeneralText: generalData.Colors[SiteSettingsKeys.ColorsTextGeneral],
			colorsNavbarBackground: generalData.Colors[SiteSettingsKeys.ColorsBackgroundLayoutHeader],
			colorsNavbarText: generalData.Colors[SiteSettingsKeys.ColorsTextLayoutHeader],
			colorsTilesBackground: generalData.Colors[SiteSettingsKeys.ColorsBackgroundTile],
			colorsTilesText: generalData.Colors[SiteSettingsKeys.ColorsTextTiles],
			colorsInputsBackground: generalData.Colors[SiteSettingsKeys.ColorsInputBackground],
			colorsInputsText: generalData.Colors[SiteSettingsKeys.ColorsTextInputPrimary],
			colorsGrid: generalData.Colors[SiteSettingsKeys.ColorsGrid],
			colorsGridText: generalData.Colors[SiteSettingsKeys.ColorsTextGrid],
			playerBackgroundColor: generalData.Colors[SiteSettingsKeys.ColorsBackgroundMyDeskPlayer],
		};
	}
	return {} as IGeneralSettingsModel;
};

export const convertToGeneralSettingsRequest = (generalData: IGeneralSettingsModel): Dictionary<string> => {
	const generalStringsData = convertToStringsSettingsRequest(generalData.strings);
	return {
		...generalStringsData,
		[SiteSettingsKeys.Favicon]: getImageFileUrl("favicon", generalData.favicon),
		[SiteSettingsKeys.Logo]: getImageFileUrl("logo", generalData.logo),
		[SiteSettingsKeys.ColorsBackgroundLayoutHeader]: generalData.colorsNavbarBackground,
		[SiteSettingsKeys.ColorsPrimary]: generalData.colorsPrimary,
		[SiteSettingsKeys.ColorsTextPrimary]: generalData.colorsPrimaryText,
		[SiteSettingsKeys.ColorsSecondary]: generalData.colorsSecondary,
		[SiteSettingsKeys.ColorsTextSecondary]: generalData.colorsSecondaryText,
		[SiteSettingsKeys.ColorsBackgroundLayout]: generalData.colorsGeneralBackground,
		[SiteSettingsKeys.ColorsTextGeneral]: generalData.colorsGeneralText,
		[SiteSettingsKeys.ColorsBackgroundNavbar]: generalData.colorsHeaderBackground,
		[SiteSettingsKeys.ColorsTextLayoutHeader]: generalData.colorsNavbarText,
		[SiteSettingsKeys.ColorsBackgroundTile]: generalData.colorsTilesBackground,
		[SiteSettingsKeys.ColorsTextTiles]: generalData.colorsTilesText,
		[SiteSettingsKeys.ColorsInputBackground]: generalData.colorsInputsBackground,
		[SiteSettingsKeys.ColorsTextInputPrimary]: generalData.colorsInputsText,
		[SiteSettingsKeys.ColorsGrid]: generalData.colorsGrid,
		[SiteSettingsKeys.ColorsTextGrid]: generalData.colorsGridText,
		[SiteSettingsKeys.ColorsBackgroundMyDeskPlayer]: generalData.playerBackgroundColor,
	};
};

export const convertFromCertificatesSettingsResponse = (
	certificatesData: Dictionary<Dictionary<string>>
): ICertificatesSettingsFormModel => {
	if (!isNil(certificatesData) && !isNil(certificatesData.Certificates)) {
		return {
			signatureImg: {
				url: certificatesData.Certificates[SiteSettingsKeys.CertificatesSignature + ".Url"],
				id: Number(certificatesData.Certificates[SiteSettingsKeys.CertificatesSignature]),
			},
			stampImg: {
				url: certificatesData.Certificates[SiteSettingsKeys.CertificatesStamp + ".Url"],
				id: Number(certificatesData.Certificates[SiteSettingsKeys.CertificatesStamp]),
			},
			enableCertificates: toBoolean(certificatesData.Certificates[SiteSettingsKeys.CertificatesEnable]),
			enableQRcode: toBoolean(certificatesData.Certificates[SiteSettingsKeys.CertificatesGenerateQR]),
			issuedBy: certificatesData.Certificates[SiteSettingsKeys.CertificatesIssuedBy],
		};
	}
	return {} as ICertificatesSettingsFormModel;
};

export const convertToCertificatesSettingsRequest = (
	certificatesData: ICertificatesSettingsFormModel
): Dictionary<string> => {
	return {
		[SiteSettingsKeys.CertificatesSignature]: getImageFileUrl("other", certificatesData.signatureImg),
		[SiteSettingsKeys.CertificatesStamp]: getImageFileUrl("other", certificatesData.stampImg),
		[SiteSettingsKeys.CertificatesEnable]: String(certificatesData.enableCertificates),
		[SiteSettingsKeys.CertificatesGenerateQR]: String(certificatesData.enableQRcode),
		[SiteSettingsKeys.CertificatesIssuedBy]: certificatesData.issuedBy,
	};
};

export const convertFromBillingSettingsResponse = (
	settings: Dictionary<Dictionary<string>>
): IBillingSettingsModelExtended => {
	if (!isNil(settings) && !isNil(settings.Billing)) {
		return {
			useCustomStripe: toBoolean(settings.Billing[SiteSettingsKeys.BillingUseOwnStripe]),
			stripeSecretKey: settings.Billing[SiteSettingsKeys.BillingStripeSecretKey],
			stripeEndpointSecret: settings.Billing[SiteSettingsKeys.BillingStripeEndpointSecret],
			stripeAllowPromocodes: toBoolean(settings.Billing[SiteSettingsKeys.BillingStripeAllowPromocodes]),
			strings: {
				taxCode: settings.Strings[SiteSettingsKeys.StringsTaxCode],
				taxBehavior: settings.Strings[SiteSettingsKeys.StringsTaxBehavior],
			},
		};
	}
	return {} as IBillingSettingsModelExtended;
};

export const convertToBillingSettingsRequest = (billingData: IBillingSettingsModelExtended): Dictionary<string> => {
	let baseData = {
		[SiteSettingsKeys.BillingUseOwnStripe]: String(billingData.useCustomStripe),
		[SiteSettingsKeys.StringsTaxCode]: billingData.strings.taxCode,
		[SiteSettingsKeys.StringsTaxBehavior]: billingData.strings.taxBehavior,
		[SiteSettingsKeys.BillingStripeAllowPromocodes]: String(billingData.stripeAllowPromocodes),
	};

	if (billingData.stripeEndpointSecret) {
		baseData = {
			...baseData,
			[SiteSettingsKeys.BillingStripeEndpointSecret]: billingData.stripeEndpointSecret,
		};
	}

	if (billingData.stripeSecretKey) {
		baseData = {
			...baseData,
			[SiteSettingsKeys.BillingStripeSecretKey]: billingData.stripeSecretKey,
		};
	}

	return baseData;
};

export const convertFromStringsSettingsResponse = (stringsData: Dictionary<string>): IGeneralStringsSettingsModel => {
	if (!isNil(stringsData)) {
		return {
			companyName: stringsData[SiteSettingsKeys.StringsCompanyName],
			platformName: stringsData[SiteSettingsKeys.StringsPlatformName],
			teamName: stringsData[SiteSettingsKeys.StringsTeamName],
			apiVersion: stringsData[SiteSettingsKeys.StringsCurrentApiVersion],
		};
	}
	return {} as IGeneralStringsSettingsModel;
};

export const convertToStringsSettingsRequest = (stringsData: IGeneralStringsSettingsModel): Dictionary<string> => {
	return {
		[SiteSettingsKeys.StringsCompanyName]: stringsData.companyName,
		[SiteSettingsKeys.StringsPlatformName]: stringsData.platformName,
		[SiteSettingsKeys.StringsTeamName]: stringsData.teamName,
		[SiteSettingsKeys.StringsCurrentApiVersion]: stringsData.apiVersion,
	};
};

export const convertToHomeSettingsRequest = (homeData: ICommonPageSettings): Dictionary<string> => {
	return {
		[SiteSettingsKeys.HomeBanner]: getImageFileUrl("banner", homeData.banner),
		[SiteSettingsKeys.ColorsNavbarHome]: homeData.tabColor,
		[SiteSettingsKeys.HomeTabName]: homeData.tabName,
		[SiteSettingsKeys.HomeCarouselsNameCoursesCuratedForYou]: getCarouselName(
			HOME_CAROUSELS_KEYS.curatedSpeciallyForYou,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsOrderCoursesCuratedForYou]: getCarouselSequence(
			HOME_CAROUSELS_KEYS.curatedSpeciallyForYou,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsNameHighestRated]: getCarouselName(
			HOME_CAROUSELS_KEYS.mostRated,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsOrderHighestRated]: getCarouselSequence(
			HOME_CAROUSELS_KEYS.mostRated,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsNameMostWatched]: getCarouselName(
			HOME_CAROUSELS_KEYS.mostWatched,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsOrderMostWatched]: getCarouselSequence(
			HOME_CAROUSELS_KEYS.mostWatched,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsNamePlaylist]: getCarouselName(
			HOME_CAROUSELS_KEYS.playlistCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsOrderPlaylist]: getCarouselSequence(
			HOME_CAROUSELS_KEYS.playlistCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsNameRequiredSkillsFor]: getCarouselName(
			HOME_CAROUSELS_KEYS.requiredSkillsCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsOrderRequiredSkillsFor]: getCarouselSequence(
			HOME_CAROUSELS_KEYS.requiredSkillsCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsNameTrendingCourses]: getCarouselName(
			HOME_CAROUSELS_KEYS.trendingCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsOrderTrendingCourses]: getCarouselSequence(
			HOME_CAROUSELS_KEYS.trendingCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsNamePurchasedCourses]: getCarouselName(
			HOME_CAROUSELS_KEYS.purchasedCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsOrderPurchasedCourses]: getCarouselSequence(
			HOME_CAROUSELS_KEYS.purchasedCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsNameAssignedToYou]: getCarouselName(
			HOME_CAROUSELS_KEYS.assignedCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsOrderAssignedToYou]: getCarouselSequence(
			HOME_CAROUSELS_KEYS.assignedCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsNameMeetYourGoals]: getCarouselName(
			HOME_CAROUSELS_KEYS.goalsCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsOrderMeetYourGoals]: getCarouselSequence(
			HOME_CAROUSELS_KEYS.goalsCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsNameMostLiked]: getCarouselName(
			HOME_CAROUSELS_KEYS.mostLikedCarousel,
			homeData.carousels
		),
		[SiteSettingsKeys.HomeCarouselsOrderMostLiked]: getCarouselSequence(
			HOME_CAROUSELS_KEYS.mostLikedCarousel,
			homeData.carousels
		),
	};
};

export const convertToMyDeskSettingsRequest = (myDeskData: IMyDeskPageSettings): Dictionary<string> => {
	return {
		[SiteSettingsKeys.ColorsNavbarDesk]: myDeskData.tabColor,
		[SiteSettingsKeys.MyDeskTabName]: myDeskData.tabName,
		[SiteSettingsKeys.MyDeskCarouselsNameMostWatched]: getCarouselName(
			MYDESK_CAROUSELS_KEYS.mostWatchedCarousel,
			myDeskData.carousels
		),
		[SiteSettingsKeys.MyDeskCarouselsOrderMostWatched]: getCarouselSequence(
			MYDESK_CAROUSELS_KEYS.mostWatchedCarousel,
			myDeskData.carousels
		),
		[SiteSettingsKeys.MyDeskCarouselsNameSimilarCourses]: getCarouselName(
			MYDESK_CAROUSELS_KEYS.similarCoursesCarousel,
			myDeskData.carousels
		),
		[SiteSettingsKeys.MyDeskCarouselsOrderSimilarCourses]: getCarouselSequence(
			MYDESK_CAROUSELS_KEYS.similarCoursesCarousel,
			myDeskData.carousels
		),
		[SiteSettingsKeys.MyDeskCarouselsNameMeetYourGoals]: getCarouselName(
			MYDESK_CAROUSELS_KEYS.meetYourGoalsCarousel,
			myDeskData.carousels
		),
		[SiteSettingsKeys.MyDeskCarouselsOrderMeetYourGoals]: getCarouselSequence(
			MYDESK_CAROUSELS_KEYS.meetYourGoalsCarousel,
			myDeskData.carousels
		),
		[SiteSettingsKeys.MyDeskCarouselsNameAssignedToYou]: getCarouselName(
			MYDESK_CAROUSELS_KEYS.assignedToYouCarousel,
			myDeskData.carousels
		),
		[SiteSettingsKeys.MyDeskCarouselsOrderAssignedToYou]: getCarouselSequence(
			MYDESK_CAROUSELS_KEYS.assignedToYouCarousel,
			myDeskData.carousels
		),
		[SiteSettingsKeys.MyDeskCommentsOnTheRightSide]: String(myDeskData.isCommentsOnTheRightSide),
		[SiteSettingsKeys.MyDeskRecommendationsBelowComments]: String(myDeskData.isRecommendationsBelowComments),
		[SiteSettingsKeys.MyDeskRecommendSegmentsInsideOfCurrentCourse]: String(
			myDeskData.isRecommendSegmentsInsideOfCurrentCourse
		),
		[SiteSettingsKeys.MyDeskHideCreatorName]: String(myDeskData.hideCreatorName),
		[SiteSettingsKeys.MyDeskShowAlwaysPrevNextButtons]: String(myDeskData.alwaysShowNavArrows),
		[SiteSettingsKeys.MyDeskCourseItemsNameTooltipForArrowButtons]: myDeskData.navArrowsTooltip,
		[SiteSettingsKeys.MyDeskAllowClickableLinks]: String(myDeskData.allowClickableLinks),
	};
};

export const convertToPeersSettingsRequest = (peersData: ICommonPageSettings): Dictionary<string> => {
	return {
		[SiteSettingsKeys.PeersBanner]: getImageFileUrl("banner", peersData.banner),
		[SiteSettingsKeys.ColorsNavbarPeers]: peersData.tabColor,
		[SiteSettingsKeys.PeersTabName]: peersData.tabName,
		[SiteSettingsKeys.PeersCarouselsNameThreadsCreatedByYou]: getCarouselName(
			PEERS_CAROUSELS_KEYS.createdByYouCarousel,
			peersData.carousels
		),
		[SiteSettingsKeys.PeersCarouselsOrderThreadsCreatedByYou]: getCarouselSequence(
			PEERS_CAROUSELS_KEYS.createdByYouCarousel,
			peersData.carousels
		),
		[SiteSettingsKeys.PeersCarouselsNameThreadsCuratedForYou]: getCarouselName(
			PEERS_CAROUSELS_KEYS.curatedForYouCarousel,
			peersData.carousels
		),
		[SiteSettingsKeys.PeersCarouselsOrderThreadsCuratedForYou]: getCarouselSequence(
			PEERS_CAROUSELS_KEYS.curatedForYouCarousel,
			peersData.carousels
		),
		[SiteSettingsKeys.PeersCarouselsNameTrendingThreads]: getCarouselName(
			PEERS_CAROUSELS_KEYS.trendingCarousel,
			peersData.carousels
		),
		[SiteSettingsKeys.PeersCarouselsOrderTrendingThreads]: getCarouselSequence(
			PEERS_CAROUSELS_KEYS.trendingCarousel,
			peersData.carousels
		),
		[SiteSettingsKeys.PeersCarouselsNameLastSavedThreads]: getCarouselName(
			PEERS_CAROUSELS_KEYS.savedThreadsCarousel,
			peersData.carousels
		),
		[SiteSettingsKeys.PeersCarouselsOrderLastSavedThreads]: getCarouselSequence(
			PEERS_CAROUSELS_KEYS.savedThreadsCarousel,
			peersData.carousels
		),
	};
};

export const convertToInfluencersSettingsRequest = (influencersData: ICommonPageSettings): Dictionary<string> => {
	return {
		[SiteSettingsKeys.InfluencersBanner]: getImageFileUrl("banner", influencersData.banner),
		[SiteSettingsKeys.ColorsNavbarInfluencers]: influencersData.tabColor,
		[SiteSettingsKeys.InfluencerTabName]: influencersData.tabName,
		[SiteSettingsKeys.InfluencersRecommendationsCarouselsNamePeopleYouShouldBeFollowing]: getCarouselName(
			INFLUENCERS_CAROUSELS_KEYS.shouldFollowCarousel,
			influencersData.carousels
		),
		[SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderPeopleYouShouldBeFollowing]: getCarouselSequence(
			INFLUENCERS_CAROUSELS_KEYS.shouldFollowCarousel,
			influencersData.carousels
		),
		[SiteSettingsKeys.InfluencersRecommendationsCarouselsNameMostLikedInfluencers]: getCarouselName(
			INFLUENCERS_CAROUSELS_KEYS.mostLikedCarousel,
			influencersData.carousels
		),
		[SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderMostLikedInfluencers]: getCarouselSequence(
			INFLUENCERS_CAROUSELS_KEYS.mostLikedCarousel,
			influencersData.carousels
		),
		[SiteSettingsKeys.InfluencersRecommendationsCarouselsNameMostFollowedInfluencers]: getCarouselName(
			INFLUENCERS_CAROUSELS_KEYS.mostFollowedCarousel,
			influencersData.carousels
		),
		[SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderMostFollowedInfluencers]: getCarouselSequence(
			INFLUENCERS_CAROUSELS_KEYS.mostFollowedCarousel,
			influencersData.carousels
		),
		[SiteSettingsKeys.InfluencersRecommendationsCarouselsNameLastFollowedInfluencers]: getCarouselName(
			INFLUENCERS_CAROUSELS_KEYS.myInfluencersCarousel,
			influencersData.carousels
		),
		[SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderLastFollowedInfluencers]: getCarouselSequence(
			INFLUENCERS_CAROUSELS_KEYS.myInfluencersCarousel,
			influencersData.carousels
		),
		[SiteSettingsKeys.InfluencersRecommendationsCarouselsNameMoversAndShakersOfLastWeek]: getCarouselName(
			INFLUENCERS_CAROUSELS_KEYS.moversAndShakersCarousel,
			influencersData.carousels
		),
		[SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderMoversAndShakersOfLastWeek]: getCarouselSequence(
			INFLUENCERS_CAROUSELS_KEYS.moversAndShakersCarousel,
			influencersData.carousels
		),
	};
};

export const convertToCmsSettingsRequest = (tabsData: ITabPageSettings): Dictionary<string> => {
	return {
		[SiteSettingsKeys.ColorsNavbarCms]: tabsData.tabColor,
		[SiteSettingsKeys.CMSTabName]: tabsData.tabName,
	};
};

export const convertToMyDataSettingsRequest = (tabsData: IMyDataPageSettings): Dictionary<string> => {
	return {
		[SiteSettingsKeys.ColorsNavbarMyData]: tabsData.tabColor,
		[SiteSettingsKeys.MyDataTabName]: tabsData.tabName,
		[SiteSettingsKeys.MyDataTabQuizResultsTabVisible]: String(tabsData.showQuizResultsTab),
		[SiteSettingsKeys.HistoryCarouselsNameFollowingInfluencers]: getCarouselName(
			MY_DATA_CAROUSELS_KEYS.followingInfluencers,
			tabsData.carousels
		),
		[SiteSettingsKeys.HistoryCarouselsOrderFollowingInfluencers]: getCarouselSequence(
			MY_DATA_CAROUSELS_KEYS.followingInfluencers,
			tabsData.carousels
		),
		[SiteSettingsKeys.HistoryCarouselsNameWatchingHistory]: getCarouselName(
			MY_DATA_CAROUSELS_KEYS.watchingHistory,
			tabsData.carousels
		),
		[SiteSettingsKeys.HistoryCarouselsOrderWatchingHistory]: getCarouselSequence(
			MY_DATA_CAROUSELS_KEYS.watchingHistory,
			tabsData.carousels
		),
		[SiteSettingsKeys.HistoryCarouselsNameContinueWatching]: getCarouselName(
			MY_DATA_CAROUSELS_KEYS.continueWatching,
			tabsData.carousels
		),
		[SiteSettingsKeys.HistoryCarouselsOrderContinueWatching]: getCarouselSequence(
			MY_DATA_CAROUSELS_KEYS.continueWatching,
			tabsData.carousels
		),
		[SiteSettingsKeys.HistoryCarouselsNameCoursesCreatedByYou]: getCarouselName(
			MY_DATA_CAROUSELS_KEYS.coursesCreatedByYou,
			tabsData.carousels
		),
		[SiteSettingsKeys.HistoryCarouselsOrderCoursesCreatedByYou]: getCarouselSequence(
			MY_DATA_CAROUSELS_KEYS.coursesCreatedByYou,
			tabsData.carousels
		),
		[SiteSettingsKeys.HistoryCarouselsNameThreadsCreatedByYou]: getCarouselName(
			MY_DATA_CAROUSELS_KEYS.threadsCreatedByYou,
			tabsData.carousels
		),
		[SiteSettingsKeys.HistoryCarouselsOrderThreadsCreatedByYou]: getCarouselSequence(
			MY_DATA_CAROUSELS_KEYS.threadsCreatedByYou,
			tabsData.carousels
		),
		[SiteSettingsKeys.HistoryCarouselsNameBookmarkedCourses]: getCarouselName(
			MY_DATA_CAROUSELS_KEYS.bookmarkedCourses,
			tabsData.carousels
		),
		[SiteSettingsKeys.HistoryCarouselsOrderBookmarkedCourses]: getCarouselSequence(
			MY_DATA_CAROUSELS_KEYS.bookmarkedCourses,
			tabsData.carousels
		),
	};
};

export const convertToReportsSettingsRequest = (tabsData: ITabPageSettings): Dictionary<string> => {
	return {
		[SiteSettingsKeys.ColorsNavbarReports]: tabsData.tabColor,
		[SiteSettingsKeys.ReportsTabName]: tabsData.tabName,
	};
};

export const convertToTermsOfUseSettingsRequest = (fileId: number, data: ITermsParameters): Dictionary<string> => {
	return {
		[SiteSettingsKeys.WikiTermsOfUse]: String(fileId),
		[SiteSettingsKeys.WikiTermsOfUseEnabled]: String(data.termsOfUseEnabled),
	};
};

export const convertToLoginSettingsRequest = (loginData: ILoginParameters): Dictionary<string> => {
	return {
		[SiteSettingsKeys.AllowedDomains]: !isEmpty(loginData.allowedDomains)
			? loginData.allowedDomains.map((item) => item.replace("@", "")).join(";")
			: "",
		[SiteSettingsKeys.DefaultRole]: loginData.role ? String(loginData.role?.value) : String(AccountRoles.FreeLearner),
		[SiteSettingsKeys.DefaultCompany]:
			loginData.role?.value === AccountRoles.CompanyLearner ? String(loginData.company?.value) : "",
		[SiteSettingsKeys.FacebookAuthEnabled]: String(loginData.facebookSettings?.isActive ?? ""),
		[SiteSettingsKeys.LinkedInAuthEnabled]: String(loginData.linkedInSettings?.isActive ?? ""),
		[SiteSettingsKeys.GoogleAuthEnabled]: String(loginData.googleSettings?.isActive ?? ""),
		[SiteSettingsKeys.GoogleAuthAppId]: String(loginData.googleSettings?.appId ?? ""),
		[SiteSettingsKeys.LinkedInAuthAppId]: String(loginData.linkedInSettings?.appId ?? ""),
		[SiteSettingsKeys.FacebookAuthAppId]: String(loginData.facebookSettings?.appId ?? ""),
		[SiteSettingsKeys.Enabled2FA]: String(loginData.is2FAEnabled),
	};
};

export const convertToExternalApiSettingsRequest = (enabledApiKeyAccess: boolean): Dictionary<string> => {
	return {
		[SiteSettingsKeys.EnabledApiKeyAccess]: String(enabledApiKeyAccess),
	};
};

export const convertToDigitalUniverseSettingsRequest = (digitalUniverseClientId: string, digitalUniverseUrl: string): Dictionary<string> => {
	return {
		[SiteSettingsKeys.DigitalUniverseClientId]: String(digitalUniverseClientId),
		[SiteSettingsKeys.DigitalUniverseBaseUrl]: String(digitalUniverseUrl),
	};
};
