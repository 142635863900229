import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import ReactPlayer from "react-player/lazy";
import { IContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentModel";
import VideoPlaceholder from "assets/images/video_placeholder.png";
import AudioPlaceholder from "assets/images/audio_placeholder.png";
import { ContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentTypes";
import { ControlParams } from "../player-control/player-control";
import { useAppStore } from "store";
import { isSafariBrowser, mobileCheck, tabletCheck } from "helpers/device.helper";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { isNil } from "lodash";
import { observer } from "mobx-react";

interface PlayerVideoProps {
	params: ControlParams;
	setParams: Dispatch<SetStateAction<ControlParams>>;
	content: IContentModel;
	className?: string;
	children?: ReactNode;
}

interface stateProgress {
	played: number;
	playedSeconds: number;
	loaded: number;
	loadedSeconds: number;
}

const cx = classNames.bind(styles);

const PlayerVideo = (props: PlayerVideoProps) => {
	const { params, className, setParams, children, content } = props;
	const {
		playing,
		muted,
		volume,
		playerRef,
		boxRef,
		handlePlay,
		handlePause,
		handleStart,
		handleSeekChange,
		speed,
		handleComplete,
	} = params;

	const { playerStore, contentStore, toasterStore } = useAppStore();

	/* Player ratio: 100 / (1280 / 720) as per https://github.com/cookpete/react-player recommendations */
	const paddingTop = 56.25;
	const [isContentReady, setContentReady] = useState(false);
	const isSessionInitialized = Boolean(contentStore.sessionId);
	const disableAutoPlay =
		useMemo(() => isSafariBrowser() && (mobileCheck() || tabletCheck), []) || !isSessionInitialized;

	const zoomParams = useMemo(() => {
		if (playerStore.zoomMultiplier > 1) {
			return {
				width: `${100 * playerStore.zoomMultiplier}%`,
				height: `${100 * playerStore.zoomMultiplier}%`,
				top: `${(1 - playerStore.zoomMultiplier) * 70}%`,
				left: `${(1 - playerStore.zoomMultiplier) * 70}%`,
				paddingTop: `${paddingTop * playerStore.zoomMultiplier}%`,
			};
		}
		return {
			width: "100%",
			height: "100%",
			top: `${(1 - playerStore.zoomMultiplier) * 100}%`,
			left: "0",
			paddingTop: `${paddingTop * playerStore.zoomMultiplier}%`,
		};
	}, [playerStore.zoomMultiplier]);

	const handleDuration = (duration: number) =>
		setParams((prevState: ControlParams) => ({ ...prevState, totalProgress: duration }));

	const handleProgress = (state: stateProgress) => {
		const currentProgress = state.playedSeconds > params.totalProgress ? params.totalProgress : state.playedSeconds;
		setParams((prevState: ControlParams) => ({ ...prevState, currentProgress }));
	};
	const getPoster = () => {
		if (content.contentType === ContentTypes.Video) return VideoPlaceholder;
		else if (content.contentType === ContentTypes.Podcast)
			return !isNil(content.thumbnail) ? content.thumbnail.url : AudioPlaceholder;
	};

	const handleError = (error: any) => {
		if (error.name !== "AbortError") {
			toasterStore.showErrorMessage(`Playback error: ${error.name} ${error.message} ${content.fileContent.url}`);
		}
	};

	const getPlayOnClick = () => (playing ? handlePause?.() : handlePlay?.());

	useEffect(() => {
		playerStore.zoomMultiplier = 1;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={cx(styles.player_video, className)} ref={boxRef}>
			<div className={styles.player_video__pause_click_zone} onClick={getPlayOnClick} />
			<LoadingIndicator loading={!isContentReady} backgroundStyle={"none"} spinnerPosition="center">
				<div className={styles.player_video__wrap} style={{ paddingTop: zoomParams.paddingTop }}>
					{isSessionInitialized && (
						<ReactPlayer
							autoPlay={!disableAutoPlay}
							className={styles.player_video__inner}
							style={{ top: zoomParams.top, left: zoomParams.left }}
							url={content.fileContent.url}
							width={zoomParams.width}
							height={zoomParams.height}
							playing={Boolean(playing)}
							muted={muted}
							onReady={() => {
								setContentReady(true);
								setParams((prevState: ControlParams) => ({ ...prevState, isReady: true }));
							}}
							onDuration={handleDuration}
							onProgress={handleProgress}
							onSeek={disableAutoPlay ? (value) => handleSeekChange?.(value) : () => null}
							onPlay={disableAutoPlay ? handlePlay : () => null}
							onPause={disableAutoPlay ? handlePause : () => null}
							volume={Number(volume)}
							onStart={handleStart}
							onEnded={handleComplete}
							onError={handleError}
							ref={playerRef}
							loop={false}
							config={{
								file: {
									attributes: {
										poster: getPoster(),
										controlsList: "nodownload",
										disablePictureInPicture: true,
										preload: "meatadata",
									},
								},
							}}
							playbackRate={Number(speed)}
						/>
					)}
				</div>
			</LoadingIndicator>
			{children}
		</div>
	);
};

export default observer(PlayerVideo);
