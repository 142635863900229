//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class PermissionConstantsCloning
{
	public static OwnCoursesInsideTenant: string = `cloning.own_courses_inside_tenant`;
	public static AllCoursesInsideTenant: string = `cloning.all_courses_inside_tenant`;
	public static CoursesBetweenTenant: string = `cloning.courses_between_tenant`;
	public static UsersBetweenTenants: string = `cloning.users_between_tenants`;
}
