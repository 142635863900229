import Api from "api/utils/api";
import { IAnalyticItemCountResponseModel } from "models/dto/ZoomiLxp/Models/Analytics/IAnalyticItemCountResponseModel";
import { ICourseMotifStatisticsParams } from "models/dto/ZoomiLxp/Models/Analytics/ICourseMotifStatisticsParams";
import { IEngagementRequestModel } from "models/dto/ZoomiLxp/Models/Analytics/IEngagementRequestModel";
import { IEngagementResponseModel } from "models/dto/ZoomiLxp/Models/Analytics/IEngagementResponseModel";
import { IInteractionsInfluencerResponseModel } from "models/dto/ZoomiLxp/Models/Analytics/IInteractionsInfluencerResponseModel";
import { ILearningPathItem } from "models/dto/ZoomiLxp/Models/Analytics/ILearningPathItem";
import { IMotifSessionItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifSessionItem";
import { IMotifStatisticsItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifStatisticsItem";
import { IMotifStatisticsParams } from "models/dto/ZoomiLxp/Models/Analytics/IMotifStatisticsParams";
import { IMotifsMeterRequestModel } from "models/dto/ZoomiLxp/Models/Analytics/IMotifsMeterRequestModel";
import { IMotifsMeterResponseModel } from "models/dto/ZoomiLxp/Models/Analytics/IMotifsMeterResponseModel";
import { ITransitionSourceModel } from "models/dto/ZoomiLxp/Models/Analytics/ITransitionSourceModel";
import { IZ1RequestModel } from "models/dto/ZoomiLxp/Models/Analytics/IZ1RequestModel";
import { IZ1ResponseModel } from "models/dto/ZoomiLxp/Models/Analytics/IZ1ResponseModel";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { ISubjectsModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ISubjectsModel";

export class AnalyticsApi {
	//--Methods for current user--.
	static getLikesCount() {
		return Api.get<IAnalyticItemCountResponseModel>("/analytics/getLikesCount");
	}
	static getCommentsCount() {
		return Api.get<IAnalyticItemCountResponseModel>("/analytics/getCommentsCount");
	}
	static getPodcastsCount() {
		return Api.get<IAnalyticItemCountResponseModel>("/analytics/getPodcastsCount");
	}
	static getTextsCount() {
		return Api.get<IAnalyticItemCountResponseModel>("/analytics/getTextsCount");
	}
	static getVideosCount() {
		return Api.get<IAnalyticItemCountResponseModel>("/analytics/getVideosCount");
	}
	static getTotalWatchedTime() {
		return Api.get<IAnalyticItemCountResponseModel>("/analytics/getTotalTimeWatched");
	}
	static getTotalListenedTime() {
		return Api.get<IAnalyticItemCountResponseModel>("/analytics/getTotalTimeListened");
	}
	static getTotalReadedTime() {
		return Api.get<IAnalyticItemCountResponseModel>("/analytics/getTotalTimeReaded");
	}
	static getTotalScormTime() {
		return Api.get<IAnalyticItemCountResponseModel>("/analytics/getTotalTimeScorm");
	}

	static getAnalyticsSubjects() {
		return Api.get<ISubjectsModel>("/analytics/analyticSubjects");
	}

	static getMotifsMeterData(data: IMotifsMeterRequestModel) {
		return Api.post<IMotifsMeterResponseModel>("/analytics/motifsMeter", data);
	}

	static getMotifsStatisticsData(data: IMotifStatisticsParams) {
		return Api.post<IGetRecordsResponse<IMotifStatisticsItem>>("/analytics/motifStatistics", data);
	}

	static getCourseMotifsData(data: ICourseMotifStatisticsParams) {
		return Api.post<IGetRecordsResponse<IMotifSessionItem>>("/analytics/courseMotifs", data);
	}

	static getZ1Score(data: IZ1RequestModel) {
		return Api.post<IZ1ResponseModel>("/analytics/getZ1Rating", data);
	}

	static getEngagementScore(data: IEngagementRequestModel) {
		return Api.post<IEngagementResponseModel>("/analytics/getEngagement", data);
	}

	static getInteractions() {
		return Api.get<IInteractionsInfluencerResponseModel[]>("analytics/GetInteractionsWithInfluencer");
	}

	static getMyLearningPathData(data: IPagingParams) {
		return Api.post<IGetRecordsResponse<ILearningPathItem>>("/analytics/myLearningPath", data);
	}

	static getTransitionSources() {
		return Api.get<ITransitionSourceModel[]>("/analytics/transitionSources");
	}

	//--Methods by UserID--.
	static getUserLikesCount(userId: number) {
		return Api.get<IAnalyticItemCountResponseModel>(`/analytics/getUserLikesCount/${userId}`);
	}
	static getUserCommentsCount(userId: number) {
		return Api.get<IAnalyticItemCountResponseModel>(`/analytics/getUserCommentsCount/${userId}`);
	}
	static getUserPodcastsCount(userId: number) {
		return Api.get<IAnalyticItemCountResponseModel>(`/analytics/getUserPodcastsCount/${userId}`);
	}
	static getUserTextsCount(userId: number) {
		return Api.get<IAnalyticItemCountResponseModel>(`/analytics/getUserTextsCount/${userId}`);
	}
	static getUserVideosCount(userId: number) {
		return Api.get<IAnalyticItemCountResponseModel>(`/analytics/getUserVideosCount/${userId}`);
	}
	static getUserTotalWatchedTime(userId: number) {
		return Api.get<IAnalyticItemCountResponseModel>(`/analytics/getUserTotalTimeWatched/${userId}`);
	}
	static getUserTotalListenedTime(userId: number) {
		return Api.get<IAnalyticItemCountResponseModel>(`/analytics/getUserTotalTimeListened/${userId}`);
	}
	static getUserTotalReadedTime(userId: number) {
		return Api.get<IAnalyticItemCountResponseModel>(`/analytics/getUserTotalTimeReaded/${userId}`);
	}
	static getUserTotalScormTime(userId: number) {
		return Api.get<IAnalyticItemCountResponseModel>(`/analytics/getUserTotalTimeScorm/${userId}`);
	}
	static getUserZ1Score(userId: number, data: IZ1RequestModel) {
		return Api.post<IZ1ResponseModel>(`/analytics/getUserZ1Rating/${userId}`, data);
	}
	static getUserEngagementScore(userId: number, data: IEngagementRequestModel) {
		return Api.post<IEngagementResponseModel>(`/analytics/getUserEngagement/${userId}`, data);
	}
	static getUserMotifsMeterData(userId: number, data: IMotifsMeterRequestModel) {
		return Api.post<IMotifsMeterResponseModel>(`/analytics/userMotifsMeter/${userId}`, data);
	}
	static getUserMotifsStatisticsData(userId: number, data: IMotifStatisticsParams) {
		return Api.post<IGetRecordsResponse<IMotifStatisticsItem>>(`/analytics/userMotifStatistics/${userId}`, data);
	}
	static getUserCourseMotifsData(userId: number, data: ICourseMotifStatisticsParams) {
		return Api.post<IGetRecordsResponse<IMotifSessionItem>>(`/analytics/userCourseMotifStatistics/${userId}`, data);
	}
	static getUserInteractions(userId: number) {
		return Api.get<IInteractionsInfluencerResponseModel[]>(`analytics/GetUserInteractionsWithInfluencer/${userId}`);
	}
	static getUserLearningPathData(data: IPagingParams, userId: number) {
		return Api.post<IGetRecordsResponse<ILearningPathItem>>(`/analytics/userLearningPath/${userId}`, data);
	}
}
