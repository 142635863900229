//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class PermissionConstantsCreateOrUpdate
{
	public static CoursesWithAnyCreator: string = `create_or_update.courses_with_any_creator`;
	public static AllAccounts: string = `create_or_update.all_accounts`;
	public static Account: string = `create_or_update.account`;
	public static RequestToAssignCourse: string = `create_or_update.request_to_assign_course`;
}
