import { RefObject, useCallback, useEffect, useRef } from "react";
import throttle from "lodash/throttle";

export const useScroll = (ref: RefObject<HTMLDivElement>, sendScrollEvent: (posY: number) => Promise<void>) => {
	const calledOnce = useRef(false);
	const lastPosition = useRef(0);

	const updatePosition = useCallback(
		(event: Event) => {
			//@ts-ignore
			const posY = Math.round(event?.target?.scrollTop);

			if (posY !== lastPosition.current && !calledOnce.current) {
				calledOnce.current = true;

				sendScrollEvent(posY).then(() => {
					lastPosition.current = posY;
					calledOnce.current = false;
				});
			}
		},
		[sendScrollEvent]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const throttlePosition = useCallback(throttle(updatePosition, 1000), [updatePosition]);

	useEffect(() => {
		ref.current?.addEventListener("scroll", throttlePosition);

		return () => {
			window.removeEventListener("scroll", throttlePosition);
		};
	}, [ref, throttlePosition]);
};
