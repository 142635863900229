import Api from "../utils/api";
import { ISystemEmailSettingsModel } from "models/dto/ZoomiLxp/Models/Notifications/ISystemEmailSettingsModel";
import { EmailSourceMessage } from "models/dto/ZoomiLxp/Utilities/Enumerations/EmailSourceMessage";
import { ISystemNotificationPreviewModel } from "models/dto/ZoomiLxp/Models/Notifications/ISystemNotificationPreviewModel";
import { IIsActiveNotificationRequest } from "models/dto/ZoomiLxp/Models/Notifications/IIsActiveNotificationRequest";
import { ICreateScheduleEmailSetingsModel } from "models/dto/ZoomiLxp/Models/Notifications/ICreateScheduleEmailSetingsModel";
import { IExistingScheduleSettingsModel } from "models/dto/ZoomiLxp/Models/Notifications/IExistingScheduleSettingsModel";
import { IEmailSchedulePreviewModel } from "models/dto/ZoomiLxp/Models/Notifications/IEmailSchedulePreviewModel";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IHistoryCustomNotificationModel } from "models/dto/ZoomiLxp/Models/Notifications/IHistoryCustomNotificationModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { IDummyResponseModel } from "models/dto/ZoomiLxp/Models/Common/IDummyResponseModel";
export class NotificationApi {
	static getSystemEmailSettings(code: EmailSourceMessage) {
		return Api.get<ISystemEmailSettingsModel>(`/Notification/GetSystemEmailSettings/${code}`);
	}

	static getNotificationStates() {
		return Api.get<ISystemNotificationPreviewModel[]>("/Notification/GetNotificationStates");
	}

	static saveSystemEmailSettings(data: ISystemEmailSettingsModel) {
		return Api.post<ISystemEmailSettingsModel>("/Notification/SaveSystemEmailSettings", data);
	}

	static setIsActiveNotificationMessage(type: EmailSourceMessage, request: IIsActiveNotificationRequest) {
		return Api.put<IDummyResponseModel>(`/Notification/SetIsActiveNotificationMessage/${type}`, request);
	}
	static createOrSendCustomNotification(data: ICreateScheduleEmailSetingsModel) {
		return Api.post<IDummyResponseModel>("/Notification/CreateOrSendCustomNotification", data);
	}

	static updateCustomNotification(data: IExistingScheduleSettingsModel) {
		return Api.post<IExistingScheduleSettingsModel>("/Notification/UpdateCustomNotification", data);
	}

	static setIsActiveCustomNotificationMessage(scheduleId: number, request: IIsActiveNotificationRequest) {
		return Api.put<IDummyResponseModel>(`/Notification/SetIsActiveCustomNotificationMessage/${scheduleId}`, request);
	}

	static getAllSchedulePreview() {
		return Api.get<IEmailSchedulePreviewModel[]>("/Notification/GetAllSchedulePreview");
	}

	static getHistoryCustomNotification(queryParams: IQueryParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IHistoryCustomNotificationModel>>(
			"/Notification/GetHistoryCustomNotification",
			queryParams
		);
	}

	static getScheduleEmailSettings(scheduleId: number) {
		return Api.get<IExistingScheduleSettingsModel>(`/Notification/GetScheduleEmailSettings/${scheduleId}`);
	}

	static deleteScheduleEmailSettings(scheduleId: number) {
		return Api.delete<IDummyResponseModel>(`/Notification/DeleteScheduleEmailSettings/${scheduleId}`);
	}
}
