import { FC, useMemo, useRef, useState } from "react";
import styles from "../styles.module.scss";
import classNames from "classnames";
import Select, {
	components,
	GroupBase,
	MultiValue,
	MultiValueProps,
	OptionProps,
	StylesConfig,
} from "react-select";
import update from "immutability-helper";
import { IOptionsItem, stylesSelect } from "helpers/select.helper";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import { IFilterCriterion } from "models/dto/ZoomiLxp/Models/Query/IFilterCriterion";
import { debounce, findIndex, uniqWith } from "lodash";
import { useDetectOutsideClick } from "hooks/useDetectOutsideClick";
export interface MultiSelectParamsObject {
	params: IQueryParams;
	setParams: (value: ((prevState: IQueryParams) => IQueryParams) | IQueryParams) => void;
	propertyName: string;
	isLoading?: boolean;
}
export interface Props {
	params: IQueryParams;
	propertyName: string;
	filterFunction?: FilterFunction;
	setParams: (value: ((prevState: IQueryParams) => IQueryParams) | IQueryParams) => void;
	label: string;
	className?: string;
	options: IOptionsItem[] | { value: string; label: string }[];
	isStringOptions?: boolean;
	customStyles?: StylesConfig<
		IOptionsItem | { value: string; label: string },
		boolean,
		GroupBase<IOptionsItem | { value: string; label: string }>
	>;
	containerClassName?: string;
	isClear?: boolean;
	isSearchable?: boolean;
	isLoading?: boolean;
	querySearchParams?: MultiSelectParamsObject;
}

const MultiSelectFilter = (props: Props) => {
	const {
		label,
		className,
		params,
		setParams,
		propertyName,
		filterFunction = FilterFunction.In,
		options,
		isStringOptions,
		customStyles,
		containerClassName,
		isClear,
		isSearchable = true,
		querySearchParams,
		isLoading,
	} = props;

	const submenuRef = useRef<HTMLDivElement>(null);
	const { isActive: isOpen, setIsActive: setIsOpen } = useDetectOutsideClick(submenuRef, false);

	const [selectedValue, setSelectedValue] = useState(null as any);
	const calledOnce = useRef(false);

	if (isClear && !calledOnce.current) {
		setTimeout(() => {
			setSelectedValue([]);
			setIsOpen(false);
			calledOnce.current = true;
		}, 0);
	}

	if (!isClear) {
		calledOnce.current = false;
	}

	const onSelect = (items: MultiValue<IOptionsItem | { value: string; label: string }>) => {
		if (params.filterCriteria.length <= 0) {
			const newParams = update<IQueryParams>(params, {
				filterCriteria: {
					$push: uniqWith(
						items
							.map((item) => {
								return {
									propertyNames: [propertyName],
									argument: isStringOptions ? item?.label : item?.value,
									function: filterFunction,
								} as IFilterCriterion;
							})
							.concat(params.filterCriteria),
						(item1, item2) =>
							item1.argument === item2.argument && item1.propertyNames.toString() === item2.propertyNames.toString()
					),
				},
				skip: { $set: 0 },
			});
			setParams(newParams);
			return;
		}
		items?.forEach((item) => {
			const idx = findIndex(params.filterCriteria, { propertyNames: [propertyName], argument: item.value });
			const filterItem = params.filterCriteria[idx];

			if (!(idx >= 0 && !!filterItem.argument && filterItem.argument === item?.value)) {
				const newParams = update<IQueryParams>(params, {
					filterCriteria: {
						$set: uniqWith(
							items
								.map((item) => {
									return {
										propertyNames: [propertyName],
										argument: isStringOptions ? item?.label : item?.value,
										function: filterFunction,
									} as IFilterCriterion;
								})
								.concat(params.filterCriteria),
							(item1, item2) =>
								item1.argument === item2.argument && item1.propertyNames.toString() === item2.propertyNames.toString()
						),
					},
					skip: { $set: 0 },
				});
				setParams(newParams);
			}
		});
		params.filterCriteria.forEach((filterCriterion, i) => {
			if (filterCriterion.propertyNames.toString() === propertyName) {
				const idx = findIndex(items, { value: filterCriterion.argument });

				if (idx < 0) {
					const newParams = update(params, {
						filterCriteria: {
							$splice: [[i, 1]],
						},
						skip: {
							$set: 0,
						},
					});
					setParams(newParams);
				}
			}
		});
	};

	const CustomOption: FC<OptionProps<IOptionsItem | { value: string; label: string }>> = (
		optionProps: OptionProps<IOptionsItem | { value: string; label: string }>
	) => {
		const { isSelected, data } = optionProps;
		return (
			<components.Option {...optionProps}>
				{isSelected ? (
					<>
						<span>✔ </span>
						<span>{data.label}</span>
					</>
				) : (
					<>
						<span>    </span>
						<span>{data.label}</span>
					</>
				)}
			</components.Option>
		);
	};

	const MultiValue: FC<MultiValueProps<IOptionsItem | { value: string; label: string }>> = (
		multiValueProps: MultiValueProps<IOptionsItem | { value: string; label: string }>
	) => {
		const { index } = multiValueProps;
		const value = !!params.filterCriteria?.find((filter: IFilterCriterion) =>
			filter.propertyNames.includes(propertyName)
		)
			? isOpen
				? ""
				: selectedValue?.length > 0
				? `${label}(${selectedValue?.length})`
				: label
			: label;
		return !index ? <div style={{position: "absolute"}}>{value}</div> : <></>;
	};

	// eslint-disable-next-line
	const onInput = (val: string) => {
		const lengthInput = val.length;
		const searchText = val.toLowerCase();
		const idx = findIndex(querySearchParams?.params.filterCriteria, {
			propertyNames: [querySearchParams?.propertyName],
		});
		if (querySearchParams && lengthInput > 0) {
			if (idx < 0) {
				const newParams = update<IQueryParams>(querySearchParams?.params, {
					filterCriteria: {
						$push: [
							{
								propertyNames: [querySearchParams?.propertyName],
								argument: searchText,
								function: FilterFunction.Like,
							} as IFilterCriterion,
						],
					},
					skip: { $set: 0 },
				});
				querySearchParams.setParams(newParams);
			} else {
				const filterItem = querySearchParams?.params.filterCriteria[idx];

				if (filterItem.argument !== searchText) {
					const updatedFilterItem = update(filterItem, { argument: { $set: searchText } });
					const newParams = update(querySearchParams?.params, {
						filterCriteria: {
							$splice: [[idx, 1, updatedFilterItem]],
						},
					});
					querySearchParams?.setParams(newParams);
				}
			}
		} else if (querySearchParams && lengthInput === 0 && idx > 0) {
			if (querySearchParams?.params?.filterCriteria?.length > 0) {
				const newParams = update<IQueryParams>(querySearchParams?.params, {
					filterCriteria: {
						$splice: [[idx, 1]],
					},
				});
				querySearchParams?.setParams(newParams);
			}
		}
	};

	const onDebouncedInput = useMemo(() => debounce(onInput, 350), [onInput]);

	return (
		<div
			ref={submenuRef}
			className={classNames(styles.filter_button, containerClassName)}
			onClick={() => setIsOpen(true)}
		>
			<Select
				options={options}
				className={classNames(styles.filter_button, className)}
				placeholder={isOpen ? "" : label}
				styles={customStyles ?? stylesSelect}
				isSearchable={isSearchable}
				onChange={(item) => {
					setSelectedValue(item);
					onSelect(item);
				}}
				isMulti
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				onMenuOpen={() => setIsOpen(true)}
				onMenuClose={() => setIsOpen(false)}
				menuIsOpen={isOpen}
				onInputChange={(value) => onDebouncedInput(value)}
				components={{
					Option: CustomOption,
					MultiValue,
				}}
				blurInputOnSelect
				isClearable={isClear}
				value={selectedValue}
				isLoading={isLoading || querySearchParams?.isLoading}
			/>
		</div>
	);
};

export default MultiSelectFilter;
