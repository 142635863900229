import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { useAsync } from "react-async-hook";
import { DEFAULT_PAGE_SIZE } from "constants/constants";
import { ToasterStore } from "store/toaster-store/toaster-store";

export function useAsyncDataTable<T, R>(
	setData: (value: ((prevState: T[]) => T[]) | T[]) => void,
	params: IQueryParams,
	setPageCount: (value: ((prevState: number) => number) | number) => void,
	toaster: ToasterStore | undefined,
	fetchData: (setting: IQueryParams) => Promise<R>,
	getResult: (result: R) => IGetRecordsResponse<T>
) {
	const fetchSuccessData = (result: R) => {
		const { records, totalFilteredRecords } = getResult(result);
		setData(records);
		if (params.filterCriteria.length > 0 || records.length) {
			setPageCount(Math.ceil(totalFilteredRecords / (params?.take ? params.take : DEFAULT_PAGE_SIZE)));
		} else {
			setPageCount(0);
		}
	};

	const fetchErrorData = (error: Error) => toaster?.showErrorMessage(error);

	const { loading } = useAsync(fetchData, [params], {
		onSuccess: fetchSuccessData,
		onError: fetchErrorData,
		executeOnMount: false,
	});

	return { loading };
}
