import { FormikHelpers } from "formik";
import { ICloneCourseOptions } from "models/dto/ZoomiLxp/Models/BulkOperations/ICloneCourseOptions";
import styles from "../styles.module.scss";
import { useAppStore } from "store";
import Checkbox from "components/base/checkbox/checkbox";
import AccessWrapper from "components/partial/access-wrapper/access-wrapper";
import { PermissionConstantsCloning } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsCloning";

interface ICourseCloningFormProps {
	values: ICloneCourseOptions;
	formHelpers: FormikHelpers<ICloneCourseOptions>;
}

const CoursesCloningForm = ({ values, formHelpers }: ICourseCloningFormProps) => {
	const { bulkOperationsStore } = useAppStore();
	const { setFieldValue } = formHelpers;

	return (
		<>
			<AccessWrapper permissions={[PermissionConstantsCloning.CoursesBetweenTenant]}>
				<Checkbox
					label="Creator profile"
					value={values.copyCreatorProfile}
					onChange={(value) => {
						bulkOperationsStore.coursesCloningOptions = {
							...bulkOperationsStore.coursesCloningOptions,
							copyCreatorProfile: value,
						};
						setFieldValue("copyCreatorProfile", value);
					}}
					className={styles.bulk_cloning_popup__checkbox}
				/>
			</AccessWrapper>
			<Checkbox
				label="Connected threads branch"
				value={values.copyConnectedThreads}
				onChange={(value) => {
					bulkOperationsStore.coursesCloningOptions = {
						...bulkOperationsStore.coursesCloningOptions,
						copyConnectedThreads: value,
					};
					setFieldValue("copyConnectedThreads", value);
				}}
				className={styles.bulk_cloning_popup__checkbox}
			/>

			<Checkbox
				label="Price"
				value={values.copyPrice}
				onChange={(value) => {
					bulkOperationsStore.coursesCloningOptions = {
						...bulkOperationsStore.coursesCloningOptions,
						copyPrice: value,
					};
					setFieldValue("copyPrice", value);
				}}
				className={styles.bulk_cloning_popup__checkbox}
			/>
		</>
	);
};

export default CoursesCloningForm;
