import React from "react";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { ConfirmationModal } from "components/partial/confirmation-popup/confirmation-popup";
import { promptsCancel } from "constants/messages";
import { showErrorMessage } from "helpers/error.handling.helper";
import HeaderDashboardRow from "../header-dashboard-row/header-dashboard-row";

interface HeaderDashboardProps {
	className?: string;
}

const HeaderDashboard = (props: HeaderDashboardProps) => {
	const { reportsStore } = useAppStore();

	const handleAdd = () => reportsStore.addWidget();

	const changeShowMode = (show: boolean) => {
		reportsStore.isShowButton = show;
		reportsStore.staticWidgets(!show);
	};

	const handleEdit = () => changeShowMode(true);

	const handleCancel = async () => {
		await modalDialogService.show(ConfirmationModal, {
			title: promptsCancel.title,
			popupText: promptsCancel.text,
			confirmationAction: () => {
				changeShowMode(false);
				reportsStore.cancelChanges();
			},
			confirmationButtonLabel: promptsCancel.confirmationButton,
			cancelButtonLabel: promptsCancel.cancelButton,
		});
	};

	const handleSave = async () => {
		try {
			reportsStore.checkErrors();
			changeShowMode(false);
			await reportsStore.saveWidgets();
		} catch (err: unknown) {
			showErrorMessage(reportsStore.alertEventBus, err);
		}
	};

	return (
		<HeaderDashboardRow
			onEdit={handleEdit}
			onCancel={handleCancel}
			onAdd={handleAdd}
			onSave={handleSave}
			title="Dashboard"
		/>
	);
};

export default observer(HeaderDashboard);
