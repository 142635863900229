import styles from "./styles.module.scss";
import SimpleLayout from "layouts/simple/simple";

const InvalidTenantPage = (props: { message?: string }) => {
	const { message } = props;
	return (
		<SimpleLayout cleanPage>
			<div className={styles.invalid_tenant}>
				<div className={styles.invalid_tenant__message}>{message ?? "Invalid URL."}</div>
			</div>
		</SimpleLayout>
	);
};

export default InvalidTenantPage;
