import classNames from "classnames/bind";
import styles from "./styles.module.scss";
import { Routes } from "routes";
import { generatePath, useHistory } from "react-router-dom";
import { useAppStore } from "store";
import Avatar from "components/base/avatar/avatar";
import { getAvatarTitle, getUserFullName } from "helpers/string.helper";
import { observer } from "mobx-react";
import { getThumbnail } from "helpers/image.helper";

interface Props {
	className?: string;
}

const UserSubmenu = (props: Props) => {
	const { className } = props;

	const { usersStore } = useAppStore();
	const currentUser = usersStore.currentUserInfo;

	const history = useHistory();

	const cx = classNames.bind(styles);

	return (
		<div className={cx(styles.user_info, className)} onClick={() => history.push(generatePath(Routes.Profile))}>
			<Avatar
				title={getAvatarTitle(currentUser!.firstName, currentUser!.lastName)}
				className={styles.user_info__avatar}
				image={getThumbnail(currentUser!.userPicture, true)}
			/>
			<div className={styles.user_info__details}>
				<div className={styles.user_info__details__subtitle}>
					{getUserFullName(currentUser?.firstName ?? "", currentUser?.lastName ?? "")}
				</div>
				<div className={styles.user_info__details__subtitle}>{currentUser?.jobTitle ?? ""}</div>
			</div>
		</div>
	);
};

export default observer(UserSubmenu);
