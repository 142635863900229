import styles from "./styles.module.scss";
import { useState } from "react";
import classNames from "classnames";
import { ColumnGroup } from "react-table";
import isEmpty from "lodash/isEmpty";
import { ReactComponent as IconAudioType } from "assets/icons/ic_type_audio.svg";
import { ReactComponent as IconVideoType } from "assets/icons/ic_type_video.svg";
import { ReactComponent as IconDocumentType } from "assets/icons/ic_type_document.svg";
import { ReactComponent as IconQuiz } from "assets/icons/ic_type_quiz.svg";
import { ReactComponent as IconZip } from "assets/icons/ic_type_zip.svg";
import { ReactComponent as ScormTypeIcon } from "assets/icons/ic_type_scorm.svg";
import { ReactComponent as HtmlTypeIcon } from "assets/icons/ic_type_html.svg";
import { ReactComponent as PlayIcon } from "assets/icons/ic_player_play.svg";
import { ReactComponent as SegmentIcon } from "assets/icons/ic_segment.svg";
import { ReactComponent as ThreadIcon } from "assets/icons/ic_type_thread.svg";
import { ReactComponent as StreamIcon } from "assets/icons/ic_type_stream.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/icons/ic_type_external_link.svg";
import { ContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentTypes";
import Tooltip from "components/base/tooltip/tooltip";
import { DateTime } from "luxon";
import { ICourseItemModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseItemModel";
import { ContentItemTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentItemTypes";
import { ExternalContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ExternalContentTypes";
import { generatePath, NavLink, useHistory } from "react-router-dom";
import { Routes } from "routes";
import { Duration } from "luxon";
import { isReferenceContent } from "helpers/content.helper";
import TogglerActivation from "components/partial/toggler-activation/toggler-activation";
import { getThreadTitle } from "helpers/string.helper";
import { ReactComponent as ArchiveIcon } from "assets/icons/ic_archive_in.svg";
import { ReactComponent as UnarchiveIcon } from "assets/icons/ic_archive_out.svg";
import CmsContentStatus from "../cms-content-status/cms-content-status";

export const getContentTypeIcon = (type?: ContentTypes): JSX.Element => {
	if (type === ContentTypes.Text) {
		return <IconDocumentType className={styles.cms_content__cell_icon} />;
	} else if (type === ContentTypes.Video) {
		return <IconVideoType className={styles.cms_content__cell_icon} />;
	} else if (type === ContentTypes.Podcast) {
		return <IconAudioType className={styles.cms_content__cell_icon} />;
	} else if (type === ContentTypes.Scorm) {
		return <ScormTypeIcon className={styles.cms_content__cell_icon} />;
	} else if (type === ContentTypes.Html || type === ContentTypes.Mhtml) {
		return <HtmlTypeIcon className={styles.cms_content__cell_icon} />;
	} else if (type === ContentTypes.Zip) {
		return <IconZip className={styles.cms_content__cell_icon} />;
	}
	return <></>;
};

export enum SequenceDirection {
	Up,
	Down,
}

export enum ContentGridColumnsHeaders {
	Name = "Name",
	Creator = "Creator",
	LastUpdated = "LastUpdated",
	Status = "Processing status",
	Empty = "",
}

export const getCourseItemIcon = (item: ICourseItemModel, disableTooltip?: boolean, useColoredRefIcon?: boolean) => {
	if (item.contentItemType === ContentItemTypes.Content && item.content) {
		return (
			<div
				className={classNames(styles.cms_content__cell_icon_wrap, {
					[styles.cms_content__ref_content_icon]: isReferenceContent(item) && !useColoredRefIcon,
				})}
			>
				<Tooltip
					label={ContentTypes[item.content.contentType]}
					className={styles.cms_content__tooltip}
					isActive={!disableTooltip}
				>
					{getContentTypeIcon(item.content.contentType)}
				</Tooltip>
			</div>
		);
	} else if (item.contentItemType === ContentItemTypes.ContentSegment) {
		return (
			<Tooltip label={"Segment"} className={styles.cms_content__tooltip} isActive={!disableTooltip}>
				<div className={styles.cms_content__cell_icon_wrap}>
					<SegmentIcon className={classNames(styles.cms_content__cell_icon, styles.cms_content__icon_segment)} />
				</div>
			</Tooltip>
		);
	} else if (item.contentItemType === ContentItemTypes.Assessment) {
		return (
			<Tooltip label={"Assessment"} className={styles.cms_content__tooltip} isActive={!disableTooltip}>
				<div className={styles.cms_content__cell_icon_wrap}>
					<IconQuiz className={styles.cms_content__cell_icon} />
				</div>
			</Tooltip>
		);
	} else if (item.contentItemType === ContentItemTypes.Thread) {
		return (
			<Tooltip label={"Thread"} className={styles.cms_content__tooltip} isActive={!disableTooltip}>
				<div className={styles.cms_content__cell_icon_wrap}>
					<ThreadIcon className={styles.cms_content__cell_icon} />
				</div>
			</Tooltip>
		);
	} else if (item.contentItemType === ContentItemTypes.ExternalContent) {
		return (
			<Tooltip label={"External Content"} className={styles.cms_content__tooltip} isActive={!disableTooltip}>
				<div className={styles.cms_content__cell_icon_wrap}>
					{item?.externalContent?.type === ExternalContentTypes.StreamMeeting && <StreamIcon className={styles.cms_content__cell_icon} />}
					{item?.externalContent?.type === ExternalContentTypes.ExternalLink && <ExternalLinkIcon className={styles.cms_content__cell_icon} />}
				</div>
			</Tooltip>
		);
	}
	return <></>;
};

const getFormatedTime = (seconds: number) => {
	const duration = Duration.fromObject({ seconds });
	const currentTime = duration.shiftTo("hours", "minutes").toObject();
	const hours = currentTime.hours;
	const minutes = currentTime.minutes;
	return `${hours && hours > 0 ? hours.toFixed(0) + " h " : ""}${minutes?.toFixed(0)} min`;
};

export const getCourseItemDuration = (item: ICourseItemModel) => {
	let durationSeconds: number | null | undefined = null;
	if (item.contentItemType === ContentItemTypes.ContentSegment) {
		durationSeconds = item.contentSegment?.duration;
	} else if (item.contentItemType === ContentItemTypes.Content) {
		durationSeconds = item.content?.durationSeconds;
	}
	if (durationSeconds == null) return null;
	else return getFormatedTime(durationSeconds);
};

export interface CellProps {
	item: ICourseItemModel;
	cellValue?: string;
}

interface ControlCellProps extends CellProps {
	masterContentIsPublic: boolean;
	masterContentIsActive: boolean;
	courseItemId: number;
	courseId: number;
	onActiveChange: (isActive: boolean) => void;
}

export const ControlsCell = (props: ControlCellProps) => {
	const { item, masterContentIsPublic, masterContentIsActive, courseItemId, courseId, onActiveChange } = props;
	const isRefItem = isReferenceContent(item);

	const [isActiveCourseItem, setIsActiveCourseItem] = useState(item.isActive);

	const isRootContentDisabled = isRefItem && (!masterContentIsPublic || !masterContentIsActive);

	return (
		<div className={styles.cms_content__controls_container}>
			<Tooltip
				label={!item.isArchive ? "Activate content" : "Archived"}
				className={classNames(styles.cms_content__tooltip, styles.cms_content__tooltip_activate)}
				isActive={!isActiveCourseItem}
			>
				<NavLink
					className={classNames({
						[styles.cms_content__disabled_icon]: !isActiveCourseItem,
					})}
					to={generatePath(Routes.MyDeskContent, {
						courseId,
						courseItemId,
					})}
					onClick={(e) => {
						if (!isActiveCourseItem || isRootContentDisabled) {
							e.preventDefault();
						}
					}}
					isActive={() => isActiveCourseItem || isRootContentDisabled}
					target="_blank"
				>
					<PlayIcon
						className={classNames(styles.cms_content__play_icon, {
							[styles.cms_content__disabled_icon]: !isActiveCourseItem || isRootContentDisabled,
						})}
					/>
				</NavLink>
			</Tooltip>
			{isRootContentDisabled ? (
				<p className={styles.cms_content__master_disabled_label}>
					Disabled <br /> by Creator
				</p>
			) : (
				<TogglerActivation
					value={isActiveCourseItem}
					onChange={(value: boolean) => {
						setIsActiveCourseItem(value);
						onActiveChange(value);
					}}
					theme="left"
					isDisabled={item.isArchive}
				/>
			)}
		</div>
	);
};

const NameCell = (props: CellProps) => {
	const { item, cellValue } = props;
	const { courseId } = item;
	const isRefItem = isReferenceContent(item);
	const history = useHistory();

	const handleProfileForward = () => {
		if (isRefItem) {
			history.push(
				generatePath(Routes.CmsRefContentProfile, {
					courseId,
					courseItemId: item.courseItemId,
				})
			);
		} else if (item.contentItemType === ContentItemTypes.Content) {
			history.push(
				generatePath(Routes.CmsContentProfile, {
					courseId,
					courseItemId: item.courseItemId,
				})
			);
		} else if (item.contentItemType === ContentItemTypes.Assessment) {
			history.push(generatePath(Routes.CmsQuiz, { assessmentId: Number(item.assessmentId) }));
		} else if (item.contentItemType === ContentItemTypes.ContentSegment) {
			history.push(
				generatePath(Routes.CmsContentProfile, {
					courseId,
					courseItemId: item.courseItemId,
				})
			);
		} else if (item.contentItemType === ContentItemTypes.Thread) {
			history.push(
				generatePath(Routes.CmsContentThreadProfile, {
					courseId,
					courseItemId: item.courseItemId,
				})
			);
		} else if (item.contentItemType === ContentItemTypes.ExternalContent) {
			if (item?.externalContent?.type === ExternalContentTypes.StreamMeeting) {
				history.push(
					generatePath(Routes.CmsContentStreamProfile, {
						courseId,
						courseItemId: item.courseItemId,
					})
				);
			} else if (item?.externalContent?.type === ExternalContentTypes.ExternalLink) {
				history.push(
					generatePath(Routes.CmsContentExternalLinkProfile, {
						courseId,
						courseItemId: item.courseItemId,
					})
				);
			}
		}
	};

	return (
		<div className={styles.cms_content__cell_name} onClick={handleProfileForward}>
			{getCourseItemIcon(item)}
			<span className={styles.cms_content__cell_title}>{!isEmpty(cellValue) ? cellValue : item.content?.name}</span>
		</div>
	);
};

export const columns: ColumnGroup<ICourseItemModel>[] = [
	{
		Header: "Content",
		columns: [
			{
				Header: ContentGridColumnsHeaders.Empty,
				id: "0",
				accessor: (originalRow: ICourseItemModel) => {
					return originalRow.fileContentId;
				},
				Cell(cell: any) {
					return (
						<ControlsCell
							item={cell.row.original}
							courseId={cell.row.original.courseId}
							courseItemId={cell.row.original.courseItemId}
							masterContentIsActive={cell.row.original.masterContentIsActive}
							masterContentIsPublic={cell.row.original.masterContentIsPublic}
							onActiveChange={cell.onActiveChange}
						/>
					);
				},
			},
			{
				Header: ContentGridColumnsHeaders.Name,
				id: "1",
				accessor: (row: ICourseItemModel) => {
					if (row.contentItemType === ContentItemTypes.Content) {
						return row.content?.name ?? "";
					} else if (row.contentItemType === ContentItemTypes.ContentSegment) {
						return row.contentSegment?.name ?? "";
					} else if (row.contentItemType === ContentItemTypes.Assessment) {
						return row.assessment?.name ?? "";
					} else if (row.contentItemType === ContentItemTypes.Thread) {
						return row.thread ? getThreadTitle(row.thread) ?? "" : "";
					} else if (row.contentItemType === ContentItemTypes.ExternalContent) {
						return row.externalContent?.title ?? "";
					}
					return "";
				},
				Cell(cell: any) {
					return <NameCell cellValue={cell.value} item={cell.row.original} />;
				},
			},
			{
				Header: ContentGridColumnsHeaders.Creator,
				id: "2",
				accessor: (row: ICourseItemModel) => {
					if (row.contentItemType === ContentItemTypes.Thread) {
						return row.thread?.author ?? "";
					} else if (row.contentItemType === ContentItemTypes.ExternalContent) {
						return row.externalContent?.accountCreated ?? "";
					}
					return row.accountCreated;
				},
				Cell({ value }: { value: any }) {
					return !isEmpty(value) ? <>{`${value.firstName} ${value.lastName}`}</> : <></>;
				},
			},
			{
				Header: ContentGridColumnsHeaders.LastUpdated,
				id: "3",
				accessor: (row) => {
					if (
						row?.contentItemType === ContentItemTypes.Content ||
						row?.contentItemType === ContentItemTypes.ContentSegment
					) {
						return row?.content?.lastUpdated!;
					} else if (row?.contentItemType === ContentItemTypes.Assessment) {
						return row?.assessment?.lastUpdated;
					} else if (row?.contentItemType === ContentItemTypes.Thread) {
						return row?.thread?.updated;
					} else if (row?.contentItemType === ContentItemTypes.ExternalContent) {
						return row?.externalContent?.lastUpdated;
					}
				},
				Cell({ value }: { value: any }) {
					return !isEmpty(value) ? DateTime.fromISO(value).setLocale("en").toLocaleString(DateTime.DATE_MED) : <></>;
				},
			},
			{
				Header: ContentGridColumnsHeaders.Status,
				id: "4",
				accessor: (row: ICourseItemModel) => {
					if (
						row?.contentItemType === ContentItemTypes.Content ||
						row?.contentItemType === ContentItemTypes.ContentSegment
					) {
						return row.content?.status!;
					}
				},
				Cell(cell: any) {
					if (!cell.value) return <></>;
					return <CmsContentStatus processingStatus={cell.value} contentId={cell.row?.original?.contentId} />;
				},
			},
			{
				Header: ContentGridColumnsHeaders.Empty,
				id: "5",
				accessor: (row: ICourseItemModel) => {
					return row.isArchive!;
				},
				Cell(cell: any) {
					return cell.isArchived ? (
						<UnarchiveIcon
							className={styles.archive_box_icon}
							onClick={() => cell.onArchiveItem(cell.row)}
							data-testid="arrow_unarchive_course_item"
						/>
					) : (
						<ArchiveIcon
							className={styles.archive_box_icon}
							onClick={() => cell.onArchiveItem(cell.row)}
							data-testid="arrow_archive_course_item"
						/>
					);
				},
			},
		],
	},
];
