import React, { CSSProperties, ReactElement } from "react";
export interface IconProps {
  icon: ReactElement;
  className?: string;
  style?: CSSProperties;
  onClick?: React.MouseEventHandler;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
}

const Icon = (props: IconProps) => {
  const { icon, ...otherProps } = props;
  return <div {...otherProps}>{icon}</div>;
};
export default Icon;
