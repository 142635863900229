import { IFileModel } from "models/dto/ZoomiLxp/Models/Common/IFileModel";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import React, { useRef, useState } from "react";
import { UploadFileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/UploadFileTypes";
import { FileApi } from "api/controllers/FileApi";

interface IUploadTargetProps {
	fileType: UploadFileTypes;
	maxSize?: number;
	accept?: string;
	additionalRestrictions?: { additionalMaxSize: number; additionalRestrictionAcceptType: string };
	onSuccess?: (result: IResponseModel<IFileModel>, type: string, fileName: string) => void;
	onError?: (err: unknown) => void;
	onMaxSizeExceeded?: (size: number | string, maxSize: number, type: string) => void;
	onWrongFormat?: (type: string) => void;
	onLoading?: (isLoading: boolean) => void;
	showLoadingIndocator?: boolean;
	children?: React.ReactNode;
	disabled?: boolean;
}

const UploadTarget = (props: IUploadTargetProps) => {
	const {
		fileType,
		maxSize,
		onSuccess,
		onError,
		onMaxSizeExceeded,
		onWrongFormat,
		onLoading,
		additionalRestrictions,
		disabled,
	} = props;
	const fileInput = useRef<HTMLInputElement | null>(null);

	const [accept] = useState(() => (props.accept && new RegExp(props.accept)) || /.*/);
	const [additionalRestrictionsAccept] = useState(
		() =>
			(additionalRestrictions?.additionalRestrictionAcceptType &&
				new RegExp(additionalRestrictions?.additionalRestrictionAcceptType)) ||
			/.*/
	);
	const handle = async (file: File) => {
		const currentFileSize = file.size / 1024 / 1024;
		if (
			additionalRestrictions &&
			additionalRestrictionsAccept.test(file.type) &&
			currentFileSize > additionalRestrictions.additionalMaxSize
		) {
			onMaxSizeExceeded?.(currentFileSize.toFixed(2), additionalRestrictions.additionalMaxSize, file.type);
		} else if (!additionalRestrictions && maxSize && currentFileSize > maxSize) {
			onMaxSizeExceeded?.(currentFileSize.toFixed(2), maxSize, file.type);
		} else if (!accept.test(file.type)) {
			onWrongFormat?.(file.type);
		} else {
			onLoading?.(true);
			try {
				const data = {
					fileName: file.name,
					contentType: file.type,
					fileType: fileType,
				};
				const uploadInfo = await FileApi.createUploadUrl(data, file);
				const fileId = uploadInfo.id;
				const urlInfo = await FileApi.getDownloadUrl(uploadInfo.id);
				onSuccess?.({ data: { id: fileId, url: urlInfo.data.data.url } }, file.type, file.name);
				onLoading?.(false);
			} catch (e) {
				onError?.(e);
			}
		}

		if (fileInput.current) fileInput.current.value = "";
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		handleDragLeave(e);
		handle(e.dataTransfer.files[0]);
	};

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => handleDragOver(e);

	const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => handleDragOver(e);

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
	};

	const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files?.length) {
			handle(files[0]);
		}
	};

	return (
		<div
			onDrop={(e) => !disabled && handleDrop(e)}
			onDragEnter={(e) => !disabled && handleDragEnter(e)}
			onDragLeave={(e) => !disabled && handleDragLeave(e)}
			onDragOver={(e) => !disabled && handleDragOver(e)}
			onClick={(e) => !disabled && fileInput.current?.click()}
		>
			{props.children}
			<input
				ref={fileInput}
				type="file"
				style={{ display: "none" }}
				multiple={false}
				onChange={(e) => handleFileSelected(e)}
				disabled={disabled}
			/>
		</div>
	);
};

export default UploadTarget;
