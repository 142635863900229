import { useCallback, useEffect } from "react";
import { getSearchInitParams } from "helpers/filter.helper";
import { useAppStore } from "store";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";

export const useAdvSearch = () => {
	const { searchStore } = useAppStore();

	useEffect(() => {
		return () => {
			searchStore.params = getSearchInitParams();
		};
	}, [searchStore]);

	const setParams = useCallback(
		(value: ((prevState: IQueryParams) => IQueryParams) | IQueryParams): void => {
			if (typeof value === "function") {
				searchStore.params = value(searchStore.params);
			} else {
				searchStore.params = value as IQueryParams;
			}
		},
		[searchStore]
	);

	return { setParams };
};
