import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { columns } from "./data";
import { usePagination, useTable } from "react-table";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import CmsTable from "components/partial/cms/cms-table/cms-table";
import CmsFooter from "components/partial/cms/cms-footer/cms-footer";
import { useAppStore } from "store";
import { IReportModel } from "store/reports/reports-store";
import AllReportsTile from "./all-reports-tile/all-reports-tile";
import { uniqueId } from "lodash";

const AllReports = () => {
	const tableColumns = React.useMemo(() => columns, []);
	const { reportsStore } = useAppStore();
	const [data, setData] = useState<IReportModel[]>([]);

	useEffect(() => {
		setData([
			{
				reportName: "Course Progress",
				onExport: () => reportsStore.exportCourseProgressReport(),
			},
			{
				reportName: "Z2 by Course",
				onExport: () => reportsStore.exportZ2ByCourseReport(),
			},
			{
				reportName: "Engagement by Course",
				onExport: () => reportsStore.exportEngagementByCourseReport(),
			},
			{
				reportName: "Quiz Results (all data)",
				onExport: () => reportsStore.exportQuizResultsReport(),
			},
			{
				reportName: "Assigned courses activity",
				onExport: () => reportsStore.exportAssignedCoursesActivityReport(),
			},
			{
				reportName: "Not assigned courses activity",
				onExport: () => reportsStore.exportNotAssignedCoursesActivityReport(),
			},
		]);
	}, [reportsStore]);

	const table = useTable<IReportModel>(
		{
			columns: tableColumns,
			data,
			initialState: { pageIndex: 0 },
			pageCount: 0,
		},
		usePagination
	);

	const initParams: IQueryParams = {
		take: table.state.pageSize,
		skip: 0,
		filterCriteria: [],
		sortCriteria: [],
		queryString: "",
	};
	const [, setParams] = React.useState<IQueryParams>(initParams);
	const getKey = (item: IReportModel) => uniqueId(item.reportName);

	return (
		<div className={styles.all_reports}>
			<div className={styles.all_reports__header}>
				<div className={styles.all_reports__title}>All Reports</div>
			</div>
			<div className={styles.all_reports__body}>
				<CmsTable
					table={table}
					data={data}
					tileComponent={AllReportsTile}
					getKey={getKey}
					className={styles.all_reports__table}
					disableRowCursor={true}
					disableRowHover={true}
				/>
				<CmsFooter table={table} loading={false} hideControls={true} setParams={setParams} />
			</div>
		</div>
	);
};

export default AllReports;

