import { mimeTypes } from "constants/constants";
import { PixelCrop } from "react-image-crop";

export const canvasPreview = async (image: HTMLImageElement, crop: PixelCrop, quality?: number, isPng?: boolean) => {
	const canvas = document.createElement("canvas");
	const context: CanvasRenderingContext2D | null = canvas.getContext("2d");

	if (!context) {
		throw new Error("No 2d context");
	}

	const scaleX: number = image.naturalWidth / image.width;
	const scaleY: number = image.naturalHeight / image.height;

	const pixelRatio: number = window.devicePixelRatio;

	canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
	canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

	context.scale(pixelRatio, pixelRatio);
	context.imageSmoothingQuality = "high";

	const cropX: number = crop.x * scaleX;
	const cropY: number = crop.y * scaleY;

	const centerX: number = image.naturalWidth / 2;
	const centerY: number = image.naturalHeight / 2;

	context.save();
	context.translate(-cropX, -cropY);
	context.translate(centerX, centerY);
	context.translate(-centerX, -centerY);
	context.drawImage(
		image,
		0,
		0,
		image.naturalWidth,
		image.naturalHeight,
		0,
		0,
		image.naturalWidth,
		image.naturalHeight
	);

	context.restore();
	const base64Image = canvas.toDataURL(isPng ? mimeTypes.png : mimeTypes.jpeg, quality ?? 0.92);
	return base64Image;
};
