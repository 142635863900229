import { ISignInRequestModel } from "models/dto/ZoomiLxp/Models/Authentication/ISignInRequestModel";
import { ISignInResponseModel } from "models/dto/ZoomiLxp/Models/Authentication/ISignInResponseModel";
import { ISignUpRequestModel } from "models/dto/ZoomiLxp/Models/Authentication/ISignUpRequestModel";
import { IExternalSignInRequestModel } from "models/dto/ZoomiLxp/Models/Authentication/IExternalSignInRequestModel";
import { IConfirmEmailRequestModel } from "models/dto/ZoomiLxp/Models/EmailConfirmation/IConfirmEmailRequestModel";
import Api, { ApiBase, abortController, axiosRetryConfig } from "../utils/api";
import { IRequestPasswordResetRequestModel } from "models/dto/ZoomiLxp/Models/PasswordReset/IRequestPasswordResetRequestModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { AxiosResponse } from "axios";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import { IConfirmPasswordResetRequestModel } from "models/dto/ZoomiLxp/Models/PasswordReset/IConfirmPasswordResetRequestModel";
import { ICheckPasswordConfirmationCodeModel } from "models/dto/ZoomiLxp/Models/PasswordReset/ICheckPasswordConfirmationCodeModel";
import { ICodeValidationResultModel } from "models/dto/ZoomiLxp/Models/PasswordReset/ICodeValidationResultModel";
import { IRoleModel } from "models/dto/ZoomiLxp/Models/Profile/IRoleModel";
import { ICheckInvitationCodeModel } from "models/dto/ZoomiLxp/Models/Invitation/ICheckInvitationCodeModel";
import { IConfirmInvitationRequestModel } from "models/dto/ZoomiLxp/Models/Invitation/IConfirmInvitationRequestModel";
import { IInvitationCodeValidationResultModel } from "models/dto/ZoomiLxp/Models/Invitation/IInvitationCodeValidationResultModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { IExpertiseModel } from "models/dto/ZoomiLxp/Models/Influencer/IExpertiseModel";
import { IDummyResponseModel } from "models/dto/ZoomiLxp/Models/Common/IDummyResponseModel";
import { ISetup2FAModel } from "models/dto/ZoomiLxp/Models/Authentication/ISetup2FAModel";
import { ICheck2FAModel } from "models/dto/ZoomiLxp/Models/Authentication/ICheck2FAModel";

export class AccountApi {
	static signin(data: ISignInRequestModel) {
		return Api.post<ISignInResponseModel>("/account/signin", data, {
			"axios-retry": axiosRetryConfig,
		});
	}

	static signout() {
		return Api.post("/account/SignOut");
	}

	static signup(data: ISignUpRequestModel) {
		return Api.post("/account/SignUp", data);
	}

	static setup2FA(data: ISetup2FAModel) {
		return Api.post<ISignInResponseModel>("/account/Setup2FAKey", data);
	}

	static check2FA(data: ICheck2FAModel) {
		return Api.post<ISignInResponseModel>("/account/Check2FA", data);
	}

	static externalSignIn(data: IExternalSignInRequestModel) {
		return Api.post<ISignInResponseModel>("/account/ExternalSignIn", data);
	}

	static confirmEmail(data: IConfirmEmailRequestModel) {
		return Api.post("/account/ConfirmEmail", data);
	}

	static resetPassword(data: IRequestPasswordResetRequestModel) {
		return Api.post("/account/RequestPasswordReset", data);
	}

	static confirmPasswordReset(data: IConfirmPasswordResetRequestModel) {
		return Api.post("/account/ConfirmPasswordReset", data);
	}

	static getUsers(data: IQueryParams) {
		return ApiBase.post<IQueryParams, AxiosResponse<IResponseModel<IGetRecordsResponse<IProfileDataModel>>>>(
			"/account/GetAll",
			data,
			{
				signal: abortController.getSignalToken(),
			}
		);
	}

	static CheckPasswordConfirmationCode(data: ICheckPasswordConfirmationCodeModel) {
		return Api.post<ICodeValidationResultModel, ICheckPasswordConfirmationCodeModel>(
			"/account/CheckPasswordConfirmationCode",
			data
		);
	}

	static getRoles() {
		return Api.get<IRoleModel[]>("/account/GetRoles");
	}

	static getAllInfluencerJobTitles(params?: IPagingParams) {
		return Api.postUnwrapped<string[], IPagingParams>("/account/GetAllInfluencerJobTitles", params);
	}

	static getAllAreaOfExpertises(params: IPagingParams) {
		return Api.postUnwrapped<IExpertiseModel[], IPagingParams>("/account/GetAllAreaOfExpertises", params);
	}

	static checkInvitationCode(data: ICheckInvitationCodeModel) {
		return Api.post<IInvitationCodeValidationResultModel, ICheckInvitationCodeModel>(
			"/account/CheckInvitationCode",
			data
		);
	}

	static confirmInvitation(data: IConfirmInvitationRequestModel) {
		return Api.post<IDummyResponseModel, IConfirmInvitationRequestModel>("/account/ConfirmInvitation", data);
	}
}
