import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import classNames from "classnames";
import LoadingIndicator from "../loading-indicator/loading-indicator";
import { useAppStore } from "store";
import CarouselDataProvider from "./carousel-data-provider";

interface Props {
	carouselsKeys: string[];
}

const CarouselsArray = ({ carouselsKeys }: Props) => {
	const { settingsStore, usersStore } = useAppStore();
	const userId = usersStore.currentUserInfo!.id;
	return (
		<>
			{carouselsKeys.map((carouselKey) => {
				const carouselData = settingsStore.getCarouselObject(userId, carouselKey);

				return (
					!carouselData.isEmpty && (
						<div className={classNames(styles.carousels_array__lessons_section)} key={carouselKey + carouselData.label}>
							<div
								className={classNames(styles.carousels_array__tiles_wrap, {
									[styles.carousels_array__lessons_section__dark]: carouselData.isDarkTheme,
								})}
							>
								<p
									className={classNames(
										styles.carousels_array__section_type,
										styles.carousels_array__section_type__primary
									)}
								>
									{carouselData.labelPrefix}
								</p>
								<h2
									className={classNames(styles.carousels_array__section_label, {
										[styles.carousels_array__section_label__dark]: carouselData.isDarkTheme,
									})}
								>
									{carouselData.label}
								</h2>
								<LoadingIndicator
									loading={carouselData.isLoading}
									spinnerPosition="center"
									backgroundStyle="blur"
									hideChildrenDuringLoading={false}
								>
									<div className={styles.carousels_array__tiles_section}>
										<CarouselDataProvider
											carouselData={carouselData}
											step={
												//TODO: temporary decision, need to check why steps are diffetent for the same type components
												carouselKey.includes("PurchasedCourses") || carouselKey.includes("TrendingCourses") ? 1 : 1.05
											}
										/>
									</div>
								</LoadingIndicator>
							</div>
						</div>
					)
				);
			})}
		</>
	);
};

export default observer(CarouselsArray);
