import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { ConfirmationModal } from "components/partial/confirmation-popup/confirmation-popup";
import { PRIVACY_POLICY_URL } from "constants/constants";
import styles from "./styles.module.scss";
import { ReactComponent as ArrowRight } from "assets/icons/ic_arrow-right.svg";
import isNil from "lodash/isNil";
import { useFooterData } from "hooks/useGetTermsOfUse";

export const showLoginConfirmationModal = async (confirmAction: () => void, rejectAction?: () => void) =>
	await modalDialogService.show(
		ConfirmationModal,
		{
			children: <ModalContent />,
			confirmationAction: confirmAction,
			cancelButtonLabel: "Decline",
			confirmationButtonLabel: "Accept",
			rejectionAction: rejectAction,
			showLogo: true,
			swapButtons: true,
		},
		false
	);

const ModalContent: React.FC = () => {
	const { termsOfUseEnabled, termsOfUseUrl } = useFooterData();
	return (
		<div className={styles.conditions_popup}>
			<div className={styles.conditions_popup__title}>
				Please read and accept following documents before continuing.
			</div>
			{getModalSection("Privacy Policy (Required)", getTermsDescription(termsOfUseEnabled), PRIVACY_POLICY_URL)}
			{termsOfUseEnabled &&
				getModalSection(
					"General Terms Of Service (Required)",
					getTermsDescription(termsOfUseEnabled, ""),
					termsOfUseUrl
				)}
		</div>
	);
};

const getTermsDescription = (termsOfUseEnabled: boolean, company?: string) => {
	return `I have read and agree to ${
		termsOfUseEnabled && !isNil(company) ? company + " Terms Of Service" : "Zoomi Privacy Policy"
	}
	 and have read, understand, and agree to each set of terms presented, and shall abide by them.`;
};

const getModalSection = (title: string, description: string, forwardTo: string) => {
	return (
		<section className={styles.conditions_popup__terms_section} onClick={() => window.open(forwardTo, "_blank")}>
			{title}
			<div className={styles.conditions_popup__terms_description}>
				{description}
				<ArrowRight className={styles.conditions_popup__icon} />
			</div>
		</section>
	);
};
