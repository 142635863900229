import Api from "api/utils/api";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IWidgetModel } from "models/dto/ZoomiLxp/Models/Reports/IWidgetModel";
import { ISaveWidgetsRequestModel } from "models/dto/ZoomiLxp/Models/Reports/ISaveWidgetsRequestModel";

export class ReportsApi {
	static exportCourseProgressReport() {
		return Api.post("/Reports/ExportEmployPartcipants", null, {
			responseType: "blob",
		});
	}

	static exportQuizResultsReport() {
		return Api.post("/Reports/ExportQuizResults", null, {
			responseType: "blob",
		});
	}

	static exportZ2ByCourseReport() {
		return Api.post("/Reports/ExportCourseZ2History", null, {
			responseType: "blob",
		});
	}

	static exportEngagementByCourseReport() {
		return Api.post("/Reports/ExportCourseEngagementHistory", null, {
			responseType: "blob",
		});
	}

	static exportAssignedCoursesActivityReport() {
		return Api.post("/Reports/ExportCourseAssignments", null, {
			responseType: "blob",
		});
	}

	static exportNotAssignedCoursesActivityReport() {
		return Api.post("/Reports/ExportUnassignedCoursesActivity", null, {
			responseType: "blob",
		});
	}

	static getWidgets() {
		return Api.get<IGetRecordsResponse<IWidgetModel>>("/Reports/Widgets");
	}

	static saveWidgets(data: ISaveWidgetsRequestModel) {
		return Api.post<IGetRecordsResponse<IWidgetModel>>("/Reports/SaveWidgets", data);
	}
}
