import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { ReactNode } from "react";

interface CourseCardBoxProps {
	isShow: boolean;
	className?: string;
	children?: ReactNode;
}

const cx = classNames.bind(styles);

const CourseCardBox = (props: CourseCardBoxProps) => {
	const { className, children, isShow } = props;

	return (
		<div
			className={cx(styles.course_card_box, className, {
				[styles.course_card_box__show]: isShow,
			})}
		>
			{children}
		</div>
	);
};

export default CourseCardBox;
