//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class EmailSubstitutions
{
	public static PlatformName: string = `{PlatformName}`;
	public static PlatformOwnerCompany: string = `{PlatformOwnerCompany}`;
	public static TeamName: string = `{TeamName}`;
	public static CurrentUserEmail: string = `{CurrentUserEmail}`;
	public static TargetEmail: string = `{Email}`;
	public static CourseTitle: string = `{CourseTitle}`;
	public static CourseSubstitution: string = `{CourseSubstitution}`;
	public static CompanyManagerName: string = `{CompanyManagerName}`;
	public static CompanyLearnerJobTitle: string = `{CompanyLearnerJobTitle}`;
	public static CompanyLearnerName: string = `{CompanyLearnerName}`;
	public static CourseUrl: string = `{CourseUrl}`;
	public static InvitationUrl: string = `{InvitationUrl}`;
	public static Username: string = `{Username}`;
	public static TargetDisplayName: string = `{TargetDisplayname}`;
	public static CurrentDisplayName: string = `{CurrentDisplayname}`;
	public static CompanyName: string = `{CompanyName}`;
	public static ConfirmationCode: string = `{ConfirmationCode}`;
	public static ConfirmationUrl: string = `{ConfirmationUrl}`;
	public static Expires: string = `{ExpiresDate}`;
	public static CurrentUserCompanyName: string = `{CurrentUserCompanyName}`;
	public static TargetUserCompanyName: string = `{TargetUserCompanyName}`;
	public static TargetCompanyName: string = `{TargetCompanyName}`;
	public static CompanyManagerUsername: string = `{CompanyManagerUsername}`;
	public static PasswordResetUrl: string = `{PasswordResetUrl}`;
	public static WhoReply: string = `{WhoReply}`;
	public static AuthorOfParentPost: string = `{AuthorOfParentPost}`;
	public static ThreadName: string = `{ThreadName}`;
	public static CommentText: string = `{CommentText}`;
	public static CommentUrl: string = `{CommentUrl}`;
	public static WhoFollowedName: string = `{WhoFollowedName}`;
	public static Influencer: string = `{Influencer}`;
	public static WhoLikedName: string = `{WhoLikedName}`;
	public static ContentName: string = `{ContentName}`;
	public static LinkOrNameLikedItem: string = `{LinkOrNameLikedItem}`;
	public static WhoRatedName: string = `{WhoRatedName}`;
	public static WhoStartedConversationName: string = `{WhoStartedConversationName}`;
	public static WhoAssignedName: string = `{WhoAssignedName}`;
	public static Message: string = `{Message}`;
	public static CompanyManagerDisplayName: string = `{CompanyManagerDisplayName}`;
	public static BackgroundNavbarColor: string = `{BackgroundNavbarColor}`;
	public static BackgroundLayoutColor: string = `{BackgroundLayoutColor}`;
	public static TextGeneralColor: string = `{TextGeneralColor}`;
	public static PrimaryColor: string = `{PrimaryColor}`;
	public static LogoUrl: string = `{LogoUrl}`;
	public static PlatformNameUrl: string = `{PlatformNameUrl}`;
	public static TenantUrl: string = `{TenantUrl}`;
	public static TenantName: string = `{TenantName}`;
	public static TenantHost: string = `{TenantHost}`;
	public static ConfirmationLink: string = `{ConfirmationLink}`;
	public static InvitationLink: string = `{InvitationLink}`;
	public static CommentLink: string = `{CommentLink}`;
	public static TenantLink: string = `{TenantLink}`;
	public static CourseLink: string = `{CourseLink}`;
	public static PasswordResetLink: string = `{PasswordResetLink}`;
	public static GroupsAssigned: string = `{GroupsAssigned}`;
	public static substitutionsGroups: { [key:number]: string[] } = {
		0: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{CourseTitle}", "{CourseSubstitution}", "{CompanyManagerName}", "{CompanyLearnerJobTitle}", "{CompanyLearnerName}", "{CourseUrl}", "{CourseLink}", "{TargetDisplayname}"],
		7: ["{CourseTitle}", "{CompanyManagerName}", "{InvitationUrl}", "{InvitationLink}", "{Username}", "{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{TargetDisplayname}", "{CourseSubstitution}"],
		1: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{CompanyName}", "{Email}", "{TargetDisplayname}", "{CurrentDisplayname}"],
		2: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{CompanyName}", "{Email}", "{TargetDisplayname}", "{CurrentDisplayname}"],
		3: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{Email}", "{TargetDisplayname}", "{CurrentDisplayname}"],
		4: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{Email}", "{TargetDisplayname}", "{CurrentDisplayname}"],
		5: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{Email}", "{TargetDisplayname}", "{CurrentDisplayname}"],
		18: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{Email}", "{TargetDisplayname}", "{CurrentDisplayname}", "{TenantUrl}", "{TenantLink}", "{TenantName}", "{TenantHost}"],
		6: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{ConfirmationCode}", "{ConfirmationUrl}", "{ConfirmationLink}", "{ExpiresDate}"],
		8: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{InvitationUrl}", "{InvitationLink}", "{ExpiresDate}", "{TargetUserCompanyName}", "{Username}", "{TargetCompanyName}", "{CompanyManagerUsername}", "{TargetDisplayname}", "{CompanyManagerDisplayName}"],
		9: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{InvitationUrl}", "{InvitationLink}", "{ExpiresDate}", "{Username}", "{TargetCompanyName}", "{CompanyManagerUsername}", "{TargetDisplayname}", "{CompanyManagerDisplayName}"],
		10: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{ExpiresDate}", "{Username}", "{PasswordResetUrl}", "{PasswordResetLink}"],
		11: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}", "{WhoReply}", "{AuthorOfParentPost}", "{ThreadName}", "{CommentText}", "{CommentUrl}", "{CommentLink}"],
		12: ["{WhoFollowedName}", "{Influencer}"],
		17: ["{PlatformName}", "{PlatformOwnerCompany}", "{TeamName}", "{CurrentUserEmail}"],
		13: ["{Username}", "{WhoLikedName}", "{LinkOrNameLikedItem}"],
		19: ["{Username}", "{WhoLikedName}", "{LinkOrNameLikedItem}"],
		20: ["{Username}", "{WhoLikedName}", "{LinkOrNameLikedItem}"],
		21: ["{Username}", "{WhoLikedName}", "{LinkOrNameLikedItem}"],
		14: ["{Influencer}", "{WhoRatedName}", "{ContentName}"],
		22: ["{Username}", "{WhoAssignedName}", "{GroupsAssigned}"],
	};
}
