import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { IMotifsTableRowData, columns } from "./data";
import { usePagination, useTable } from "react-table";
import CmsTable from "components/partial/cms/cms-table/cms-table";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import CmsFooter from "components/partial/cms/cms-footer/cms-footer";
import MotifsTableTile from "./motifs-table-tile/motifs-table-tile";
import { useRouteMatch } from "react-router-dom";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { IMotifStatisticsParams } from "models/dto/ZoomiLxp/Models/Analytics/IMotifStatisticsParams";
import { TimeFilterTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/TimeFilterTypes";
import { useRouteUserId } from "hooks/useRouteUserId";
import AnalyticsFilters from "../analytics-filter";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";

const MotifsTable = () => {
	const match = useRouteMatch<{ motifId: string }>();
	const routeMotifId = Number(match.params.motifId);
	const { routeUserId } = useRouteUserId();
	const tableColumns = useMemo(() => columns, []);
	const [data, setData] = useState<IMotifsTableRowData[]>([]);
	const { analyticsStore } = useAppStore();

	const table = useTable<IMotifsTableRowData>(
		{
			columns: tableColumns,
			data,
			initialState: { pageIndex: 0, pageSize: 20 },
			manualPagination: true,
			pageCount: 1,
		},
		usePagination
	);

	const [tableParams, setTableParams] = useState<IMotifStatisticsParams>(() => {
		return {
			motifsGroupId: routeMotifId,
			timeFilterType: TimeFilterTypes.OverAll,
			take: table.state.pageSize,
			skip: 0,
		};
	});

	const params = useRef<IMotifStatisticsParams>(tableParams);

	const getData = useCallback(async () => {
		return await analyticsStore.getMotifsStatistics(tableParams, routeUserId);
	}, [analyticsStore, routeUserId, tableParams]);

	const { items } = useInfiniteScroll<IMotifsTableRowData>(getData, params);

	useEffect(() => {
		if (items.length) {
			setData(items);
		}
	}, [analyticsStore, items]);

	const getKey = (item: IMotifsTableRowData) => item.courseId;

	return (
		<div className={styles.motifs_statistics}>
			<div className={styles.motifs_statistics__header}>
				<div className={styles.motifs_statistics__title}>Motifs Statistics</div>
				<div className={styles.motifs_statistics__filters_group}>
					<AnalyticsFilters
						initialMotifId={routeMotifId}
						onParamsChange={(timeFilterType, motifsGroupId, subjectId) => {
							setTableParams((prev: IMotifStatisticsParams) => ({ ...prev, timeFilterType, motifsGroupId, subjectId }));
						}}
						showMotifsFilter
					/>
				</div>
			</div>
			<div className={styles.motifs_statistics__body}>
				<LoadingIndicator loading={analyticsStore.isSessionsLoading}>
					<CmsTable
						table={table}
						data={data}
						tileComponent={MotifsTableTile}
						getKey={getKey}
						className={styles.motifs_statistics__table}
						disableRowCursor={true}
					/>
					<CmsFooter
						table={table}
						loading={analyticsStore.isSessionsLoading}
						hideControls={true}
						itemsCount={data.length}
					/>
				</LoadingIndicator>
			</div>
		</div>
	);
};

export default observer(MotifsTable);
