//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ExternalIdentityProviderTypes {
	Unknown = 0,
	Google = 1,
	Facebook = 2,
	LinkedIn = 3,
	DigitalUniverse = 4
}
