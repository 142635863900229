import { observer } from "mobx-react";
import PrivateLayout from "layouts/private/private";
import styles from "./styles.module.scss";
import { useAppStore } from "store";
import { useEffect } from "react";
import { getOrderedCarousels } from "helpers/settings.helper";
import CarouselsArray from "components/partial/carousels-array/carousels-array";
import Banner from "components/base/banner/banner";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";

export const HomePage = observer(function HomePage() {
	const { usersStore, coursesStore, settingsStore, toasterStore } = useAppStore();
	const sortedCarousels = getOrderedCarousels(settingsStore.publicSettings, "Home.Carousels.Order");
	const userId = usersStore.currentUserInfo!.id;

	useEffect(() => {
		coursesStore.getAllCoursesByGroups(userId).catch((err) => toasterStore.showErrorMessage(err));
		return coursesStore.clearStore();
	}, [toasterStore, coursesStore, usersStore, userId]);

	return (
		<PrivateLayout>
			<div className={styles.homepage}>
				<Banner
					className={styles.home_banner}
					bannerContentType={settingsStore.publicSettings.Images?.[SiteSettingsKeys.HomeBannerContentType]}
					bannerSrc={settingsStore.publicSettings.Images?.[SiteSettingsKeys.HomeBanner]}
				/>
				<div className={styles.homepage__content}>
					<CarouselsArray carouselsKeys={sortedCarousels} />
				</div>
			</div>
		</PrivateLayout>
	);
});
