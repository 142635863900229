//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class ClaimTypes
{
	public static TenantIdClaimType: string = `zoomi_lxp_tenant_id`;
	public static SessionIdClaimType: string = `zoomi_lxp_session_id`;
	public static AccountIdClaimType: string = `zoomi_lxp_account_id`;
	public static RolesClaimType: string = `zoomi_lxp_role_names`;
	public static PermissionsClaimType: string = `zoomi_lxp_permission_names`;
	public static MFAStatusClaimType: string = `zoomi_lxp_mfa_status`;
	public static Name: string = `name`;
	public static Email: string = `email`;
	public static UserId: string = `user_id`;
	public static GivenName: string = `given_name`;
	public static FamilyName: string = `family_name`;
	public static EmailVerified: string = `email_verified`;
	public static App: string = `zoomi_lxp_app`;
	public static Sid: string = `sid`;
}
