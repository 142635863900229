import { IOptionsItem, getMotifsOptions, getSubjectOptions } from "helpers/select.helper";
import uniqueId from "lodash/uniqueId";
import { TimeFilterTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/TimeFilterTypes";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useAppStore } from "store";
import styles from "../styles.module.scss";
import { motifsFilterDropdownStyles } from "../data";
import isNil from "lodash/isNil";
import { ReactComponent as CloseIcon } from "assets/icons/ic_close.svg";
import { observer } from "mobx-react";
import { IMotifMeterItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifMeterItem";

interface IAnalyticsFiltersProps {
	showMotifsFilter?: boolean;
	initialMotifId?: number;
	onParamsChange: (timeFilterType: TimeFilterTypes, selectedMotifId: number, subjectId?: number) => void;
}

const AnalyticsFilters = ({ onParamsChange, showMotifsFilter, initialMotifId }: IAnalyticsFiltersProps) => {
	const { analyticsStore } = useAppStore();
	const subjectsOptions = getSubjectOptions(analyticsStore.analyticsSubjects);
	const motifsOptions = getMotifsOptions(analyticsStore.motifsMeterItems);
	const [selectedMotif, setSelectedMotif] = useState<IOptionsItem | undefined>(undefined);
	const [selectedSubject, setSelectedSubject] = useState<IOptionsItem | null>(null);
	const [selectedTimeOption, setSelectedTimeOption] = useState<IOptionsItem>({
		value: TimeFilterTypes.OverAll.toString(),
		label: TimeFilterTypes[TimeFilterTypes.OverAll],
	});

	useEffect(() => {
		const initMotifItem: IMotifMeterItem | undefined = analyticsStore.motifsMeterItems.find(
			(item: IMotifMeterItem) => item.id === initialMotifId
		);
		if (initMotifItem) setSelectedMotif({ value: initMotifItem.id, label: initMotifItem.name });
	}, [analyticsStore.motifsMeterItems, initialMotifId]);

	const timeDropdownOptions = [
		{
			value: TimeFilterTypes.Week.toString(),
			label: TimeFilterTypes[TimeFilterTypes.Week],
		},
		{
			value: TimeFilterTypes.Month.toString(),
			label: TimeFilterTypes[TimeFilterTypes.Month],
		},
		{
			value: TimeFilterTypes.OverAll.toString(),
			label: TimeFilterTypes[TimeFilterTypes.OverAll],
		},
	];
	return (
		<div className={styles.analytics_page__filters_container}>
			{showMotifsFilter && (
				<Select
					id={uniqueId("select-motif-")}
					value={selectedMotif}
					options={motifsOptions}
					className={styles.analytics_page__z1_dropdown}
					styles={motifsFilterDropdownStyles}
					isSearchable={false}
					onChange={async (value) => {
						if (value) {
							setSelectedMotif(value as IOptionsItem);
							if ("value" in value)
								onParamsChange(Number(selectedTimeOption.value), Number(value.value), Number(selectedSubject?.value));
						}
					}}
				/>
			)}
			<Select
				id={uniqueId("select-time-")}
				value={selectedTimeOption}
				options={timeDropdownOptions}
				className={styles.analytics_page__z1_dropdown}
				styles={motifsFilterDropdownStyles}
				isSearchable={false}
				onChange={async (value) => {
					if (value) {
						setSelectedTimeOption(value as IOptionsItem);
						if ("value" in value)
							onParamsChange(Number(value.value), Number(selectedMotif?.value), Number(selectedSubject?.value));
					}
				}}
			/>
			<Select
				id={uniqueId("select-subject-")}
				value={selectedSubject}
				options={subjectsOptions}
				className={styles.analytics_page__z1_dropdown}
				styles={motifsFilterDropdownStyles}
				isSearchable={false}
				onChange={async (value) => {
					if (value) {
						setSelectedSubject(value as IOptionsItem);
						if ("value" in value)
							onParamsChange(Number(selectedTimeOption.value), Number(selectedMotif?.value), Number(value.value));
					}
				}}
			/>
			{!isNil(selectedSubject) && (
				<CloseIcon
					className={styles.analytics_page__close_icon}
					onClick={async () => {
						setSelectedSubject(null);
						onParamsChange(Number(selectedTimeOption.value), Number(selectedMotif?.value), undefined);
					}}
				/>
			)}
		</div>
	);
};

export default observer(AnalyticsFilters);
