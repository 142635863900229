import * as Yup from "yup";
import { DateTime } from "luxon";
import { IExternalContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IExternalContentModel";
import { titleValidator, urlValidator } from "helpers/validator.helper";
import { IOptionsItem } from "helpers/select.helper";
import { IPlayerPropsBase } from "components/partial/player/data/data";

export interface IStreamInfo {
	isActive: boolean;
	subject: string;
	link: string;
	meetingDate: any;
}

export interface IStreamProps {
	stream: IStreamInfo;
	chapter?: IOptionsItem;
}

export const streamProfileSchema = Yup.object().shape({
	stream: Yup.object().shape({
		subject: titleValidator,
		link: urlValidator,
		meetingDate: Yup.date().min(new Date(), "The date cannot be in the past"),
	}),
});

export const defaultStreamInfo: IStreamInfo = {
	isActive: true,
	subject: "",
	link: "",
	meetingDate: DateTime.now(),
};

export enum StreamTypes {
	Zoom = "Zoom",
	Teams = "Teams",
	Unknown = "Unknown",
}

export interface IPlayerStreamBoxProps extends IPlayerPropsBase {
	stream: IExternalContentModel | undefined;
}
