import PrivateLayout from "layouts/private/private";
import CourseCard from "components/partial/lesson-card/course-card";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useRouteMatch } from "react-router-dom";
import { useAppStore } from "store";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { observer } from "mobx-react";
import ChaptersList from "components/partial/chapters/chapters-list/chapters-list";

const CoursePage = () => {
	const match = useRouteMatch<{ id: string; chapterId: string }>();
	const courseId = Number(match.params?.id ?? 0);
	const chapterId = Number(match.params?.chapterId ?? 0);
	const [errorMessage, setErrorMessage] = useState("");

	const { coursesStore, toasterStore } = useAppStore();

	useEffect(() => {
		if (courseId > 0) coursesStore.getCourseById(courseId).catch((err) => console.log(err));
	}, [courseId, coursesStore]);

	useEffect(() => {
		if (coursesStore.isErrorCourse) {
			setErrorMessage(coursesStore.errorText);
		}
	}, [toasterStore, coursesStore.errorText, coursesStore.isErrorCourse]);

	return (
		<PrivateLayout className={styles.course_page__container}>
			<LoadingIndicator loading={coursesStore.isLoading} spinnerPosition={"center"} backgroundStyle={"white"}>
				<div className={styles.course_page}>
					{errorMessage ? (
						<div className={styles.course_page__nodata}>{errorMessage}</div>
					) : (
						<div className={styles.course_page__wrap}>
							<div className={styles.course_page__box}>
								<CourseCard type="landing" />
							</div>
							{coursesStore.isActiveCourse && <ChaptersList id={chapterId} />}
						</div>
					)}
				</div>
			</LoadingIndicator>
		</PrivateLayout>
	);
};

export default observer(CoursePage);
