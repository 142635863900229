import Api from "api/utils/api";
import { ICreateCategoryModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ICreateCategoryModel";
import { ICourseCategoryResponseModel } from "models/dto/ZoomiLxp/Models/ContentModels/ICourseCategoryResponseModel";
import { IUpdateCategoryModel } from "models/dto/ZoomiLxp/Models/Taxonomy/IUpdateCategoryModel";
import { ITaxonomyModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ITaxonomyModel";
import { ITagModel } from "models/dto/ZoomiLxp/Models/Common/ITagModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";

export class TaxonomyApi {
	static addNewCategory(data: ICreateCategoryModel) {
		return Api.post<ICourseCategoryResponseModel>("/taxonomy/category", data);
	}

	static updateCategory(categoryId: number, data: IUpdateCategoryModel) {
		return Api.put<ICourseCategoryResponseModel>(`/taxonomy/category/${categoryId}`, data);
	}

	static getAllCategories() {
		return Api.get<ITaxonomyModel>("/taxonomy/GetAll");
	}

	static getCoursesTags(params: IQueryParams) {
		return Api.postUnwrapped<ITagModel[]>("/taxonomy/CoursesTags", params);
	}

	static getThreadTags(params: IQueryParams) {
		return Api.postUnwrapped<ITagModel[]>("/taxonomy/ThreadsTags", params);
	}

	static removeCategory(categoryId: number) {
		return Api.delete(`/taxonomy/category/${categoryId}`);
	}
}
