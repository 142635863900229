import { ISaveEventAccountHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventAccountHistoryRequestModel";
import { ISaveEventContentHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventContentHistoryModel";
import { ISaveEventCourseHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseHistoryRequestModel";
import Api, { axiosRetryConfig } from "../utils/api";
import { IGenerateSessionRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/IGenerateSessionRequestModel";
import { ISessionPlayerResponse } from "models/dto/ZoomiLxp/Models/EventModels/ISessionPlayerResponse";
import { ISaveEventThreadHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventThreadHistoryRequestModel";
import { ISaveEventPostHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventPostHistoryRequestModel";
import { IKeepAliveModel } from "models/dto/ZoomiLxp/Models/EventModels/IKeepAliveModel";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { IDummyResponseModel } from "models/dto/ZoomiLxp/Models/Common/IDummyResponseModel";
import { ISessionRecommendationsResponse } from "models/dto/ZoomiLxp/Models/EventModels/ISessionRecommendationsResponse";
import { ILastMotiffGroup } from "models/dto/ZoomiLxp/Models/EventModels/ILastMotiffGroup";

export class EventApi {
	static sendContentEvent(data: ISaveEventContentHistoryModel) {
		return Api.post("Event/SaveEventContentHistory", data);
	}

	static sendInfluencerEvent(data: ISaveEventAccountHistoryRequestModel) {
		return Api.post("Event/SaveEventAccountHistory", data);
	}

	static sendCourseEvent(data: ISaveEventCourseHistoryRequestModel) {
		return Api.post("Event/SaveEventCourseHistory", data);
	}

	static generatePlayerSessionId(data: IGenerateSessionRequestModel) {
		return Api.post<ISessionPlayerResponse, IGenerateSessionRequestModel>("Event/GeneratePlayerSessionId", data, {
			"axios-retry": axiosRetryConfig,
		});
	}

	static saveEventThreadHistory(data: ISaveEventThreadHistoryRequestModel) {
		return Api.post<IDummyResponseModel, ISaveEventThreadHistoryRequestModel>("Event/SaveEventThreadHistory", data);
	}

	static saveEventPostHistory(data: ISaveEventPostHistoryRequestModel) {
		return Api.post<IDummyResponseModel, ISaveEventPostHistoryRequestModel>("Event/SaveEventPostHistory", data);
	}

	static keepAlive(data: IKeepAliveModel) {
		return Api.post<IDummyResponseModel, IKeepAliveModel>("Event/KeepAlive", data);
	}

	static saveEventCourseItemHistory(data: ISaveEventCourseItemHistoryModel) {
		return Api.post<ILastMotiffGroup, ISaveEventCourseItemHistoryModel>("Event/SaveEventCourseItemHistory", data);
	}

	static saveEventCourseItemEvents(data: ISaveEventCourseItemHistoryModel[]) {
		return Api.post<ILastMotiffGroup, ISaveEventCourseItemHistoryModel[]>("Event/SaveCourseItemEvents", data);
	}

	static getRecommendations(sessionId: number) {
		return Api.get<ISessionRecommendationsResponse>(`Event/AskRecommendations/${sessionId}`);
	}

	static getRecommendationsGroup(sessionId: number) {
		return Api.get<ILastMotiffGroup>(`Event/AskRecommendationsGroup/${sessionId}`);
	}

	static clearRecommendationsGroup(sessionId: number) {
		return Api.post<ILastMotiffGroup>(`Event/ClearRecommendationsGroup/${sessionId}`);
	}
}
