import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import SimpleLayout from "layouts/simple/simple";
import { useAppStore } from "store";
import { useRouteMatch } from "react-router-dom";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";

interface IWikiProps {
	className?: string;
}

const Wiki = ({ className }: IWikiProps) => {
	const { settingsStore, notificationsStore, toasterStore } = useAppStore();
	const match = useRouteMatch<{ fileId: string | undefined }>();
	const fileId = Number(match.params.fileId);
	const [html, setHtml] = useState<string>("");

	const termsOfUserFileId = Number(settingsStore.publicSettings.Wiki?.[SiteSettingsKeys.WikiTermsOfUse]);
	const termsOfUseEnabled = settingsStore.publicSettings.Wiki?.[SiteSettingsKeys.WikiTermsOfUseEnabled] === "true";
	const accessIsDenied = !termsOfUseEnabled && fileId === termsOfUserFileId;
	console.log({ accessIsDenied, termsOfUseEnabled, fileId, termsOfUserFileId });

	useEffect(() => {
		if (fileId > 0) {
			notificationsStore
				.getDownloadHtml(fileId)
				.then((response) => setHtml(response))
				.catch((err) => toasterStore.showErrorMessage(err));
		} else toasterStore.showErrorMessage("Required parameter is not defined");
	}, [toasterStore, fileId, notificationsStore, setHtml]);

	return (
		<SimpleLayout>
			<div className={classNames(styles.wiki, className)}>
				{accessIsDenied ? (
					<div>Access is denied</div>
				) : (
					<LoadingIndicator loading={notificationsStore.isLoading} spinnerPosition={"center"} backgroundStyle={"white"}>
						<div dangerouslySetInnerHTML={{ __html: html }}></div>
					</LoadingIndicator>
				)}
			</div>
		</SimpleLayout>
	);
};

export default Wiki;
