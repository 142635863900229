import styles from "../styles.module.scss";
import { useAppStore } from "store";
import GroupsList from "components/partial/groups-list/groups-list";
import { observer } from "mobx-react";
import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { ConfirmationModal } from "components/partial/confirmation-popup/confirmation-popup";

export const showGroupsAssignmentModal = async (confirmAction: () => void, onCloseAction: () => void) =>
	await modalDialogService.show(
		ConfirmationModal,
		{
			children: <GroupsAssignmentModalContent />,
			confirmationAction: confirmAction,
			closeCallback: onCloseAction,
			hideCancelBtn: true,
			confirmationButtonLabel: "Apply changes",
			title: "Assign courses",
			showCloseIcon: true,
			swapButtons: true,
			hideDivider: true,
		},
		false
	);

const GroupsAssignmentModalContent = observer(() => {
	const { groupStore } = useAppStore();
	return (
		<div className={styles.assign_groups_popup}>
			If you want to assign/unassign courses from added/deleted groups please select group you need to do it for (bought
			courses will not be unassigned).
			<div className={styles.assign_groups_popup__content_container}>
				<GroupsList groups={groupStore.addedGroupsAssignmentData} title="Groups added (assign)" />
				<GroupsList groups={groupStore.removedGroupsAssignmentData} title="Groups removed (unassign)" />
			</div>
		</div>
	);
});
