import React from "react";

interface IconCornerProps {
	fill?: string;
}
const IconCorner = (props: IconCornerProps) => {
	const { fill = "#000000" } = props;

	return (
		<svg data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
			<path fill={fill} d="M40,40A41,41,0,0,1,0,0H0V40H40Z" />
		</svg>
	);
};

export default IconCorner;
