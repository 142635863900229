import { knowledgeOutdated } from "constants/messages";
import Tooltip from "components/base/tooltip/tooltip";
import { ReactComponent as AlertIcon } from "assets/icons/ic_alert-circle.svg";

interface AlertTooltipProps {
	className?: string;
}

const AlertTooltip = (props: AlertTooltipProps) => {
	return (
		<Tooltip label={knowledgeOutdated} className={props.className}>
			<AlertIcon />
		</Tooltip>
	);
};

export default AlertTooltip;
