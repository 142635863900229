//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class PermissionConstantsUpdate
{
	public static LearnerProfileData: string = `update.learner_profile_data`;
	public static GoalsMyPerfectBrain: string = `update.goals_my_perfect_brain`;
	public static OthersGoalsMyPerfectBrain: string = `update.other_goals_my_perfect_brain`;
	public static OtherCompanyUserGoals: string = `update.other_company_users_goals`;
	public static Billing: string = `update.billing`;
	public static Courses: string = `update.courses`;
	public static Company: string = `update.company`;
	public static AnyCompanies: string = `update.any_companies`;
	public static Taxonomy: string = `update.taxonomy`;
	public static Purchases: string = `update.purchases`;
	public static OwnThreads: string = `update.own_threads`;
	public static AllThreads: string = `update.all_threads`;
	public static InfluencerTileExtended: string = `update.influencer_tile_extended`;
	public static LessonTile: string = `update.lesson_tile`;
	public static Account: string = `update.account`;
	public static SetupAccountCompany: string = `update.account.switch_company`;
	public static Notes: string = `update.notes`;
	public static ThreadComments: string = `update.thread_comments`;
	public static PrivateSettings: string = `update.private_settings`;
	public static ScheduleEmailSettings: string = `update.schedule_email_settings`;
	public static SystemEmailSettings: string = `update.system_email_settings`;
	public static TenantAdmin: string = `update.tenant_admin`;
	public static ManageAssignCoursesOwnCompanyUsers: string = `update.manage_assign_courses_own_company_users`;
	public static ManageAssignCoursesForAllUsers: string = `update.manage_assign_courses_all_users`;
	public static ManageGroups: string = `update.manage_groups`;
}
