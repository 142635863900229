import { ContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentTypes";
import { ReactComponent as VideoIcon } from "assets/icons/ic_type_video.svg";
import { ReactComponent as PdfIcon } from "assets/icons/ic_type_document.svg";
import { ReactComponent as PodcastIcon } from "assets/icons/ic_type_audio.svg";
import { ReactComponent as CourseIcon } from "assets/icons/ic_type_course.svg";
import { ReactComponent as SegmentIcon } from "assets/icons/ic_segment.svg";
import { ReactComponent as ScormIcon } from "assets/icons/ic_type_scorm.svg";
import { ReactComponent as HtmlIcon } from "assets/icons/ic_type_html.svg";
import { ReactComponent as ThreadIcon } from "assets/icons/ic_type_thread.svg";
import { ReactComponent as QuizIcon } from "assets/icons/ic_type_quiz.svg";
import { ReactComponent as StreamIcon } from "assets/icons/ic_type_stream.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/icons/ic_type_external_link_core.svg";
import { ContentItemTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentItemTypes";

export const getIconContentTypes =
	(getIconStyles: (isNestedIcon?: boolean) => string, getCourseIconStyles: () => string) =>
	(contentType: ContentTypes) => {
		switch (contentType) {
			case ContentTypes.Video:
				return <VideoIcon className={getIconStyles()} />;
			case ContentTypes.Podcast:
				return <PodcastIcon className={getIconStyles()} />;
			case ContentTypes.Text:
				return <PdfIcon className={getIconStyles()} />;
			case ContentTypes.Thread:
				return <ThreadIcon className={getIconStyles(true)} />;
			case ContentTypes.Assessment:
				return <QuizIcon className={getIconStyles()} />;
			case ContentTypes.Scorm:
				return <ScormIcon className={getIconStyles(true)} />;
			case ContentTypes.ExternalContent:
				return <StreamIcon className={getIconStyles(true)} />;
			case ContentTypes.ExternalLink:
				return <ExternalLinkIcon className={getIconStyles(true)} />;
			case ContentTypes.Html:
			case ContentTypes.Zip:
			case ContentTypes.Mhtml:
				return <HtmlIcon className={getIconStyles(true)} />;
			default:
				return <CourseIcon className={getCourseIconStyles()} />;
		}
	};

export const getIconContentItemTypes =
	(getIconClassName: () => string, getIconSegmentClassName: () => string, getContentTypeIcon: JSX.Element) =>
	(contentItemType: ContentItemTypes) => {
		if (contentItemType === ContentItemTypes.Content) {
			return getContentTypeIcon;
		} else if (contentItemType === ContentItemTypes.Assessment) {
			return <QuizIcon className={getIconClassName()} />;
		} else if (contentItemType === ContentItemTypes.ContentSegment) {
			return <SegmentIcon className={getIconSegmentClassName()} />;
		} else if (contentItemType === ContentItemTypes.Thread) {
			return <ThreadIcon className={getIconClassName()} />;
		} else if (contentItemType === ContentItemTypes.ExternalContent) {
			return <StreamIcon className={getIconClassName()} />;
		}
		return <></>;
	};
