import styles from "./styles.module.scss";
import classNames from "classnames";
import { ColumnGroup, TableInstance } from "react-table";
import isEmpty from "lodash/isEmpty";
import noImage from "assets/images/no-image.png";
import { DateTime } from "luxon";
import { isNil } from "lodash";
import { matchPath, useLocation } from "react-router-dom";
import { Routes } from "routes";
import CmsCheckbox from "../cms-checkbox/cms-checkbox";
import CmsCheckBtn from "../cms-check-btn/cms-check-btn";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { Cell } from "react-table";
import { useAppStore } from "store";
import { SyntheticEvent } from "react";
import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { ConfirmationModal } from "components/partial/confirmation-popup/confirmation-popup";
import { ErrorMessage, promptsDelete } from "constants/messages";

const cx = classNames.bind(styles);

export const Columns = (): ColumnGroup<IPreviewCourseModel>[] => {
	const { pathname } = useLocation();
	const { groupStore, searchStore } = useAppStore();
	const isGroupsRoute = matchPath(pathname, Routes.CmsGroupProfileTab);

	const onDeleteClick = async (e: SyntheticEvent, courseId: number) => {
		e.stopPropagation();
		await modalDialogService.show(ConfirmationModal, {
			title: promptsDelete.title,
			popupText: promptsDelete.text,
			confirmationAction: async () => {
				groupStore.isLoading = true;
				try {
					await groupStore.deleteGroupFromCourse(courseId);
					searchStore.items = searchStore.items.filter(({ id }) => id !== courseId);
				} catch (e) {
					const error = (e as ErrorMessage)?.response?.data?.data?.errorMessage;
					alert(error ? error : e);
				} finally {
					groupStore.isLoading = false;
				}
			},
			confirmationButtonLabel: promptsDelete.confirmationButton,
			cancelButtonLabel: promptsDelete.cancelButton,
		});
	};

	if (isGroupsRoute) {
		return [
			{
				Header: "Courses",
				columns: [
					{
						id: "name",
						Header: "Name",
						accessor: "title",
						Cell({ cell }) {
							const { thumbnail, title } = cell.row.original;
							return (
								<div className={styles.cms_courses__cell_name}>
									<div className={styles.cms_courses__cell_picture}>
										<img
											className={styles.cms_courses__cell_image}
											src={isEmpty(thumbnail) ? noImage : thumbnail.url}
											alt={title}
										/>
									</div>
									<span className={styles.cms_courses__cell_title}>{title}</span>
								</div>
							);
						},
					},
					{
						id: "details",
						Header: "Details",
						accessor: "description",
						Cell({ cell: { value } }) {
							return !isEmpty(value) ? <>{value}</> : <></>;
						},
					},
					{
						id: "last_updated",
						Header: "Last Updated",
						accessor: (originalRow: IPreviewCourseModel) => {
							return originalRow.updatedUtc;
						},
						Cell({ cell }: { cell: Cell<IPreviewCourseModel> }) {
							const { updatedUtc } = cell.row.original;
							return !isNil(updatedUtc) ? (
								updatedUtc ? (
									DateTime.fromISO(updatedUtc!).setLocale("en").toLocaleString(DateTime.DATE_MED)
								) : (
									<>-</>
								)
							) : (
								<>-</>
							);
						},
					},
					{
						id: "price",
						Header: "Price",
						accessor: "price",
						Cell({ cell: { value } }) {
							return !isNil(value) ? `$ ${Number(value / 100).toFixed(2)}` : <>-</>;
						},
					},
					{
						id: "id",
						Header: "",
						accessor: "id",
						Cell({ cell: { value } }) {
							return (
								<span className={styles.delete_icon} onClick={(e) => onDeleteClick(e, value)}>
									&#10005;
								</span>
							);
						},
					},
				],
			},
		];
	}

	return [
		{
			Header: "Courses",
			columns: [
				{
					id: "selection",
					Header: () => <CmsCheckBtn className={styles.cms_courses__checkbox} />,
					Cell: (state: TableInstance<IPreviewCourseModel>) => {
						const { id } = state.cell.row.original;
						return <CmsCheckbox className={styles.cms_courses__checkbox} id={id} />;
					},
				},
				{
					id: "name",
					Header: "Name",
					accessor: "title",
					Cell({ cell }) {
						const { thumbnail, title } = cell.row.original;
						return (
							<div className={styles.cms_courses__cell_name}>
								<div className={styles.cms_courses__cell_picture}>
									<img
										className={styles.cms_courses__cell_image}
										src={isEmpty(thumbnail) ? noImage : thumbnail.url}
										alt={title}
									/>
								</div>
								<span className={styles.cms_courses__cell_title}>{title}</span>
							</div>
						);
					},
				},
				{
					id: "details",
					Header: "Details",
					accessor: "description",
					Cell({ cell: { value } }) {
						return !isEmpty(value) ? <div>{value}</div> : <></>;
					},
				},
				{
					id: "last_updated",
					Header: "Last Updated",
					accessor: (originalRow: IPreviewCourseModel) => {
						return originalRow.updatedUtc;
					},
					Cell({ cell }: { cell: Cell<IPreviewCourseModel> }) {
						const { updatedUtc } = cell.row.original;
						return !isNil(updatedUtc) ? (
							updatedUtc ? (
								DateTime.fromISO(updatedUtc!).setLocale("en").toLocaleString(DateTime.DATE_MED)
							) : (
								<>-</>
							)
						) : (
							<>-</>
						);
					},
				},
				{
					id: "price",
					Header: "Price",
					accessor: "price",
					Cell({ cell: { value } }) {
						return !isNil(value) ? `$ ${Number(value / 100).toFixed(2)}` : <>-</>;
					},
				},
				{
					id: "status",
					Header: "Status",
					accessor: "isActive",
					Cell({ cell: { value } }) {
						return (
							<span className={cx(styles.cms_courses__status, { [styles.cms_courses__status_success]: value })}>
								{value ? "Active" : "InActive"}
							</span>
						);
					},
				},
			],
		},
	];
};
