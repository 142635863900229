import styles from "./styles.module.scss";
import React, { ReactNode } from "react";
import classNames from "classnames";
import SubMenu from "components/partial/submenu/submenu";
import { influencerMenuItems } from "components/partial/submenu/items";
import PrivateLayout from "layouts/private/private";
import { useAppStore } from "store";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import Banner from "components/base/banner/banner";

interface Props {
	children: ReactNode;
	className?: string;
}

const cx = classNames.bind(styles);

const InfluencersLayout = (props: Props) => {
	const { children, className } = props;
	const { settingsStore } = useAppStore();
	return (
		<PrivateLayout className={cx(styles.inf_layout, className)}>
			<Banner
				bannerContentType={settingsStore.publicSettings.Images?.[SiteSettingsKeys.InfluencersBannerContentType]}
				bannerSrc={settingsStore.publicSettings.Images?.[SiteSettingsKeys.InfluencersBanner]}
			/>
			<div className={styles.inf_layout__nav}>
				<SubMenu menuItems={influencerMenuItems} className={styles.inf_layout__menu} />
			</div>
			<div className={styles.inf_layout__influencers}>{children}</div>
		</PrivateLayout>
	);
};

export default InfluencersLayout;
