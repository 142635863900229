import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { Form, Formik, FormikErrors, FormikValues } from "formik";
import Button from "components/base/button/button";
import isEmpty from "lodash/isEmpty";
import InputField from "components/base/input-field/input-field";
import * as Yup from "yup";
import { fieldStringValidtor, urlValidatorQuickSight } from "helpers/validator.helper";
import { MAX_LENGHT_NAME, MAX_LENGTH_URL } from "constants/widget";

interface WidgetFormProps {
	onSave: (values: IWidgetValues) => void;
	className?: string;
}

const cx = classNames.bind(styles);

export interface IWidgetValues {
	name: string;
	url: string;
}

const WidgetForm = (props: WidgetFormProps) => {
	const { onSave, className } = props;

	const initialValues: IWidgetValues = {
		name: "",
		url: "",
	};

	const WidgetSchema = Yup.object().shape({
		name: fieldStringValidtor(MAX_LENGHT_NAME),
		url: urlValidatorQuickSight(MAX_LENGTH_URL),
	});

	const handleSave =
		(
			validateForm: (values?: IWidgetValues) => Promise<FormikErrors<FormikValues>>,
			values: FormikValues,
			setErrors: (errors: FormikErrors<FormikValues>) => void
		) =>
		async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			e.preventDefault();
			const errors = await validateForm();
			isEmpty(errors) ? onSave(values as IWidgetValues) : setErrors(errors);
		};

	return (
		<div className={cx(styles.widget_form, className)}>
			<Formik initialValues={initialValues} validationSchema={WidgetSchema} onSubmit={onSave} validateOnChange={false}>
				{({ values, validateForm, setFieldValue, errors, setErrors }) => (
					<Form className={styles.widget_form__form}>
						<div className={styles.widget_form__fields_wrap}>
							<InputField
								inputType="text"
								label="Name"
								className={cx(styles.widget_form__form_field, styles.widget_form__name_field)}
								name="name"
								value={values.name}
								onChange={(e) => setFieldValue("name", e.target.value)}
								isError={Boolean(errors["name"])}
								errorText={errors["name"]}
							/>
							<InputField
								inputType="text"
								label="Url"
								className={cx(styles.widget_form__form_field, styles.widget_form__url_field)}
								name="url"
								value={values.url}
								onChange={(e) => setFieldValue("url", e.target.value)}
								isError={Boolean(errors["url"])}
								errorText={errors["url"]}
							/>
						</div>
						<Button
							onClick={handleSave(validateForm, values, setErrors)}
							className={styles.widget_form__btn_save}
							label="Save"
						/>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default WidgetForm;
