import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as CheckIcon } from "assets/icons/ic_player_check.svg";
import { IPlayerSettings } from "../player-control/player-control";

interface PlayerSpeedProps {
	isShow: boolean;
	speed: number;
	onSpeed?: (speed: number) => void;
	className?: string;
}

const PlayerSpeed = (props: PlayerSpeedProps) => {
	const { isShow, speed, onSpeed, className } = props;

	if (!isShow) return <></>;

	const gradeSpeed: IPlayerSettings[] = [
		{ value: 0.25, label: "x0.25" },
		{ value: 0.5, label: "x0.5" },
		{ value: 0.75, label: "x0.75" },
		{ value: 1, label: "x1" },
		{ value: 1.25, label: "x1.25" },
		{ value: 1.5, label: "x1.5" },
		{ value: 1.75, label: "x1.75" },
		{ value: 2, label: "x2" },
	];

	return (
		<div className={classNames(styles.player_speed, className)}>
			<div className={styles.player_speed__header}>Playback speed</div>
			<div className={styles.player_speed__body}>
				<ul className={styles.player_speed__list}>
					{gradeSpeed.map((item) => (
						<li className={styles.player_speed__item} key={item.value} onClick={() => onSpeed?.(item.value)}>
							{item.value === speed && <CheckIcon className={styles.player_speed__icon} />}
							<div className={styles.player_speed__label}>{item.label}</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default PlayerSpeed;
