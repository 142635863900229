import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { columns } from "./data";
import { usePagination, useTable } from "react-table";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import CmsTable from "components/partial/cms/cms-table/cms-table";
import { useAppStore } from "store";
import Carousel from "components/partial/carousel/carousel";
import CourseTile from "components/partial/course-tile/course-tile";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { observer } from "mobx-react";
import isNil from "lodash/isNil";
import { INoteModel } from "models/dto/ZoomiLxp/Models/ContentModels/INoteModel";
import NoteTableTile from "./note-table-tile/note-table-tile";
import CmsFooter from "components/partial/cms/cms-footer/cms-footer";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { initialCarouselPagingParams } from "helpers/params.helper";
import { TransitionSources } from "constants/constants";

const cx = classNames.bind(styles);

const MyNotesGrid = () => {
	const tableColumns = useMemo(() => columns, []);
	const [alertEventBus] = useState(() => createAlertEventBus());
	const [data, setData] = useState<INoteModel[]>([]);
	const { notesStore } = useAppStore();
	const [courseId, setCourseId] = useState<number>(0);

	const table = useTable<INoteModel>(
		{
			columns: tableColumns,
			data,
			initialState: { pageIndex: 0 },
			manualPagination: true,
			pageCount: 1,
		},
		usePagination
	);

	const tableParams: IPagingParams = useMemo(() => {
		return { take: table.state.pageSize, skip: 0 };
	}, [table.state.pageSize]);

	const params = useRef<IPagingParams>(initialCarouselPagingParams);
	const getData = useCallback(async () => {
		return await notesStore.getCoursesWithNotes(params.current);
	}, [notesStore]);
	const { items, hasMore, fetchMoreData } = useInfiniteScroll<IPreviewCourseModel>(getData, params);

	useEffect(() => {
		if (!isNil(items) && items.length) {
			setCourseId(items[0].id);
			notesStore.getNotesByCourseId(items[0]!.id, tableParams).then((response: INoteModel[]) => setData(response));
		}
	}, [notesStore, items, tableParams]);

	const handleClick = (courseId: number) => {
		setCourseId(courseId);
		notesStore.getNotesByCourseId(courseId, tableParams).then((response: INoteModel[]) => setData(response));
	};

	const getKey = (item: INoteModel) => item.courseItemId;

	return (
		<div className={styles.profile_notes}>
			<Alert eventBus={alertEventBus} />
			<LoadingIndicator
				loading={notesStore.isLoading}
				className={cx({ [styles.profile_notes__loading]: notesStore.isLoading })}
				spinnerPosition="center"
				backgroundStyle="blur"
			>
				{!isEmpty(items) && (
					<section className={styles.profile_notes__carousel}>
						<Carousel
							hasMore={hasMore}
							getMoreData={() => fetchMoreData()}
							items={items?.map((course: IPreviewCourseModel) => (
								<CourseTile
									courseInfo={course}
									className={styles.profile_notes__lesson_tile}
									disableRating={true}
									key={`${course.id}-${course.isBookmarked}`}
									onClick={() => handleClick(course.id)}
									isHilightDisabled={course.id === courseId}
									disableTitleForward={true}
									courseTileKey={TransitionSources.Notes}
								/>
							))}
						/>
					</section>
				)}

				<div className={styles.profile_notes__grid}>
					<CmsTable
						table={table}
						data={data}
						tileComponent={NoteTableTile}
						getKey={getKey}
						className={styles.profile_notes__table}
						disableRowCursor={true}
					/>
					<CmsFooter table={table} loading={notesStore.isLoading} hideControls={true} itemsCount={data.length} />
				</div>
			</LoadingIndicator>
		</div>
	);
};

export default observer(MyNotesGrid);
