import { GroupAssignmentType } from "helpers/groups.helper";
import { BulkOperationType } from "models/dto/ZoomiLxp/Utilities/Enumerations/BulkOperationType";
import { GroupAssignmentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/GroupAssignmentTypes";
import { useAppStore } from "store";

export const useGroupsAssignmentsList = () => {
	const { groupStore } = useAppStore();

	//TODO Think about how to do this with fewer checks.

	const checkIsGroupAlreadyInList = (grp: GroupAssignmentType) => {
		if (grp.type === GroupAssignmentTypes.Unassign) {
			return groupStore.addedGroupsAssignmentData.some((group) => group.groupId === grp?.groupId);
		}
		return groupStore.removedGroupsAssignmentData.some((group) => group.groupId === grp?.groupId);
	};

	const checkIsGroupAlreadyInBulkList = (grp: GroupAssignmentType, bulkOperationType: BulkOperationType) => {
		if (bulkOperationType === BulkOperationType.BulkGroupAssignment) {
			return groupStore.addedGroupsAssignmentData.some((group) => group.groupId === grp?.groupId);
		}
		if (bulkOperationType === BulkOperationType.BulkGroupUnAssignment) {
			return groupStore.removedGroupsAssignmentData.some((group) => group.groupId === grp?.groupId);
		}
		return false;
	};

	const handleGroupAssignment = (grp: GroupAssignmentType, bulkOperationType?: BulkOperationType) => {
		const isAlreadyInProfileList = checkIsGroupAlreadyInList(grp);

		if (isAlreadyInProfileList) {
			removeGroupFromList(grp);
		} else if (grp.type === GroupAssignmentTypes.Unassign) {
			groupStore.removedGroupsAssignmentData = [...groupStore.removedGroupsAssignmentData, grp];
		} else {
			groupStore.addedGroupsAssignmentData = [...groupStore.addedGroupsAssignmentData, grp];
		}
	};

	const removeGroupFromList = (grp: GroupAssignmentType) => {
		if (grp.type === GroupAssignmentTypes.Unassign) {
			groupStore.addedGroupsAssignmentData = groupStore.addedGroupsAssignmentData.filter(
				(group) => group.groupId !== grp.groupId
			);
		} else {
			groupStore.removedGroupsAssignmentData = groupStore.removedGroupsAssignmentData.filter(
				(group) => group.groupId !== grp.groupId
			);
		}
		groupStore.groupsAssignmentRequestData = groupStore.groupsAssignmentRequestData.filter(
			(group) => group.groupId !== grp.groupId
		);
	};

	const removeGroupFromBulkList = (grp: GroupAssignmentType, bulkOperationType: BulkOperationType) => {
		if (bulkOperationType === BulkOperationType.BulkGroupAssignment) {
			groupStore.addedGroupsAssignmentData = groupStore.addedGroupsAssignmentData.filter(
				(group) => group.groupId !== grp.groupId
			);
		} else if (bulkOperationType === BulkOperationType.BulkGroupUnAssignment) {
			groupStore.removedGroupsAssignmentData = groupStore.removedGroupsAssignmentData.filter(
				(group) => group.groupId !== grp.groupId
			);
		}
	};

	return { handleGroupAssignment, checkIsGroupAlreadyInBulkList, removeGroupFromBulkList };
};
