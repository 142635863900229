import styles from "./styles.module.scss";
import classNames from "classnames";
import noImage from "assets/images/no-image.png";
import { ColumnGroup, TableInstance } from "react-table";
import isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import CmsCheckBtn from "../cms-check-btn/cms-check-btn";
import CmsCheckbox from "../cms-checkbox/cms-checkbox";
import { Routes } from "routes";
import { useAppStore } from "store";
import { matchPath, useLocation } from "react-router";
import { SyntheticEvent } from "react";
import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { ConfirmationModal } from "components/partial/confirmation-popup/confirmation-popup";
import { ErrorMessage, promptsDelete } from "constants/messages";

export const Columns = (): ColumnGroup<IThreadModel>[] => {
	const { pathname } = useLocation();
	const { groupStore, searchStore } = useAppStore();
	const isGroupsRoute = matchPath(pathname, Routes.CmsGroupProfileTab);

	const onDeleteClick = async (e: SyntheticEvent, threadId: number) => {
		e.stopPropagation();
		await modalDialogService.show(ConfirmationModal, {
			title: promptsDelete.title,
			popupText: promptsDelete.text,
			confirmationAction: async () => {
				groupStore.isLoading = true;
				try {
					await groupStore.deleteGroupFromThread(threadId);
					searchStore.items = searchStore.items.filter(({ id }) => id !== threadId);
				} catch (e) {
					const error = (e as ErrorMessage)?.response?.data?.data?.errorMessage;
					alert(error ? error : e);
				} finally {
					groupStore.isLoading = false;
				}
			},
			confirmationButtonLabel: promptsDelete.confirmationButton,
			cancelButtonLabel: promptsDelete.cancelButton,
		});
	};

	if (isGroupsRoute) {
		return [
			{
				Header: "Threads",
				columns: [
					{
						Header: "Name",
						accessor: "title",
						Cell({ cell }) {
							const { thumbnail, title } = cell.row.original;
							return (
								<div className={styles.cms_threads__cell_name}>
									<div className={styles.cms_threads__cell_picture}>
										<img
											className={styles.cms_threads__cell_image}
											src={isEmpty(thumbnail?.url) ? noImage : thumbnail.url}
											alt={title}
										/>
									</div>
									<span className={styles.cms_threads__cell_title}>{title}</span>
								</div>
							);
						},
					},
					{
						Header: "Author",
						accessor: "author",
						Cell({ cell: { value } }) {
							return !isEmpty(value) ? (
								<>
									{value.firstName} {value.lastName}
								</>
							) : (
								<></>
							);
						},
					},
					{
						Header: "Tags",
						accessor: "tags",
						Cell({ cell: { value } }) {
							return !isEmpty(value) ? <>{value.map((item) => "#" + item.name).join(" ")}</> : <></>;
						},
					},
					{
						Header: "Last Update",
						accessor: "updated",
						Cell({ cell: { value } }) {
							return !isEmpty(value) ? (
								DateTime.fromISO(value).setLocale("en").toLocaleString(DateTime.DATE_MED)
							) : (
								<></>
							);
						},
					},

					{
						id: "id",
						Header: "",
						accessor: "id",
						Cell({ cell: { value } }) {
							return (
								<span className={styles.delete_icon} onClick={(e) => onDeleteClick(e, value)}>
									&#10005;
								</span>
							);
						},
					},
				],
			},
		];
	}

	return [
		{
			Header: "Threads",
			columns: [
				{
					id: "selection",
					Header: () => <CmsCheckBtn className={styles.cms_threads__checkbox} />,
					Cell: (state: TableInstance<IThreadModel>) => {
						const { id } = state.cell.row.original;
						return <CmsCheckbox className={styles.cms_threads__checkbox} id={id} />;
					},
				},
				{
					Header: "Name",
					accessor: "title",
					Cell({ cell }) {
						const { thumbnail, title } = cell.row.original;
						return (
							<div className={styles.cms_threads__cell_name}>
								<div className={styles.cms_threads__cell_picture}>
									<img
										className={styles.cms_threads__cell_image}
										src={isEmpty(thumbnail?.url) ? noImage : thumbnail.url}
										alt={title}
									/>
								</div>
								<span className={styles.cms_threads__cell_title}>{title}</span>
							</div>
						);
					},
				},
				{
					Header: "Author",
					accessor: "author",
					Cell({ cell: { value } }) {
						return !isEmpty(value) ? (
							<>
								{value.firstName} {value.lastName}
							</>
						) : (
							<></>
						);
					},
				},
				{
					Header: "Tags",
					accessor: "tags",
					Cell({ cell: { value } }) {
						return !isEmpty(value) ? <>{value.map((item) => "#" + item.name).join(" ")}</> : <></>;
					},
				},
				{
					Header: "Last Update",
					accessor: "updated",
					Cell({ cell: { value } }) {
						return !isEmpty(value) ? DateTime.fromISO(value).setLocale("en").toLocaleString(DateTime.DATE_MED) : <></>;
					},
				},

				{
					Header: "Status",
					accessor: "isActive",
					Cell({ cell: { value } }) {
						//--------------
						return (
							<span className={classNames(styles.cms_threads__status, { [styles.cms_threads__status_success]: value })}>
								{value ? "Active" : "InActive"}
							</span>
						);
					},
				},
			],
		},
	];
};
