import MyNotesGrid from "components/partial/myDesk/myDesk-notes/my-notes";
import MyAccountLayout from "layouts/my-account/my-account-layout";

const MyNotesPage = () => {
	return (
		<MyAccountLayout>
			<MyNotesGrid />
		</MyAccountLayout>
	);
};

export default MyNotesPage;
