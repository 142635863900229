import { makeAutoObservable, runInAction } from "mobx";
import { ICategoryModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ICategoryModel";
import { find, isNaN, isNil, sortBy, sumBy } from "lodash";
import { Emitter } from "mitt";
import { AlertEventTypes } from "components/base/alert/alert";
import { GoalsApi } from "api/controllers/GoalsApi";
import { IGoalModel } from "models/dto/ZoomiLxp/Models/Goals/IGoalModel";
import { ICreateGoalModel } from "models/dto/ZoomiLxp/Models/Goals/ICreateGoalModel";
import { IUpdateGoalModel } from "models/dto/ZoomiLxp/Models/Goals/IUpdateGoalModel";
import { IGoalAchievementModel } from "models/dto/ZoomiLxp/Models/Goals/IGoalAchievementModel";
import { TaxonomyApi } from "api/controllers/TaxonomyApi";
import { GRAY, RED } from "constants/colors";
import { newShade } from "helpers/color.helper";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import { IFilterCriterion } from "models/dto/ZoomiLxp/Models/Query/IFilterCriterion";
import { CoursesApi } from "api/controllers/CoursesApi";
import { AppStore } from "../index";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { UsersApi } from "api/controllers/UsersApi";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { IOptionsItem } from "helpers/select.helper";
import { initialCarouselPagingParams } from "helpers/params.helper";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { initialAssignments } from "store/courses/courses-store-helper";

type ValueItem = { name: string; value: number; highValue?: number; original?: number };
type CourseItem = { id: number; name: string; courses: IPreviewCourseModel[]; totalFilteredRecords: number };

export class GoalsStore {
	private _isLoading: boolean = false;
	private rootStore;
	alertEventBus: Emitter<AlertEventTypes> | undefined;
	private _categories: ICategoryModel[] = [];
	private _categoriesOptions: IOptionsItem[] = [];
	private _goals: IGoalModel[] = [];
	private _achievements: IGoalAchievementModel[] = [];
	private _dataPie: ValueItem[] = [];
	private _colorPie: string[] = [];
	private _dataBar: ValueItem[] = [];
	private _colorBar: string[] = [];
	private _highColorBar: string[] = [];
	private _coursesForJobTitle: IGetRecordsResponse<IPreviewCourseModel> = initialAssignments;
	private _courses: CourseItem[] = [];
	private _accountId: number = 0;

	constructor(rootStore: AppStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	set isLoading(value: boolean) {
		this._isLoading = value;
	}

	async getInfo(userId: number) {
		this.isLoading = true;
		const isCurrentUser = this.rootStore.usersStore.currentUserInfo?.id === userId;
		try {
			await Promise.all([
				this.getCategories(),
				this.getGoals(userId),
				this.getAchievements(userId),
				this.getCoursesForJobTitle(userId),
			]);
			if (isCurrentUser) {
				this._accountId = userId;
			}
			this.setDataPie();
			this.setDataBar();
			await this.getCourses();
		} finally {
			this.isLoading = false;
		}
	}

	private async getCategories() {
		const response = await TaxonomyApi.getAllCategories();
		runInAction(() => {
			this._categories = response.data.data.categories;
			this._categoriesOptions = sortBy(response.data.data.categories, ["name"]).map((category) => ({
				value: category.id.toString(),
				label: category.name,
			}));
		});
	}

	private async getAchievements(userId?: number) {
		const isCurrentUser = userId === this.rootStore.usersStore.currentUserInfo?.id;
		const response =
			userId && !isCurrentUser ? await GoalsApi.getUserAchievements(userId) : await GoalsApi.myAchievements();
		runInAction(() => {
			this._achievements = sortBy(response.data.data, ["category.name"]);
		});
	}

	get categories(): ICategoryModel[] {
		return this._categories;
	}

	get categoriesOptions(): IOptionsItem[] {
		return this._categoriesOptions;
	}

	getCategoryById(id: number) {
		return find(this._categories, { id });
	}

	get goals(): IGoalModel[] {
		return this._goals;
	}

	private async getGoals(userId?: number) {
		const isCurrentUser = userId && userId === this.rootStore.usersStore.currentUserInfo?.id;
		const resp = userId && !isCurrentUser ? await GoalsApi.getUserGoals(userId) : await GoalsApi.myGoals();
		runInAction(() => {
			this._goals = sortBy(resp.data.data, ["category.name"]);
		});
	}

	async addGoal(data: ICreateGoalModel, userId?: number) {
		this.isLoading = true;

		try {
			userId ? await GoalsApi.createGoalForUser(data, userId) : await GoalsApi.createGoal(data);
			await this.updateData(userId);
			await this.updateCourses(userId);
		} finally {
			this.isLoading = false;
		}
	}

	async updateGoal(goalId: number, data: IUpdateGoalModel, userId?: number) {
		this.isLoading = true;

		try {
			await GoalsApi.updateGoal(goalId, data);
			await this.updateData(userId);
			await this.updateCourses(userId);
		} finally {
			this.isLoading = false;
		}
	}

	async deleteGoal(goalId: number, userId?: number) {
		this.isLoading = true;

		try {
			await GoalsApi.deleteGoal(goalId);
			await this.updateData(userId);
			await this.updateCourses(userId);
		} finally {
			this.isLoading = false;
		}
	}

	get goalsCount(): number {
		return this._goals?.length ?? 0;
	}

	get goalsSumWeight(): number {
		return sumBy(this._goals, "weight");
	}

	get achievements(): IGoalAchievementModel[] {
		return this._achievements;
	}

	private setDataPie() {
		if (this.goalsCount > 0) {
			this._dataPie = this._goals.map((goal) => ({ name: goal.category?.name ?? "", value: goal?.weight ?? 0 }));
			this._colorPie = this._goals.map((goal) => goal.category.color ?? RED);

			const delta = 100 - this.goalsSumWeight;
			if (delta > 0) {
				this._dataPie.push({ name: "Free Time", value: delta });
				this._colorPie.push(GRAY);
			}
		} else {
			this._dataPie = [];
			this._colorPie = [];
		}
	}

	get dataPie(): ValueItem[] {
		return this._dataPie;
	}

	get colorPie(): string[] {
		return this._colorPie;
	}

	private setDataBar() {
		if (this._achievements.length > 0) {
			this._dataBar = this._achievements.map((achievement) => ({
				name: achievement.category?.name ?? "",
				value: achievement?.percent > 100 ? 100 : Math.round(achievement?.percent),
				highValue: achievement?.percent > 100 ? 40 : 0,
				original: Math.round(achievement?.percent),
			}));
			this._colorBar = this._achievements.map((achievement) => achievement.category.color ?? RED);
			this._highColorBar = this._colorBar.map((color) => newShade(color, 40));
		} else {
			this._dataBar = [];
			this._colorBar = [];
			this._highColorBar = [];
		}
	}

	get dataBar(): ValueItem[] {
		return this._dataBar;
	}

	get colorBar(): string[] {
		return this._colorBar;
	}

	get highColorBar(): string[] {
		return this._highColorBar;
	}

	getDataBarById(id: number) {
		return find(this._dataBar, { id });
	}

	get coursesForJobTitle(): IGetRecordsResponse<IPreviewCourseModel> {
		return this._coursesForJobTitle;
	}

	private async getCoursesForJobTitle(userId: number, data?: IPagingParams) {
		const response = await UsersApi.getCoursesForJobTitle(userId, data ?? initialCarouselPagingParams);
		runInAction(() => {
			this._coursesForJobTitle = {
				records: response.data.data.records,
				totalFilteredRecords: response.data.data.totalFilteredRecords,
			};
		});
	}

	async getMoreCoursesForJobTitle(userId: number, skip: number) {
		this.isLoading = true;
		const response = await UsersApi.getCoursesForJobTitle(userId, { ...initialCarouselPagingParams, skip });
		runInAction(() => {
			this._coursesForJobTitle = {
				...this._coursesForJobTitle,
				records: [...this._coursesForJobTitle.records, ...response.data.data.records],
			};
			this.isLoading = false;
		});
	}

	private static getCoursesById(id: number, skip = 0, take = 10) {
		const query: IQueryParams = {
			queryString: "",
			take,
			skip,
			filterCriteria: [
				{
					propertyNames: ["isActive"],
					function: FilterFunction.IsTrue,
					argument: true,
				} as IFilterCriterion,
				{
					propertyNames: ["categoryId"],
					function: FilterFunction.Equal,
					argument: id,
				} as IFilterCriterion,
			],
			sortCriteria: [
				{
					order: 1,
					propertyName: "title",
					direction: 0,
				},
			],
		};
		return CoursesApi.searchCourses(query);
	}

	private async updateData(userId?: number) {
		await Promise.all([this.getGoals(userId), this.getAchievements(userId)]);
		this.setDataPie();
		this.setDataBar();
	}

	private async getCourses() {
		let courses: CourseItem[] = this._goals.map((goal) => ({
			id: goal.category.id,
			name: goal.category.name,
			courses: [],
			totalFilteredRecords: 0,
		}));
		const promises = courses.map((course) => GoalsStore.getCoursesById(course.id));
		const response = await Promise.all(promises);
		const records = response.map((record) => ({
			courses: record.data.data.records,
			totalFilteredRecords: record.data.data.totalFilteredRecords,
		}));

		courses = courses.map((course, idx) => ({
			...course,
			courses: records[idx].courses,
			totalFilteredRecords: records[idx].totalFilteredRecords,
		}));
		runInAction(() => {
			this._courses = courses;
		});
	}

	async getMoreCourses(id: number, skip: number) {
		this.isLoading = true;
		const response = await GoalsStore.getCoursesById(id, skip);
		const courses = this._courses.map((course) => {
			if (course.id === id) {
				return {
					...course,
					courses: [...course.courses, ...response.data.data.records],
				};
			}
			return course;
		});
		runInAction(() => {
			this._courses = courses;
			this.isLoading = false;
		});
	}

	get courses(): CourseItem[] {
		return this._courses;
	}

	private async updateCourses(userId?: number) {
		const isOtherUser = !isNil(userId) && !isNaN(userId);
		await Promise.allSettled([this.getCourses(), this.getCoursesForJobTitle(isOtherUser ? userId : this._accountId)]);
	}
}
