import { makeAutoObservable, runInAction } from "mobx";
import { CompaniesApi } from "api/controllers/CompaniesApi";
import { ICompanyModel } from "models/dto/ZoomiLxp/Models/CompanyModels/ICompanyModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";

export class CompaniesStore {
	private _isLoading: boolean = false;
	private _companies: ICompanyModel[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	async addNewCompany(data: ICompanyModel) {
		this.isLoading = true;
		try {
			const response = await CompaniesApi.addNewCompany(data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getCompanyById(id: number) {
		this.isLoading = true;
		try {
			const response = await CompaniesApi.getCompanyById(id);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async updateCompany(data: ICompanyModel) {
		this.isLoading = true;
		try {
			const response = await CompaniesApi.updateCompany(data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getAll(data: IQueryParams) {
		this.isLoading = true;
		try {
			const response = await CompaniesApi.getAllCompanies(data);
			runInAction(() => {
				this._companies = response.data.data.records;
			});
			return response.data.data.records;
		} finally {
			this.isLoading = false;
		}
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get isLoading() {
		return this._isLoading;
	}

	get companies(): ICompanyModel[] {
		return this._companies;
	}
}
