import { useState } from "react";

import classNames from "classnames";
import isNil from "lodash/isNil";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { ISaveEventCourseHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseHistoryRequestModel";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";

import styles from "./styles.module.scss";
import { useAppStore } from "store";
import ButtonBookmark from "components/partial/button-bookmark/button-bookmark";
import StarRating from "components/partial/star-rating/star-rating";
import { getPicture } from "helpers/image.helper";
import { DEFAULT_COLOR } from "constants/colors";
import { getIconContentTypes } from "helpers/icons.helper";
import { TilePlacement } from "../peers/peer-tile/peer-tile";

interface Props {
	courseInfo: IPreviewCourseModel;
	tileStyle?: "default" | "light";
	className?: string;
	disableRating?: boolean;
	isHilightDisabled?: boolean;
	disableTitleForward?: boolean;
}

const CourseTileCompact = ({
	courseInfo,
	tileStyle = TilePlacement.default,
	className,
	disableRating,
	isHilightDisabled = true,
	disableTitleForward,
}: Props) => {
	const [rating, setRating] = useState<number>(courseInfo.rating);
	const { coursesStore } = useAppStore();
	const [isBookmarked, setIsBookmarked] = useState<boolean>(() => courseInfo.isBookmarked);
	const isStringSubject = typeof courseInfo.subject === "string";
	const isLight = tileStyle === "light";

	const handleBookmark = async (isMarked: boolean) => {
		const bookmarkCourseEvent: ISaveEventCourseHistoryRequestModel = {
			courseId: courseInfo.id,
			eventType: isMarked ? EventTypes.CourseBookmarked : EventTypes.CourseUnbookmarked,
			payload: "",
		};
		await coursesStore.sendCourseEvent(bookmarkCourseEvent).then(() => {
			coursesStore.getBookmarkedCourses(undefined, true);
		});
	};

	const getIconStyles = (isNestedIcon?: boolean) =>
		isNestedIcon
			? classNames(styles.nested_icon, {
					[styles.nested_icon_dark]: isLight,
			  })
			: classNames(styles.course_tile__type_icon, {
					[styles.course_tile__type_icon__dark]: isLight,
			  });

	const getCourseIconStyles = () =>
		classNames(styles.course_tile__type_icon, styles.course_tile__course_icon, {
			[styles.course_tile__type_icon__dark]: isLight,
			[styles.course_tile__course_icon__dark]: isLight,
		});

	const getIcon = getIconContentTypes(getIconStyles, getCourseIconStyles);

	return (
		<div
			className={classNames(
				styles.course_tile,
				{ [styles.course_tile__light]: isLight },
				className,
				styles.course_tile__compact,
				{ [styles.course_tile__not_active]: !isHilightDisabled }
			)}
		>
			<div className={classNames(styles.course_tile__header, styles.course_tile__header)}>
				<div className={styles.course_tile__duration_block}>
					{getIcon(courseInfo.contentType)}
					<span className={styles.course_tile__duration}>{courseInfo.durationMinutes} min</span>
				</div>
				<div className={styles.course_tile__rating_block}>
					<StarRating
						className={styles.course_tile__rating}
						value={rating}
						isDisabled={disableRating}
						onRatingChange={(newRating) => {
							setRating(newRating);
						}}
						darkBorder={isLight}
					/>
					<ButtonBookmark
						className={styles.course_tile__bookmark}
						bookmarked={isBookmarked}
						isBookmarkable={true}
						isSecondaryType={isLight}
						onClick={() => {
							setIsBookmarked(!isBookmarked);
							handleBookmark(!isBookmarked).catch((err) => console.log(err));
						}}
					/>
				</div>
			</div>
			<div className={classNames(styles.course_tile__content, styles.course_tile__content__compact)}>
				<img
					src={getPicture(courseInfo)}
					alt="Thumbnail"
					className={classNames(
						styles.course_tile__content__thumbnail,
						styles.course_tile__content__thumbnail__compact,
						{
							[styles.no_image]: isNil(courseInfo.thumbnail),
						}
					)}
				/>
			</div>
			<div className={classNames(styles.course_tile__info_container__compact)}>
				<div className={classNames(styles.course_tile__lesson_name_container)}>
					<div
						className={classNames(styles.course_tile__lesson_name, styles.course_tile__lesson_name__compact, {
							[styles.course_tile__lesson_name__dark]: isLight,
							[styles.default_cursor]: disableTitleForward,
						})}
					>
						{courseInfo.title}
					</div>

					<div className={classNames(styles.course_tile__wrap)}>
						<div
							style={{ backgroundColor: courseInfo.categoryColor ?? DEFAULT_COLOR }}
							className={classNames(styles.course_tile__color_bar, styles.course_tile__color_bar__compact)}
						/>
						<div className={styles.course_tile__content_info_section__compact}>
							<div className={styles.course_tile__info_box}>
								<p className={styles.course_tile__content_info}>
									<b>Source · </b> {courseInfo!.source}
								</p>

								<p className={styles.course_tile__content_info}>
									<b>Subject · </b> {isStringSubject ? courseInfo!.subject : "none"}
								</p>

								<p className={styles.course_tile__content_info}>
									<b>Course · </b> {courseInfo!.courseTitle ?? "none"}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default CourseTileCompact;
