import { ICompanyModel } from "models/dto/ZoomiLxp/Models/CompanyModels/ICompanyModel";
import isEmpty from "lodash/isEmpty";
import { EmailAddressModelType } from "models/dto/ZoomiLxp/Utilities/Enumerations/EmailAddressModelType";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { findIndex } from "lodash";
import update from "immutability-helper";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { IRoleModel } from "models/dto/ZoomiLxp/Models/Profile/IRoleModel";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import { IBaseModel } from "models/dto/ZoomiLxp/Models/Common/IBaseModel";
import { IOptionsItem } from "helpers/select.helper";
import { SelectionAccountsType } from "models/dto/ZoomiLxp/Utilities/Enumerations/SelectionAccountsType";
import { IAssignmentAccountSelectionModel } from "models/dto/ZoomiLxp/Models/BulkOperations/AssignmentBulkOperation/IAssignmentAccountSelectionModel";

export const getOptions = (
	records: ICompanyModel[] | IProfileDataModel[] | IRoleModel[] | IBaseModel[],
	field: string,
	name: string,
	type: EmailAddressModelType | SelectionAccountsType,
	isBulkOperation?: boolean
): IOptionsItem[] => {
	const options: IOptionsItem[] = [];

	if (!isEmpty(records)) {
		records.forEach((item) => {
			options.push({
				value:
					type === EmailAddressModelType.Email && !isBulkOperation
						? ("email" in item ? item.email : "") + "|" + type
						: String(item.id) + "|" + type,
				// @ts-ignore
				label: item[field] + " | " + name,
			});
		});
	}
	return options;
};

export const getAssignmentSelectionObject = (item: IOptionsItem): IAssignmentAccountSelectionModel => {
	let selectionValue: string = "";
	let selectionType: string = "";
	if (typeof item.value === "string") {
		const stringValues = item.value.split("|");
		selectionValue = stringValues[0];
		selectionType = stringValues[1];
	}
	return {
		value: Number(selectionValue),
		selectionType: Number(selectionType),
	};
};

export const changeName = (params: IQueryParams, name: string): IQueryParams => {
	const idx = findIndex(params.filterCriteria, {
		propertyNames: ["name"],
	});
	if (idx !== -1) {
		const filterItem = params.filterCriteria[idx];
		const updatedFilterItem = update(filterItem, { propertyNames: { $set: [name] } });
		return update(params, {
			filterCriteria: {
				$splice: [[idx, 1, updatedFilterItem]],
			},
		});
	}
	return params;
};

export const getQueryString = (params: IQueryParams): string => {
	const idx = findIndex(params.filterCriteria, {
		propertyNames: ["name"],
	});
	if (idx !== -1) {
		const filterItem = params.filterCriteria[idx];
		return filterItem.argument;
	}
	return "";
};

export const initParams = {
	skip: 0,
	take: 100,
	filterCriteria: [
		{
			propertyNames: ["isActive"],
			function: FilterFunction.IsTrue,
			argument: true,
		},
	],
	sortCriteria: [],
	queryString: "",
};
