import { makeAutoObservable, runInAction } from "mobx";
import { HistoryApi } from "api/controllers/HistoryApi";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { PeersApi } from "api/controllers/PeersApi";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { initialCarouselPagingParams } from "helpers/params.helper";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { CarouselDataType } from "components/partial/carousels-array/carousel-data-provider";
import { defaultCarouselRecordsParams, getParams, getUpdatedParams } from "helpers/carousel.helper";

export class HistoryStore {
	private _isLoading: boolean = false;
	followingInfluencers: IInfluencerDataModel[] = [];
	watchingHistory: IPreviewCourseModel[] = [];
	continueWatching: IPreviewCourseModel[] = [];
	bookmarkedCourses: IPreviewCourseModel[] = [];
	coursesCreatedByYou: IPreviewCourseModel[] = [];
	lastSavedThreads: IThreadModel[] = [];
	myCreatedThreads: IThreadModel[] = [];

	private _loadingGroup: {
		[key: string]: boolean;
	} = {
		followingInfluencers: false,
		watchingHistory: false,
		continueWatching: false,
		bookmarkedCourses: false,
		coursesCreatedByYou: false,
		lastSavedThreads: false,
		myCreatedThreads: false,
	};

	private _historyCarouselsPagingParams: {
		[key: string]: IGetRecordsResponse<CarouselDataType>;
	} = {
		followingInfluencers: defaultCarouselRecordsParams,
		watchingHistory: defaultCarouselRecordsParams,
		continueWatching: defaultCarouselRecordsParams,
		bookmarkedCourses: defaultCarouselRecordsParams,
		coursesCreatedByYou: defaultCarouselRecordsParams,
		lastSavedThreads: defaultCarouselRecordsParams,
		myCreatedThreads: defaultCarouselRecordsParams,
	};

	constructor() {
		makeAutoObservable(this);
	}

	async getAllHistory(userId?: number) {
		this.isLoading = true;
		try {
			await Promise.allSettled([
				this.getFollowingInfluencers(initialCarouselPagingParams, userId),
				this.getBookmarkedCourses(initialCarouselPagingParams, userId),
				this.getContinueWatching(initialCarouselPagingParams, userId),
				this.getCoursesCreatedByYou(initialCarouselPagingParams, userId),
				this.getGetWatchingHistory(initialCarouselPagingParams, userId),
				this.getLastSavedThreads(initialCarouselPagingParams, userId),
				this.getThreadsCreatedByUser(initialCarouselPagingParams, userId),
			]);
		} finally {
			this.isLoading = false;
		}
	}

	async getFollowingInfluencers(data?: IPagingParams, userId?: number) {
		this._loadingGroup = { ...this._loadingGroup, followingInfluencers: true };

		try {
			const followingInfluencersResponse = userId
				? await HistoryApi.getUserFollowingInfluencers(userId, data ?? initialCarouselPagingParams)
				: await HistoryApi.getFollowingInfluencers(data ?? initialCarouselPagingParams);
			const newParams = getParams(followingInfluencersResponse.data.data);
			const oldParams = this._historyCarouselsPagingParams.followingInfluencers;
			runInAction(() => {
				this.followingInfluencers = followingInfluencersResponse.data.data.records;
				this._historyCarouselsPagingParams = {
					...this._historyCarouselsPagingParams,
					followingInfluencers: getUpdatedParams(newParams, oldParams),
				};
			});
			return followingInfluencersResponse.data.data;
		} finally {
			this._loadingGroup = { ...this._loadingGroup, followingInfluencers: false };
		}
	}

	async getBookmarkedCourses(data?: IPagingParams, userId?: number) {
		this._loadingGroup = { ...this._loadingGroup, bookmarkedCourses: true };
		try {
			const bookmarkedCoursesResponse = userId
				? await HistoryApi.getUserBookmarkedCourses(userId, data ?? initialCarouselPagingParams)
				: await HistoryApi.getBookmarkedCourses(data ?? initialCarouselPagingParams);
			const newParams = getParams(bookmarkedCoursesResponse.data.data);
			const oldParams = this._historyCarouselsPagingParams.bookmarkedCourses;
			runInAction(() => {
				this.bookmarkedCourses = bookmarkedCoursesResponse.data.data.records;
				this._historyCarouselsPagingParams = {
					...this._historyCarouselsPagingParams,
					bookmarkedCourses: getUpdatedParams(newParams, oldParams),
				};
			});
			return bookmarkedCoursesResponse.data.data;
		} finally {
			this._loadingGroup = { ...this._loadingGroup, bookmarkedCourses: false };
		}
	}

	async getContinueWatching(data?: IPagingParams, userId?: number) {
		this._loadingGroup = { ...this._loadingGroup, continueWatching: true };
		try {
			const continueWatchingResponse = userId
				? await HistoryApi.getUserContinueWatching(userId, data ?? initialCarouselPagingParams)
				: await HistoryApi.getContinueWatching(data ?? initialCarouselPagingParams);
			const newParams = getParams(continueWatchingResponse.data.data);
			const oldParams = this._historyCarouselsPagingParams.continueWatching;
			runInAction(() => {
				this.continueWatching = continueWatchingResponse.data.data.records;
				this._historyCarouselsPagingParams = {
					...this._historyCarouselsPagingParams,
					continueWatching: getUpdatedParams(newParams, oldParams),
				};
			});
			return continueWatchingResponse.data.data;
		} finally {
			this._loadingGroup = { ...this._loadingGroup, continueWatching: false };
		}
	}

	async getCoursesCreatedByYou(data?: IPagingParams, userId?: number) {
		this._loadingGroup = { ...this._loadingGroup, coursesCreatedByYou: true };
		try {
			const coursesCreatedByYouResponse = userId
				? await HistoryApi.getCoursesCreatedByUser(userId, data ?? initialCarouselPagingParams)
				: await HistoryApi.getCoursesCreatedByYou(data ?? initialCarouselPagingParams);
			const newParams = getParams(coursesCreatedByYouResponse.data.data);
			const oldParams = this._historyCarouselsPagingParams.coursesCreatedByYou;
			runInAction(() => {
				this.coursesCreatedByYou = coursesCreatedByYouResponse.data.data.records;
				this._historyCarouselsPagingParams = {
					...this._historyCarouselsPagingParams,
					coursesCreatedByYou: getUpdatedParams(newParams, oldParams),
				};
			});
			return coursesCreatedByYouResponse.data.data;
		} finally {
			this._loadingGroup = { ...this._loadingGroup, coursesCreatedByYou: false };
		}
	}

	async getGetWatchingHistory(data?: IPagingParams, userId?: number) {
		this._loadingGroup = { ...this._loadingGroup, watchingHistory: true };
		try {
			const watchingHistoryResponse = userId
				? await HistoryApi.getUserWatchingHistory(userId, data ?? initialCarouselPagingParams)
				: await HistoryApi.getWatchingHistory(data ?? initialCarouselPagingParams);
			const newParams = getParams(watchingHistoryResponse.data.data);
			const oldParams = this._historyCarouselsPagingParams.watchingHistory;
			runInAction(() => {
				this.watchingHistory = watchingHistoryResponse.data.data.records;
				this._historyCarouselsPagingParams = {
					...this._historyCarouselsPagingParams,
					watchingHistory: getUpdatedParams(newParams, oldParams),
				};
			});
			return watchingHistoryResponse.data.data;
		} finally {
			this._loadingGroup = { ...this._loadingGroup, watchingHistory: false };
		}
	}

	async getLastSavedThreads(data?: IPagingParams, userId?: number) {
		this._loadingGroup = { ...this._loadingGroup, lastSavedThreads: true };

		try {
			const lastSavedThreadsResponse = userId
				? await PeersApi.lastSavedThreadsByUserId(userId, data ?? initialCarouselPagingParams)
				: await PeersApi.lastSavedThreads(data ?? initialCarouselPagingParams);
			const newParams = getParams(lastSavedThreadsResponse.data.data);
			const oldParams = this._historyCarouselsPagingParams.lastSavedThreads;
			runInAction(() => {
				this.lastSavedThreads = lastSavedThreadsResponse.data.data.records;
				this._historyCarouselsPagingParams = {
					...this._historyCarouselsPagingParams,
					lastSavedThreads: getUpdatedParams(newParams, oldParams),
				};
			});
			return lastSavedThreadsResponse.data.data;
		} finally {
			this._loadingGroup = { ...this._loadingGroup, lastSavedThreads: false };
		}
	}

	async getThreadsCreatedByUser(data?: IPagingParams, userId?: number) {
		this._loadingGroup = { ...this._loadingGroup, myCreatedThreads: true };

		try {
			const threadsCreatedByYouResponse = userId
				? await PeersApi.createdByUserThreads(userId, data ?? initialCarouselPagingParams)
				: await PeersApi.createdByYouThreads(data ?? initialCarouselPagingParams);
			const newParams = getParams(threadsCreatedByYouResponse.data.data);
			const oldParams = this._historyCarouselsPagingParams.myCreatedThreads;
			runInAction(() => {
				this.myCreatedThreads = threadsCreatedByYouResponse.data.data.records;
				this._historyCarouselsPagingParams = {
					...this._historyCarouselsPagingParams,
					myCreatedThreads: getUpdatedParams(newParams, oldParams),
				};
			});
			return threadsCreatedByYouResponse.data.data;
		} finally {
			this._loadingGroup = { ...this._loadingGroup, myCreatedThreads: false };
		}
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get isLoading() {
		return this._isLoading;
	}

	get loadingGroup(): {
		[key: string]: boolean;
	} {
		return this._loadingGroup;
	}

	get historyCarouselsPagingParams() {
		return this._historyCarouselsPagingParams;
	}

	clearStore() {
		runInAction(() => {
			this.isLoading = false;
			this.followingInfluencers = [];
			this.watchingHistory = [];
			this.continueWatching = [];
			this.bookmarkedCourses = [];
			this.coursesCreatedByYou = [];
			this.lastSavedThreads = [];
			this.myCreatedThreads = [];
			this._loadingGroup = {
				mostLiked: false,
				mostFollowed: false,
				shouldBeFollow: false,
				myInfluencers: false,
				moversAndShakers: false,
			};
			this._historyCarouselsPagingParams = {
				mostLiked: defaultCarouselRecordsParams,
				mostFollowed: defaultCarouselRecordsParams,
				shouldBeFollow: defaultCarouselRecordsParams,
				myInfluencers: defaultCarouselRecordsParams,
				moversAndShakers: defaultCarouselRecordsParams,
			};
		});
	}
}
