import React, { useCallback, useEffect, useState } from "react";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { isNil, isUndefined } from "lodash";
import { useAppStore } from "store";

type ParamsType = IQueryParams | IPagingParams;

export function useInfiniteScroll<T>(
	getData: () => Promise<IGetRecordsResponse<T> | null | undefined>,
	params: React.MutableRefObject<ParamsType>,
	reload?: boolean,
	externalData?: IGetRecordsResponse<T>,
	invertItemsConcatination?: boolean
) {
	const [items, setItems] = useState<T[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [total, setTotal] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const { toasterStore } = useAppStore();

	const getInitalData = useCallback(() => {
		setIsLoading(true);
		if (!isNil(externalData)) {
			setTotal(externalData.totalFilteredRecords);
			setItems(externalData.records);
			setHasMore(externalData.records.length < externalData.totalFilteredRecords);
			setIsLoading(false);
		} else {
			getData()
				.then((response: IGetRecordsResponse<T> | null | undefined) => {
					if (!isNil(response)) {
						setTotal(response.totalFilteredRecords);
						setItems(response.records);
						setHasMore(response.records.length < response.totalFilteredRecords);
					}
				})
				.catch((err: Error) => toasterStore.showErrorMessage(err))
				.finally(() => setIsLoading(false));
		}
	}, [externalData, toasterStore, getData]);

	useEffect(() => {
		if (isUndefined(reload)) getInitalData();
	}, [getInitalData, reload]);

	useEffect(() => {
		if (reload) {
			setItems([]);
			setTotal(0);
			setHasMore(true);
			params.current = { ...params.current, skip: 0 };
			getInitalData();
		}
	}, [getInitalData, params, reload]);

	const fetchMoreData = () => {
		if (items.length >= total) {
			setHasMore(false);
			return;
		}
		params.current = { ...params.current, skip: (params.current?.skip ?? 0) + (params.current?.take ?? 0) };

		setIsLoading(true);
		getData()
			.then((response: IGetRecordsResponse<T> | null | undefined) => {
				if (!isNil(response)) {
					const itemsLength = response.records.length;
					if (itemsLength > 0 && isNil(externalData)) {
						setItems((prev) =>
							invertItemsConcatination ? response.records.concat(prev) : prev.concat(response.records)
						);
					} else if (!itemsLength) {
						setHasMore(false);
					}
				}
				setIsLoading(false);
			})
			.catch((err: Error) => toasterStore.showErrorMessage(err));
	};

	return { items, hasMore, fetchMoreData, isLoading, setItems, total };
}
