//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SelectionAccountsType {
	AccountId = 1,
	Role = 2,
	Company = 3,
	Group = 4
}
