import capitalize from "lodash/capitalize";
import escape from "lodash/escape";
import isNull from "lodash/isNull";
import { IThreadRefModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadRefModel";
import { urlRegex } from "./validator.helper";

export const getFirstLetter = (str: string): string => {
	return (str && str.length && str[0]) || "";
};

export const getAvatarTitleFromDisplayName = (displayName: string): string => {
	if (displayName && displayName.length) {
		const words = displayName.split(" ");
		return getAvatarTitle(words[0], words[1]);
	}
	return "";
};

export function getAvatarTitle(firstName: string, lastName: string): string {
	return getFirstLetter(firstName) + getFirstLetter(lastName);
}

export function getShortName(firstName: string, lastName: string): string {
	return `${firstName} ${getFirstLetter(lastName)}.`;
}

export function getUserFullName(firstName: string, lastName: string, options?: { capitalize?: boolean }): string {
	return `${options?.capitalize ? capitalize(firstName) : firstName} ${
		options?.capitalize ? capitalize(lastName) : lastName
	}`;
}

export const getTextWithLinks = (text: string) => {
	const splittedText = text.split(urlRegex);
	const formattedText = splittedText.map((textPart, index) => {
		if (textPart && textPart.match(urlRegex)) {
			return (
				<a key={index} href={textPart} target="_blank" rel="noopener noreferrer">
					{textPart}
				</a>
			);
		} else {
			return <span key={index}>{textPart}</span>;
		}
	});
	return formattedText;
};

export function onlyLettersAndPunctuation(str: string): boolean {
	return /^[A-Za-z0-9\u00B0\u00BA\u00B5\u00C0-\u00FF _.,'`"?!():#@-]*$/.test(str);
}

export const getFormattedProgress = (value: number): string => String(value);

export const getHTML = (name: string) => {
	const escapeName = escape(name);
	return escapeName.replaceAll(/(?:\r\n|\r|\n)/g, "<br/>");
};

export const getThreadTitle = (thread?: IThreadRefModel) =>
	isNull(thread?.discussionName) ? thread?.title ?? "" : (thread?.title ?? "") + " | " + (thread?.discussionName ?? "");

export const clearHtmlTags = (str: string): string => {
	return str.replace(/<[^>]+>/g, "");
};
