import { observer } from "mobx-react";
import styles from "./styles.module.scss";
import CourseTile from "components/partial/course-tile/course-tile";
import Carousel from "components/partial/carousel/carousel";
import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useAppStore } from "store";
import PrivateLayout from "layouts/private/private";
import CommonHeader from "components/partial/common-header/common-header";
import { isNil } from "lodash";
import InfluencerTile from "components/partial/influencer/influencer-tile/influencer-tile";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import isEmpty from "lodash/isEmpty";
import { TransitionSources } from "constants/constants";

export const SearchResultPage = observer(function SearchResultPage() {
	const match = useRouteMatch<{ searchString: string }>();
	const searchRequestString = String(match.params.searchString);
	const { coursesStore, influencersStore, peersStore } = useAppStore();
	useEffect(() => {
		const initParams: IQueryParams = {
			skip: 0,
			take: 100,
			filterCriteria: [],
			sortCriteria: [],
			queryString: searchRequestString,
		};

		const coursesSearchParams: IQueryParams = {
			...initParams,

			filterCriteria: [
				{
					propertyNames: ["isActive"],
					function: FilterFunction.IsTrue,
					argument: true,
				},
			],
		};
		if (searchRequestString) {
			coursesStore.getAllCoursesByQuery(coursesSearchParams);
			influencersStore.getAllInfluencersByQuery(coursesSearchParams);
		}
	}, [searchRequestString, coursesStore, influencersStore]);

	return (
		<PrivateLayout>
			<div className={styles.search_page}>
				<CommonHeader label={`Search results for "${searchRequestString}"`} />

				<div className={styles.search_page__content}>
					{!isNil(coursesStore?.findedCourses!) && !isEmpty(coursesStore?.findedCourses) && (
						<div className={styles.search_page__lessons_section}>
							<h2 className={styles.search_page__section_label}>Courses</h2>
							<div className={styles.search_page__tiles_section}>
								<Carousel
									items={coursesStore?.findedCourses?.map((course: IPreviewCourseModel) => (
										<CourseTile
											courseInfo={course}
											className={styles.search_page__lesson_tile}
											disableRating={true}
											courseTileKey={TransitionSources.Search}
										/>
									))}
								/>
							</div>
						</div>
					)}
					{!isNil(influencersStore?.findedInfluencers!) && !isEmpty(influencersStore?.findedInfluencers) && (
						<div className={styles.search_page__lessons_section}>
							<h2 className={styles.search_page__section_label}>Influencers</h2>
							<div className={styles.search_page__tiles_section}>
								<Carousel
									items={influencersStore.findedInfluencers?.map((influencer: IInfluencerDataModel) => (
										<InfluencerTile
											influencerInfo={influencer}
											className={styles.search_page__lesson_tile}
											key={influencer.id}
										/>
									))}
								/>
							</div>
						</div>
					)}
					{!isNil(peersStore?.findedPeers!) && !isEmpty(peersStore?.findedPeers) && (
						<div className={styles.search_page__lessons_section}>
							<h2 className={styles.search_page__section_label}>Threads</h2>
							<div className={styles.search_page__tiles_section}>
								{/* TODO: Finish when BE part of Threads was done. */}
								{/* <Carousel
									items={historyStore.continueWatching.map((lesson: IContentModel) => (
										<LessonTile courseInfo={lesson} className={styles.search_page__lesson_tile} disableRating={true} />
									))}
								/> */}
							</div>
						</div>
					)}
				</div>
			</div>
		</PrivateLayout>
	);
});
