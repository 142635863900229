import styles from "./styles.module.scss";
import classNames from "classnames";
import React, { useState } from "react";
import StarRating from "components/partial/star-rating/star-rating";
import ButtonBookmark from "components/partial/button-bookmark/button-bookmark";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { useAppStore } from "store";
import { generatePath, useHistory } from "react-router-dom";
import { isNil } from "lodash";
import { Routes } from "routes";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import { DEFAULT_COLOR } from "constants/colors";
import { getThumbnail } from "helpers/image.helper";
import { ISaveEventThreadHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventThreadHistoryRequestModel";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import { PeerTileInfo } from "./peer-tile-info";

export enum TilePlacement {
	default = "default",
	horizontal = "horiz",
	vertical = "vert",
}
interface Props {
	threadInfo: IThreadModel;
	tileStyle?: "default" | "light";
	tilePlacement?: TilePlacement.default | TilePlacement.horizontal | TilePlacement.vertical;
	className?: string;
	disableRating?: boolean;
	openInNewTab?: boolean;
}

const PeerTile = ({
	threadInfo,
	tileStyle = "default",
	tilePlacement = TilePlacement.default,
	className,
	disableRating = true,
	openInNewTab,
}: Props) => {
	const [rating, setRating] = useState<number>(threadInfo?.rating ?? 0);
	const [isBookmarked, setIsBookmarked] = useState<boolean>(threadInfo?.isBookmarked);
	const { peersStore } = useAppStore();
	const history = useHistory();
	const isLight = tileStyle === "light";

	const onBookmarked = async () => {
		const active = isBookmarked;
		setIsBookmarked(!isBookmarked);
		const data = {
			threadId: threadInfo?.id,
			eventType: active ? EventTypes.ThreadUnbookmarked : EventTypes.ThreadBookmarked,
		} as ISaveEventThreadHistoryRequestModel;
		await peersStore.saveEventThreadHistory(data).then(() => peersStore.getLastSavedThreads(undefined, true));
	};

	const onRating = async (rating: number) => {
		setRating(rating);
		const data = {
			threadId: threadInfo?.id,
			eventType: EventTypes.ThreadRated,
			payload: JSON.stringify({ rating }),
		} as ISaveEventThreadHistoryRequestModel;
		await peersStore.saveEventThreadHistory(data);
	};

	const goToThread = () => {
		const threadPath = generatePath(Routes.PeersThread, { id: threadInfo.id });
		openInNewTab ? window.open(threadPath, "_blank") : history.push(threadPath);
	};

	return (
		<div
			className={classNames(
				styles.peer_tile,
				{ [styles.peer_tile__light]: isLight },
				styles[`peer_tile__${tilePlacement}`],
				className
			)}
		>
			<div className={classNames(styles.peer_tile__header, styles[`peer_tile__header__${tilePlacement}`])}>
				<div
					className={classNames(styles.peer_tile__color_bar)}
					style={{ backgroundColor: threadInfo.category?.color ?? DEFAULT_COLOR }}
				/>
				<div className={styles.peer_tile__rating_block}>
					<StarRating
						className={styles.peer_tile__rating}
						value={rating}
						isDisabled={disableRating}
						onRatingChange={onRating}
						darkBorder={isLight}
					/>
					<ButtonBookmark
						className={styles.peer_tile__bookmark}
						bookmarked={isBookmarked}
						isSecondaryType={isLight}
						isBookmarkable={true}
						onClick={onBookmarked}
					/>
				</div>
			</div>
			<div className={classNames(styles.peer_tile__content, styles[`peer_tile__content__${tilePlacement}`])}>
				<img
					src={getThumbnail(threadInfo.thumbnail)}
					alt="Thumbnail"
					className={classNames(
						styles.peer_tile__content__thumbnail,
						styles[`peer_tile__content__thumbnail__${tilePlacement}`],
						{
							[styles.peer_tile__no_image]:
								isNull(threadInfo.thumbnail) || isNil(threadInfo.thumbnail?.url) || isEmpty(threadInfo.thumbnail?.url),
						}
					)}
					onClick={() => goToThread()}
				/>
			</div>
			<PeerTileInfo threadInfo={threadInfo} tilePlacement={tilePlacement} isLight={isLight} />
		</div>
	);
};

export default React.memo(PeerTile);
