import { NotesApi } from "api/controllers/NotesApi";
import { makeAutoObservable, runInAction } from "mobx";
import { INoteModel } from "models/dto/ZoomiLxp/Models/ContentModels/INoteModel";
import { IUpdateNoteModel } from "models/dto/ZoomiLxp/Models/ContentModels/IUpdateNoteModel";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";

export class NotesStore {
	private _isLoading: boolean = false;
	coursesWithNotes: IPreviewCourseModel[] = [];
	currentCourseNotes: INoteModel[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	async saveNote(contentId: number, note: IUpdateNoteModel) {
		this.isLoading = true;
		try {
			const response = await NotesApi.saveNote(contentId, note);
			return response.data.data.note;
		} finally {
			this.isLoading = false;
		}
	}

	async getNotesByCourseId(courseId: number, params: IPagingParams) {
		this.isLoading = true;
		try {
			const response = await NotesApi.getNotesByCourseId(courseId, params);
			runInAction(() => {
				this.currentCourseNotes = response.data.data.records;
			});
			return response.data.data.records as INoteModel[];
		} finally {
			this.isLoading = false;
		}
	}

	async getCoursesWithNotes(params: IPagingParams) {
		this.isLoading = true;
		try {
			const response = await NotesApi.getCoursesWithNotes(params);
			runInAction(() => {
				this.coursesWithNotes = response.data.data.records;
			});
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get isLoading() {
		return this._isLoading;
	}
}
