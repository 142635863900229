import Button from "components/base/button/button";
import { truncate } from "lodash";
import { useState } from "react";
import styles from "./styles.module.scss";

interface ExpandCellProps {
	text: string;
	expandLength?: number;
	maxLines?: number;
	className?: string;
}

export const ExpandCell = (props: ExpandCellProps) => {
	const { text, expandLength = 300, maxLines = 3 } = props;
	const [isShowMore, setShowMore] = useState(false);
	const isLinesOverflow: boolean = text?.split(/\r\n|\n\r|\n|\r/).length > maxLines;
	const isTextOverflow: boolean = text?.length > expandLength;

	const getFormattedText = () => {
		if (isTextOverflow) {
			return truncate(text, { length: expandLength, separator: " " });
		}
		if (isLinesOverflow) {
			return truncate(text, { separator: /\r\n|\n\r|\n|\r/ });
		}
		return text;
	};

	return (
		<div className={styles.expand_cell}>
			<div className={styles.expand_text}>{isShowMore ? text : getFormattedText()} </div>
			{(isTextOverflow || isLinesOverflow) && (
				<Button
					type="text"
					label={isShowMore ? "Less" : "More"}
					onClick={() => setShowMore(!isShowMore)}
					className={styles.show_more_btn}
					labelColor="primary"
				/>
			)}
		</div>
	);
};
