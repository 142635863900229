import { AlertEventTypes, createAlertEventBus } from "components/base/alert/alert";
import { Emitter } from "mitt";
import { makeAutoObservable } from "mobx";

export class CommonStore {
	private _alertEventBus: Emitter<AlertEventTypes>;

	constructor() {
		makeAutoObservable(this);
		this._alertEventBus = createAlertEventBus();
	}

	get alertEventBus(): Emitter<AlertEventTypes> {
		return this._alertEventBus;
	}

	set alertEventBus(value: Emitter<AlertEventTypes>) {
		this._alertEventBus = value;
	}
}
