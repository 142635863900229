import styles from "./styles.module.scss";
import InfluencersLayout from "layouts/influencers/influencers-layout";
import { useAppStore } from "store";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import isEmpty from "lodash/isEmpty";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import InfluencerTile from "components/partial/influencer/influencer-tile/influencer-tile";
import { observer } from "mobx-react";
import { mobileCheck, tabletCheck } from "helpers/device.helper";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { SortDirection } from "models/dto/ZoomiLxp/Utilities/Enumerations/SortDirection";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import { IFilterCriterion } from "models/dto/ZoomiLxp/Models/Query/IFilterCriterion";
import { TilePlacement } from "components/partial/peers/peer-tile/peer-tile";

const cx = classNames.bind(styles);

const InfluencersAll = () => {
	const { influencersStore } = useAppStore();
	const [alertEventBus] = useState(() => createAlertEventBus());

	const params = useRef<IQueryParams>({
		skip: 0,
		take: 20,
		filterCriteria: [
			{
				propertyNames: ["isActive"],
				function: FilterFunction.IsTrue,
				argument: true,
			} as IFilterCriterion,
		],
		sortCriteria: [
			{
				order: 1,
				propertyName: "DisplayName",
				direction: SortDirection.Ascending,
			},
		],
		queryString: "",
	});

	const getData = useCallback(async () => {
		return await influencersStore.getAllInfluencersByQuery(params.current);
	}, [influencersStore]);

	const { items, hasMore, fetchMoreData } = useInfiniteScroll<IInfluencerDataModel>(getData, params);

	const isMobile = useMemo(() => mobileCheck(), []);
	const isTablet = useMemo(() => tabletCheck, []);

	const isAdaptive = isMobile || isTablet;

	const [horisontal, vertical] = useMemo(
		() => [
			isAdaptive ? TilePlacement.default : TilePlacement.horizontal,
			isAdaptive ? TilePlacement.default : TilePlacement.vertical,
		],
		[isAdaptive]
	);

	let counter: number;

	return (
		<InfluencersLayout className={styles.inf_all}>
			<Alert eventBus={alertEventBus} />
			<div className={styles.inf_all__content}>
				<div className={styles.inf_all__section_grid}>
					{!isEmpty(items) && (
						<InfiniteScroll
							dataLength={items.length}
							next={fetchMoreData}
							hasMore={hasMore}
							loader={<h4>Loading...</h4>}
						>
							<div className={styles.inf_all__grid}>
								{items.map((influencer: IInfluencerDataModel, index: number) => {
									if (index === 0 || index % 10 === 0) counter = 0;
									counter++;
									if (counter === 1) {
										return (
											<InfluencerTile
												influencerInfo={influencer}
												tilePlacement={horisontal}
												key={influencer.id}
												className={cx({ [styles.inf_all__tile_horiz]: !isAdaptive })}
											/>
										);
									} else if (counter === 2) {
										return (
											<InfluencerTile
												influencerInfo={influencer}
												tilePlacement={vertical}
												key={influencer.id}
												className={cx({ [styles.inf_all__tile_vert]: !isAdaptive })}
											/>
										);
									} else
										return (
											<InfluencerTile
												influencerInfo={influencer}
												className={styles.inf_all__influencer_tile}
												key={influencer.id}
											/>
										);
								})}
							</div>
						</InfiniteScroll>
					)}
				</div>
			</div>
		</InfluencersLayout>
	);
};

export default observer(InfluencersAll);
