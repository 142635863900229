import { useCallback, useEffect } from "react";
import styles from "./styles.module.scss";
import InputSearch from "components/base/input-search/input-search";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import update from "immutability-helper";
import { useAppStore } from "store";
import SmallLoadingIndicator from "../small-loading-indicator/smal-loading-indicator";
import { observer } from "mobx-react";
import { useLocation } from "react-router";
import Tooltip from "components/base/tooltip/tooltip";
import Button from "components/base/button/button";
import { useReindexArgument } from "hooks/useReindexArgument";

interface SearchFilterProps {
	params: IQueryParams;
	setParams: (value: ((prevState: IQueryParams) => IQueryParams) | IQueryParams) => void;
	classNameInput?: string;
	isSearchDisabled?: boolean;
}

const MAX_SYMBOLS = 50;

function SearchFilter({ params, setParams, classNameInput, isSearchDisabled }: SearchFilterProps) {
	const { bulkOperationsStore, searchStore, toasterStore } = useAppStore();
	const { pathname } = useLocation();
	const { argument: cmsArgument } = useReindexArgument(pathname);

	const onSearch = useCallback(
		(value: string) => {
			const lengthInput = value.length;

			if (lengthInput > MAX_SYMBOLS) {
				toasterStore.showErrorMessage(
					`The length of the search text cannot exceed the limit value of ${MAX_SYMBOLS}`
				);
				return;
			}
			const searchText = value.toLowerCase();

			if (lengthInput > 0) {
				const newParams = update<IQueryParams>(params, {
					queryString: { $set: searchText },
					skip: { $set: 0 },
				});
				setParams(newParams);
			} else if (lengthInput === 0) {
				const newParams = update<IQueryParams>(params, {
					queryString: { $set: "" },
					skip: { $set: 0 },
				});
				setParams(newParams);
			}
		},
		[toasterStore, params, setParams]
	);

	useEffect(() => {
		bulkOperationsStore.checkedAll(false);
	}, [params, bulkOperationsStore]);

	useEffect(() => {
		if (cmsArgument) {
			bulkOperationsStore.checkIsIndexing(cmsArgument);
		}
	}, [bulkOperationsStore, cmsArgument]);

	const reqestReindexing = () => {
		if (!bulkOperationsStore.isIndexing) {
			bulkOperationsStore.requestReindexing(cmsArgument!);
			toasterStore.showSuccessMessage(
				'Reindexing successfully started, you can continue to work with current data.\nPlease find reindex statuses in "Log History"'
			);
		}
	};

	return (
		<div className={styles.search_filter}>
			<InputSearch
				className={classNameInput}
				onChange={onSearch}
				defaultValue={params.queryString}
				disabled={isSearchDisabled ?? searchStore.isLoadingSearchData}
			/>
			{!!cmsArgument && (
				<div className={styles.search_reindex}>
					<Button
						className={styles.reindex_button}
						type="link"
						labelColor="secondary"
						onClick={reqestReindexing}
						disabled={bulkOperationsStore.isIndexing}
						label="Reindex database"
					/>
					{bulkOperationsStore.isIndexing && (
						<Tooltip
							label={"Reindexing requested, it can takes couple of minutes"}
							className={styles.login_settings__tooltip}
						>
							<SmallLoadingIndicator className={styles.spinner} />
						</Tooltip>
					)}
				</div>
			)}
		</div>
	);
}

export default observer(SearchFilter);
