import classNames from "classnames";
import DropdownList from "components/base/dropdown-list/dropdown-list";
import { FieldArray, FormikErrors, FormikValues } from "formik";
import { getSubjectChipStyles } from "helpers/select.helper";
import styles from "./styles.module.scss";
import { useAppStore } from "store";
import Tooltip from "components/base/tooltip/tooltip";
import { infoMessages } from "constants/messages";
import { ReactComponent as InfoIcon } from "assets/icons/ic_info.svg";
import { observer } from "mobx-react";
import Chip from "components/base/chip/chip";
import { useCategorySubjects } from "hooks/useCategorySubjects";
import { ISubjectModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ISubjectModel";

interface SubjectsChipsProps {
	values: FormikValues;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
	errors: FormikErrors<FormikValues>;
	isUpdateDisabled?: boolean;
}

//TODO: Remake this component with generic types usage.

const SubjectsChipsSelector = ({ values, setFieldValue, errors, isUpdateDisabled }: SubjectsChipsProps) => {
	const { taxonomyStore, toasterStore } = useAppStore();
	const { courseCategorySubjects, getCategorySubjectColor, getMappedSubjects } = useCategorySubjects();
	const onMainSubjectChange = (subject: ISubjectModel) => {
		setFieldValue("subject", subject);
		setFieldValue("subjects", getMappedSubjects(subject, values.subjects));
		subject.isMain = true;
	};
	const onRemoveSubjectHandler = (subject: ISubjectModel, idx: number) => {
		if (subject.isMain) {
			subject.isMain = false;
		}
		if (values.subjects?.length === 2) {
			setFieldValue("subject", values.subjects[0]);
			setFieldValue(`subjects[${0}].isMain`, true);
		} else {
			setFieldValue("subject", null);
		}
	};

	return (
		<FieldArray
			name="subjects"
			render={({ push, remove }) => (
				<div>
					<DropdownList
						value={null}
						placeholder="Category | Subjects"
						options={courseCategorySubjects}
						className={classNames(styles.subjects_chips_selector__form_field, styles.subjects_chips_selector__wide)}
						isSearchable
						onChange={(value) => {
							const isAlreadySelected: boolean = values.subjects?.some(
								(subject: ISubjectModel) => subject.id === value?.value
							);

							if (isAlreadySelected) {
								toasterStore.showErrorMessage("Subjects cannot contain duplicates.");
							}

							if (value && !isAlreadySelected) {
								const selectedOption: ISubjectModel | undefined = taxonomyStore.allSubjects.find(
									(subject: ISubjectModel) => subject.id === Number(value.value)
								);

								if (selectedOption) {
									selectedOption.isMain = !values.subjects?.some((subj: ISubjectModel) => subj.isMain);
									push(selectedOption);
								}
							}
						}}
						isError={!!errors?.subjects}
						errorText={infoMessages.requiredField}
						isDisabled={isUpdateDisabled}
					/>
					<div className={styles.subjects_chips_selector__subjects_container}>
						<div className={styles.subjects_chips_selector__chips_block}>
							{values.subjects?.map((subject: ISubjectModel, index: number) => {
								const categorySubject = getCategorySubjectColor(subject);
								const chipStyles = getSubjectChipStyles(subject.isMain, categorySubject.color);
								return (
									<Chip
										key={`${subject.id} - ${subject.name}`}
										label={categorySubject.name}
										onChipClick={() => {
											onMainSubjectChange(subject);
										}}
										onIconClick={() => {
											remove(index);
											onRemoveSubjectHandler(subject, index);
										}}
										isMain={subject.isMain}
										inlineChipStyle={chipStyles.chipStyle}
										iconColor={chipStyles.iconColor}
									/>
								);
							})}
						</div>
						{!!values.subjects?.length && (
							<Tooltip
								label={"Please click on chip to select main category color for displaying on course tile. "}
								className={styles.subjects_chips_selector__info_tooltip}
							>
								<InfoIcon className={styles.subjects_chips_selector__info_icon} />
							</Tooltip>
						)}
					</div>
				</div>
			)}
		/>
	);
};

export default observer(SubjectsChipsSelector);
