import styles from "./styles.module.scss";
import { ReactComponent as LinkedInLogo } from "assets/icons/ic_linkedin-logo.svg";
//@ts-ignore
import { LinkedIn } from "react-linkedin-login-oauth2";
import { Routes } from "routes";
import { useSocialSignIn } from "hooks/useSocialSignIn";
import { ExternalIdentityProviderTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ExternalIdentityProviderTypes";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { useAppStore } from "store";

const LinkedInLoginButton = () => {
	const { externalSignIn } = useSocialSignIn();
	const { settingsStore } = useAppStore();
	const redirectUri = window.location.origin + Routes.LinkedIn;

	return (
		<LinkedIn
			clientId={settingsStore.publicSettings.Auth?.[SiteSettingsKeys.LinkedInAuthAppId]}
			onFailure={() => console.log("Signing in failed!")}
			onSuccess={(resp: any) => {
				externalSignIn({
					externalProvider: ExternalIdentityProviderTypes.LinkedIn,
					token: resp.code,
					redirectUri: redirectUri,
				});
			}}
			style={{ background: "auto" }}
			className={styles.linkedIn_login_button}
			redirectUri={redirectUri}
		>
			<LinkedInLogo className={styles.linkedIn_logo_icon} />
			Sign in with LinkedIn
		</LinkedIn>
	);
};
export default LinkedInLoginButton;
