import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { uniqueId } from "lodash";

type Theme = "small";

interface Props {
	className?: string;
	onChange: (value: boolean) => void;
	onLoadAction?: () => void;
	value: boolean;
	theme?: Theme;
	isDisabled?: boolean;
}

const cx = classNames.bind(styles);

const Toggler = (props: Props) => {
	const { className, onChange, value, onLoadAction, theme, isDisabled } = props;
	const [id] = useState(() => uniqueId("id-"));

	useEffect(() => {
		onLoadAction?.();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<label
			htmlFor={id}
			className={cx(styles.toggler, className, styles[`toggler__theme__${theme}`], {
				[styles.toggler__is_disabled]: isDisabled,
			})}
		>
			<input
				id={id}
				type="checkbox"
				checked={value}
				onChange={(e) => {
					e.stopPropagation();
					onChange(e.target.checked);
				}}
				className={styles.toggler_input}
			/>
			<span className={cx(styles.control, styles[`control__theme__${theme}`])} />
		</label>
	);
};

export default Toggler;
