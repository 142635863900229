import Tooltip from "components/base/tooltip/tooltip";
import { ReactComponent as InfoIcon } from "assets/icons/ic_info.svg";
import { ReactComponent as SpinnerIcon } from "assets/icons/ic_spinner.svg";
import { useAppStore } from "store";
import styles from "../../styles.module.scss";
import { motivesMeterInfo } from "constants/messages";
import { IMotifMeterItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifMeterItem";
import MotivesMeterItem from "./motifs-meter-item";
import isNil from "lodash/isNil";
import { observer } from "mobx-react";

const MotivesMeter = () => {
	const { analyticsStore } = useAppStore();

	return (
		<div className={styles.motif_meter}>
			<div className={styles.analytics_page__chart_top_box}>
				Motifs Meter
				{analyticsStore.isLoading ? (
					<div className={styles.analytics_page__spinner_box}>
						<SpinnerIcon className={styles.analytics_page__loading_spinner} />
					</div>
				) : (
					<Tooltip label={motivesMeterInfo} className={styles.analytics_page__info_tooltip}>
						<InfoIcon className={styles.analytics_page__info_icon} />
					</Tooltip>
				)}
			</div>
			<section className={styles.motif_meter__chart_box}>
				{analyticsStore.motifsMeterItems.map((motifItem: IMotifMeterItem) => {
					return <MotivesMeterItem motifItem={motifItem} key={motifItem.id} />;
				})}
			</section>
			{!isNil(analyticsStore.motifsMeterInsight) && (
				<section className={styles.motif_meter__insight_section}>
					<div className={styles.motif_meter__insight_text}>{analyticsStore.motifsMeterInsight.title}</div>
					<span>{analyticsStore.motifsMeterInsight.text}</span>
				</section>
			)}
		</div>
	);
};
export default observer(MotivesMeter);
