import { DEFAULT_COLOR } from "constants/colors";

interface IProgressBarProps {
	fillerColor: string;
	completedPercentage: number;
	showPercentage?: boolean;
	containerStyles?: Partial<React.CSSProperties>;
	fillerStyles?: Partial<React.CSSProperties>;
	onClick?: () => void;
}
const ProgressBar = (props: IProgressBarProps) => {
	const { fillerColor, completedPercentage, showPercentage, containerStyles, fillerStyles, onClick } = props;

	const defaultContainerStyles = {
		height: 8,
		width: "100%",
		backgroundColor: DEFAULT_COLOR,
		borderRadius: 50,
	};

	const defaultFillerStyles = {
		height: "100%",
		width: `${completedPercentage}%`,
		backgroundColor: fillerColor,
		borderRadius: "inherit",
	};

	const labelStyles = {
		padding: 5,
		color: "var(--text-tile)",
	};

	return (
		<div style={{ ...defaultContainerStyles, ...containerStyles }} onClick={onClick}>
			<div style={{ ...defaultFillerStyles, ...fillerStyles }}>
				<span style={labelStyles}>{showPercentage ? `${completedPercentage}%` : ""}</span>
			</div>
		</div>
	);
};

export default ProgressBar;
