import { useAppStore } from "./store";
import { observer } from "mobx-react";
import { PublicView } from "pages/public";
import { PrivateView } from "pages/private";
import { isBadRequest, setAuthHeaders } from "api/utils/api";
import { useEffect, useState } from "react";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import Favicon from "react-favicon";
import InvalidTenantPage from "pages/public/invalid-tenant/invalid-tenant";
import { isEmpty, isNil } from "lodash";
import { useKeycloakProvider } from "hooks/useKeycloakProvider";

let isLoading = false;

export default observer(function App() {
	const { accountStore, usersStore, settingsStore } = useAppStore();
	const { checkReferrer, initiKeycloak } = useKeycloakProvider();
	const [invalidUrl, setInvalidUrl] = useState<{ isInvalid: boolean; error?: string }>({ isInvalid: false });
	checkReferrer();
	initiKeycloak();

	if (accountStore.accessToken) {
		setAuthHeaders(accountStore.accessToken);
	}

	useEffect(() => {
		if (isNil(settingsStore.publicSettings) || isEmpty(settingsStore.publicSettings))
			settingsStore.getPublicSettings().catch((error) => {
				if (isBadRequest(error)) {
					setInvalidUrl({ isInvalid: true, error: error.response?.data.data.errorMessage });
				}
			});
	}, [settingsStore]);

	if (accountStore.isAuthenticated && !usersStore.currentUserInfo) {
		if (!isLoading) {
			isLoading = true;
			usersStore
				.getCurrentUser()
				.finally(() => (isLoading = false))
				.catch((err) => console.log(err));
		}
	}

	if (!accountStore.isHydrated || (accountStore.isAuthenticated && !usersStore.currentUserInfo)) {
		return <></>;
	}

	const getPage = () => {
		if (invalidUrl.isInvalid) {
			console.log(invalidUrl.error);
			return <InvalidTenantPage message={invalidUrl.error} />;
		}

		return accountStore.isAuthenticated ? <PrivateView /> : <PublicView />;
	};

	return (
		<>
			{settingsStore.publicSettings.Images?.[SiteSettingsKeys.Favicon] && (
				<Favicon url={settingsStore.publicSettings.Images?.[SiteSettingsKeys.Favicon]} />
			)}
			{getPage()}
		</>
	);
});
