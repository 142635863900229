import { makeAutoObservable, runInAction } from "mobx";
import { ITaxonomyModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ITaxonomyModel";
import { ICreateCategoryModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ICreateCategoryModel";
import { TaxonomyApi } from "api/controllers/TaxonomyApi";
import { IUpdateCategoryModel } from "models/dto/ZoomiLxp/Models/Taxonomy/IUpdateCategoryModel";
import { sortBy } from "lodash";
import { ISubjectModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ISubjectModel";
import { ICategoryModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ICategoryModel";

export class TaxonomyStore {
	private _isLoading: boolean = false;
	private _taxonomyData: ITaxonomyModel = {} as ITaxonomyModel;
	private _allSubjects: ISubjectModel[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	async addNewCategory(data: ICreateCategoryModel) {
		this.isLoading = true;
		try {
			const response = await TaxonomyApi.addNewCategory(data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getAllCategories() {
		this.isLoading = true;
		try {
			await TaxonomyApi.getAllCategories().then((response) =>
				runInAction(() => {
					const categories: ICategoryModel[] = sortBy(response.data.data.categories, ["name"]);
					this.fillAllSubjects(categories);
					this.taxonomyData = { categories };
				})
			);
			return this.taxonomyData;
		} finally {
			this.isLoading = false;
		}
	}

	private fillAllSubjects(categories: ICategoryModel[]) {
		const subjectsArray: ISubjectModel[] = [];
		for (const category of categories) {
			for (const subject of category.subjects) {
				subjectsArray.push(subject);
			}
		}
		this.allSubjects = subjectsArray;
	}

	async updateCategory(categoryId: number, data: IUpdateCategoryModel) {
		this.isLoading = true;
		try {
			const response = await TaxonomyApi.updateCategory(categoryId, data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async removeCategory(categoryId: number) {
		this.isLoading = true;
		try {
			const response = await TaxonomyApi.removeCategory(categoryId);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	set taxonomyData(data: ITaxonomyModel) {
		this._taxonomyData = data;
	}

	get taxonomyData() {
		return this._taxonomyData;
	}

	set allSubjects(data: ISubjectModel[]) {
		this._allSubjects = data;
	}

	get allSubjects() {
		return this._allSubjects;
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get isLoading() {
		return this._isLoading;
	}
}
