import { makeAutoObservable, runInAction } from "mobx";
import {
	generalItem,
	INotificationsItem,
	IScheduleSettingsModel,
	newNotificationItem,
	TypeNotification,
	TypeTabs,
} from "./data";
import findIndex from "lodash/findIndex";
import update from "immutability-helper";
import { NotificationApi } from "api/controllers/NotificationApi";
import { chain, sortBy } from "lodash";
import { EmailSourceMessage } from "models/dto/ZoomiLxp/Utilities/Enumerations/EmailSourceMessage";
import { ISystemEmailSettingsModel } from "models/dto/ZoomiLxp/Models/Notifications/ISystemEmailSettingsModel";
import { EmailSubstitutions } from "models/dto/ZoomiLxp/Utilities/Constants/EmailSubstitutions";
import { IFormParameters } from "components/partial/notifications/notifications-settings/data";
import { ISystemNotificationPreviewModel } from "models/dto/ZoomiLxp/Models/Notifications/ISystemNotificationPreviewModel";
import isEmpty from "lodash/isEmpty";
import { IIsActiveNotificationRequest } from "models/dto/ZoomiLxp/Models/Notifications/IIsActiveNotificationRequest";
import { DateTime } from "luxon";
import { ScheduleNotificationTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ScheduleNotificationTypes";
import { IEmailSchedulePreviewModel } from "models/dto/ZoomiLxp/Models/Notifications/IEmailSchedulePreviewModel";
import { ICreateScheduleEmailSetingsModel } from "models/dto/ZoomiLxp/Models/Notifications/ICreateScheduleEmailSetingsModel";
import { IScheduleParameters } from "components/partial/notifications/notifications-schedule/data";
import { IEmailAddressNotificationModel } from "models/dto/ZoomiLxp/Models/Notifications/IEmailAddressNotificationModel";
import { EmailAddressModelType } from "models/dto/ZoomiLxp/Utilities/Enumerations/EmailAddressModelType";
import omit from "lodash/omit";
import { IExistingScheduleSettingsModel } from "models/dto/ZoomiLxp/Models/Notifications/IExistingScheduleSettingsModel";
import { DaysOfWeek } from "models/dto/ZoomiLxp/Utilities/Enumerations/DaysOfWeek";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { getTimezoneOffset } from "helpers/time.helper";
import { IOptionsItem } from "helpers/select.helper";
import { FileApi } from "api/controllers/FileApi";
import { ICreateFileUploadUrlRequestModel } from "models/dto/ZoomiLxp/Models/FileUpload/ICreateFileUploadUrlRequestModel";
import { v4 as uuidv4 } from "uuid";
import { UploadFileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/UploadFileTypes";
import axios from "axios";
import { replaceContent } from "helpers/html.helper";

export class NotificationsStore {
	private _isLoading: boolean = false;
	private _isLoadingList: boolean = false;
	private _menu: INotificationsItem[] = [];
	private _selectedItem: INotificationsItem = generalItem;
	private _fontFamilyAttr: string | undefined = undefined;
	private _fontSizeAttr: string | undefined = undefined;
	private _emailPreview: string | undefined = undefined;
	private _emailHtml: string | undefined = undefined;
	private _emailSettings: ISystemEmailSettingsModel | undefined = undefined;
	private _substitutionOptions: IOptionsItem[] = [];
	private _scheduleSettingsValues: IExistingScheduleSettingsModel | undefined = undefined;
	private _scheduleSettings: IScheduleSettingsModel = {
		isSchedule: false,
		scheduleNotificationType: ScheduleNotificationTypes.Once,
		daysOfWeek: [],
		days: null,
		dayOfDate: null,
		monthOfDate: null,
		yearOfDate: null,
		hours: null,
		minutes: null,
	};
	private _editorContent: string = "";

	constructor() {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	set isLoading(value: boolean) {
		this._isLoading = value;
	}

	get menu(): INotificationsItem[] {
		return this._menu;
	}

	get selectedItem(): INotificationsItem {
		return this._selectedItem;
	}

	set selectedItem(value: INotificationsItem) {
		this._selectedItem = value;
	}

	async setActiveMenuItem(item: INotificationsItem, value: boolean) {
		const index = findIndex(this._menu, { id: item.id });
		if (index !== -1) {
			this._menu = update(this._menu, { [index]: { isActive: { $set: value } } });
			await NotificationApi.setIsActiveNotificationMessage(
				item.type as EmailSourceMessage,
				{
					isActive: value,
				} as IIsActiveNotificationRequest
			);
		}
	}

	get fontFamilyAttr(): string | undefined {
		return this._fontFamilyAttr;
	}

	set fontFamilyAttr(value: string | undefined) {
		this._fontFamilyAttr = value;
	}

	get fontSizeAttr(): string | undefined {
		return this._fontSizeAttr;
	}

	set fontSizeAttr(value: string | undefined) {
		this._fontSizeAttr = value;
	}

	get emailPreview(): string | undefined {
		return this._emailPreview;
	}

	set emailPreview(value: string | undefined) {
		this._emailPreview = value;
	}

	async getNotificationStates() {
		runInAction(() => {
			this._isLoadingList = true;
		});
		try {
			const response = await NotificationApi.getNotificationStates();
			const notificationItems: ISystemNotificationPreviewModel[] = sortBy(response.data.data, ["displayName"]);
			if (!isEmpty(notificationItems)) {
				const newMenu: INotificationsItem[] = notificationItems.map((item: ISystemNotificationPreviewModel) => ({
					id: item.id,
					name: item.displayName,
					isActive: item.isActive,
					type: item.type as TypeNotification,
					isRequired: item.isRequired,
					description: item.description,
				}));
				newMenu.unshift(generalItem);

				runInAction(() => {
					this._menu = newMenu;
					this._selectedItem = newMenu[0];
				});
			}
			return response.data.data;
		} finally {
			runInAction(() => {
				this._isLoadingList = false;
			});
		}
	}

	async getSystemEmailSettings() {
		const type = this._selectedItem.type;
		if (type !== TypeTabs.GeneralTab && type !== TypeTabs.NoData) {
			this.isLoading = true;
			try {
				const response = await NotificationApi.getSystemEmailSettings(type as EmailSourceMessage);
				runInAction(() => {
					this._emailSettings = response.data.data;
				});
				this.setHtml(response.data.data.body);
				return response.data.data;
			} finally {
				this.isLoading = false;
			}
		}
	}

	get emailSettings(): ISystemEmailSettingsModel | undefined {
		return this._emailSettings;
	}

	set emailSettings(value: ISystemEmailSettingsModel | undefined) {
		this._emailSettings = value;
	}

	get substitutionOptions(): IOptionsItem[] {
		return this._substitutionOptions;
	}

	getSubstitutionOptions() {
		let type = this._selectedItem.type;

		if (type === TypeTabs.NewNotification) type = EmailSourceMessage.ScheduleSendMessage;

		if (type !== TypeTabs.GeneralTab && type !== TypeTabs.NoData) {
			const values = EmailSubstitutions.substitutionsGroups[type];
			const labels = chain(values)
				.map((key) => key.replace("{", ""))
				.map((key) => key.replace("}", ""))
				.sortBy()
				.value();

			const options: IOptionsItem[] = labels.map(
				(label) =>
					({
						value: `{${label}}`,
						label,
					} as IOptionsItem)
			);
			options.unshift({ value: "", label: "---" });

			runInAction(() => {
				this._substitutionOptions = options;
			});
		}
	}

	async saveSystemEmailSettings(data: IFormParameters) {
		const info: ISystemEmailSettingsModel = {
			subject: data.subject,
			cc: this.convertItemToIEmailModel(data.emailCC),
			body: data.notificationTextHtml,
			bodyHtml: String(this._emailHtml),
			bcc: this.convertItemToIEmailModel(data.emailBCC),
			isActive: this._selectedItem.isActive,
			emailSettingScope: this._selectedItem.type as EmailSourceMessage,
			isRequired: Boolean(this._selectedItem.isRequired),
		};
		await NotificationApi.saveSystemEmailSettings(info);
	}

	get isLoadingList(): boolean {
		return this._isLoadingList;
	}

	set isLoadingList(value: boolean) {
		this._isLoadingList = value;
	}

	async getScheduleStates() {
		runInAction(() => {
			this._isLoadingList = true;
		});
		try {
			const response = await NotificationApi.getAllSchedulePreview();
			const notificationItems: IEmailSchedulePreviewModel[] = sortBy(response.data.data, ["name"]);

			const newMenu: INotificationsItem[] = notificationItems.map((item: IEmailSchedulePreviewModel) => ({
				...item,
				type: EmailSourceMessage.ScheduleSendMessage,
			}));
			newMenu.unshift(newNotificationItem);

			runInAction(() => {
				this._menu = newMenu;
				this._selectedItem = newMenu[0];
				this.clearEditor();
			});
			return response.data.data;
		} finally {
			runInAction(() => {
				this._isLoadingList = false;
			});
		}
	}

	async setCustomActiveMenuItem(item: INotificationsItem, value: boolean) {
		const index = findIndex(this._menu, { id: item.id });
		if (index !== -1) {
			this._menu = update(this._menu, { [index]: { isActive: { $set: value } } });
			await NotificationApi.setIsActiveCustomNotificationMessage(item.id, {
				isActive: value,
			} as IIsActiveNotificationRequest);
		}
	}

	async removeCustomMenuItem(item: INotificationsItem) {
		const index = findIndex(this._menu, { id: item.id });
		if (index !== -1) {
			this._menu = update(this._menu, { $splice: [[index, 1]] });
			await NotificationApi.deleteScheduleEmailSettings(item.id);
		}
	}

	get scheduleSettings(): IScheduleSettingsModel {
		return this._scheduleSettings;
	}

	set scheduleSettings(value: IScheduleSettingsModel) {
		this._scheduleSettings = value;
	}

	initScheduleSettings() {
		const now = DateTime.now();

		this._scheduleSettings = {
			isSchedule: false,
			scheduleNotificationType: ScheduleNotificationTypes.Once,
			daysOfWeek: [],
			days: [],
			dayOfDate: now.day,
			monthOfDate: now.month,
			yearOfDate: now.year,
			hours: now.hour,
			minutes: now.minute,
		};
	}

	initScheduleSettingsValues() {
		this._scheduleSettingsValues = {
			scheduleSettingsId: 0,
			subject: "",
			recipients: [],
			cc: [],
			bcc: [],
			body: "",
			bodyHtml: "",
			isActive: true,
			...this._scheduleSettings,
		} as IExistingScheduleSettingsModel;
		this._editorContent = "";
	}
	initSchedule = () => {
		this.initScheduleSettings();
		this.initScheduleSettingsValues();
	};

	private convertItemToIEmailModel(items: IOptionsItem[]): IEmailAddressNotificationModel[] {
		if (!isEmpty(items)) {
			return items.map((item) => {
				let result: IEmailAddressNotificationModel;
				const elements: string[] = String(item.value).split("|");
				const value = elements[0];
				const type = Number(elements[1]);

				if (type === EmailAddressModelType.Email)
					result = { type: EmailAddressModelType.Email, value, substitutionId: undefined };
				else result = { type, value: item.label, substitutionId: Number(value) };

				return result;
			});
		}
		return [] as IEmailAddressNotificationModel[];
	}

	async createOrSendCustomNotification(data: IScheduleParameters) {
		try {
			const schedule = omit(this._scheduleSettings, "isSchedule");
			let info: ICreateScheduleEmailSetingsModel = {
				isSchedule: this._scheduleSettings.isSchedule,
				subject: data.subject,
				cc: this.convertItemToIEmailModel(data.recipientCC),
				body: data.notificationTextHtml,
				bodyHtml: this._emailHtml,
				bcc: this.convertItemToIEmailModel(data.recipientBCC),
				isActive: true,
				recipients: this.convertItemToIEmailModel(data.recipientTo),
				timeZone: getTimezoneOffset(),
			} as ICreateScheduleEmailSetingsModel;
			if (this._scheduleSettings.isSchedule) info = { ...info, ...schedule } as ICreateScheduleEmailSetingsModel;
			return await NotificationApi.createOrSendCustomNotification(info);
		} catch (err) {
			throw err;
		}
	}

	async updateCustomNotification(data: IScheduleParameters) {
		try {
			const schedule = omit(this._scheduleSettings, "isSchedule");
			let info: IExistingScheduleSettingsModel = {
				scheduleSettingsId: this._selectedItem.id,
				recipients: this.convertItemToIEmailModel(data.recipientTo),
				subject: data.subject,
				cc: this.convertItemToIEmailModel(data.recipientCC),
				body: data.notificationTextHtml,
				bodyHtml: this._emailHtml,
				bcc: this.convertItemToIEmailModel(data.recipientBCC),
				isActive: true,
				timeZone: getTimezoneOffset(),
			} as IExistingScheduleSettingsModel;
			info = { ...info, ...schedule } as IExistingScheduleSettingsModel;
			return await NotificationApi.updateCustomNotification(info);
		} catch (err) {
			throw err;
		}
	}
	async getScheduleEmailSettings(scheduleId: number) {
		runInAction(() => {
			this._isLoading = true;
		});
		try {
			const response = await NotificationApi.getScheduleEmailSettings(scheduleId);
			const settings: IExistingScheduleSettingsModel = response.data.data;
			runInAction(() => {
				this._scheduleSettingsValues = settings;
				this._scheduleSettings = {
					isSchedule: true,
					scheduleNotificationType: settings.scheduleNotificationType,
					daysOfWeek: [...(settings.daysOfWeek as DaysOfWeek[])],
					days: [...(settings.days as number[])],
					dayOfDate: settings.dayOfDate,
					monthOfDate: settings.monthOfDate,
					yearOfDate: settings.yearOfDate,
					hours: settings.hours,
					minutes: settings.minutes,
				} as IScheduleSettingsModel;
			});
			this.setHtml(settings.body);
			return response.data.data;
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	get scheduleSettingsValues(): IExistingScheduleSettingsModel | undefined {
		return this._scheduleSettingsValues;
	}

	set scheduleSettingsValues(value: IExistingScheduleSettingsModel | undefined) {
		this._scheduleSettingsValues = value;
	}

	async getHistoryCustomNotification(query: IQueryParams) {
		try {
			const response = await NotificationApi.getHistoryCustomNotification(query);
			return response.data.data;
		} catch (err) {
			throw err;
		}
	}

	async uploadBlob(html: string) {
		const blob = new Blob([html], { type: "text/html" });
		const data: ICreateFileUploadUrlRequestModel = {
			fileName: uuidv4() + ".html",
			contentType: "text/html",
			fileType: UploadFileTypes.Wiki,
		};
		return await FileApi.createUploadUrl(data, blob as File);
	}

	async getDownloadHtml(fileId: number) {
		runInAction(() => {
			this._isLoading = true;
		});
		try {
			const response = await FileApi.getPublicDownloadUrl(fileId);
			const info = response.data.data;
			const res = await axios.request({
				url: info.url,
				method: info.method,
			});
			return res.data;
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	get emailHtml(): string | undefined {
		return this._emailHtml;
	}

	set emailHtml(value: string | undefined) {
		this._emailHtml = value;
	}

	get editorContent(): string {
		return this._editorContent;
	}

	set editorContent(value: string) {
		this._editorContent = value;
	}

	setHtml(html: string) {
		runInAction(() => {
			this._editorContent = html;
			this._emailPreview = replaceContent(this._emailPreview, html);
			this._emailHtml = replaceContent(this._emailHtml, html);
		});
	}

	clearEditor = () => {
		this.clearHtmlEditor();
		runInAction(() => {
			this._editorContent = "";
		});
	};

	clearHtmlEditor = () => {
		this.setHtml("");
	};
}
