import React from "react";
import styles from "./styles.module.scss";
import SimpleLayout from "layouts/simple/simple";

const NoAccessPage = () => {
	return (
		<SimpleLayout>
			<div className={styles.no_access}>Sorry, you are not allowed to access this page</div>
		</SimpleLayout>
	);
};

export default NoAccessPage;
