import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { INoteModel } from "models/dto/ZoomiLxp/Models/ContentModels/INoteModel";
import { CmsTileProps } from "components/partial/cms/cms-table/cms-table";
import { NavLink, generatePath } from "react-router-dom";
import { getContentTypeIcon } from "components/partial/cms/cms-content-table/data";
import { ReactComponent as PlayIcon } from "assets/icons/ic_player_play.svg";
import { DateTime } from "luxon";
import { isEmpty, isNil } from "lodash";
import { Routes } from "routes";
import { ExpandCell } from "components/partial/cms/cms-table/expand-cell";

const NoteTableTile = (props: CmsTileProps<INoteModel>) => {
	const { item } = props;
	const { contentTitle, lastUpdated, contentType, courseId, courseItemId, note } = item;
	return (
		<div className={styles.note_table_tile}>
			<div className={styles.content_info}>
				<div className={styles.icons_container}>
					{getContentTypeIcon(contentType)}
					<NavLink
						to={generatePath(Routes.MyDeskContent, {
							courseId: !isNil(courseId) ? courseId : 0,
							courseItemId: courseItemId,
						})}
						target="_blank"
					>
						<PlayIcon className={classNames(styles.play_icon)} />
					</NavLink>
				</div>
				<div className={styles.content_tile}>
					{contentTitle}
					<div className={styles.updated_date}>
						{!isEmpty(lastUpdated) ? (
							DateTime.fromISO(lastUpdated).setLocale("en").toLocaleString(DateTime.DATE_MED)
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
			<ExpandCell text={note} />
		</div>
	);
};

export default NoteTableTile;
