import styles from "./styles.module.scss";
import Tooltip from "components/base/tooltip/tooltip";
import Toggler from "components/base/toggler/toggler";
import omit from "lodash/omit";
import classNames from "classnames";

interface TogglerActivationProps {
	onChange: (value: boolean) => void;
	onLoadAction?: () => void;
	value: boolean;
	className?: string;
	theme?: "left" | "top" | "default";
	isDisabled?: boolean;
}

const cx = classNames.bind(styles);

const TogglerActivation = (props: TogglerActivationProps) => {
	const { value, theme = "default" } = props;

	const propsToggler = omit(props, "theme");

	return (
		<div className={styles.toggler_activation__wrap}>
			<Tooltip
				label={value ? "Active" : "Inactive"}
				className={cx(styles.toggler_activation, styles[`toggler_activation__theme_${theme}`])}
				classNameHead={styles.toggler_activation__head}
				classNameContent={styles.toggler_activation__content}
			>
				<Toggler {...propsToggler}></Toggler>
			</Tooltip>
		</div>
	);
};

export default TogglerActivation;
