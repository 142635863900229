export enum Routes {
	//Public
	Root = "/",
	Register = "/register/:guid?",
	Login = "/login/:guid?",
	ResetPassword = "/reset-password",
	CreateNewPassword = "/create-new-password",
	Invitation = "/invitation",
	Page404 = "/404",
	LinkedIn = "/linkedin",
	DigitalUniversity = "/digital-university",
	WikiPage = "/wiki-page/:fileId",
	InvalidUrl = "/invalidUrlPage",
	NoAccess = "/no-access",
	Check2FA = "/2fa-check",

	//Private
	Home = "/home",
	Profile = "/profile",
	Peers = "/peers",
	PeersThread = "/peers/thread/:id",
	PeersThreadShareLink = "/peers/thread/:id/:shareLink",
	PeersThreadEdit = "/peers/thread-edit/:id",
	MyDesk = "/myDesk",
	MyDeskLesson = "/myDesk/:courseId",
	MyDeskContent = "/myDesk/:courseId/:courseItemId",
	MyDeskContentTime = "/myDesk/:courseId/:courseItemId/:startPosition",
	MyDeskShareLink = "/myDesk/:courseId/:courseItemId/to/:shareLink",
	MyHistory = "/myData/history",
	ProfilePurchases = "/myData/purchases",
	MyAnalytics = "/myData/analytics",
	MyQuizResults = "/myData/quizResults",
	MyData = "/myData",
	MyGoals = "/myData/goals",
	MyNotes = "/myData/notes",
	MyCertificates = "/myData/certificates",
	MotifStatisticsPage = "/myData/analytics/motifStatistics/:motifId",
	UserMotifStatisticsPage = "/cms/users/:userId/analytics/motifStatistics/:motifId",
	Influencers = "/influencers",
	InfluencersAll = "/influencers/all",
	InfluencersRecommendations = "/influencers/recommendations",
	InfluencerPofile = "/influencer/:id",
	Reports = "/reports",
	AllReports = "/reports/all",
	ReportUser = "/reports/user",
	ReportCourse = "/reports/course",
	ReportDashboard = "/reports/dashboard",
	Cms = "/cms",
	CmsTaxonomy = "/cms/taxonomy",
	CmsGroups = "/cms/groups",
	CmsGroupProfileTab = "/cms/groups/:id/:tab",
	CmsUsers = "/cms/users",
	CmsUserProfile = "/cms/users/:userId",
	CmsUserHistory = "/cms/users/:userId/history",
	CmsUserPurchases = "/cms/users/:userId/purchases",
	CmsUserAnalytics = "/cms/users/:userId/analytics",
	CmsUserQuizResults = "/cms/users/:userId/quizResults",
	CmsUserGoals = "/cms/users/:userId/goals",
	CmsUserCertificates = "/cms/users/:userId/certificates",
	CmsAddUser = "/cms/users/newUser",
	CmsCompanies = "/cms/companies",
	CmsCompanyProfile = "/cms/companies/:id",
	CmsAddCompany = "/cms/companies/newCompany",
	CmsCourses = "/cms/courses",
	CmsCourseProfile = "/cms/course/:id",
	CmsCourseProfileVersion = "/cms/course/:id/:versionId?",
	CmsAddChapter = "/cms/course/:courseId/newChapter",
	CmsChapterProfile = "/cms/course/:courseId/chapter/:chapterId",
	CmsQuiz = "/cms/course/quiz/:assessmentId",
	CmsAddQuiz = "/cms/course/quiz/:courseId/addQuiz",
	CmsAddCourse = "/cms/course/newCourse",
	CmsAddContent = "/cms/course/:courseId/addContent/:contentType?",
	CmsAddRefContent = "/cms/course/:courseId/addContentRef/",
	CmsContentProfile = "/cms/content/:courseId/:courseItemId",
	CmsRefContentProfile = "/cms/content-ref/:courseId/:courseItemId",
	CmsContentThreadAdd = "/cms/content/:courseId/thread/addThread",
	CmsContentThreadProfile = "/cms/content/:courseId/thread/:courseItemId",
	CmsContentStreamAdd = "/cms/content/:courseId/stream/addStream",
	CmsContentStreamProfile = "/cms/content/:courseId/stream/:courseItemId",
	CmsContentExternalLinkAdd = "/cms/content/:courseId/external/addExternal",
	CmsContentExternalLinkProfile = "/cms/content/:courseId/external/:courseItemId",
	CmsThreads = "/cms/threads",
	CmsThreadProfile = "/cms/threads/:id",
	CmsAddThread = "/cms/threads/newThread",
	CmsSales = "/cms/sales",
	CmsB2BSales = "/cms/b2b-sales",
	CmsB2CSales = "/cms/b2c-sales",
	CmsPurchases = "/cms/purchases",
	CmsB2BPurchases = "/cms/b2b-purchases",
	CmsB2CPurchases = "/cms/b2c-purchases",
	CmsSettings = "/cms/settings",
	SearchPage = "/search/:searchString",
	AdvSearchPage = "/adv-search",
	AdvSearchCoursesPage = "/adv-search/courses",
	AdvSearchInfluensersPage = "/adv-search/influensers",
	AdvSearchThreadsPage = "/adv-search/threads",
	CoursePage = "/course/:id",
	CoursePageChapter = "/course/:id/:chapterId",
	CmsNotificationsSystem = "/cms/notifications/system",
	CmsNotificationsCustom = "/cms/notifications/custom",
	CmsLogHistory = "/cms/logHistory",

	//Admin
	ConfirmEmail = "/confirm-email/:guid?",
	ShortQuizResults = "/short-quiz-results/:courseId",

	//Stripe
	PaymentSuccess = "/payment/success/:sessionId?",
	PaymentError = "/payment/error",
}
