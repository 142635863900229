import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import { getFormattedProgress } from "helpers/string.helper";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { useAppStore } from "store";
import { usePlayerNav } from "hooks/usePlayerNav";
import PlayerControl, { ControlParams } from "../player-control/player-control";
import { IPlayerStreamBoxProps, StreamTypes } from "pages/private/cms-stream-page/constants";
import meetingImg from "assets/images/stream/meeting.jpg";
import teamsImg from "assets/images/stream/teams-logo.png";
import zoomImg from "assets/images/stream/zoom.png";
import noImage from "assets/images/no-image.png";
import { DateTime } from "luxon";
import Countdown, { zeroPad } from "react-countdown";
import { CountdownRenderProps } from "react-countdown/dist/Countdown";
import { usePlayerControl } from "hooks/usePlayerControl";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";

const cx = classNames.bind(styles);

const PlayerStreamBox = (props: IPlayerStreamBoxProps) => {
	const { stream, className, onEventHandle } = props;
	const [alertEventBus] = useState(() => createAlertEventBus());
	const { contentStore, coursesStore } = useAppStore();
	const [isDisabled, setDisabled] = useState(false);

	const typeUrl = useMemo(() => {
		if (stream?.url.includes("teams.microsoft.com")) {
			return StreamTypes.Teams;
		} else if (stream?.url.includes("zoom.us")) {
			return StreamTypes.Zoom;
		}
		return StreamTypes.Unknown;
	}, [stream?.url]);

	useEffect(() => {
		const currentDate = DateTime.now();
		const meetingDate = DateTime.fromISO(stream?.meetingDate);
		const delta = meetingDate.diff(currentDate, "seconds").toObject();
		setDisabled(Number(delta.seconds) > 0);
	}, [stream?.meetingDate]);

	const { prev, next } = usePlayerNav();

	const handleNext = () => next();

	const handlePrev = () => prev();

	const handleClick = () => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Complete,
			payload: "",
		};
		onEventHandle(data).then(() => {
			coursesStore.curActiveContent.completed = true;
		});
	};

	const initParams: ControlParams = {
		currentProgress: 1,
		totalProgress: 1,
		boxRef: useRef<HTMLDivElement>(null),
		getFormattedProgress,
		muted: true,
		playing: false,
		isDownload: false,
		handleNext,
		handlePrev,
		isPrev: false,
		isNext: false,
	};

	const [params, setParams] = React.useState<ControlParams>(initParams);

	usePlayerControl(setParams);

	const getImage = () => {
		if (typeUrl === StreamTypes.Teams) {
			return teamsImg;
		} else if (typeUrl === StreamTypes.Zoom) {
			return zoomImg;
		}
		return noImage;
	};

	const renderer = (props: CountdownRenderProps) => {
		const { days, hours, minutes, seconds, completed } = props;
		if (completed) {
			return <></>;
		} else {
			return (
				<div className={styles.player_stream_box__countdown}>
					Start in{" "}
					{days > 0
						? `${zeroPad(days)}:${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`
						: `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`}
				</div>
			);
		}
	};

	const sendStart = useCallback(() => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Start,
			payload: "",
		};
		contentStore.sendEventCourseItemHistory(data);
	}, [contentStore]);

	useEffect(() => {
		if (contentStore.sessionId) {
			sendStart();
		}
	}, [contentStore.sessionId, sendStart]);

	if (!stream) return <></>;

	return (
		<div className={cx(styles.player_stream_box, className)} ref={params.boxRef}>
			<Alert eventBus={alertEventBus} />
			{typeUrl === StreamTypes.Unknown && (
				<img src={meetingImg} alt={"meeting"} className={styles.player_stream_box__img} />
			)}
			<div className={styles.player_stream_box__wrap}>
				{typeUrl === StreamTypes.Unknown ? (
					<div className={styles.player_stream_box__color_bar} />
				) : (
					<div className={styles.player_stream_box__picture}>
						<img src={getImage()} alt={"logo"} className={styles.player_stream_box__image} />
					</div>
				)}
				<div className={styles.player_stream_box__body}>
					<div className={styles.player_stream_box__title}>{stream.title}</div>
					<Countdown
						date={stream.meetingDate}
						renderer={renderer}
						className={cx({ [styles.player_stream_box__countdown__disable]: !isDisabled })}
						onComplete={() => setDisabled(false)}
					/>
				</div>
				<div className={styles.player_stream_box__footer}>
					<a
						href={stream.url}
						target="_blank"
						className={cx(styles.player_stream_box__btn, { [styles.player_stream_box__btn__disable]: isDisabled })}
						rel="noreferrer"
						onClick={handleClick}
					>
						Join
					</a>
				</div>
			</div>
			<PlayerControl params={params} />
		</div>
	);
};

export default PlayerStreamBox;
