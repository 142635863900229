import styles from "./styles.module.scss";
import { CmsTileProps } from "components/partial/cms/cms-table/cms-table";
import { IMotifsTableRowData } from "../data";

const MotifsTableTile = (props: CmsTileProps<IMotifsTableRowData>) => {
	const { item } = props;

	return (
		<div className={styles.motif_statistics_tile}>
			<span className={styles.course_title}>{item.title}</span>
			<section className={styles.data_section}>
				<span className={styles.motif_data}>Z1 Rating: {Math.round(item.z1Rating)}%</span>
				<span className={styles.motif_data}>Engagement: {Math.round(item.engagement)}%</span>
				<span className={styles.motif_data}>Motif: {Math.round(item.motiffPercent)}%</span>
			</section>
		</div>
	);
};

export default MotifsTableTile;
