import styles from "./styles.module.scss";
import { Column } from "react-table";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import { IAssessmentResultModel } from "models/dto/ZoomiLxp/Models/Assessments/IAssessmentResultModel";
import { IAnswerModel } from "models/dto/ZoomiLxp/Models/Assessments/IAnswerModel";
import { QuestionTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/QuestionTypes";
import { ExpandCell } from "components/partial/cms/cms-table/expand-cell";
import { ReactComponent as QuizIcon } from "assets/icons/ic_type_quiz.svg";
import { getVersionTitle } from "helpers/select.helper";
import { IPreviewCourseVersionModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseVersionModel";

export const isAnswerCanBeCorrect = (questionType: QuestionTypes) => {
	if (
		questionType === QuestionTypes.Choice ||
		questionType === QuestionTypes.MultiChoice ||
		questionType === QuestionTypes.NumberWithCheck
	) {
		return true;
	}
	return false;
};

export const columns: Column<IAssessmentResultModel>[] = [
	{
		Header: "Quiz results",
		columns: [
			{
				Header: "",
				id: "0",
				accessor: (originalRow: IAssessmentResultModel) => {
					return originalRow;
				},
				Cell() {
					return <QuizIcon className={styles.quiz_icon} />;
				},
			},
			{
				Header: "Name",
				id: "1",
				accessor: (row: IAssessmentResultModel) => {
					return row.name;
				},
				Cell({ value }: { value: any }) {
					return <ExpandCell expandLength={90} maxLines={1} text={value} />;
				},
			},
			{
				Header: "Course Version",
				id: "2",
				accessor: (row: IAssessmentResultModel) => row.version,
				Cell({ value }: { value: IPreviewCourseVersionModel }) {
					return !isEmpty(value) ? getVersionTitle(value, true) : <>-</>;
				},
			},
			{
				Header: "Attempt Date",
				id: "3",
				accessor: (row: IAssessmentResultModel) => row.passDate,
				Cell({ value }: { value: any }) {
					return !isEmpty(value) ? new Date(value).toLocaleString() : <></>;
				},
			},
			{
				Header: "Score",
				id: "4",
				accessor: (row: IAssessmentResultModel) => {
					return row.learnerPercent;
				},
				Cell(cell: any) {
					return (
						<div>
							{cell.row.original.learnerPercent % 100 === 0
								? cell.row.original.learnerPercent
								: cell.row.original.learnerPercent.toFixed(1)}
							%
						</div>
					);
				},
			},
			{
				Header: "Questions",
				id: "5",
				accessor: (row: IAssessmentResultModel) => {
					return row.answers;
				},
				Cell(cell: any) {
					return cell.row.original.answers.map((answer: IAnswerModel) => {
						const isCanBeRight = isAnswerCanBeCorrect(answer.question.questionType);
						return (
							<div className={styles.question_cell} key={answer.question.id}>
								<ExpandCell className={styles.question_text} text={answer.question.name} />
								<div
									key={`${answer.question.id}-${answer.isRightAnswer?.toString()}`}
									className={classNames(styles.quiz_results__status, {
										[styles.quiz_results__status_success]: isCanBeRight && answer.isRightAnswer,
										[styles.quiz_results__status_error]: isCanBeRight && !answer.isRightAnswer,
									})}
								>
									{isCanBeRight
										? answer.isRightAnswer
											? "Pass"
											: "Failed"
										: QuestionTypes[answer.question.questionType]}
								</div>
							</div>
						);
					});
				},
			},
		],
	},
];
