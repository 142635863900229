import { IGroupModel } from "models/dto/ZoomiLxp/Models/GroupModels/IGroupModel";
import { IOptionsItem } from "./select.helper";
import { IGroupAssignment } from "models/dto/ZoomiLxp/Models/Profile/IGroupAssignment";
import { GroupAssignmentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/GroupAssignmentTypes";

export type GroupAssignmentType = IGroupAssignment & { groupName: string };

export const optionToGroupModelConverter = (optionsItem: IOptionsItem): IGroupModel => {
	const grp: IGroupModel = { id: Number(optionsItem.value), name: optionsItem.label };
	return grp;
};

export const getChangedGroup = (optionItem: IOptionsItem, type: GroupAssignmentTypes) => {
	return optionToGroupAssignModelConverter(optionItem, type);
};

export const optionToGroupAssignModelConverter = (
	optionsItem: IOptionsItem,
	changeType: GroupAssignmentTypes
): GroupAssignmentType => {
	const grp: GroupAssignmentType = {
		groupName: optionsItem.label,
		groupId: Number(optionsItem.value),
		type: changeType,
	};
	return grp;
};
