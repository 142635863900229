import { IAssessmentModel } from "models/dto/ZoomiLxp/Models/Assessments/IAssessmentModel";
import { answerRequest, getStyle } from "./data";
import styles from "./styles.module.scss";
import Button from "components/base/button/button";
import React from "react";
import classNames from "classnames";
import { ITextTheme } from "models/dto/ZoomiLxp/Models/Common/ITextTheme";
import { FieldArray, Form, Formik, FormikErrors, FormikValues } from "formik";
import { INextQuestionModel } from "models/dto/ZoomiLxp/Models/Assessments/INextQuestionModel";
import { QuestionTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/QuestionTypes";
import { INextQuestionAnswerOptionModel } from "models/dto/ZoomiLxp/Models/Assessments/INextQuestionAnswerOptionModel";
import Checkbox from "components/base/checkbox/checkbox";
import InputField, { InputType } from "components/base/input-field/input-field";
import StarRating from "components/partial/star-rating/star-rating";
import { ReactComponent as AlertCircle } from "assets/icons/ic_alert-circle.svg";
import { ISaveAnswerRequest } from "models/dto/ZoomiLxp/Models/Assessments/ISaveAnswerRequest";
import { IFinishAssessmentAttemptResponse } from "models/dto/ZoomiLxp/Models/Assessments/IFinishAssessmentAttemptResponse";
import { ICompletionMessageModel } from "models/dto/ZoomiLxp/Models/Assessments/ICompletionMessageModel";
import { creatingIntervals } from "helpers/quiz.helper";
import { generatePath, Link } from "react-router-dom";
import { Routes } from "routes";
import { isEqual, sortBy, round, isEmpty } from "lodash";
import { ISaveAnswerResponse } from "models/dto/ZoomiLxp/Models/Assessments/ISaveAnswerResponse";
import { ShowRightAnswersOptions } from "models/dto/ZoomiLxp/Utilities/Enumerations/ShowRightAnswersOptions";
import { ToasterStore } from "store/toaster-store/toaster-store";

export const getFirstSlide = (quiz: IAssessmentModel, onNext: () => void, buttonLabel: string) => {
	const questionsStyle = getStyle(quiz.questionTextTheme);
	return (
		<section className={classNames(styles.player_quiz__wrap, styles.player_quiz__wrap__small)}>
			{!isEmpty(quiz.description) && (
				<div className={styles.player_quiz__description} style={questionsStyle}>
					{quiz.description}
				</div>
			)}
			<div
				className={classNames(styles.player_quiz__footer, styles.player_quiz__footer__small, {
					[styles.player_quiz__footer__center]: isEmpty(quiz.description),
				})}
			>
				<div className={styles.player_quiz__footer_start}>
					<Button label={buttonLabel} onClick={onNext} className={styles.player_quiz__btn_next} size="common" />
					{quiz.allowedSingleAttempt && <div className={styles.player_quiz__single_attempt}>Single attempt</div>}
				</div>
			</div>
		</section>
	);
};

export type InfoQuiz = {
	curQuestion: INextQuestionModel;
	indexQuestion: number;
	questionTextTheme: ITextTheme;
	answerTextTheme: ITextTheme;
	quizHighlightType: ShowRightAnswersOptions;
	answerCheckResult?: ISaveAnswerResponse;
	numberOfLeftAttempts?: number;
	allowedShowingNextQuestion?: boolean;
};

export const getMiddleSlides = (
	schema: undefined | {},
	infoQuiz: InfoQuiz,
	onSave: (data: ISaveAnswerRequest) => Promise<void>,
	toasterStore: ToasterStore,
	wrongAnswers: number[], 
	addNewWrongAnswer: (answer: number) => void,
	resetWrongAnswer: () => void,
) => {
	const {
		curQuestion,
		indexQuestion,
		questionTextTheme,
		answerTextTheme,
		answerCheckResult,
		quizHighlightType,
		allowedShowingNextQuestion,
	} = infoQuiz;
	const questionsStyle = getStyle(questionTextTheme);
	const getItems = getAnswers(
		curQuestion,
		answerTextTheme,
		quizHighlightType,
		!!allowedShowingNextQuestion,
		wrongAnswers, 
		addNewWrongAnswer,
		answerCheckResult
	);

	const handleSubmit =
		(
			values: FormikValues,
			validateForm: (values?: any) => Promise<FormikErrors<FormikValues>>,
			setErrors: (errors: FormikErrors<FormikValues>) => void
		) =>
		async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			e.preventDefault();
			const errors = await validateForm();

			if (isEmpty(errors)) {
				await onSave(values as ISaveAnswerRequest).then(() => !!allowedShowingNextQuestion && resetWrongAnswer());
			} else {
				setErrors(errors);

				let text = "Please check all fields are filled in correctly";
				if (errors?.answerOptionIds) text = String(errors.answerOptionIds);

				toasterStore.showErrorMessage(text);
			}
		};
	return (
		<section className={styles.player_quiz__wrap}>
			<Formik
				initialValues={answerRequest}
				validationSchema={schema}
				onSubmit={() => {}}
				validateOnChange={false}
				enableReinitialize={true}
			>
				{({ values, validateForm, setFieldValue, errors, setErrors }) => (
					<Form className={styles.player_quiz__form}>
						<div className={styles.player_quiz__header} style={questionsStyle}>
							Question {indexQuestion}
						</div>
						<div className={styles.player_quiz__body}>
							<div className={styles.player_quiz__name} style={questionsStyle}>
								{curQuestion.name}
							</div>
							<div className={styles.player_quiz__answers}>{getItems(values, setFieldValue, errors)}</div>
						</div>
						<div className={classNames(styles.player_quiz__footer, styles.player_quiz__footer__end)}>
							<Button
								label={allowedShowingNextQuestion ? "Next" : "Submit"}
								onClick={handleSubmit(values, validateForm, setErrors)}
								className={classNames(styles.player_quiz__btn_next, {
									[styles.player_quiz__disabled]: isEqual(values, answerRequest),
								})}
								size="common"
							/>
						</div>
					</Form>
				)}
			</Formik>
		</section>
	);
};

export const getAnswers = (
	curQuestion: INextQuestionModel,
	textTheme: ITextTheme,
	quizHighlightType: ShowRightAnswersOptions,
	showNextButton: boolean,
	wrongAnswers: number[], 
	addNewWrongAnswer: (answer: number) => void,
	answerCheckResult?: ISaveAnswerResponse
) => {
	return (
		values: FormikValues,
		setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
		errors: FormikErrors<FormikValues>
	): JSX.Element => {
		const { id, questionType, answerOptions, ratingScale, ratingShape, ratingShapeColor, surveyIsMulti } = curQuestion;
		const isChoice = questionType === QuestionTypes.Choice || (questionType === QuestionTypes.Survey && !surveyIsMulti);
		const isMultiChoice =
			questionType === QuestionTypes.MultiChoice || (questionType === QuestionTypes.Survey && surveyIsMulti);
		const handleOnChange = (
			push: (obj: any) => void,
			answer: INextQuestionAnswerOptionModel,
			remove: (index: number) => void
		) => {
			return (value: boolean) => {
				if (isChoice) {
					values.answerOptionIds = [];
					push(answer.id);
				} else if (isMultiChoice) {
					if (value) {
						push(answer.id);
					} else {
						const idx = values.answerOptionIds.indexOf(answer.id);
						if (idx !== -1) remove(idx);
					}
				}
			};
		};
		const getType = () => {
			if (isMultiChoice) return "checkbox";
			return "radio";
		};

		const answerStyle = getStyle(textTheme);
		if (
			questionType === QuestionTypes.Choice ||
			questionType === QuestionTypes.MultiChoice ||
			questionType === QuestionTypes.Survey
		) {
			return (
				<div className={styles.player_quiz__box}>
					<FieldArray
						name="answerOptionIds"
						render={({ push, remove }) => (
							<>
								{answerOptions.map((answerOption: INextQuestionAnswerOptionModel) => {
									const hasIsCorrectAnswerOrNot =
										answerCheckResult &&
										!(answerCheckResult.isAnswerCorrect === null || answerCheckResult.isAnswerCorrect === undefined);
									const numberOfAttemptsLeft =
										answerCheckResult && answerCheckResult?.countAttempts
											? answerCheckResult.countAttempts - answerCheckResult.currentAnswerNumber
											: 0;
									const isSelected: boolean = values.answerOptionIds.includes(answerOption.id);
									const answerOptionResult = answerCheckResult?.answerResults?.find(
										(e) => e.answerOptionId === answerOption.id
									);
									const isHighlightWrong = quizHighlightType === ShowRightAnswersOptions.ShowWrongSelected;
									const isAnswerChecked = isHighlightWrong && !!wrongAnswers?.find(
										(answerId) => answerId === answerOption.id
									);
									if(isSelected && answerOptionResult && !answerOptionResult.isRight && isHighlightWrong) {							
										if(!isAnswerChecked) {
											addNewWrongAnswer(answerOption.id);
										}
									}
									const shouldHighlight =
										(isHighlightWrong &&
											((isSelected && answerOptionResult && !answerOptionResult.isRight) || isAnswerChecked)) ||
										(quizHighlightType === ShowRightAnswersOptions.ShowCorrectAndWrongIfNoAttempts &&
											numberOfAttemptsLeft <= 0 && answerOptionResult && !answerOptionResult.isRight);

									return (
										<div className={styles.player_quiz__inner} key={answerOption.id}>
											<Checkbox
												type={getType()}
												name={`group-${id}`}
												value={values.answerOptionIds.includes(answerOption.id)}
												onChange={handleOnChange(push, answerOption, remove)}
												theme={"quiz"}
												className={styles.checkbox}
												isDisabled={showNextButton}
											/>
											<div
												className={styles.player_quiz__answer_name}
												style={{
													...answerStyle,
													color:
														hasIsCorrectAnswerOrNot &&
														shouldHighlight
															? "red"
															: hasIsCorrectAnswerOrNot &&
															  shouldHighlight &&
															  answerOptionResult &&
															  answerOptionResult.isRight
															? "green"
															: answerStyle.color,
												}}
											>
												{answerOption.name}
											</div>
										</div>
									);
								})}
							</>
						)}
					/>
				</div>
			);
		} else if (
			questionType === QuestionTypes.Number ||
			questionType === QuestionTypes.Text ||
			questionType === QuestionTypes.NumberWithCheck
		) {
			const isTextQuestion = questionType === QuestionTypes.Text;
			const type: InputType = isTextQuestion ? "text" : "number";
			const field: string = isTextQuestion ? "answerText" : "answerNumber";
			const getOnChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) =>
				setFieldValue(field, isTextQuestion ? String(e.target.value) : Number(e.target.value));
			const isIncorrectAnswer =
				questionType === QuestionTypes.NumberWithCheck && answerCheckResult?.allowedAnswerAttempt;
			return (
				<InputField
					inputType={type}
					label="Your Answer"
					className={classNames(styles.player_quiz__form_field, styles.player_quiz__wide)}
					name={field}
					value={String(values[field])}
					onChange={getOnChange}
					isError={!!errors[field] || isIncorrectAnswer}
					errorText={isIncorrectAnswer ? "Incorrect. Please try again." : String(errors[field])}
					disabled={showNextButton}
				/>
			);
		} else if (questionType === QuestionTypes.Rating) {
			return (
				<div className={styles.player_quiz__rating_box}>
					<StarRating
						value={values["answerNumber"] ?? 0}
						maxValue={ratingScale}
						shape={ratingShape}
						color={ratingShapeColor}
						onRatingChange={(newRating) => setFieldValue("answerNumber", newRating)}
					/>
					{!!errors["answerNumber"] && (
						<div className={styles.player_quiz__input_error}>
							<div className={styles.player_quiz__input_error_box}>
								<AlertCircle className={styles.player_quiz__input_error_icon} />
							</div>
							<div className={styles.player_quiz__input_error_text}>{String(errors["answerNumber"])}</div>
						</div>
					)}
				</div>
			);
		}
		return <></>;
	};
};

export type InfoFinish = {
	finishResult: IFinishAssessmentAttemptResponse;
	completionMessages: ICompletionMessageModel[];
	questionTextTheme: ITextTheme;
	allowedSingleAttempt: boolean;
};

export const getLastSlide = (
	infoFinish: InfoFinish,
	onTryAgain: () => void,
	onContinue: () => void,
	isActive: boolean,
	id: number,
	showQuizResults?: boolean
) => {
	const { finishResult, completionMessages, questionTextTheme, allowedSingleAttempt } = infoFinish;

	let title = "";
	const learnerPercent = finishResult.learnerPercent;

	if (finishResult.percentToPass === 0) title = "Quiz finished";
	else {
		if (finishResult.percentToPass > 0 && finishResult.isPassed)
			title = `Quiz passed: score is ${round(learnerPercent, 2)}%`;
		else if (finishResult.percentToPass > 0 && !finishResult.isPassed)
			title = `Quiz failed: score is ${round(learnerPercent, 2)}%`;
	}

	const questionsStyle = getStyle(questionTextTheme);

	const sortMessages = sortBy(completionMessages, ["percent"]);
	const intervals = creatingIntervals(sortMessages);

	let desc = "";
	if (!isEmpty(intervals)) {
		intervals.forEach((interval, index) => {
			if (index === intervals.length - 1) {
				if (learnerPercent >= interval.low) {
					desc = interval.text;
				}
			} else {
				if (learnerPercent >= interval.low && learnerPercent < interval.high) {
					desc = interval.text;
				}
			}
		});
	}

	return (
		<section className={classNames(styles.player_quiz__wrap, styles.player_quiz__wrap__small)}>
			<div className={styles.player_quiz__wrapper}>
				<div className={styles.player_quiz__description} style={questionsStyle}>
					{title}
				</div>
				{showQuizResults && (
					<Link
						to={generatePath(`${Routes.MyQuizResults}?id=${id}`)}
						target="_blank"
						className={styles.player_quiz__btn_result}
					>
						Go to quiz results table
					</Link>
				)}
			</div>
			{!isEmpty(desc) && (
				<div className={styles.player_quiz__description} style={questionsStyle}>
					{desc}
				</div>
			)}
			<div className={classNames(styles.player_quiz__footer, styles.player_quiz__footer__small)}>
				{!allowedSingleAttempt && (
					<Button label="Try again" onClick={onTryAgain} className={styles.player_quiz__btn_next} size="common" />
				)}
				<Button
					label="Continue"
					onClick={onContinue}
					className={classNames(styles.player_quiz__btn_next, { [styles.player_quiz__disabled]: !isActive })}
					size="common"
				/>
			</div>
		</section>
	);
};
