//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum QuestionTypes {
	Choice = 1,
	MultiChoice = 2,
	Number = 3,
	Text = 4,
	NumberWithCheck = 5,
	Survey = 6,
	Rating = 7
}
