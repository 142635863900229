import styles from "./styles.module.scss";
import { UsersGridColumnsHeaders, Columns } from "./data";
import CmsTileUser from "./cms-tile-user/cms-tile-user";
import { Routes } from "routes";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { useTabletGridColumns } from "hooks/useTabletGridColumns";
import { CmsUsersFilter } from "./cms-users-filter";
import CmsSpreadsheet from "../cms-spreadsheet/cms-spreadsheet";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import { useBulkSelections } from "../cms-courses/useBulkSelections";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";

const CmsUsers = ({ isHideFilter }: { isHideFilter?: boolean }) => {
	const columns = Columns();
	const { accountStore, bulkOperationsStore } = useAppStore();
	const { tableColumns } = useTabletGridColumns<IProfileDataModel>({
		columns,
		excludedColumns: [UsersGridColumnsHeaders.Company, UsersGridColumnsHeaders.RegDate],
	});

	const getColumns = () => {
		if (!bulkOperationsStore.isShowSelection) {
			const filteredColumns = tableColumns.slice(1);
			return filteredColumns;
		}
		return columns;
	};

	useBulkSelections([
		PermissionConstantsRead.OtherOwnCompanyUsersAdditionalInformation,
		PermissionConstantsRead.OtherAllUsersAdditionalInformation,
	]);

	return (
		<div className={styles.cms_users}>
			{!isHideFilter && <CmsUsersFilter />}
			<CmsSpreadsheet
				tableColumns={getColumns()}
				tileComponent={CmsTileUser}
				fetchData={() => accountStore.getAllUsers()}
				profileRoute={Routes.CmsUserProfile}
				className={styles.cms_users__body}
			/>
		</div>
	);
};

export default observer(CmsUsers);
