import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";

export const emptyQueryParams = {
	queryString: "",
	filterCriteria: [],
	sortCriteria: [],
	take: 100,
	skip: 0,
};

export const initialCarouselPagingParams: IPagingParams = {
	skip: 0,
	take: 20,
};
