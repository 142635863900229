import styles from "./styles.module.scss";
import { Slider, Slide, ButtonBack, ButtonNext, CarouselContext } from "pure-react-carousel";
import { observer } from "mobx-react";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { ReactComponent as ArrowLeft } from "assets/icons/ic_arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/ic_arrow-right.svg";
import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import { mobileCheck, tabletCheck } from "helpers/device.helper";
import { getIsEndOfList } from "helpers/carousel.helper";

export interface Props {
	items: ReactNode[];
	controlsStyle?: "dark" | "light";
	classNameSlider?: string;
	onCaruselEnded: () => void;
	hasMore?: boolean;
	isCompact?: boolean;
	onMobileSlideChange?: (slideIndex: number) => void;
	isLoading?: boolean;
}

const cx = classNames.bind(styles);

const CarouselSlider = (props: Props) => {
	const {
		items,
		controlsStyle = "dark",
		classNameSlider,
		onCaruselEnded,
		hasMore,
		isCompact,
		onMobileSlideChange,
		isLoading = false,
	} = props;
	const [slideId] = useState(() => uniqueId("-slide-"));
	const carouselContext = useContext(CarouselContext);
	const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
	const isMobile = useMemo(() => mobileCheck(), []);
	const isTablet = useMemo(() => tabletCheck, []);
	const isAdaptive = isMobile || isTablet;

	const isEndOfList = getIsEndOfList(items.length, currentSlide);
	const isEndOfListMobile = carouselContext.state.currentSlide === items.length - 1;

	useEffect(() => {
		const onSlideChange = () => setCurrentSlide(carouselContext.state.currentSlide);
		carouselContext.subscribe(onSlideChange);

		return () => carouselContext.unsubscribe(onSlideChange);
	}, [carouselContext]);

	const onButtonNextClick = () => {
		if (isMobile && onMobileSlideChange) {
			onMobileSlideChange(carouselContext.state.currentSlide + 1);
		}
		carouselEndHandler();
	};

	const onButtonBackClick = () => {
		if (isMobile && onMobileSlideChange) {
			onMobileSlideChange(carouselContext.state.currentSlide - 1);
		}
	};

	useEffect(() => {
		if (isEndOfList && isLoading) {
			carouselContext.setStoreState({ disableAnimation: true });
		} else if (!isEndOfList && !isLoading) {
			carouselContext?.setStoreState({ disableAnimation: false });
		}
	}, [carouselContext, isLoading, isEndOfList]);

	const carouselEndHandler = () => {
		if (!isAdaptive && hasMore && isEndOfList) {
			onCaruselEnded();
		}
		if (isAdaptive && hasMore && isEndOfListMobile) {
			onCaruselEnded();
		}
	};

	return !items.length ? (
		<></>
	) : (
		<div className={classNames(styles.slider_container, { [styles.slider_container__small]: isCompact })}>
			<ButtonBack
				className={classNames(
					styles.carousel_button,
					styles.carousel_button__left,
					styles[`carousel_button__left__${controlsStyle}`]
				)}
				onClick={() => {
					if (isAdaptive) {
						onButtonBackClick();
					}
				}}
			>
				<ArrowLeft className={styles.button_icon} />
			</ButtonBack>
			<Slider
				classNameTray={cx(styles.slider_tray, classNameSlider)}
				classNameAnimation={classNames({ [styles.carousel_animation]: !carouselContext.state.disableAnimation })}
				onTransitionEnd={carouselEndHandler}
			>
				{items?.map((item, index) => (
					<Slide index={index} className={styles.slide} style={{ width: "auto" }} key={index + slideId}>
						{item}
					</Slide>
				))}
			</Slider>
			<ButtonNext
				className={classNames(
					styles.carousel_button,
					styles.carousel_button__right,
					styles[`carousel_button__right__${controlsStyle}`]
				)}
				onClick={onButtonNextClick}
			>
				<ArrowRight className={styles.button_icon} />
			</ButtonNext>
		</div>
	);
};

export default observer(CarouselSlider);
