import styles from "./styles.module.scss";
import { useAppStore } from "store";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import classNames from "classnames/bind";
import { useEffect } from "react";
import { ReactComponent as RemoveIcon } from "assets/icons/ic_close.svg";
import Button from "components/base/button/button";
import { ReactComponent as VideoIcon } from "assets/icons/ic_play_circle.svg";
import { ReactComponent as ShareIcon } from "assets/icons/ic_share.svg";
import { getFormattedTime } from "helpers/time.helper";
import { ISegmentModel } from "models/dto/ZoomiLxp/Models/ContentModels/ISegmentModel";
import { generatePath } from "react-router-dom";
import { Routes } from "routes";
import { copyTextToClipboard } from "helpers/clipboard.helper";
import { ContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentTypes";
import isNull from "lodash/isNull";
import { infoMessages } from "constants/messages";

interface CourseCardTimestampsProps {
	onClose: () => void;
	className?: string;
}

const cx = classNames.bind(styles);

const CourseCardTimestamps = (props: CourseCardTimestampsProps) => {
	const { className, onClose } = props;

	const { contentStore, playerStore, coursesStore, toasterStore } = useAppStore();

	useEffect(() => {
		contentStore.getSegmentsByCourseItem(coursesStore.curActiveContent).catch((err) => {
			toasterStore.showErrorMessage(err);
		});

		return () => contentStore.clearSegments();
	}, [contentStore, toasterStore, coursesStore.curActiveContent]);

	const handleGoto = async (segment: ISegmentModel) => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		await playerStore.params?.handleSeekChange?.(segment.startPosition);
		if (!playerStore.params?.playing && !isNull(playerStore.params?.playing)) await playerStore.params?.handlePlay?.();
	};

	const handleGetLink = (segment: ISegmentModel) => {
		const url = window.location.origin;
		const path = generatePath(Routes.MyDeskContentTime, {
			courseId: coursesStore.curActiveContent.courseId,
			courseItemId: coursesStore.curActiveContent.courseItemId,
			startPosition: Math.round(segment.startPosition),
		});
		const fullPath = url + path;

		copyTextToClipboard(fullPath).then(() => {
			toasterStore.showErrorMessage(infoMessages.linkCopiedToClipboard);
		});
	};

	return (
		<div className={cx(className, styles.course_card_timestamps)}>
			{!isEmpty(contentStore.segments) ? (
				<div className={styles.course_card_timestamps__box}>
					<div className={styles.course_card_timestamps__header}>
						<div className={styles.course_card_timestamps__text}>Segments</div>
						<Button
							label=""
							onClick={() => onClose()}
							type="text"
							icon={<RemoveIcon className={styles.course_card_timestamps__button_icon} />}
							className={styles.course_card_timestamps__remove_btn}
						/>
					</div>
					<div className={styles.course_card_timestamps__body}>
						{contentStore.segments.map((segment) => (
							<div className={styles.course_card_timestamps__segment} key={segment.id}>
								<VideoIcon
									className={cx(styles.course_card_timestamps__icon, styles.course_card_timestamps__video_icon)}
									onClick={() => handleGoto(segment)}
								/>
								<div className={styles.course_card_timestamps__name} onClick={() => handleGoto(segment)}>
									{segment.name}
								</div>
								<ShareIcon
									className={cx(styles.course_card_timestamps__icon, styles.course_card_timestamps__share_icon)}
									onClick={() => handleGetLink(segment)}
								/>
								<div className={styles.course_card_timestamps__duration}>
									{coursesStore.curActiveContent.content?.contentType === ContentTypes.Text
										? `${segment.startPosition} - ${segment.endPosition}`
										: getFormattedTime(segment.duration)}
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className={styles.course_card_timestamps__no_data}>
					{contentStore.isLoading ? "Loading..." : "Segments not found"}
				</div>
			)}
		</div>
	);
};

export default observer(CourseCardTimestamps);
