import { observer } from "mobx-react";
import styles from "./styles.module.scss";
import { useAppStore } from "store";
import { useEffect } from "react";
import InfluencersLayout from "layouts/influencers/influencers-layout";
import CarouselsArray from "components/partial/carousels-array/carousels-array";
import { getOrderedCarousels } from "helpers/settings.helper";

export const InfluencersPage = observer(function InfluencersPage() {
	const { influencersStore, settingsStore } = useAppStore();

	useEffect(() => {
		influencersStore.getAllInfluencers().catch((err) => console.log(err));
		return influencersStore.clearStore();
	}, [influencersStore]);

	const sortedInfluencersCarousels = getOrderedCarousels(
		settingsStore.publicSettings,
		"InfluencersRecommendations.Carousels.Order"
	);

	return (
		<InfluencersLayout>
			<div className={styles.influencers__content}>
				<CarouselsArray carouselsKeys={sortedInfluencersCarousels} />
			</div>
		</InfluencersLayout>
	);
});
