import { GroupsApi } from "api/controllers/GroupsApi";
import { requestErrorHandler } from "api/utils/api";
import { CourseFilter, ThreadFilter, UserFilter, getInitParams, fistLastNameSort } from "helpers/filter.helper";
import { GroupAssignmentType } from "helpers/groups.helper";
import { omit } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { IGroupModel } from "models/dto/ZoomiLxp/Models/GroupModels/IGroupModel";
import { IGroupAssignment } from "models/dto/ZoomiLxp/Models/Profile/IGroupAssignment";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { PermissionConstantsCreateOrUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsCreateOrUpdate";
import { PermissionConstantsDelete } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsDelete";
import { PermissionConstantsUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsUpdate";
import { GroupAssignmentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/GroupAssignmentTypes";
import { AppStore } from "store";

const initGroupData = {
	id: 0,
	name: "",
};

export class GroupStore {
	private rootStore;
	private _groupData: IGroupModel = initGroupData;
	private _isLoading: boolean = false;
	private _groupsSearchParams: IQueryParams = getInitParams();
	private _addedGroupsAssignmentData: GroupAssignmentType[] = [];
	private _removedGroupsAssignmentData: GroupAssignmentType[] = [];
	private _groupsAssignmentRequestData: IGroupAssignment[] = [];
	public allowedPrefixes: string[] = ["visgrp__", "grp__", "licensegrp__"];

	constructor(rootStore: AppStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);
	}

	initCourseParams = (): IQueryParams => ({
		...getInitParams(),
		filterCriteria: this.rootStore.usersStore.checkAllUserPermissions([
			PermissionConstantsCreateOrUpdate.CoursesWithAnyCreator,
			PermissionConstantsDelete.GroupCmsManagement,
		])
			? [CourseFilter.tagId(this.groupData.tagId)]
			: [
					CourseFilter.tagId(this.groupData.tagId),
					CourseFilter.creatorId(this.rootStore.usersStore.currentUserInfo!.id),
			  ],
	});

	initThreadParams = (): IQueryParams => ({
		...getInitParams(),
		filterCriteria: this.rootStore.usersStore.checkAllUserPermissions([
			PermissionConstantsDelete.GroupCmsManagement,
			PermissionConstantsUpdate.AllThreads,
		])
			? [ThreadFilter.tagId(this.groupData.tagId), ThreadFilter.courseItemId]
			: [
					ThreadFilter.tagId(this.groupData.tagId),
					ThreadFilter.courseItemId,
					ThreadFilter.accountCreatedId(this.rootStore.usersStore.currentUserInfo!.id),
			  ],
	});

	initUsersParams = (): IQueryParams => ({
		...getInitParams(),
		filterCriteria: [UserFilter.groupsId(this.groupData.id)],
		sortCriteria: fistLastNameSort,
	});

	hasAllowedPrefixes = (name: string) => {
		let hasAllowedPrefix = false;
		this.allowedPrefixes.forEach((prefix) => {
			if (name.startsWith(prefix) && name.length > prefix.length) {
				hasAllowedPrefix = true;
				return;
			}
		});
		return hasAllowedPrefix;
	};

	updateCourseParams() {
		this.rootStore.searchStore.params = this.initCourseParams();
	}

	updateThreadsParams() {
		this.rootStore.searchStore.params = this.initThreadParams();
	}

	updateUsersParams() {
		this.rootStore.searchStore.params = this.initUsersParams();
	}

	async getGroupData(id: number) {
		const response = await GroupsApi.getGroup(id);
		this.groupData = response.data.data;
	}

	async deleteGroup(id: number) {
		await GroupsApi.deleteGroup(id);
	}

	async deleteGroupFromCourse(courseId: number) {
		await GroupsApi.deleteGroupFromCourse(this.groupData.id, courseId);
	}

	async deleteGroupFromThread(threadId: number) {
		await GroupsApi.deleteGroupFromThread(this.groupData.id, threadId);
	}

	async deleteGroupFromUser(userId: number) {
		await GroupsApi.deleteGroupFromUser(this.groupData.id, userId);
	}

	async getCMSGroups() {
		this.isLoading = true;
		try {
			this.groupsSearchParams = this.rootStore.searchStore.params;
			const response = await GroupsApi.getGroupsStatistic(this.groupsSearchParams);
			return response.data.data;
		} catch (err) {
			requestErrorHandler(err);
		} finally {
			this.isLoading = false;
		}
	}

	addGroupToAssign(group: GroupAssignmentType) {
		const groupRequestData: IGroupAssignment = omit(group, "groupName");
		runInAction(() => {
			this.groupsAssignmentRequestData.push(groupRequestData);
		});
	}

	removeGroupToAssign(removedGroup: GroupAssignmentType) {
		runInAction(() => {
			this.groupsAssignmentRequestData = this.groupsAssignmentRequestData.filter(
				(group) => group.groupId !== removedGroup.groupId
			);
		});
	}

	get groupData() {
		return this._groupData;
	}

	set groupData(data: IGroupModel) {
		this._groupData = data;
	}

	get isLoading() {
		return this._isLoading;
	}

	set isLoading(data: boolean) {
		this._isLoading = data;
	}

	get groupsSearchParams() {
		return this._groupsSearchParams;
	}

	set groupsSearchParams(params: IQueryParams) {
		this._groupsSearchParams = params;
	}

	get addedGroupsAssignmentData() {
		return this._addedGroupsAssignmentData.filter((group) => group.type === GroupAssignmentTypes.Assign);
	}

	set addedGroupsAssignmentData(data: GroupAssignmentType[]) {
		this._addedGroupsAssignmentData = data;
	}

	get removedGroupsAssignmentData() {
		return this._removedGroupsAssignmentData.filter((group) => group.type === GroupAssignmentTypes.Unassign);
	}

	set removedGroupsAssignmentData(data: GroupAssignmentType[]) {
		this._removedGroupsAssignmentData = data;
	}

	get groupsAssignmentRequestData() {
		return this._groupsAssignmentRequestData;
	}

	set groupsAssignmentRequestData(data: IGroupAssignment[]) {
		this._groupsAssignmentRequestData = data;
	}

	clearGroupsAssignRequestData() {
		runInAction(() => {
			this.groupsAssignmentRequestData = [];
		});
	}

	clearGroupsAssignPopupData() {
		this.clearGroupsAssignRequestData();
		runInAction(() => {
			this.addedGroupsAssignmentData = [];
			this.removedGroupsAssignmentData = [];
		});
	}
}
