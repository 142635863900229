import React, { useEffect } from "react";
import { ControlParams } from "components/partial/player/player-control/player-control";
import { useAppStore } from "store";

export const usePlayerControl = (setParams: React.Dispatch<React.SetStateAction<ControlParams>>) => {
	const { coursesStore } = useAppStore();

	useEffect(() => {
		const currentPosition = coursesStore.curPositionContent;
		setParams((prevState: ControlParams) => ({
			...prevState,
			isPrev: currentPosition > 0,
			isNext:
				currentPosition + 1 <= coursesStore.countContents &&
				(coursesStore.hasNextItemInSameChapter || coursesStore.nextChapterUnlocked),
		}));
	}, [
		coursesStore.countContents,
		coursesStore.curPositionContent,
		setParams,
		coursesStore.hasNextItemInSameChapter,
		coursesStore.nextChapterUnlocked,
	]);
};
