import { IOptionsItem } from "helpers/select.helper";
import { IBaseModel } from "models/dto/ZoomiLxp/Models/Common/IBaseModel";
import { IFileModel } from "models/dto/ZoomiLxp/Models/Common/IFileModel";
import { ICompanyModel } from "models/dto/ZoomiLxp/Models/CompanyModels/ICompanyModel";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";

export const GOOGLE_CLIENT_ID = "897869103571-e7das3o0bd5sfoqs6k8d0eu25v0gr7l4.apps.googleusercontent.com";
export const FACEBOOK_APP_ID = "4517573754975899";
export const LINKEDIN_CLIENT_ID = "86z1uqvcxbu3v4";
export const BANNER_URL = process.env.PUBLIC_URL + "/assets/banner-small.mov";
export const PRIVACY_POLICY_URL = "https://zoomi.ai/privacy-policy/";
export const GDPR_URL = "https://zoomi.ai/gdpr-notice/";
export const ZOOMI_URL = "https://zoomi.ai/";
export const mimeTypeSeparator = "|";

export const mimeTypes = {
	spreadsheet: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	presentation: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
	document: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	csv: "text/csv",
	jpeg: "image/jpeg",
	png: "image/png",
	mp3: "audio/mpeg",
	mp4: "video/mp4",
	mpeg: "video/mp4",
	mov: "video/quicktime",
	msicon: "image/vnd.microsoft.icon",
	msexcel: "application/vnd.ms-excel",
	msword: "application/msword",
	mspresentation: "application/vnd.ms-powerpoint",
	icon: "image/x-icon",
	pdf: "application/pdf",
	zip: "application/x-zip-compressed",
	eml: "message/rfc822",
	zip_app: "application/zip",
	video_all: "video/*",
	text_all: "text/*",
	audio_all: "audio/*",
	multipart: "multipart/related",
};

export const acceptedMimeTypes = {
	spreadsheet: "application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	spreadsheetAndCSV: "application/(vnd.ms-excel|vnd.openxmlformats-officedocument.spreadsheetml.sheet)|text/csv",
	pictures: "image/(jpeg|png)",
	picturesAndVideo: "image/(jpeg|png)|video/mp4",
	favicon: "image/(vnd.microsoft.icon|x-icon|png)",
	video: [mimeTypes.mp4, mimeTypes.mpeg].join(mimeTypeSeparator),
	attachments: [
		mimeTypes.pdf,
		mimeTypes.jpeg,
		mimeTypes.png,
		mimeTypes.document,
		mimeTypes.msword,
		mimeTypes.spreadsheet,
		mimeTypes.msexcel,
		mimeTypes.presentation,
		mimeTypes.mspresentation,
		mimeTypes.mp3,
		mimeTypes.mp4,
		mimeTypes.mpeg,
		mimeTypes.mov,
	].join(mimeTypeSeparator),
	textFiles: [
		mimeTypes.msword,
		mimeTypes.mspresentation,
		mimeTypes.document,
		mimeTypes.presentation,
		mimeTypes.pdf,
	].join(mimeTypeSeparator),
	html: [mimeTypes.zip_app, mimeTypes.zip, mimeTypes.eml].join(mimeTypeSeparator),
	scorm: [mimeTypes.zip_app, mimeTypes.zip].join(mimeTypeSeparator),
	complex: [
		mimeTypes.video_all,
		mimeTypes.text_all,
		mimeTypes.audio_all,
		mimeTypes.pdf,
		mimeTypes.mspresentation,
		mimeTypes.presentation,
		mimeTypes.msword,
		mimeTypes.document,
		mimeTypes.zip,
		mimeTypes.zip_app,
		mimeTypes.multipart,
	].join(mimeTypeSeparator),
};

export const fileUploadRestrictions = {
	maxSize: { video: 200, threadAttachments: 20 },
};

export const emptyOption = (): IOptionsItem => ({ label: "", value: "" });

export const outOfChapter = (): IOptionsItem => ({
	value: "0",
	label: "Out of chapter",
});

export const emptyFile = (): IFileModel => ({
	id: 0,
	url: "",
});

export const getEmptyUserData = (): IProfileDataModel => {
	return {
		id: 0,
		isActive: false,
		firstName: "",
		lastName: "",
		displayName: "",
		email: "",
		userPicture: emptyFile(),
		roles: [],
		jobTitle: "",
		company: getEmptyCompany(),
		country: "",
		city: "",
		description: "",
		registringDate: "",
		likesCount: 0,
		isLiked: false,
		isFollowed: false,
		expertises: [],
		groupIds: [],
		permissions: [],
		message: "",
		mfaEnabled: true,
	};
};

export const getEmptyCompany = (): ICompanyModel => {
	return {
		id: 0,
		name: "",
		description: "",
		isActive: false,
		companyPicture: emptyFile(),
		domains: [],
		companyManager: getEmptyBaseModel(),
	};
};

export const getEmptyBaseModel = (): IBaseModel => {
	return { id: 0, name: "" };
};
export const MAX_EMAIL_LENGTH = 256;
export const MAX_SIMPLE_TEXT = 10000;
export const MAX_EMAILS = 50;
export const MAX_NAME_LENGTH = 50;

export const TABLET_MAX_WIDTH = 1024;

export const SMALL_DELAY_TIME = 500;
export const DELAY_TIME = 1000;
export const DELAY_TIME_LONGER = 3000;
export const LONG_RUNNING_TESTS_TIMEOUT = 20000;
export const DEFAULT_PAGE_SIZE = 20;
export const MAX_LENGTH_DOMAIN = 256;

export enum SearchStateConstants {
	users = "usersSearchState",
	threads = "threadsSearchState",
	b2bSales = "b2bSalesSearchState",
	b2cSales = "b2cSalesSearchState",
	b2bPurchases = "b2bPurchasesSearchState",
	b2cPurchases = "b2cPurchasesSearchState",
	groups = "groupsSearchState",
	courses = "coursesSearchState",
	companies = "companiesSearchState",
	logHistory = "logHistorySearchState",
	scormAdvancedSettings = "scormAdvancedSettingsSearchState",
}

export enum QueryParamsConstansts {
	startPosition = "startPosition",
	endPosition = "endPosition",
}

export enum TransitionSources {
	Search = "Search",
	QuizResults = "QuizResults",
	Goals = "GoalsPage",
	Notes = "Notes",
	Purchases = "Purchases",
	InfluencerProfile = "InfluencerProfile",
	RealTimeRecommendation = "RealTimeRecommendation",
}
