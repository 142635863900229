import styles from "./styles.module.scss";
import { CarouselProvider } from "pure-react-carousel";
import { mobileCheck, tabletCheck } from "helpers/device.helper";
import { observer } from "mobx-react";
import { ReactNode, useMemo } from "react";
import classNames from "classnames";
import CarouselSlider from "./carousel-slider";
import { CAROUSEL_SLIDE_HEIGHT, CAROUSEL_SLIDE_HEIGHT_SMALL, CAROUSEL_SLIDE_WIDTH } from "helpers/carousel.helper";

export interface CarouselProps {
	items: ReactNode[];
	controlsStyle?: "dark" | "light";
	className?: string;
	classNameSlider?: string;
	hasMore?: boolean;
	getMoreData?: () => void;
	isCompact?: boolean;
	onMobileSlideChange?: (slideIndex: number) => void;
	step?: number;
	isLoading?: boolean;
}

const Carousel = ({
	items,
	controlsStyle = "dark",
	className,
	classNameSlider,
	hasMore,
	getMoreData,
	isCompact,
	onMobileSlideChange,
	step = 1,
	isLoading,
}: CarouselProps) => {
	const isMobile = useMemo(() => mobileCheck(), []);
	const isTablet = useMemo(() => tabletCheck, []);
	const visibleSlidesCount = Math.round(window.innerWidth / CAROUSEL_SLIDE_WIDTH - 1);
	const getVisibleSlidesCount = (): number => {
		if (isMobile) {
			return 1;
		}
		if (isTablet) {
			return 2;
		}
		return visibleSlidesCount <= 5 ? visibleSlidesCount : 5;
	};
	return !items.length ? (
		<></>
	) : (
		<>
			<CarouselProvider
				naturalSlideWidth={CAROUSEL_SLIDE_WIDTH}
				naturalSlideHeight={isCompact ? CAROUSEL_SLIDE_HEIGHT_SMALL : CAROUSEL_SLIDE_HEIGHT}
				totalSlides={hasMore ? items.length + 1 : items.length}
				visibleSlides={getVisibleSlidesCount()}
				className={classNames(styles.carousel, className)}
				step={step}
				dragEnabled={!isMobile}
				touchEnabled={!isMobile}
			>
				<CarouselSlider
					items={items}
					classNameSlider={classNameSlider}
					controlsStyle={controlsStyle}
					onCaruselEnded={() => {
						if (getMoreData) {
							getMoreData();
						}
					}}
					onMobileSlideChange={(slideIndex) => onMobileSlideChange?.(slideIndex)}
					hasMore={hasMore}
					isCompact={isCompact}
					isLoading={isLoading}
				/>
			</CarouselProvider>
		</>
	);
};

export default observer(Carousel);
