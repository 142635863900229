import "./styles.scss";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppStoreContext, appStoreInstance } from "store";
import { BrowserRouter } from "react-router-dom";
import { initAxios } from "api/utils/api";
import { AxiosError } from "axios";
import AlertToaster from "components/base/alert-toaster/alert-toaster";

initAxios((error: AxiosError) => {
	try {
		if (error && error.response?.status === 401 && !error.config?.url?.includes("account/signout")) {
			console.warn("Error. Unauthorized. Probably token was expired.");
			appStoreInstance.accountStore.signout(true);
		}
	} catch (err) {
		throw err;
	}
});

ReactDOM.render(
	<React.StrictMode>
		<AppStoreContext.Provider value={appStoreInstance}>
			<BrowserRouter>
				<App />
				<AlertToaster />
			</BrowserRouter>
		</AppStoreContext.Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
