import styles from "./styles.module.scss";
import GoalsBrain from "./components/goals-brain/goals-brain";
import GoalsHistogram from "./components/goals-histogram/goals-histogram";
import GoalsCarousels from "./components/goals-carousels/goals-carousels";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import { useRouteUserId } from "hooks/useRouteUserId";

const MyGoals = () => {
	const { goalsStore, usersStore, coursesStore, toasterStore } = useAppStore();
	const [title, setTitle] = useState("");
	const { routeUserId: otherUserId } = useRouteUserId();
	useEffect(() => {
		if (otherUserId) {
			goalsStore.getInfo(otherUserId).catch((err) => toasterStore.showErrorMessage(err));
			coursesStore.getAssignedCourses(otherUserId).catch((err) => toasterStore.showErrorMessage( err));
			usersStore
				.getUserById(otherUserId)
				.then(({ jobTitle }) => setTitle(jobTitle))
				.catch((err) => toasterStore.showErrorMessage(err));
		} else if (usersStore.currentUserInfo?.id) {
			goalsStore.getInfo(usersStore.currentUserInfo.id).catch((err) => toasterStore.showErrorMessage(err));
			coursesStore
				.getAssignedCourses(usersStore.currentUserInfo.id)
				.catch((err) => toasterStore.showErrorMessage(err));
			setTitle(usersStore.currentUserInfo.jobTitle);
		}
	}, [toasterStore, goalsStore, usersStore, coursesStore, otherUserId]);

	return (
		<div className={styles.goals}>
			<div className={styles.goals__wrap}>
				<GoalsBrain />
				<GoalsHistogram />
			</div>
			<GoalsCarousels title={title} otherUserId={otherUserId} />
		</div>
	);
};

export default observer(MyGoals);
