import BulkAssignmentForm from "components/partial/bulk-operations-forms/bulk-assignment-form/bulk-assignment-form";
import BulkCloningForm from "components/partial/bulk-operations-forms/bulk-cloning-form/bulk-cloning-form";
import { BulkOperationType } from "models/dto/ZoomiLxp/Utilities/Enumerations/BulkOperationType";
import { CloningFormType } from "store/bulk-operations/bulk-operations-store";

export const getLabel = (type: BulkOperationType) => {
	if (type === BulkOperationType.CourseCloning) {
		return "Clone";
	}
	return "Apply";
};

export const getBulkPopupForm = (type: BulkOperationType) => {
	const isCloningForm = type === BulkOperationType.CourseCloning || type === BulkOperationType.UserCloning;
	const cloningType = type === BulkOperationType.CourseCloning ? CloningFormType.Courses : CloningFormType.Users;
	if (isCloningForm) {
		return <BulkCloningForm cloningType={cloningType} />;
	}

	return <BulkAssignmentForm />;
};
