import MotifsTable from "components/partial/myDesk/my-analytics/components/motifs-statistics/motifs-table";
import { useRouteUserId } from "hooks/useRouteUserId";
import MyAccountLayout from "layouts/my-account/my-account-layout";
import ProfileLayout from "layouts/private/profile/profile-layout";

const MotifsStatisticPage = () => {
	const { routeUserId } = useRouteUserId();
	return routeUserId ? (
		<ProfileLayout>
			<MotifsTable />
		</ProfileLayout>
	) : (
		<MyAccountLayout>
			<MotifsTable />
		</MyAccountLayout>
	);
};

export default MotifsStatisticPage;
