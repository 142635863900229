import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { IWidgetMeta } from "helpers/reports.helper";
import { ReactComponent as CloseIcon } from "assets/icons/ic_close.svg";
import { ReactComponent as CollapseIcon } from "assets/icons/ic_collapsed.svg";
import { ReactComponent as ExpandIcon } from "assets/icons/ic_expand.svg";
import WidgetForm, { IWidgetValues } from "./components/widget-form/widget-form";
import isEmpty from "lodash/isEmpty";
import { useAppStore } from "store";
import { observer } from "mobx-react";

interface GridItemProps {
	meta: IWidgetMeta;
	onDelete?: (meta: IWidgetMeta) => void;
	onCollapse?: (meta: IWidgetMeta, isCollapse: boolean) => void;
	className?: string;
}

const cx = classNames.bind(styles);

const Widget = (props: GridItemProps) => {
	const { meta, onDelete, onCollapse, className } = props;
	const { reportsStore } = useAppStore();

	const handleSave = (values: IWidgetValues): void => reportsStore.saveMetaInformation({ ...meta, ...values });
	const handleCollapse = () => onCollapse?.(meta, !meta.collapsed);

	return (
		<div className={cx(styles.widget, className)}>
			<div className={styles.widget__header}>
				<div className={styles.widget__title}>{meta.name}</div>
				<div className={cx(styles.widget__buttons, { [styles.widget__buttons__hide]: meta.static })}>
					{meta.collapsed ? (
						<ExpandIcon className={styles.widget__icon_expand} onClick={handleCollapse} />
					) : (
						<CollapseIcon className={styles.widget__icon_collapse} onClick={handleCollapse} />
					)}
					<CloseIcon className={styles.widget__icon_close} onClick={() => onDelete?.(meta)} />
				</div>
			</div>
			<div className={styles.widget__body}>
				{isEmpty(meta.url) ? (
					<WidgetForm onSave={handleSave} />
				) : (
					<iframe
						className={styles.widget__frame}
						title={meta.name}
						id={meta.id}
						name={meta.name}
						src={meta.url}
						loading="lazy"
						allowFullScreen
					/>
				)}
			</div>
		</div>
	);
};

export default observer(Widget);
