import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as SpinnerIcon } from "assets/icons/ic_spinner.svg";

type Props = {
	className?: string;
};

const SmallLoadingIndicator = ({ className }: Props) => {
	return <SpinnerIcon className={classNames(styles.loading_spinner, className)} />;
};

export default SmallLoadingIndicator;
