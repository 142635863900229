import { History } from "history";
import { merge } from "lodash";
import { generatePath, matchPath } from "react-router-dom";
import { Routes } from "routes";

const MIDDLE_BUTTON = 1;

interface CmsTableClickProps {
	route: string;
	id: number;
	basePathOption?: object;
	history: History<unknown>;
	event?: React.MouseEvent<HTMLTableRowElement>;
}

export const cmsItemClick = ({ route, basePathOption, id, history, event }: CmsTableClickProps) => {
	if (!route) return;
	const isUserPage = !!matchPath(route, Routes.CmsUserProfile);
	const dynOption = isUserPage ? { userId: id } : { id };
	const pathOption = basePathOption ? merge(dynOption, basePathOption) : dynOption;
	const path = generatePath(route, pathOption);
	if (event?.button === MIDDLE_BUTTON) window.open(path, "_blank", "noopener, noreferrer");
	else if (!event?.button) history.push(path);
};
