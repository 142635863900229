import React, { useRef } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useDetectOutsideClick } from "hooks/useDetectOutsideClick";
import { ReactComponent as FilterIcon } from "assets/icons/ic_filter.svg";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import Button from "components/base/button/button";
import { getInitParams } from "helpers/filter.helper";
import { isEqual, isNil } from "lodash";
import isEmpty from "lodash/isEmpty";
import { useAppStore } from "store";

export interface Props {
	label?: string;
	menuClassName?: string;
	buttonClassName?: string;
	children?: React.ReactNode;
	params: IQueryParams;
	setParams: (value: ((prevState: IQueryParams) => IQueryParams) | IQueryParams) => void;
	initParams?: IQueryParams;
}

const FilterMenu = (props: Props) => {
	const { label = "Filter", children, menuClassName, buttonClassName, params, setParams, initParams } = props;
	const { bulkOperationsStore } = useAppStore();
	const filterButtonRef = useRef<HTMLDivElement>(null);
	const { isActive: isOpen, setIsActive: setIsOpen } = useDetectOutsideClick(filterButtonRef, false);

	const openMenu = () => setIsOpen(!isOpen);

	const showClearButton =
		(!isEmpty(params.filterCriteria) &&
			!!params.filterCriteria?.find((filter) => filter.propertyNames.find((name) => name !== "title")) &&
			!isEqual(params.filterCriteria, initParams?.filterCriteria)) ||
		bulkOperationsStore.isShowSelection;

	const clearFilter = () => {
		if (!isNil(initParams))
			setParams({
				...initParams,
				queryString: params.queryString,
			});
		else {
			setParams(getInitParams(10));
		}
		bulkOperationsStore.isShowSelection = false;
		bulkOperationsStore.checkedState = [];
	};

	return (
		<>
			{showClearButton && (
				<Button label="Clear" onClick={clearFilter} className={styles.menu_button__clear_button} size="common" />
			)}
			<div className={styles.menu_button_container} ref={filterButtonRef}>
				<Button
					label={label}
					onClick={openMenu}
					className={classNames(styles.menu_button, buttonClassName)}
					icon={<FilterIcon className={styles.filter_icon} />}
					iconPosition="right"
				/>
				<div className={classNames(styles.filter_menu, { [styles.hide_menu]: !isOpen }, menuClassName)}>{children}</div>
			</div>
		</>
	);
};

export default FilterMenu;
