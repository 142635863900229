import styles from "./styles.module.scss";
import React, { ReactNode } from "react";
import classNames from "classnames/bind";
import Navbar from "components/partial/navbar";
import Footer from "components/base/footer/footer";
import { usePagesPublicSettings } from "hooks/usePagesPublicSettings";
import { observer } from "mobx-react";

interface Props {
	children: ReactNode;
	className?: string;
}

const cx = classNames.bind(styles);

const PrivateLayout = (props: Props) => {
	const { children, className } = props;
	usePagesPublicSettings();
	return (
		<>
			<Navbar />
			<main className={cx(styles.private_layout, className)}>{children}</main>
			<Footer />
		</>
	);
};

export default observer(PrivateLayout);
