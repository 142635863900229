import React, { useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import uniqueId from "lodash/uniqueId";
import { ReactComponent as EyeOn } from "assets/icons/ic_eye-on.svg";
import { ReactComponent as EyeOff } from "assets/icons/ic_eye-off.svg";
import { ReactComponent as AlertCircle } from "assets/icons/ic_alert-circle.svg";

interface Props {
	label: string;
	className?: string;
	showPassword?: boolean;
	name?: string;
	value?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	isError?: boolean;
	errorText?: string;
}

const cx = classNames.bind(styles);

const InputPassword: React.FC<Props> = (props: Props) => {
	const { label, className, showPassword = false, name, value, onChange, onFocus, isError, errorText } = props;
	const [isShowPassword, setShowPassword] = useState(showPassword);
	const [id] = useState(() => uniqueId("id-"));

	return (
		<div className={cx(styles.input_password, className)}>
			<input
				id={id}
				type={isShowPassword ? "text" : "password"}
				className={cx(styles.input_password__input, { "input_password__input--invalid": isError })}
				placeholder={label}
				name={name}
				value={value}
				onChange={onChange}
				onFocus={onFocus}
			/>
			{label && (
				<label htmlFor={id} className={styles.input_password__field_label}>
					{label}
				</label>
			)}
			<div className={styles.input_password__input__show_password} onClick={() => setShowPassword(!isShowPassword)}>
				{isShowPassword ? <EyeOn /> : <EyeOff />}
			</div>
			{isError && (
				<div className={styles.input_password__input_error}>
					<div className={styles.input_password__input_error_box}>
						<AlertCircle className={styles.input_password__input_error_icon} />
					</div>
					<div className={styles.input_password__input_error_text}>{errorText}</div>
				</div>
			)}
		</div>
	);
};

export default InputPassword;
