import { isUndefined } from "lodash";
import { Duration } from "luxon";

export const getFormattedTime = (seconds?: number): string => {
	if (isUndefined(seconds)) return "";

	const duration = Duration.fromObject({ seconds });
	return getFormattedDuration(duration);
};

export const getFormattedDuration = (duration: Duration): string => {
	const currentTime = duration.shiftTo("hours", "minutes", "seconds").toObject();

	if (currentTime.hours && currentTime.hours > 0) return `${duration.toFormat("hh")} hr`;
	if (currentTime.minutes && currentTime.minutes > 0) return `${duration.toFormat("mm")} min`;
	if (currentTime.seconds && currentTime.seconds > 0) return `${duration.toFormat("ss")} sec`;
	else return "";
};

export const convertUTCToLocalDate = (date: Date | number) => {
	if (date) {
		const newDate = new Date(date);
		return new Date(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate());
	}
};

export const convertLocalToUTCDate = (date: Date | number) => {
	if (date) {
		const newDate = new Date(date);
		return new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));
	}
};

export const getTimezoneOffset = (uiFormat?: boolean): string | number => {
	let offset = new Date().getTimezoneOffset();
	if (uiFormat) {
		function convertTimezone(timezone: number) {
			return (timezone < 10 ? "0" : "") + timezone;
		}
		const sign = offset <= 0 ? "+" : "-";
		offset = Math.abs(offset);
		return `(GMT${sign}${convertTimezone((offset / 60) | 0)}:${convertTimezone(offset % 60)})`;
	}
	return offset / 60;
};
