import styles from "./styles.module.scss";
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis } from "recharts";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import React, { useMemo } from "react";
import truncate from "lodash/truncate";
import { mobileCheck, tabletCheck } from "helpers/device.helper";
import { ReactComponent as InfoIcon } from "assets/icons/ic_info.svg";
import TooltipBase from "components/base/tooltip/tooltip";

const GoalsHistogram = () => {
	const { goalsStore } = useAppStore();

	const isMobile = useMemo(() => mobileCheck(), []);
	const isTablet = useMemo(() => tabletCheck, []);
	const isAdaptive = isMobile || isTablet;

	const renderCustomizedLabel = (props: any) => {
		const { x, y, width, value } = props;
		const delta = 10;

		return (
			<text
				x={x + width / 2}
				y={y - delta < 0 ? 15 : y - delta}
				fill="#fff"
				textAnchor="middle"
				dominantBaseline="middle"
				fontSize={14}
				fontWeight={700}
			>
				{`${value}%`}
			</text>
		);
	};

	const itemFormatter = (value: string): string =>
		truncate(value, {
			length: isAdaptive ? 3 : 7,
			omission: isAdaptive ? "" : "...",
		});

	return (
		<LoadingIndicator loading={goalsStore.isLoading} spinnerPosition={"center"}>
			<div className={styles.goals_histogram}>
				<div className={styles.goals_histogram__wrapper}>
					<h1 className={styles.goals_histogram__title}>Your Progress So Far</h1>
					<TooltipBase
						label={
							"Each bar shows how well the learner met their target percentage of learning time for each category. For a given category, we calculate the percentage of their total learning time spent in content in that category and compare it to the target percentage set as a goal in My Perfect Brain. For example, let’s say a learner has set a goal to spend 10% of their total learning time studying Motivation. If they spent 9% of their total learning time for it, then the bar will show 90% of goal fulfillment. If the learner spent 11% of their total learning time, then bar will show 110%."
						}
						className={styles.goals_histogram__info_tooltip}
						classNameContent={styles.goals_histogram__tooltip__content}
					>
						<InfoIcon className={styles.goals_histogram__info_icon} />
					</TooltipBase>
				</div>
				<ResponsiveContainer width="100%" height="80%" className={styles.goals_histogram__bar_box}>
					<BarChart data={goalsStore.dataBar}>
						<Bar
							dataKey="value"
							fill="#8884d8"
							background={{ fill: "var(--background-layout-header)" }}
							minPointSize={1}
							stackId="stack"
							barSize={31}
						>
							{goalsStore.dataBar.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={goalsStore.colorBar[index]} strokeWidth={1} strokeOpacity={0.5} />
							))}
						</Bar>
						<Bar dataKey="highValue" stackId="stack">
							<LabelList dataKey="original" position="top" content={renderCustomizedLabel} />
							{goalsStore.dataBar.map((entry, index) => (
								<Cell
									key={`cell-bar-${index}`}
									fill={goalsStore.highColorBar[index]}
									strokeWidth={1}
									strokeOpacity={0.5}
								/>
							))}
						</Bar>
						<XAxis dataKey="name" fontSize={10} tickFormatter={itemFormatter} />
					</BarChart>
				</ResponsiveContainer>
			</div>
		</LoadingIndicator>
	);
};

export default observer(GoalsHistogram);
