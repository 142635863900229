import styles from "./styles.module.scss";
import { Column } from "react-table";
import { IReportModel } from "store/reports/reports-store";
import Button from "components/base/button/button";

export const columns: Column<IReportModel>[] = [
	{
		Header: "Reports",
		columns: [
			{
				Header: "Name",
				accessor: "reportName",
				Cell({ cell: { value } }) {
					return <div className={styles.all_reports__report_name}>{value}</div>;
				},
			},
			{
				Header: "",
				accessor: "onExport",
				Cell({ cell: { value } }) {
					return <Button className={styles.all_reports__export_button} onClick={value} label="Export" />;
				},
			},
		],
	},
];
