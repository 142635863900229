import * as Yup from "yup";
import {
	customNameValidator,
	descriptionValidator,
	emailValidator,
	expertisesValidator,
	simpleTextNotReqValidator,
	simpleTextValidator,
} from "helpers/validator.helper";

const baseSchema = {
	firstName: customNameValidator(
		"'First Name' can not contain special symbols (except for the space and dash characters)",
		"The first name length must be more than 2 symbols.",
		"The first name length must be less than 256 symbols."
	).nullable(),
	lastName: customNameValidator(
		"'Last Name' can not contain special symbols (except for the space and dash characters) ",
		"The last name length must be more than 2 symbols.",
		"The last name length must be less than 256 symbols."
	).nullable(),
	jobTitle: simpleTextValidator(
		"'Job Title' can not contain special symbols (except for the space and dash characters)",
		"The job title length must be less than 256 symbols.",
		true
	).nullable(true),

	country: simpleTextNotReqValidator(
		"'Country' can not contain special symbols (except for the space and dash characters)",
		"The country name length must be less than 256 symbols."
	).nullable(true),
	city: simpleTextNotReqValidator(
		"'City' can not contain special symbols (except for the space and dash characters)",
		"The city name length must be less than 256 symbols."
	).nullable(true),
};

export const getUserSchema = (isNewUser: boolean) => {
	const userSchema = {
		email: isNewUser ? emailValidator : Yup.string(),
	};
	const schema = { ...baseSchema, ...userSchema };
	return Yup.object().shape(schema);
};

export const getInfluencerSchema = () => {
	const influencerSchema = {
		userPicture: Yup.object().nullable().required("User picture is required"),
		description: descriptionValidator.nullable(),
		expertises: expertisesValidator.nullable(),
	};
	const schema = { ...baseSchema, ...influencerSchema };
	return Yup.object().shape(schema);
};
