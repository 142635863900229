import { CarouselDataType } from "components/partial/carousels-array/carousel-data-provider";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";

export const isCoursesCarousel = (items: CarouselDataType[]): items is IPreviewCourseModel[] => {
	if (items.length) {
		return "z1Rating" in items[0];
	}
	return false;
};
export const isInfluencersCarousel = (items: CarouselDataType[]): items is IInfluencerDataModel[] => {
	if (items.length) {
		return "firstName" in items[0] && "lastName" in items[0];
	}
	return false;
};
export const isPeersCarousel = (items: CarouselDataType[]): items is IThreadModel[] => {
	if (items.length) {
		return "discussionName" in items[0] && "author" in items[0];
	}
	return false;
};

export const isCourseObj = (item: IPreviewCourseModel): item is IPreviewCourseModel => {
	if (item) {
		return "z1Rating" in item;
	}
	return false;
};
export const isInfluencerObj = (item: IInfluencerDataModel): item is IInfluencerDataModel => {
	if (item) {
		return "firstName" in item && "lastName" in item;
	}
	return false;
};
export const isPeerObj = (item: IThreadModel): item is IThreadModel => {
	if (item) {
		return "discussionName" in item && "author" in item;
	}
	return false;
};

export const getObjectType = (object: any): "course" | "influencer" | "thread" => {
	if (isInfluencerObj(object)) {
		return "influencer";
	}
	if (isPeerObj(object)) {
		return "thread";
	}
	return "course";
};
