import { useCallback, useEffect, useRef } from "react";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { useAppStore } from "store";

export const useBulkSelections = (permissions: string[], initParams?: IQueryParams) => {
	const { searchStore, commonStore, usersStore, bulkOperationsStore } = useAppStore();
	const oldParams = useRef<IQueryParams>({} as IQueryParams);

	const isFiltered = useCallback(
		(params: IQueryParams) => !isEmpty(params.queryString) || !isEmpty(params.filterCriteria),
		[]
	);

	useEffect(() => {
		if (!isEmpty(searchStore.params) && usersStore.checkOneOfUserPermissions(permissions)) {
			if (isFiltered(searchStore.params)) {
				const params = omit(cloneDeep(searchStore.params), ["skip", "take"]);
				if (!isEqual(params, oldParams.current)) {
					oldParams.current = params;
				}
			}
			if (initParams) {
				bulkOperationsStore.isShowSelection = !isEqual(searchStore.params.filterCriteria, initParams.filterCriteria);
			} else if (isFiltered(searchStore.params) !== bulkOperationsStore.isShowSelection) {
				bulkOperationsStore.isShowSelection = !bulkOperationsStore.isShowSelection;
			}
		}
	}, [
		isFiltered,
		searchStore.params,
		bulkOperationsStore,
		usersStore,
		permissions,
		commonStore.alertEventBus,
		initParams,
	]);
};
