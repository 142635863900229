import React, { CSSProperties } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface Props {
	label: React.ReactNode;
	className?: string;
	classNameHead?: string;
	classNameContent?: string;
	isActive?: boolean;
	inlineStyles?: CSSProperties;
}

const Tooltip: React.FC<Props> = ({
	label,
	className,
	classNameHead,
	classNameContent,
	isActive = true,
	children,
	inlineStyles,
}) => {
	return (
		<div style={inlineStyles} className={classNames(styles.tooltip, classNameHead)}>
			<div className={styles.tooltip__hover_component}>{children}</div>
			{isActive && (
				<div className={classNames(styles.tooltip__label, className)}>
					<div className={classNames(classNameContent, styles.tooltip__content)}>{label}</div>
				</div>
			)}
		</div>
	);
};

export default Tooltip;
