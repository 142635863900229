import uniqueId from "lodash/uniqueId";
import React, { ReactNode, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as AlertCircle } from "assets/icons/ic_alert-circle.svg";

export interface CheckboxProps {
	label?: string;
	onChange?: (value: boolean) => void;
	value?: boolean;
	name?: string;
	className?: string;
	innerClassName?: string;
	isDisabled?: boolean;
	children?: ReactNode;
	isError?: boolean;
	errorText?: string;
	theme?: "default" | "quiz" | "transparent" | "cms" | "assignments" | "locked";
	type?: "checkbox" | "radio";
}

const cx = classNames.bind(styles);

const Checkbox: React.FC<CheckboxProps> = ({
	label,
	value = false,
	isDisabled = false,
	className,
	onChange,
	name,
	children,
	isError,
	errorText,
	theme = "default",
	type = "checkbox",
	innerClassName,
}) => {
	const [id] = useState(() => uniqueId("check-id-"));

	return (
		<div
			className={cx(styles.custom_checkbox, className, `custom_checkbox__theme_${theme}`, {
				custom_checkbox__disabled: isDisabled,
			})}
			onClick={(e) => e.stopPropagation()}
		>
			<input
				id={id}
				type={type}
				className={styles.custom_checkbox__input}
				onChange={() => onChange?.(!value)}
				checked={Boolean(value)}
				value={String(value)}
				name={name}
				disabled={isDisabled}
			/>
			<label className={cx(innerClassName, styles.custom_checkbox__label)} htmlFor={id}>
				{children ?? label}
			</label>
			{isError && (
				<div className={styles.custom_checkbox__input_error}>
					<AlertCircle className={styles.custom_checkbox__input_error_icon} />
					<div className={styles.custom_checkbox__input_error_text}>{errorText}</div>
				</div>
			)}
		</div>
	);
};

export default Checkbox;
