import styles from "../styles.module.scss";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import CarouselsArray from "components/partial/carousels-array/carousels-array";
import { getOrderedCarousels } from "helpers/settings.helper";


export const MyDeskCoursesSection = () => {
	const { settingsStore } = useAppStore();
	const sortedCarousels = getOrderedCarousels(settingsStore.publicSettings, "MyDesk.Carousels.Order");

	return (
		<div className={styles.myDesk__courses_section}>
			<CarouselsArray carouselsKeys={sortedCarousels} />
		</div>
	);
};

export default observer(MyDeskCoursesSection);
