import { StylesConfig } from "react-select";

export const dropdownStyles: StylesConfig = {
	valueContainer: (styles) => ({
		...styles,
		maxHeight: "100%",
		overflow: "auto",
		"::-webkit-scrollbar": {
			width: "4px",
			height: "0px",
		},
		"::-webkit-scrollbar-track": {
			background: "#f1f1f1",
		},
		"::-webkit-scrollbar-thumb": {
			background: "#888",
		},
		"::-webkit-scrollbar-thumb:hover": {
			background: "#555",
		},
	}),
	multiValue: (styles) => ({
		...styles,
		backgroundColor: "#565656",
	}),
	multiValueLabel: (styles) => ({
		...styles,
		color: "white",
	}),
	multiValueRemove: (styles) => ({
		...styles,
		":hover": {
			backgroundColor: "#9b9b9b",
		},
	}),
};
