import { makeAutoObservable, runInAction } from "mobx";
import { UsersApi } from "api/controllers/UsersApi";
import { IUpdateProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IUpdateProfileDataModel";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { IAccountModel } from "models/dto/ZoomiLxp/Models/Authentication/IAccountModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { ICreateProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/ICreateProfileDataModel";
import { chain, Dictionary, filter, includes, isNil, lowerCase, sortBy } from "lodash";
import { IRoleModel } from "models/dto/ZoomiLxp/Models/Profile/IRoleModel";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { IBaseModel } from "models/dto/ZoomiLxp/Models/Common/IBaseModel";
import { downloadFile } from "helpers/file-download.helper";
import { IImportUsersResult } from "models/dto/ZoomiLxp/Models/Authentication/IImportUsersResult";
import { AccountRoles } from "models/dto/ZoomiLxp/Utilities/Enumerations/AccountRoles";
import { containsRole } from "helpers/security.helper";
import { AppStore } from "../index";
import jwtDecode from "jwt-decode";
import isEmpty from "lodash/isEmpty";
import { ClaimTypes } from "models/dto/ZoomiLxp/Utilities/Constants/ClaimTypes";
import { IPreviewProfileModel } from "models/dto/ZoomiLxp/Models/Profile/IPreviewProfileModel";

export class UsersStore {
	private _isLoading: boolean = false;
	private _influencersShortInfo: IAccountModel[] = [];
	private _currentUserInfo: IProfileDataModel | null = null;
	coursesForJobTitle: IPreviewCourseModel[] = [];
	profilePreviewData: IPreviewProfileModel | null = null;
	private _permissionGroups: IBaseModel[] = [];
	private rootStore;
	cmsChosenUserNameKey = "cmsUserDisplayName";
	private _permissions: string[] = [];

	constructor(rootStore: AppStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);
	}

	async addNewUser(data: ICreateProfileDataModel) {
		this.isLoading = true;
		try {
			const response = await UsersApi.addNewUser(data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getCurrentUser() {
		if (!this.currentUserInfo) {
			await this.refreshCurrentUser(this.rootStore.accountStore.wasLogin);
		}
		return this.currentUserInfo;
	}

	async getUserById(id: number) {
		this.isLoading = true;
		try {
			const response = await UsersApi.getUserById(id);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getProfilePreviewByUserId(userId: number) {
		this.isLoading = true;
		try {
			const response = await UsersApi.getProfilePreview(userId);
			runInAction(() => {
				this.profilePreviewData = response.data.data;
			});
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getPermissionsGroups() {
		this.isLoading = true;
		try {
			const response = await UsersApi.getPermissionsGroups();
			const permissionGroups = sortBy(response.data.data, ["name"]);
			runInAction(() => {
				this.permissionGroups = permissionGroups;
			});
			return permissionGroups;
		} finally {
			this.isLoading = false;
		}
	}

	getPermissions() {
		const accessToken = this.rootStore.accountStore.accessToken;
		if (!isNil(accessToken) && !isEmpty(accessToken)) {
			const decodedToken: Dictionary<string | number> = jwtDecode(accessToken);
			if (!isEmpty(decodedToken[ClaimTypes.PermissionsClaimType])) {
				runInAction(() => {
					this.permissions = chain(decodedToken[ClaimTypes.PermissionsClaimType]).split(",").sortBy().value();
				});
			} else {
				runInAction(() => {
					this.permissions = [];
				});
			}
		}
	}

	checkOneOfUserPermissions(allowedPermissions: string[], user?: IProfileDataModel) {
		const userPermissions = user ? user.permissions : this.permissions;
		if (isEmpty(userPermissions) || isEmpty(allowedPermissions)) return false;
		return allowedPermissions.some((permission: string) => includes(userPermissions, permission));
	}

	checkAllUserPermissions(allowedPermissions: string[], user?: IProfileDataModel) {
		const userPermissions = user ? user.permissions : this.permissions;
		if (isEmpty(userPermissions) || isEmpty(allowedPermissions)) return false;
		return allowedPermissions.every((permission: string) => includes(userPermissions, permission));
	}

	clearPermissions() {
		runInAction(() => {
			this.permissions = [];
		});
	}

	clearCurrentUserInfo() {
		runInAction(() => {
			this.currentUserInfo = null;
		});
	}

	findGroups = (queryString: string): Promise<IBaseModel[]> => {
		return Promise.resolve(filter(this.permissionGroups, (group) => lowerCase(group.name).includes(queryString)));
	};

	private hasRole(role: AccountRoles): boolean {
		return containsRole(this.currentUserInfo?.roles, role);
	}

	async updateUser(data: IUpdateProfileDataModel) {
		this.isLoading = true;
		const updatedData = { ...data, groupAssignments: this.rootStore.groupStore.groupsAssignmentRequestData };
		try {
			const response = await UsersApi.updateUser(updatedData);
			if (data.accountId === this.currentUserInfo!.id) {
				this.currentUserInfo = response.data.data;
				return response.data.data;
			} else {
				return response.data.data;
			}
		} finally {
			this.rootStore.groupStore.clearGroupsAssignPopupData();
			this.isLoading = false;
		}
	}

	async downloadBulkTemplate() {
		this.isLoading = true;
		try {
			await UsersApi.downloadBulkTemplate().then((response) => {
				downloadFile(response);
			});
		} finally {
			this.isLoading = false;
		}
	}

	async usersBulkUpload(fileId: string) {
		this.isLoading = true;
		try {
			const response = await UsersApi.usersBulkUpload(fileId);
			return response.data.data as IImportUsersResult;
		} finally {
			this.isLoading = false;
		}
	}

	async getInfluencers(data: IQueryParams) {
		this.isLoading = true;
		try {
			const response = await UsersApi.getInfluencers(data);

			runInAction(() => {
				this.influencersShortInfo = response.data.data.records;
			});
			return response.data.data.records;
		} finally {
			this.isLoading = false;
		}
	}

	async refreshCurrentUser(noCache?: boolean) {
		const response = await UsersApi.getCurrentUser(noCache);
		this.getPermissions();
		runInAction(() => {
			this.currentUserInfo = response.data.data;
			this.rootStore.accountStore.wasLogin = false;
		});
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get isLoading() {
		return this._isLoading;
	}

	set influencersShortInfo(influencersInfo: IAccountModel[]) {
		this._influencersShortInfo = influencersInfo;
	}

	get isTenantAdmin(): boolean {
		return this.hasRole(AccountRoles.TenantAdmin);
	}

	get isAdmin(): boolean {
		return this.hasRole(AccountRoles.MasterAdmin) || this.isTenantAdmin;
	}

	get cmsChosenUserName(): string | null {
		return window.sessionStorage.getItem(this.cmsChosenUserNameKey);
	}

	get currentUserRole(): IRoleModel {
		if (!isNil(this.currentUserInfo) && this.currentUserInfo?.roles.length) {
			return this.currentUserInfo.roles[0];
		}
		return {} as IRoleModel;
	}

	get influencersShortInfo() {
		return this._influencersShortInfo;
	}

	get permissionGroups(): IBaseModel[] {
		return this._permissionGroups;
	}

	set permissionGroups(value: IBaseModel[]) {
		this._permissionGroups = value;
	}

	get permissions(): string[] {
		return this._permissions;
	}

	set permissions(value: string[]) {
		this._permissions = value;
	}

	get currentUserInfo(): IProfileDataModel | null {
		return this._currentUserInfo;
	}

	set currentUserInfo(value: IProfileDataModel | null) {
		this._currentUserInfo = value;
	}
}
