import React, { ReactNode, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useAppStore } from "store";

interface GetAccessProps {
	permissions?: string[];
	checkCondition?: () => boolean;
	children?: ReactNode;
}

const AccessWrapper = (props: GetAccessProps) => {
	const { permissions, children, checkCondition } = props;
	const { usersStore } = useAppStore();

	const [isAccessGranted, setAccessGranted] = useState(false);

	useEffect(() => {
		if (!isEmpty(usersStore.permissions)) {
			if (checkCondition) {
				setAccessGranted(Boolean(checkCondition?.()));
			} else if (permissions && !isEmpty(permissions)) {
				setAccessGranted(usersStore.checkOneOfUserPermissions([...permissions]));
			}
		}
	}, [permissions, checkCondition, usersStore]);

	return <>{isAccessGranted && children}</>;
};

export default AccessWrapper;
