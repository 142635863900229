import { Dictionary } from "lodash";
import { useEffect, useState } from "react";
import { useAppStore } from "store";

export const usePagesPublicSettings = () => {
	const { settingsStore, toasterStore } = useAppStore();
	const [pagesPublicSettings, setPagesPublicSettings] = useState<Dictionary<string>>(
		settingsStore.publicSettings.Pages
	);

	useEffect(() => {
		settingsStore
			.getPublicSettings()
			.then((data: Dictionary<Dictionary<string>>) => setPagesPublicSettings(data.Pages))
			.catch((error: unknown) => {
				toasterStore.showErrorMessage(error);
			});
	}, [settingsStore, toasterStore]);

	return pagesPublicSettings;
};
