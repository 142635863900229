import styles from "./styles.module.scss";
import { ReactNode } from "react";
import classNames from "classnames/bind";
import Footer from "components/base/footer/footer";
import { usePagesPublicSettings } from "hooks/usePagesPublicSettings";
import { observer } from "mobx-react";

interface Props {
	children: ReactNode;
	className?: string;
}

const cx = classNames.bind(styles);

const PublicLayout = (props: Props) => {
	const { children, className } = props;
	usePagesPublicSettings();

	return (
		<>
			<main className={cx(styles.public_layout, className)}>{children}</main>
			<Footer isPublic />
		</>
	);
};

export default observer(PublicLayout);
