import { useAppStore } from "store";

export const usePlayerNav = () => {
	const { coursesStore } = useAppStore();

	const prev = () => {
		if (coursesStore.curPositionContent > 0) {
			coursesStore.prevPositionContent = coursesStore.curPositionContent;
			coursesStore.curPositionContent--;
		}
		coursesStore.isCourseEnded = false;
	};

	const next = () => {
		if (
			coursesStore.curPositionContent + 1 < coursesStore.countContents &&
			(coursesStore.hasNextItemInSameChapter || coursesStore.nextChapterUnlocked)
		) {
			coursesStore.prevPositionContent = coursesStore.curPositionContent;
			coursesStore.curPositionContent++;
		} else coursesStore.isCourseEnded = true;
	};

	return { prev, next };
};
