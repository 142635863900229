import { ILearningPathItem } from "models/dto/ZoomiLxp/Models/Analytics/ILearningPathItem";
import { Position, Node, Edge, MarkerType } from "reactflow";

export interface IMyLearningPathPaging {
	onLoadMore: () => void;
	hasMore: boolean;
}

export const BCKWRD_TOP_SRC_ID = "backwardTopSource";
export const BCKWRD_BTTM_SRC_ID = "backwardBottomSource";
export const BCKWRD_TOP_TRGT_ID = "backwardTopTarget";
export const BCKWRD_BTTM_TRGT_ID = "backwardBottomTarget";
export const LEFT_TRGT_ID = "leftTarget";
export const RIGHT_SRC_ID = "rightSource";
const PHANTOM_NODE_ID = "phantom-node";

export const defaultEdgeProps: Partial<Edge> = {
	markerEnd: {
		type: MarkerType.ArrowClosed,
		width: 10,
		height: 10,
	},
	focusable: true,
	animated: true,
	style: {
		strokeWidth: 4,
	},
};

export const getSourceHandlesId = (backwardItem?: ILearningPathItem) => {
	if (backwardItem && backwardItem.row === 1) {
		return BCKWRD_TOP_SRC_ID;
	}
	if (backwardItem && backwardItem.row === 2) {
		return BCKWRD_BTTM_SRC_ID;
	}
	return RIGHT_SRC_ID;
};

export const getTargetHandlesId = (item?: ILearningPathItem) => {
	if (item && item.row === 1) {
		return BCKWRD_TOP_TRGT_ID;
	}
	if (item && item.row === 2) {
		return BCKWRD_BTTM_TRGT_ID;
	}
	return LEFT_TRGT_ID;
};

export const getAnchorPosition = (item: ILearningPathItem, isSource?: boolean) => {
	if (item.backwardTo && item.row === 1) {
		return Position.Top;
	}
	if (item.backwardTo && item.row === 2) {
		return Position.Bottom;
	}

	return isSource ? Position.Right : Position.Left;
};

export const getBackwardItem = (item: ILearningPathItem, array: ILearningPathItem[]) => {
	const backwardItem = array.find((backItem) => item.backwardTo && backItem.toSessionId === item.backwardTo);
	return backwardItem;
};

export const checkIsBackwardItem = (item: ILearningPathItem, array: ILearningPathItem[]) => {
	const backwardItemsIds = array.filter((item) => item.backwardTo).map((item) => item.backwardTo);
	const isBackward = backwardItemsIds.some((backItemId) => backItemId === item.toSessionId);
	return isBackward;
};

export const checkIsSameLine = (item: ILearningPathItem, arr: ILearningPathItem[]) => {
	const currentItemIdx = arr.findIndex((arrItem) => arrItem.toSessionId === item.toSessionId);
	const prevItem = arr[currentItemIdx - 1];
	const nextItem = arr[currentItemIdx + 1];
	const isOnSameLine = item.row === prevItem?.row && item.row === nextItem?.row;
	return { prevItem, nextItem, isOnSameLine };
};

export const getLastNodesCoords = (nodes: Node[]) => {
	if (nodes.length < 5) {
		return { x: 0, y: 0, zoom: 1 };
	}
	const lastNode = nodes[nodes.length - 1];
	const lastNodeCoords = lastNode?.position;
	const midNode = nodes[Math.round(nodes.length / 2)];
	const midNodeCoords = midNode?.position;
	return {
		x: midNodeCoords.x - lastNodeCoords.x + 300,
		y: 100,
		zoom: 0.5,
	};
};

export const getPhantomNodeData = (pathItems: ILearningPathItem[], pagingParams?: IMyLearningPathPaging) => {
	const isPhantomSource = pathItems[0].row !== pathItems[1]?.row && !!pathItems[0].backwardTo;
	const phantomNode: Node = {
		id: PHANTOM_NODE_ID,
		position: { x: -700, y: pathItems[0].row === 1 ? 0 : 300 },
		data: { isPhantom: true, onLoadMore: pagingParams?.onLoadMore },
		type: "custom",
		sourcePosition: isPhantomSource ? getAnchorPosition(pathItems[0], true) : Position.Right,
		targetPosition: isPhantomSource ? getAnchorPosition(pathItems[0], false) : Position.Left,
	};

	const phantomEdges: Edge[] = [
		{
			...defaultEdgeProps,
			id: "phantom-edge-top",
			source: String(pathItems[0]?.toSessionId),
			target: PHANTOM_NODE_ID,
			sourceHandle: BCKWRD_TOP_SRC_ID,
			targetHandle: BCKWRD_TOP_TRGT_ID,
		},
		{
			...defaultEdgeProps,
			id: "phantom-edge-mid",
			source: PHANTOM_NODE_ID,
			target: String(pathItems[0]?.toSessionId),
			sourceHandle: RIGHT_SRC_ID,
			targetHandle: LEFT_TRGT_ID,
		},
		{
			...defaultEdgeProps,
			id: "phantom-edge-bottom",
			source: PHANTOM_NODE_ID,
			target: String(pathItems[1]?.toSessionId),
			sourceHandle: BCKWRD_BTTM_SRC_ID,
			targetHandle: LEFT_TRGT_ID,
		},
	];

	const getPhantomEdges = () => {
		if (isPhantomSource) {
			return phantomEdges;
		}
		return phantomEdges.filter((edge) => edge.id.includes("mid"));
	};

	return { isPhantomSource, phantomNode, phantomEdges, getPhantomEdges };
};
