import styles from "./styles.module.scss";
import { ReactComponent as LikeIcon } from "assets/icons/ic_heart.svg";
import React, { MouseEventHandler } from "react";
import classNames from "classnames";

interface ButtonLikeProps {
	className?: string;
	isSecondaryType: boolean;
	isActive: boolean;
	isLikable: boolean;
	onClick?: MouseEventHandler<SVGSVGElement>;
}

const cx = classNames.bind(styles);
const ButtonLike = (props: ButtonLikeProps) => {
	const { className, isSecondaryType, isActive, isLikable, onClick } = props;
	return (<LikeIcon
		className={cx(className, styles.button_like__like_icon, {
			[styles.button_like__like_icon__likable]: isLikable,
			[styles.button_like__like_icon__disliked]: !isActive,
			[styles.button_like__like_icon__secondary]: isSecondaryType,
			[styles.button_like__like_icon__secondary__disliked]: isSecondaryType && !isActive,
		})}
		onClick={isLikable ? onClick : () => {}}
	/>);
};

export default ButtonLike;
