import { EmailSubstitutions } from "../models/dto/ZoomiLxp/Utilities/Constants/EmailSubstitutions";

const juice = require("juice");

export const replaceLogo = (html: string, url: string): string => html.replaceAll(EmailSubstitutions.LogoUrl, url);

export const replaceColors = (html: string): string => {
	const backgroundNavbar = document.documentElement.style.getPropertyValue("--background-navbar");
	const backgroundLayout = document.documentElement.style.getPropertyValue("--background-layout");
	const textGeneral = document.documentElement.style.getPropertyValue("--text-general");
	const primary = document.documentElement.style.getPropertyValue("--primary");

	const exchangeColor = [
		{ source: EmailSubstitutions.BackgroundNavbarColor, target: backgroundNavbar },
		{ source: EmailSubstitutions.BackgroundLayoutColor, target: backgroundLayout },
		{ source: EmailSubstitutions.TextGeneralColor, target: textGeneral },
		{ source: EmailSubstitutions.PrimaryColor, target: primary },
	];

	let result = html;
	for (const item of exchangeColor) {
		result = result.replaceAll(item.source, item.target);
	}
	return result;
};

export const replaceContent = (html: string | undefined, content: string): string => {
	const textGeneral = document.documentElement.style.getPropertyValue("--text-general");
	const styleCss = `<style>
		mark {
			color: ${textGeneral};
			background-color: #ffff00;
			font-family: Arial,Helvetica,sans-serif;
			font-size: 16px;
			font-weight: 400;
			line-height: normal;
			margin: 0;
			padding: 0;
			text-align: left;
		}
		p {
			color: ${textGeneral};
			font-family: Arial,Helvetica,sans-serif;
			font-size: 16px;
			font-weight: 400;
			line-height: normal;
			margin: 0;
			margin-bottom: 10px;
			padding: 0;
			text-align: left;
			min-height: 18px;
		}
		span {
			color: ${textGeneral};
			font-family: Arial,Helvetica,sans-serif;
			font-size: 16px;
			font-weight: 400;
			line-height: normal;
			margin: 0;
			padding: 0;
			text-align: left;
			min-height: 18px;
		}
		pre {
			color: ${textGeneral};
			font-family: Arial,Helvetica,sans-serif;
			font-size: 16px;
			font-weight: 400;
			line-height: normal;
			margin: 0;
			padding: 0;
			text-align: left;
			min-height: 18px;
		}
		</style>`;

	const separator =
		'<table align="center"><tbody><tr><td height="16" style="font-size:16px;line-height:16px;">&nbsp;</td></tr></tbody></table>';
	const message = content.replaceAll("<p></p>", separator);

	let inlineHtml = juice(styleCss + message);
	inlineHtml = inlineHtml.replaceAll("<mark", "<span");
	inlineHtml = inlineHtml.replaceAll("</mark>", "</span>");

	const pattern = /(<th\sid="content"\s.*?>)(.*?)(<\/th>.*?<th class="expander")/gms;
	return html ? html.replace(pattern, `$1${inlineHtml}$3`) : "";
};

export const replacePlatformName = (html: string, platformName: string) => {
	const url = window.location.origin;
	const result = html.replaceAll(EmailSubstitutions.PlatformName, platformName);
	return result.replaceAll(EmailSubstitutions.PlatformNameUrl, url);
};
