import Api, { ApiBase } from "api/utils/api";
import { AxiosResponse } from "axios";
import { IAccountModel } from "models/dto/ZoomiLxp/Models/Authentication/IAccountModel";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import { ICreateProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/ICreateProfileDataModel";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { IUpdateProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IUpdateProfileDataModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { IBaseModel } from "models/dto/ZoomiLxp/Models/Common/IBaseModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { IImportUsersResult } from "models/dto/ZoomiLxp/Models/Authentication/IImportUsersResult";
import { IPreviewProfileModel } from "models/dto/ZoomiLxp/Models/Profile/IPreviewProfileModel";

const noCacheHeaders = { headers: { "Cache-Control": "no-cache" } };
export class UsersApi {
	static getCurrentUser(noCache?: boolean) {
		return Api.get<IProfileDataModel>("/account/profile", noCache ? noCacheHeaders : {});
	}

	static getUserById(accountId: number) {
		return Api.get<IProfileDataModel>(`/account/profile/${accountId}`, noCacheHeaders);
	}

	static getProfilePreview(userId: number) {
		return Api.get<IPreviewProfileModel>(`/account/PreviewProfile/${userId}`);
	}

	static getInfluencers(data: IQueryParams) {
		return ApiBase.post<IQueryParams, AxiosResponse<IResponseModel<IGetRecordsResponse<IAccountModel>>>>(
			"/account/influencers",
			data
		);
	}

	static addNewUser(data: ICreateProfileDataModel) {
		return Api.post<IProfileDataModel, ICreateProfileDataModel>("account/addUser", data);
	}

	static updateUser(data: IUpdateProfileDataModel) {
		return Api.post<IProfileDataModel, IUpdateProfileDataModel>("/account/updateProfile", data);
	}

	static getCoursesForJobTitle(userId: number, data: IPagingParams) {
		return Api.get<IGetRecordsResponse<IPreviewCourseModel>>(`/account/CoursesForJobTitle/${userId}`, {
			params: data,
		});
	}

	static getPermissionsGroups() {
		return Api.get<IBaseModel[]>("/group/getAll");
	}

	static downloadBulkTemplate() {
		return Api.get("/Account/DownloadImportUsersTemplate", {
			responseType: "blob",
		});
	}

	static usersBulkUpload(fileId: string) {
		return Api.post<IImportUsersResult>("/Account/Import", { fileId });
	}
}
