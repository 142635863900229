import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import PlayerControl, { ControlParams } from "../player-control/player-control";
import PlayerQuiz from "../player-quiz/player-quiz";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { getFormattedProgress } from "helpers/string.helper";
import { usePlayerNav } from "hooks/usePlayerNav";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { usePlayerTool } from "hooks/usePlayerTool";
import { IPlayerPropsBase } from "../data/data";
import { isEmpty, isNil } from "lodash";

interface IPlayerQuizBoxProps extends IPlayerPropsBase {}

const cx = classNames.bind(styles);

const PlayerQuizBox = (props: IPlayerQuizBoxProps) => {
	const { className, onEventHandle } = props;
	const { quizStore, coursesStore, contentStore, playerStore } = useAppStore();
	const { prev, next } = usePlayerNav();

	const handlePrev = async () => {
		contentStore.stopKeepAlive();
		quizStore.clearStore();
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Complete,
			payload: "",
		};
		await onEventHandle(data);
		prev();
	};

	const handleNext = async () => {
		contentStore.stopKeepAlive();
		quizStore.clearStore();
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Complete,
			payload: "",
		};
		await onEventHandle(data);
		coursesStore.curActiveContent.completed = true;
		next();
	};

	const initParams: ControlParams = {
		currentProgress: 1,
		totalProgress: 10,
		boxRef: useRef<HTMLDivElement>(null),
		getFormattedProgress,
		muted: true,
		playing: false,
		isDownload: false,
		handleNext,
		handlePrev,
		isPrev: false,
		isNext: false,
	};

	const [params, setParams] = useState<ControlParams>(initParams);

	useEffect(() => {
		playerStore.params = params;
	}, [params, playerStore]);

	const { disableControls, enableControls } = usePlayerTool(setParams);

	useEffect(() => {
		setParams((prev: ControlParams) => ({
			...prev,
			totalProgress:
				quizStore.quiz.allowedSingleAttempt && quizStore.quiz.attempted && quizStore.finishAssessment
					? initParams.totalProgress
					: quizStore.questionsCount,
		}));
	}, [
		quizStore.questionsCount,
		quizStore.quiz,
		quizStore.quiz.allowedSingleAttempt,
		quizStore.quiz.attempted,
		quizStore.finishAssessment,
		initParams.totalProgress,
	]);

	useEffect(() => {
		setParams((prev: ControlParams) => ({ ...prev, currentProgress: quizStore.indexQuestion }));
	}, [quizStore.indexQuestion]);

	useEffect(() => {
		return () => {
			if(coursesStore.curPositionContent + 1 < coursesStore.countContents) {
				contentStore.sessionId = undefined;
			}
		};
	}, [contentStore, coursesStore]);

	useEffect(() => {
		const isRequiredStarted =
			quizStore.quiz.required && (isNil(quizStore.finishAssessment) || isEmpty(quizStore.finishAssessment));
		const isQuizFailed =
			!isNil(quizStore.finishAssessment) &&
			!isEmpty(quizStore.finishAssessment) &&
			!quizStore.finishAssessment.isPassed;

		if (!contentStore.sessionId || quizStore.isLoading) {
			disableControls();
		} else if (isRequiredStarted || isQuizFailed) {
			enableControls("prev");
		} else enableControls();
	}, [
		disableControls,
		enableControls,
		quizStore.quiz.required,
		contentStore.sessionId,
		quizStore.isLoading,
		quizStore.finishAssessment,
	]);

	return (
		<div className={cx(styles.player_quiz_box, className)}>
			<PlayerQuiz params={params} setParams={setParams} onEventHandle={onEventHandle}>
				<PlayerControl params={params} />
			</PlayerQuiz>
		</div>
	);
};

export default observer(PlayerQuizBox);
