import { makeAutoObservable } from "mobx";
import { ControlParams } from "components/partial/player/player-control/player-control";
import { RefObject } from "react";

export class PlayerStore {
	private _params: ControlParams | undefined;
	private _heightPage: number = 0;
	private _documentRef: RefObject<HTMLDivElement> | null = null;
	private _zoomMultiplier: number = 1;

	constructor() {
		makeAutoObservable(this);
	}

	get zoomMultiplier(): number {
		return this._zoomMultiplier;
	}

	set zoomMultiplier(value: number) {
		this._zoomMultiplier = value;
	}

	get params(): ControlParams | undefined {
		return this._params;
	}

	set params(value: ControlParams | undefined) {
		this._params = value;
	}

	get heightPage(): number {
		return this._heightPage;
	}

	set heightPage(value: number) {
		this._heightPage = value;
	}

	get documentRef(): RefObject<HTMLDivElement> | null {
		return this._documentRef;
	}

	set documentRef(value: RefObject<HTMLDivElement> | null) {
		this._documentRef = value;
	}
}
