import Api from "../utils/api";
import { ICreateGoalModel } from "models/dto/ZoomiLxp/Models/Goals/ICreateGoalModel";
import { IGoalModel } from "models/dto/ZoomiLxp/Models/Goals/IGoalModel";
import { IUpdateGoalModel } from "models/dto/ZoomiLxp/Models/Goals/IUpdateGoalModel";
import { IGoalAchievementModel } from "models/dto/ZoomiLxp/Models/Goals/IGoalAchievementModel";

export class GoalsApi {
	static createGoal(data: ICreateGoalModel) {
		return Api.post<IGoalModel, ICreateGoalModel>("/Goals", data);
	}

	static updateGoal(goalId: number, data: IUpdateGoalModel) {
		return Api.put<IGoalModel, IUpdateGoalModel>(`/Goals/${goalId}`, data);
	}

	static deleteGoal(goalId: number) {
		return Api.delete(`/Goals/${goalId}`);
	}

	static myAchievements() {
		return Api.get<IGoalAchievementModel[]>("/Goals/MyAchievements");
	}

	static myGoals() {
		return Api.get<IGoalModel[]>("/Goals/MyGoals");
	}

	//--Methods by User ID.--

	static createGoalForUser(data: ICreateGoalModel, userId: number) {
		return Api.post<IGoalModel, ICreateGoalModel>(`/Goals/UserGoal/${userId}`, data);
	}

	static getUserAchievements(userId: number) {
		return Api.get<IGoalAchievementModel[]>(`/Goals/UserAchievements/${userId}`);
	}

	static getUserGoals(userId: number) {
		return Api.get<IGoalModel[]>(`/Goals/UserGoals/${userId}`);
	}
}
