import Tooltip from "components/base/tooltip/tooltip";
import { ICourseModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseModel";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { ReactComponent as AlertIcon } from "assets/icons/ic_alert-circle.svg";
import styles from "./styles.module.scss";
import { knowledgeOutdated } from "constants/messages";
import classNames from "classnames";

interface Props {
	course: ICourseModel | IPreviewCourseModel;
	isShowVersionIndicator?: boolean;
	alertClassName?: string;
}

const ZStatsBlock = ({ course, isShowVersionIndicator, alertClassName }: Props) => {
	const showTooltip = course.knowledgeOutdated || isShowVersionIndicator;
	return (
		<div className={styles.zStats_container}>
			{showTooltip && (
				<Tooltip
					label={knowledgeOutdated}
					className={classNames(alertClassName, styles.version_tooltip)}
					classNameHead={styles.version_tooltip__container}
				>
					<AlertIcon />
				</Tooltip>
			)}

			<span className={styles.zStats_container__zStat}>Z1 · {Math.round(course?.z1Rating)}%</span>
			<span className={styles.zStats_container__zStat}>Z2 · {Math.round(course?.z2Rating)}%</span>
		</div>
	);
};

export default ZStatsBlock;
