import React from "react";
import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import PublicLayout from "layouts/public/public";
import Logo from "components/base/logo/logo";
import InputField from "components/base/input-field/input-field";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "routes";
import isEmpty from "lodash/isEmpty";
import { emailValidator } from "helpers/validator.helper";

interface FormValues {
	email: string;
}

const ResetPassword: React.FC = observer(() => {
	const { accountStore, toasterStore } = useAppStore();

	const SignupSchema = Yup.object().shape({
		email: emailValidator,
	});

	const initialValues: FormValues = {
		email: "",
	};

	const handleSubmit = async (values: FormValues) => {
		try {
			await accountStore.resetPassword({ email: values.email });
			toasterStore.showSuccessMessage("Reset password was successful and a confirmation code was sent by email");
		} catch (err) {
			toasterStore.showErrorMessage(err);
		}
	};

	const history = useHistory();

	return (
		<PublicLayout>
			<section className={styles.resetpassword_page}>
				<div className={styles.resetpassword_page__logo_box}>
					<Logo />
				</div>
				<div className={styles.resetpassword_page__title}>Forgot Password?</div>
				<div className={styles.resetpassword_page__wrap}>
					<div className={`${styles.resetpassword_page__text} ${styles.resetpassword_page__text_inner}`}>
						Enter your email address associated with your account
					</div>
				</div>

				<Formik
					initialValues={initialValues}
					validationSchema={SignupSchema}
					onSubmit={handleSubmit}
					validateOnChange={false}
				>
					{({ values, validateForm, setFieldValue, errors, setErrors }) => (
						<Form>
							<div className={styles.resetpassword_page__fields_wrap}>
								<InputField
									inputType="email"
									label="Email"
									className={styles.resetpassword_page__field_inner}
									name="email"
									value={values.email}
									onChange={(e) => setFieldValue("email", e.target.value)}
									isError={Boolean(errors["email"])}
									errorText={errors["email"]}
								/>
							</div>
							<button
								onClick={async (e) => {
									e.preventDefault();
									const errors = await validateForm();
									isEmpty(errors) ? handleSubmit(values) : setErrors(errors);
								}}
								type="submit"
								className={styles.resetpassword_page__btn_sign_in}
							>
								Reset password
							</button>
						</Form>
					)}
				</Formik>
				<div className={styles.resetpassword_page__container}>
					<button
						className={styles.resetpassword_page__link}
						onClick={() => history.push(generatePath(Routes.Login))}
						type="button"
					>
						Back to Login
					</button>
				</div>
			</section>
		</PublicLayout>
	);
});

export default ResetPassword;
