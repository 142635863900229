import styles from "./styles.module.scss";
import { Routes } from "routes";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import CmsSpreadsheet from "../cms-spreadsheet/cms-spreadsheet";
import { Columns } from "./data";
import CmsTileCourse from "./cms-tile-course/cms-tile-course";
import { useBulkSelections } from "./useBulkSelections";
import { CmsCoursesFilter } from "./cms-courses-filter";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";

const CmsCourses = ({ isHideFilter }: { isHideFilter?: boolean }) => {
	const { coursesStore, bulkOperationsStore } = useAppStore();

	const getColumns = () => {
		if (bulkOperationsStore.isShowSelection) {
			return Columns();
		} else {
			const newColumns = Columns()[0].columns.filter((column) => column.id !== "selection");
			return newColumns;
		}
	};

	useBulkSelections([
		PermissionConstantsRead.OwnCoursesForTechActions,
		PermissionConstantsRead.AllCoursesForTechActions,
	]);

	return (
		<div className={styles.cms_courses}>
			{!isHideFilter && <CmsCoursesFilter />}
			<CmsSpreadsheet
				tableColumns={getColumns()}
				tileComponent={CmsTileCourse}
				fetchData={() => coursesStore.getCMSCourses()}
				profileRoute={Routes.CmsCourseProfile}
				className={styles.cms_courses__body}
			/>
		</div>
	);
};

export default observer(CmsCourses);
