import React, { ReactElement, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import { ReactComponent as AlertCircle } from "assets/icons/ic_alert-circle.svg";
import MaskedInput from "react-text-mask";
import { DateTime } from "luxon";

export type InputType = "text" | "email" | "date" | "number" | "password";
type InputMode = "text" | "tel" | "url" | "email" | "numeric" | "search" | undefined;

interface Props {
	label?: string;
	labelClassName?: string;
	inputType?: InputType;
	fieldType?: "input" | "textarea";
	className?: string;
	name?: string;
	value?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
	onBlurValueChange?: (value: string) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) => void;
	onIconClick?: () => void;
	icon?: ReactElement | null;
	isError?: boolean;
	errorText?: string;
	disabled?: boolean;
	onKeyDown?: (event: React.KeyboardEvent) => void;
	maxLength?: number;
	inputRef?: React.Ref<HTMLInputElement>;
	maskedInput?: { mask: Array<string | RegExp> };
	autoFocus?: boolean;
	prefix?: string;
	autocomplete?: string;
	inputMode?: InputMode;
}

const cx = classNames.bind(styles);

const InputField = (props: Props) => {
	const {
		label,
		labelClassName,
		inputType = "text",
		fieldType = "input",
		className,
		name,
		value,
		onChange,
		onFocus,
		onBlur,
		isError,
		errorText,
		disabled,
		icon,
		onIconClick,
		onKeyDown,
		maxLength,
		maskedInput,
		onBlurValueChange,
		autoFocus,
		prefix,
		autocomplete,
		inputMode,
	} = props;

	const CustomInput = fieldType;
	const [id] = useState(() => uniqueId("input-id-"));
	const [maskedErrorMessage, setMaskedErrorMessage] = useState<string | null>(null);
	const [isFocused, setIsFocused] = useState<boolean>(false);

	return (
		<div className={cx(styles.input_field, { [styles.input_field__textarea]: fieldType === "textarea" }, className)}>
			{prefix && (isFocused || value) && (
				<label htmlFor={id} className={styles.input_field_prefix}>
					{prefix}
				</label>
			)}
			{maskedInput ? (
				<MaskedInput
					autoFocus={autoFocus}
					mask={maskedInput.mask}
					className={classNames(styles.input_field__input, {
						[styles.input_field__invalid]: isError,
						[styles.input_field__disabled]: disabled,
					})}
					guide={true}
					name={name}
					showMask={true}
					disabled={disabled}
					id={id}
					value={value}
					onChange={onChange}
					onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
						if (
							RegExp(maskedInput.mask[0]).test(event.target.value) &&
							DateTime.fromFormat(event.target.value, "D").invalidExplanation
						) {
							setMaskedErrorMessage("Invalid date format");
						} else {
							setMaskedErrorMessage(null);
						}
						onBlurValueChange?.(event.target.value);
					}}
					keepCharPositions={true}
				/>
			) : (
				<CustomInput
					autoFocus={autoFocus}
					type={inputType}
					id={id}
					className={cx(styles.input_field__input, {
						[styles.input_field__input__textarea]: fieldType === "textarea",
						[styles.input_field__invalid]: isError,
						[styles.input_field__disabled]: disabled,
						[styles.input_field__input__with_prefix]: prefix && (isFocused || value),
					})}
					placeholder={label}
					name={name}
					value={value}
					onChange={onChange}
					onFocus={(e) => {
						setIsFocused(true);
						onFocus?.(e);
					}}
					onBlur={(e) => {
						setIsFocused(false);
						onBlur?.(e);
					}}
					disabled={disabled}
					onKeyDown={onKeyDown}
					maxLength={maxLength}
					prefix={prefix}
					autoComplete={autocomplete}
					inputMode={inputMode}
				/>
			)}

			{label && (
				<label htmlFor={id} className={classNames(styles.input_field__field_label, labelClassName)}>
					{label}
				</label>
			)}
			{icon && (
				<div
					className={styles.input_field__input__input_icon}
					onClick={() => {
						onIconClick?.();
					}}
				>
					{icon}
				</div>
			)}
			{(isError || maskedErrorMessage) && (
				<div className={styles.input_field__input_error}>
					<div className={styles.input_field__input_error_box}>
						<AlertCircle className={styles.input_field__input_error_icon} />
					</div>
					<div className={styles.input_field__input_error_text}>{errorText ?? maskedErrorMessage}</div>
				</div>
			)}
		</div>
	);
};

export default React.forwardRef((props: Props, ref?: React.Ref<HTMLInputElement>) => (
	<InputField inputRef={ref} {...props} />
));
