import { useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { IRecommendationAlertItem } from "models/dto/ZoomiLxp/Models/EventModels/IRecommendationAlertItem";
import { AlertItemTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/AlertItemTypes";
import { ReactComponent as ThreadIcon } from "assets/icons/ic_peers.svg";
import { ReactComponent as InfluencersIcon } from "assets/icons/ic_influencers_alt.svg";
import { ReactComponent as SegmentIcon } from "assets/icons/ic_segment.svg";
import { getIconContentItemTypes, getIconContentTypes } from "helpers/icons.helper";
import Button from "components/base/button/button";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { ISaveEventAccountHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventAccountHistoryRequestModel";
import { useAppStore } from "store";
import { ISaveEventCourseHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseHistoryRequestModel";
import ButtonBookmark from "components/partial/button-bookmark/button-bookmark";
import { generatePath } from "react-router-dom";
import { Routes } from "routes";
import { getThumbnail } from "helpers/image.helper";
import { QueryParamsConstansts, TransitionSources } from "constants/constants";
import * as qs from "querystringify";

interface RecommendationsItemProps {
	item: IRecommendationAlertItem;
	className?: string;
}

const RecommendationsItem = ({ item, className }: RecommendationsItemProps) => {
	const { influencersStore, coursesStore, toasterStore } = useAppStore();
	const [isFollowed, setIsFollowed] = useState<boolean>(item.isFollowed);
	const [isBookmarked, setIsBookmarked] = useState(item.isBookmarked);

	const getRecommendedItemIcon = () => {
		const getIconStyles = (isAnotherStyle?: boolean) =>
			classNames(styles.recommendations_item__small_icon, {
				[styles.recommendations_item__little_icon]: isAnotherStyle,
			});
		const getCourseIconStyles = () =>
			classNames(styles.recommendations_item__icon, styles.recommendations_item__course_icon);
		const getSegmentIconStyles = () =>
			classNames(styles.recommendations_item__small_icon, styles.recommendations_item__segment_icon);

		const getContentTypeIcon = getIconContentTypes(getIconStyles, getCourseIconStyles)(item.contentType!);

		if (item.alertItemType === AlertItemTypes.Course) {
			return getContentTypeIcon;
		} else if (item.alertItemType === AlertItemTypes.Thread) {
			return (
				<ThreadIcon
					className={classNames(styles.recommendations_item__icon, styles.recommendations_item__thread_icon)}
				/>
			);
		} else if (item.alertItemType === AlertItemTypes.Influencer) {
			return (
				<InfluencersIcon
					className={classNames(styles.recommendations_item__icon, styles.recommendations_item__influencer_icon)}
				/>
			);
		} else if (item.alertItemType === AlertItemTypes.CourseItem) {
			return getIconContentItemTypes(getIconStyles, getSegmentIconStyles, getContentTypeIcon)(item.contentItemType!);
		} else if (item.alertItemType === AlertItemTypes.Segment) {
			return (
				<SegmentIcon
					className={classNames(styles.recommendations_item__small_icon, styles.recommendations_item__segment_icon)}
				/>
			);
		}
		return <></>;
	};

	const handleInfluencerFollow = async () => {
		try {
			const influencerFollowEvent: ISaveEventAccountHistoryRequestModel = {
				likedAccountId: item.id,
				eventType: !isFollowed ? EventTypes.InfluencerFollowed : EventTypes.InfluencerUnfollowed,
				payload: "",
			};
			await influencersStore.sendInfluencerEvent(influencerFollowEvent);
		} catch (err: unknown) {
			toasterStore.showErrorMessage(err);
		}
	};

	const handleBookmark = async (isMarked: boolean) => {
		try {
			const bookmarkCourseEvent: ISaveEventCourseHistoryRequestModel = {
				courseId: item.id,
				eventType: isMarked ? EventTypes.CourseBookmarked : EventTypes.CourseUnbookmarked,
				payload: "",
			};
			await coursesStore.sendCourseEvent(bookmarkCourseEvent);
		} catch (err: unknown) {
			toasterStore.showErrorMessage(err);
		}
	};
	const getActionButton = () => {
		if (item.alertItemType === AlertItemTypes.Influencer) {
			return (
				<Button
					label={isFollowed ? "Unfollow" : "Follow"}
					onClick={async () => {
						setIsFollowed(!isFollowed);
						await handleInfluencerFollow();
					}}
					type={isFollowed ? "secondary" : "primary"}
					className={classNames(styles.recommendations_item__follow_button)}
				/>
			);
		} else if (item.alertItemType === AlertItemTypes.Course || item.alertItemType === AlertItemTypes.Thread) {
			return (
				<ButtonBookmark
					className={styles.recommendations_item__bookmark_button}
					bookmarked={isBookmarked}
					isBookmarkable={true}
					isSecondaryType={false}
					onClick={async () => {
						setIsBookmarked(!isBookmarked);
						await handleBookmark(!isBookmarked);
					}}
				/>
			);
		}
		return <></>;
	};

	const handleClickLink = () => {
		let link = "";
		let query = "";
		if (item.alertItemType === AlertItemTypes.Segment) {
			if (item.startPosition) {
				query += `&${QueryParamsConstansts.startPosition}=${Math.round(item.startPosition)}`;
			}
			if (item.endPosition) {
				query += `&${QueryParamsConstansts.endPosition}=${Math.round(item.endPosition)}`;
			}
		}
		if (item.alertItemType === AlertItemTypes.Course) {
			link = generatePath(Routes.CoursePage, { id: item.id });
			link += qs.stringify(
				{
					ref: TransitionSources.RealTimeRecommendation,
					refCourseId: coursesStore.curActiveContent.courseId,
					refCourseItemId: coursesStore.curActiveContent.courseItemId,
				},
				true
			);
		} else if (item.alertItemType === AlertItemTypes.CourseItem || item.alertItemType === AlertItemTypes.Segment) {
			link = generatePath(Routes.MyDeskContent, {
				courseId: item.courseId!,
				courseItemId: String(item.id),
			});
			link += qs.stringify(
				{
					ref: TransitionSources.RealTimeRecommendation,
					refCourseId: coursesStore.curActiveContent.courseId,
					refCourseItemId: coursesStore.curActiveContent.courseItemId,
				},
				true
			);
		} else if (item.alertItemType === AlertItemTypes.Thread) {
			link = generatePath(Routes.PeersThread, { id: item.id });
		} else if (item.alertItemType === AlertItemTypes.Influencer) {
			link = generatePath(Routes.InfluencerPofile, { id: item.id });
		}
		window.open(link + query, "_blank", "noopener, noreferrer");
	};

	return (
		<div className={classNames(styles.recommendations_item, className)}>
			<div className={styles.recommendations_item__header}>
				{getRecommendedItemIcon()}
				{getActionButton()}
			</div>
			<div className={styles.recommendations_item__picture} onClick={handleClickLink}>
				<img
					src={getThumbnail(item.thumbnail)}
					alt="RecommendationsImage"
					className={styles.recommendations_item__image}
				/>
			</div>
			<div className={styles.recommendations_item__body}>
				<div className={styles.recommendations_item__title}>{item.title}</div>
			</div>
		</div>
	);
};

export default RecommendationsItem;
