import React from "react";

import classNames from "classnames";
import isEmpty from "lodash/isEmpty";

import styles from "./styles.module.scss";
import noImage from "assets/images/no-image.png";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import Tooltip from "components/base/tooltip/tooltip";
import { ReactComponent as InfoIcon } from "assets/icons/ic_info.svg";
import { ReactComponent as EmptyAvatarIcon } from "assets/icons/ic_avatar.svg";

interface Props {
	size?: "large" | "medium" | "small" | "compact" | "little";
	image?: string;
	title: string;
	noEmptyAvatar?: boolean;
	onClick?: () => void;
	className?: string;
	children?: React.ReactNode;
	isLoading?: boolean;
	hintText?: string;
}

const cx = classNames.bind(styles);

const Avatar: React.FC<Props> = (props: Props) => {
	const {
		size = "small",
		image,
		title,
		noEmptyAvatar = false,
		onClick,
		className,
		children,
		isLoading = false,
		hintText,
	} = props;
	const isEmptyAvatar = isEmpty(image) && isEmpty(title) && !noEmptyAvatar;
	const showHint = hintText && hintText?.length;

	return (
		<div className={styles.avatar_container}>
			<div
				className={cx(
					className,
					styles.avatar,
					styles[`avatar__size_${size}`],
					{ [styles.avatar__cursor_on]: Boolean(onClick) },
					{ [styles.avatar__empty]: isEmptyAvatar }
				)}
				onClick={() => onClick?.()}
			>
				{image && (
					<LoadingIndicator loading={isLoading}>
						<img
							className={styles.avatar__img}
							alt={title}
							src={image}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;
								currentTarget.src = noImage;
							}}
						/>
					</LoadingIndicator>
				)}
				{title && !image && <div className={cx(styles.avatar__text, styles[`avatar__text_${size}`])}>{title}</div>}
				{isEmptyAvatar && <EmptyAvatarIcon className={styles.avatar__icon} />}

				{children}
			</div>
			{showHint && (
				<Tooltip label={hintText} className={styles.avatar__tooltip}>
					<InfoIcon className={styles.avatar__hint_icon} />
				</Tooltip>
			)}
		</div>
	);
};

export default Avatar;
