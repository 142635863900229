//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class PermissionConstantsDelete
{
	public static ThreadComments: string = `delete.thread_comments`;
	public static OtherUserGoals: string = `delete.other_goals`;
	public static OtherCompanyUserGoals: string = `delete.other_company_users_goals`;
	public static GroupCmsManagement: string = `delete.group_cms_management`;
}
