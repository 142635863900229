import { useEffect, useState } from "react";
import { IPackageMetadata } from "models/dto/ZoomiLxp/Models/LMS/IPackageMetadata";
import { getRteRequired, getScormVersion } from "helpers/scorm.helper";

interface ScriptElement {
	name: string;
	element: HTMLScriptElement | null;
}

const scripts: ScriptElement[] = [
	{ name: "Rte1p2Api.js", element: null },
	{ name: "Parser1p2.js", element: null },
	{ name: "typevalidators1p2.js", element: null },
	{ name: "Rte2004Api.js", element: null },
	{ name: "parser.js", element: null },
	{ name: "typevalidators.js", element: null },
	{ name: "RteApiSite.js", element: null },
	{ name: "FramesetMgr.js", element: null },
];

export const loadScript = (
	baseUrl: string,
	scriptElement: ScriptElement,
	isAsync: boolean
): Promise<HTMLScriptElement> =>
	new Promise((resolve, reject) => {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.src = baseUrl + scriptElement.name;
		script.async = isAsync;
		script.onload = function () {
			resolve(script);
		};
		script.onerror = function () {
			reject(script);
		};
		scriptElement.element = script;
		document.head.append(script);
		return script;
	});

const loadScripts = async (
	baseScriptUrl: string,
	scripts: ScriptElement[],
	scriptIndex: number
): Promise<HTMLScriptElement> => {
	const scriptPromise = await loadScript(baseScriptUrl, scripts[scriptIndex], false);
	scriptIndex++;
	if (scriptIndex < scripts.length) {
		await loadScripts(baseScriptUrl, scripts, scriptIndex);
	}
	return scriptPromise;
};

export const useLoadScript = (metaData?: IPackageMetadata) => {
	const [isLMSInitialize, setLMSInitialize] = useState(false);
	const SCORM_API_SCRIPT_NAME = "scorm_API_selector";
	useEffect(() => {
		if (!metaData?.scormVersion) return;

		if (!!scripts.find((item, idx) => item.name === SCORM_API_SCRIPT_NAME && idx === scripts.length - 1)) {
			scripts.pop();
		}

		const baseScriptUrl = process.env.PUBLIC_URL + "/js/";

		const scriptPromise = loadScripts(baseScriptUrl, scripts, 0);

		scriptPromise.then(() => {
			const ScormVersionHtml = getScormVersion(metaData?.scormVersion ?? 2);
			const RteRequired = getRteRequired(metaData?.scormVersion ?? 2);
			const scriptText = `
								// Constants
								SCORM_2004 = "V1p3";
								SCORM_12 = "V1p2";
								// FrameMgr is called from all frames
								// debugger;
								var g_frameMgr = new FramesetManager();
								// TODO (M2): The following code is only required if the package is SCORM
								var g_scormVersion = "${ScormVersionHtml}";// Version of current session
								var API_1484_11 = null; // Name of RTE object for 2004 -- name is determined by SCORM.
								var API = null; // Name of RTE object for 1.2 -- name is determined by SCORM
								var g_API = g_frameMgr.GetRteApi(g_scormVersion, ${RteRequired});
								if (g_scormVersion == SCORM_2004)
								{
									API_1484_11 = g_API;
								}
								else
								{
									API  = g_API;
								};

								`;
			const script = document.createElement("script");
			script.type = "text/javascript";
			script.async = false;
			script.text = scriptText;
			scripts.push({ name: SCORM_API_SCRIPT_NAME, element: script });
			document.head.appendChild(script);
			setLMSInitialize(true);
		});

		return () => {
			for (let i = 0; i < scripts.length; i++) {
				const currentScript = scripts[i];
				if (currentScript && currentScript.element && window.document.head.contains(currentScript.element)) {
					window.document.head.removeChild(currentScript.element);
				}
			}
		};
	}, [metaData]);

	return isLMSInitialize;
};
