import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import { isEmpty, isNil } from "lodash";

interface Props {
	className?: string;
	onClick?: () => void;
}

const cx = classNames.bind(styles);

const Logo = (props: Props) => {
	const { settingsStore } = useAppStore();
	const { className, onClick } = props;
	const isLogoAvailable = !isNil(settingsStore.logoUrl) && !isEmpty(settingsStore.logoUrl);

	return isLogoAvailable ? (
		<div
			className={cx(className, styles.logo, { [styles.logo__cursor_on]: Boolean(onClick) })}
			onClick={() => onClick?.()}
		>
			<img src={settingsStore.logoUrl} alt="logo" className={styles.logo__img} />
		</div>
	) : (
		<></>
	);
};

export default observer(Logo);
