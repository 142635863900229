import { isApiError, isBadRequest } from "api/utils/api";
import { AxiosError } from "axios";
import { AlertEventTypes } from "components/base/alert/alert";
import { Emitter } from "mitt";
import { IBadRequestModel } from "models/dto/ZoomiLxp/Models/Common/IBadRequestModel";
import { IBadRequestValidationFailedModel } from "models/dto/ZoomiLxp/Models/Common/IBadRequestValidationFailedModel";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";

export interface IErrorInfo {
	isError: boolean;
	errorMessage: string;
}

export const emptyErrorInfo = (): IErrorInfo => ({
	isError: false,
	errorMessage: "",
});

export const makeErrorInfo = (errorMessage: string): IErrorInfo => ({
	isError: true,
	errorMessage,
});

export const isApiBadRequestModel = (obj: any): obj is IBadRequestValidationFailedModel => {
	return "errorMessage" in obj && "title" in obj && "errorMessages" in obj;
};

export const isApiValidationFailedModel = (obj: any): obj is IBadRequestValidationFailedModel => {
	return obj && isApiBadRequestModel(obj) && "validationFailures" in obj;
};

export type ZoomiError =
	| string
	| AxiosError<IResponseModel<IBadRequestModel>>
	| AxiosError<IResponseModel<IBadRequestValidationFailedModel>>;

export const showErrorMessage = (alertEventBus: Emitter<AlertEventTypes> | undefined, err: unknown) => {
	let errorMessage: string | null | undefined = null;
	if (typeof err === "string") {
		errorMessage = err;
	} else if (isApiError<IBadRequestModel | IBadRequestValidationFailedModel>(err)) {
		const data = err.response?.data.data;
		const status = err.response?.status;

		if (status === 401) {
			//According to Business rules we do not need to show to user any messages when session/token expired.
			return;
		}
		if (isApiValidationFailedModel(data)) {
			errorMessage = data?.validationFailures?.map((e) => e.errorMessage)?.join(", ");
		}
		if (!errorMessage) {
			if (isBadRequest(err)) {
				errorMessage = err.response?.data.data?.errorMessage;
			} else {
				errorMessage = data?.errorMessages?.join(", ");
			}
		}
		if (!errorMessage) {
			errorMessage = err.message;
		}
	}

	const notHandledCorrectly: boolean = !errorMessage;
	if (!errorMessage) {
		errorMessage = String(err);
	}

	alertEventBus?.emit("show", { text: errorMessage });
	if (notHandledCorrectly) {
		throw err;
	}
};

export const showSuccessMessage = (alertEventBus: Emitter<AlertEventTypes> | undefined, message: string) => {
	alertEventBus?.emit("show", { text: message, theme: "green" });
};
