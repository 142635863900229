import { ContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentTypes";
import { ContentProfileType } from "pages/private/cms-content-page/content-profile/content-profile.helper";
import { ICourseItemModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseItemModel";
import { ContentItemTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentItemTypes";
import { getFormattedDuration, getFormattedTime } from "./time.helper";
import { DateTime, Duration } from "luxon";
import { acceptedMimeTypes, mimeTypes } from "constants/constants";
import { getThreadTitle } from "./string.helper";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { ICourseModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseModel";

export const getContentType = (type: string, profileType?: string): number | null => {
	if (type === mimeTypes.zip) {
		return ContentTypes.Zip;
	} else if (type === "multipart/related") {
		return ContentTypes.Mhtml;
	} else if (type.includes("video")) {
		return ContentTypes.Video;
	} else if (type.includes("text/html")) {
		return ContentTypes.Html;
	} else if (type.includes("text") || type.includes("pdf") || acceptedMimeTypes.textFiles.includes(type)) {
		return ContentTypes.Text;
	} else if (type.includes("zip") && profileType)
		return profileType === ContentProfileType.Html ? ContentTypes.Html : ContentTypes.Scorm;
	else if (type.includes("zip")) return ContentTypes.Scorm;

	return ContentTypes.Podcast;
};

export const isReferenceContent = (item: ICourseItemModel) => {
	//is this content created in this course?
	if (item.contentItemType === ContentItemTypes.Content) {
		return item.courseId !== item.content?.courseId;
	} else if (item.contentItemType === ContentItemTypes.Assessment) {
		return item.courseId !== item.assessment?.courseId;
	} else if (item.contentItemType === ContentItemTypes.ContentSegment) {
		return true;
	}
	return false;
};

export const getCourseItemName = (item: ICourseItemModel) => {
	switch (item.contentItemType) {
		case ContentItemTypes.Assessment:
			return item.assessment?.name ?? (item.content?.name || "");
		case ContentItemTypes.ContentSegment:
			return item.segmentName ?? (item.contentSegment?.name || "");
		case ContentItemTypes.ExternalContent:
			return item.externalContent?.title ?? "";
		case ContentItemTypes.Thread:
			return item.thread ? getThreadTitle(item.thread) : "";
		default:
			return item.content?.name ?? "";
	}
};

export const initActiveCourseItem = (item: ICourseItemModel) => {
	if (item.contentItemType === ContentItemTypes.Content) {
		return item.isActive;
	} else if (item.contentItemType === ContentItemTypes.Assessment) {
		return item.assessment?.isActive;
	} else if (item.contentItemType === ContentItemTypes.ContentSegment) {
		return item.isActive;
	}
	return item.isActive;
};

const toDuration = (duration: string): Duration => {
	const time = DateTime.fromSQL(duration).toObject();
	return Duration.fromObject({ hour: time.hour, minute: time.minute, second: time.second });
};

export const getDuration = (item: ICourseItemModel) => {
	if (item.contentItemType === ContentItemTypes.ContentSegment) {
		const seconds = item.contentSegment?.duration;
		return getFormattedTime(seconds);
	} else if (item.contentItemType === ContentItemTypes.Content) {
		const durationStr = String(item.content?.duration) ?? "0";
		const duration = toDuration(durationStr);
		return getFormattedDuration(duration);
	}
	return "";
};

export const getCourseViewsCount = (courseInfo: IPreviewCourseModel | ICourseModel) =>
	courseInfo.viewCount > 999999 ? Number(courseInfo.viewCount / 1000000).toFixed(0) + "M" : courseInfo.viewCount;
