import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import classNames from "classnames";
import range from "lodash/range";
import { ShapeTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ShapeTypes";
import IconStar from "components/partial/icons/icon-star/icon-star";
import IconHeart from "components/partial/icons/icon-heart/icon-heart";
import IconCircle from "components/partial/icons/icon-circle/icon-circle";

interface Props {
	value?: number;
	isDisabled?: boolean;
	onRatingChange?: (newRating: number, prevRating: number) => void;
	darkBorder?: boolean;
	className?: string;
	maxValue?: number;
	shape?: ShapeTypes;
	color?: string;
}

const StarRating = (props: Props) => {
	const {
		isDisabled = false,
		value = 0,
		onRatingChange,
		darkBorder = false,
		className,
		maxValue = 5,
		shape = ShapeTypes.Star,
		color,
	} = props;
	const [rating, setRating] = useState(value);
	const [hover, setHover] = useState(0);

	const stars = range(1, maxValue + 1);

	useEffect(() => {
		setRating(value);
		setHover(0);
	}, [value]);

	const getStrokeColor = (starValue: number) => {
		if (color) {
			return color;
		}
		if (starValue <= (hover || Math.round(rating))) {
			return "var(--primary)";
		} else {
			if (darkBorder) {
				return "var(--background-tile)";
			} else {
				return "var(--text-tiles)";
			}
		}
	};

	const getFillColor = (starValue: number) => {
		if (starValue <= (hover || Math.round(rating))) {
			return color ? color : "var(--primary)";
		} else {
			return "none";
		}
	};

	return (
		<div className={classNames(styles.star_rating, className)}>
			{stars.map((starValue, index) => {
				return (
					<button
						key={index}
						type="button"
						className={classNames(styles.star_icon, {
							[styles.star_icon__disabled]: isDisabled,
							[styles.star_icon__dark]: darkBorder && !color,
						})}
						onClick={() => {
							if (!isDisabled) {
								setRating(starValue);
								setHover(0);
								onRatingChange?.(starValue, rating);
							}
						}}
						onMouseEnter={() => !isDisabled && setHover(starValue)}
						onMouseLeave={() => !isDisabled && setHover(Math.round(rating))}
					>
						{shape === ShapeTypes.Star && (
							<IconStar fill={getFillColor(starValue)} stroke={getStrokeColor(starValue)} />
						)}
						{shape === ShapeTypes.Heart && (
							<IconHeart fill={getFillColor(starValue)} stroke={getStrokeColor(starValue)} />
						)}
						{shape === ShapeTypes.Circle && (
							<IconCircle fill={getFillColor(starValue)} stroke={getStrokeColor(starValue)} />
						)}
					</button>
				);
			})}
		</div>
	);
};
export default StarRating;
