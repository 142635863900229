//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum EmailAddressModelType {
	Email = 0,
	Role = 1,
	Company = 2,
	Group = 3
}
