import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import Button from "components/base/button/button";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import Avatar from "components/base/avatar/avatar";
import { getAvatarTitle } from "helpers/string.helper";
import { IExpertiseModel } from "models/dto/ZoomiLxp/Models/Influencer/IExpertiseModel";
import { useAppStore } from "store";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { getThumbnail } from "helpers/image.helper";
import Description from "components/partial/description/description";

interface InfluencerCardProps {
	influencer: IInfluencerDataModel | null;
	className?: string;
}

interface IElementState {
	isActive: boolean;
	count: number;
	isDisabled: boolean;
}

const cx = classNames.bind(styles);

const InfluencerCard = (props: InfluencerCardProps) => {
	const { influencer, className } = props;
	const { usersStore } = useAppStore();

	const { influencersStore } = useAppStore();

	const initState: IElementState = {
		isActive: false,
		count: 0,
		isDisabled: false,
	};
	const [followed, setFollowed] = useState<IElementState>(initState);
	const [liked, setLiked] = useState<IElementState>(initState);

	const accountId = usersStore.currentUserInfo?.id ?? 0;

	useEffect(() => {
		if (accountId !== influencer?.id) {
			const isFollowed = influencer?.isFollowed;
			setFollowed((prevState: IElementState) => ({
				...prevState,
				isActive: isFollowed ?? false,
			}));
		} else {
			setFollowed((prevState: IElementState) => ({ ...prevState, isDisabled: true }));
		}
	}, [accountId, influencer?.id, influencer?.isFollowed]);

	useEffect(() => {
		setFollowed((prevState: IElementState) => ({
			...prevState,
			count: influencer?.followCount ?? 0,
		}));
	}, [influencer?.followCount]);

	useEffect(() => {
		if (accountId !== influencer?.id) {
			const isLiked = influencer?.isLiked;
			setLiked((prevState: IElementState) => ({
				...prevState,
				isActive: isLiked ?? false,
			}));
		} else {
			setLiked((prevState: IElementState) => ({ ...prevState, isDisabled: true }));
		}
	}, [accountId, influencer?.id, influencer?.isLiked]);

	useEffect(() => {
		setLiked((prevState: IElementState) => ({
			...prevState,
			count: influencer?.likesCount ?? 0,
		}));
	}, [influencer?.likesCount]);

	const handleInfluencerFollow = async () => {
		if (influencer && accountId !== influencer?.id) {
			setFollowed((prevState: IElementState) => ({
				...prevState,
				isDisabled: true,
				count: !prevState.isActive ? prevState.count + 1 : prevState.count - 1 > 0 ? prevState.count - 1 : 0,
			}));

			await influencersStore.sendInfluencerEvent(
				{
					likedAccountId: influencer?.id,
					eventType: followed.isActive ? EventTypes.InfluencerUnfollowed : EventTypes.InfluencerFollowed,
					payload: "",
				},
				true
			);
			setFollowed((prevState: IElementState) => ({ ...prevState, isActive: !prevState.isActive, isDisabled: false }));
		}
	};

	const handleInfluencerLike = async () => {
		if (influencer && accountId !== influencer?.id) {
			setLiked((prevState: IElementState) => ({
				...prevState,
				count: !prevState.isActive ? prevState.count + 1 : prevState.count - 1 > 0 ? prevState.count - 1 : 0,
				isDisabled: true,
			}));

			await influencersStore.sendInfluencerEvent(
				{
					likedAccountId: influencer?.id,
					eventType: liked.isActive ? EventTypes.InfluencerUnliked : EventTypes.InfluencerLiked,
					payload: "",
				},
				true
			);

			setLiked((prevState: IElementState) => ({ ...prevState, isDisabled: false, isActive: !prevState.isActive }));
		}
	};

	const LikeFollowBlock = () => {
		return (
			<div className={styles.influencer_card__right_section}>
				<div className={styles.influencer_card__btn_container}>
					<Button
						label={followed.isActive ? "Unfollow" : "Follow"}
						type={followed.isActive ? "secondary" : "primary"}
						onClick={handleInfluencerFollow}
						size="common"
						className={classNames(styles.influencer_card__button, styles.influencer_card__button_follow)}
						disabled={followed.isDisabled}
					/>
					<Button
						label={liked.isActive ? "Unlike" : "Like"}
						type={liked.isActive ? "secondary" : "primary"}
						onClick={handleInfluencerLike}
						size="common"
						className={styles.influencer_card__button}
						disabled={liked.isDisabled}
					/>
				</div>
			</div>
		);
	};

	if (isNull(influencer)) return <div className={styles.influencer_card__nodata}>No data</div>;

	return (
		<LoadingIndicator
			loading={influencersStore.isLoading}
			spinnerPosition="center"
			hideChildrenDuringLoading={true}
			className={styles.influencer_card__empty}
		>
			<div className={styles.influencer_card__container}>
				<div className={cx(styles.influencer_card, className)}>
					<div className={cx(styles.influencer_card__color_bar, styles.influencer_card__color_bar__red)} />
					<div className={styles.influencer_card__main_info_block}>
						<div className={styles.influencer_card__left_section}>
							<div className={styles.influencer_card__description_container}>
								<Avatar
									title={getAvatarTitle(influencer?.firstName ?? "", influencer?.lastName ?? "")}
									size="medium"
									className={styles.influencer_card__image_container}
									image={getThumbnail(influencer?.userPicture)}
								/>
								<div className={styles.influencer_card__details_container}>
									<div className={styles.influencer_card__box_title}>
										<div className={styles.influencer_card__influencer_name}>
											{[influencer?.firstName ?? "", influencer?.lastName ?? ""].join(" ")}
										</div>
										<div className={styles.influencer_card__description}>{influencer?.jobTitle ?? ""}</div>
									</div>
									<div className={styles.influencer_card__likes_followers}>
										<div className={styles.influencer_card__box_wrap}>
											<div className={styles.influencer_card__likes}>Likes</div>
											<div className={styles.influencer_card__likes_number}>{liked.count}</div>
										</div>
										<div className={styles.influencer_card__divider} />
										<div className={styles.influencer_card__box_wrap}>
											<div className={styles.influencer_card__followers}>Followers</div>
											<div className={styles.influencer_card__followers_number}>{followed.count}</div>
										</div>
									</div>
								</div>
							</div>
							<Description description={influencer?.description ?? ""} maxLength={400} />
							{!isEmpty(influencer?.expertises) && (
								<div className={styles.influencer_card__content_info_section}>
									{influencer?.expertises.map((expertise: IExpertiseModel) => (
										<p key={expertise.id} className={styles.influencer_card__content_info}>
											{expertise.name}
										</p>
									))}
								</div>
							)}
						</div>
						<LikeFollowBlock />
					</div>
				</div>
			</div>
		</LoadingIndicator>
	);
};

export default InfluencerCard;
