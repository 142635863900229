import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { ControlParams } from "../player-control/player-control";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import { QuestionTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/QuestionTypes";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import isEmpty from "lodash/isEmpty";
import { ISaveAnswerRequest } from "models/dto/ZoomiLxp/Models/Assessments/ISaveAnswerRequest";
import { useSchema } from "./hooks";
import { getMediumImage } from "helpers/image.helper";
import { getFirstSlide, getLastSlide, getMiddleSlides, InfoFinish, InfoQuiz } from "./slides";
import { Slide } from "./data";
import isNull from "lodash/isNull";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { useQuiz } from "hooks/useQuiz";
import { usePlayerNav } from "hooks/usePlayerNav";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";
import { isBadRequest } from "api/utils/api";
import { IPlayerPropsBase } from "../data/data";
import toast from "react-hot-toast";

interface PlayerQuizProps extends IPlayerPropsBase {
	params: ControlParams;
	setParams: Dispatch<SetStateAction<ControlParams>>;
	children?: ReactNode;
}

const cx = classNames.bind(styles);

const PlayerQuiz = ({ children, className, params, onEventHandle }: PlayerQuizProps) => {
	const { boxRef } = params;
	const { quizStore, contentStore, coursesStore, usersStore, toasterStore } = useAppStore();
	const [slide, setSlide] = useState<Slide>(Slide.First);
	const [wrongAnswers, setWrongAnswers] = useState<number[]>([]);
	const schema = useSchema(quizStore.curQuestion.questionType);
	const { startQuiz } = useQuiz();
	const { next } = usePlayerNav();
	const [numberOfLeftAttempts, setNumberOfLeftAttempts] = useState<number | undefined>(undefined);
	const [allowedShowingNextQuestion, setAllowedShowingNextQuestion] = useState<boolean | undefined>(undefined);

	useEffect(() => {
		setNumberOfLeftAttempts(undefined);
		setAllowedShowingNextQuestion(undefined);
	}, [contentStore?.sessionId, coursesStore.curActiveContent.assessmentId]);

	useEffect(() => {
		const assessmentId = Number(coursesStore.curActiveContent.assessmentId);
		if (assessmentId) startQuiz(assessmentId);
	}, [coursesStore.curActiveContent.assessmentId, startQuiz]);

	useEffect(() => {
		if (!isEmpty(quizStore.finishAssessment) && contentStore?.sessionId) {
			setSlide(Slide.Last);
		} else if (!isEmpty(quizStore.curQuestion) && contentStore?.sessionId) {
			setSlide(Slide.Middle);
		} else if (contentStore?.sessionId) {
			setSlide(Slide.First);
		}
	}, [quizStore.curQuestion, quizStore.finishAssessment, contentStore?.sessionId]);

	useEffect(() => {
		if (slide === Slide.Last && contentStore?.sessionId) {
			contentStore.stopKeepAlive();
			const data: ISaveEventCourseItemHistoryModel = {
				sessionId: contentStore.sessionId,
				eventType: EventTypes.Complete,
				payload: "",
			};
			onEventHandle(data);
		}
	}, [contentStore, slide, onEventHandle]);

	const addNewWrongAnswer = (answer: number) => {
		setWrongAnswers([...wrongAnswers, answer]);
	};

	const resetWrongAnswer = () => {
		if(numberOfLeftAttempts === 0 && wrongAnswers.length > 0) {
			setWrongAnswers([]);
		}
	};

	const onSave = async (data: ISaveAnswerRequest) => {
		const { attemptId, curQuestion, quiz } = quizStore;
		if (allowedShowingNextQuestion) {
			await quizStore.nextQuestion();
			setNumberOfLeftAttempts(undefined);
			setAllowedShowingNextQuestion(undefined);
			toast.dismiss();
			return;
		}
		try {
			let info = {
				attemptId: Number(attemptId),
				questionId: curQuestion.id,
			} as ISaveAnswerRequest;

			if (
				curQuestion.questionType === QuestionTypes.Choice ||
				curQuestion.questionType === QuestionTypes.MultiChoice ||
				curQuestion.questionType === QuestionTypes.Survey
			) {
				info = { ...info, answerOptionIds: data.answerOptionIds };
			} else if (
				curQuestion.questionType === QuestionTypes.Number ||
				curQuestion.questionType === QuestionTypes.NumberWithCheck ||
				curQuestion.questionType === QuestionTypes.Rating
			) {
				info = { ...info, answerNumber: data.answerNumber };
			} else if (curQuestion.questionType === QuestionTypes.Text) {
				info = { ...info, answerText: data.answerText };
			}
			toast.dismiss();
			const answerResponse = await quizStore.saveAnswer(info);
			const numberOfAttemptsLeft = answerResponse.countAttempts
				? answerResponse.countAttempts - answerResponse.currentAnswerNumber
				: 0;
			const attemptsLeftMessage = ` Number of attempts left: ${numberOfAttemptsLeft}`;
			setNumberOfLeftAttempts(numberOfAttemptsLeft);
			if (!(answerResponse.isAnswerCorrect === null || answerResponse.isAnswerCorrect === undefined)) {
				if (answerResponse.isAnswerCorrect) {
					const successMessage = (quiz.messageForCorrectAnswer ?? "Correct! Great job!") + attemptsLeftMessage;
					toasterStore.showSuccessMessage(successMessage);
					setAllowedShowingNextQuestion(true);
				} else {
					const errorMessage = (quiz.messageForWrongAnswer ?? "Incorrect. Please try again.") + attemptsLeftMessage;
					toasterStore.showErrorMessage(errorMessage);
				}
			}
			if (!answerResponse.allowedAnswerAttempt) {
				setAllowedShowingNextQuestion(true);
			}
		} catch (e) {
			if (isBadRequest(e) && e.response?.data.data?.errorMessage.includes("already answered")) {
				setNumberOfLeftAttempts(undefined);
				setAllowedShowingNextQuestion(undefined);
				toast.dismiss();
				await quizStore.nextQuestion();
			} else toasterStore.showErrorMessage(e);
		}
	};

	const onTryAgain = () => startQuiz(Number(coursesStore.curActiveContent.assessmentId), true);

	const onContinue = () => {
		coursesStore.updateActiveCourseItemToCompleted();
		next();
	};

	const getSlide = () => {
		if (slide === Slide.First && !quizStore.finishAssessment) {
			const buttonLabel = quizStore.indexQuestion > 1 ? "Continue quiz" : "Start quiz";
			return getFirstSlide(quizStore.quiz, () => quizStore.nextQuestion(), buttonLabel);
		} else if (slide === Slide.Middle) {
			const infoQuiz: InfoQuiz = {
				curQuestion: quizStore.curQuestion,
				indexQuestion: quizStore.indexQuestion,
				questionTextTheme: quizStore.quiz.questionTextTheme,
				answerTextTheme: quizStore.quiz.answerTextTheme,
				answerCheckResult: quizStore.answerCheckResult,
				quizHighlightType: quizStore.quiz.showRightAnswers,
				numberOfLeftAttempts: numberOfLeftAttempts,
				allowedShowingNextQuestion,
			};
			return getMiddleSlides(schema, infoQuiz, onSave, toasterStore, wrongAnswers, addNewWrongAnswer, resetWrongAnswer);
		} else if (slide === Slide.Last && quizStore.finishAssessment) {
			const infoFinish: InfoFinish = {
				finishResult: quizStore.finishAssessment,
				completionMessages: quizStore.quiz.completionMessages,
				questionTextTheme: quizStore.quiz.questionTextTheme,
				allowedSingleAttempt: quizStore.quiz.allowedSingleAttempt,
			};
			quizStore.indexQuestion = quizStore.questionsCount;
			return getLastSlide(
				infoFinish,
				onTryAgain,
				onContinue,
				coursesStore.curPositionContent + 1 <= coursesStore.countContents,
				coursesStore.curActiveContent.courseId,
				usersStore.checkOneOfUserPermissions([PermissionConstantsRead.ShowQuizResultsAfterPassage])
			);
		}
	};

	const backgroundStyle = {
		backgroundColor: quizStore.quiz.backgroundColor,
		backgroundImage: !isNull(quizStore.quiz.backgroundImageId)
			? `url(${getMediumImage(quizStore.quiz.backgroundImage)})`
			: "",
		backgroundSize: "cover",
	};

	return (
		<LoadingIndicator
			loading={quizStore.isLoading && !contentStore?.sessionId}
			spinnerPosition={"center"}
			backgroundStyle={"white"}
		>
			<div
				className={cx(styles.player_quiz, className, styles[`player_quiz__theme__${quizStore.theme}`])}
				ref={boxRef}
				style={backgroundStyle}
			>
				{getSlide()}
				{children}
			</div>
		</LoadingIndicator>
	);
};

export default observer(PlayerQuiz);
