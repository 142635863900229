export interface SystemUnitInfo {
	name?: string;
	version?: string;
}
export interface SystemInfo {
	os: SystemUnitInfo;
	browser: SystemUnitInfo;
}

export enum OSEnums {
	Windows = "Windows",
	MacX = "Mac OS X",
	Android = "Android",
	iOS = "iOS",
}

export const mobileCheck = (): boolean => {
	const userAgent: string = navigator.userAgent || navigator.vendor || (window as any).opera;
	const regExpPlatform =
		/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
	const regExp =
		/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ /])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i;

	return regExpPlatform.test(userAgent) || regExp.test(userAgent.substring(0, 4));
};

const userAgent: string = navigator.userAgent.toLowerCase();
export const tabletCheck: boolean =
	/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
		userAgent
	);

export const getBrowserInfo = (): SystemUnitInfo => {
	const userAgent = navigator.userAgent;
	let browserMatches = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

	if (/trident/i.test(browserMatches[1])) {
		const version = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
		return { name: "IE", version: version[1] || "" };
	}

	if (userAgent.split("CriOS/").length > 1) {
		return { name: "Chrome", version: userAgent.split("CriOS/")[1].split(".")[0] };
	}

	if (browserMatches[1] === "Chrome") {
		const version = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
		if (version !== null) return { name: version[1].replace("OPR", "Opera"), version: version[2] };
	}

	browserMatches = browserMatches[2]
		? [browserMatches[1], browserMatches[2]]
		: [navigator.appName, navigator.appVersion, "-?"];
	const version = userAgent.match(/version\/(\d+)/i);
	if (version !== null) browserMatches.splice(1, 1, version[1]);

	return { name: browserMatches[0], version: browserMatches[1] };
};

export const getOSInfo = (): SystemUnitInfo => {
	const userAgent: string = navigator.userAgent || navigator.vendor || (window as any).opera;

	let os: string | undefined = undefined;
	const clientStrings = [
		{
			s: "Windows 10",
			r: /(Windows 10.0|Windows NT 10.0)/,
		},
		{
			s: "Windows 8.1",
			r: /(Windows 8.1|Windows NT 6.3)/,
		},
		{
			s: "Windows 8",
			r: /(Windows 8|Windows NT 6.2)/,
		},
		{
			s: "Windows 7",
			r: /(Windows 7|Windows NT 6.1)/,
		},
		{
			s: "Windows Vista",
			r: /Windows NT 6.0/,
		},
		{
			s: "Windows Server 2003",
			r: /Windows NT 5.2/,
		},
		{
			s: "Windows XP",
			r: /(Windows NT 5.1|Windows XP)/,
		},
		{
			s: "Windows 2000",
			r: /(Windows NT 5.0|Windows 2000)/,
		},
		{
			s: "Windows ME",
			r: /(Win 9x 4.90|Windows ME)/,
		},
		{
			s: "Windows 98",
			r: /(Windows 98|Win98)/,
		},
		{
			s: "Windows 95",
			r: /(Windows 95|Win95|Windows_95)/,
		},
		{
			s: "Windows NT 4.0",
			r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/,
		},
		{
			s: "Windows CE",
			r: /Windows CE/,
		},
		{
			s: "Windows 3.11",
			r: /Win16/,
		},
		{
			s: "Android",
			r: /Android/,
		},
		{
			s: "Open BSD",
			r: /OpenBSD/,
		},
		{
			s: "Sun OS",
			r: /SunOS/,
		},
		{
			s: "Linux",
			r: /(Linux|X11)/,
		},
		{
			s: "iOS",
			r: /(iPhone|iPod)/,
		},
		{
			s: "iPad OS",
			r: /(iPad)/,
		},
		{
			s: "Mac OS X",
			r: /Mac OS X/,
		},
		{
			s: "Mac OS",
			r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/,
		},
		{
			s: "QNX",
			r: /QNX/,
		},
		{
			s: "UNIX",
			r: /UNIX/,
		},
		{
			s: "BeOS",
			r: /BeOS/,
		},
		{
			s: "OS/2",
			r: /OS\/2/,
		},
		{
			s: "Search Bot",
			r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
		},
	];

	for (const id in clientStrings) {
		const cs = clientStrings[id];
		if (cs.r.test(userAgent)) {
			os = cs.s;
			break;
		}
	}

	let osVersion: string | undefined = undefined;
	let regExpExec: RegExpExecArray | null = null;

	if (os) {
		if (/Windows/.test(os)) {
			regExpExec = /Windows (.*)/.exec(os);
			osVersion = regExpExec ? regExpExec[1] : undefined;
			os = OSEnums.Windows;
		}
	}

	switch (os) {
		case OSEnums.MacX:
			regExpExec = /Mac OS X ([\d]+)/.exec(userAgent);
			osVersion = regExpExec ? regExpExec[1] : undefined;
			break;

		case OSEnums.Android:
			regExpExec = /Android ([\d]+)/.exec(userAgent);
			osVersion = regExpExec ? regExpExec[1] : undefined;
			break;

		case OSEnums.iOS:
			regExpExec = /OS (\d+)_(\d+)_?(\d+)?/.exec(navigator.appVersion);
			osVersion = regExpExec?.join(".");
			break;
	}

	return {
		name: os,
		version: osVersion,
	};
};

export const isSafariBrowser = (): boolean => getBrowserInfo()?.name?.toLowerCase() === "safari";
