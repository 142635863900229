import { ApiBase } from "api/utils/api";

export class QuizResultsApi {
	static exportQuizResults(courseId: number) {
		return ApiBase.get(`/QuizResults/ExportQuizResults/${courseId}`, {
			responseType: "blob",
		});
	}
	static exportCourseCompletion() {
		return ApiBase.get("/QuizResults/ExportCourseCompletion", {
			responseType: "blob",
		});
	}
	static exportZ1Score(courseId: number) {
		return ApiBase.get(`/QuizResults/ExportZ1Scores/${courseId}`, {
			responseType: "blob",
		});
	}
	static exportScormQuizResults() {
		return ApiBase.get("/QuizResults/ExportScormQuizResults", {
			responseType: "blob",
		});
	}
}
