import CmsLayout from "layouts/cms/cms";
import AllReports from "components/partial/reports/all-reports/all-reports";

const AllReportsPage = () => {
	return (
		<CmsLayout label="Reports">
			<AllReports />
		</CmsLayout>
	);
};

export default AllReportsPage;
