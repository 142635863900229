import { makeAutoObservable, runInAction } from "mobx";
import { IAssignmentAccountSelectionModel } from "models/dto/ZoomiLxp/Models/BulkOperations/AssignmentBulkOperation/IAssignmentAccountSelectionModel";
import { BulkOperationType } from "models/dto/ZoomiLxp/Utilities/Enumerations/BulkOperationType";
import { IMainTaskRequestModel } from "models/dto/ZoomiLxp/Models/BulkOperations/IMainTaskRequestModel";
import { AppStore } from "store";
import { ActivityApi } from "api/controllers/ActivityApi";
import { CheckState } from "components/partial/cms/cms-check-btn/cms-check-btn";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import { downloadFile } from "helpers/file-download.helper";
import { ITenantModel } from "models/dto/ZoomiLxp/Models/Tenants/ITenantModel";
import isNil from "lodash/isNil";
import { ICloneManyCourseModel } from "models/dto/ZoomiLxp/Models/BulkOperations/CloningCoursesBulkOperation/ICloneManyCourseModel";
import { ICloneCourseOptions } from "models/dto/ZoomiLxp/Models/BulkOperations/ICloneCourseOptions";
import { ICloneUserOptions } from "models/dto/ZoomiLxp/Models/BulkOperations/CloningUsersBulkOperation/ICloneUserOptions";
import { ICloneManyUsersModel } from "models/dto/ZoomiLxp/Models/BulkOperations/CloningUsersBulkOperation/ICloneManyUsersModel";
import { ICourseTagAssignmentBulkRequestModel } from "models/dto/ZoomiLxp/Models/BulkOperations/AssignmentBulkOperation/ICourseTagAssignmentBulkRequestModel";
import { IGroupAssignmentBulkRequestModel } from "models/dto/ZoomiLxp/Models/BulkOperations/AssignmentBulkOperation/IGroupAssignmentBulkRequestModel";
import { SelectionAccountsType } from "models/dto/ZoomiLxp/Utilities/Enumerations/SelectionAccountsType";
import { IThreadTagAssignmentBulkRequestModel } from "models/dto/ZoomiLxp/Models/BulkOperations/AssignmentBulkOperation/IThreadTagAssignmentBulkRequestModel";
import { getInitParams } from "helpers/filter.helper";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import { SortDirection } from "models/dto/ZoomiLxp/Utilities/Enumerations/SortDirection";
import { requestErrorHandler } from "api/utils/api";

export enum CloningFormType {
	Courses,
	Users,
}

export class BulkOperationsStore {
	private rootStore;
	private _isLoading: boolean = false;
	private _assignmentRecipients: IAssignmentAccountSelectionModel[] | number[] | string[] = [];
	private _btnState: CheckState = CheckState.Unchecked;
	private _ids: number[] = [];
	private _checkedState: number[] = [];
	private _isShowSelection: boolean = false;
	private _isShowBulkOperation: boolean = false;
	private _tenantsList: ITenantModel[] = [];
	private _currentTenantData: ITenantModel | null = null;
	private _coursesCloningOptions: ICloneCourseOptions = {} as ICloneCourseOptions;
	private _usersCloningOptions: ICloneUserOptions = {} as ICloneUserOptions;
	private _bulkOperationType: number = 0;
	private _isIndexing: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	constructor(rootStore: AppStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);

		runInAction(() => {
			this._coursesCloningOptions = {
				targetTenantId: this.rootStore.settingsStore.currentTenantId,
				copyCreatorProfile: false,
				copyConnectedThreads: false,
				copyPrice: false,
			};
			this._usersCloningOptions = {
				targetTenantId: this.rootStore.settingsStore.currentTenantId,
				copyCompany: false,
			};
		});
	}

	set assignmentRecipients(recipients: IAssignmentAccountSelectionModel[] | number[] | string[]) {
		this._assignmentRecipients = recipients;
	}

	get assignmentRecipients() {
		return this._assignmentRecipients;
	}

	get tenantsList(): ITenantModel[] {
		return this._tenantsList;
	}

	get currentTenantData() {
		return this._currentTenantData;
	}

	get coursesCloningOptions() {
		return this._coursesCloningOptions;
	}

	set coursesCloningOptions(options: ICloneCourseOptions) {
		this._coursesCloningOptions = options;
	}

	get usersCloningOptions() {
		return this._usersCloningOptions;
	}

	set usersCloningOptions(options: ICloneUserOptions) {
		this._usersCloningOptions = options;
	}

	get isIndexing(): boolean {
		return this._isIndexing;
	}

	set isIndexing(value: boolean) {
		this._isIndexing = value;
	}

	getThreadInitParams = () => {
		const permissions = [PermissionConstantsRead.AllThreads];
		return {
			...getInitParams(),
			filterCriteria: !this.rootStore.usersStore.checkOneOfUserPermissions(permissions)
				? [
						{
							propertyNames: ["CourseItemId"],
							function: FilterFunction.IsNull,
						},
						{
							propertyNames: ["AccountCreatedId"],
							function: FilterFunction.Equal,
							argument: this.rootStore.usersStore.currentUserInfo!.id,
						},
				  ]
				: [
						{
							propertyNames: ["CourseItemId"],
							function: FilterFunction.IsNull,
						},
				  ],
			sortCriteria: [
				{
					order: 1,
					propertyName: "title",
					direction: SortDirection.Ascending,
				},
			],
		};
	};

	createBulkOperation = async () => {
		const getPayload = () => {
			if (this.bulkOperationType === BulkOperationType.CourseCloning) {
				const courseCloningPayload: ICloneManyCourseModel = {
					courseIds: this.checkedState,
					cloneOptions: this.coursesCloningOptions,
				};
				return courseCloningPayload;
			}

			if (
				this.bulkOperationType === BulkOperationType.CourseAssignment ||
				this.bulkOperationType === BulkOperationType.CourseUnAssignment
			) {
				const assignmentPayload = {
					CourseIds: this.checkedState,
					AccountSelections: this.assignmentRecipients,
				};
				return assignmentPayload;
			}

			if (this.bulkOperationType === BulkOperationType.UserCloning) {
				const usersCloningPayload: ICloneManyUsersModel = {
					userIds: this.checkedState,
					cloneOptions: this.usersCloningOptions,
				};
				return usersCloningPayload;
			}

			if (this.isCourseBulkGroup) {
				const groupAssignPayload: ICourseTagAssignmentBulkRequestModel = {
					courseIds: this.checkedState,
					tagNames: this.assignmentRecipients as string[],
				};
				return groupAssignPayload;
			}

			if (this.isThreadBulkGroup) {
				const groupAssignPayload: IThreadTagAssignmentBulkRequestModel = {
					threadIds: this.checkedState,
					tagNames: this.assignmentRecipients as string[],
				};
				return groupAssignPayload;
			}

			if (this.isUsersBulkGroup) {
				const assigningForGroupIds: number[] = this.rootStore.groupStore.groupsAssignmentRequestData.map(
					(grp) => grp.groupId
				);
				const groupAssignPayload: IGroupAssignmentBulkRequestModel = {
					accountSelections: this.checkedState.map((id) => ({
						selectionType: SelectionAccountsType.AccountId,
						value: id,
					})),
					groupIds: this.assignmentRecipients as number[],
					assigningForGroupIds,
				};
				return groupAssignPayload;
			}
		};

		const payload = JSON.stringify(getPayload());
		const model: IMainTaskRequestModel = {
			operationType: this.bulkOperationType,
			payload,
		};
		await ActivityApi.createBulkOperation(model);
	};

	async checkIsIndexing(argument: number) {
		this.isIndexing = true;
		try {
			const response = await ActivityApi.activeOperations(`${argument}`);
			if (!response.data.data.records.length) {
				this.isIndexing = false;
			}
		} catch (error) {
			this.isIndexing = false;
			requestErrorHandler(error);
		}
	}

	async requestReindexing(operationType: number) {
		try {
			const model: IMainTaskRequestModel = {
				operationType,
				payload: "{}",
			};
			await ActivityApi.createBulkOperation(model);
			this.isIndexing = true;
		} catch (error) {
			requestErrorHandler(error);
		}
	}

	clearData() {
		runInAction(() => {
			this._btnState = CheckState.Unchecked;
			this._checkedState = [];
			this._isShowSelection = false;
			this._isShowBulkOperation = false;
			this._ids = [];
			this._coursesCloningOptions = {
				targetTenantId: this.rootStore.settingsStore.currentTenantId,
				copyCreatorProfile: false,
				copyConnectedThreads: false,
				copyPrice: false,
			};
			this._usersCloningOptions = {
				targetTenantId: this.rootStore.settingsStore.currentTenantId,
				copyCompany: false,
			};
		});
	}

	get btnState(): CheckState {
		return this._btnState;
	}

	set btnState(value: CheckState) {
		this._btnState = value;
	}

	getId = (id: number) => {
		return this._checkedState.includes(id);
	};

	setId = (id: number, value: boolean) => {
		if (value) {
			runInAction(() => {
				this._checkedState = [...this._checkedState, id];
			});
		} else {
			runInAction(() => {
				this._checkedState = this._checkedState.filter((item) => item !== id);
			});
		}
		this.setBtnState();
	};

	setBtnState = () => {
		let result;
		const size = this._checkedState?.length ?? 0;

		if (size === 0) {
			result = CheckState.Unchecked;
		} else if (size === this._ids?.length) {
			result = CheckState.Checked;
		} else {
			result = CheckState.PartChecked;
		}

		if (result !== this._btnState) {
			this._btnState = result;
		}
	};

	getIds = () => {
		if (!isEmpty(this.rootStore.searchStore.items)) {
			let ids = this.rootStore.searchStore.items.reduce((array: number[], item) => {
				array.push(item.id);
				return array;
			}, []);
			ids = sortBy(ids);
			runInAction(() => (this._ids = ids));
		}
	};

	checkedAll = (value: boolean) => {
		if (value) {
			runInAction(() => {
				this._checkedState = [...this._ids];
			});
		} else {
			runInAction(() => {
				this._checkedState = [];
			});
		}
	};

	async getBulkOperationsTenants() {
		runInAction(() => (this._isLoading = true));
		try {
			const response = await ActivityApi.getActivityTenants();
			const currentTenantData: ITenantModel | undefined = response.data.data.find(
				(tenant: ITenantModel) => tenant.id === this.rootStore.settingsStore.currentTenantId
			);

			runInAction(() => {
				this._tenantsList = response.data.data;
				if (currentTenantData) {
					if (isNil(this._currentTenantData)) {
						this._currentTenantData = currentTenantData;
					}
					this._coursesCloningOptions = {
						...this._coursesCloningOptions,
						targetTenantId: currentTenantData.id,
					};

					this._usersCloningOptions = {
						...this._usersCloningOptions,
						targetTenantId: currentTenantData.id,
					};
				}
			});
			return response.data.data;
		} finally {
			runInAction(() => (this._isLoading = false));
		}
	}

	async exportActivity(activityId: number) {
		runInAction(() => (this._isLoading = true));
		try {
			await ActivityApi.exportActivity(activityId).then((response) => {
				downloadFile(response);
			});
		} finally {
			runInAction(() => (this._isLoading = false));
		}
	}

	get isUsersBulkGroup() {
		return [BulkOperationType.BulkGroupAssignment, BulkOperationType.BulkGroupUnAssignment].includes(
			this.bulkOperationType
		);
	}

	get isCourseBulkGroup() {
		return [BulkOperationType.BulkCourseTagAssignment, BulkOperationType.BulkCourseTagUnAssignment].includes(
			this.bulkOperationType
		);
	}

	get isThreadBulkGroup() {
		return [BulkOperationType.BulkThreadTagAssignment, BulkOperationType.BulkThreadTagUnAssignment].includes(
			this.bulkOperationType
		);
	}

	get isCoursesOrThreadsBulkGroup() {
		return this.isCourseBulkGroup || this.isThreadBulkGroup;
	}

	get ids(): number[] {
		return this._ids;
	}

	set ids(value: number[]) {
		this._ids = value;
	}

	get checkedState(): number[] {
		return this._checkedState;
	}

	set checkedState(value: number[]) {
		this._checkedState = value;
	}

	get isShowSelection(): boolean {
		return this._isShowSelection;
	}

	set isShowSelection(value: boolean) {
		this._isShowSelection = value;
	}

	get isShowBulkOperation(): boolean {
		return this._isShowBulkOperation;
	}

	set isShowBulkOperation(value: boolean) {
		this._isShowBulkOperation = value;
	}

	get bulkOperationType(): number {
		return this._bulkOperationType;
	}

	set bulkOperationType(value: number) {
		this._bulkOperationType = value;
	}
}
