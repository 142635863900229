import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as CheckIcon } from "assets/icons/ic_player_check.svg";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import { IPlayerSettings } from "../player-control/player-control";

interface PlayerZoomProps {
	isShowZoom: boolean;
	zoomLimitMultiplier?: number;
	onZoom?: (zoom: number) => void;
	className?: string;
}

const PlayerZoom = ({ isShowZoom, onZoom, className, zoomLimitMultiplier = 3 }: PlayerZoomProps) => {
	const { playerStore } = useAppStore();
	if (!isShowZoom) return <></>;

	const gradeZoom: IPlayerSettings[] = [
		{ value: 0.5, label: "50%" },
		{ value: 0.75, label: "75%" },
		{ value: 1, label: "100%" },
		{ value: 1.25, label: "125%" },
		{ value: 1.5, label: "150%" },
		{ value: 1.75, label: "175%" },
		{ value: 2, label: "200%" },
		{ value: 2.5, label: "250%" },
	];

	const filteredGradeZoom = gradeZoom.filter(({ value }) => value <= zoomLimitMultiplier);

	return (
		<div className={classNames(styles.player_zoom, className)}>
			<div className={styles.player_zoom__header}>Zoom</div>
			<div className={styles.player_zoom__body}>
				<ul className={styles.player_zoom__list}>
					{filteredGradeZoom.map((item) => (
						<li className={styles.player_zoom__item} key={item.value} onClick={() => onZoom?.(item.value)}>
							{item.value === playerStore.zoomMultiplier && <CheckIcon className={styles.player_zoom__icon} />}
							<div className={styles.player_zoom__label}>{item.label}</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default observer(PlayerZoom);
