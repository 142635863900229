import styles from "./styles.module.scss";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { ReactComponent as RemoveIcon } from "assets/icons/ic_close.svg";
import Button from "components/base/button/button";
import InputField from "components/base/input-field/input-field";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";

interface CourseCardNotesProps {
	className?: string;
	onClose: () => void;
}

const cx = classNames.bind(styles);

const CourseCardNotes = (props: CourseCardNotesProps) => {
	const { className, onClose } = props;
	const { notesStore, coursesStore } = useAppStore();
	const [currentNote, setCurrentNote] = useState(coursesStore.curActiveContent.content?.note ?? "");

	const handleChange = () => {
		if (coursesStore.curActiveContent.content)
			notesStore.saveNote(coursesStore.curActiveContent.content.id, { description: currentNote });
	};

	useEffect(() => {
		setCurrentNote(coursesStore.curActiveContent.content?.note ?? "");
	}, [
		coursesStore.curActiveContent.content,
		coursesStore.curActiveContent.content?.id,
		coursesStore.curActiveContent.content?.note,
	]);

	return (
		<div className={cx(className, styles.course_card_notes)}>
			<div className={styles.header}>
				Add note
				<Button
					label=""
					onClick={() => onClose()}
					type="text"
					icon={<RemoveIcon className={styles.course_card_notes__button_icon} />}
				/>
			</div>
			<div className={styles.body}>
				<LoadingIndicator loading={notesStore.isLoading}>
					<InputField
						fieldType="textarea"
						label="Note"
						className={styles.note_input_area}
						name="description"
						value={currentNote ?? ""}
						onChange={(e) => setCurrentNote(e.target.value)}
						maxLength={50000}
					/>
				</LoadingIndicator>

				<Button label="Save" onClick={handleChange} size="small" disabled={notesStore.isLoading} />
			</div>
		</div>
	);
};

export default observer(CourseCardNotes);
