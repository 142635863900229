import React from "react";
import styles from "./styles.module.scss";
import { CmsTileProps } from "components/partial/cms/cms-table/cms-table";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import { DateTime } from "luxon";
import Avatar from "components/base/avatar/avatar";
import { IB2CPurchaseModel } from "models/dto/ZoomiLxp/Models/Billing/IB2CPurchaseModel";
import { getFirstLetter } from "helpers/string.helper";
import { NavLink, generatePath } from "react-router-dom";
import { ReactComponent as PlayIcon } from "assets/icons/ic_player_play.svg";
import { Routes } from "routes";

const cx = classNames.bind(styles);

const CmsB2CTileSale: React.FunctionComponent<CmsTileProps<IB2CPurchaseModel>> = (
	props: CmsTileProps<IB2CPurchaseModel>
) => {
	const { item: content, onClick } = props;
	const { accountName, date, status, cost, courseTitle, courseId } = content;

	return (
		<div className={styles.cms_tile_sale} onClick={() => onClick && onClick(content)}>
			<div className={styles.cms_tile_sale__cell_picture}>
				<Avatar title={getFirstLetter(accountName)} />
			</div>
			<div className={styles.cms_tile_sale__wrap}>
				<div className={styles.cms_tile_sale__cell_title}>
					{accountName ?? ""}
					<span className={styles.course_title}>{courseTitle}</span>
					<NavLink
						to={generatePath(Routes.CoursePage, {
							id: courseId,
						})}
						target="_blank"
					>
						<PlayIcon className={classNames(styles.play_icon)} />
					</NavLink>
				</div>
				<div className={styles.cms_tile_sale__inner}>
					<div className={cx(styles.cms_tile_sale__status, { [styles.cms_tile_sale__status_success]: status })}>
						{status}
					</div>
					<div className={styles.cms_tile_sale__date}>
						{!isEmpty(date) && DateTime.fromISO(date).setLocale("en").toLocaleString(DateTime.DATE_MED)}
					</div>
					${cost}
				</div>
			</div>
		</div>
	);
};

export default CmsB2CTileSale;
