import { useAppStore } from "store";
import { observer } from "mobx-react";
import Alert from "components/base/alert/alert";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import { Routes } from "routes";
import { useEffect, useState } from "react";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import Tooltip from "components/base/tooltip/tooltip";
import { ReactComponent as LeftIcon } from "assets/icons/ic_player_left.svg";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";

interface IPlayerFinalScreenProps {
	onBackButtonClick?: () => void;
}

const PlayerFinalScreen = ({ onBackButtonClick }: IPlayerFinalScreenProps) => {
	const { coursesStore, commonStore, settingsStore } = useAppStore();
	const [isCourseCompleted, setIsCourseCompleted] = useState(false);
	const isCertificatesEnabled: boolean = coursesStore.currentCourse.enableCertificates;
	const courseItemsCount = coursesStore.activeCourseItems.length;
	const lastCourseItem = coursesStore.activeCourseItems[courseItemsCount - 1];
	const backButtonTooltipPostfix =
		settingsStore.publicSettings.Pages?.[SiteSettingsKeys.MyDeskCourseItemsNameTooltipForArrowButtons];
	//* Hidden by Vlad request until certificate generation fix. Uncomment and remove "REMOVE" part when fix was ready.
	// const [certPreviewData, setCertPreviewData] = useState<string | undefined>("");

	const FinalScreenBackButton = () => {
		return (
			<div
				className={styles.certificate_screen__btn_back_wrap}
				onClick={() => {
					if (lastCourseItem && onBackButtonClick) {
						onBackButtonClick();
						coursesStore.isCourseEnded = false;
					}
				}}
			>
				<Tooltip
					label={`Previous ${backButtonTooltipPostfix}`}
					className={styles.arrow_tooltip}
					classNameContent={styles.arrow_tooltip__content}
				>
					<button className={styles.certificate_screen__btn}>
						<LeftIcon className={styles.certificate_screen__btn_icon} />
					</button>
				</Tooltip>
			</div>
		);
	};

	useEffect(() => {
		if (isCertificatesEnabled) {
			coursesStore.getCourseCertificate(); /*.then((certBase64) => setCertPreviewData(certBase64));*/
			setIsCourseCompleted(coursesStore.isCourseCompleted());
		}
	}, [coursesStore, isCertificatesEnabled]);

	if (!isCertificatesEnabled)
		return (
			<div>
				<FinalScreenBackButton />
				<div className={styles.certificate_screen__final_message}>
					You have reached the end of the course. <br />
					Please close this window or utilize the menu at the top of the screen to navigate the platform.
				</div>
			</div>
		);

	// const handleDownloadPdf = async () => {
	// 	const certUrl = await coursesStore.getCourseCertificatePdfUrl();
	// 	window.open(certUrl, "_blank");
	// };

	// const getPreviewImage = () => {
	// 	if (certPreviewData && certPreviewData.length) {
	// 		return (
	// 			<img
	// 				src={`data:image/png;base64,${certPreviewData}`}
	// 				alt="certificate preview"
	// 				className={styles.certificate_screen__certificate_image}
	// 			/>
	// 		);
	// 	}
	// 	return <></>;
	// };

	if(!isCourseCompleted) {
		return (
			<div>
				<FinalScreenBackButton />
				<div className={styles.certificate_screen__final_message}>
					<p>The course has not been completed.</p> 
					<p>Please complete all contents to get your certificate.</p>
				</div>
			</div>
		); 
	}

	return (
		<div className={styles.certificate_screen}>
			<FinalScreenBackButton />
			<Alert eventBus={commonStore.alertEventBus} />
			{/* //! REMOVE -------- */}
			<LoadingIndicator
				loading={coursesStore.isLoading}
				spinnerPosition={"center"}
				backgroundStyle={"white"}
				className={styles.loading_container}
			>
				<div className={styles.certificate_screen__temp_container}>
					<h5 className={styles.certificate_screen__message}>Congratulations!</h5>
					<span className={styles.certificate_screen__temp_text}>
						Your certificate will be available in
						<NavLink to={Routes.MyCertificates} className={styles.certificate_screen__link}>
							My Certificates
						</NavLink>
						soon.
					</span>
				</div>
			</LoadingIndicator>

			{/* //! ---------- */}
			{/* <div className={styles.certificate_screen__left_container}>
				<h5 className={styles.certificate_screen__message}>Congratulations!</h5>
				<NavLink to={Routes.MyCertificates} className={styles.certificate_screen__link}>
					Go to my Certificates
				</NavLink>
				<Button label="Download certificate" onClick={handleDownloadPdf} />
			</div>

			<LoadingIndicator
				loading={coursesStore.isLoading || !certPreviewData || !certPreviewData.length}
				spinnerPosition={"center"}
				backgroundStyle={"white"}
			>
				<div className={styles.certificate_screen__certificate_preview}>{getPreviewImage()}</div>
			</LoadingIndicator> */}
		</div>
	);
};

export default observer(PlayerFinalScreen);
