import styles from "./styles.module.scss";
import classNames from "classnames";
import { generatePath, NavLink, useHistory } from "react-router-dom";
import { Routes } from "routes";
import { getUserFullName } from "helpers/string.helper";
import { isNil } from "lodash";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import { getImage } from "helpers/image.helper";
import isEmpty from "lodash/isEmpty";
import { TilePlacement } from "components/partial/peers/peer-tile/peer-tile";
import InfluencerTileInfo from "./influencer-tile-info";

interface Props {
	influencerInfo: IInfluencerDataModel;
	tileStyle?: "default" | "light";
	className?: string;
	tilePlacement?: TilePlacement.default | TilePlacement.horizontal | TilePlacement.vertical;
	openInNewTab?: boolean;
}

const InfluencerTile = ({
	influencerInfo,
	tileStyle = "default",
	className,
	tilePlacement = TilePlacement.default,
	openInNewTab,
}: Props) => {
	const history = useHistory();

	const fullName = getUserFullName(influencerInfo.firstName, influencerInfo.lastName ?? "");
	const forwardPath = generatePath(Routes.InfluencerPofile, { id: influencerInfo.id });
	const goToInfluencer = () => {
		openInNewTab ? window.open(forwardPath, "_blank") : history.push(forwardPath);
	};
	const isLight = tileStyle === "light";

	return (
		<div
			className={classNames(
				styles.influencer_tile,
				{ [styles.influencer_tile__light]: isLight },
				styles[`influencer_tile__${tilePlacement}`],
				className
			)}
		>
			{tilePlacement !== TilePlacement.horizontal && (
				<div className={styles.influencer_tile__header}>
					{!isEmpty(fullName) && (
						<NavLink
							className={classNames(styles.influencer_tile__title, styles.influencer_tile__clickable, {
								[styles.influencer_tile__title__dark]: isLight,
							})}
							to={forwardPath}
							target={openInNewTab ? "_blank" : undefined}
						>
							{fullName}
						</NavLink>
					)}
				</div>
			)}
			<div
				className={classNames(
					styles.influencer_tile__picture_container,
					styles[`influencer_tile__picture_container__${tilePlacement}`]
				)}
			>
				<img
					src={getImage(influencerInfo)}
					alt="Influencer"
					className={classNames(styles.influencer_tile__picture_container__pic, {
						[styles.no_image]: isNil(influencerInfo?.userPicture?.url) || isEmpty(influencerInfo?.userPicture?.url),
					})}
					onClick={() => goToInfluencer()}
				/>
			</div>
			<InfluencerTileInfo influencerInfo={influencerInfo} tilePlacement={tilePlacement} isLight={isLight} />
		</div>
	);
};
export default InfluencerTile;
