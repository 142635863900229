import { some, isNil } from "lodash";
import { IRoleModel } from "models/dto/ZoomiLxp/Models/Profile/IRoleModel";
import { AccountRoles } from "models/dto/ZoomiLxp/Utilities/Enumerations/AccountRoles";

export const userHasRole = (userRole: IRoleModel | undefined | null, targetRole: AccountRoles): boolean => {
	if (!isNil(userRole) &&
		!isNil(userRole.name)) {
		const roleName = AccountRoles[targetRole].toUpperCase();
		return userRole.name.toUpperCase() === roleName;
	}
	return false;
};

export const containsRole = (userRoles: IRoleModel[] | undefined | null, targetRole: AccountRoles): boolean => {
	if (!isNil(userRoles)) {
		const roleName = AccountRoles[targetRole].toUpperCase();
		return some(userRoles, (role) => role.name.toUpperCase() === roleName);
	}
	return false;
};
