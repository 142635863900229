import React from "react";
import styles from "./styles.module.scss";

import { IReportModel } from "store/reports/reports-store";
import { CmsTileProps } from "components/partial/cms/cms-table/cms-table";
import Button from "components/base/button/button";

const AllReportsTile: React.FunctionComponent<CmsTileProps<IReportModel>> = (props: CmsTileProps<IReportModel>) => {
	const { item } = props;
	const { reportName, onExport } = item;

	return (
		<div className={styles.all_reports_tile}>
			<div className={styles.all_reports_tile__wrap}>
				<div className={styles.all_reports_tile__cell_title}>{reportName ?? ""}</div>
				<Button className={styles.all_reports_tile__export_button} onClick={onExport} label="Export" />
			</div>
		</div>
	);
};

export default AllReportsTile;
