import styles from "./styles.module.scss";
import { ReactComponent as CornerIcon } from "assets/icons/ic_corner.svg";
import { DEFAULT_COLOR } from "constants/colors";
import isEmpty from "lodash/isEmpty";
import Avatar from "components/base/avatar/avatar";
import { getAvatarTitle, getUserFullName } from "helpers/string.helper";
import { DateTime } from "luxon";
import { ReactComponent as CommentIcon } from "assets/icons/ic_comment.svg";
import ButtonLike from "components/partial/button-like/button-like";
import ButtonBookmark from "components/partial/button-bookmark/button-bookmark";
import { ReactComponent as ShareIcon } from "assets/icons/ic_share.svg";
import { generatePath } from "react-router-dom";
import { Routes } from "routes";
import { ReactComponent as EditIcon } from "assets/icons/ic_edit.svg";
import StarRating from "components/partial/star-rating/star-rating";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import isNil from "lodash/isNil";
import { copyTextToClipboard } from "helpers/clipboard.helper";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import { ISaveEventThreadHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventThreadHistoryRequestModel";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { getAttachmentPicture, getIsAttachmentPicture, getMediumImage, getThumbnail } from "helpers/image.helper";
import isNull from "lodash/isNull";
import { PermissionConstantsUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsUpdate";
import AccessWrapper from "components/partial/access-wrapper/access-wrapper";
import { infoMessages } from "constants/messages";
import { useShowLinksInText } from "hooks/useShowLinksInText";
import { ToasterStore } from "store/toaster-store/toaster-store";

const cx = classNames.bind(styles);

interface PeerCardProps {
	threadInfo: IThreadModel;
	toasterStore: ToasterStore;
	className?: string;
}

type stateLiked = { active: boolean; count: number };

const PeerCard = (props: PeerCardProps) => {
	const { threadInfo, toasterStore, className } = props;

	const [liked, setLiked] = useState<stateLiked>({
		active: false,
		count: 0,
	});
	const [bookmarked, setBookmarked] = useState(false);
	const history = useHistory();
	const { peersStore, usersStore } = useAppStore();
	const { formattedText } = useShowLinksInText(threadInfo?.description);
	const handleGetLink = (id: number) => {
		const url = window.location.origin;
		const path = generatePath(Routes.PeersThread, { id });
		const fullPath = url + path;

		copyTextToClipboard(fullPath).then(() => {
			toasterStore.showSuccessMessage(infoMessages.linkCopiedToClipboard);
		});
	};

	useEffect(() => {
		if (threadInfo) {
			setLiked({ active: threadInfo.isLiked, count: threadInfo.likesCount });
			setBookmarked(threadInfo.isBookmarked);
		}
	}, [threadInfo]);

	if (isNil(threadInfo)) return <></>;

	const onLike = async () => {
		const active = liked.active;

		let count = liked.count;
		if (active) {
			count--;
			if (count < 0) count = 0;
		} else count++;

		setLiked((prevState) => ({
			active: !prevState.active,
			count,
		}));

		const data = {
			threadId: threadInfo?.id,
			eventType: active ? EventTypes.ThreadUnliked : EventTypes.ThreadLiked,
		} as ISaveEventThreadHistoryRequestModel;
		await peersStore.saveEventThreadHistory(data);
	};

	const onBookmarked = async () => {
		const active = bookmarked;
		setBookmarked(!bookmarked);
		const data = {
			threadId: threadInfo?.id,
			eventType: active ? EventTypes.ThreadUnbookmarked : EventTypes.ThreadBookmarked,
		} as ISaveEventThreadHistoryRequestModel;
		await peersStore.saveEventThreadHistory(data);
	};

	const onRating = async (rating: number) => {
		const data = {
			threadId: threadInfo?.id,
			eventType: EventTypes.ThreadRated,
			payload: JSON.stringify({ rating: rating }),
		} as ISaveEventThreadHistoryRequestModel;
		await peersStore.saveEventThreadHistory(data);
	};

	const getCondition = (): boolean => {
		if (threadInfo && isNull(threadInfo.course)) {
			if (usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.AllThreads])) return true;
			else if (usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.OwnThreads]))
				return usersStore.currentUserInfo?.id === threadInfo.author.id;
		}
		return false;
	};

	const onEdit = () => {
		if (threadInfo?.id) {
			history.push(generatePath(Routes.PeersThreadEdit, { id: threadInfo.id }));
		} else {
			history.push(generatePath(Routes.Home));
		}
	};

	return (
		<>
			{!isNull(threadInfo.thumbnail) && (
				<div className={styles.peer_card__picture}>
					<img src={getMediumImage(threadInfo.thumbnail)} alt="thumbnail" className={styles.peer_card__img} />
				</div>
			)}
			<article className={cx(styles.peer_card, className)}>
				<div className={styles.peer_card__corner}>
					<CornerIcon className={styles.peer_card__icon} />
				</div>
				<div
					className={styles.peer_card__color_bar}
					style={{ backgroundColor: threadInfo?.category?.color ?? DEFAULT_COLOR }}
				/>
				<header className={styles.peer_card__header}>
					{!isEmpty(threadInfo?.author) && (
						<div className={styles.peer_card__avatar}>
							<Avatar
								title={getAvatarTitle(threadInfo?.author.firstName ?? "", threadInfo?.author.lastName ?? "")}
								className={styles.peer_card__cell_avatar}
								size="compact"
								image={
									!isNull(threadInfo?.author.userPicture) ? getThumbnail(threadInfo?.author.userPicture) : undefined
								}
							/>
						</div>
					)}
					<div className={styles.peer_card__box}>
						{!isEmpty(threadInfo?.author) && (
							<div className={styles.peer_card__fullname}>
								{getUserFullName(threadInfo?.author.firstName ?? "", threadInfo?.author.lastName ?? "")}
							</div>
						)}
						{!isEmpty(threadInfo?.created) && (
							<div className={styles.peer_card__datetime}>
								<time className={styles.peer_card__date}>
									{DateTime.fromISO(threadInfo?.created).setLocale("en").toLocaleString(DateTime.DATETIME_MED)}
								</time>
							</div>
						)}
					</div>
				</header>
				<div className={styles.peer_card__content}>
					{!isEmpty(threadInfo?.title) && <h1 className={styles.peer_card__title}>{threadInfo?.title}</h1>}
					{!isEmpty(threadInfo?.description) && <div className={styles.peer_card__description}>{formattedText}</div>}
					{!isEmpty(threadInfo?.uploadedFiles) && (
						<div className={styles.peer_card__uploaded_attachments}>
							{threadInfo?.uploadedFiles.map((item) => {
								return getIsAttachmentPicture(item) ? (
									<Avatar
										size="medium"
										className={styles.peer_card__thumbnail}
										image={getAttachmentPicture(item, false)}
										title={""}
										noEmptyAvatar={true}
										key={item.id}
										onClick={() => window.open(getMediumImage(item), "_blank", "")}
									/>
								) : (
									<a className={styles.peer_card__thumbnail} href={item.url} download key={item.id}>
										<img src={getAttachmentPicture(item, false)} alt="" className={styles.peer_card__attachment_pic} />
									</a>
								);
							})}
						</div>
					)}

					<footer className={styles.peer_card__footer}>
						<div className={styles.peer_card__col}>
							<div className={styles.peer_card__group}>
								<CommentIcon className={styles.peer_card__icon_card} />
								{threadInfo?.totalPostCount}
							</div>
							<div className={styles.peer_card__group}>
								<ButtonLike isLikable={true} isActive={liked.active} isSecondaryType={false} onClick={onLike} />
								{liked.count ?? 0}
							</div>
							<div className={styles.peer_card__group}>
								<ButtonBookmark
									className={styles.peer_card__bookmark}
									bookmarked={bookmarked}
									isBookmarkable={true}
									isSecondaryType={false}
									onClick={() => onBookmarked()}
								/>
								Save
							</div>
							<div
								className={cx(styles.peer_card__group, styles.peer_card__group__share)}
								onClick={() => handleGetLink(threadInfo.id)}
							>
								<ShareIcon className={styles.peer_card__icon_card} />
								Share
							</div>
							<AccessWrapper checkCondition={getCondition}>
								<div className={cx(styles.peer_card__group, styles.peer_card__group__edit)} onClick={onEdit}>
									<EditIcon className={styles.peer_card__icon_card} />
									Edit
								</div>
							</AccessWrapper>
						</div>
						<div className={styles.peer_card__col_second}>
							<StarRating className={styles.peer_card__rating} value={threadInfo?.rating} onRatingChange={onRating} />
						</div>
					</footer>
				</div>
			</article>
		</>
	);
};
export default observer(PeerCard);
