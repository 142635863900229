import React, { useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as EditIcon } from "assets/icons/ic_edit.svg";
import { ReactComponent as RemoveIcon } from "assets/icons/ic_close.svg";
import GoalsForm from "../goals-form/goals-form";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import { IGoalModel } from "models/dto/ZoomiLxp/Models/Goals/IGoalModel";
import { IUpdateGoalModel } from "models/dto/ZoomiLxp/Models/Goals/IUpdateGoalModel";
import { RED } from "constants/colors";
import { useRouteUserId } from "hooks/useRouteUserId";
import { showErrorMessage } from "helpers/error.handling.helper";

interface IGoalsItemProps {
	goal: IGoalModel;
	className?: string;
}

const cx = classNames.bind(styles);

const GoalsItem = (props: IGoalsItemProps) => {
	const { goal, className } = props;
	const { goalsStore } = useAppStore();
	const [isShowForm, setShowForm] = useState(false);
	const { routeUserId } = useRouteUserId();
	const stylesColor = {
		colorCategory: {
			backgroundColor: goal.category?.color ?? RED,
		},
	};

	const onUpdate = (goalModel: IUpdateGoalModel) => {
		setShowForm(false);
		goalsStore
			.updateGoal(
				goal.id,
				{
					weight: goalModel.weight,
					subjectIds: [],
				},
				routeUserId
			)
			.catch((err) => showErrorMessage(goalsStore.alertEventBus, err));
	};

	const onDelete = () => {
		goalsStore.deleteGoal(goal.id, routeUserId).catch((err) => showErrorMessage(goalsStore.alertEventBus, err));
	};

	return (
		<>
			<div className={cx(styles.goals_item, className)}>
				<div className={cx(styles.goals_item__wrap, styles.goals_item__wrap_one)}>
					<div className={styles.goals_item__color} style={stylesColor.colorCategory} />
					<div className={styles.goals_item__name}>{goal.category.name}</div>
					<div className={styles.goals_item__weight}>{goal.weight}%</div>
				</div>
				<div className={cx(styles.goals_item__wrap, styles.goals_item__wrap_two)}>
					<EditIcon
						className={cx(styles.goals_item__icon, styles.goals_item__icon_edit)}
						onClick={() => setShowForm(true)}
					/>
					<RemoveIcon className={cx(styles.goals_item__icon, styles.goals_item__icon_remove)} onClick={onDelete} />
				</div>
			</div>
			{isShowForm && (
				<GoalsForm
					categoryId={goal.category.id}
					onCancel={() => setShowForm(false)}
					onSave={onUpdate}
					onError={(error) => showErrorMessage(goalsStore.alertEventBus, error)}
					isNew={false}
					weight={goal.weight}
				/>
			)}
		</>
	);
};

export default observer(GoalsItem);
