import { makeAutoObservable, runInAction } from "mobx";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { CoursesApi } from "api/controllers/CoursesApi";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { InfluencersApi } from "api/controllers/InfluencersApi";
import { TaxonomyApi } from "api/controllers/TaxonomyApi";
import { PeersApi } from "api/controllers/PeersApi";
import { ActivityApi } from "api/controllers/ActivityApi";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import { AccountApi } from "api/controllers/AccountApi";
import { getInitParams, getSearchInitParams } from "helpers/filter.helper";
import isNil from "lodash/isNil";
import update from "immutability-helper";
import size from "lodash/size";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { getObjectType } from "helpers/typechecking.helper";

export type SearchDataType = IPreviewCourseModel | IInfluencerDataModel | IThreadModel;

export class SearchStore {
	private _isLoadingSearchData: boolean = false;
	private _params: IQueryParams = {} as IQueryParams;
	private _items: SearchDataType[] = [];
	private _coursesResult: IPreviewCourseModel[] = [];
	private _influencersResult: IInfluencerDataModel[] = [];
	private _threadsResult: IThreadModel[] = [];
	private _hasMore: boolean = false;
	private _total: number = 0;
	private _openResultsInNewTab: boolean = true;

	constructor() {
		makeAutoObservable(this);
	}

	async getCreators() {
		try {
			const response = await CoursesApi.getCreators();
			return response.data.data.authors;
		} catch (err) {
			throw err;
		}
	}

	async getSources() {
		try {
			const response = await CoursesApi.getSources();
			return response.data.data.sources;
		} catch (err) {
			throw err;
		}
	}

	async getCategories() {
		try {
			const response = await CoursesApi.getCategories();
			return response.data.data;
		} catch (err) {
			throw err;
		}
	}

	async getSubjects() {
		try {
			const response = await CoursesApi.getSubjects();
			return response.data.data;
		} catch (err) {
			throw err;
		}
	}

	async getCourseTags(tagParams: IQueryParams) {
		const response = await TaxonomyApi.getCoursesTags(tagParams);
		return response.data.data;
	}

	async getThreadTags(tagParams: IQueryParams) {
		const response = await TaxonomyApi.getThreadTags(tagParams);
		return response.data.data;
	}

	async getAreaOfExpertises() {
		const params: IPagingParams = { take: 100, skip: 0 };
		const response = await AccountApi.getAllAreaOfExpertises(params);
		return response.data.data;
	}

	async getJobTitles() {
		const params: IPagingParams = { take: 100, skip: 0 };
		const response = await AccountApi.getAllInfluencerJobTitles(params);
		return response.data.data;
	}

	set isLoadingSearchData(isLoading: boolean) {
		//-- Handling loading for canceled requests. Because when request aborted we have a slight out of sync in changing this variable. Can be removed after searchStore refactoring.
		if (isLoading) {
			setTimeout(() => runInAction(() => (this._isLoadingSearchData = isLoading)));
		} else runInAction(() => (this._isLoadingSearchData = isLoading));
	}

	get isLoadingSearchData() {
		return this._isLoadingSearchData;
	}

	get params(): IQueryParams {
		return this._params;
	}

	set params(value: IQueryParams) {
		this._params = value;
	}

	set items(items) {
		this._items = items;
	}

	get items(): SearchDataType[] {
		return this._items;
	}
	get coursesResult(): IPreviewCourseModel[] {
		return this._coursesResult;
	}
	get influencersResult(): IInfluencerDataModel[] {
		return this._influencersResult;
	}
	get threadsResult(): IThreadModel[] {
		return this._threadsResult;
	}

	set coursesResult(courses: IPreviewCourseModel[]) {
		this._coursesResult = courses;
	}
	set influencersResult(influencers: IInfluencerDataModel[]) {
		this._influencersResult = influencers;
	}
	set threadsResult(threads: IThreadModel[]) {
		this._threadsResult = threads;
	}

	get hasMore(): boolean {
		return this._hasMore;
	}

	set openResultsInNewTab(value: boolean) {
		this._openResultsInNewTab = value;
	}

	get openResultsInNewTab(): boolean {
		return this._openResultsInNewTab;
	}

	get total(): number {
		return this._total;
	}

	clearStoreAll(keepParams?: boolean) {
		runInAction(() => {
			this.coursesResult = [];
			this.influencersResult = [];
			this.threadsResult = [];
			if (!keepParams) {
				this.params = getSearchInitParams();
			}
		});
		this.clearResults();
	}

	clearResults() {
		runInAction(() => {
			this._total = 0;
			this.items = [];
			this._hasMore = true;
		});
	}

	clearSearchResult() {
		this.clearResults();
		runInAction(() => {
			this.params = getInitParams();
		});
	}

	private setInitPagination = (response: IGetRecordsResponse<SearchDataType>) => {
		runInAction(() => {
			this._total = response.totalFilteredRecords;
			this._hasMore = size(response.records) < response.totalFilteredRecords;
		});
	};

	getTableInitialData = async (getData: () => Promise<IGetRecordsResponse<SearchDataType> | undefined>) => {
		this.isLoadingSearchData = true;
		this.clearResults();

		try {
			const response = await getData();
			if (!isNil(response)) {
				runInAction(() => {
					this.items = response.records;
				});
				this.setInitPagination(response);
			}
		} finally {
			this.isLoadingSearchData = false;
		}
	};

	getAdvSearchInitData = async (getData: () => Promise<IGetRecordsResponse<SearchDataType> | undefined>) => {
		this.isLoadingSearchData = true;
		this.clearResults();
		runInAction(() => {
			this.params.skip = 0;
		});
		try {
			const response = await getData();
			if (!isNil(response)) {
				runInAction(() => {
					switch (getObjectType(response.records[0])) {
						case "course":
							this.coursesResult = response.records as IPreviewCourseModel[];
							break;
						case "influencer":
							this.influencersResult = response.records as IInfluencerDataModel[];
							break;
						case "thread":
							this.threadsResult = response.records as IThreadModel[];
							break;
					}
				});
				this.setInitPagination(response);
			}
		} finally {
			this.isLoadingSearchData = false;
		}
	};

	fetchMoreData = async (getData: () => Promise<IGetRecordsResponse<SearchDataType> | undefined>) => {
		if (size(this.items) >= this._total) {
			runInAction(() => (this._hasMore = false));
			return;
		}
		runInAction(() => {
			this.params = update(this.params, { skip: { $set: (this.params?.skip ?? 0) + (this.params?.take ?? 0) } });
		});

		if (getData) {
			const response = await getData();
			if (!isNil(response)) {
				runInAction(() => {
					const countItems = size(response.records);
					if (countItems > 0) {
						this._total = response.totalFilteredRecords;
						this.items = update(this.items, { $push: response.records });
						this._hasMore = size(this.items) < this.total;
					} else {
						this._hasMore = false;
					}
				});
			}
		}
	};

	async searchCourses() {
		this.isLoadingSearchData = true;
		try {
			const response = await CoursesApi.searchCourses(this.params);
			runInAction(() => {
				this.coursesResult = [...this.coursesResult, ...response.data.data.records];
			});
			return response.data.data;
		} finally {
			this.isLoadingSearchData = false;
		}
	}

	async searchInfluencers() {
		this.isLoadingSearchData = true;
		try {
			const response = await InfluencersApi.getAllInfluencers(this.params);
			runInAction(() => {
				this.influencersResult = [...this.influencersResult, ...response.data.data.records];
			});
			return response.data.data;
		} finally {
			this.isLoadingSearchData = false;
		}
	}

	async searchThreads() {
		this.isLoadingSearchData = true;
		try {
			const response = await PeersApi.searchThreadsByQuery(this.params);
			runInAction(() => {
				this.threadsResult = [...this.threadsResult, ...response.data.data.records];
			});
			return response.data.data;
		} finally {
			this.isLoadingSearchData = false;
		}
	}

	// TODO: used for receiveing Infinity Scroll Data, remove after typysation completion

	async searchLogHistory() {
		const response = await ActivityApi.getAll(this.params);
		return response.data.data;
	}
}
