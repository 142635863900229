import styles from "./styles.module.scss";
import Button from "components/base/button/button";
import { useKeycloakProvider } from "hooks/useKeycloakProvider";

const ExternalApiLoginButton = () => {
	const { initiKeycloakWithoutReferrer } = useKeycloakProvider();

	return (
			<Button
				label="Digital University SSO Login"
				onClick={() => initiKeycloakWithoutReferrer()}
				className={styles.external_api_login_button}
				form="default"
				labelColor="textLayoutHeader"
			/>
	);
};
export default ExternalApiLoginButton;
