import React, { ReactNode } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "routes";
import Logo from "components/base/logo/logo";
import Footer from "components/base/footer/footer";

interface ISimpleProps {
	children: ReactNode;
	className?: string;
	cleanPage?: boolean;
}

const cx = classNames.bind(styles);

const SimpleLayout = (props: ISimpleProps) => {
	const { children, className, cleanPage } = props;
	const history = useHistory();
	return (
		<main className={cx(styles.simple, className)}>
			<header className={styles.simple__header}>
				{!cleanPage && <Logo className={styles.simple__logo} onClick={() => history.push(generatePath(Routes.Home))} />}
			</header>
			<article className={styles.simple__article}>{children}</article>
			{!cleanPage && <Footer />}
		</main>
	);
};

export default SimpleLayout;
