//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FilterFunction {
	Like = 1,
	Equal = 2,
	NotEqual = 3,
	LessThan = 4,
	LessThanOrEqual = 5,
	GreaterThan = 6,
	GreaterThanOrEqual = 7,
	IsNull = 8,
	IsNotNull = 9,
	Today = 10,
	ThisWeek = 11,
	ThisMonth = 12,
	IsTrue = 13,
	IsFalse = 14,
	DateRange = 15,
	In = 16
}
