import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import CommentAdd from "./components/comment-add/comment-add";
import { observer } from "mobx-react";
import { FormikValues } from "formik";
import { ICreatePostModel } from "models/dto/ZoomiLxp/Models/Peers/ICreatePostModel";
import { useAppStore } from "store";
import PeersList from "components/partial/peers/peers-list/peers-list";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { SortDirection } from "models/dto/ZoomiLxp/Utilities/Enumerations/SortDirection";
import { scrollType } from "pages/private/peers/peers-topic/peers-topic";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { IPostModel } from "models/dto/ZoomiLxp/Models/Peers/IPostModel";
import Discussion from "../discussion/discussion";
import { emptyErrorInfo, IErrorInfo, makeErrorInfo } from "helpers/error.handling.helper";
import classNames from "classnames";

interface SimpleCommentsProps {
	shareIds: number[];
	isBorderless?: boolean;
}

const SimpleComments = (props: SimpleCommentsProps) => {
	const { shareIds, isBorderless = true } = props;
	const [reload, setReload] = useState(false);
	const { peersStore, coursesStore, toasterStore } = useAppStore();
	const [errorState, setErrorState] = useState<IErrorInfo>(emptyErrorInfo());
	const params = useRef<IQueryParams>({
		skip: 0,
		take: 10,
		filterCriteria: [],
		sortCriteria: [
			{
				order: 1,
				propertyName: "createdUTC",
				direction: SortDirection.Descending,
			},
		],
		queryString: "",
	});

	const getData = useCallback(async () => {
		if (peersStore.currentThread.id) {
			const result = await peersStore
				.getThreadPosts(peersStore.currentThread.id, params.current)
				.catch((err: Error) => {
					setErrorState(makeErrorInfo(err.toString()));
				});
			return result ?? null;
		}
		return null;
	}, [peersStore]);

	const scroll: scrollType = useInfiniteScroll<IPostModel>(getData, params, reload);

	useEffect(() => {
		if (Boolean(peersStore.currentThread.id)) setReload(true);
	}, [peersStore.currentThread.id]);

	useEffect(() => {
		if (scroll.isLoading) setReload(false);
	}, [scroll.isLoading]);

	async function onSave(values: FormikValues) {
		try {
			const data = {
				threadId: peersStore.currentThread.id,
				text: values.description,
				uploadedFiles: values?.uploadedFiles ?? [],
				fromMyDesk: true,
			} as ICreatePostModel;
			await peersStore.addThreadPost(data, coursesStore.curActiveContent.courseItemId);
			setReload(true);
		} catch (err) {
			toasterStore.showErrorMessage(err);
		}
	}

	return (
		<div
			className={classNames(styles.simple_comments, { [styles.borderless]: isBorderless })}
			style={{ height: `${peersStore.parentContainerHeight}px` }}
		>
			{!peersStore.currentThread.id || errorState.isError ? (
				<div className={styles.simple_comments__nodata}>{errorState.errorMessage}</div>
			) : (
				<>
					<Discussion />
					<CommentAdd
						onSave={(values: FormikValues) => onSave(values)}
						className={styles.simple_comments__comment_add}
						type={!isBorderless ? "compact" : undefined}
					/>
					<PeersList
						type={!isBorderless ? "compact" : undefined}
						location="my-desk"
						shareIds={shareIds}
						scroll={scroll}
					/>
				</>
			)}
		</div>
	);
};

export default observer(SimpleComments);
