import styles from "./styles.module.scss";
import classNames from "classnames";
import Avatar from "components/base/avatar/avatar";
import { getAvatarTitle } from "helpers/string.helper";
import { ColumnGroup, TableInstance } from "react-table";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { isNil } from "lodash";
import CmsCheckBtn from "../cms-check-btn/cms-check-btn";
import CmsCheckbox from "../cms-checkbox/cms-checkbox";
import { matchPath, useLocation } from "react-router-dom";
import { useAppStore } from "store";
import { SyntheticEvent } from "react";
import { Routes } from "routes";
import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { ConfirmationModal } from "components/partial/confirmation-popup/confirmation-popup";
import { ErrorMessage, promptsDelete } from "constants/messages";

export enum UsersGridColumnsHeaders {
	Name = "Name",
	Email = "Email",
	Company = "Company",
	Role = "Role",
	RegDate = "Registration date",
	Status = "Status",
	Remove = "",
}
export const Columns = (): ColumnGroup<IProfileDataModel>[] => {
	const { pathname } = useLocation();
	const { groupStore, searchStore } = useAppStore();
	const isGroupsRoute = matchPath(pathname, Routes.CmsGroupProfileTab);

	const onDeleteClick = async (e: SyntheticEvent, userId: number) => {
		e.stopPropagation();
		await modalDialogService.show(ConfirmationModal, {
			title: promptsDelete.title,
			popupText: promptsDelete.text,
			confirmationAction: async () => {
				groupStore.isLoading = true;
				try {
					await groupStore.deleteGroupFromUser(userId);
					searchStore.items = searchStore.items.filter(({ id }) => id !== userId);
				} catch (e) {
					const error = (e as ErrorMessage)?.response?.data?.data?.errorMessage;
					alert(error ? error : e);
				} finally {
					groupStore.isLoading = false;
				}
			},
			confirmationButtonLabel: promptsDelete.confirmationButton,
			cancelButtonLabel: promptsDelete.cancelButton,
		});
	};

	if (isGroupsRoute) {
		return [
			{
				Header: "Users",
				columns: [
					{
						Header: UsersGridColumnsHeaders.Name,
						accessor: "firstName",
						Cell({ cell }) {
							const { firstName, lastName, userPicture } = cell.row.original;
							return (
								<div className={styles.cms_users__cell_name}>
									<Avatar
										title={getAvatarTitle(firstName, lastName)}
										image={userPicture?.url}
										className={styles.cms_users__cell_avatar}
										size="compact"
									/>
									<span className={styles.cms_users__cell_text}>
										{firstName ?? ""} {lastName ?? ""}
									</span>
								</div>
							);
						},
					},
					{
						Header: UsersGridColumnsHeaders.Email,
						accessor: "email",
					},
					{
						Header: UsersGridColumnsHeaders.Company,
						accessor: "company",
						Cell({ cell: { value } }) {
							return <div>{!isNil(value) ? value.name : "-"}</div>;
						},
					},
					{
						Header: UsersGridColumnsHeaders.Role,
						accessor: "roles",
						Cell({ cell: { value } }) {
							return <div>{isNil(value) ? "" : value[0]?.displayName}</div>;
						},
					},
					{
						Header: UsersGridColumnsHeaders.RegDate,
						accessor: "registringDate",
					},
					{
						Header: UsersGridColumnsHeaders.Remove,
						accessor: "id",
						Cell({ cell: { value } }) {
							return (
								<span className={styles.delete_icon} onClick={(e) => onDeleteClick(e, value)}>
									&#10005;
								</span>
							);
						},
					},
				],
			},
		];
	}
	return [
		{
			Header: "Users",
			columns: [
				{
					id: "selection",
					Header: () => <CmsCheckBtn className={styles.cms_users__checkbox} />,
					Cell: (state: TableInstance<IProfileDataModel>) => {
						const { id } = state.cell.row.original;
						return <CmsCheckbox className={styles.cms_users__checkbox} id={id} />;
					},
				},
				{
					Header: UsersGridColumnsHeaders.Name,
					accessor: "firstName",
					Cell({ cell }) {
						const { firstName, lastName, userPicture } = cell.row.original;
						return (
							<div className={styles.cms_users__cell_name}>
								<Avatar
									title={getAvatarTitle(firstName, lastName)}
									image={userPicture?.url}
									className={styles.cms_users__cell_avatar}
									size="compact"
								/>
								<span className={styles.cms_users__cell_text}>
									{firstName ?? ""} {lastName ?? ""}
								</span>
							</div>
						);
					},
				},
				{
					Header: UsersGridColumnsHeaders.Email,
					accessor: "email",
				},
				{
					Header: UsersGridColumnsHeaders.Company,
					accessor: "company",
					Cell({ cell: { value } }) {
						return <div>{!isNil(value) ? value.name : "-"}</div>;
					},
				},
				{
					Header: UsersGridColumnsHeaders.Role,
					accessor: "roles",
					Cell({ cell: { value } }) {
						return <div>{isNil(value) ? "" : value[0]?.displayName}</div>;
					},
				},
				{
					Header: UsersGridColumnsHeaders.RegDate,
					accessor: "registringDate",
				},
				{
					Header: UsersGridColumnsHeaders.Status,
					accessor: "isActive",
					Cell({ cell: { value } }) {
						return (
							<span className={classNames(styles.cms_users__status, { [styles.cms_users__status_success]: value })}>
								{value ? "Active" : "InActive"}
							</span>
						);
					},
				},
			],
		},
	];
};
