import React, { useState } from "react";
import styles from "./styles.module.scss";
import Button from "components/base/button/button";
import { FieldArray, Form, Formik, FormikErrors, FormikValues } from "formik";
import classNames from "classnames/bind";
import InputField from "components/base/input-field/input-field";
import * as Yup from "yup";
import { fieldValidator } from "helpers/validator.helper";
import { isEmpty, isEqual } from "lodash";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import UploadTarget from "components/base/upload-target/upload-target";
import { IFileModel } from "models/dto/ZoomiLxp/Models/Common/IFileModel";
import Avatar from "components/base/avatar/avatar";
import findIndex from "lodash/findIndex";
import { ReactComponent as RemoveIcon } from "assets/icons/ic_close.svg";
import { UploadFileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/UploadFileTypes";
import { IPostModel } from "models/dto/ZoomiLxp/Models/Peers/IPostModel";
import { ReactComponent as SpinnerIcon } from "assets/icons/ic_spinner.svg";
import { errorMessages, infoMessages } from "constants/messages";
import { acceptedMimeTypes, fileUploadRestrictions } from "constants/constants";
import { getAttachmentPicture } from "helpers/image.helper";
import { Prompt } from "react-router";

interface CommentAddProps {
	onSave: (values: FormikValues) => void;
	type?: "compact" | "simple" | "edit" | "plain_compact" | "plain_edit" | "plain_secondary" | "edit_secondary";
	className?: string;
	item?: IPostModel;
	onCancel?: () => void;
	loading?: boolean;
}

const cx = classNames.bind(styles);

const CommentAdd = (props: CommentAddProps) => {
	const { className, onSave, type = "full", item, onCancel, loading } = props;
	const [isFocus, setFocus] = useState(false);
	const [uploadImageButtonDisabled, setUploadImageButtonDisabled] = useState(false);
	const { peersStore, coursesStore, toasterStore } = useAppStore();
	const isSavingDisabled =
		type === "compact"
			? uploadImageButtonDisabled || peersStore.isLoading || !coursesStore.currentCourse.isActive
			: uploadImageButtonDisabled || loading;

	const initialValues = item
		? {
				description: item.text,
				uploadedFiles: item.uploadedFiles,
		  }
		: {
				description: "",
				uploadedFiles: [] as IFileModel[],
		  };

	const schema = Yup.object().shape({
		description: fieldValidator,
	});

	const handelClick =
		(
			values: FormikValues,
			validateForm: (values?: any) => Promise<FormikErrors<FormikValues>>,
			setErrors: (errors: FormikErrors<FormikValues>) => void,
			resetForm: () => void
		) =>
		async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			e.preventDefault();
			const errors = await validateForm();

			if (isEmpty(errors)) {
				resetForm();
				onSave(values);
			} else {
				setErrors(errors);
				toasterStore.showErrorMessage("Please check all fields are filled correctly");
			}
		};

	const isCompactType = type === "compact" || type === "plain_compact" || type === "plain_secondary";
	const isEditType = type === "edit" || type === "plain_edit" || type === "edit_secondary";
	const isSimpleType = type === "simple" || type === "plain_compact" || type === "plain_secondary";

	const getLabel = () => {
		if (isSimpleType) return "Reply";
		else if (isEditType) return "Save";
		return "Post";
	};

	return (
		<div
			className={cx(styles.comment_add, className, {
				[styles.comment_add__compact]: type === "compact" || type === "plain_compact",
				[styles.comment_add__simple]: type === "simple",
				[styles.comment_add__edit]: isEditType,
				[styles.comment_add__plain_secondary]: type === "plain_secondary",
			})}
		>
			<h2
				className={cx(styles.comment_add__head, {
					[styles.comment_add__head__compact]: type === "compact",
					[styles.comment_add__head__simple]: isSimpleType || isEditType,
				})}
			>
				Add post
			</h2>
			<LoadingIndicator loading={peersStore.isLoading}>
				<Formik
					initialValues={initialValues}
					validationSchema={schema}
					onSubmit={() => {}}
					validateOnChange={false}
					enableReinitialize={true}
				>
					{({ values, validateForm, setFieldValue, errors, setErrors, resetForm }) => (
						<Form className={styles.comment_add__form}>
							<Prompt message={infoMessages.leaveWithoutSaving} when={!isEqual(initialValues, values)} />
							<InputField
								inputType="text"
								fieldType="textarea"
								label="Start typing..."
								className={cx(styles.comment_add__field_title, {
									[styles.comment_add__field_focus]: isFocus,
									[styles.comment_add__field_title__compact]: isCompactType,
									[styles.comment_add__field_title__edit]: isEditType,
								})}
								name="description"
								value={values.description}
								onChange={(e) => setFieldValue("description", String(e.target.value))}
								onFocus={() => setFocus(true)}
								onBlur={() => setFocus(false)}
								isError={!!errors.description}
								errorText={errors.description}
							/>
							<div
								className={cx(styles.comment_add__footer, {
									[styles.comment_add__footer__compact]: isCompactType || type === "plain_edit",
								})}
							>
								<div
									className={cx(styles.comment_add__upload_group, {
										[styles.comment_add__upload_group__compact]: isCompactType || type === "plain_edit",
									})}
								>
									<FieldArray
										name="uploadedFiles"
										render={({ push, remove }) => (
											<>
												<UploadTarget
													accept={acceptedMimeTypes.attachments}
													onSuccess={(res, type) => {
														if (!values.uploadedFiles?.length || values.uploadedFiles?.length < 5) {
															push({ id: res.data.id, url: res.data.url, contentType: type });
														}
													}}
													additionalRestrictions={{
														additionalMaxSize: fileUploadRestrictions.maxSize.video,
														additionalRestrictionAcceptType: acceptedMimeTypes.video,
													}}
													onError={(err) => console.log(err)}
													maxSize={fileUploadRestrictions.maxSize.threadAttachments}
													onLoading={(isLoading) => {
														setUploadImageButtonDisabled(isLoading);
													}}
													onWrongFormat={(type) =>
														toasterStore.showErrorMessage(
															errorMessages.unsupportedFileType(
																type,
																".pdf, .png, .jpeg, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4"
															)
														)
													}
													onMaxSizeExceeded={(size, maxSize) => {
														toasterStore.showErrorMessage(errorMessages.exceedingAllowedFileSize(size, maxSize));
													}}
													fileType={UploadFileTypes.PostPicture}
													disabled={values.uploadedFiles?.length >= 5 || uploadImageButtonDisabled}
												>
													<Button
														label={"Upload file"}
														onClick={(event) => {
															if (values.uploadedFiles?.length >= 5) {
																event.stopPropagation();
																event.preventDefault();
																toasterStore.showErrorMessage("The number of files cannot be more than 5");
																return false;
															}
														}}
														className={cx(styles.comment_add__upload_button, {
															[styles.comment_add__upload_button__compact]: isCompactType || type === "plain_edit",
														})}
														disabled={values.uploadedFiles?.length >= 5 || uploadImageButtonDisabled}
													/>
												</UploadTarget>
												<div
													className={cx(styles.comment_add__images_group, {
														[styles.comment_add__images_group__compact]: isCompactType || type === "plain_edit",
													})}
												>
													{isEmpty(values.uploadedFiles) ? (
														<div
															className={cx(styles.comment_add__info, {
																[styles.comment_add__info__edit]: type === "edit",
																[styles.comment_add__info__plain_edit]:
																	type === "plain_edit" || type === "edit_secondary",
															})}
														>
															You can upload up to 5 files
														</div>
													) : (
														values.uploadedFiles?.map((item: IFileModel) => {
															return (
																<Avatar
																	size="medium"
																	className={styles.comment_add__uploaded_image}
																	image={getAttachmentPicture(item, true)}
																	title={""}
																	noEmptyAvatar={true}
																	key={item.id}
																>
																	<div
																		className={styles.comment_add__uploaded_block}
																		onClick={() => {
																			remove(findIndex(values.uploadedFiles, { id: item.id }));
																		}}
																	>
																		<RemoveIcon className={styles.comment_add__uploaded_icon} />
																	</div>
																</Avatar>
															);
														})
													)}
												</div>
											</>
										)}
									/>
								</div>
								<div className={cx(styles.comment_add__btn_group)}>
									{(isEditType || isSimpleType) && (
										<Button
											label={"Cancel"}
											onClick={() => onCancel?.()}
											className={styles.comment_add__btn_cancel}
											type="secondary"
											size="small"
										/>
									)}
									<Button
										label={getLabel()}
										onClick={handelClick(values, validateForm, setErrors, resetForm)}
										className={cx(styles.comment_add__btn_next, {
											[styles.comment_add__btn_next__compact]: type === "compact",
											[styles.comment_add__btn_next__plain]: type === "plain_compact" || type === "plain_secondary",
										})}
										size="small"
										disabled={isSavingDisabled}
										icon={
											peersStore.isLoading || uploadImageButtonDisabled || loading ? (
												<SpinnerIcon className={styles.comment_add__icon_spinner} />
											) : null
										}
									/>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</LoadingIndicator>
		</div>
	);
};

export default observer(CommentAdd);
