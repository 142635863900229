import Api from "api/utils/api";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { INoteModel } from "models/dto/ZoomiLxp/Models/ContentModels/INoteModel";
import { IUpdateNoteModel } from "models/dto/ZoomiLxp/Models/ContentModels/IUpdateNoteModel";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";

export class NotesApi {
	static saveNote(contentId: number, note: IUpdateNoteModel) {
		return Api.put(`/note/${contentId}`, note);
	}

	static getCoursesWithNotes(data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IPreviewCourseModel>, IPagingParams>("/note/GetPreviewCourses", data);
	}

	static getNotesByCourseId(courseId: number, data: IPagingParams) {
		return Api.post<IGetRecordsResponse<INoteModel>, IPagingParams>(`/note/GetNotesByCourseId/${courseId}`, data);
	}
}
