import React from "react";

interface IconCircleProps {
	fill?: string;
	stroke?: string;
}
const IconCircle = (props: IconCircleProps) => {
	const { fill = "none", stroke = "white" } = props;

	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
				fill={fill}
				stroke={stroke}
			/>
		</svg>
	);
};

export default IconCircle;
