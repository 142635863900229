import styles from "../../styles.module.scss";
import ProgressBar from "components/base/progress-bar/progress-bar";
import Tooltip from "components/base/tooltip/tooltip";
import { IMotifMeterItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifMeterItem";
import { ReactComponent as InfoIcon } from "assets/icons/ic_info.svg";
import { useAppStore } from "store";

interface IMotifItemProps {
	motifItem: IMotifMeterItem;
}

interface IMotifProgressBarProps {
	color: string;
	value: number;
	style?: React.CSSProperties;
}

//* Forwarding to motifs grid disabled by client request.

const MotivesMeterItem = (props: IMotifItemProps) => {
	const { /*id, */ name, isPositive, myMotiffsCount, othersMotiffsCount, description } = props.motifItem;
	const { analyticsStore } = useAppStore();
	// const { routeUserId } = useRouteUserId();
	const { allMyMotifsCount, allOthersMotifsCount } = analyticsStore;
	const myPercentage = Math.round(allMyMotifsCount ? (myMotiffsCount * 100) / allMyMotifsCount : 0);
	const othersPercentage = Math.round(allOthersMotifsCount ? (othersMotiffsCount * 100) / allOthersMotifsCount : 0);
	// const history = useHistory();

	const getProgressBarColor = (opacity: number) => {
		return isPositive ? `rgba(93, 95, 239, ${opacity})` : `rgba(239, 93, 168, ${opacity})`;
	};

	const defaultProgressBarStyle: React.CSSProperties = {
		height: 18,
		background: getProgressBarColor(0.3),
		cursor: "pointer",
		marginRight: 10,
	};

	const myProgressBarStyle: React.CSSProperties = {
		...defaultProgressBarStyle,
		borderRadius: "5px 5px 0 0",
	};

	const otherProgressBarStyle: React.CSSProperties = {
		...defaultProgressBarStyle,
		borderRadius: "0 0 5px 5px",
	};

	const MotifProgressBar = ({ color, value, style }: IMotifProgressBarProps) => {
		return (
			<section className={styles.motif_item__progress_bar_box}>
				<ProgressBar
					fillerColor={color}
					completedPercentage={value}
					containerStyles={style}
					onClick={() => {
						// routeUserId
						// 	? history.push(generatePath(Routes.UserMotifStatisticsPage, { motifId: id, userId: routeUserId }))
						// 	: history.push(generatePath(Routes.MotifStatisticsPage, { motifId: id }));
					}}
				/>
				<div className={styles.motif_item__value}>{value}% </div>
			</section>
		);
	};

	return (
		<div className={styles.motif_item}>
			<div className={styles.motif_item__name}>{name}</div>
			<Tooltip label={description} className={styles.analytics_page__info_tooltip}>
				<InfoIcon className={styles.analytics_page__info_icon} />
			</Tooltip>
			<div className={styles.motif_item__progress_bars_container}>
				<MotifProgressBar color={getProgressBarColor(1)} value={myPercentage} style={myProgressBarStyle} />
				<MotifProgressBar color={getProgressBarColor(0.5)} value={othersPercentage} style={otherProgressBarStyle} />
			</div>
		</div>
	);
};
export default MotivesMeterItem;
