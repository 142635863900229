import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useAppStore } from "store";
import { ReactComponent as CheckOff } from "assets/icons/ic_check_off.svg";
import { ReactComponent as CheckOn } from "assets/icons/ic_check_on.svg";
import { ReactComponent as CheckPart } from "assets/icons/ic_check_inter.svg";
import { observer } from "mobx-react";

interface CmsCheckBtnProps {
	className?: string;
}

const cx = classNames.bind(styles);

export enum CheckState {
	Checked,
	Unchecked,
	PartChecked,
}
const CmsCheckButton = ({ className }: CmsCheckBtnProps) => {
	const { bulkOperationsStore } = useAppStore();

	const handleClick = () => {
		if (
			bulkOperationsStore.btnState === CheckState.Unchecked ||
			bulkOperationsStore.btnState === CheckState.PartChecked
		) {
			bulkOperationsStore.checkedAll(true);
			bulkOperationsStore.btnState = CheckState.Checked;
		} else if (bulkOperationsStore.btnState === CheckState.Checked) {
			bulkOperationsStore.checkedAll(false);
			bulkOperationsStore.btnState = CheckState.Unchecked;
		}
	};

	useEffect(() => {
		bulkOperationsStore.btnState = CheckState.Unchecked;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const CheckStateToIconDict = {
		[CheckState.Checked]: (
			<CheckOn className={cx(styles.cms_check_btn__icon, styles.cms_check_btn__icon__check_on)} onClick={handleClick} />
		),
		[CheckState.Unchecked]: (
			<CheckOff
				className={cx(styles.cms_check_btn__icon, styles.cms_check_btn__icon__check_off)}
				onClick={handleClick}
			/>
		),
		[CheckState.PartChecked]: (
			<CheckPart
				className={cx(styles.cms_check_btn__icon, styles.cms_check_btn__icon__check_part)}
				onClick={handleClick}
			/>
		),
	};

	return (
		<div className={cx(styles.cms_check_btn, className)}>{CheckStateToIconDict[bulkOperationsStore.btnState]}</div>
	);
};

export default observer(CmsCheckButton);
