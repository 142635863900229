import { observer } from "mobx-react-lite";
import { IContentStatusModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentStatusModel";
import { useEffect, useState } from "react";
import { useAppStore } from "store";
import Tooltip from "components/base/tooltip/tooltip";
import { ReactComponent as RetryIcon } from "assets/icons/ic_retry.svg";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ContentStatuses } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentStatuses";

interface RetryCellProps {
	processingStatus: IContentStatusModel;
	shortProcessingError?: string;
	contentId: number;
}

const CmsContentStatus = (props: RetryCellProps) => {
	const { processingStatus, shortProcessingError, contentId } = props;
	const { contentStore } = useAppStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [status, setStatus] = useState<IContentStatusModel>(processingStatus);
	const [errStatusName, setErrStatusName] = useState<string>(processingStatus?.name);

	const handleRetry = () => {
		if (contentId) {
			setIsLoading(true);
			contentStore.retryProcessing(contentId).then((response: IContentStatusModel) => {
				contentStore.setRetryStatus(response);

				if (response?.id === ContentStatuses.Error) {
					setErrStatusName(response.name);
				}
				setStatus(response);
				setIsLoading(false);
			});
		}
	};

	useEffect(() => {
		if (processingStatus) {
			setStatus(processingStatus);
		}
	}, [processingStatus]);

	const ProcessingErrorStatus = () => {
		return isLoading ? (
			<div className={styles.retry_loading}>Loading...</div>
		) : (
			<button
				className={styles.retry_btn}
				onClick={handleRetry}
				onMouseEnter={() => setErrStatusName("Retry processing")}
				onMouseLeave={() => setErrStatusName(processingStatus.name)}
			>
				{errStatusName}
				<RetryIcon className={styles.retry_icon} />
			</button>
		);
	};

	const getStatusChip = () => {
		if (status)
			return status?.id === ContentStatuses.Error || status?.id === ContentStatuses.SCORMValidationFailed ? (
				<ProcessingErrorStatus />
			) : (
				<span
					className={classNames(styles.cms_content__status, {
						[styles.cms_content__status_success]: status?.id === ContentStatuses.Done,
						[styles.cms_content__status_error]:
							status?.id === ContentStatuses.ZipExtractingFailed ||
							status?.id === ContentStatuses.SCORMValidationFailed,
					})}
				>
					{status.name}
				</span>
			);
		return <></>;
	};

	return (
		<>
			{shortProcessingError ? (
				<Tooltip label={shortProcessingError} className={styles.cms_content__error_tooltip} isActive={!isLoading}>
					{getStatusChip()}
				</Tooltip>
			) : (
				getStatusChip()
			)}
		</>
	);
};

export default observer(CmsContentStatus);
