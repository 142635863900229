import styles from "./styles.module.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as ArrowLeft } from "assets/icons/ic_arrow-left-2.svg";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useAppStore } from "store";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import InfluencerCard from "components/partial/influencer/influencer-card/influencer-card";
import { observer } from "mobx-react";
import isEmpty from "lodash/isEmpty";
import CourseTile from "components/partial/course-tile/course-tile";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { mobileCheck, tabletCheck } from "helpers/device.helper";
import classNames from "classnames";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import PrivateLayout from "layouts/private/private";
import { TilePlacement } from "components/partial/peers/peer-tile/peer-tile";
import { TransitionSources } from "constants/constants";

const InfluencerProfile = () => {
	const match = useRouteMatch<{ id: string }>();
	const routeInfluencerId = Number(match.params.id);
	const { influencersStore, toasterStore } = useAppStore();
	const [influencer, setInfluencer] = useState<IInfluencerDataModel | null>();
	const history = useHistory();
	const params = useRef<IPagingParams>({
		skip: 0,
		take: 100,
	});

	useEffect(() => {
		if (routeInfluencerId) {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

			influencersStore
				.getInfluencerById(routeInfluencerId)
				.then((response) => setInfluencer(response))
				.catch((err: Error) => {
					toasterStore.showErrorMessage(err);
				});
		} else {
			setInfluencer(null);
			toasterStore.showErrorMessage("Invalid request parameter");
		}
	}, [toasterStore, influencersStore, routeInfluencerId]);

	const getData = useCallback(async () => {
		if (routeInfluencerId) return await influencersStore.getCoursesByInfluencerId(routeInfluencerId, params.current);
		return null;
	}, [influencersStore, routeInfluencerId]);

	const { items, hasMore, fetchMoreData } = useInfiniteScroll<IPreviewCourseModel>(getData, params);

	const isMobile = useMemo(() => mobileCheck(), []);
	const isTablet = useMemo(() => tabletCheck, []);

	const isAdaptive = isMobile || isTablet;

	let counter: number;

	return (
		<PrivateLayout>
			<LoadingIndicator
				loading={influencersStore.isLoading}
				spinnerPosition={"center"}
				className={styles.influencer__loading}
			>
				<div className={styles.influencer_layout__submenu}>
					<ArrowLeft onClick={() => history.goBack()} className={styles.influencer_layout__icon} />
				</div>
				{influencer && <InfluencerCard influencer={influencer} />}
				{!isEmpty(items) && (
					<div className={styles.influencer}>
						<h2 className={styles.influencer__title}>Created</h2>
						<InfiniteScroll
							dataLength={items.length}
							next={fetchMoreData}
							hasMore={hasMore}
							loader={<h4>Loading...</h4>}
						>
							<section className={styles.influencer__grid}>
								{items.map((course: IPreviewCourseModel, index) => {
									if (index === 0 || index % 10 === 0) counter = 0;
									counter++;
									if (counter === 5) {
										return (
											<CourseTile
												courseInfo={course}
												className={classNames({ [styles.influencer__tile_horiz]: !isAdaptive })}
												key={course.id}
												tilePlacement={isAdaptive ? TilePlacement.default : TilePlacement.horizontal}
												courseTileKey={TransitionSources.InfluencerProfile}
											/>
										);
									} else if (counter === 6) {
										return (
											<CourseTile
												courseInfo={course}
												className={classNames({ [styles.influencer__tile_vert]: !isAdaptive })}
												key={course.id}
												tilePlacement={isAdaptive ? TilePlacement.default : TilePlacement.vertical}
												courseTileKey={TransitionSources.InfluencerProfile}
											/>
										);
									}
									return (
										<CourseTile
											courseInfo={course}
											className={styles.influencer__tile}
											key={course.id}
											courseTileKey={TransitionSources.InfluencerProfile}
										/>
									);
								})}
							</section>
						</InfiniteScroll>
					</div>
				)}
			</LoadingIndicator>
		</PrivateLayout>
	);
};

export default observer(InfluencerProfile);
