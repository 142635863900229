import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { IContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentModel";
import PlayerPdf from "../player-pdf/player-pdf";
import PlayerControl, { ControlParams } from "../player-control/player-control";
import { useAppStore } from "store";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { ISegmentPositions } from "../player-layout/player-layout";
import { getFormattedProgress } from "helpers/string.helper";
import { usePlayerNav } from "hooks/usePlayerNav";
import { usePlayerControl } from "hooks/usePlayerControl";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import isNull from "lodash/isNull";
import { IPlayerPropsBase } from "../data/data";

interface IPlayerPdfBoxProps extends IPlayerPropsBase {
	content: IContentModel;
	segment?: ISegmentPositions;
}

const PlayerPdfBox = ({ content, segment, className, onEventHandle }: IPlayerPdfBoxProps) => {
	const { contentStore, playerStore } = useAppStore();
	const { prev, next } = usePlayerNav();

	const handleNext = async (settings: ControlParams) => {
		contentStore.stopKeepAlive();

		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Complete,
			payload: JSON.stringify({
				currentPosition: settings.currentProgress,
			}),
		};
		onEventHandle(data).then(() => {
			next();
		});
	};

	const handlePrev = async (settings: ControlParams) => {
		setParams((prevState: ControlParams) => ({ ...prevState, loading: true }));
		setParams((prevState: ControlParams) => ({ ...prevState, currentProgress: 1 }));

		contentStore.stopKeepAlive();
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.End,
			payload: JSON.stringify({
				currentPosition: settings.currentProgress,
			}),
		};
		onEventHandle(data).then(() => prev());
	};
	const handleSeekChange = (value: number) => {
		if (value > 0 && playerStore.heightPage > 0 && !isNull(playerStore.documentRef))
			playerStore.documentRef.current?.scrollTo({
				top: (value - 1) * playerStore.heightPage + 10,
				left: 0,
				behavior: "smooth",
			});
	};

	const initParams: ControlParams = {
		currentProgress: 1,
		totalProgress: 0,
		boxRef: useRef<HTMLDivElement>(null),
		getFormattedProgress,
		muted: true,
		playing: false,
		handleNext,
		handlePrev,
		isPrev: false,
		isNext: false,
		loading: true,
		isDownload: false,
		urlDownload: "",
		handleSeekChange,
	};
	const [params, setParams] = useState<ControlParams>(initParams);

	usePlayerControl(setParams);

	useEffect(() => {
		const onBeforeUnload = () => {
			contentStore.stopKeepAlive();

			const data: ISaveEventCourseItemHistoryModel = {
				sessionId: contentStore?.sessionId ?? 0,
				eventType: EventTypes.End,
				payload: "",
			};
			onEventHandle(data);
		};
		window.addEventListener("beforeunload", onBeforeUnload);
		return () => window.removeEventListener("beforeunload", onBeforeUnload);
	}, [contentStore, onEventHandle]);

	useEffect(() => {
		playerStore.params = params;
	}, [params, playerStore]);

	useEffect(() => {
		setParams((prevState: ControlParams) => ({
			...prevState,
			isDownload: content.downloadable,
			urlDownload: content.fileContent.url,
		}));
	}, [content.downloadable, content.fileContent.url]);

	if (isEmpty(content.fileContent?.url)) return <div className={styles.player_pdf_box__nodata}>No data</div>;

	return (
		<div className={classNames(styles.player_pdf_box, className)}>
			<PlayerPdf
				content={content}
				params={params}
				setParams={setParams}
				key={content.id}
				segment={segment}
				onEventHandle={onEventHandle}
			>
				<PlayerControl params={params} zoomLimitMultiplier={2} />
			</PlayerPdf>
		</div>
	);
};

export default PlayerPdfBox;
