//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ContentTypes {
	Video = 0,
	Text = 1,
	Podcast = 2,
	Thread = 3,
	Course = 4,
	Scorm = 5,
	Html = 6,
	Zip = 7,
	Mhtml = 8,
	Assessment = 9,
	ExternalContent = 10,
	ExternalLink = 11
}
