import { toBoolean } from "components/partial/cms/cms-settings/settings-converter";
import { getTextWithLinks } from "helpers/string.helper";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { useAppStore } from "store";

export const useShowLinksInText = (text: string) => {
	const { settingsStore } = useAppStore();
	const showLinksInText = toBoolean(settingsStore.publicSettings.Pages?.[SiteSettingsKeys.MyDeskAllowClickableLinks]);
	const formattedText = showLinksInText ? getTextWithLinks(text) : text;

	return { showLinksInText, formattedText };
};
