import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import PrivateLayout from "layouts/private/private";
import PlayerLayout from "components/partial/player/player-layout/player-layout";
import CircleSubmenu from "components/partial/circle-submenu/circle-submenu";
import CourseCard from "components/partial/lesson-card/course-card";
import { generatePath, NavLink, useRouteMatch } from "react-router-dom";
import { useAppStore } from "store";
import { useEffect, useMemo, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import SimpleComments from "components/partial/simple-comments/simple-comments";
import { mobileCheck, tabletCheck } from "helpers/device.helper";
import { ContentItemTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentItemTypes";
import { ContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentTypes";
import last from "lodash/last";
import { Routes } from "routes";
import { ReactComponent as StackIcon } from "assets/icons/ic_stack.svg";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import isNull from "lodash/isNull";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { DELAY_TIME } from "constants/constants";
import { IBadRequestModel } from "models/dto/ZoomiLxp/Models/Common/IBadRequestModel";
import { isApiError } from "api/utils/api";
import classNames from "classnames";
import MyDeskChaptersSection from "./components/myDesk-chapters-section";
import MyDeskCoursesSection from "./components/myDesk-courses-section";
import { ICourseModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseModel";
import isNil from "lodash/isNil";

export const MyDesk = observer(function MyDesk() {
	const { coursesStore, playerStore, contentStore, peersStore, settingsStore, usersStore, toasterStore } = useAppStore();
	const match = useRouteMatch<{ courseId: string; courseItemId: string; startPosition: string; shareLink: string }>();
	const userId = usersStore.currentUserInfo!.id;
	const courseItemId = Number(match.params?.courseItemId ?? 0);
	const startPosition = Number(match.params?.startPosition ?? 0);
	const shareLink = String(match.params?.shareLink ?? "");
	const shareIds: number[] = useMemo(() => shareLink?.split(":").map((item) => Number(item)) ?? [], [shareLink]);
	const [isLoading, setLoading] = useState(false);
	const isSessionInitialized = Boolean(contentStore.sessionId);
	const ref = useRef<HTMLDivElement>(null);
	const calledOnce = useRef(false);
	const isMobile = useMemo(() => mobileCheck(), []);
	const isTablet = useMemo(() => tabletCheck, []);
	const isAdaptive = isMobile || isTablet;
	const isCommentsOnRightSide =
		!isAdaptive &&
		settingsStore.publicSettings?.Pages[SiteSettingsKeys.MyDeskCommentsOnTheRightSide]?.toLowerCase() === "true";
	const isRecommendationsBelowComments =
		settingsStore.publicSettings?.Pages[SiteSettingsKeys.MyDeskRecommendationsBelowComments]?.toLowerCase() === "true";
	const isCommentsAboveCarousel = !isCommentsOnRightSide && isRecommendationsBelowComments;
	const isCommentsBelowCarousel = !isCommentsOnRightSide && !isRecommendationsBelowComments;
	const courseId = Number(match.params?.courseId);
	const isHasChapters = !isNil(coursesStore.activeChaptersGroup) && coursesStore.activeChaptersGroup?.length > 1;

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		setLoading(true);
		coursesStore.clearStore();

		if (!courseId) {
			coursesStore
				.getLastWatchedCourse()
				.then((course: ICourseModel) => coursesStore.getMyDeskCourses(userId, course.id))
				.catch((err) => toasterStore.showErrorMessage(err))
				.finally(() => setLoading(false));
		} else if (courseId > 0) {
			Promise.allSettled([
				coursesStore.getCourseById(courseId, courseItemId),
				coursesStore.getMyDeskCourses(userId, courseId),
			])
				.catch((err) => toasterStore.showErrorMessage(err))
				.finally(() => setLoading(false));
		}
	}, [toasterStore, contentStore, courseItemId, coursesStore, peersStore, courseId, userId]);

	useEffect(() => {
		const seekChange = () => {
			setTimeout(() => {
				playerStore.params?.handleSeekChange?.(startPosition);
				calledOnce.current = true;
			}, DELAY_TIME);
		};

		if (!calledOnce.current) {
			if (courseId > 0 && startPosition > 0) {
				const curContent = coursesStore.curActiveContent;
				const contentType = curContent?.content?.contentType;
				if (curContent?.contentItemType === ContentItemTypes.Content) {
					if (
						(contentType === ContentTypes.Video || contentType === ContentTypes.Podcast) &&
						playerStore.params?.playerRef
					)
						seekChange();
					else if (contentType === ContentTypes.Text && playerStore.params?.isReady) seekChange();
				}
			}
		}
	}, [coursesStore.curActiveContent, playerStore.params, startPosition, courseId]);

	useEffect(() => {
		return () => contentStore.stopKeepAlive();
	}, [contentStore]);

	useEffect(() => {
		if (!isEmpty(shareIds)) peersStore.shareId = last(shareIds) ?? null;
	}, [peersStore, shareIds]);

	useEffect(() => {
		const id = coursesStore.curActiveContent.courseItemId;
		if (id) {
			peersStore.getRunningThread().catch((err: unknown) => {
				if (isApiError<IBadRequestModel>(err) && err.response) {
					const data = err.response?.data?.data;
					if (data?.errorMessage === settingsStore.publicSettings?.General[SiteSettingsKeys.GetThreadAccessMsg]) {
						return;
					}
				}
				toasterStore.showErrorMessage(err);
			});
		}
	}, [toasterStore, coursesStore.curActiveContent.courseItemId, peersStore, settingsStore.publicSettings]);

	useEffect(() => {
		coursesStore.isPartCourse = courseItemId === coursesStore.curActiveContent.courseItemId;
	}, [courseItemId, coursesStore, coursesStore.curActiveContent.courseItemId]);

	useEffect(() => {
		setTimeout(() => {
			if (ref.current?.clientHeight && coursesStore.curActiveContent.courseItemId) {
				peersStore.parentContainerHeight = ref.current.clientHeight;
			}
		}, DELAY_TIME);
	}, [peersStore, coursesStore.curActiveContent.courseItemId]);

	const goToCourse = () =>
		isNull(coursesStore.curActiveContent.chapter)
			? generatePath(Routes.CoursePage, {
					id: coursesStore.currentCourse?.id ?? 0,
			  })
			: generatePath(Routes.CoursePageChapter, {
					id: coursesStore.currentCourse?.id ?? 0,
					chapterId: coursesStore.curActiveContent.chapter?.id ?? 0,
			  });

	return (
		<PrivateLayout>
			<div className={styles.myDesk}>
				<div className={styles.myDesk__nav_container}>
					{coursesStore.activeCourseItems.length > 1 && (
						<NavLink className={styles.myDesk__link} to={goToCourse()}>
							<StackIcon className={styles.myDesk__stack_icon} />
							{!isAdaptive && (
								<span
									className={classNames({
										[styles.myDesk__back_btn_label]: isHasChapters,
									})}
								>
									Back to Course Home
								</span>
							)}
						</NavLink>
					)}
					<MyDeskChaptersSection courseId={courseId} />
				</div>
				<div className={classNames(styles.myDesk__wrap, { [styles.myDesk__wide_container]: !isCommentsOnRightSide })}>
					<CircleSubmenu />
					<div className={styles.myDesk__box} ref={ref}>
						<LoadingIndicator
							loading={isLoading || !isSessionInitialized}
							spinnerPosition={"center"}
							backgroundStyle={"white"}
						>
							<PlayerLayout />
						</LoadingIndicator>
						<div className={styles.myDesk__course_card_container}>
							{coursesStore.isCurrentCourseActive && <CourseCard />}
						</div>
					</div>
					{isCommentsOnRightSide && <SimpleComments shareIds={shareIds} isBorderless={false} />}
				</div>
				{isCommentsAboveCarousel && <SimpleComments shareIds={shareIds} />}
				<MyDeskCoursesSection />
				{isCommentsBelowCarousel && <SimpleComments shareIds={shareIds} />}
			</div>
		</PrivateLayout>
	);
});
