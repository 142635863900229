import Popup from "reactjs-popup";
import mitt from "mitt";
import React, { createContext, useContext, useEffect, useState } from "react";

type ModalEvents = {
	show: ModalDialogState;
};

export interface ModalContextProps<T = unknown> {
	resolve(): void;
	resolve(result: T): void;
	className?: string;
}

export interface ModalComponent<TProps, TResolve> {
	component: React.FunctionComponent<TProps>;
	modalContext: React.Context<ModalContextProps<TResolve>>;
}

interface ModalDialogState {
	popupComponent?: ModalComponent<any, any>;
	params?: any;
	resolve?: (result: any) => void;
	closeOnOutsideClick?: boolean;
}

const modalEmitter = mitt<ModalEvents>();

class ModalService {
	show<TProps, TResolve>(component: ModalComponent<TProps, TResolve>, params: TProps, closeOnOutClick?: boolean) {
		return new Promise<TResolve | undefined>((resolve) => {
			modalEmitter.emit("show", {
				popupComponent: component,
				params: params,
				resolve: resolve,
				closeOnOutsideClick: closeOnOutClick,
			});
		});
	}
}

export const modalDialogService = new ModalService();

export function createModalContext<T = unknown>() {
	const modalContext = createContext<ModalContextProps<T>>(null as any);

	const useModalContext = () => {
		return useContext(modalContext);
	};

	return { modalContext, useModalContext };
}

const ModalDialog = () => {
	const [popupState, setPopupState] = useState<ModalDialogState>({});
	const { popupComponent } = popupState;
	useEffect(() => {
		modalEmitter.on("show", (data) => setPopupState(data));

		return () => {
			modalEmitter.all.clear();
		};
	});

	const resolve = (data?: any) => {
		if (popupComponent) {
			popupState.resolve?.(data);
			setPopupState({});
		}
	};

	return (
		<Popup
			onClose={() => resolve()}
			closeOnDocumentClick={popupState.closeOnOutsideClick}
			open={!!popupComponent}
			overlayStyle={{
				background: "rgba(0,0,0,0.5)",
			}}
		>
			{popupComponent && (
				<popupComponent.modalContext.Provider value={{ resolve }}>
					<popupComponent.component {...popupState.params} />
				</popupComponent.modalContext.Provider>
			)}
		</Popup>
	);
};

export default ModalDialog;

