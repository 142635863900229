import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { IFileModel } from "models/dto/ZoomiLxp/Models/Common/IFileModel";
import { FileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/FileTypes";
import { ConfirmationModal } from "../confirmation-popup/confirmation-popup";
import { UploadFileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/UploadFileTypes";
import ImageCropper from "components/partial/image-cropper/image-cropper";

export interface ICroppedFileOptions {
	imageType: FileTypes;
	uploadFileType: UploadFileTypes;
}

export interface IImageCropperProps {
	parentFile: IFileModel;
	filesOptions: ICroppedFileOptions;
	onCropSuccessHandler: (newFile: IFileModel) => void;
	onLoading?: (isLoading: boolean) => void;
	isCircularCrop?: boolean;
	aspect?: number;
}

export const cropImageHandler = async (props: IImageCropperProps) => {
	const { parentFile, filesOptions, onCropSuccessHandler, onLoading, isCircularCrop, aspect } = props;
	let closeModalFn = (): void => {};
	await modalDialogService.show(ConfirmationModal, {
		title: "Crop your image",
		children: (
			<ImageCropper
				imageFile={parentFile}
				fileOptions={filesOptions}
				onCrop={(file) => {
					onCropSuccessHandler(file);
				}}
				onConfirm={() => closeModalFn()}
				onLoading={(isLoading) => onLoading?.(isLoading)}
				isCircularCrop={isCircularCrop}
				aspect={aspect}
			/>
		),
		hideCancelBtn: true,
		hideConfirmBtn: true,
		showCloseIcon: true,
		closeCallback: (closeFn) => (closeModalFn = closeFn),
	});
};

export const getAspect = (isCircularCrop: boolean, fileOptions: ICroppedFileOptions) => {
	const isLogo = fileOptions.uploadFileType === UploadFileTypes.Logo;

	const isBanner =
		!isCircularCrop &&
		fileOptions.imageType === FileTypes.Medium &&
		(fileOptions.uploadFileType === UploadFileTypes.HomeBanner ||
			fileOptions.uploadFileType === UploadFileTypes.PeersBanner ||
			fileOptions.uploadFileType === UploadFileTypes.InfluencersBanner);

	const isThumbnail =
		!isCircularCrop &&
		fileOptions.imageType === FileTypes.Thumbnail &&
		fileOptions.uploadFileType !== UploadFileTypes.Favicon;

	if (isLogo) {
		return undefined;
	}

	if (isThumbnail) {
		return 4 / 3;
	}
	if (isBanner) {
		return 16 / 3;
	}
	if (isCircularCrop || fileOptions.uploadFileType === UploadFileTypes.Favicon) {
		return 1;
	}

	return 16 / 9;
};
