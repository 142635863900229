import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import Api from "../utils/api";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IActivityModel } from "models/dto/ZoomiLxp/Models/Activities/IActivityModel";
import { IMainTaskRequestModel } from "models/dto/ZoomiLxp/Models/BulkOperations/IMainTaskRequestModel";
import { IDummyResponseModel } from "models/dto/ZoomiLxp/Models/Common/IDummyResponseModel";
import { ITenantModel } from "models/dto/ZoomiLxp/Models/Tenants/ITenantModel";
import { getInitParams } from "helpers/filter.helper";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";

const initialActivityParams = (argument: string, pageSize: number = 1) => ({
	...getInitParams(pageSize),
	filterCriteria: [{ propertyNames: ["ActivityTypeId"], function: FilterFunction.Equal, argument }],
});
export class ActivityApi {
	static getAll(queryParams: IQueryParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IActivityModel>>("/Activity/GetAll", queryParams);
	}

	static createBulkOperation(model: IMainTaskRequestModel) {
		return Api.post<IGetRecordsResponse<IDummyResponseModel>>("/Activity/CreateBulkOperation", model);
	}

	static getActivityTenants() {
		return Api.get<ITenantModel[]>("/Activity/Tenants");
	}

	static exportActivity(activityId: number) {
		return Api.post(`/Activity/Export/${activityId}`, undefined, {
			responseType: "blob",
		});
	}

	static activeOperations<TRecord>(argument: string) {
		return Api.postUnwrapped<IGetRecordsResponse<TRecord>>(`/Activity/ActiveOperations`, {
			...initialActivityParams(argument),
		});
	}
}
