//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum BulkOperationType {
	CourseAssignment = 1,
	CourseUnAssignment = 2,
	CourseCloning = 3,
	SetСreator = 4,
	SetSubject = 5,
	BulkGroupAssignment = 6,
	BulkGroupUnAssignment = 7,
	UserCloning = 8,
	BulkCourseTagAssignment = 9,
	BulkCourseTagUnAssignment = 10,
	BulkThreadTagAssignment = 11,
	BulkThreadTagUnAssignment = 12,
	BulkCourseGroupAssignment = 13,
	BulkCourseGroupUnAssignment = 14,
	BulkThreadGroupAssignment = 15,
	BulkThreadGroupUnAssignment = 16,
	ReindexCourses = 17,
	ReindexContents = 18,
	ReindexThreads = 19,
	ReindexAccounts = 20,
	ReindexCompanies = 21,
	BulkThreadCommentCloning = 22
}
