import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import Widget from "components/partial/widget/widget";
import { IWidgetMeta } from "helpers/reports.helper";
import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { ConfirmationModal } from "components/partial/confirmation-popup/confirmation-popup";
import { promptsDelete } from "constants/messages";
import "./styles.css";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";

interface BodyDashboardProps {
	className?: string;
}

const cx = classNames.bind(styles);

const ResponsiveGridLayout = WidthProvider(Responsive);
const BodyDashboard = (props: BodyDashboardProps) => {
	const { className } = props;
	const { reportsStore } = useAppStore();

	const handelDelete = async (meta: IWidgetMeta) => {
		await modalDialogService.show(ConfirmationModal, {
			title: promptsDelete.title,
			popupText: promptsDelete.text,
			confirmationAction: () => reportsStore.deleteWidget(meta.id),
			confirmationButtonLabel: promptsDelete.confirmationButton,
			cancelButtonLabel: promptsDelete.cancelButton,
		});
	};
	const handleCollapse = (meta: IWidgetMeta, isCollapse: boolean) => reportsStore.collapseWidget(meta.id, isCollapse);

	return (
		<div className={cx(styles.body_dashboard, className)}>
			<LoadingIndicator
				loading={reportsStore.isLoading}
				spinnerPosition="center"
				backgroundStyle={"none"}
				className={styles.body_dashboard__loading}
			>
				<ResponsiveGridLayout
					className={styles.body_dashboard__layout}
					breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
					cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
					layouts={reportsStore.viewWidgets}
					useCSSTransforms={false}
					onLayoutChange={(_, layouts) => (reportsStore.viewWidgets = layouts)}
				>
					{reportsStore.metaWidgets.map((meta) => (
						<div key={meta.id} className={styles.body_dashboard__item}>
							<Widget meta={meta} onDelete={handelDelete} onCollapse={handleCollapse} />
						</div>
					))}
				</ResponsiveGridLayout>
			</LoadingIndicator>
		</div>
	);
};

export default observer(BodyDashboard);
