import Api, { ApiBase } from "../utils/api";
import { ICreateAttemptResponse } from "models/dto/ZoomiLxp/Models/LMS/ICreateAttemptResponse";
import { IPackageMetadata } from "models/dto/ZoomiLxp/Models/LMS/IPackageMetadata";
import { AxiosResponse } from "axios";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import { IActivityInfo } from "models/dto/ZoomiLxp/Models/LMS/IActivityInfo";
import { ICreateAttemptRequest } from "models/dto/ZoomiLxp/Models/LMS/ICreateAttemptRequest";

export class LmsApi {
	static createAttempt(id: string) {
		return ApiBase.post<ICreateAttemptRequest, AxiosResponse<IResponseModel<ICreateAttemptResponse>>>(
			"/LMS/CreateAttempt",
			{
				organizationId: id,
			}
		);
	}

	static getPackageMetadata(contentId: number) {
		return Api.get<IPackageMetadata>(`/LMS/GetPackageMetadata/${contentId}`);
	}

	static getActivityInfo(view: number, attemptId: number) {
		return Api.get<IActivityInfo>(`/LMS/ActivityInfo/${view}/${attemptId}`);
	}

	static setActivityInfo(view: number, attemptId: number) {
		return Api.post<IActivityInfo, AxiosResponse<IResponseModel<IActivityInfo>>>(
			`/LMS/ActivityInfo/${view}/${attemptId}`
		);
	}

	static getPackageContent(contentId: number) {
		return Api.get<string>(`/LMS/PackageContent/${contentId}/`);
	}
}
