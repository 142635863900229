import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { useAppStore } from "store";
import {
	getAuthorsOptions,
	getSources,
	getSubjectOptions,
	getTagsOptions,
	IOptionsItem,
	searchStyleSheet,
} from "helpers/select.helper";
import DateFilter from "components/partial/filters/date-filter/date-filter";
import MultiSelectFilter from "components/partial/filters/select-filter/multi-select-filter";
import { getSearchInitParams } from "helpers/filter.helper";
import SearchClearButton from "./filter-clear-button";
import { observer } from "mobx-react";
import isEqual from "lodash/isEqual";
import { useAdvSearch } from "hooks/useAdvSearch";
import isEmpty from "lodash/isEmpty";
import { IDefaultFilter } from "./data";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { TaxonomyApi } from "api/controllers/TaxonomyApi";
import { emptyQueryParams } from "helpers/params.helper";
import { useCategorySubjects } from "hooks/useCategorySubjects";

interface IAdvSearchFilterProps extends IDefaultFilter {}

interface IAdvFilterOptions {
	categories?: IOptionsItem[];
	subjects?: IOptionsItem[];
	sources?: IOptionsItem[] | { value: string; label: string }[];
	authors?: IOptionsItem[];
	tags?: IOptionsItem[];
}

const AdvSearchCourseFilters: FC<IAdvSearchFilterProps> = ({ className }) => {
	const [filtersOptions, setFilterOptions] = useState<IAdvFilterOptions>({} as IAdvFilterOptions);
	const { searchStore, toasterStore, taxonomyStore } = useAppStore();
	const { courseCategorySubjects } = useCategorySubjects();
	const [isClear, setIsClear] = useState(false);
	const hasFilters = !isEqual(getSearchInitParams(), searchStore.params) || searchStore.coursesResult.length;
	const [tagParams, setTagParams] = useState<IQueryParams>({ ...emptyQueryParams });
	const [categoryParams, setCategoryParams] = useState<IQueryParams>({ ...emptyQueryParams });
	const [tagsIsLoading, setTagsIsLoading] = useState(false);

	useEffect(() => {
		Promise.allSettled([
			searchStore.getSubjects(),
			searchStore.getCreators(),
			searchStore.getSources(),
		]).then((response) => {
			setFilterOptions((prevState) => ({
				...prevState,
				subjects: response[0].status === "fulfilled" ? getSubjectOptions(response[0].value) : [],
				authors: response[1].status === "fulfilled" ? getAuthorsOptions(response[1].value) : [],
				sources: response[2].status === "fulfilled" ? getSources(response[2].value) : [],
			}));
			const errors = response
				.filter((result) => result.status === "rejected")
				.map((result) => ("reason" in result ? result.reason : null));
			if (!isEmpty(errors)) toasterStore.showErrorMessage(errors);
		});
	}, [searchStore, tagParams, toasterStore]);

	const { setParams } = useAdvSearch();

	useEffect(() => {
		TaxonomyApi.getCoursesTags(tagParams)
			.then((e) => {
				setFilterOptions((prevState) => ({
					...prevState,
					tags: getTagsOptions(e.data.data),
				}));
			})
			.finally(() => setTagsIsLoading(false));
	}, [tagParams, tagParams.queryString, tagParams.filterCriteria]);

	useEffect(() => {
		setFilterOptions((prevState) => ({
			...prevState,
			categories: courseCategorySubjects,
		}));
	}, [courseCategorySubjects]);

	useEffect(() => {
		taxonomyStore.getAllCategories();
	}, [taxonomyStore]);

	return (
		<div className={classNames(styles.adv_search_filter__row, className)}>
			<MultiSelectFilter
				containerClassName={styles.adv_search_filter__select_container}
				customStyles={searchStyleSheet}
				className={styles.adv_search_filter__col}
				params={searchStore.params}
				setParams={setParams}
				label="Creator"
				options={filtersOptions.authors ?? []}
				propertyName="CreatorId"
				isClear={isClear}
			/>

			<MultiSelectFilter
				containerClassName={classNames(styles.adv_search_filter__select_container, styles.adv_search_filter__select_container_category)}
				customStyles={searchStyleSheet}
				className={classNames(styles.adv_search_filter__col, styles.adv_search_filter__col_category)}
				params={searchStore.params}
				setParams={setParams}
				label="Category | Subjects"
				options={filtersOptions.categories ?? []}
				propertyName="SubjectId"
				isClear={isClear}
				querySearchParams={{
					params: categoryParams,
					setParams: setCategoryParams,
					propertyName: "name",
				}}
			/>

			<MultiSelectFilter
				containerClassName={classNames(styles.adv_search_filter__select_container, styles.adv_search_filter__select_container_source)}
				customStyles={searchStyleSheet}
				className={classNames(styles.adv_search_filter__col, styles.adv_search_filter__col_source)}
				params={searchStore.params}
				setParams={setParams}
				label="Source"
				options={filtersOptions.sources ?? []}
				propertyName="Source"
				isClear={isClear}
			/>

			<DateFilter
				className={classNames(styles.adv_search_filter__select_container)}
				buttonClassName={styles.adv_search_filter__col}
				params={searchStore.params}
				setParams={setParams}
				label="Last updated"
				propertyName="UpdatedUtc"
				isClear={isClear}
			/>

			<MultiSelectFilter
				containerClassName={styles.adv_search_filter__select_container}
				customStyles={searchStyleSheet}
				className={styles.adv_search_filter__col}
				params={searchStore.params}
				setParams={setParams}
				label="Tags"
				options={filtersOptions.tags ?? []}
				propertyName="Tags.Id"
				isClear={isClear}
				isSearchable={true}
				querySearchParams={{
					params: tagParams,
					setParams: setTagParams,
					isLoading: tagsIsLoading,
					propertyName: "name",
				}}
			/>

			{hasFilters ? <SearchClearButton setIsClear={setIsClear} /> : <></>}
		</div>
	);
};

export default observer(AdvSearchCourseFilters);
