export function copyTextToClipboard(text: string): Promise<void> {
    if (!navigator.clipboard) {
        return fallbackCopyTextToClipboard(text);
    }

    return navigator.clipboard.writeText(text).catch(() => {
        return fallbackCopyTextToClipboard(text);
    });
}

function fallbackCopyTextToClipboard(text: string): Promise<void> {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    let successful = true;
    try {
        successful = document.execCommand("copy");
    } catch {
        successful = false;
    }

    document.body.removeChild(textArea);
    return successful ? Promise.resolve() : Promise.reject();
}
