//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class PermissionConstantsCreate
{
	public static LearnerProfileDataCreate: string = `create.learner_profile_data`;
	public static PersonalAnalytics: string = `create.personal_analytics`;
	public static OtherUserAnalytics: string = `create.other_users_analytics`;
	public static PersonalHistory: string = `create.personal_history`;
	public static OtherUserHistory: string = `create.other_users_history`;
	public static GoalsMyPerfectBrain: string = `create.goals_my_perfect_brain`;
	public static OthersGoalsMyPerfectBrain: string = `create.other_goals_my_perfect_brain`;
	public static OtherCompanyUserGoals: string = `create.other_company_users_goals`;
	public static Billing: string = `create.billing`;
	public static Courses: string = `create.courses`;
	public static CompanyUsers: string = `create.company_users`;
	public static Company: string = `create.company`;
	public static Taxonomy: string = `create.taxonomy`;
	public static Purchases: string = `create.purchases`;
	public static Threads: string = `create.threads`;
	public static Messages: string = `create.messages`;
	public static Account: string = `create.account`;
	public static Notes: string = `create.notes`;
	public static ThreadComments: string = `create.thread_comments`;
	public static ScheduleEmailSettings: string = `create.schedule_email_settings`;
	public static TenantAdmin: string = `create.tenant_admin`;
	public static Widgets: string = `create.widgets`;
	public static RequestAssignments: string = `create.request_assignments`;
}
