import styles from "./styles.module.scss";
import { ReactNode } from "react";
import classNames from "classnames";

interface Props {
	loading: boolean;
	hideChildrenDuringLoading?: boolean;
	backgroundStyle?: "default" | "none" | "blur" | "white" | "light_gray";
	backgroundTheme?: "layout" | "light" | "dark";
	spinnerPosition?: "center" | "top" | "fixed";
	isFullScreen?: boolean;
	className?: string;
	children?: ReactNode;
}

const LoadingIndicator = (props: Props) => {
	const { backgroundStyle = "default", spinnerPosition = "top", backgroundTheme = "layout", className } = props;

	return (
		<div
			className={classNames(
				styles.loading_indicator,
				styles[`loading_indicator__bg_${backgroundStyle}`],
				styles[`loading_indicator__bg_theme_${backgroundTheme}`],
				styles[`loading_indicator__spinner_${spinnerPosition}`],
				props.isFullScreen && styles.loading_indicator__fullscreen,
				className
			)}
		>
			{(!props.loading || !props.hideChildrenDuringLoading) && props.children}
			{props.loading && (
				<div className={styles.loading_indicator__fade}>
					<div className={styles.loader} />
				</div>
			)}
		</div>
	);
};

export default LoadingIndicator;
