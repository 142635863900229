import { observer } from "mobx-react";
import PrivateLayout from "layouts/private/private";
import styles from "./styles.module.scss";
import Banner from "components/base/banner/banner";
import { useAppStore } from "store";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";

export const PaymentFailedPage = observer(function PaymentFailedPage() {
	const { settingsStore } = useAppStore();
	return (
		<PrivateLayout>
			<div className={styles.payment_result_page}>
				<Banner
					bannerContentType={settingsStore.publicSettings.Images?.[SiteSettingsKeys.HomeBannerContentType]}
					bannerSrc={settingsStore.publicSettings.Images?.[SiteSettingsKeys.HomeBanner]}
				/>

				<div className={styles.payment_result_page__content}>
					<span className={styles.payment_result_page__text}>Payment was failed</span>
				</div>
			</div>
		</PrivateLayout>
	);
});
