import { FormikHelpers } from "formik";
import styles from "../styles.module.scss";
import { useAppStore } from "store";
import Checkbox from "components/base/checkbox/checkbox";
import { ICloneUserOptions } from "models/dto/ZoomiLxp/Models/BulkOperations/CloningUsersBulkOperation/ICloneUserOptions";

interface IUsersCloningFormProps {
	values: ICloneUserOptions;
	formHelpers: FormikHelpers<ICloneUserOptions>;
}

const UsersCloningForm = ({ values, formHelpers }: IUsersCloningFormProps) => {
	const { bulkOperationsStore } = useAppStore();
	const { setFieldValue } = formHelpers;

	return (
		<>
			<Checkbox
				label="User`s company"
				value={values.copyCompany}
				onChange={(value) => {
					bulkOperationsStore.usersCloningOptions = {
						...bulkOperationsStore.usersCloningOptions,
						copyCompany: value,
					};
					setFieldValue("copyCompany", value);
				}}
				className={styles.bulk_cloning_popup__checkbox}
			/>
		</>
	);
};

export default UsersCloningForm;
