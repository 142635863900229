import React, { useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { Columns } from "./data";
import { usePagination, useTable } from "react-table";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { useAsyncDataTable } from "hooks/useAsyncDataTable";
import { AxiosResponse } from "axios";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import CmsTable from "components/partial/cms/cms-table/cms-table";
import CmsFooter from "components/partial/cms/cms-footer/cms-footer";

import { useAppStore } from "store";
import { useRouteUserId } from "hooks/useRouteUserId";
import { ICertificatePreviewModel } from "models/dto/ZoomiLxp/Models/Certificates/ICertificatePreviewModel";
import MyCertificatesTile from "./my-certificates-tile";
import { CertificatesApi } from "api/controllers/CertificatesApi";

const MyCertificates = () => {
	const { routeUserId: otherUserId } = useRouteUserId();
	const tableColumns = useMemo(() => Columns(), []);
	const [data, setData] = useState<ICertificatePreviewModel[]>([]);
	const [pageCount, setPageCount] = useState<number>(0);
	const { toasterStore } = useAppStore();

	const table = useTable<ICertificatePreviewModel>(
		{
			columns: tableColumns,
			data,
			initialState: { pageIndex: 0 },
			manualPagination: true,
			pageCount,
		},
		usePagination
	);

	const initParams: IQueryParams = {
		take: table.state.pageSize,
		skip: 0,
		filterCriteria: [],
		sortCriteria: [],
		queryString: "",
	};
	const [params, setParams] = React.useState<IQueryParams>(initParams);

	const fetchData = async (data: IQueryParams) =>
		otherUserId
			? await CertificatesApi.getCertificatesByUserId(otherUserId, data)
			: await CertificatesApi.getCertificates(data);

	const getResult = (
		result: AxiosResponse<IResponseModel<IGetRecordsResponse<ICertificatePreviewModel>>>
	): IGetRecordsResponse<ICertificatePreviewModel> => result.data.data;

	const { loading } = useAsyncDataTable<
		ICertificatePreviewModel,
		AxiosResponse<IResponseModel<IGetRecordsResponse<ICertificatePreviewModel>>>
	>(setData, params, setPageCount, toasterStore, fetchData, getResult);

	const getKey = (item: ICertificatePreviewModel) => item.certId;

	return (
		<div className={styles.my_certificates}>
			<div className={styles.my_certificates__header}>
				<div className={styles.my_certificates__title}>Certificates</div>
			</div>
			<div className={styles.my_certificates__body}>
				<LoadingIndicator loading={loading}>
					<CmsTable
						table={table}
						data={data}
						tileComponent={MyCertificatesTile}
						getKey={getKey}
						className={styles.my_certificates__table}
						disableRowHover
					/>
					<CmsFooter table={table} loading={false} setParams={setParams} />
				</LoadingIndicator>
			</div>
		</div>
	);
};

export default MyCertificates;
