import styles from "./styles.module.scss";
import { CellProps, Column } from "react-table";
import isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";
import { ReactComponent as PlayIcon } from "assets/icons/ic_player_play.svg";
import { INoteModel } from "models/dto/ZoomiLxp/Models/ContentModels/INoteModel";
import { generatePath, NavLink } from "react-router-dom";
import classNames from "classnames";
import { getContentTypeIcon } from "components/partial/cms/cms-content-table/data";
import { isNil } from "lodash";
import { Routes } from "routes";
import Tooltip from "components/base/tooltip/tooltip";
import { ContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentTypes";
import { ExpandCell } from "components/partial/cms/cms-table/expand-cell";
import React from "react";

interface CustomCellProps {
	noteItem: INoteModel;
}

const ControllCell = (props: CustomCellProps) => {
	const { noteItem } = props;
	const { contentType, courseId, courseItemId } = noteItem;

	return (
		<div className={styles.controll_cell}>
			<NavLink
				to={generatePath(Routes.MyDeskContent, {
					courseId: !isNil(courseId) ? courseId : 0,
					courseItemId: courseItemId,
				})}
				target="_blank"
			>
				<PlayIcon className={classNames(styles.play_icon)} />
			</NavLink>
			<Tooltip label={ContentTypes[contentType]} className={styles.content_type_tooltip}>
				<div className={styles.icon_wrap}>{getContentTypeIcon(contentType)}</div>
			</Tooltip>
		</div>
	);
};

export const columns: Column<INoteModel>[] = [
	{
		Header: "Notes",
		columns: [
			{
				Header: "",
				id: "0",
				accessor: (originalRow: INoteModel) => {
					return originalRow;
				},
				Cell(cell: React.PropsWithChildren<CellProps<INoteModel, INoteModel>>) {
					return <ControllCell noteItem={cell.row.original} />;
				},
			},
			{
				Header: "Name",
				id: "1",
				accessor: (row: INoteModel) => {
					return row.contentTitle;
				},
				Cell(cell: React.PropsWithChildren<CellProps<INoteModel, string>>) {
					return cell.value;
				},
			},
			{
				Header: "Last Updated",
				id: "2",
				accessor: (row) => row?.lastUpdated!,
				Cell(cell: React.PropsWithChildren<CellProps<INoteModel, string>>) {
					return !isEmpty(cell.value) ? (
						DateTime.fromISO(cell.value).setLocale("en").toLocaleString(DateTime.DATE_MED)
					) : (
						<></>
					);
				},
			},
			{
				Header: "Note",
				id: "3",
				accessor: (originalRow: INoteModel) => {
					return originalRow.note;
				},
				Cell(cell: React.PropsWithChildren<CellProps<INoteModel, string>>) {
					return <ExpandCell text={cell.value} />;
				},
			},
		],
	},
];
