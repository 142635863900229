import styles from "./styles.module.scss";
import React, { useState } from "react";
import classNames from "classnames/bind";
import Select, { SingleValue } from "react-select";
import { activeStateOptions, stylesSelect } from "helpers/select.helper";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import findIndex from "lodash/findIndex";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import update from "immutability-helper";
import { IFilterCriterion } from "models/dto/ZoomiLxp/Models/Query/IFilterCriterion";

interface StatusFilterProps {
	params: IQueryParams;
	setParams: (value: ((prevState: IQueryParams) => IQueryParams) | IQueryParams) => void;
	classNameSelect?: string;
	initParams?: IQueryParams;
	propertyName?: string;
}

const cx = classNames.bind(styles);

function StatusFilter(props: StatusFilterProps) {
	const { params, setParams, classNameSelect, propertyName = "isActive" } = props;

	const [selectedValue, setSelectedValue] = useState(null as any);

	const onSelect = (item: SingleValue<{ value: number; label: string }>) => {
		const idx = findIndex(params.filterCriteria, { propertyNames: [propertyName] });
		const val = item?.value ?? 0;
		setSelectedValue(item);
		if (val) {
			const functionType = val === 1 ? FilterFunction.IsTrue : FilterFunction.IsFalse;

			if (idx < 0) {
				const newParams = update<IQueryParams>(params, {
					filterCriteria: {
						$push: [
							{
								propertyNames: [propertyName],
								function: functionType,
							} as IFilterCriterion,
						],
					},
					skip: { $set: 0 },
				});
				setParams(newParams);
			} else {
				const filterItem = params.filterCriteria[idx];
				if (filterItem.function !== functionType) {
					const updatedFilterItem = update(filterItem, { function: { $set: functionType } });
					const newParams = update(params, {
						filterCriteria: {
							$splice: [[idx, 1, updatedFilterItem]],
						},
						skip: { $set: 0 },
					});
					setParams(newParams);
				}
			}
		} else if (!val) {
			if (params.filterCriteria.length > 0) {
				const newParams = update<IQueryParams>(params, {
					filterCriteria: {
						$splice: [[idx, 1]],
					},
					skip: { $set: 0 },
				});
				setParams(newParams);
			}
		}
	};

	return (
		<div className={styles.cms_filter}>
			<Select
				options={activeStateOptions}
				className={cx(styles.status_filter, classNameSelect)}
				placeholder="Status"
				styles={stylesSelect}
				isSearchable={false}
				onChange={onSelect}
				value={
					params.filterCriteria?.find((filter: IFilterCriterion) => filter.propertyNames.includes(propertyName))
						? { ...selectedValue, label: `Status: ${selectedValue?.label}` }
						: "Status"
				}
			/>
		</div>
	);
}

export default StatusFilter;
