import styles from "./styles.module.scss";
import { GroupBase, StylesConfig } from "react-select";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { uniqueId } from "lodash";
import { ReactComponent as ErrorIcon } from "assets/icons/ic_alert-circle.svg";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import { ICompanyModel } from "models/dto/ZoomiLxp/Models/CompanyModels/ICompanyModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { CompaniesApi } from "api/controllers/CompaniesApi";
import isEmpty from "lodash/isEmpty";
import { defaultDropdownStyles } from "components/base/dropdown-list/data";
import { IOptionsItem } from "helpers/select.helper";

interface Props {
	placeholder: string;
	className?: string;
	errorText?: string;
	customStyles?: StylesConfig;
	onChange?: (option: IOptionsItem | null) => void;
	value?: IOptionsItem | null;
	isDisabled?: boolean;
	isError?: boolean;
}

const DropdownListCompany = (props: Props) => {
	const { className, placeholder, isError, errorText, isDisabled, customStyles, value, onChange } = props;
	const [id] = useState(() => uniqueId("company-id-"));
	const [isSelected, setIsSelected] = useState(value ?? false);

	useEffect(() => {
		setIsSelected(!!value);
	}, [value]);

	let params: IQueryParams = {
		take: 100,
		skip: 0,
		filterCriteria: [],
		sortCriteria: [
			{
				order: 1,
				propertyName: "name",
				direction: 0,
			},
		],
		queryString: "",
	};

	const defaultAdditional = {
		skip: 0,
	};

	const getOptions = (records: ICompanyModel[]) => {
		const options: { value: string; label: string }[] = [];

		if (!isEmpty(records)) {
			records.forEach((item) => {
				options.push({
					value: String(item.id),
					label: item.name,
				});
			});
		}
		return options;
	};

	const loadOptions: LoadOptions<any, GroupBase<any>, any> = async (search, prevOptions, { skip }) => {
		params = { ...params, skip, queryString: search };

		const response = await CompaniesApi.getAllCompanies(params);
		const records = response.data.data.records;
		const options = getOptions(records);

		const hasMore = response.data.data.totalFilteredRecords > prevOptions.length + Number(params.take);

		return {
			options,
			hasMore,
			additional: {
				skip: skip + params.take,
			},
		};
	};

	return (
		<div className={classNames(styles.select_company, { [styles.disabled]: isDisabled }, className)}>
			<AsyncPaginate
				additional={defaultAdditional}
				debounceTimeout={300}
				id={id}
				value={value}
				loadOptions={loadOptions}
				className={styles.select_company__select}
				placeholder={!isSelected ? placeholder : ""}
				styles={customStyles ? { ...customStyles, ...defaultDropdownStyles } : defaultDropdownStyles}
				isDisabled={isDisabled}
				onChange={(option: any) => {
					onChange!(option);
					setIsSelected(!!value);
				}}
			/>
			{placeholder && isSelected && (
				<label htmlFor={id} className={styles.select_company__label}>
					{placeholder}
				</label>
			)}
			{isError && (
				<div className={styles.select_company__error}>
					<div className={styles.select_company__error_box}>
						<ErrorIcon className={styles.select_company__error_icon} />
					</div>
					<div className={styles.select_company__error_text}>{errorText}</div>
				</div>
			)}
		</div>
	);
};

export default DropdownListCompany;
