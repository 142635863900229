import Api from "api/utils/api";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";

export class HistoryApi {
	//Current user methods.
	static getFollowingInfluencers(data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IInfluencerDataModel>>("/History/GetFollowingInfluencers", data);
	}

	static getWatchingHistory(data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IPreviewCourseModel>>("/History/GetWatchingHistory", data);
	}

	static getBookmarkedCourses(data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IPreviewCourseModel>>("/History/GetBookmarkedCourses", data);
	}

	static getContinueWatching(data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IPreviewCourseModel>>("/History/GetContinueWatching", data);
	}

	static getCoursesCreatedByYou(data: IPagingParams) {

		return Api.get<IGetRecordsResponse<IPreviewCourseModel>>("/History/CoursesCreatedByYou", {
			params: data,
		});
	}

	//Methods by UserID.
	static getUserFollowingInfluencers(userId: number, data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IInfluencerDataModel>>(
			`/History/GetUserFollowingInfluencers/${userId}`,
			data
		);
	}

	static getUserWatchingHistory(userId: number, data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IPreviewCourseModel>>(
			`/History/GetUserWatchingHistory/${userId}`,
			data
		);
	}

	static getUserBookmarkedCourses(userId: number, data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IPreviewCourseModel>>(
			`/History/GetUserBookmarkedCourses/${userId}`,
			data
		);
	}

	static getUserContinueWatching(userId: number, data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IPreviewCourseModel>>(
			`/History/GetUserContinueWatching/${userId}`,
			data
		);
	}

	static getCoursesCreatedByUser(userId: number, data: IPagingParams) {
		return Api.postUnwrapped<IGetRecordsResponse<IPreviewCourseModel>>(
			`/History/GetUserCoursesCreatedByYou/${userId}`,
			data
		);
	}
}
