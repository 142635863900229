import styles from "./styles.module.scss";
import { IMotifSessionItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifSessionItem";
import { IMotifDetailsItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifDetailsItem";
import classNames from "classnames";
import { uniqueId } from "lodash";
import { DateTime } from "luxon";
import Tooltip from "components/base/tooltip/tooltip";

interface IMotifsSessionItemProps {
	sessionItem: IMotifSessionItem;
}

const MotifSessionItem = ({ sessionItem }: IMotifsSessionItemProps) => {
	const { title, startDate, endDate, motifs, sessionId } = sessionItem;
	const idKey = uniqueId(`${title} - ${sessionId} - `);

	const sessionStart = DateTime.fromISO(startDate).setLocale("en").toLocaleString(DateTime.TIME_24_WITH_SECONDS);
	const sessionEnd = DateTime.fromISO(endDate).setLocale("en").toLocaleString(DateTime.TIME_24_WITH_SECONDS);

	const TooltipContent = () => {
		return (
			<div className={styles.tooltip__content}>
				<span className={styles.tooltip__title}>{title}</span>
				<span className={styles.tooltip__body}>
					Session Start: {sessionStart} | Session End: {sessionEnd}
				</span>
			</div>
		);
	};

	return (
		<div className={styles.motif_session_item}>
			<section className={styles.motif_session_item__title_container}>
				<Tooltip label={<TooltipContent />} className={styles.tooltip}>
					<span
						className={classNames(styles.motif_session_item__title, {
							[styles.motif_session_item__short]: motifs.length <= 1,
						})}
					>
						{title}
					</span>
				</Tooltip>
			</section>

			<section className={styles.motif_session_item__details_container} key={idKey}>
				{motifs.length ? (
					motifs.map((motifDetailsItem: IMotifDetailsItem) => {
						return (
							<div className={styles.motif_session_item__details_item} key={uniqueId(`${motifDetailsItem.id} - `)}>
								<div
									className={classNames(styles.motif_session_item__details_chip, {
										[styles.motif_session_item__details_chip__negative]: !motifDetailsItem.isPositive,
									})}
								>
									{motifDetailsItem.name}
								</div>
								<span className={styles.motif_session_item__details_time}>
									{DateTime.fromISO(motifDetailsItem.startDate)
										.setLocale("en")
										.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
								</span>
							</div>
						);
					})
				) : (
					<span className={styles.motif_session_item__title__dark}>No motifs</span>
				)}
			</section>
		</div>
	);
};

export default MotifSessionItem;
