import { CellProps, Column } from "react-table";
import React from "react";
import { IMotifStatisticsItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifStatisticsItem";
import { IMotifSessionItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifSessionItem";
import MotifSessionItem from "./motif-session-item/motif-session-item";
import styles from "./styles.module.scss";
import { uniqueId } from "lodash";
import ScrollContainer from "react-indiana-drag-scroll";
export interface IMotifsTableRowData extends IMotifStatisticsItem {
	sessions: IMotifSessionItem[];
}

export const columns: Column<IMotifsTableRowData>[] = [
	{
		Header: "Notes",
		columns: [
			{
				Header: "Course Name",
				id: "0",
				accessor: (row: IMotifsTableRowData) => {
					return row.title;
				},
				Cell(cell: React.PropsWithChildren<CellProps<IMotifsTableRowData, string>>) {
					return cell.value;
				},
			},
			{
				Header: "Z1 Score",
				id: "1",
				accessor: (row: IMotifsTableRowData) => {
					return row.z1Rating;
				},
				Cell(cell: React.PropsWithChildren<CellProps<IMotifsTableRowData, number>>) {
					return <span>{Math.round(cell.value)}%</span>;
				},
			},
			{
				Header: "Engagement",
				id: "2",
				accessor: (row: IMotifsTableRowData) => row.engagement,
				Cell(cell: React.PropsWithChildren<CellProps<IMotifsTableRowData, number>>) {
					return <span>{Math.round(cell.value)}%</span>;
				},
			},
			{
				Header: "Motif %",
				id: "3",
				accessor: (originalRow: IMotifsTableRowData) => {
					return originalRow.motiffPercent;
				},
				Cell(cell: React.PropsWithChildren<CellProps<IMotifsTableRowData, number>>) {
					return <span>{Math.round(cell.value)}%</span>;
				},
			},
			{
				Header: "Motifs",
				id: "4",
				accessor: (originalRow: IMotifsTableRowData) => {
					return originalRow.sessions;
				},
				Cell(cell: React.PropsWithChildren<CellProps<IMotifSessionItem[], IMotifSessionItem[]>>) {
					return (
						<ScrollContainer
							className={styles.motifs_statistics__sessions_cell}
							vertical={false}
							hideScrollbars={false}
						>
							{cell.value?.map((sessionItem) => (
								<MotifSessionItem sessionItem={sessionItem} key={uniqueId(`${sessionItem.sessionId}`)} />
							))}
						</ScrollContainer>
					);
				},
			},
		],
	},
];
