import styles from "./styles.module.scss";
import classNames from "classnames";
import { observer } from "mobx-react";
import { BanerTypes, getBannerType } from "helpers/settings.helper";
import { useAppStore } from "store";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import isEmpty from "lodash/isEmpty";

interface Props {
	bannerSrc: string;
	bannerContentType: string;
	className?: string;
	onClick?: () => void;
}

const Banner = (props: Props) => {
	const { settingsStore } = useAppStore();
	const { className, bannerSrc, bannerContentType, onClick } = props;

	const bannerType = getBannerType(
		bannerContentType ?? settingsStore.publicSettings.Images?.[SiteSettingsKeys.HomeBannerContentType]
	);

	const getBanner = () => {
		return bannerType === BanerTypes.Video ? (
			<video autoPlay loop muted playsInline className={classNames(styles.banner, className)} onClick={onClick}>
				<source src={bannerSrc} />
			</video>
		) : (
			<img className={classNames(styles.banner, styles.banner__image, className)} src={bannerSrc} alt="Banner" />
		);
	};

	return isEmpty(bannerSrc) ? <></> : getBanner();
};

export default observer(Banner);
