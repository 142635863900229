import styles from "./styles.module.scss";
import classNames from "classnames";
import { useFooterData } from "hooks/useGetTermsOfUse";
import { Link } from "react-router-dom";
import { GDPR_URL, PRIVACY_POLICY_URL, ZOOMI_URL } from "constants/constants";

interface Props {
	className?: string;
	isPublic?: boolean;
}

const Footer = ({ className, isPublic }: Props) => {
	const { termsOfUseEnabled, termsOfUseUrl, companyName, supportEmail, apiVersion } = useFooterData();

	return (
		<footer className={classNames(styles.footer, { [styles.footer_public]: isPublic }, className)}>
			<section className={classNames(styles.footer__section, styles.footer__left_section)}>
				<div className={classNames(styles.footer__top_container, styles.footer__text)}>{companyName}</div>
				<div className={styles.footer__bottom_container}>
					<Link className={styles.footer__link} to={{ pathname: `mailto:${supportEmail}` }} target="_blank">
						{"Help and Support"}
					</Link>
					{termsOfUseEnabled && (
						<Link className={styles.footer__link} to={termsOfUseUrl} target="_blank">
							Terms Of Service
						</Link>
					)}
				</div>
			</section>
			<section className={classNames(styles.footer__section, styles.footer__right_section)}>
				<div className={styles.footer__top_container}>
					<span className={styles.footer__text}>Powered by </span>
					<Link className={styles.footer__link} to={{ pathname: ZOOMI_URL }} target="_blank">
						zoomi.ai
					</Link>
				</div>
				<div className={styles.footer__bottom_container}>
					<Link className={styles.footer__link} to={{ pathname: PRIVACY_POLICY_URL }} target="_blank">
						Privacy Policy
					</Link>
					<Link className={styles.footer__link} to={{ pathname: GDPR_URL }} target="_blank">
						GDPR Notice
					</Link>
					<p>
						<span
							className={styles.footer__versions}
						>{`BE v${apiVersion} / FE v${process.env.REACT_APP_VERSION}`}</span>
					</p>
				</div>
			</section>
		</footer>
	);
};

export default Footer;
