import { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { Columns } from "./data";
import { ColumnGroup } from "react-table";
import { Routes } from "routes";
import CmsTileThread from "./cms-tile-thread/cms-tile-thread";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import { useAppStore } from "store";
import { CmsThreadsFilter } from "./cms-threads-filter";
import CmsSpreadsheet from "../cms-spreadsheet/cms-spreadsheet";
import { observer } from "mobx-react";
import { useBulkSelections } from "../cms-courses/useBulkSelections";
import { cloneDeep } from "lodash";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";

const CmsThreads = ({ isHideFilter }: { isHideFilter?: boolean }) => {
	const columns = useMemo(() => Columns(), []);
	const [tableColumns, setTableColumns] = useState<ColumnGroup<IThreadModel>[]>(columns);
	const { peersStore, bulkOperationsStore } = useAppStore();

	useEffect(() => {
		if (!bulkOperationsStore.isShowSelection) {
			const updatedColumns: ColumnGroup<IThreadModel>[] = cloneDeep(columns);
			updatedColumns[0].columns.splice(0, 1);
			setTableColumns(updatedColumns);
		} else {
			setTableColumns(columns);
		}
	}, [bulkOperationsStore.isShowSelection, columns]);

	useBulkSelections([PermissionConstantsRead.ThreadsFromCms], bulkOperationsStore.getThreadInitParams());

	return (
		<div className={styles.cms_threads}>
			{!isHideFilter && <CmsThreadsFilter />}
			<CmsSpreadsheet
				tableColumns={tableColumns}
				tileComponent={CmsTileThread}
				fetchData={() => peersStore.searchCMSThreads()}
				profileRoute={Routes.CmsThreadProfile}
				className={styles.cms_threads__body}
			/>
		</div>
	);
};

export default observer(CmsThreads);
