//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ValidationBehavior {
	None = 0,
	LogWarning = 1,
	LogError = 2,
	Enforce = 3
}
