import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import Select, { SingleValue } from "react-select";
import { IOptionsItem, optionsPageSize, stylesTableSelect } from "helpers/select.helper";
import { ReactComponent as ArrowLeft } from "assets/icons/ic_arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/ic_arrow-right.svg";
import { TableInstance } from "react-table";
import find from "lodash/find";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { isNil } from "lodash";

export interface IFetchFunc {
	(obj: { pageIndex: number; pageSize: number }): void;
}

interface CmsFooterProps<D extends object> {
	table: TableInstance<D>;
	loading: boolean;
	setParams?: Dispatch<SetStateAction<IQueryParams>>;
	hideControls?: boolean;
	itemsCount?: number;
	courseId?: number;
}

function CmsFooter<D extends object>({
	table,
	loading,
	setParams,
	hideControls,
	itemsCount,
	courseId,
}: CmsFooterProps<D>) {
	const {
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex },
	} = table;

	const listPages = useMemo(
		() =>
			Array.from(new Array(pageCount), (_, i) => ({
				value: i + 1,
				label: String(i + 1),
			})),
		[pageCount]
	);

	const [currentPageSize, setCurrentPageSize] = useState<SingleValue<IOptionsItem>>(optionsPageSize[0]);
	const [currentPage, setCurrentPage] = useState<SingleValue<IOptionsItem>>();

	useEffect(() => {
		const curPage = find(listPages, { value: pageIndex + 1 });

		if (isNil(curPage) && !hideControls) {
			gotoPage(0);
		} else {
			setCurrentPage(curPage as SingleValue<IOptionsItem>);
		}
	}, [listPages, pageIndex, gotoPage, hideControls]);

	useEffect(() => {
		if (setParams) {
			setParams((prevState: IQueryParams) => ({
				...prevState,
				skip: prevState?.take! * pageIndex,
			}));
		}
	}, [pageIndex, setParams]);

	const onChangePageSize = (item: SingleValue<IOptionsItem>) => {
		setPageSize(Number(item?.value ?? 10));
		setCurrentPageSize(item);
		if (setParams) {
			setParams((prevState: IQueryParams) => ({
				...prevState,
				take: Number(item?.value ?? 10),
			}));
		}
	};

	useEffect(() => {
		onChangePageSize({ value: 10, label: "10" });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courseId]);

	if (isEmpty(table.data)) {
		return loading ? (
			<></>
		) : (
			<div className={styles.cms_footer__wrap}>
				<div className={styles.cms_footer__text_alert}>No data found</div>
			</div>
		);
	}

	return (
		<div className={styles.cms_footer}>
			{hideControls && itemsCount && (
				<div className={styles.cms_footer__left_group}>
					<div className={`${styles.cms_footer__text} ${styles.cms_footer__text_indent}`}>Rows count: {itemsCount}</div>
				</div>
			)}

			{!hideControls && (
				<>
					<div className={styles.cms_footer__left_group}>
						<div className={`${styles.cms_footer__text} ${styles.cms_footer__text_indent}`}>Item per page:</div>
						<Select
							isMulti={false}
							options={optionsPageSize}
							className={styles.cms_footer__select_items}
							value={currentPageSize}
							styles={stylesTableSelect}
							isSearchable={false}
							onChange={onChangePageSize}
						/>
					</div>
					<div className={styles.cms_footer__right_group}>
						<Select
							isMulti={false}
							options={listPages}
							className={styles.cms_footer__select_pages}
							value={currentPage}
							styles={stylesTableSelect}
							isSearchable={false}
							onChange={(item) => gotoPage(Number(item?.value ?? 1) - 1)}
						/>
						<div className={`${styles.cms_footer__text} ${styles.cms_footer__text_indent_two}`}>
							of {pageCount} pages
						</div>
						<div
							className={classNames(styles.cms_footer__icon_wrap, {
								[styles.cms_footer__icon_disable]: !canPreviousPage,
							})}
							onClick={() => previousPage()}
						>
							<ArrowLeft className={styles.cms_footer__icon} />
						</div>
						<div
							className={classNames(styles.cms_footer__icon_wrap, { [styles.cms_footer__icon_disable]: !canNextPage })}
							onClick={() => nextPage()}
						>
							<ArrowRight className={styles.cms_footer__icon} />
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default CmsFooter;
