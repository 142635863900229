import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";

import { generatePath } from "react-router-dom";
import { Routes } from "routes";
import { useAppStore } from "store";

export const useFooterData = () => {
	const { settingsStore } = useAppStore();
	const termsOfUseUrl = settingsStore.publicSettings.Wiki?.[SiteSettingsKeys.WikiTermsOfUse]
		? generatePath(Routes.WikiPage, {
				fileId: settingsStore.publicSettings.Wiki?.[SiteSettingsKeys.WikiTermsOfUse],
		  })
		: Routes.WikiPage;
	const companyName = settingsStore.publicSettings.Strings?.[SiteSettingsKeys.StringsCompanyName];
	const supportEmail = settingsStore.publicSettings.General?.[SiteSettingsKeys.SupportEmail];
	const apiVersion = settingsStore.publicSettings.Strings?.[SiteSettingsKeys.StringsCurrentApiVersion];
	const termsOfUseEnabled = settingsStore.publicSettings.Wiki?.[SiteSettingsKeys.WikiTermsOfUseEnabled] === "true";
	return { termsOfUseEnabled, termsOfUseUrl, companyName, supportEmail, apiVersion };
};
