import styles from "./styles.module.scss";
import React, { ReactNode } from "react";
import classNames from "classnames";
import CommonHeader from "components/partial/common-header/common-header";
import PrivateLayout from "../private/private";
import SubMenu from "components/partial/submenu/submenu";
import { reportsMenuItems } from "components/partial/submenu/items";
import { matchPath, useHistory } from "react-router-dom";
import { Routes } from "routes";

interface Props {
	children: ReactNode;
	className?: string;
	label?: string;
}

const CmsLayout: React.FC<Props> = ({ children, className, label = "CMS" }: Props) => {
	const history = useHistory();
	const isReportsPage =
		!!matchPath(history.location.pathname, Routes.Reports) || !!matchPath(history.location.pathname, Routes.AllReports);
	return (
		<PrivateLayout>
			<div className={classNames(styles.cms_layout, className)}>
				<CommonHeader
					label={label}
					className={styles.cms_layout__header}
					classNameContent={styles.cms_layout__header_content}
				>
					{isReportsPage ? <SubMenu menuItems={reportsMenuItems} className={styles.sales_submenu} /> : <SubMenu />}
				</CommonHeader>
				<div className={styles.cms_layout__body}>{children}</div>
			</div>
		</PrivateLayout>
	);
};

export default CmsLayout;
