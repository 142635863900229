import styles from "./styles.module.scss";
import classNames from "classnames";
import { useRef, useState } from "react";
import ReactCrop, { Crop, PixelCrop, centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import { canvasPreview } from "./canvas-preview";
import { getFileFromDataUrl } from "helpers/image.helper";
import { FileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/FileTypes";
import { IFileModel } from "models/dto/ZoomiLxp/Models/Common/IFileModel";
import { ICroppedFileOptions, getAspect } from "./image-cropper.helper";
import { FileApi } from "api/controllers/FileApi";
import Button from "components/base/button/button";
import { mimeTypes } from "constants/constants";

interface IImageCropperProps {
	imageFile: IFileModel;
	fileOptions: ICroppedFileOptions;
	onCrop: (croppedFile: IFileModel) => void;
	onLoading?: (isLoading: boolean) => void;
	onConfirm?: () => void;
	className?: string;
	isCircularCrop?: boolean;
	aspect?: number;
}

const ImageCropper = (props: IImageCropperProps) => {
	const { imageFile, onCrop, className, fileOptions, onLoading, onConfirm, isCircularCrop = false, aspect } = props;
	const imgRef = useRef<HTMLImageElement>(null);
	const cropAspect = aspect ?? getAspect(isCircularCrop, fileOptions);
	const [crop, setCrop] = useState<Crop>();
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

	const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect?: number) => {
		return centerCrop(
			makeAspectCrop(
				{
					unit: "%",
					width: 50,
				},
				aspect ?? 4 / 3,
				mediaWidth,
				mediaHeight
			),
			mediaWidth,
			mediaHeight
		);
	};

	const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
		const { width, height } = e.currentTarget;
		setCrop(centerAspectCrop(width, height, cropAspect));
	};

	const getPictureQuality = () => {
		if (fileOptions.imageType === FileTypes.Thumbnail) {
			return 0.5;
		}
		return 0.8;
	};

	const uploadCroppedFile = async () => {
		onLoading?.(true);

		if (completedCrop?.width && completedCrop?.height && imgRef.current) {
			const isPng = imageFile.contentType === mimeTypes.png;
			const dataUrl = await canvasPreview(imgRef.current, completedCrop, getPictureQuality(), isPng);
			const croppedFile = await getFileFromDataUrl(dataUrl, imageFile.contentType);

			if (croppedFile) {
				const data = {
					fileName: croppedFile.name,
					contentType: croppedFile.type,
					fileType: fileOptions.uploadFileType,
					formatFileType: fileOptions.imageType,
					parentFileId: imageFile.id,
				};
				const uploadInfo = await FileApi.createUploadUrl(data, croppedFile);
				const urlInfo = await FileApi.getDownloadUrl(uploadInfo.id);
				const fileModel: IFileModel = { id: uploadInfo.id, url: urlInfo.data.data.url };
				onCrop(fileModel);
				onLoading?.(false);
			}
		}
	};

	return (
		<div className={styles.image_cropper}>
			<ReactCrop
				aspect={cropAspect}
				crop={crop}
				onChange={(_, percentCrop) => {
					setCrop(percentCrop);
				}}
				onComplete={(newCompletedCrop) => {
					setCompletedCrop(newCompletedCrop);
				}}
				circularCrop={isCircularCrop}
			>
				<div className={classNames(className, styles.image_cropper__image_container)}>
					<img
						ref={imgRef}
						className={styles.image_cropper__image}
						src={imageFile.url}
						alt="Cropped"
						crossOrigin="anonymous"
						onLoad={(loadEvent) => onImageLoad(loadEvent)}
					/>
				</div>
			</ReactCrop>
			<div className={styles.image_cropper__button_container}>
				<Button
					label="Ok"
					onClick={() => {
						uploadCroppedFile();
						onConfirm?.();
					}}
				/>
			</div>
		</div>
	);
};

export default ImageCropper;
