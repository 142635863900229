import styles from "./styles.module.scss";
import PrivateLayout from "layouts/private/private";
import CommonHeader from "components/partial/common-header/common-header";
import classNames from "classnames/bind";
import { ReactNode, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "assets/icons/ic_arrow-left-2.svg";
import SubMenu from "components/partial/submenu/submenu";
import { getMyAccountItems } from "components/partial/submenu/items";
import { mobileCheck } from "helpers/device.helper";
import { Routes } from "routes";
import { useAppStore } from "store";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";

interface Props {
	children: ReactNode;
	className?: string;
}

const cx = classNames.bind(styles);

const MyAccountLayout: React.FC<Props> = (props: Props) => {
	const { children, className } = props;
	const { settingsStore, usersStore } = useAppStore();
	const history = useHistory();
	const isMobile = useMemo(() => mobileCheck(), []);
	const isMyDataLayout = history.location.pathname.includes(Routes.MyData);

	return (
		<PrivateLayout>
			<div className={cx(styles.myDesk_layout, className)}>
				<CommonHeader
					label={isMyDataLayout ? "My Data" : "My Account"}
					className={styles.myDesk_layout__header}
					classNameContent={styles.myDesk_layout__header_content}
				>
					<div className={styles.myDesk_layout__submenu}>
						{!isMobile && <ArrowLeft onClick={() => history.goBack()} className={styles.myDesk_layout__back_button} />}
						<SubMenu
							menuItems={getMyAccountItems(
								settingsStore.publicSettings.Pages?.[SiteSettingsKeys.MyDataTabQuizResultsTabVisible],
								usersStore.checkOneOfUserPermissions([PermissionConstantsRead.AllCourseWithAllGroups])
							)}
						/>
					</div>
				</CommonHeader>
				<div className={styles.myDesk_layout__body}>{children}</div>
			</div>
		</PrivateLayout>
	);
};

export default MyAccountLayout;
