import Api, { ApiBase } from "api/utils/api";
import { AxiosResponse } from "axios";
import { IB2BPurchaseModel } from "models/dto/ZoomiLxp/Models/Billing/IB2BPurchaseModel";
import { IB2BStatusesModel } from "models/dto/ZoomiLxp/Models/Billing/IB2BStatusesModel";
import { IB2CPurchaseModel } from "models/dto/ZoomiLxp/Models/Billing/IB2CPurchaseModel";
import { IB2CStatusesModel } from "models/dto/ZoomiLxp/Models/Billing/IB2CStatusesModel";
import { IUpdateB2BPurchaseModel } from "models/dto/ZoomiLxp/Models/Billing/IUpdateB2BPurchaseModel";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import { ICompanyModel } from "models/dto/ZoomiLxp/Models/CompanyModels/ICompanyModel";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";

export class BillingApi {
	static createCheckoutSession(courseId: number) {
		return Api.post("/billing/createCheckoutSession", { courseId });
	}

	static getB2CStatuses() {
		return Api.get<IB2CStatusesModel>("/billing/B2CPurchaseStatuses");
	}

	static getB2BStatuses() {
		return Api.get<IB2BStatusesModel>("/billing/B2BPurchaseStatuses");
	}

	static getB2BCompanies() {
		return Api.get<ICompanyModel[]>("/billing/B2BPurchaseCompanies");
	}

	static getB2BAssigners() {
		return Api.get<Pick<IProfileDataModel, "id" | "displayName">[]>("/billing/B2BPurchaseAssigners");
	}

	static updateB2BStatus(purchaseId: number, data: IUpdateB2BPurchaseModel) {
		return Api.put<IB2BPurchaseModel>(`/billing/B2BPurchase/${purchaseId}`, data);
	}

	static getB2CPurchases(data: IQueryParams) {
		return ApiBase.post<IQueryParams, AxiosResponse<IResponseModel<IGetRecordsResponse<IB2CPurchaseModel>>>>(
			"/billing/b2cPurchases",
			data
		);
	}

	static getB2BPurchases(data: IQueryParams) {
		return ApiBase.post<IQueryParams, AxiosResponse<IResponseModel<IGetRecordsResponse<IB2BPurchaseModel>>>>(
			"/billing/b2bPurchases",
			data
		);
	}

	static getB2BSales(data: IQueryParams) {
		return ApiBase.post<IQueryParams, AxiosResponse<IResponseModel<IGetRecordsResponse<IB2BPurchaseModel>>>>(
			"/billing/b2bSales",
			data
		);
	}

	static getB2CSales(data: IQueryParams) {
		return ApiBase.post<IQueryParams, AxiosResponse<IResponseModel<IGetRecordsResponse<IB2CPurchaseModel>>>>(
			"/billing/b2cSales",
			data
		);
	}

	static exportB2CPurchases(data: IQueryParams) {
		return ApiBase.post("/billing/exportB2CPurchases", data, {
			responseType: "blob",
		});
	}

	static exportB2BPurchases(data: IQueryParams) {
		return ApiBase.post("/billing/exportB2BPurchases", data, {
			responseType: "blob",
		});
	}

	static exportB2CSales(data: IQueryParams) {
		return ApiBase.post("/billing/exportB2CSales", data, {
			responseType: "blob",
		});
	}

	static exportB2BSales(data: IQueryParams) {
		return ApiBase.post("/billing/exportB2BSales", data, {
			responseType: "blob",
		});
	}

	static getCourseIdByPaymentSession(sessionId: string) {
		return Api.get(`/billing/courseBySession/${sessionId}`);
	}

	static getUserB2CPurchases(userId: number, data: IQueryParams) {
		return ApiBase.post<IQueryParams, AxiosResponse<IResponseModel<IGetRecordsResponse<IB2CPurchaseModel>>>>(
			`/billing/UserB2CPurchases/${userId}`,
			data
		);
	}
}
