import { filter, isEmpty } from "lodash";
import { ICourseItemModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseItemModel";
import { ContentItemTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentItemTypes";

export const takeActive = (sortedItems: ICourseItemModel[]): ICourseItemModel[] => {
	const filteredItems = filter(sortedItems, (item) =>
		item.contentItemType === ContentItemTypes.Content || item.contentItemType === ContentItemTypes.ContentSegment
			? item.isActive && item.masterContentIsActive && item.masterContentIsPublic
			: item.isActive
	);
	return isEmpty(filteredItems) ? [] : filteredItems;
};

export const initialLastWatchedItem = {
	id: 0,
	posX: 0,
	posY: 0,
	position: 0,
};

export const initialAssignments = { records: [], totalFilteredRecords: 0 };
