import classNames from "classnames";
import styles from "./styles.module.scss";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import { isEmpty } from "lodash";
import { getHTML } from "helpers/string.helper";
import { ReactComponent as LikeIcon } from "assets/icons/ic_heart.svg";
import Button from "components/base/button/button";
import { useAppStore } from "store";
import { useEffect, useState } from "react";
import { runInAction } from "mobx";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { TilePlacement } from "components/partial/peers/peer-tile/peer-tile";
import { observer } from "mobx-react";
import { NavLink, generatePath } from "react-router-dom";
import { Routes } from "routes";

type PropsType = {
	influencerInfo: IInfluencerDataModel;
	tilePlacement: TilePlacement;
	isLight: boolean;
};

const InfluencerTileInfo = ({ influencerInfo, tilePlacement, isLight }: PropsType) => {
	const { usersStore, influencersStore } = useAppStore();
	const [isFollowed, setIsFollowed] = useState<boolean>(influencerInfo.isFollowed);
	const [isLiked, setIsLiked] = useState<boolean>(() => influencerInfo.isLiked);
	useEffect(() => {
		if (influencerInfo) {
			setIsLiked(influencerInfo.isLiked);
			setIsFollowed(influencerInfo.isFollowed);
		}
	}, [influencerInfo]);

	const accountId = usersStore.currentUserInfo?.id ?? 0;
	const description = [influencerInfo?.jobTitle ?? "", influencerInfo?.company && `of ${influencerInfo?.company ?? ""}`]
		.join(" ")
		.trim();

	const handleInfluencerFollow = async () => {
		await influencersStore.sendInfluencerEvent({
			likedAccountId: influencerInfo.id,
			eventType: !isFollowed ? EventTypes.InfluencerFollowed : EventTypes.InfluencerUnfollowed,
			payload: "",
		});
	};

	const handleInfluencerLike = async () => {
		runInAction(() => {
			!isLiked ? influencerInfo.likesCount++ : influencerInfo.likesCount--;
		});

		await influencersStore.sendInfluencerEvent({
			likedAccountId: influencerInfo.id,
			eventType: !isLiked ? EventTypes.InfluencerLiked : EventTypes.InfluencerUnliked,
			payload: "",
		});
	};
	if (tilePlacement === TilePlacement.horizontal) {
		return (
			<>
				<div
					className={classNames(
						styles.influencer_tile__color_bar,
						styles[`influencer_tile__color_bar__${tilePlacement}`],
						styles.influencer_tile__color_bar__red
					)}
				/>
				<div
					className={classNames(
						styles.influencer_tile__info_container,
						styles[`influencer_tile__info_container__${tilePlacement}`]
					)}
				>
					<div
						className={classNames(styles.influencer_tile__header, styles[`influencer_tile__header__${tilePlacement}`])}
					>
						{!isEmpty(influencerInfo.displayName) && (
							<NavLink
								className={classNames(
									styles.influencer_tile__title,
									styles.influencer_tile__title__author,
									styles.influencer_tile__clickable,
									{
										[styles.influencer_tile__title__dark]: isLight,
									}
								)}
								to={generatePath(Routes.InfluencerPofile, { id: influencerInfo.id })}
							>
								{influencerInfo.displayName}
							</NavLink>
						)}
					</div>
					{!isEmpty(description) && (
						<div
							className={classNames(styles.influencer_tile__title, styles.influencer_tile__title__description, {
								[styles.influencer_tile__title__dark]: isLight,
							})}
						>
							{description}
						</div>
					)}
				</div>
				<div
					className={classNames(
						styles.influencer_tile__description,
						styles[`influencer_tile__description__${tilePlacement}`]
					)}
					dangerouslySetInnerHTML={{ __html: getHTML(influencerInfo.description) }}
				/>
				<div
					className={classNames(
						styles.influencer_tile__stats_section,
						styles[`influencer_tile__stats_section__${tilePlacement}`]
					)}
				>
					<div className={styles.influencer_tile__likes}>
						<LikeIcon
							className={classNames(styles.influencer_tile__like_icon, {
								[styles.influencer_tile__like_icon__disliked]: !isLiked,
								[styles.influencer_tile__like_icon__light]: isLight,
							})}
							onClick={() => {
								if (accountId !== influencerInfo.id) {
									setIsLiked(!isLiked);
									handleInfluencerLike();
								}
							}}
						/>
						{influencerInfo.likesCount > 0 ? influencerInfo.likesCount : 0}
					</div>
					<Button
						disabled={accountId === influencerInfo.id}
						label={isFollowed ? "Unfollow" : "Follow"}
						onClick={() => {
							setIsFollowed(!isFollowed);
							handleInfluencerFollow();
						}}
						type={isFollowed ? "secondary" : "primary"}
						className={classNames(styles.influencer_tile__follow_button)}
					/>
				</div>
			</>
		);
	}
	return (
		<div
			className={classNames(
				styles.influencer_tile__info_container,
				styles[`influencer_tile__info_container__${tilePlacement}`]
			)}
		>
			{!isEmpty(description) && (
				<div
					className={classNames(styles.influencer_tile__title, {
						[styles.influencer_tile__title__dark]: isLight,
					})}
				>
					{description}
				</div>
			)}
			<div className={classNames(styles.influencer_tile__color_bar, styles.influencer_tile__color_bar__red)} />
			<div
				className={classNames(
					styles.influencer_tile__description_section,
					styles[`influencer_tile__description_section__${tilePlacement}`]
				)}
			>
				<div
					className={classNames(
						styles.influencer_tile__description,
						styles[`influencer_tile__description__${tilePlacement}`]
					)}
					dangerouslySetInnerHTML={{ __html: getHTML(influencerInfo.description) }}
				/>
				<div className={styles.influencer_tile__stats_section}>
					<div className={styles.influencer_tile__likes}>
						<LikeIcon
							className={classNames(styles.influencer_tile__like_icon, {
								[styles.influencer_tile__like_icon__disliked]: !isLiked,
								[styles.influencer_tile__like_icon__light]: isLight,
							})}
							onClick={() => {
								if (accountId !== influencerInfo.id) {
									setIsLiked(!isLiked);
									handleInfluencerLike();
								}
							}}
						/>
						{influencerInfo.likesCount > 0 ? influencerInfo.likesCount : 0}
					</div>
					<Button
						disabled={accountId === influencerInfo.id}
						label={isFollowed ? "Unfollow" : "Follow"}
						onClick={() => {
							setIsFollowed(!isFollowed);
							handleInfluencerFollow();
						}}
						type={isFollowed ? "secondary" : "primary"}
						className={classNames(styles.influencer_tile__follow_button)}
					/>
				</div>
			</div>
		</div>
	);
};

export default observer(InfluencerTileInfo);
