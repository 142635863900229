import { getCourseViewsCount } from "helpers/content.helper";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { ReactComponent as EyeOnIcon } from "assets/icons/ic_eye-on.svg";
import { ICourseModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseModel";
import styles from "./styles.module.scss";

interface IViewsCounterProps {
	courseInfo: IPreviewCourseModel | ICourseModel;
	className?: string;
	iconClassName?: string;
}

const ViewsCounter = ({ courseInfo, className, iconClassName }: IViewsCounterProps) => {
	const viewsCount = getCourseViewsCount(courseInfo);

	return (
		<div className={className ?? styles.views_counter__container}>
			<EyeOnIcon className={iconClassName ?? styles.views_counter__icon} />
			{viewsCount}
		</div>
	);
};

export default ViewsCounter;
