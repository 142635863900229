import styles from "./styles.module.scss";
import classNames from "classnames";
import { IChapterRef } from "models/dto/ZoomiLxp/Models/Courses/IChapterRef";
import { ReactComponent as VideoIcon } from "assets/icons/ic_play_circle.svg";
import { getThumbnail } from "helpers/image.helper";
import { useCourseOutdated } from "hooks/useCourseOutdated";
import AlertTooltip from "components/partial/icons/alert-tooltip";

interface ChapterTileProps {
	chapter: IChapterRef;
	isActive: boolean;
	onClick: (id: number) => void;
	count: number;
	className?: string;
	marginLeft?: null | number;
}

const ChapterTile = (props: ChapterTileProps) => {
	const { chapter, className, isActive, count, onClick, marginLeft } = props;
	const { isChapterOutdated } = useCourseOutdated({ chapterId: chapter.id });

	return (
		<div
			className={classNames(styles.chapter_tile, className, {
				[styles.chapter_tile__not_active]: !isActive,
			})}
			onClick={() => onClick(chapter.id)}
			style={{left: marginLeft ? `${marginLeft}px` : "unset"}}
		>
			<div className={styles.chapter_tile__head}>
				<div className={styles.chapter_tile__inner_group}>
					<VideoIcon className={classNames(styles.chapter_tile__inner_icon, styles.chapter_tile__icon_video)} />
					<div className={classNames(styles.chapter_tile__header_text)}>
						{count} content{count > 1 && "s"}
					</div>
				</div>
				{isChapterOutdated && <AlertTooltip className={styles.chapter_tile__outdated_tooltip} />}
			</div>
			<div className={styles.chapter_tile__body}>
				<div className={styles.chapter_tile__description_picture}>
					<img
						src={getThumbnail(chapter.thumbnail)}
						alt="thumbnail"
						className={classNames(styles.chapter_tile__description_img)}
					/>
				</div>
			</div>
			<div className={styles.chapter_tile__footer}>
				<div className={classNames(styles.chapter_tile__chapter_desc)}>{chapter.name}</div>
			</div>
		</div>
	);
};

export default ChapterTile;
