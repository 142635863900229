import { TABLET_MAX_WIDTH } from "constants/constants";
import { tabletCheck } from "helpers/device.helper";
import { useMemo } from "react";
import { ColumnGroup } from "react-table";

interface Props<T extends object> {
	columns: ColumnGroup<T>[];
	excludedColumns: string[];
}

export function useTabletGridColumns<T extends object>({ columns, excludedColumns }: Props<T>) {
	const isTablet = useMemo(() => tabletCheck, []) || window.innerWidth < TABLET_MAX_WIDTH;
	const tableColumns = useMemo(() => {
		const tabletColumns = columns[0].columns.filter((column) => {
			if (typeof column.Header === "string") {
				return !excludedColumns.includes(column.Header.toString());
			}
			return true;
		});
		const usersGridColumns = isTablet ? tabletColumns : columns[0].columns;
		return usersGridColumns;
		// eslint-disable-next-line
	}, [isTablet]);

	return { tableColumns };
}
