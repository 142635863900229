import { BillingApi } from "api/controllers/BillingApi";
import { makeAutoObservable, runInAction } from "mobx";
import { IAccountModel } from "models/dto/ZoomiLxp/Models/Authentication/IAccountModel";
import { IB2BPurchaseModel } from "models/dto/ZoomiLxp/Models/Billing/IB2BPurchaseModel";
import { IB2CPurchaseModel } from "models/dto/ZoomiLxp/Models/Billing/IB2CPurchaseModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { B2BPurchaseStatuses } from "models/dto/ZoomiLxp/Utilities/Enumerations/B2BPurchaseStatuses";
import { ICompanyModel } from "models/dto/ZoomiLxp/Models/CompanyModels/ICompanyModel";
import { downloadFile } from "helpers/file-download.helper";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { getInitParams } from "helpers/filter.helper";
import { AppStore } from "store";

export class BillingStore {
	private rootStore;
	private _isLoading: boolean = false;
	private _b2cPurchases: IB2CPurchaseModel[] = [];
	private _b2bPurchases: IB2BPurchaseModel[] = [];
	private _b2bCustomersList: string[] = [];
	private _b2cCustomersList: IAccountModel[] = [];
	private _b2bCompanies: ICompanyModel[] = [];
	private _b2bAssigners: Pick<IProfileDataModel, "id" | "displayName">[] = [];
	private _b2cStatuses: string[] = [];
	private _b2bSearchParams: IQueryParams = getInitParams();
	private _b2cSearchParams: IQueryParams = getInitParams();

	constructor(rootStore: AppStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);
	}

	async createCheckoutSession(courseId: number) {
		this.isLoading = true;
		try {
			const response = await BillingApi.createCheckoutSession(courseId);
			return response.data.data.url;
		} finally {
			this.isLoading = false;
		}
	}

	async getCourseIdByPaymentSession(sessionId: string) {
		this.isLoading = true;
		try {
			const response = await BillingApi.getCourseIdByPaymentSession(sessionId);
			return response.data.data.courseId;
		} catch (error) {
			throw error;
		} finally {
			this.isLoading = false;
		}
	}

	async getB2CPurchases(data?: IQueryParams) {
		this.isLoading = true;
		try {
			this.b2cSearchParams = this.rootStore.searchStore.params;
			const response = await BillingApi.getB2CPurchases(data ?? this.b2cSearchParams);
			runInAction(() => {
				this._b2cPurchases = response.data.data.records;
			});
			this.setB2CCustomersList(response.data.data.records);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getB2BPurchases(data?: IQueryParams) {
		this.isLoading = true;
		try {
			this.b2bSearchParams = this.rootStore.searchStore.params;
			const response = await BillingApi.getB2BPurchases(data ?? this.b2bSearchParams);
			runInAction(() => {
				this._b2bPurchases = response.data.data.records;
			});

			this.setB2BCustomersList(response.data.data.records);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getB2BSales(data?: IQueryParams) {
		this.isLoading = true;
		try {
			this.b2bSearchParams = this.rootStore.searchStore.params;
			const response = await BillingApi.getB2BSales(data ?? this.b2bSearchParams);

			runInAction(() => {
				this._b2bPurchases = response.data.data.records;
			});

			this.setB2BCustomersList(response.data.data.records);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getB2CSales(data?: IQueryParams) {
		this.isLoading = true;
		try {
			this.b2cSearchParams = this.rootStore.searchStore.params;
			const response = await BillingApi.getB2CSales(data ?? this.b2cSearchParams);
			runInAction(() => {
				this._b2cPurchases = response.data.data.records;
			});
			this.setB2CCustomersList(response.data.data.records);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getB2CStatuses() {
		this.isLoading = true;
		try {
			const response = await BillingApi.getB2CStatuses();
			this.b2cStatuses = response.data.data?.statuses;
			return response.data.data?.statuses;
		} finally {
			this.isLoading = false;
		}
	}

	async getB2BCompanies() {
		this.isLoading = true;
		try {
			const response = await BillingApi.getB2BCompanies();
			this.b2bCompanies = response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getB2BAssigners() {
		this.isLoading = true;
		try {
			const response = await BillingApi.getB2BAssigners();
			this.b2bAssigners = response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async updateB2BStatus(purchaseId: number, data: B2BPurchaseStatuses) {
		this.isLoading = true;
		try {
			const response = await BillingApi.updateB2BStatus(purchaseId, { status: data });
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	public async exportB2CPurchases(data: IQueryParams) {
		this.isLoading = true;
		try {
			await BillingApi.exportB2CPurchases(data).then((response) => {
				downloadFile(response);
			});
		} finally {
			this.isLoading = false;
		}
	}

	public async exportB2BPurchases(data: IQueryParams) {
		this.isLoading = true;
		try {
			await BillingApi.exportB2BPurchases(data).then((response) => {
				downloadFile(response);
			});
		} finally {
			this.isLoading = false;
		}
	}

	public async exportB2CSales(data: IQueryParams) {
		this.isLoading = true;
		try {
			await BillingApi.exportB2CSales(data).then((response) => {
				downloadFile(response);
			});
		} finally {
			this.isLoading = false;
		}
	}

	public async exportB2BSales(data: IQueryParams) {
		this.isLoading = true;
		try {
			await BillingApi.exportB2BSales(data).then((response) => {
				downloadFile(response);
			});
		} finally {
			this.isLoading = false;
		}
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	private setB2BCustomersList(b2bPurchases: IB2BPurchaseModel[]) {
		this._b2bCustomersList = b2bPurchases.map((purchase: IB2BPurchaseModel) => purchase.companyName);
	}

	private setB2CCustomersList(b2cPurchases: IB2CPurchaseModel[]) {
		this._b2cCustomersList = b2cPurchases.map((purchase: IB2CPurchaseModel) => purchase.account);
	}

	get b2cCustomersList() {
		return this._b2cCustomersList;
	}

	get b2bCustomersList() {
		return this._b2bCustomersList;
	}

	get B2CPurchases() {
		return this._b2cPurchases;
	}

	get B2BPurchases() {
		return this._b2bPurchases;
	}

	get b2bCompanies() {
		return this._b2bCompanies;
	}

	set b2bCompanies(companies: ICompanyModel[]) {
		this._b2bCompanies = companies;
	}

	get b2bAssigners() {
		return this._b2bAssigners;
	}

	set b2bAssigners(assigners: Pick<IProfileDataModel, "id" | "displayName">[]) {
		this._b2bAssigners = assigners;
	}

	get b2cStatuses() {
		return this._b2cStatuses;
	}

	set b2cStatuses(statuses: string[]) {
		this._b2cStatuses = statuses;
	}

	get isLoading() {
		return this._isLoading;
	}

	get b2bSearchParams() {
		return this._b2bSearchParams;
	}

	set b2bSearchParams(params: IQueryParams) {
		this._b2bSearchParams = params;
	}

	get b2cSearchParams() {
		return this._b2cSearchParams;
	}

	set b2cSearchParams(params: IQueryParams) {
		this._b2cSearchParams = params;
	}
}
