import { makeAutoObservable, runInAction } from "mobx";
import { LmsApi } from "api/controllers/LmsApi";
import { IActivityInfo } from "models/dto/ZoomiLxp/Models/LMS/IActivityInfo";
import { showErrorMessage } from "helpers/error.handling.helper";
import { AppStore } from "store";

export class LmsStore {
	private _isLoading: boolean = false;
	private _activityInfo: IActivityInfo = {} as IActivityInfo;
	private _attemptId: number = 0;
	rootStore: AppStore;

	constructor(rootStore: AppStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);
	}

	get activityInfo(): IActivityInfo {
		return this._activityInfo;
	}

	set activityInfo(value: IActivityInfo) {
		this._activityInfo = value;
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get isLoading() {
		return this._isLoading;
	}

	set attemptId(attemptId: number) {
		this._attemptId = attemptId;
	}

	get attemptId() {
		return this._attemptId;
	}

	async createAttempt(organizationId: string) {
		this.isLoading = true;

		try {
			const response = await LmsApi.createAttempt(organizationId);
			runInAction(() => {
				this.attemptId = response.data.data.attemptId;
			});
			window.sessionStorage.setItem(
				"lmsStore",
				JSON.stringify({
					attemptId: response.data.data.attemptId,
				})
			);
			return response.data.data;
		} catch (err) {
			console.error(err);
			showErrorMessage(
				this.rootStore.commonStore.alertEventBus,
				"LMS lost connection to SCORM. Please refresh page to retry."
			);
		} finally {
			this.isLoading = false;
		}
	}

	async getPackageMetadata(contentId: number) {
		this.isLoading = true;
		try {
			const response = await LmsApi.getPackageMetadata(contentId);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getActivityInfo(view: number, attemptId: number) {
		this.isLoading = true;
		try {
			const response = await LmsApi.getActivityInfo(view, attemptId);
			this._activityInfo = response.data.data;
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async setActivityInfo(view: number, attemptId: number) {
		this.isLoading = true;
		try {
			const response = await LmsApi.setActivityInfo(view, attemptId);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	clearStore() {
		runInAction(() => {
			this._activityInfo = {} as IActivityInfo;
		});
	}
}
