import React, { useCallback } from "react";
import { ControlParams } from "components/partial/player/player-control/player-control";
import { useAppStore } from "store";

export const usePlayerTool = (setParams: React.Dispatch<React.SetStateAction<ControlParams>>) => {
	const { coursesStore } = useAppStore();

	const disableControls = useCallback(() => {
		setParams((prevState: ControlParams) => ({ ...prevState, isPrev: false }));
		setParams((prevState: ControlParams) => ({ ...prevState, isNext: false }));
	}, [setParams]);

	const enableControls = useCallback(
		(enableOnly?: "prev" | "next") => {
			const current = coursesStore.curPositionContent;
			if (!enableOnly || enableOnly === "prev")
				setParams((prevState: ControlParams) => ({ ...prevState, isPrev: current > 0 }));
			if (!enableOnly || enableOnly === "next")
				setParams((prevState: ControlParams) => ({ ...prevState, isNext: current + 1 <= coursesStore.countContents }));
		},
		[coursesStore.countContents, coursesStore.curPositionContent, setParams]
	);

	return { disableControls, enableControls };
};
