import Api, { ApiBase } from "api/utils/api";
import { mimeTypes } from "constants/constants";
import { ICertificatePreviewModel } from "models/dto/ZoomiLxp/Models/Certificates/ICertificatePreviewModel";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";

export class CertificatesApi {
	static getCertificateData<T>(certId: string, isPdf?: boolean) {
		return ApiBase.get<T>(`/Certificates/certificate/${certId}`, {
			headers: { Accept: isPdf ? mimeTypes.pdf : mimeTypes.png },
			responseType: "arraybuffer",
		});
	}
	static getCertificates(data: IQueryParams) {
		return Api.post<IGetRecordsResponse<ICertificatePreviewModel>, IQueryParams>(`/Certificates/certificates`, data);
	}

	static getCertificatesByUserId(userId: number, data: IQueryParams) {
		return Api.post<IGetRecordsResponse<ICertificatePreviewModel>, IQueryParams>(
			`/Certificates/userCertificates/${userId}`,
			data
		);
	}
}
