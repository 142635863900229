import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as CloseIcon } from "assets/icons/ic_close.svg";
import mitt, { Emitter } from "mitt";
import { isNil } from "lodash";

export type AlertEventTypes = {
	show: IAlertProps;
	hide?: undefined;
};

export function createAlertEventBus() {
	return mitt<AlertEventTypes>();
}

interface Props {
	eventBus: Emitter<AlertEventTypes>;
	className?: string;
	onClose?: () => void;
}

interface IAlertProps {
	text: string;
	theme?: "red" | "green";
	link?: { prefix: string; link: string };
}

const cx = classNames.bind(styles);

const Alert: React.FC<Props> = (props: Props) => {
	const { className, onClose, eventBus } = props;

	const [state, setState] = useState(() => ({
		isShown: false,
		text: "",
		theme: "red",
		link: { prefix: "", link: "" },
	}));
	const { isShown, text, theme, link } = state;

	const onCloseInternal = useCallback(() => {
		setState((state) => ({
			...state,
			isShown: false,
			text: "",
			theme: "red",
		}));
		onClose?.();
	}, [onClose]);

	useEffect(() => {
		eventBus.on("show", (props: IAlertProps) => {
			const { text, theme, link } = props;
			setState((state) => ({
				...state,
				isShown: true,
				text,
				theme: theme || "red",
				link: link || { prefix: "", link: "" },
			}));
		});

		eventBus.on("hide", onCloseInternal);

		return () => {
			eventBus.all.clear();
		};
	}, [eventBus, onCloseInternal]);

	return (
		<>
			{isShown && (
				<div className={cx(styles.alert, styles[`alert__theme_${theme}`], className)}>
					<div className={cx(styles.alert__text, styles[`alert__theme_${theme}_text`])}>
						{text}
						<br />
						{!isNil(link) && link.link.length > 0 && (
							<>
								{link.prefix}
								<a href={link.link}> Download</a>
							</>
						)}
					</div>
					<div className={styles.alert__icon_box} onClick={onCloseInternal}>
						<CloseIcon className={cx(styles.alert__icon)} />
					</div>
				</div>
			)}
		</>
	);
};

export default Alert;
