import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { generatePath, useHistory } from "react-router-dom";
import { matchPath, Prompt } from "react-router";

import classNames from "classnames";
import { FieldArray, Form, Formik, FormikErrors, FormikValues, FormikProps } from "formik";
import { difference, find, isEmpty, isEqual, isNil, isNull, some } from "lodash";

import { Routes } from "routes";
import { useAppStore } from "store";
import { useRouteUserId } from "hooks/useRouteUserId";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { IBaseModel } from "models/dto/ZoomiLxp/Models/Common/IBaseModel";
import { AccountRoles } from "models/dto/ZoomiLxp/Utilities/Enumerations/AccountRoles";
import { UploadFileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/UploadFileTypes";
import { IFileModel } from "models/dto/ZoomiLxp/Models/Common/IFileModel";
import { FileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/FileTypes";
import { BulkOperationType } from "models/dto/ZoomiLxp/Utilities/Enumerations/BulkOperationType";

import styles from "./styles.module.scss";
import ProfileLayout from "layouts/private/profile/profile-layout";
import Button from "components/base/button/button";
import InputField from "components/base/input-field/input-field";
import UploadTarget from "components/base/upload-target/upload-target";
import Avatar from "components/base/avatar/avatar";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import DropdownList from "components/base/dropdown-list/dropdown-list";
import TogglerActivation from "components/partial/toggler-activation/toggler-activation";
import DropdownListRole from "components/partial/dropdown-list-role/dropdown-list-role";
import DropdownListCompany from "components/partial/dropdown-list-company/dropdown-list-company";
import Tooltip from "components/base/tooltip/tooltip";
import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { ConfirmationModal } from "components/partial/confirmation-popup/confirmation-popup";
import { ICroppedFileOptions, cropImageHandler } from "components/partial/image-cropper/image-cropper.helper";
import {
	errorMessages,
	picturesHintMessages,
	infoMessages,
	promptsResetPassword,
	promptsChangeCompanyMethod,
} from "constants/messages";
import { acceptedMimeTypes, DELAY_TIME_LONGER, getEmptyUserData } from "constants/constants";
import { emptyOptionItem, fromBaseModel, getBaseModelOptions, IOptionsItem } from "helpers/select.helper";
import { getAvatarTitle } from "helpers/string.helper";
import { dropdownStyles } from "./styles";
import { converterToCreateProfile, converterToUpdateProfile } from "./converter";
import { getInfluencerSchema, getUserSchema } from "./schema";
import { ReactComponent as AddIcon } from "assets/icons/ic_plus.svg";
import { ReactComponent as RemoveIcon } from "assets/icons/ic_close.svg";
import { ReactComponent as InfoIcon } from "assets/icons/ic_info.svg";
import { useBulkOperationsModal } from "hooks/useBulkOperationsModal";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";
import { PermissionConstantsCloning } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsCloning";
import { PermissionConstantsCreate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsCreate";
import { PermissionConstantsAllowForTarget } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsAllowForTarget";
import { showGroupsAssignmentModal } from "./components/groups-assignment-popup";
import { getChangedGroup } from "helpers/groups.helper";
import { GroupAssignmentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/GroupAssignmentTypes";
import { IUpdateProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IUpdateProfileDataModel";
import { useGroupsAssignmentsList } from "hooks/useGroups";
import Toggler from "components/base/toggler/toggler";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { toBoolean } from "components/partial/cms/cms-settings/settings-converter";

const ACTIVATE_TOOLTIP_MESSAGE =
	"Active users can log in the platform. Inactive users are not able to log in. Active Influencers will show up in search and in Influencers page. Inactive Influencers won’t show up in search and in Influencers page";

export type DisplayType = {
	isShow: boolean;
	isDisabled: boolean;
};

const UserProfilePage = () => {
	const { routeUserId } = useRouteUserId();
	const { usersStore, accountStore, bulkOperationsStore, toasterStore, groupStore, settingsStore } = useAppStore();
	const history = useHistory();
	const isNewUser = !!matchPath(history.location.pathname, Routes.CmsAddUser);
	const isCmsUpdateUser = !!matchPath(history.location.pathname, Routes.CmsUserProfile);
	const isCurrentUserProfile = !!matchPath(history.location.pathname, Routes.Profile);
	const is2FAEnabled = toBoolean(settingsStore.publicSettings?.Auth[SiteSettingsKeys.Enabled2FA]);
	const [userInfo, setUserInfo] = useState<IProfileDataModel>(getEmptyUserData());
	const [resetPasswordMessage, setPasswordMessage] = useState<string>();
	const isOtherUserProfile = isNewUser || (!!routeUserId && !isCurrentUserProfile);
	const [newArea, setNewArea] = useState({ name: "" });
	const [isInfluencerProfile, setIsInfluencerProfile] = useState<boolean>(false);
	const [userProfileSchema, setUserProfileSchema] = useState({});
	const [showCompany, setShowCompany] = useState<DisplayType>({ isShow: false, isDisabled: true });
	const [company, setCompany] = useState<IOptionsItem | null>(null);
	const [role, setRole] = useState<IOptionsItem | null>(null);
	const [rolesOptions, setRolesOptions] = useState<IOptionsItem[]>([]);
	const ref = useRef<FormikProps<IProfileDataModel>>(null);
	const [userGroups, setUserGroups] = useState<IOptionsItem[]>([]);
	const [groupsOptions, setGroupsOptions] = useState<IOptionsItem[]>([]);
	const [isPictureLoading, setIsPictureLoading] = useState<boolean>(false);
	const { openBulkPopup } = useBulkOperationsModal(toasterStore);
	const isAdminActionsAllowed = usersStore.checkOneOfUserPermissions([PermissionConstantsRead.AllUsersProfileInfo]);
	const isShowCompany = usersStore.checkOneOfUserPermissions([PermissionConstantsRead.CompanyUsersProfileInfo]);
	const isCloningAllowed =
		usersStore.checkOneOfUserPermissions([PermissionConstantsCloning.UsersBetweenTenants]) && !isNewUser;
	const isCreateCompanyUserAllowed = usersStore.checkOneOfUserPermissions([PermissionConstantsCreate.CompanyUsers]);
	const { handleGroupAssignment } = useGroupsAssignmentsList();

	useEffect(() => {
		setShowCompany({ isShow: isShowCompany, isDisabled: !isAdminActionsAllowed });
	}, [isShowCompany, isAdminActionsAllowed]);

	useEffect(() => {
		if (isInfluencerProfile && role?.value === AccountRoles.Influencer) {
			setUserProfileSchema(getInfluencerSchema);
		} else setUserProfileSchema(getUserSchema(isNewUser));
	}, [isInfluencerProfile, isNewUser, role]);

	useEffect(() => {
		usersStore
			.getPermissionsGroups()
			.then((response) => setGroupsOptions(getBaseModelOptions(response)))
			.catch((err: unknown) => toasterStore.showErrorMessage(err));
	}, [toasterStore, usersStore]);

	useEffect(() => {
		if (usersStore.permissionGroups.length && userInfo.id) {
			setUserGroups(
				usersStore.permissionGroups
					.filter((group: IBaseModel) => userInfo.groupIds.includes(group.id))
					.map(fromBaseModel)
			);
		}
	}, [usersStore.permissionGroups, userInfo]);

	useEffect(() => {
		const setDropdownList = (user: IProfileDataModel) => {
			if (!isEmpty(user.roles)) setRole({ label: user.roles[0].displayName, value: String(user.roles[0].id) });
			if (!isEmpty(user.company)) setCompany({ label: user.company.name, value: String(user.company.id) });
		};

		const getUsersData = async (id: number) => {
			await usersStore.getUserById(Number(id)).then((user) => {
				setUserInfo(user as IProfileDataModel);
				setIsInfluencerProfile(
					usersStore.checkOneOfUserPermissions([PermissionConstantsRead.GetPurchasesByOwnCourses], user)
				);
				setDropdownList(user);
			});
		};

		if (!isNewUser) {
			if (routeUserId) {
				if (isEmpty(rolesOptions)) getUsersData(routeUserId);
			} else {
				getUsersData(usersStore.currentUserInfo!.id);
			}
		} else {
			setIsInfluencerProfile(false);
			if (isCreateCompanyUserAllowed && !isEmpty(rolesOptions)) {
				const role = find(rolesOptions, { value: AccountRoles.CompanyLearner }) ?? emptyOptionItem;
				setRole(role);
				ref.current?.setFieldValue("roles", [{ id: Number(role.value), name: role.label }]);
				if (usersStore.currentUserInfo?.company) {
					setCompany({
						value: String(usersStore.currentUserInfo?.company.id),
						label: String(usersStore.currentUserInfo?.company.name),
					});
					ref.current?.setFieldValue("company", {
						id: String(usersStore.currentUserInfo?.company.id),
						name: usersStore.currentUserInfo?.company.name,
					});
				}
			}
		}
	}, [isNewUser, rolesOptions, routeUserId, usersStore, isCreateCompanyUserAllowed]);

	useEffect(() => {
		if (!isNil(role)) {
			const id = Number(role.value);

			if (id === AccountRoles.CompanyLearner || id === AccountRoles.CompanyManager) {
				setShowCompany((prev: DisplayType) => ({ ...prev, isShow: true }));
			} else {
				setShowCompany((prev: DisplayType) => ({ ...prev, isShow: false }));
			}
		}
	}, [role]);

	const onSaveNewProfile = async (
		data: IProfileDataModel,
		setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
	) => {
		try {
			const user = await usersStore.addNewUser(converterToCreateProfile(data));
			toasterStore.showSuccessMessage(
				"A new user is successfully created. The user will receive an email with a link to verify the account and set a password"
			);
			setFieldValue("roles", role ? [{ id: Number(role.value), name: role.label }] : []);
			setFieldValue("company", company ? { id: Number(company.value), name: company.label } : null);
			setTimeout(() => {
				const userProfilePage = generatePath(Routes.CmsUserProfile, { userId: user.id });
				history.push(userProfilePage);
				window.location.reload();
			}, DELAY_TIME_LONGER);
		} catch (err: unknown) {
			toasterStore.showErrorMessage(err);
		}
	};

	const onSaveUpdatedProfile = async (data: IProfileDataModel, isChangeCompanyImmediately?: boolean) => {
		try {
			const isChangeCompanyToTarget = some(data.permissions, PermissionConstantsAllowForTarget.ChangeCompany);
			if (isChangeCompanyToTarget && isNull(data.company)) {
				toasterStore.showErrorMessage("Please select a company to update the user.");
				return;
			}
			const updatedData: IUpdateProfileDataModel = converterToUpdateProfile(data, isChangeCompanyImmediately ?? false);
			await usersStore.updateUser(updatedData).then((user) => {
				setUserInfo(user);
				if (user.message) toasterStore.showSuccessMessage(user.message);
				else toasterStore.showSuccessMessage("Profile data successfully updated");
			});
		} catch (err: unknown) {
			toasterStore.showErrorMessage(err);
		}
	};

	const openCloningPopup = () => {
		bulkOperationsStore.checkedState = [userInfo.id];
		bulkOperationsStore.bulkOperationType = BulkOperationType.UserCloning;
		openBulkPopup("User cloning");
	};

	const openConfirmationPopup = async () => {
		const resetPasswordResult = await modalDialogService.show(ConfirmationModal, {
			popupText: promptsResetPassword.text,
			confirmationAction: async () => accountStore.resetPassword({ email: usersStore.currentUserInfo?.email! }),
			resolveMessage: promptsResetPassword.resolveMessage,
			title: promptsResetPassword.title,
			confirmationButtonLabel: "Reset password",
		});

		if (!!resetPasswordResult?.resolveMessage) {
			setPasswordMessage(resetPasswordResult.resolveMessage);
		}
	};

	const CompanyChangeMethodPopup = async (
		validateForm: (values?: any) => Promise<FormikErrors<FormikValues>>,
		values: FormikValues,
		setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
		setErrors: (errors: FormikErrors<FormikValues>) => void
	) => {
		await modalDialogService.show(ConfirmationModal, {
			popupText: promptsChangeCompanyMethod.popupText,
			confirmationAction: async () => {
				await saveProfileHandler(validateForm, values, setFieldValue, setErrors, false);
			},
			secondaryConfirmationAction: async () => {
				await saveProfileHandler(validateForm, values, setFieldValue, setErrors, true);
			},
			confirmationButtonLabel: promptsChangeCompanyMethod.confirmationButtonLabel,
			cancelButtonLabel: promptsChangeCompanyMethod.cancelButtonLabel,
			title: promptsChangeCompanyMethod.title,
			isWideButtons: true,
			showCloseIcon: true,
		});
	};

	const handleAreaClick = (values: FormikValues, push: (obj: { name: string }) => void) => () => {
		if (isEmpty(newArea.name)) {
			toasterStore.showErrorMessage("Area of expertise field can't be empty");
			return;
		}

		const maxAreasOfExpertises = 15;
		if (values.expertises?.length >= maxAreasOfExpertises) {
			toasterStore.showErrorMessage(
				"The number of areas of expertise cannot be more than " + maxAreasOfExpertises.toString()
			);
			return;
		}

		if (!isEmpty(find(values.expertises, newArea))) {
			toasterStore.showErrorMessage("Area of expertise cannot contain duplicates");
			return;
		}

		push(newArea);
		setNewArea({ name: "" });
	};

	const saveProfileHandler = async (
		validateForm: (values?: any) => Promise<FormikErrors<FormikValues>>,
		values: FormikValues,
		setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
		setErrors: (errors: FormikErrors<FormikValues>) => void,
		isChangeCompanyImmediately?: boolean
	) => {
		const errors = await validateForm();
		if (isEmpty(errors)) {
			const updatedData = { ...values, displayName: `${values.firstName} ${values.lastName}` } as IProfileDataModel;
			if (isNewUser) {
				onSaveNewProfile(updatedData, setFieldValue);
			} else {
				if (groupStore.removedGroupsAssignmentData.length || groupStore.addedGroupsAssignmentData.length) {
					showGroupsAssignmentModal(
						async () => await onSaveUpdatedProfile(updatedData, isChangeCompanyImmediately),
						() => groupStore.clearGroupsAssignRequestData()
					);
				} else await onSaveUpdatedProfile(updatedData, isChangeCompanyImmediately);
			}
		} else setErrors(errors);
	};

	const checkPrompt = (values: FormikValues) =>
		!isNewUser && !isNil(userInfo) && !isEmpty(values) && !isEqual(userInfo, values);

	const changeRole =
		(setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => (value: IOptionsItem | null) => {
			if (!isNull(value)) {
				setIsInfluencerProfile(Number(value.value) === AccountRoles.Influencer);
				setRole(value);

				setFieldValue("roles", [
					{
						id: Number(value.value),
						name: value.label,
					},
				]);
			}
		};

	const fileCroppingOptions: ICroppedFileOptions = {
		imageType: FileTypes.Thumbnail,
		uploadFileType: UploadFileTypes.UserThumbnail,
	};

	const groupsChangeHandler = (
		value: IOptionsItem[],
		setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
	) => {
		if (value) {
			const groupsMappedArr: number[] = value.map((item) => Number(item.value));
			setUserGroups(value);
			setFieldValue("groupIds", groupsMappedArr);

			const removedGroupOption: IOptionsItem | undefined = difference(userGroups, value)[0];
			const addedGroupOption: IOptionsItem | undefined = difference(value, userGroups)[0];

			const chagedGroup = getChangedGroup(
				removedGroupOption || addedGroupOption,
				removedGroupOption ? GroupAssignmentTypes.Unassign : GroupAssignmentTypes.Assign
			);

			handleGroupAssignment(chagedGroup);
		}
	};

	return (
		<ProfileLayout>
			<LoadingIndicator loading={usersStore.isLoading}>
				<div className={classNames(styles.user_profile, { [styles.user_profile__current_user]: isCurrentUserProfile })}>
					<Formik
						initialValues={userInfo}
						validationSchema={userProfileSchema}
						onSubmit={() => {}}
						validateOnChange={false}
						enableReinitialize={true}
						innerRef={ref}
					>
						{({ values, validateForm, setFieldValue, errors, setErrors }) => (
							<Form>
								<Prompt message={infoMessages.leaveWithoutSaving} when={checkPrompt(values)} />
								<div className={styles.user_profile__tile}>
									<div className={styles.user_profile__label_container}>
										<p className={styles.user_profile__tile_label}>Profile picture</p>
										{isOtherUserProfile && (
											<div className={styles.user_profile__buttons_container}>
												{isCloningAllowed && (
													<Button
														label="Clone"
														onClick={async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
															e.preventDefault();
															openCloningPopup();
														}}
														size="small"
														disabled={usersStore.isLoading}
														testId={"course_profile__clone_button"}
													/>
												)}

												<div className={styles.user_profile__activation_group}>
													<TogglerActivation
														value={values.isActive ?? false}
														onChange={(value) => {
															setFieldValue("isActive", value);
														}}
														className={styles.user_profile__toggler}
													/>
													<Tooltip label={ACTIVATE_TOOLTIP_MESSAGE} className={styles.user_profile__tooltip}>
														<InfoIcon className={styles.user_profile__info_icon} />
													</Tooltip>
												</div>
											</div>
										)}
									</div>
									<div className={styles.user_profile__box}>
										<div className={styles.user_profile__pictures_container}>
											<Avatar
												title={userInfo && getAvatarTitle(userInfo.firstName, userInfo.lastName)}
												size="medium"
												className={styles.user_profile__avatar}
												image={values.userPicture?.url}
												isLoading={isPictureLoading}
												hintText={picturesHintMessages.avatar}
											/>
											<UploadTarget
												accept={acceptedMimeTypes.pictures}
												onSuccess={(res, type) => {
													cropImageHandler({
														parentFile: { ...res.data, contentType: type },
														filesOptions: fileCroppingOptions,
														onCropSuccessHandler: (newFile: IFileModel) => setFieldValue("userPicture", newFile),
														onLoading: (isLoading) => setIsPictureLoading(isLoading),
														isCircularCrop: true,
													});
												}}
												onError={(err) => console.log(err)}
												onLoading={(isLoading) => setIsPictureLoading(isLoading)}
												maxSize={10}
												onWrongFormat={(type) =>
													toasterStore.showErrorMessage(
														errorMessages.unsupportedFileType(type, "a JPG or PNG")
													)
												}
												onMaxSizeExceeded={(size, maxSize) => {
													toasterStore.showErrorMessage(
														errorMessages.exceedingAllowedFileSize(size, maxSize)
													);
												}}
												fileType={UploadFileTypes.UserThumbnail}
											>
												<label className={styles.user_profile__change_avatar_button}>
													{values.userPicture && !!values.userPicture.id ? "Change image" : "Upload picture"}
												</label>
											</UploadTarget>
											{!!errors.userPicture && (
												<p className={styles.user_profile__avatar_error}>{errors.userPicture}</p>
											)}
											{values.userPicture && !!values.userPicture.id && (
												<Button
													label="Remove"
													labelColor="outlineSecondary"
													onClick={() => setFieldValue("userPicture", null)}
													type="outlined"
													className={styles.user_profile__remove_avatar_button}
												/>
											)}
										</div>

										<div className={styles.user_profile__dropdown_wrap}>
											<DropdownListRole
												value={role}
												onChange={changeRole(setFieldValue)}
												isDisabled={!isAdminActionsAllowed || !isOtherUserProfile}
												className={styles.user_profile__dropdown_role}
												onGetOptions={(options) => setRolesOptions(options)}
											/>
										</div>
									</div>
								</div>

								<div className={styles.user_profile__tile}>
									<p className={styles.user_profile__tile_label}>Details</p>
									<div className={styles.user_profile__content_box}>
										<InputField
											inputType="text"
											label="First Name"
											className={styles.user_profile__field_form}
											name="firstName"
											value={values.firstName ?? ""}
											onChange={(e) => setFieldValue("firstName", e.target.value)}
											isError={!!errors.firstName}
											errorText={errors.firstName}
										/>
										<InputField
											inputType="text"
											label="Last Name"
											className={styles.user_profile__field_form}
											name="lastName"
											value={values.lastName ?? ""}
											onChange={(e) => setFieldValue("lastName", e.target.value)}
											isError={!!errors.lastName}
											errorText={errors.lastName}
										/>
										<InputField
											disabled={!isNewUser}
											label="Email"
											className={styles.user_profile__field_form}
											name="email"
											value={!values.email ? "" : values.email}
											onChange={(e) => setFieldValue("email", e.target.value)}
											isError={!!errors.email}
											errorText={errors.email}
										/>
										{is2FAEnabled && (
											<div className={styles.user_profile__activation_group}>
												Enable 2FA
												<Toggler value={values.mfaEnabled} onChange={(value) => setFieldValue("mfaEnabled", value)} />
											</div>
										)}
									</div>
								</div>
								<div className={styles.user_profile__tile}>
									<p className={styles.user_profile__tile_label}>Profile info</p>
									<div className={styles.user_profile__content_box}>
										<InputField
											inputType="text"
											label="Job Title"
											className={styles.user_profile__field_form}
											name="jobTitle"
											value={values.jobTitle ?? ""}
											onChange={(e) => setFieldValue("jobTitle", e.target.value)}
											isError={!!errors.jobTitle}
											errorText={errors.jobTitle}
										/>
										{showCompany.isShow && (
											<DropdownListCompany
												value={company}
												placeholder="Company"
												className={styles.user_profile__drop_list}
												onChange={(value) => {
													if (value) {
														setCompany({ label: value.label, value: value.value });
														setFieldValue("company", {
															id: Number(value.value),
															name: value.label,
														});
													}
												}}
												isDisabled={showCompany.isDisabled}
											/>
										)}
										<InputField
											inputType="text"
											label="Country"
											className={styles.user_profile__field_form}
											name="country"
											value={values.country ?? ""}
											onChange={(e) => setFieldValue("country", e.target.value)}
											isError={!!errors.country}
											errorText={errors.country}
										/>
										<InputField
											inputType="text"
											label="City"
											className={styles.user_profile__field_form}
											name="city"
											value={values.city ?? ""}
											onChange={(e) => setFieldValue("city", e.target.value)}
											isError={!!errors.city}
											errorText={errors.city}
										/>
									</div>
								</div>
								{isInfluencerProfile && (
									<>
										<div className={styles.user_profile__tile}>
											<p className={styles.user_profile__tile_label}>Details</p>
											<div className={classNames(styles.user_profile__content_box_details)}>
												<InputField
													fieldType="textarea"
													label="Description"
													className={styles.user_profile__input_area}
													name="description"
													value={values.description ?? ""}
													onChange={(e) => setFieldValue("description", e.target.value)}
													isError={!!errors.description}
													errorText={errors.description}
												/>
											</div>
										</div>
										<div className={styles.user_profile__tile}>
											<p className={styles.user_profile__tile_label}>Areas of expertise</p>

											<FieldArray
												name="expertises"
												render={({ push, remove }) => (
													<div className={styles.user_profile__areas_container}>
														<InputField
															inputType="text"
															label="Add areas"
															className={classNames(styles.user_profile__field_form, styles.user_profile__wide)}
															value={newArea.name ?? ""}
															onChange={(e) => setNewArea({ name: e.target.value })}
															isError={!!errors.expertises!}
															icon={<AddIcon className={styles.user_profile__areas_button_icon} />}
															onKeyDown={(event) => {
																if (event.key === "Enter") {
																	event.preventDefault();
																	handleAreaClick(values, push)();
																}
															}}
															onIconClick={handleAreaClick(values, push)}
															errorText={String(errors.expertises)}
														/>

														<div className={styles.user_profile__areas_block}>
															{values.expertises?.map((item: IBaseModel, index: number) => {
																return (
																	<Button
																		key={index}
																		label={item.name}
																		onClick={() => remove(index)}
																		size="small"
																		iconPosition="right"
																		icon={<RemoveIcon className={styles.user_profile__areas_button_icon} />}
																		className={styles.user_profile__areas_remove_btn}
																	/>
																);
															})}
														</div>
													</div>
												)}
											/>
										</div>
									</>
								)}
								{isAdminActionsAllowed && (
									<div className={styles.user_profile__tile}>
										<p className={styles.user_profile__tile_label}>Groups</p>
										<div className={styles.group_container}>
											<DropdownList
												value={userGroups}
												placeholder="Groups"
												options={groupsOptions ?? []}
												className={classNames(styles.group_dropdown, styles.user_profile__drop_list)}
												onChange={(value) => {
													if (Array.isArray(value)) {
														groupsChangeHandler(value, setFieldValue);
													}
												}}
												customStyles={dropdownStyles}
												isMultiSelected={true}
											/>
											<Tooltip
												label={"Add this user to a Group by clicking on that Group in the list"}
												className={styles.group_tooltip}
											>
												<InfoIcon className={styles.info_icon} />
											</Tooltip>
										</div>
									</div>
								)}
								{!isOtherUserProfile && (
									<div className={styles.user_profile__tile}>
										<p className={styles.user_profile__tile_label}>Password</p>
										<div className={styles.user_profile__content_box}>
											<Button
												label="Reset password"
												onClick={() => openConfirmationPopup()}
												size="small"
												type="outlined"
												className={styles.user_profile__password_button}
											/>
											<p className={styles.user_profile__password_message}>{resetPasswordMessage}</p>
										</div>
									</div>
								)}
								<div className={styles.user_profile__save_button_container}>
									<Button
										label={isNewUser ? "Add user" : "Save"}
										onClick={
											isCmsUpdateUser && showCompany.isShow && !isEqual(values.company, userInfo.company)
												? () => {
														CompanyChangeMethodPopup(validateForm, values, setFieldValue, setErrors);
												  }
												: () => saveProfileHandler(validateForm, values, setFieldValue, setErrors)
										}
										size="common"
										className={styles.user_profile__save_button}
									/>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</LoadingIndicator>
		</ProfileLayout>
	);
};

export default observer(UserProfilePage);
