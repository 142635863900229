import { QuizResultsApi } from "api/controllers/QuizResultsApi";
import { downloadFile } from "helpers/file-download.helper";

export class QuizResultsStore {
	private _isLoading: boolean = false;
	get isLoading(): boolean {
		return this._isLoading;
	}

	async exportShortQuizResults(courseId: number) {
		this._isLoading = true;
		try {
			await QuizResultsApi.exportQuizResults(courseId).then((response) => {
				downloadFile(response);
			});
		} finally {
			this._isLoading = false;
		}
	}

	async exportCourseCompletion() {
		this._isLoading = true;
		try {
			await QuizResultsApi.exportCourseCompletion().then((response) => {
				downloadFile(response);
			});
		} finally {
			this._isLoading = false;
		}
	}

	async exportZ1Score(courseId: number) {
		this._isLoading = true;
		try {
			await QuizResultsApi.exportZ1Score(courseId).then((response) => {
				downloadFile(response);
			});
		} finally {
			this._isLoading = false;
		}
	}

	async exportScormQuizResults() {
		this._isLoading = true;
		try {
			await QuizResultsApi.exportScormQuizResults().then((response) => {
				downloadFile(response);
			});
		} finally {
			this._isLoading = false;
		}
	}
}
