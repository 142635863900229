import { IQuestionModel } from "models/dto/ZoomiLxp/Models/Assessments/IQuestionModel";
import { IFileModel } from "models/dto/ZoomiLxp/Models/Common/IFileModel";
import { IAuthorModel } from "models/dto/ZoomiLxp/Models/Common/IAuthorModel";
import { QuestionTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/QuestionTypes";
import { IAssessmentModel } from "models/dto/ZoomiLxp/Models/Assessments/IAssessmentModel";
import { IAccountModel } from "models/dto/ZoomiLxp/Models/Authentication/IAccountModel";
import { ShapeTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ShapeTypes";
import { ICreateCompletionMessageModel } from "models/dto/ZoomiLxp/Models/Assessments/ICreateCompletionMessageModel";
import { ShowRightAnswersOptions } from "models/dto/ZoomiLxp/Utilities/Enumerations/ShowRightAnswersOptions";

export const emptyCompletionMessage: ICreateCompletionMessageModel = {
	text: "",
	percent: 0,
};

export const emptyQuestion: IQuestionModel = {
	answerOptions: [],
	author: {
		id: 0,
		firstName: "",
		lastName: "",
		userPicture: {
			id: 0,
			url: "",
		} as IFileModel,
	} as IAuthorModel,
	created: Date.now(),
	id: 0,
	name: "",
	questionType: QuestionTypes.Choice,
	allowedRetriesCount: 1,
	rightAnswerNumber: 0,
	weight: 0,
	ratingScale: 5,
	ratingShape: ShapeTypes.Star,
	ratingShapeColor: "#e50914",
	surveyIsMulti: true,
};

export const initQuiz: IAssessmentModel = {
	isActive: true,
	name: "",
	passPercent: 0,
	description: "",
	required: false,
	allowedSingleAttempt: false,
	showRightAnswers: ShowRightAnswersOptions.NeverShow,
	backgroundColor: "#FFFFFF",
	backgroundImage: {
		id: 0,
		url: "",
	},
	questionTextTheme: {
		size: "16px",
		color: "#151515",
		bold: false,
		italic: false,
		underline: false,
	},
	answerTextTheme: {
		size: "16px",
		color: "#151515",
		bold: false,
		italic: false,
		underline: false,
	},
	id: 0,
	created: Date.now(),
	author: {
		id: 0,
		username: "",
		email: "",
		firstName: "",
		lastName: "",
	} as IAccountModel,
	courseId: 0,
	chapterId: 0,
	questions: [emptyQuestion],
	completionMessages: [],
	messageForWrongAnswer: "Incorrect. Please try again.",
	messageForCorrectAnswer: "Correct! Great job!",
	attempted: false,
};

export type Theme = "light" | "dark";
