import { makeAutoObservable, runInAction } from "mobx";
import { InfluencersApi } from "api/controllers/InfluencersApi";
import { HistoryApi } from "api/controllers/HistoryApi";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import { AppStore } from "store";
import { EventApi } from "api/controllers/EventApi";
import { ISaveEventAccountHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventAccountHistoryRequestModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { initialCarouselPagingParams } from "helpers/params.helper";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { CarouselDataType } from "components/partial/carousels-array/carousel-data-provider";
import { defaultCarouselRecordsParams, getParams, getUpdatedParams } from "helpers/carousel.helper";

export class InfluencersStore {
	private _isLoading: boolean = false;
	private rootStore;
	mostLiked: IInfluencerDataModel[] = [];
	mostFollowed: IInfluencerDataModel[] = [];
	shouldBeFollow: IInfluencerDataModel[] = [];
	myInfluencers: IInfluencerDataModel[] = [];
	moversAndShakers: IInfluencerDataModel[] = [];
	findedInfluencers: IInfluencerDataModel[] = [];

	private _loadingGroup: {
		[key: string]: boolean;
	} = {
		mostLiked: false,
		mostFollowed: false,
		shouldBeFollow: false,
		myInfluencers: false,
		moversAndShakers: false,
	};

	private _influencersCarouselsPagingParams: {
		[key: string]: IGetRecordsResponse<CarouselDataType>;
	} = {
		mostLiked: defaultCarouselRecordsParams,
		mostFollowed: defaultCarouselRecordsParams,
		shouldBeFollow: defaultCarouselRecordsParams,
		myInfluencers: defaultCarouselRecordsParams,
		moversAndShakers: defaultCarouselRecordsParams,
	};

	constructor(rootStore: AppStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);
	}

	async getInfluencerById(id: number) {
		this.isLoading = true;
		try {
			const response = await InfluencersApi.getInfluencerById(id);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getAllInfluencers(keepStateAfterEvent?: boolean) {
		this.isLoading = true;
		try {
			await Promise.allSettled([
				this.getMostLikedInfluencers(initialCarouselPagingParams, keepStateAfterEvent),
				this.getMostFollowedInfluencers(initialCarouselPagingParams, keepStateAfterEvent),
				this.getMoverShakersOfLastWeek(initialCarouselPagingParams, keepStateAfterEvent),
				this.getRecommendedInfluencers(initialCarouselPagingParams, keepStateAfterEvent),
				this.getFollowingInfluencers(initialCarouselPagingParams, keepStateAfterEvent),
			]);
		} finally {
			this.isLoading = false;
		}
	}

	async getAllInfluencersByQuery(queryParams: IQueryParams) {
		this.isLoading = true;
		try {
			const response = await InfluencersApi.getAllInfluencers(queryParams);
			runInAction(() => (this.findedInfluencers = response.data.data.records));
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getFollowingInfluencers(data?: IPagingParams, keepStateAfterEvent?: boolean) {
		runInAction(() => (this._loadingGroup = { ...this._loadingGroup, myInfluencers: !keepStateAfterEvent }));

		try {
			const followingInfluencersResponse = await HistoryApi.getFollowingInfluencers(
				data ?? initialCarouselPagingParams
			);
			const newParams = getParams(followingInfluencersResponse.data.data);
			const oldParams = this._influencersCarouselsPagingParams.myInfluencers;

			runInAction(() => {
				this.rootStore.historyStore.followingInfluencers = followingInfluencersResponse.data.data.records;
				this.myInfluencers = followingInfluencersResponse.data.data.records;
				this._influencersCarouselsPagingParams = {
					...this._influencersCarouselsPagingParams,
					myInfluencers: keepStateAfterEvent ? newParams : getUpdatedParams(newParams, oldParams),
				};
			});
			return followingInfluencersResponse.data.data;
		} finally {
			runInAction(() => (this._loadingGroup = { ...this._loadingGroup, myInfluencers: false }));
		}
	}

	async getMostLikedInfluencers(data?: IPagingParams, keepStateAfterEvent?: boolean) {
		runInAction(() => (this._loadingGroup = { ...this._loadingGroup, mostLiked: !keepStateAfterEvent }));
		try {
			const mostLikedResponse = await InfluencersApi.getMostLikedInfluencers(data ?? initialCarouselPagingParams);
			const newParams = getParams(mostLikedResponse.data.data);
			const oldParams = this._influencersCarouselsPagingParams.mostLiked;
			runInAction(() => {
				this.mostLiked = mostLikedResponse.data.data.records;
				this._influencersCarouselsPagingParams = {
					...this._influencersCarouselsPagingParams,
					mostLiked: keepStateAfterEvent ? newParams : getUpdatedParams(newParams, oldParams),
				};
			});
			return mostLikedResponse.data.data;
		} finally {
			runInAction(() => (this._loadingGroup = { ...this._loadingGroup, mostLiked: false }));
		}
	}

	async getMostFollowedInfluencers(data?: IPagingParams, keepStateAfterEvent?: boolean) {
		runInAction(() => (this._loadingGroup = { ...this._loadingGroup, mostFollowed: !keepStateAfterEvent }));
		try {
			const mostFollowedResponse = await InfluencersApi.getMostFollowedInfluencers(data ?? initialCarouselPagingParams);
			const newParams = getParams(mostFollowedResponse.data.data);
			const oldParams = this._influencersCarouselsPagingParams.mostFollowed;
			runInAction(() => {
				this.mostFollowed = mostFollowedResponse.data.data.records;
				this._influencersCarouselsPagingParams = {
					...this._influencersCarouselsPagingParams,
					mostFollowed: keepStateAfterEvent ? newParams : getUpdatedParams(newParams, oldParams),
				};
			});
			return mostFollowedResponse.data.data;
		} finally {
			runInAction(() => (this._loadingGroup = { ...this._loadingGroup, mostFollowed: false }));
		}
	}

	async getMoverShakersOfLastWeek(data?: IPagingParams, keepStateAfterEvent?: boolean) {
		runInAction(() => (this._loadingGroup = { ...this._loadingGroup, moversAndShakers: !keepStateAfterEvent }));
		try {
			const moversAndShakersResponse = await InfluencersApi.getMoverShakersOfLastWeek(
				data ?? initialCarouselPagingParams
			);
			const newParams = getParams(moversAndShakersResponse.data.data);
			const oldParams = this._influencersCarouselsPagingParams.moversAndShakers;
			runInAction(() => {
				this.moversAndShakers = moversAndShakersResponse.data.data.records;
				this._influencersCarouselsPagingParams = {
					...this._influencersCarouselsPagingParams,
					moversAndShakers: keepStateAfterEvent ? newParams : getUpdatedParams(newParams, oldParams),
				};
			});
			return moversAndShakersResponse.data.data;
		} finally {
			runInAction(() => (this._loadingGroup = { ...this._loadingGroup, moversAndShakers: false }));
		}
	}

	async getRecommendedInfluencers(data?: IPagingParams, keepStateAfterEvent?: boolean) {
		runInAction(() => (this._loadingGroup = { ...this._loadingGroup, shouldBeFollow: !keepStateAfterEvent }));
		try {
			const recommendedResponse = await InfluencersApi.getRecommendedInfluencers(data ?? initialCarouselPagingParams);
			const newParams = getParams(recommendedResponse.data.data);
			const oldParams = this._influencersCarouselsPagingParams.shouldBeFollow;
			runInAction(() => {
				this.shouldBeFollow = recommendedResponse.data.data.records;
				this._influencersCarouselsPagingParams = {
					...this._influencersCarouselsPagingParams,
					shouldBeFollow: keepStateAfterEvent ? newParams : getUpdatedParams(newParams, oldParams),
				};
			});
			return recommendedResponse.data.data;
		} finally {
			runInAction(() => (this._loadingGroup = { ...this._loadingGroup, shouldBeFollow: false }));
		}
	}

	sendInfluencerEvent = async (data: ISaveEventAccountHistoryRequestModel, disableDataRefreshing?: boolean) => {
		try {
			await EventApi.sendInfluencerEvent(data);
			if (!disableDataRefreshing) {
				await this.getAllInfluencers(true);
			}
		} catch (e) {
			console.log(e);
		}
	};

	async getCoursesByInfluencerId(id: number, pagingParams: IPagingParams) {
		try {
			const response = await InfluencersApi.getCoursesByInfluencerId(id, pagingParams);
			return response.data.data;
		} catch (err) {
			throw err;
		}
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get loadingGroup(): {
		[key: string]: boolean;
	} {
		return this._loadingGroup;
	}

	get isLoading() {
		return this._isLoading;
	}

	get influencersCarouselsPagingParams() {
		return this._influencersCarouselsPagingParams;
	}

	clearStore() {
		runInAction(() => {
			this.isLoading = false;
			this.mostLiked = [];
			this.mostFollowed = [];
			this.shouldBeFollow = [];
			this.myInfluencers = [];
			this.moversAndShakers = [];
			this.findedInfluencers = [];
			this._loadingGroup = {
				mostLiked: false,
				mostFollowed: false,
				shouldBeFollow: false,
				myInfluencers: false,
				moversAndShakers: false,
			};
			this._influencersCarouselsPagingParams = {
				mostLiked: defaultCarouselRecordsParams,
				mostFollowed: defaultCarouselRecordsParams,
				shouldBeFollow: defaultCarouselRecordsParams,
				myInfluencers: defaultCarouselRecordsParams,
				moversAndShakers: defaultCarouselRecordsParams,
			};
		});
	}
}
