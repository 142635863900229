import { useAppStore } from "../store";
import isNil from "lodash/isNil";

export const useShowChapters = () => {
	const { coursesStore } = useAppStore();

	const isShowChapters =
		!isNil(coursesStore.activeChaptersGroup) &&
		!(coursesStore.activeChaptersGroup?.length === 1 && coursesStore.activeChaptersGroup[0].name === "Out of Chapter");

	return { isShowChapters };
};
