import PrivateLayout from "layouts/private/private";
import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import { Routes } from "routes";
import { NavLink } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef } from "react";
import PeerTile, { TilePlacement } from "components/partial/peers/peer-tile/peer-tile";
import classNames from "classnames";
import { useAppStore } from "store";
import { mobileCheck, tabletCheck } from "helpers/device.helper";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import isEmpty from "lodash/isEmpty";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import { SortDirection } from "models/dto/ZoomiLxp/Utilities/Enumerations/SortDirection";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import CarouselsArray from "components/partial/carousels-array/carousels-array";
import { getOrderedCarousels } from "helpers/settings.helper";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { initialCarouselPagingParams } from "helpers/params.helper";
import Banner from "components/base/banner/banner";
import { PermissionConstantsCreate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsCreate";
import AccessWrapper from "components/partial/access-wrapper/access-wrapper";

const cx = classNames.bind(styles);

export default observer(function PeersHomePage() {
	const { peersStore, settingsStore, toasterStore } = useAppStore();

	const params = useRef<IQueryParams>({
		skip: 0,
		take: 20,
		filterCriteria: [
			{
				propertyNames: ["isActive"],
				function: FilterFunction.IsTrue,
				argument: true,
			},
			{
				propertyNames: ["CourseItemId"],
				function: FilterFunction.IsNull,
				argument: null,
			},
		],
		sortCriteria: [
			{
				order: 1,
				propertyName: "createdUTC",
				direction: SortDirection.Descending,
			},
		],
		queryString: "",
	});

	const getData = useCallback(async () => {
		return await peersStore.getAll(params.current);
	}, [peersStore]);

	const { items, hasMore, fetchMoreData } = useInfiniteScroll<IThreadModel>(getData, params);

	useEffect(() => {
		peersStore.getThreadsByGroups(initialCarouselPagingParams.take).catch((err) => {
			toasterStore.showErrorMessage(err);
		});

		return peersStore.clearStore();
	}, [toasterStore, peersStore]);

	const isMobile = useMemo(() => mobileCheck(), []);
	const isTablet = useMemo(() => tabletCheck, []);
	const sortedPeersCarousels = getOrderedCarousels(settingsStore.publicSettings, "Peers.Carousels.Order");
	const isAdaptive = isMobile || isTablet;
	const [horisontal, vertical] = useMemo(
		() => [
			isAdaptive ? TilePlacement.default : TilePlacement.horizontal,
			isAdaptive ? TilePlacement.default : TilePlacement.vertical,
		],
		[isAdaptive]
	);
	let counter: number;

	return (
		<PrivateLayout className={styles.peers}>
			<div className={styles.peers__box}>
				<Banner
					className={styles.peers_banner}
					bannerContentType={settingsStore.publicSettings.Images?.[SiteSettingsKeys.PeersBannerContentType]}
					bannerSrc={settingsStore.publicSettings.Images?.[SiteSettingsKeys.PeersBanner]}
				/>

				<AccessWrapper permissions={[PermissionConstantsCreate.Threads]}>
					<div className={styles.peers__inner}>
						<NavLink className={styles.btn_add} to={Routes.CmsAddThread}>
							Create new thread
						</NavLink>
					</div>
				</AccessWrapper>
			</div>
			<LoadingIndicator loading={peersStore.isLoading} spinnerPosition={"center"} backgroundStyle={"blur"}>
				<div className={styles.peers__page}>
					<CarouselsArray carouselsKeys={sortedPeersCarousels} />
				</div>
				<section className={cx(styles.peers__section_grid, styles.peers__section_second)}>
					{!isEmpty(items) && (
						<InfiniteScroll
							dataLength={items.length}
							next={fetchMoreData}
							hasMore={hasMore}
							loader={<h4>Loading...</h4>}
						>
							<div className={styles.peers__grid}>
								{items.map((item, index) => {
									if (index === 0 || index % 10 === 0) counter = 0;
									counter++;
									if (counter === 1) {
										return (
											<PeerTile
												threadInfo={item}
												key={item.id}
												tilePlacement={horisontal}
												className={cx({ [styles.peers__second_horiz]: !isAdaptive })}
											/>
										);
									} else if (counter === 2) {
										return (
											<PeerTile
												threadInfo={item}
												key={item.id}
												tilePlacement={vertical}
												className={cx({ [styles.peers__second_vert]: !isAdaptive })}
											/>
										);
									} else return <PeerTile threadInfo={item} key={item.id} className={styles.peers__tile} />;
								})}
							</div>
						</InfiniteScroll>
					)}
				</section>
			</LoadingIndicator>
		</PrivateLayout>
	);
});
