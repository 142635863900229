import React, { useState } from "react";
import { generatePath, NavLink, useHistory } from "react-router-dom";

import * as qs from "querystringify";
import classNames from "classnames";
import isNil from "lodash/isNil";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { ISaveEventCourseHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseHistoryRequestModel";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";

import styles from "./styles.module.scss";
import { Routes } from "routes";
import { useAppStore } from "store";
import ButtonBookmark from "components/partial/button-bookmark/button-bookmark";
import ButtonLike from "components/partial/button-like/button-like";
import StarRating from "components/partial/star-rating/star-rating";
import { getPicture } from "helpers/image.helper";
import { DEFAULT_COLOR } from "constants/colors";
import { getIconContentTypes } from "helpers/icons.helper";
import ZStatsBlock from "../z-stats-block/z-stats-block";
import { ContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentTypes";
import { TilePlacement } from "../peers/peer-tile/peer-tile";
import ViewsCounter from "../views-counter/views-counter";

interface Props {
	courseInfo: IPreviewCourseModel;
	tileStyle?: "default" | "light";
	className?: string;
	disableRating?: boolean;
	tilePlacement?: TilePlacement;
	isHilightDisabled?: boolean;
	onClick?: () => void;
	disableTitleForward?: boolean;
	openInNewTab?: boolean;
	courseTileKey: string;
}

const getPrice = (coursePrice?: number) => {
	if (coursePrice) {
		const price = coursePrice / 100;
		return coursePrice % 100 === 0 ? `$${price}` : `$${price.toFixed(2)}`;
	}
	return "Free";
};

const CourseTile = ({
	courseInfo,
	tileStyle = TilePlacement.default,
	className,
	disableRating = false,
	tilePlacement = TilePlacement.default,
	onClick,
	isHilightDisabled = true,
	disableTitleForward = false,
	openInNewTab,
	courseTileKey,
}: Props) => {
	const [rating, setRating] = useState<number>(courseInfo.rating);
	const { coursesStore, contentStore } = useAppStore();
	const [isBookmarked, setIsBookmarked] = useState<boolean>(() => courseInfo.isBookmarked);
	const isStringSubject = typeof courseInfo.subject === "string";
	const history = useHistory();
	const price = getPrice(courseInfo.price);
	const isLight = tileStyle === "light";

	const handleBookmark = async (isMarked: boolean) => {
		const bookmarkCourseEvent: ISaveEventCourseHistoryRequestModel = {
			courseId: courseInfo.id,
			eventType: isMarked ? EventTypes.CourseBookmarked : EventTypes.CourseUnbookmarked,
			payload: "",
		};
		await coursesStore.sendCourseEvent(bookmarkCourseEvent).then(() => {
			coursesStore.getBookmarkedCourses(undefined, true);
		});
	};

	const getIconStyles = (isNestedIcon?: boolean) =>
		isNestedIcon
			? classNames(styles.nested_icon, {
					[styles.nested_icon_dark]: isLight,
			  })
			: classNames(styles.course_tile__type_icon, {
					[styles.course_tile__type_icon__dark]: isLight,
			  });

	const getCourseIconStyles = () =>
		classNames(styles.course_tile__type_icon, styles.course_tile__course_icon, {
			[styles.course_tile__type_icon__dark]: isLight,
			[styles.course_tile__course_icon__dark]: isLight,
		});

	const getIcon = getIconContentTypes(getIconStyles, getCourseIconStyles);

	const generateCoursePath = (): string => {
		let path =
			courseInfo.contentType !== ContentTypes.Course
				? generatePath(Routes.MyDeskLesson, { courseId: courseInfo.id })
				: generatePath(Routes.CoursePage, { id: courseInfo.id });
		path += qs.stringify(
			{
				ref: courseTileKey,
				refCourseId: coursesStore.curActiveContent.courseId,
				refCourseItemId: coursesStore.curActiveContent.courseItemId,
			},
			true
		);
		return path;
	};

	const goToCourse = () => {
		const path = generateCoursePath();
		contentStore.sessionId = undefined;
		openInNewTab ? window.open(path, "_blank", "noopener, noreferrer") : history.push(path);
	};

	const onClickHandler = () => {
		onClick ? onClick() : goToCourse();
	};

	return (
		<div
			className={classNames(
				styles.course_tile,
				{ [styles.course_tile__light]: isLight },
				className,
				styles[`course_tile__${tilePlacement}`],
				{ [styles.course_tile__not_active]: !isHilightDisabled }
			)}
		>
			<div className={classNames(styles.course_tile__header, styles[`course_tile__header__${tilePlacement}`])}>
				<div className={styles.course_tile__duration_block}>
					{getIcon(courseInfo.contentType)}
					<span className={styles.course_tile__duration}>{courseInfo.durationMinutes} min</span>
				</div>
				<div className={styles.course_tile__rating_block}>
					<StarRating
						className={styles.course_tile__rating}
						value={rating}
						isDisabled={disableRating}
						onRatingChange={(newRating) => {
							setRating(newRating);
						}}
						darkBorder={isLight}
					/>
					<ButtonBookmark
						className={styles.course_tile__bookmark}
						bookmarked={isBookmarked}
						isBookmarkable={true}
						isSecondaryType={isLight}
						onClick={() => {
							setIsBookmarked(!isBookmarked);
							handleBookmark(!isBookmarked).catch((err) => console.log(err));
						}}
					/>
				</div>
			</div>
			<div className={classNames(styles.course_tile__content, styles[`course_tile__content__${tilePlacement}`])}>
				<img
					src={getPicture(courseInfo)}
					alt="Thumbnail"
					className={classNames(
						styles.course_tile__content__thumbnail,
						styles[`course_tile__content__thumbnail__${tilePlacement}`],
						{
							[styles.no_image]: isNil(courseInfo.thumbnail),
						}
					)}
					onClick={() => onClickHandler()}
				/>
			</div>
			<div
				className={classNames(
					styles.course_tile__info_container,
					styles[`course_tile__info_container__${tilePlacement}`]
				)}
			>
				<div
					className={classNames(
						styles.course_tile__lesson_name_container,
						styles[`course_tile__lesson_name_container__${tilePlacement}`],
						{ [styles.default_cursor]: disableTitleForward }
					)}
				>
					<NavLink
						className={classNames(
							styles.course_tile__lesson_name,
							styles[`course_tile__lesson_name__${tilePlacement}`],
							{
								[styles.course_tile__lesson_name__dark]: isLight,
								[styles.default_cursor]: disableTitleForward,
							}
						)}
						to={generateCoursePath()}
						onClick={(e) => {
							if (!disableTitleForward) {
								e.preventDefault();
								onClickHandler();
							}
						}}
						rel="noopener, noreferrer"
						target={openInNewTab ? "_blank" : "_self"}
					>
						{courseInfo.title}
					</NavLink>

					{tilePlacement === TilePlacement.vertical && (
						<div className={classNames(styles.course_tile__wrap, styles[`course_tile__wrap__${tilePlacement}`])}>
							<div
								style={{ backgroundColor: courseInfo.categoryColor ?? DEFAULT_COLOR }}
								className={classNames(styles.course_tile__color_bar)}
							/>
							<div className={styles.course_tile__stats_section}>
								<div className={styles.course_tile__stats_left_block}>
									<div className={styles.course_tile__likes}>
										<ButtonLike
											isLikable={false}
											isActive={true}
											isSecondaryType={false}
											className={styles.course_tile__stats_icon}
										/>
										{courseInfo.likesCount}
									</div>
									<ViewsCounter
										courseInfo={courseInfo}
										className={styles.course_tile__views}
										iconClassName={styles.course_tile__stats_icon}
									/>
								</div>

								<ZStatsBlock course={courseInfo} />
							</div>
							<div className={styles.course_tile__content_info_section}>
								<div className={styles.course_tile__info_box}>
									<p className={styles.course_tile__content_info}>
										<b>Source · </b> {courseInfo!.source}
									</p>

									<p className={styles.course_tile__content_info}>
										<b>Subject · </b> {isStringSubject ? courseInfo!.subject : "none"}
									</p>

									<p className={styles.course_tile__content_info}>
										<b>Course · </b> {courseInfo!.courseTitle ?? "none"}
									</p>
								</div>
								{!courseInfo.isPaid && (
									<div className={styles.course_tile__price_box}>
										<span className={styles.course_tile__price_title}>Price:</span>
										{price}
									</div>
								)}
							</div>
						</div>
					)}
					{tilePlacement !== TilePlacement.horizontal && (
						<div className={classNames(styles.course_tile__desc, styles[`course_tile__desc__${tilePlacement}`])}>
							{courseInfo.description}
						</div>
					)}
				</div>
				{tilePlacement !== TilePlacement.vertical && (
					<div className={classNames(styles.course_tile__wrap, styles[`course_tile__wrap__${tilePlacement}`])}>
						<div
							style={{ backgroundColor: courseInfo.categoryColor ?? DEFAULT_COLOR }}
							className={styles.course_tile__color_bar}
						/>
						<div className={styles.course_tile__stats_section}>
							<div className={styles.course_tile__stats_left_block}>
								<div className={styles.course_tile__likes}>
									<ButtonLike
										isLikable={false}
										isActive={true}
										isSecondaryType={false}
										className={styles.course_tile__stats_icon}
									/>
									{courseInfo.likesCount}
								</div>
								<ViewsCounter
									courseInfo={courseInfo}
									className={styles.course_tile__views}
									iconClassName={styles.course_tile__stats_icon}
								/>
							</div>

							<ZStatsBlock course={courseInfo} />
						</div>
						<div className={styles.course_tile__content_info_section}>
							<div className={styles.course_tile__info_box}>
								<p className={styles.course_tile__content_info}>
									<b>Source · </b> {courseInfo!.source}
								</p>

								<p className={styles.course_tile__content_info}>
									<b>Subject · </b> {isStringSubject ? courseInfo!.subject : "none"}
								</p>

								<p className={styles.course_tile__content_info}>
									<b>Course · </b> {courseInfo!.courseTitle ?? "none"}
								</p>
							</div>
							{!courseInfo.isPaid && (
								<div className={styles.course_tile__price_box}>
									<span className={styles.course_tile__price_title}>Price:</span>
									{price}
								</div>
							)}
						</div>
					</div>
				)}
			</div>
			{tilePlacement === TilePlacement.horizontal && (
				<div className={classNames(styles.course_tile__desc, styles[`course_tile__desc__${tilePlacement}`])}>
					{courseInfo.description}
				</div>
			)}
		</div>
	);
};
export default React.memo(CourseTile);
