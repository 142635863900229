import ProfilePurchases from "components/partial/myDesk/profile-purchases/profile-purchases";
import { useRouteUserId } from "hooks/useRouteUserId";
import MyAccountLayout from "layouts/my-account/my-account-layout";
import ProfileLayout from "layouts/private/profile/profile-layout";

const ProfilePurchasesPage = () => {
	const { routeUserId } = useRouteUserId();
	return routeUserId ? (
		<ProfileLayout>
			<ProfilePurchases />
		</ProfileLayout>
	) : (
		<MyAccountLayout>
			<ProfilePurchases />
		</MyAccountLayout>
	);
};

export default ProfilePurchasesPage;
