import styles from "./styles.module.scss";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import { ReactComponent as CloseIcon } from "assets/icons/ic_close.svg";
import RecommendationsItem from "../recommendations-item/recommendations-item";
import uniqueId from "lodash/uniqueId";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";

interface RecommendationsListProps {
	className?: string;
}

const cx = classNames.bind(styles);

const RecommendationsList = ({ className }: RecommendationsListProps) => {
	const { recommendationsStore, contentStore } = useAppStore();
	const [recommendationsArray, setRecommendationsArray] = useState(recommendationsStore.items);

	const handleClose = async () => {
		recommendationsStore.isShowList = false;
		recommendationsStore.isShowNotice = false;
		if (contentStore.sessionId) await recommendationsStore.clearRecommendationsGroup(contentStore.sessionId);
	};

	useEffect(() => {
		setRecommendationsArray(recommendationsStore.items);
	}, [recommendationsStore.items]);

	if (isEmpty(recommendationsArray)) return <></>;

	return (
		<div
			className={cx(
				styles.recommendations_list,
				{ [styles.recommendations_list__show]: recommendationsStore.isShowList },
				className
			)}
		>
			<CloseIcon className={styles.recommendations_list__close_icon} onClick={handleClose} />
			<div className={styles.recommendations_list__wrap}>
				{recommendationsArray.map((item) => (
					<RecommendationsItem item={item} key={uniqueId(`recommendations-id-${item.title}`)} />
				))}
			</div>
		</div>
	);
};

export default observer(RecommendationsList);
