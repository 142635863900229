import Tooltip from "components/base/tooltip/tooltip";
import { FC } from "react";
import { EdgeLabelRenderer, getSmoothStepPath, SmoothStepEdgeProps, SmoothStepEdge } from "reactflow";
import styles from "../../styles.module.scss";

const CustomEdge: FC<SmoothStepEdgeProps> = ({
	id,
	source,
	sourceX,
	sourceY,
	target,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	markerEnd,
	data,
}) => {
	const [path, labelX, labelY] = getSmoothStepPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});

	const getLabelOffset = () => {
		if (data.isLoopStart?.top) {
			return "-120%";
		}
		if (data.isLoopStart?.bottom) {
			return "20%";
		}
		return "-50%";
	};

	if (!path) {
		return <></>;
	}

	return (
		<>
			<SmoothStepEdge
				id={id}
				source={source}
				sourcePosition={sourcePosition}
				sourceX={sourceX}
				sourceY={sourceY}
				target={target}
				targetPosition={targetPosition}
				targetX={targetX}
				targetY={targetY}
				markerEnd={markerEnd}
				style={{ strokeWidth: 4, stroke: "var(--text-tiles)" }}
				pathOptions={{ offset: 60 }}
			/>

			<EdgeLabelRenderer>
				<div
					style={{
						position: "absolute",
						transform: `translate(-50%, ${getLabelOffset()}) translate(${labelX}px,${labelY}px)`,
						background: "var(--background-tile)",
						color: "var(--text-tiles)",
						padding: 10,
						borderRadius: 5,
						fontSize: 30,
						pointerEvents: "all",
					}}
					className="nodrag nopan"
				>
					<Tooltip
						label={data?.label}
						className={styles.learning_path__tooltip}
						classNameContent={styles.learning_path__tooltip__content}
					>
						<div className={styles.learning_path__transition_label}>{data?.label}</div>
					</Tooltip>
				</div>
			</EdgeLabelRenderer>
		</>
	);
};

export default CustomEdge;
