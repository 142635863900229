import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Checkbox from "components/base/checkbox/checkbox";
import { useAppStore } from "store";
import { observer } from "mobx-react";

interface CmsCheckboxProps {
	id: number;
	className?: string;
}

const cx = classNames.bind(styles);

const CmsCheckbox = (props: CmsCheckboxProps) => {
	const { id, className } = props;
	const { bulkOperationsStore } = useAppStore();

	return (
		<div className={cx(styles.cms_checkbox, className)}>
			<Checkbox
				type={"checkbox"}
				onChange={(value) => bulkOperationsStore.setId(id, value)}
				value={bulkOperationsStore.getId(id)}
				theme={"cms"}
			/>
		</div>
	);
};

export default observer(CmsCheckbox);
