//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AlertItemTypes {
	Course = 0,
	CourseItem = 1,
	Thread = 2,
	Influencer = 3,
	Segment = 4
}
