import ReactFlow, { Controls, useNodesState, useEdgesState, ConnectionLineType, Node, Edge } from "reactflow";
import CourseFlowNode from "./course-flow-node";
import "reactflow/dist/style.css";
import { useAppStore } from "store";
import { ILearningPathItem } from "models/dto/ZoomiLxp/Models/Analytics/ILearningPathItem";
import { ITransitionSourceModel } from "models/dto/ZoomiLxp/Models/Analytics/ITransitionSourceModel";
import { observer } from "mobx-react";
import {
	BCKWRD_BTTM_SRC_ID,
	BCKWRD_TOP_SRC_ID,
	checkIsBackwardItem,
	checkIsSameLine,
	getAnchorPosition,
	getBackwardItem,
	getLastNodesCoords,
	getSourceHandlesId,
	getTargetHandlesId,
	defaultEdgeProps,
	IMyLearningPathPaging,
	getPhantomNodeData,
} from "helpers/learning-path.helper";
import CustomEdge from "./custom-edge";
import { useEffect, useMemo } from "react";

interface IMyLearningFlowProps {
	pathItems: ILearningPathItem[];
	pagingParams?: IMyLearningPathPaging;
}

const nodeTypes = {
	custom: CourseFlowNode,
};

const edgeTypes = {
	custom: CustomEdge,
};

const MyLearningFlow = ({ pathItems, pagingParams }: IMyLearningFlowProps) => {
	const { analyticsStore } = useAppStore();
	const phantomNodeData = getPhantomNodeData(pathItems, pagingParams);
	const getTransitionLabel = (pathItem: ILearningPathItem) => {
		const label = analyticsStore.transitionSources.find(
			(source: ITransitionSourceModel) => source.id === pathItem.transitionSourceId
		);

		return label?.name ?? analyticsStore.transitionSources[0].name;
	};

	const coursesNodes: Node[] = pathItems.map((item: ILearningPathItem, index) => {
		return {
			id: String(item.toSessionId),
			position: { x: index * 700, y: item.row === 1 ? 0 : 300 },
			data: item,
			type: "custom",
			sourcePosition: getAnchorPosition(item, true),
			targetPosition: getAnchorPosition(item, false),
		};
	});
	const backwardItems: ILearningPathItem[] = [];

	const coursesEdges: Edge[] = pathItems.map((item: ILearningPathItem, _, initArr: ILearningPathItem[]) => {
		const backwardItem = getBackwardItem(item, initArr);

		if (backwardItem) {
			backwardItems.push(backwardItem);
		}
		const isCurrentBackward = checkIsBackwardItem(item, initArr);
		const { isOnSameLine, prevItem } = checkIsSameLine(item, initArr);

		const getSourcePosition = () => {
			if (isCurrentBackward && !isOnSameLine) {
				return prevItem?.row === 1 ? BCKWRD_BTTM_SRC_ID : BCKWRD_TOP_SRC_ID;
			}

			if (prevItem?.backwardTo && !isOnSameLine) {
				return prevItem.row === 1 ? BCKWRD_BTTM_SRC_ID : BCKWRD_TOP_SRC_ID;
			}

			return getSourceHandlesId(backwardItem);
		};

		const isLoopStart = { top: item.row === 1 && !!item.backwardTo, bottom: item.row === 2 && !!item.backwardTo };
		const edge: Edge = {
			...defaultEdgeProps,
			id: `e${item.toSessionId}`,
			source: item.backwardTo ? String(item.toSessionId) : String(item.fromSessionId),
			target: item.backwardTo ? String(item.backwardTo) : String(item.toSessionId),
			data: { label: getTransitionLabel(item), isLoopStart },
			sourceHandle: getSourcePosition(),
			targetHandle: getTargetHandlesId(backwardItem),
			type: "custom",
		};

		return edge;
	});

	const rowsLastItems = pathItems.filter((item, idx, arr) => item.row !== arr[idx + 1]?.row);

	const rowsLastItemsEdges = rowsLastItems.map((item, index, arr) => {
		const isLastArrayItem = index === arr.length - 1;
		const { prevItem } = checkIsSameLine(item, pathItems);
		const isPrevOnSameLine = isLastArrayItem && item.row === prevItem?.row;

		return {
			...defaultEdgeProps,
			id: `${item.toSessionId}-last`,
			source: isLastArrayItem && !isPrevOnSameLine ? "" : String(item.fromSessionId),
			target: String(item.toSessionId),
			data: { label: getTransitionLabel(item) },
			itemData: item,
			type: "custom",
		};
	});

	const updatedEdges = useMemo(() => [...coursesEdges, ...rowsLastItemsEdges], [coursesEdges, rowsLastItemsEdges]);
	if (coursesNodes.length) {
		coursesNodes.unshift(phantomNodeData.phantomNode);
		phantomNodeData.getPhantomEdges()?.forEach((edge) => updatedEdges.unshift(edge));
	}

	const [nodes, setNodes] = useNodesState(coursesNodes);
	const [edges, setEdges] = useEdgesState(updatedEdges);

	useEffect(() => {
		if (!pagingParams?.hasMore) {
			coursesNodes.shift();
		}
		setNodes(coursesNodes);
		setEdges(updatedEdges);

		// eslint-disable-next-line
	}, [pathItems, pagingParams?.hasMore]);

	return (
		<ReactFlow
			nodes={nodes}
			edges={edges}
			defaultEdgeOptions={{ type: "smoothstep" }}
			connectionLineType={ConnectionLineType.SmoothStep}
			nodeTypes={nodeTypes}
			edgeTypes={edgeTypes}
			defaultViewport={getLastNodesCoords(nodes)}
			nodesConnectable={false}
			nodesDraggable={false}
			minZoom={0.1}
		>
			<Controls showInteractive={false} />
		</ReactFlow>
	);
};

export default observer(MyLearningFlow);
