import { useState } from "react";
import styles from "../styles.module.scss";
import classNames from "classnames";
import Select, { SingleValue, StylesConfig } from "react-select";
import update from "immutability-helper";
import { IOptionsItem, stylesSelect } from "helpers/select.helper";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import { IFilterCriterion } from "models/dto/ZoomiLxp/Models/Query/IFilterCriterion";
import { findIndex } from "lodash";

export interface Props {
	params: IQueryParams;
	propertyName: string;
	setParams: (value: ((prevState: IQueryParams) => IQueryParams) | IQueryParams) => void;
	label: string;
	className?: string;
	options: IOptionsItem[] | { value: string; label: string }[];
	isStringOptions?: boolean;
	useInFilterFunction?: boolean;
	customStyles?: StylesConfig<IOptionsItem>;
	containerClassName?: string;
}

const SelectFilter = (props: Props) => {
	const {
		label,
		className,
		params,
		setParams,
		propertyName,
		options,
		isStringOptions,
		useInFilterFunction,
		customStyles,
		containerClassName,
	} = props;
	const [isOpen, setIsOpen] = useState(false);
	const [selectedValue, setSelectedValue] = useState(null as any);

	const onSelect = (item: SingleValue<{ value: string; label: string }>) => {
		const idx = findIndex(params.filterCriteria, { propertyNames: [propertyName] });

		if (idx < 0) {
			const newParams = update<IQueryParams>(params, {
				filterCriteria: {
					$push: [
						{
							propertyNames: [propertyName],
							argument: isStringOptions ? item?.label : item?.value,
							function: useInFilterFunction ? FilterFunction.In : FilterFunction.Equal,
						} as IFilterCriterion,
					],
				},
				skip: { $set: 0 },
			});
			setParams(newParams);
		} else {
			const filterItem = params.filterCriteria[idx];
			if (filterItem.argument !== item?.value) {
				const updatedFilterItem = update(filterItem, {
					argument: { $set: isStringOptions ? item?.label : item?.value },
				});
				const newParams = update(params, {
					filterCriteria: {
						$splice: [[idx, 1, updatedFilterItem]],
					},
					skip: { $set: 0 },
				});
				setParams(newParams);
			}
		}
	};

	return (
		<div className={classNames(styles.filter_button, containerClassName)}>
			<Select
				options={options}
				className={classNames(styles.filter_button, className)}
				placeholder={isOpen ? "" : label}
				styles={customStyles ?? stylesSelect}
				isSearchable={true}
				onChange={(item) => {
					setSelectedValue(item);
					onSelect(item);
				}}
				onMenuOpen={() => setIsOpen(true)}
				onMenuClose={() => setIsOpen(false)}
				value={
					!!params.filterCriteria?.find((filter: IFilterCriterion) => filter.propertyNames.includes(propertyName))
						? isOpen
							? ""
							: { ...selectedValue, label: selectedValue?.label ? `${label}: ${selectedValue?.label}` : label }
						: label
				}
			/>
		</div>
	);
};

export default SelectFilter;
