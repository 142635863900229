import { Redirect, Route } from "react-router-dom";
import { useAppStore } from "store";
import { Routes } from "routes";
import { RouteProps } from "react-router";

interface RouteAccessProps extends RouteProps {
	permissions: string[];
	key?: Routes;
}

const RouteAccess = ({ permissions, key, ...otherProps }: RouteAccessProps) => {
	const { usersStore } = useAppStore();

	return (
		<>
			{usersStore.checkOneOfUserPermissions([...permissions]) ? (
				<Route {...otherProps} key={key} />
			) : (
				<Redirect to={Routes.NoAccess} />
			)}
		</>
	);
};

export default RouteAccess;
