import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useAppStore } from "store";
import { IChapterRef } from "models/dto/ZoomiLxp/Models/Courses/IChapterRef";
import ChapterTile from "../chapter-tile/chapter-tile";
import { useEffect, useRef, useState } from "react";
import Carousel from "components/partial/carousel/carousel";
import isNull from "lodash/isNull";
import first from "lodash/first";
import ChaptersRoster from "../chapters-roster/chapters-roster";
import find from "lodash/find";
import { ICourseItemModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseItemModel";
import ProgressBar from "components/base/progress-bar/progress-bar";
import { useShowChapters } from "hooks/useShowChapters";
import isNil from "lodash/isNil";

const cx = classNames.bind(styles);

interface ChaptersListProps {
	id?: number;
	className?: string;
	isCarouselList?: boolean;
	isRosterInTile?: boolean;
}

const ChaptersList = (props: ChaptersListProps) => {
	const { id, className, isCarouselList = true, isRosterInTile = false } = props;
	const { coursesStore } = useAppStore();
	const [chapterId, setChapterId] = useState<number>(0);
	const completedItems: number = coursesStore.activeCourseItems.filter((courseItem) => courseItem.completed).length;
	const completedPercentage: number = (completedItems / coursesStore.activeCourseItems.length) * 100;
	const scrollToRef = useRef<HTMLDivElement>(null);
	const scrollToElement = () => scrollToRef.current && scrollToRef.current.scrollIntoView({ behavior: "smooth" });

	useEffect(() => {
		if (!isNull(coursesStore.activeChaptersGroup))
			setChapterId(id ? id : first(coursesStore.activeChaptersGroup)?.id ?? 0);
	}, [coursesStore.activeChaptersGroup, id]);

	const { isShowChapters } = useShowChapters();
	const onChapterClick = (id: number) => {
		scrollToElement();
		setChapterId(id);
	};

	const getItems = () =>
		!isNil(coursesStore.activeChaptersGroup)
			? coursesStore.activeChaptersGroup.map((chapter: IChapterRef) => (
					<ChapterTile
						chapter={chapter}
						key={chapter.id}
						onClick={(id) => onChapterClick(id)}
						isActive={chapter.id === chapterId}
						count={coursesStore.activeChaptersItems?.[chapter.id === 0 ? "null" : chapter.id]?.length ?? 0}
						className={styles.chapters_list__chapter_tile}
					/>
			  ))
			: [];

	const changeChapterHandler = (slideIndex: number) => {
		if (!isNil(coursesStore.activeChaptersGroup?.[slideIndex])) {
			setChapterId(coursesStore.activeChaptersGroup?.[slideIndex].id ?? 0);
		}
	};

	return isCarouselList ? (
		<>
			<div className={cx(styles.chapters_list, className)}>
				{isShowChapters && (
					<div className={styles.chapters_list__head}>
						<h1 className={styles.chapters_list__header}>Chapters</h1>
						<Carousel
							items={getItems()}
							className={styles.chapters_list__carousel}
							isCompact
							onMobileSlideChange={(slideIndex) => changeChapterHandler(slideIndex)}
						/>
					</div>
				)}
			</div>
			<ChaptersRoster
				chapter={find(coursesStore.activeChaptersGroup, { id: chapterId }) as IChapterRef}
				roster={coursesStore.activeChaptersItems?.[chapterId === 0 ? "null" : chapterId] as ICourseItemModel[]}
				rosterRef={scrollToRef}
			/>
		</>
	) : (
		<div className={styles.chapters_roster_full}>
			{!isNull(coursesStore.activeChaptersGroup) && (
				<>
					<div className={styles.chapters_roster_full__progress_bar}>
						<h2 className={styles.chapters_roster_full__progress_title}>Progress {Math.round(completedPercentage)}%</h2>
						<ProgressBar fillerColor="var(--primary)" completedPercentage={completedPercentage} />
					</div>
					<div className={styles.chapters_roster_full__list}>
						{coursesStore.activeChaptersGroup.map((chapter: IChapterRef) => (
							<ChaptersRoster
								key={chapter.id}
								chapter={find(coursesStore.activeChaptersGroup, { id: chapter.id }) as IChapterRef}
								roster={
									coursesStore.activeChaptersItems?.[chapter.id === 0 ? "null" : chapter.id] as ICourseItemModel[]
								}
								isRosterInTile={isRosterInTile}
								rosterRef={scrollToRef}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
};

export default observer(ChaptersList);
