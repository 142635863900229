import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as CloseIcon } from "assets/icons/ic_close.svg";
import { ReactComponent as ZoomOutIcon } from "assets/icons/ic_zoom-out.svg";
import trimStart from "lodash/trimStart";

interface Props {
	className?: string;
	onChange?: (value: string) => void;
	defaultValue?: string;
	disabled?: boolean;
}

const InputSearch: React.FC<Props> = (props: Props) => {
	const { className, onChange, defaultValue = "", disabled } = props;
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		if (value !== defaultValue) setValue(defaultValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue]);

	const handleChange = (searchString: string) => {
		const val = trimStart(searchString);
		onChange?.(val);
	};

	const handleClose = () => {
		setValue("");
		onChange?.("");
	};

	return (
		<div className={classNames(styles.input_search, className, { [styles.input_search__disabled]: disabled })}>
			<input
				type="search"
				className={styles.input_search__input}
				placeholder="Search..."
				value={value}
				onChange={(e) => setValue(e.target.value)}
				disabled={disabled}
				onKeyDown={(event) => {
					if (event.key === "Enter") {
						event.preventDefault();
						handleChange(value);
					}
				}}
			/>
			<button type="submit" className={styles.input_search__btn} disabled={disabled}>
				{value !== "" && (
					<CloseIcon
						className={classNames(styles.input_search__icon, styles.input_search__icon__close)}
						onClick={handleClose}
					/>
				)}
				<ZoomOutIcon className={styles.input_search__icon} onClick={() => handleChange(value)} />
			</button>
		</div>
	);
};

export default InputSearch;
