import Api, { ApiBase, abortController } from "api/utils/api";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { AxiosResponse } from "axios";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { ICourseModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseModel";
import { ICreateCourseModel } from "models/dto/ZoomiLxp/Models/Courses/ICreateCourseModel";
import { IUpdateCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IUpdateCourseModel";
import { ICreateContentRefModel } from "models/dto/ZoomiLxp/Models/ContentModels/ICreateContentRefModel";
import { IContentRefResponse } from "models/dto/ZoomiLxp/Models/ContentModels/IContentRefResponse";
import { IUpdateContentRefModel } from "models/dto/ZoomiLxp/Models/ContentModels/IUpdateContentRefModel";
import { ICategoryModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ICategoryModel";
import { ICourseAssignmentModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseAssignmentModel";
import { IAssignCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IAssignCourseModel";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { ICreateChapterModel } from "models/dto/ZoomiLxp/Models/Courses/ICreateChapterModel";
import { IChapterModel } from "models/dto/ZoomiLxp/Models/Courses/IChapterModel";
import { IUpdateChapterModel } from "models/dto/ZoomiLxp/Models/Courses/IUpdateChapterModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { ISourcesModel } from "models/dto/ZoomiLxp/Models/Common/ISourcesModel";
import { ICreateThreadRefModel } from "models/dto/ZoomiLxp/Models/Peers/ICreateThreadRefModel";
import { IThreadRefResponse } from "models/dto/ZoomiLxp/Models/Peers/IThreadRefResponse";
import { IUpdateThreadRefModel } from "models/dto/ZoomiLxp/Models/Peers/IUpdateThreadRefModel";
import { IUpdateContentSegmentRefModel } from "models/dto/ZoomiLxp/Models/ContentModels/IUpdateContentSegmentRefModel";
import { IContentSegmentRefResponseModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentSegmentRefResponseModel";
import { IPreviewCourseVersionModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseVersionModel";
import { IBaseModel } from "models/dto/ZoomiLxp/Models/Common/IBaseModel";
import { ICertificatePreviewModel } from "models/dto/ZoomiLxp/Models/Certificates/ICertificatePreviewModel";
import { ICmsCourseModel } from "models/dto/ZoomiLxp/Models/Courses/ICmsCourseModel";

export class CoursesApi {
	static getCourseById(courseId: number) {
		return Api.get<ICourseModel>(`/courses/getCourseDetails/${courseId}`);
	}

	static getCourseVersions(courseId: number) {
		return Api.get<IPreviewCourseVersionModel[]>(`/courses/courseVersions/${courseId}`);
	}

	static getCourseVersionDetails(versionId: number) {
		return Api.get<ICourseModel>(`/courses/courseVersionDetails/${versionId}`);
	}

	static getLastWatchedCourse() {
		return Api.get<ICourseModel>("/myDesk/LastWatchedCourse");
	}

	static addNewCourse(data: ICreateCourseModel) {
		return Api.post<ICourseModel>("/courses/addCourse", data);
	}

	static addContentRef(data: ICreateContentRefModel) {
		return Api.post<IContentRefResponse>("/courses/addContentRef", data);
	}

	static createChapter(data: ICreateChapterModel) {
		return Api.post<IChapterModel>("/courses/chapter", data);
	}

	static updateChapter(data: IUpdateChapterModel, chapterId: number) {
		return Api.put<IChapterModel>(`/courses/chapter/${chapterId}`, data);
	}

	static getChapterById(chapterId: number) {
		return Api.get<IChapterModel>(`/courses/chapter/${chapterId}`);
	}

	static getChaptersByCourseId(courseId: number) {
		return Api.get<IChapterModel[]>(`/courses/chapters/${courseId}`);
	}

	static getSegmentsReTypes() {
		return Api.get<IBaseModel[]>("/courses/getSegmentsReTypes");
	}

	static deleteChapter(chapterId: number) {
		return Api.delete(`/courses/chapter/${chapterId}`);
	}

	static updateContentRef(courseItemId: number, data: IUpdateContentRefModel) {
		return Api.put<IContentRefResponse>(`/courses/updateContentRef/${courseItemId}`, data);
	}

	static updateContentSegmentRef(courseItemId: number, data: IUpdateContentSegmentRefModel) {
		return Api.put<IContentSegmentRefResponseModel>(`/courses/UpdateContentSegmentRef/${courseItemId}`, data);
	}

	static updateCourse(courseId: number, data: IUpdateCourseModel) {
		return Api.put<ICourseModel, IUpdateCourseModel>(`/courses/fullUpdateCourse/${courseId}`, data);
	}

	static getAllCourses(data: IQueryParams) {
		return Api.postUnwrapped<IGetRecordsResponse<ICmsCourseModel>, IQueryParams>("/courses/GetAll", data, {
			signal: abortController.getSignalToken(),
		});
	}

	static searchCourses(data: IQueryParams) {
		return ApiBase.post<IQueryParams, AxiosResponse<IResponseModel<IGetRecordsResponse<IPreviewCourseModel>>>>(
			"/courses/SearchCourses",
			data
		);
	}

	static getCourseAssignments(courseId: number, queryParams: IQueryParams) {
		return Api.postUnwrapped<IGetRecordsResponse<ICourseAssignmentModel>, IQueryParams>(
			`/courses/assignments/${courseId}`,
			queryParams
		);
	}

	static getIsCourseRequested(courseId: number) {
		return Api.get(`/courses/IsCourseRequested/${courseId}`);
	}

	static assignCourse(data: IAssignCourseModel) {
		return Api.post<ICourseAssignmentModel>("/courses/assign", data);
	}

	static requestAssignCourse(data: IAssignCourseModel) {
		return Api.post<ICourseAssignmentModel>("/courses/requestCourse", data);
	}

	static getAssignedCourses(userId: number, data: IPagingParams) {
		return Api.get<IGetRecordsResponse<IPreviewCourseModel>>(`/courses/AssignedCourses/${userId}`, {
			params: data,
		});
	}

	static getCategories() {
		return Api.get<ICategoryModel[]>("/courses/getCategories");
	}

	static getSubjects() {
		return Api.get("/courses/getSubjects");
	}

	static getAuthors() {
		return Api.get("/courses/Authors");
	}
	static getCreators() {
		return Api.get("/courses/CreatedBy");
	}
	static getSources() {
		return Api.get<ISourcesModel>("/courses/Sources");
	}
	static getMostLikedCourses(data: IPagingParams) {
		return Api.get<IGetRecordsResponse<IPreviewCourseModel>>("/homePage/MostLikedCourses", {
			params: data,
		});
	}
	static getMostWatchedCourses(data: IPagingParams) {
		return Api.get<IGetRecordsResponse<IPreviewCourseModel>>("/homePage/MostWatchedCourses", {
			params: data,
		});
	}
	static getRecommendedCourses(data: IPagingParams) {
		return Api.get<IGetRecordsResponse<IPreviewCourseModel>>("/homePage/RecommendedCourses", {
			params: data,
		});
	}
	static getTrendingCourses(data: IPagingParams) {
		return Api.get<IGetRecordsResponse<IPreviewCourseModel>>("/homePage/TrendingCourses", {
			params: data,
		});
	}
	static getTopRatedCourses(data: IPagingParams) {
		return Api.get<IGetRecordsResponse<IPreviewCourseModel>>("/homePage/TopRatedCourses", {
			params: data,
		});
	}
	static getBookmarkedCourses(data: IPagingParams) {
		return Api.get<IGetRecordsResponse<IPreviewCourseModel>>("/homePage/BookmarkCourses", {
			params: data,
		});
	}
	static getPurchasedCurses(data: IPagingParams) {
		return Api.get<IGetRecordsResponse<IPreviewCourseModel>>("/homePage/PurchasesByYouCourses", {
			params: data,
		});
	}

	static getSimilarCourses(courseId: number, data: IPagingParams) {
		return Api.get<IGetRecordsResponse<IPreviewCourseModel>>(`/myDesk/SimilarCourses/${courseId}`, {
			params: data,
		});
	}

	static meetYourGoals(data: IPagingParams) {
		return Api.get<IGetRecordsResponse<IPreviewCourseModel>>("/homePage/MeetYourGoals", {
			params: data,
		});
	}

	static addThreadRef(data: ICreateThreadRefModel) {
		return Api.post<IThreadRefResponse>("/Courses/AddThreadRef", data);
	}

	static updateThreadRef(courseItemId: number, data: IUpdateThreadRefModel) {
		return Api.put<IThreadRefResponse>(`/Courses/UpdateThreadRef/${courseItemId}`, data);
	}

	static getCourseCertificate(courseId: number) {
		return Api.get<ICertificatePreviewModel>(`/Courses/certificate/${courseId}`);
	}
}
