import { observer } from "mobx-react";
import styles from "./styles.module.scss";
import { useAppStore } from "store";
import { useEffect } from "react";
import CarouselsArray from "components/partial/carousels-array/carousels-array";
import { getOrderedCarousels } from "helpers/settings.helper";
import { useRouteUserId } from "hooks/useRouteUserId";

export const MyHistory = observer(function MyHistory() {
	const { routeUserId: otherUserId } = useRouteUserId();
	const { historyStore, settingsStore } = useAppStore();

	useEffect(() => {
		historyStore.getAllHistory(Number(otherUserId)).catch((err) => console.error(err));
		return historyStore.clearStore();
	}, [historyStore, otherUserId]);

	const sortedHistoryCarousels = getOrderedCarousels(settingsStore.publicSettings, "History.Carousels.Order");

	return (
		<div className={styles.history}>
			<div className={styles.history__content}>
				<CarouselsArray carouselsKeys={sortedHistoryCarousels} />
			</div>
		</div>
	);
});
