import styles from "./styles.module.scss";
import PrivateLayout from "layouts/private/private";
import { ReactComponent as CloseIcon } from "assets/icons/ic_close.svg";
import AdvSearchForm from "components/partial/adv-search/adv-search-form/adv-search-form";
import SubMenu from "components/partial/submenu/submenu";
import { advSearchMenuItems } from "components/partial/submenu/items";
import classnames from "classnames";
import { observer } from "mobx-react";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { useAppStore } from "store";
import Alert from "components/base/alert/alert";
import { useState } from "react";
import { useSearchType } from "hooks/useSearchType";
import { useHistory } from "react-router-dom";
import SearchResults from "components/partial/adv-search/components/search-results/search-results";
import Toggler from "components/base/toggler/toggler";

const AdvSearchPage = () => {
	const history = useHistory<{ prevPageInfluencers: boolean; prevPageThreads: boolean }>();
	const { searchStore, commonStore } = useAppStore();
	const { searchType } = useSearchType();
	const [openInNewTab, setOpenInNewTab] = useState<boolean>(searchStore.openResultsInNewTab);

	const handleClose = () => {
		searchStore.clearStoreAll();
		history.goBack();
	};

	const openInNewTabHandler = (value: boolean) => {
		setOpenInNewTab(value);
		searchStore.openResultsInNewTab = value;
	};

	return (
		<PrivateLayout>
			<div className={styles.adv_search}>
				<Alert eventBus={commonStore.alertEventBus} />
				<div className={styles.adv_search__header}>
					<div className={styles.adv_search__top_controls_container}>
						<div className={styles.adv_search__new_tab_toggler_container}>
							Open results in new tab
							<Toggler
								value={openInNewTab}
								onChange={(value) => openInNewTabHandler(value)}
								className={styles.adv_search__new_tab_toggler}
							/>
						</div>
						<CloseIcon className={styles.adv_search__close_icon} onClick={() => handleClose()} />
					</div>

					<AdvSearchForm searchType={searchType} />

					<div className={classnames(styles.adv_search__submenu_row, styles.adv_search__submenu)}>
						<SubMenu menuItems={advSearchMenuItems} className={styles.adv_search__submenu} />
					</div>
				</div>

				<div className={styles.adv_search__results}>
					<LoadingIndicator loading={searchStore.isLoadingSearchData} spinnerPosition="center" backgroundStyle={"none"}>
						<SearchResults searchType={searchType} />
					</LoadingIndicator>
				</div>
			</div>
		</PrivateLayout>
	);
};

export default observer(AdvSearchPage);
