import { ICustomCarouselSettings, IImageFileModel } from "components/partial/cms/cms-settings/data/models";
import { Dictionary, forEach, pickBy, sortBy } from "lodash";

export enum BanerTypes {
	Image,
	Video,
}

export const setColorTheme = (themeColors: any) => {
	forEach(themeColors, (color, key) => {
		document.documentElement.style.setProperty(key, color);
	});
};

export const getCarouselsCount = (platformSettings: Dictionary<Dictionary<string>>, settingsKey: string) => {
	const carousels = Object.keys(platformSettings.Pages).filter((key) => key.includes(settingsKey));
	let count = 0;
	for (const key in platformSettings.Pages) {
		if (carousels.includes(key)) {
			count++;
		}
	}
	return count;
};

export const getOrderedCarousels = (platformSettings: Dictionary<Dictionary<string>>, settingsKey: string) => {
	const filteredData = pickBy(
		platformSettings.Pages,
		(value, key) => key.includes(settingsKey) && value !== "Disabled"
	);
	const sortedData = sortBy(
		Object.keys(filteredData).map((key) => ({ key, value: filteredData[key] })),
		(obj) => parseInt(obj.value)
	);

	return sortedData.map((obj) => obj.key);
};

export const getCarouselName = (carouselKey: string, carousels: ICustomCarouselSettings[]): string => {
	return carousels.find((carousel) => carousel.carouselKey === carouselKey)?.name ?? "";
};
export const getCarouselSequence = (carouselKey: string, carousels: ICustomCarouselSettings[]): string => {
	return carousels.find((carousel) => carousel.carouselKey === carouselKey)?.sequence ?? "1";
};

export const getImageFileUrl = (type: "banner" | "favicon" | "logo" | "other", data?: IImageFileModel) => {
	const defaultValue = type === "favicon" ? "/favicon.ico" : type === "logo" ? "/assets/logo.svg" : "";

	if (data && data.id) {
		return data.id.toString();
	} else if (data && data.url) {
		return data.url;
	}
	return defaultValue;
};

export const getBannerType = (bannerContentType: string): BanerTypes => {
	if (bannerContentType?.includes("jpg") || bannerContentType?.includes("jpeg") || bannerContentType?.includes("png")) {
		return BanerTypes.Image;
	}
	return BanerTypes.Video;
};
