export enum HOME_CAROUSELS_KEYS {
	curatedSpeciallyForYou = "curatedSpeciallyForYou",
	mostRated = "mostRatedCarousel",
	mostWatched = "mostWatchedCarousel",
	playlistCarousel = "playlistCarousel",
	requiredSkillsCarousel = "requiredSkillsCarousel",
	trendingCarousel = "trendingCarousel",
	purchasedCarousel = "purchasedCarousel",
	assignedCarousel = "assignedCarousel",
	goalsCarousel = "goalsCarousel",
	mostLikedCarousel = "mostLikedCarousel",
}

export enum MY_DATA_CAROUSELS_KEYS {
	followingInfluencers = "followingInfluencers",
	watchingHistory = "watchingHistory",
	continueWatching = "continueWatching",
	bookmarkedCourses = "bookmarkedCourses",
	coursesCreatedByYou = "coursesCreatedByYou",
	threadsCreatedByYou = "threadsCreatedByYou",
}

export enum INFLUENCERS_CAROUSELS_KEYS {
	shouldFollowCarousel = "shouldFollowCarousel",
	mostLikedCarousel = "mostLikedCarousel",
	mostFollowedCarousel = "mostFollowedCarousel",
	myInfluencersCarousel = "myInfluencersCarousel",
	moversAndShakersCarousel = "moversAndShakersCarousel",
}

export enum PEERS_CAROUSELS_KEYS {
	trendingCarousel = "trendingCarousel",
	createdByYouCarousel = "createdByYouCarousel",
	curatedForYouCarousel = "curatedForYouCarousel",
	savedThreadsCarousel = "savedThreadsCarousel",
}

export enum MYDESK_CAROUSELS_KEYS {
	similarCoursesCarousel = "similarCoursesCarousel",
	mostWatchedCarousel = "mostWatchedCarousel",
	assignedToYouCarousel = "assignedToYouCarousel",
	meetYourGoalsCarousel = "meetYourGoalsCarousel",
}
