import MyQuizResults from "components/partial/myDesk/my-quiz-results/my-quiz-results";
import { useRouteUserId } from "hooks/useRouteUserId";
import MyAccountLayout from "layouts/my-account/my-account-layout";
import ProfileLayout from "layouts/private/profile/profile-layout";

const MyQuizResultsPage = () => {
	const { routeUserId } = useRouteUserId();
	return routeUserId ? (
		<ProfileLayout>
			<MyQuizResults />
		</ProfileLayout>
	) : (
		<MyAccountLayout>
			<MyQuizResults />
		</MyAccountLayout>
	);
};

export default MyQuizResultsPage;
