import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Routes } from "routes";
import Button from "components/base/button/button";
import { FieldArray, Form, Formik, FormikErrors, FormikValues } from "formik";
import { isEmpty } from "lodash";
import InputField from "components/base/input-field/input-field";
import classNames from "classnames";
import { ReactComponent as AddIcon } from "assets/icons/ic_plus.svg";
import { ReactComponent as RemoveIcon } from "assets/icons/ic_close.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/ic_alert-circle.svg";
import { IBaseModel } from "models/dto/ZoomiLxp/Models/Common/IBaseModel";
import find from "lodash/find";
import Avatar from "components/base/avatar/avatar";
import UploadTarget from "components/base/upload-target/upload-target";
import { useAppStore } from "store";
import { IFileModel } from "models/dto/ZoomiLxp/Models/Common/IFileModel";
import findIndex from "lodash/findIndex";
import { Prompt, useHistory } from "react-router";
import isEqual from "lodash/isEqual";
import * as Yup from "yup";
import { string } from "yup";
import { descriptionValidator, titleValidator } from "helpers/validator.helper";
import { ICreateThreadModel } from "models/dto/ZoomiLxp/Models/Peers/ICreateThreadModel";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import { IUpdateThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IUpdateThreadModel";
import { getAttachmentPicture, getPicture } from "helpers/image.helper";
import { UploadFileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/UploadFileTypes";
import TogglerActivation from "components/partial/toggler-activation/toggler-activation";
import { ReactComponent as InfoIcon } from "assets/icons/ic_info.svg";
import Tooltip from "components/base/tooltip/tooltip";
import ProfileLayout from "layouts/private/profile/profile-layout";
import { errorMessages, groupsTooltipText, infoMessages, picturesHintMessages } from "constants/messages";
import { DELAY_TIME_LONGER, acceptedMimeTypes, fileUploadRestrictions } from "constants/constants";
import { ICroppedFileOptions, cropImageHandler } from "components/partial/image-cropper/image-cropper.helper";
import { FileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/FileTypes";
import { PermissionConstantsUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsUpdate";
import SubjectsChipsSelector from "components/partial/subjects-chips-selector/subjects-chips-selector";

const fileCroppingOptions: ICroppedFileOptions = {
	imageType: FileTypes.Thumbnail,
	uploadFileType: UploadFileTypes.ThreadThumbnail,
};

const getPromptCondition = (isNewThread: boolean, formValues: IThreadModel, threadInfo?: IThreadModel | null) => {
	// Comparison with > 1 because we add the default 'isActive' key in form object for all new threads on loading.
	const isFormChanged = Object.keys(formValues).length > 1;
	if (isEmpty(threadInfo)) return false;
	return isNewThread ? isFormChanged : !isEmpty(threadInfo) && !isEqual(threadInfo, formValues);
};

const ThreadProfilePage = () => {
	const match = useRouteMatch<{ id: string }>();
	const [threadId, setThreadId] = useState(Number(match.params.id));
	const [isNewThread, setIsNewThread] = useState(!Boolean(threadId));
	const { peersStore, taxonomyStore, usersStore, toasterStore } = useAppStore();
	const [threadInfo, setThreadInfo] = useState<IThreadModel | null>();
	const [newTag, setNewTag] = useState({ name: "" });
	const history = useHistory();
	const [isActive, setIsActive] = useState<boolean>(isNewThread ? true : !!threadInfo?.isActive);
	const [isPictureLoading, setIsPictureLoading] = useState<boolean>(false);
	const [disableUpload, setDisableUpload] = useState(false);

	useEffect(() => {
		if (!isNewThread) {
			peersStore.getThreadById(threadId).then((thread: IThreadModel) => {
				const isOwnThread = usersStore.currentUserInfo?.id === thread.author.id;
				const isRouteDenied =
					!isOwnThread && !usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.AllThreads]);

				if (isRouteDenied) {
					history.replace(Routes.NoAccess);
				}

				setThreadInfo(thread);
				setIsActive(thread?.isActive);

				if (!!thread.courseItemId) {
					toasterStore.showErrorMessage("This thread cannot be edited");
					setTimeout(() => {
						history.push(Routes.CmsThreads);
					}, DELAY_TIME_LONGER);
				}
			});
		}
		taxonomyStore.getAllCategories().catch((err) => console.error(err));

		return () => {
			setThreadInfo(null);
		};
	}, [toasterStore, history, isNewThread, peersStore, threadId, taxonomyStore, usersStore]);

	const addTagHandler = (values: FormikValues, push: (obj: { name: string }) => void) => {
		if (isEmpty(newTag.name)) {
			toasterStore.showErrorMessage("Tag field can't be empty");
			return;
		}

		if (values.tags?.length >= 15) {
			toasterStore.showErrorMessage("The count of tags cannot be more than 15.");
			return;
		}

		if (!isEmpty(find(values.tags, newTag))) {
			toasterStore.showErrorMessage("Tags cannot contain duplicates");
			return;
		}

		push(newTag);
		setNewTag({ name: "" });
	};

	const ThreadProfileSchema = Yup.object().shape({
		thumbnail: Yup.object({
			url: string().required(infoMessages.requiredField),
		}),
		title: titleValidator,
		description: descriptionValidator,
		subjects: Yup.array().required("At least one Subject is required"),
	});

	const onSave = async (data: ICreateThreadModel | IUpdateThreadModel) => {
		try {
			if (!isNewThread) {
				await peersStore.updateThread(threadId, data).then((thread: IThreadModel) => {
					setThreadInfo(thread);
				});
				toasterStore.showSuccessMessage("Thread data successfully updated");
			} else {
				await peersStore.addNewThread(data).then((thread: IThreadModel) => {
					toasterStore.showSuccessMessage("New Thread was successfully created");
					setThreadInfo(thread);
					setThreadId(thread.id);
					setIsNewThread(false);
				});
			}
		} catch (err) {
			toasterStore.showErrorMessage(err);
		}
	};

	const onClick =
		(
			validateForm: (values?: any) => Promise<FormikErrors<FormikValues>>,
			values: FormikValues,
			setErrors: (errors: FormikErrors<FormikValues>) => void
		) =>
		async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			e.preventDefault();
			peersStore.isLoading = true;
			const errors = await validateForm();
			if (isEmpty(errors)) {
				await onSave(values as ICreateThreadModel | IUpdateThreadModel);
			} else {
				peersStore.isLoading = false;
				setErrors(errors);
				toasterStore.showErrorMessage("Please check all fields are filled in correctly");
			}
		};

	return (
		<ProfileLayout headerLabel={"Peers"}>
			<div className={styles.thread_profile}>
				<Formik
					initialValues={threadInfo ?? ({} as IThreadModel)}
					validationSchema={ThreadProfileSchema}
					onSubmit={() => {}}
					validateOnChange={false}
					enableReinitialize={true}
				>
					{(form) => {
						const { values, validateForm, setFieldValue, errors, setErrors } = form;

						return (
							<Form>
								<Prompt
									message={infoMessages.leaveWithoutSaving}
									when={getPromptCondition(isNewThread, values, threadInfo)}
								/>
								<div className={styles.thread_profile__tile}>
									<div className={styles.thread_profile__tile_control}>
										<div className={styles.thread_profile__tile_label}>Image</div>
										<div className={styles.thread_profile__activation_group}>
											<TogglerActivation
												value={isActive}
												onChange={(value: boolean) => {
													setIsActive(value);
													setFieldValue("isActive", value);
												}}
												onLoadAction={() => {
													setFieldValue("isActive", isActive);
												}}
												className={styles.thread_profile__toggler}
											/>
											<Tooltip
												label={
													"Active threads can be viewed by learners and will show up in " +
													"search and in home page. Inactive threads are available only in " +
													"CMS and will not appear in search and in home page."
												}
												className={styles.thread_profile__tooltip}
											>
												<InfoIcon className={styles.thread_profile__info_icon} />
											</Tooltip>
										</div>
									</div>
									<div className={styles.thread_profile__image_upload_container}>
										<UploadTarget
											accept={acceptedMimeTypes.pictures}
											onSuccess={(res, type) => {
												cropImageHandler({
													parentFile: { ...res.data, contentType: type },
													filesOptions: fileCroppingOptions,
													onCropSuccessHandler: (newFile: IFileModel) => {
														setFieldValue("thumbnail", newFile);
														setFieldValue("thumbnailId", newFile.id);
													},
													onLoading: (isLoading) => setIsPictureLoading(isLoading),
													aspect: 16 / 9,
												});
											}}
											onError={(err) => console.log(err)}
											onLoading={(isLoading) => setIsPictureLoading(isLoading)}
											maxSize={10}
											onWrongFormat={(type) =>
												toasterStore.showErrorMessage(
													errorMessages.unsupportedFileType(type, "a JPG or PNG")
												)
											}
											onMaxSizeExceeded={(size, maxSize) => {
												toasterStore.showErrorMessage(
													errorMessages.exceedingAllowedFileSize(size, maxSize)
												);
											}}
											fileType={UploadFileTypes.ThreadThumbnail}
										>
											<Avatar
												size="medium"
												className={styles.thread_profile__thumbnail}
												image={getPicture(values)}
												title={""}
												noEmptyAvatar={true}
												isLoading={isPictureLoading}
												hintText={picturesHintMessages.thumbnail}
											>
												<div className={styles.thread_profile__image_cover}>
													{values.thumbnail?.url ? "Change image" : "+ Add image"}
												</div>
											</Avatar>
										</UploadTarget>

										{errors.thumbnail?.url && (
											<div className={styles.thread_profile__error}>
												<ErrorIcon className={styles.thread_profile__error_icon} />
												<span>{errors.thumbnail?.url}</span>
											</div>
										)}
									</div>
								</div>
								<div className={styles.thread_profile__tile}>
									<p className={styles.thread_profile__tile_label}>Details</p>
									<div className={styles.thread_profile__input_box}>
										<InputField
											inputType="text"
											label="Title"
											className={classNames(styles.thread_profile__form_field, styles.thread_profile__wide)}
											name="title"
											value={values?.title ?? ""}
											onChange={(e) => setFieldValue("title", e.target.value)}
											isError={!!errors?.title}
											errorText={errors?.title}
										/>
									</div>

									<div className={styles.thread_profile__gap_bottom}>
										<InputField
											fieldType="textarea"
											label="Description"
											className={styles.thread_profile__input_area}
											name="description"
											value={values?.description ?? ""}
											onChange={(e) => setFieldValue("description", e.target.value)}
											isError={!!errors?.description}
											errorText={errors?.description}
										/>
									</div>
									<div className={styles.thread_profile__upload_group}>
										<FieldArray
											name="uploadedFiles"
											render={({ push, remove }) => (
												<>
													<UploadTarget
														accept={acceptedMimeTypes.attachments}
														disabled={disableUpload || values.uploadedFiles?.length >= 5}
														onSuccess={(res, type) => {
															if (!values.uploadedFiles?.length || values.uploadedFiles?.length < 5) {
																push({ id: res.data.id, url: res.data.url, contentType: type });
															}
														}}
														onError={(err) => console.log(err)}
														additionalRestrictions={{
															additionalMaxSize: fileUploadRestrictions.maxSize.video,
															additionalRestrictionAcceptType: acceptedMimeTypes.video,
														}}
														maxSize={fileUploadRestrictions.maxSize.threadAttachments}
														onLoading={(isLoading) => setDisableUpload(isLoading)}
														onWrongFormat={(type) => {
															toasterStore.showErrorMessage(
																errorMessages.unsupportedFileType(
																	type,
																	".pdf, .png, .jpeg, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4"
																)
															);
														}}
														onMaxSizeExceeded={(size, maxSize) => {
															toasterStore.showErrorMessage(
																errorMessages.exceedingAllowedFileSize(size, maxSize)
															);
														}}
														fileType={UploadFileTypes.ThreadPicture}
													>
														<Button
															label={"Upload attachments"}
															onClick={(event) => {
																if (values.uploadedFiles?.length >= 5) {
																	toasterStore.showErrorMessage(
																		"The number of files cannot be more than 5"
																	);
																	event.stopPropagation();
																}
															}}
															disabled={disableUpload || values.uploadedFiles?.length >= 5}
															className={styles.thread_profile__upload_button}
															type="secondary"
														/>
													</UploadTarget>
													<div className={styles.thread_profile__images_group}>
														{isEmpty(values.uploadedFiles) ? (
															<>You can upload up to 5 files</>
														) : (
															values.uploadedFiles?.map((item: IFileModel) => {
																return (
																	<Avatar
																		size="medium"
																		className={styles.thread_profile__uploaded_image}
																		image={getAttachmentPicture(item, true)}
																		title={""}
																		noEmptyAvatar={true}
																		key={item.id}
																	>
																		<div
																			className={styles.thread_profile__uploaded_block}
																			onClick={() => {
																				remove(findIndex(values.uploadedFiles, { id: item.id }));
																			}}
																		>
																			<RemoveIcon className={styles.thread_profile__uploaded_icon} />
																		</div>
																	</Avatar>
																);
															})
														)}
													</div>
												</>
											)}
										/>
									</div>
									<SubjectsChipsSelector values={values} errors={errors} setFieldValue={setFieldValue} />
								</div>
								<div className={styles.thread_profile__tile}>
									<div className={styles.thread_profile__tags_label_container}>
										<span className={classNames(styles.tags_label, styles.thread_profile__tile_label)}>Tags</span>
										<Tooltip label={groupsTooltipText} className={styles.thread_profile__tags_tooltip}>
											<InfoIcon className={styles.thread_profile__info_icon} />
										</Tooltip>
									</div>
									<div className={styles.thread_profile__content_container}>
										<FieldArray
											name="tags"
											render={({ push, remove }) => (
												<div className={styles.thread_profile__chips_container}>
													<InputField
														inputType="text"
														label="Add tags"
														className={classNames(styles.thread_profile__form_field__tags, styles.thread_profile__wide)}
														value={newTag.name ?? ""}
														onChange={(e) => setNewTag({ name: e.target.value })}
														isError={!!errors.tags!}
														icon={<AddIcon className={styles.thread_profile__tags_button_icon} />}
														onKeyDown={(event) => {
															if (event.key === "Enter") {
																event.preventDefault();
																addTagHandler(values, push);
															}
														}}
														onIconClick={() => addTagHandler(values, push)}
														errorText={String(errors.tags)}
													/>
													<div className={styles.thread_profile__chips_block}>
														{values.tags?.map((item: IBaseModel, index: number) => {
															return (
																<Button
																	key={index}
																	label={item.name}
																	onClick={() => remove(index)}
																	size="small"
																	iconPosition="right"
																	icon={<RemoveIcon />}
																	className={styles.thread_profile__tags_remove_btn}
																/>
															);
														})}
													</div>
												</div>
											)}
										/>
									</div>
								</div>
								<div className={styles.thread_profile__inner}>
									<Button
										disabled={peersStore.isLoading}
										label={isNewThread ? "Add thread" : "Save"}
										onClick={onClick(validateForm, values, setErrors)}
										className={styles.thread_profile__save_button}
									/>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</ProfileLayout>
	);
};

export default observer(ThreadProfilePage);
