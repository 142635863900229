import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "routes";
import CmsLayout from "layouts/cms/cms";
import ReportPage from "pages/private/reports/report-page/report-page";
import { IReport } from "./data";
import ReportDashboard from "../report-dashboard/report-dashboard";
import { matchPath } from "react-router";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";

const ReportBox = () => {
	const history = useHistory();

	const pathname = history.location.pathname;

	let report: IReport = {
		label: "",
		settingsKey: "",
	};

	if (Boolean(matchPath(pathname, Routes.ReportUser))) {
		report = {
			label: "Reports by User",
			settingsKey: SiteSettingsKeys.ReportByUser,
		};
	} else if (Boolean(matchPath(pathname, Routes.ReportCourse))) {
		report = {
			label: "Reports by Course",
			settingsKey: SiteSettingsKeys.ReportByCourse,
		};
	}

	const getReportPage = () => {
		if (Boolean(matchPath(pathname, Routes.ReportDashboard))) return <ReportDashboard />;
		else return <ReportPage report={report} />;
	};

	return <CmsLayout label="Reports">{getReportPage()}</CmsLayout>;
};

export default ReportBox;
