//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ContentStatuses {
	None = 0,
	DownloadingFile = 1,
	ExtractingText = 2,
	WritingExtractedText = 3,
	CalculatingEmbeddings = 4,
	GettingContentVectors = 5,
	WritingVector = 6,
	ProcessingDistances = 7,
	CalculatingDistances = 8,
	PreparingQueries = 9,
	UpdatingDistances = 10,
	WritingNewDistances = 11,
	Done = 12,
	ZipExtracting = 13,
	ZipExtractingFailed = 14,
	SCORMValidation = 15,
	SCORMValidationFailed = 16,
	SentToTextExtractor = 17,
	Error = 18
}
