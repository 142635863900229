import MyAnalytics from "components/partial/myDesk/my-analytics/my-analytics";
import { useRouteUserId } from "hooks/useRouteUserId";
import MyAccountLayout from "layouts/my-account/my-account-layout";
import ProfileLayout from "layouts/private/profile/profile-layout";

const MyAnalyticsPage = () => {
	const { routeUserId } = useRouteUserId();
	return routeUserId ? (
		<ProfileLayout>
			<MyAnalytics />
		</ProfileLayout>
	) : (
		<MyAccountLayout>
			<MyAnalytics />
		</MyAccountLayout>
	);
};

export default MyAnalyticsPage;
