import { memo } from "react";
import CourseTileCompact from "components/partial/course-tile/course-tile-compact";
import { ILearningPathItem } from "models/dto/ZoomiLxp/Models/Analytics/ILearningPathItem";
import { Handle, NodeProps, Position } from "reactflow";
import {
	BCKWRD_BTTM_SRC_ID,
	BCKWRD_BTTM_TRGT_ID,
	BCKWRD_TOP_SRC_ID,
	BCKWRD_TOP_TRGT_ID,
	LEFT_TRGT_ID,
	RIGHT_SRC_ID,
} from "helpers/learning-path.helper";
import classNames from "classnames";
import styles from "../../styles.module.scss";

export default memo(
	({ data: pathData }: NodeProps<ILearningPathItem & { isPhantom?: boolean; onLoadMore?: () => void }>) => {
		return (
			<>
				<Handle type="target" id={LEFT_TRGT_ID} position={Position.Left} />
				<Handle type="source" id={RIGHT_SRC_ID} position={Position.Right} />
				{pathData.isPhantom ? (
					<div
						className={classNames("nodrag", styles.phantom_tile, styles.phantom_tile__compact)}
						onClick={pathData.onLoadMore}
					>
						<p>Load more</p>
					</div>
				) : (
					<CourseTileCompact courseInfo={pathData.course} disableRating tileStyle="light" />
				)}
				<Handle type="target" id={BCKWRD_TOP_TRGT_ID} position={Position.Top} />
				<Handle type="source" id={BCKWRD_TOP_SRC_ID} position={Position.Top} />
				<Handle type="target" id={BCKWRD_BTTM_TRGT_ID} position={Position.Bottom} />
				<Handle type="source" id={BCKWRD_BTTM_SRC_ID} position={Position.Bottom} />
			</>
		);
	}
);
