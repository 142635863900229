import styles from "./styles.module.scss";
import { ReactComponent as Head } from "assets/images/brain.svg";
import React, { useState } from "react";
import { ReactComponent as InfoIcon } from "assets/icons/ic_info.svg";
import TooltipBase from "components/base/tooltip/tooltip";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import classNames from "classnames/bind";
import DropdownList from "components/base/dropdown-list/dropdown-list";
import GoalsForm from "../goals-form/goals-form";
import GoalsList from "../goals-list/goals-list";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { IUpdateGoalModel } from "models/dto/ZoomiLxp/Models/Goals/IUpdateGoalModel";
import find from "lodash/find";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { IOptionsItem } from "helpers/select.helper";
import { useRouteUserId } from "hooks/useRouteUserId";
import { showErrorMessage } from "helpers/error.handling.helper";

const cx = classNames.bind(styles);

const MAX_CATEGORIES = 10;

const GoalsBrain = () => {
	const { goalsStore } = useAppStore();
	const [category, setCategory] = useState({ label: "Add Category", value: "0" } as IOptionsItem);
	const [isShowForm, setShowForm] = useState(false);
	const { routeUserId } = useRouteUserId();
	const onSave = (goal: IUpdateGoalModel) => {
		if (goal.weight + goalsStore.goalsSumWeight > 100) {
			showErrorMessage(
				goalsStore.alertEventBus,
				`You don't have enough free time for that! You can assign up to ${
					100 - goalsStore.goalsSumWeight
				}% of your time to ${category.label} or free up time by reducing your targets for other categories.`
			);
		} else if (Boolean(find(goalsStore.goals, ["category.id", Number(category.value)]))) {
			showErrorMessage(goalsStore.alertEventBus, "You can't add this category, this category already exists");
		} else {
			setShowForm(false);
			setCategory({ label: "Add Category", value: "0" });
			goalsStore
				.addGoal(
					{
						weight: goal.weight,
						categoryId: Number(category.value),
						subjectIds: [],
					},
					routeUserId
				)
				.catch((err) => {
					showErrorMessage(goalsStore.alertEventBus, err);
				});
		}
	};

	const getDropdownList = () => {
		if (goalsStore.goalsCount >= MAX_CATEGORIES) {
			return (
				<div className={styles.goals_brain__drop_list_error}>
					{`You can’t add any more categories - you can have only ${MAX_CATEGORIES} goals. You have to remove some of your goals to add a new one.`}
				</div>
			);
		} else if (goalsStore.goalsSumWeight >= 100) {
			return (
				<div className={styles.goals_brain__drop_list_error}>
					You've allocated all of your time! Free up at least 10% of your time from existing categories to add a new
					one.
				</div>
			);
		} else {
			return (
				<DropdownList
					value={category}
					placeholder="Goal"
					options={goalsStore.categoriesOptions}
					className={styles.goals_brain__drop_list}
					isSearchable={true}
					onChange={(value) => {
						if (value) {
							setCategory({ label: value.label, value: value.value });
							setShowForm(true);
						}
					}}
				/>
			);
		}
	};

	const stylesTooltip = {
		boxShadow: "0 4px 4px rgb(0 0 0 / 25%)",
		borderRadius: "15px",
		padding: "7px",
	};

	return (
		<LoadingIndicator loading={goalsStore.isLoading} spinnerPosition={"center"}>
			<div className={styles.goals_brain}>
				<div className={styles.goals_brain__wrapper}>
					<h1 className={styles.goals_brain__title}>My Perfect Brain</h1>
					<TooltipBase
						label={
							"My Perfect Brain is where learners and their managers set goals for the subject matter they want to learn about. In this window, learners and managers can select categories of learning material and assign a percentage of their time to devote to each category. This page will track the learner’s progress against these goals and recommend content to help meet them."
						}
						className={styles.goals_brain__info_tooltip}
						classNameContent={styles.goals_brain__tooltip__content}
					>
						<InfoIcon className={styles.goals_brain__info_icon} />
					</TooltipBase>
				</div>
				<div className={styles.goals_brain__container}>
					<div className={styles.goals_brain__box}>
						<Head className={styles.goals_brain__head} />
						<ResponsiveContainer className={styles.goals_brain__pie_box} width={"83%"} height={"100%"}>
							<PieChart cy="50%">
								<Pie data={goalsStore.dataPie} labelLine={false} dataKey="value">
									{goalsStore.dataPie.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={goalsStore.colorPie[index]} strokeWidth={1} strokeOpacity={0.5} />
									))}
								</Pie>
								<Tooltip formatter={(value: string) => `${value}%`} contentStyle={stylesTooltip} />
							</PieChart>
						</ResponsiveContainer>
					</div>
					<div className={cx(styles.goals_brain__box, styles.goals_brain__box_right)}>
						{getDropdownList()}
						{isShowForm && (
							<GoalsForm
								categoryId={Number(category.value)}
								onCancel={() => {
									setShowForm(false);
									setCategory(() => ({ label: "Add Category", value: "0" }));
								}}
								onSave={onSave}
								onError={(error) => showErrorMessage(goalsStore.alertEventBus, error)}
								isNew
							/>
						)}
						<GoalsList />
					</div>
				</div>
			</div>
		</LoadingIndicator>
	);
};

export default observer(GoalsBrain);
