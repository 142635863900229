import styles from "./styles.module.scss";
import { Routes } from "routes";
import BulkOperationsMenu from "components/partial/bulk-operations-menu/bulk-operations-menu";
import CoursesFiltersGroup from "./components/courses-filters-group/courses-filters-group";
import { NavLink } from "react-router-dom";
import { ReactComponent as IconAdd } from "assets/icons/ic_plus.svg";

export const CmsCoursesFilter = () => {
	return (
		<>
			<div className={styles.cms_courses__header}>
				<div className={styles.cms_courses__title}>Courses</div>
				<BulkOperationsMenu menuType="courses" className={styles.cms_courses__bulk} />
				<CoursesFiltersGroup className={styles.cms_courses__filter_group} />
				<NavLink className={styles.btn_add} to={Routes.CmsAddCourse}>
					Add course
				</NavLink>
				<NavLink className={styles.cms_courses__btn_add_mobile} to={Routes.CmsAddCourse}>
					<IconAdd className={styles.cms_courses__icon} />
				</NavLink>
			</div>
		</>
	);
};
