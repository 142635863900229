import { ICreateFileUploadUrlRequestModel } from "models/dto/ZoomiLxp/Models/FileUpload/ICreateFileUploadUrlRequestModel";
import Api from "../utils/api";
import { IFileUploadUrlResponseModel } from "models/dto/ZoomiLxp/Models/FileUpload/IFileUploadUrlResponseModel";
import { ICreateFileDownloadUrlResponseModel } from "models/dto/ZoomiLxp/Models/FileUpload/ICreateFileDownloadUrlResponseModel";
import axios, { Method } from "axios";

export class FileApi {
	static async createUploadUrl(data: ICreateFileUploadUrlRequestModel, file: File) {
		const response = await Api.post<IFileUploadUrlResponseModel, ICreateFileUploadUrlRequestModel>(
			"File/CreateUploadUrl",
			data
		);
		const uploadInfo = response.data.data;
		await axios.request({
			url: uploadInfo.url,
			method: uploadInfo.method as Method,
			headers: uploadInfo.headers,
			data: file,
		});
		return uploadInfo;
	}

	static getDownloadUrl(fileId: number) {
		return Api.get<ICreateFileDownloadUrlResponseModel>(`File/downloadUrl/${fileId}`);
	}

	static getPublicDownloadUrl(fileId: number) {
		return Api.get<ICreateFileDownloadUrlResponseModel>(`File/publicDownloadUrl/${fileId}`);
	}
}
