import { makeAutoObservable, runInAction } from "mobx";
import { IRecommendationAlertItem } from "models/dto/ZoomiLxp/Models/EventModels/IRecommendationAlertItem";
import { IRecommendationAlertGroup } from "models/dto/ZoomiLxp/Models/EventModels/IRecommendationAlertGroup";
import { EventApi } from "api/controllers/EventApi";
import { find, isNull } from "lodash";

export class RecommendationsStore {
	private _isShowNotice: boolean = false;
	private _isShowList: boolean = false;
	private _items: IRecommendationAlertItem[] = [];
	private _recommendationsItemsGroup: IRecommendationAlertGroup[] = [];
	public motiffsGroupId: number | null | undefined = null;
	public isRecGroupRequested: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	get isShowNotice(): boolean {
		return this._isShowNotice;
	}

	set isShowNotice(value: boolean) {
		this._isShowNotice = value;
	}

	get isShowList(): boolean {
		return this._isShowList;
	}

	set isShowList(value: boolean) {
		this._isShowList = value;
	}

	get items(): IRecommendationAlertItem[] {
		return this._items;
	}

	set items(value: IRecommendationAlertItem[]) {
		this._items = value;
	}

	clear() {
		runInAction(() => {
			this._isShowNotice = false;
			this._isShowList = false;
			this._items = [];
			this._recommendationsItemsGroup = [];
		});
	}
	get recommendationsItemsGroup(): IRecommendationAlertGroup[] {
		return this._recommendationsItemsGroup;
	}

	set recommendationsItemsGroup(value: IRecommendationAlertGroup[]) {
		this._recommendationsItemsGroup = value;
	}

	async getRecommendations(sessionId: number) {
		const { data } = await EventApi.getRecommendations(sessionId);

		runInAction(() => {
			this._recommendationsItemsGroup = data.data.alertGroups;
		});
	}

	async getRecommendationsGroup(sessionId: number) {
		try {
			const { data } = await EventApi.getRecommendationsGroup(sessionId);
			const groupId = data.data.motiffsGroupId;
			this.setMotifGroup(groupId);
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	async clearRecommendationsGroup(sessionId: number) {
		try {
			await EventApi.clearRecommendationsGroup(sessionId);
			this.motiffsGroupId = null;
		} catch (error) {
			console.error(error);
		}
	}

	setMotifGroup(groupId?: number) {
		if (isNull(groupId) || this.motiffsGroupId === groupId) return;
		const itemsGroup = find(this._recommendationsItemsGroup, { motiffsGroupId: groupId });
		if (itemsGroup) {
			const items = itemsGroup.alertItems;
			runInAction(() => {
				this.motiffsGroupId = groupId;
				if (items && items.length) {
					this._items = [...items];
					this.isShowNotice = true;
				}
			});
		}
	}

	getRecGroupsWithDelay(sessionId?: number, timeout: number = 0) {
		if (!this.isRecGroupRequested) {
			setTimeout(() => {
				if (sessionId) this.getRecommendationsGroup(sessionId);
				this.isRecGroupRequested = false;
			}, timeout);
			this.isRecGroupRequested = true;
		}
	}
}
