import { matchPath, useHistory } from "react-router-dom";
import { SearchType } from "pages/private/adv-search-page/data";
import { Routes } from "routes";

const SEARCH_PAGES = [Routes.AdvSearchCoursesPage, Routes.AdvSearchInfluensersPage, Routes.AdvSearchThreadsPage];
export const useSearchType = () => {
	const history = useHistory();

	let searchType: SearchType = SearchType.CoursesSearch;
	const isMatchPath = (path: string): boolean => !!matchPath(history.location.pathname, path);

	SEARCH_PAGES.forEach((page, index) => {
		if (isMatchPath(page)) searchType = index;
	});

	return { searchType };
};
