//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class PermissionConstantsExport
{
	public static LearnerProfileDataCreate: string = `export.learner_profile_data`;
	public static PersonalAnalytics: string = `export.personal_analytics`;
	public static OtherUserAnalytics: string = `export.other_users_analytics`;
	public static OtherUserHistory: string = `export.other_users_history`;
	public static GoalsMyPerfectBrain: string = `export.goals_my_perfect_brain`;
	public static OthersGoalsMyPerfectBrain: string = `export.other_goals_my_perfect_brain`;
	public static Billing: string = `export.billing`;
	public static AggegateAnalytics: string = `export.aggregate_analytics`;
	public static Courses: string = `export.courses`;
	public static CompanyUsers: string = `export.company_users`;
	public static CompanyCohorts: string = `export.company_cohorts`;
	public static Taxonomy: string = `export.taxonomy`;
	public static Purchases: string = `export.purchases`;
	public static Account: string = `export.account`;
	public static Reports: string = `export.reports`;
}
