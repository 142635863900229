import axios, { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import { isNil } from "lodash";
import { IValidationFailure } from "models/dto/FluentValidation/Results/IValidationFailure";
import { IBadRequestModel } from "models/dto/ZoomiLxp/Models/Common/IBadRequestModel";
import { IBadRequestValidationFailedModel } from "models/dto/ZoomiLxp/Models/Common/IBadRequestValidationFailedModel";
import { IRequestModel } from "models/dto/ZoomiLxp/Models/Common/IRequestModel";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import { v4 as uuidv4 } from "uuid";
import axiosRetry from "axios-retry";

export const ApiBase: AxiosInstance = axios.create();
axiosRetry(ApiBase, { retries: 0 });

export const axiosRetryConfig = {
	retries: 3,
	retryDelay: () => 1000,
	retryCondition: (error: AxiosError) => {
		return Number(error.response?.status) >= 500 || !isValidationBadRequest(error);
	},
};

export const abortController = (() => {
	let controller: AbortController = new AbortController();
	let signal: AbortSignal = controller.signal;

	const cancelPendingRequest = () => {
		controller.abort();
		controller = new AbortController();
		signal = controller.signal;
	};

	const getSignalToken = (): AbortSignal => signal;

	return { cancelPendingRequest, getSignalToken };
})();

export interface ApiResponse<T> {
	data: T;
	error?: AxiosError;
}

export const initAxios = (errorHandler: (error: AxiosError) => void) => {
	ApiBase.defaults.baseURL = "/api/v1";
	ApiBase.defaults.responseType = "json";

	ApiBase.interceptors.response.use(
		(response: AxiosResponse) => response,
		(error: AxiosError) => {
			errorHandler && errorHandler(error);
			return Promise.reject(error);
		}
	);

	ApiBase.interceptors.request.use(
		(config: AxiosRequestConfig) => {
			if (config.headers) config.headers["X-Request-ID"] = uuidv4();
			return config;
		},
		(error) => Promise.reject(error)
	);
};

const commonHeaders: Record<string, string> = ApiBase.defaults.headers?.common as any;

export const clearAuthHeaders = () => {
	delete commonHeaders["Authorization"];
};

export const setAuthHeaders = (token: string) => {
	clearAuthHeaders();
	if (token) {
		commonHeaders["Authorization"] = `Bearer ${token}`;
	}
};

export function wrapRequestModel<T>(data?: T) {
	return {
		params: data,
	} as IRequestModel<T>;
}

export function isApiError<T>(payload: any): payload is AxiosError<IResponseModel<T>> {
	return axios.isAxiosError(payload);
}

export function isBadRequest(payload: any): payload is AxiosError<IResponseModel<IBadRequestModel>> {
	return isApiError<IBadRequestModel>(payload);
}

export const isAbortRequestError = (error: any) => {
	return error.code === "ERR_CANCELED";
};

export const requestErrorHandler = (error: any) => {
	if (isAbortRequestError(error)) {
		console.error("Request aborted due new request", error);
	} else console.log(error);
};

export function isValidationBadRequest(
	payload: any
): payload is AxiosError<IResponseModel<IBadRequestValidationFailedModel>> {
	return isApiError<IBadRequestValidationFailedModel>(payload);
}

export function getValidationErrorMessage(payload: any): string {
	if (!isNil(payload.data?.data?.validationFailures)) {
		return payload.data?.data?.validationFailures.find((err: IValidationFailure) => err.errorMessage)?.errorMessage;
	}
	return payload.data?.data?.errorMessage;
}

export default class Api {
	static get<T = any>(url: string, config?: AxiosRequestConfig<IResponseModel<T>>) {
		return ApiBase.get<IResponseModel<T>>(url, config);
	}

	static post<R = any, T = any>(url: string, data?: T, config?: AxiosRequestConfig<IRequestModel<T>>) {
		return ApiBase.post<IRequestModel<T>, AxiosResponse<IResponseModel<R>>>(url, wrapRequestModel(data), config);
	}

	static postUnwrapped<R = any, T = any>(url: string, data?: T, config?: AxiosRequestConfig<T>) {
		return ApiBase.post<T, AxiosResponse<IResponseModel<R>>>(url, data, config);
	}

	static put<R = any, T = any>(url: string, data?: T, config?: AxiosRequestConfig<IRequestModel<T>>) {
		return ApiBase.put<IRequestModel<T>, AxiosResponse<IResponseModel<R>>>(url, wrapRequestModel(data), config);
	}

	static delete<R = any, T = any>(url: string, data?: T) {
		return ApiBase.delete<IRequestModel<T>, AxiosResponse<IResponseModel<R>>>(url, wrapRequestModel(data));
	}
}
