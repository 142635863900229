import { GroupAssignmentType } from "helpers/groups.helper";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import Checkbox from "components/base/checkbox/checkbox";
import styles from "./styles.module.scss";

interface IGroupsListProps {
	groups: GroupAssignmentType[];
	title: string;
}

const GroupsList = ({ groups, title }: IGroupsListProps) => {
	const { groupStore } = useAppStore();

	const handleCheck = (checkedGroup: GroupAssignmentType, isChecked: boolean) => {
		if (isChecked) {
			groupStore.addGroupToAssign(checkedGroup);
		} else groupStore.removeGroupToAssign(checkedGroup);
	};

	if (!groups.length) return <></>;

	return (
		<div className={styles.assign_groups_container}>
			<h4>{title}</h4>
			<ul className={styles.assign_groups_container__groups_list}>
				{groups.map((group: GroupAssignmentType) => {
					return (
						<Checkbox
							key={group.groupId}
							value={groupStore.groupsAssignmentRequestData.some(
								(storedGroup) => storedGroup.groupId === group.groupId
							)}
							label={group.groupName}
							className={styles.assign_groups_container__group_item}
							onChange={(value) => handleCheck(group, value)}
						/>
					);
				})}
			</ul>
		</div>
	);
};

export default observer(GroupsList);
